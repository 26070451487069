import API from '../../../../services/index'

const actions = {
  async addASOToHandBook({ commit }, { payload }) {
    try {
      await API.post('/api/ProntuarioAso/Adicionar', {
        object: payload
      })
    } catch (error) {}
  },
  async editASOFromHandBook({ commit }, { payload }) {
    try {
      await API.put('/api/ProntuarioAso/Editar', {
        object: payload
      })
    } catch (error) {}
  },
  async removeASOFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/ProntuarioAso/Remover', {
        params: {
          id_prontuario_aso: payload
        }
      })
    } catch (error) {}
  },
  async addASOFavoriteHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some((favorite) => {
      return favorite.id === payload.id
    })

    try {
      const { location, ...newPayload } = payload

      if (alreadyOnQuickAccessOrFavorite) {
        const apiResponse = await API.put(
          '/api/FavoritoEncaminhamentoContrarreferencia/Editar',
          {
            object: newPayload
          }
        )

        commit({
          type: 'editModuleFavorite',
          payload: apiResponse.data.object
        })
      } else {
        const apiResponse = await API.post(
          '/api/FavoritoEncaminhamentoContrarreferencia/Adicionar',
          {
            object: newPayload
          }
        )

        commit({
          type: 'addToModuleFavoriteStateWithoutSelect',
          payload: apiResponse.data.object
        })
      }
    } catch (error) {}
  },
  async addASOToFavoritesHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some((favorite) => {
      return favorite.id === payload.id
    })

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        await API.put('/api/FavoritoEncaminhamentoContrarreferencia/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        const apiResponse = await API.post(
          '/api/FavoritoEncaminhamentoContrarreferencia/Adicionar',
          {
            object: requestObject
          }
        )

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async addASOToQuickAccessHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some(
      (favorite) => favorite.id === payload.id
    )

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        await API.put('/api/FavoritoEncaminhamentoContrarreferencia/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        const apiResponse = await API.post(
          '/api/FavoritoEncaminhamentoContrarreferencia/Adicionar',
          {
            object: requestObject
          }
        )
        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async removeASOFavoriteFromHandBook({ commit }, { payload }) {
    const { location, ...newPayload } = payload
    try {
      await API.delete('/api/FavoritoEncaminhamentoContrarreferencia/Remover', {
        data: {
          object: newPayload
        }
      })
      commit({ type: 'removeFromFavoriteState', payload: newPayload })
    } catch (error) {}
  }
}

export default actions
