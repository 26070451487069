<template>
  <div class="search-result__container" v-if="results.length >= 1">
    <table class="table" v-if="results.length >= 1">
      <tbody class="body">
        <tr v-for="result in results" :key="result">
          <td @click="onResultSelected(result)" class="cell">
            {{ result }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    results: Array,
    userId: Number
  },
  data() {
    return {
      items: []
    }
  },

  methods: {
    onResultSelected(result) {
      this.$emit('on-hour-selected', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result {
  &__container {
    position: absolute;
    z-index: 13;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 15px;
    max-height: 300px;
    overflow-y: scroll;
    width: 90%;
  }
}

.body {
  padding: 10px 4px;
  margin: 0;
  color: #000;
}

.cell {
  cursor: pointer;
}
</style>
