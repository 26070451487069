<template>
  <b-modal
    size="lg"
    id="modal-closure"
    title="Pagina de encerramento"
    title-class="text-light"
    header-bg-variant="primary"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @close="resetModal"
    cancel-title="Cancelar"
    cancel-variant="danger"
  >
    <div class="closure">
      <ValidationObserver ref="form" @submit.prevent="desfechoPreNatal">
        <form novalidate>
          <b-row>
            <b-col>
              <label>Tipo Desfecho:</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  :options="listDesfecho"
                  v-model="tipoEncerramento"
                  :reduce="(list) => list.id"
                  label="descDesfecho"
                  :clearable="false"
                  :style="
                    errors.length > 0
                      ? 'border: 1px solid red; border-radius: 11px'
                      : ''
                  "
                />
                <div v-if="errors.length > 0" style="color: red">
                  <p>* Por favor escolha um desfecho</p>
                </div>
              </ValidationProvider>
            </b-col>
            <b-col>
              <label>Nascimento do Bebê:</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  type="date"
                  v-model="form.dtNascimentoBebe"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  :max="new Date().toISOString().substring(0, 10)"
                />
              </ValidationProvider>
            </b-col>

            <b-col>
              <label>Finalização da Gestação:</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  type="date"
                  v-model="form.dtFinalizacaoGestacao"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  :max="new Date().toISOString().substring(0, 10)"
                />
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row style="margin-top: 12px">
            <b-col style="margin-bottom: 20px">
              <label>CID intercorrência:</label>
              <div class="searchCid">
                <input
                  type="text"
                  @input="onSearchInput(cid)"
                  v-model="cid"
                  class="input-cid"
                  placeholder="Pesquisar..."
                />
                <button disabled="true">
                  <i v-if="loading === false" class="ri-search-line"></i>
                  <b-spinner
                    v-else
                    class="alignCenter"
                    small
                    variant="primary"
                  ></b-spinner>
                </button>
              </div>
              <iq-card>
                <div
                  @click="showCids = false"
                  v-if="showCids && searchCid.length > 0"
                  style="
                    width: 100vw;
                    height: 100vh;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 1;
                  "
                ></div>
                <div
                  class="searchResults"
                  v-if="showCids && searchCid.length > 0"
                >
                  <div class="headerSearch">
                    <span>Descrição</span>
                    <span>CID</span>
                  </div>
                  <div
                    class="patientItem"
                    v-for="cid in searchCid"
                    :key="cid.id"
                    @click="selectCid(cid)"
                  >
                    <span>{{ cid.descCid }}</span>
                    <span>{{ cid.id }}</span>
                  </div>
                </div>
              </iq-card>
            </b-col>
          </b-row>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <b-button type="submit" variant="primary"> Salvar </b-button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: ['data'],
  data() {
    return {
      debounceTimeout: null,
      form: {},
      cids: [],
      listDesfecho: [],
      searchCid: [],
      showCids: false,
      cid: '',
      loading: false
    }
  },
  async mounted() {
    this.$bvModal.show('modal-closure')
    this.listDesfecho = await this.getListaDesfechoAction()
    this.cids = await this.getCids()
    this.form = this.data
  },
  watch: {
    data(newValue) {
      this.form = newValue
    }
  },
  methods: {
    ...mapActions({
      getListaDesfechoAction: 'MedicalReports/getListaDesfechoAction',
      getCids: 'Forwardings/getCidsAction',
      addCoverSheetToHandBook: 'MedicalReports/addCoverSheetToHandBook'
    }),
    onSearchInput(value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        if (value.length >= 4) {
          this.searchCids(value)
        } else {
          return this.$toast.error(
            'Sua pesquisa tem que ter mais de 4 caracteres!'
          )
        }
      }, 1000)
    },
    searchCids(value) {
      this.loading = true
      this.searchCid = []
      this.searchCid = this.cids
      const listCidsFilter = this.searchCid.filter((cid) =>
        cid.descCid.toLowerCase().includes(value.toLowerCase())
      )
      this.searchCid = listCidsFilter
      if (this.searchCid.length === 0) {
        this.loading = false
        return this.$toast.error(
          'Nenhuma CID encontrada. Por favor tente novamente!'
        )
      } else {
        this.loading = false
        this.showCids = true
      }
    },
    resetModal() {
      this.$bvModal.hide('modal-closure')
      this.$emit('closeModal')
    },
    async desfechoPreNatal() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        return this.$toast.error('Verifique os campos obrigatórios!')
      }
      const result = await this.addCoverSheetToHandBook(this.form)
      if (result) {
        this.resetModal()
        return this.$toast.success('Gestação encerrada!')
      } else {
        return this.$toast.error(
          'Não foi possivel encerrar a gestação, verifique os dados e tente novamente!'
        )
      }
    },
    selectCid(value) {
      this.form.idCidDesfecho = value.id
      this.cid = `${value.id} - ${value.descCid}`
      this.showCids = false
    }
  },
  computed: {
    tipoEncerramento: {
      get() {
        if (this.form.idTipoDesfecho === 0) {
          return ''
        } else {
          return this.form.idTipoDesfecho
        }
      },
      set(newValue) {
        this.form.idTipoDesfecho = newValue
        if (newValue === 1) {
          this.form.descTipoDesfecho = 'NASCIDO VIVO, PARTO NORMAL'
        }
        if (newValue === 2) {
          this.form.descTipoDesfecho = 'NASCIDO VIVO, PARTO CESÁRIA'
        }
        if (newValue === 3) {
          this.form.descTipoDesfecho = 'ABORTO'
        }
        if (newValue === 4) {
          this.form.descTipoDesfecho = 'NASCIDO MORTO'
        }
      }
    }
  }
}
</script>
<style scoped>
.searchResults {
  top: 30px;
  z-index: 10;
  position: absolute;
  width: 700px;
  height: auto;
  max-height: 400px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
}

.headerSearch {
  display: grid;
  grid-template-columns: 6fr 1fr;
  height: 30px;
  gap: 5px;
  background: #089bab;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  color: #fff;
  font-weight: bold;
}

.patientItem {
  display: grid;
  grid-template-columns: 6fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  border-top: 1px solid #333;
  height: auto;
  padding: 10px;
}

.patientItem:hover {
  background: #d1edf0;
  cursor: pointer;
}

.searchCid {
  position: relative;
}

.input-cid {
  margin-bottom: 8px;
  padding: 8px;
  padding-right: 40px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: #c3c4c7 solid 1px;
}
.input-cid-error {
  margin-bottom: 8px;
  padding: 8px;
  padding-right: 40px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: red solid 1px;
}

.input-cid:focus {
  outline: none;
  border: #0cb1c4 solid 1px;
}

.searchCid button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 37%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
}

.searchCid i {
  margin-top: 6px;
  padding: 2px;
  color: #0cb1c4;
}
</style>
