<template>
  <b-modal
    id="add-info"
    size="lg"
    title="Laudo e informações do exame"
    header-bg-variant="primary"
    title-class="text-light"
    cancel-title="Cancelar"
    cancel-variant="danger"
    ok-title="Salvar"
    @hide="resetModal"
    @ok="addInfo"
    @close="resetModal"
    @cancel="resetModal"
  >
    <div>
      <div class="toolbar" style="margin-right: auto">
        <div class="fonts__container">
          <button
            class="fonts__item"
            v-on:click="execCommand('fontSize', '3', 'Fonts')"
          >
            12
          </button>
          <button
            class="fonts__item"
            @click="execCommand('fontSize', '4', 'Fonts')"
          >
            14
          </button>
          <button
            class="fonts__item"
            @click="execCommand('fontSize', '5', 'Fonts')"
          >
            16
          </button>
          <button
            class="fonts__item"
            @click="execCommand('fontSize', '6', 'Fonts')"
          >
            18
          </button>
        </div>

        <div class="box-options">
          <ul class="tool-list">
            <li class="tool">
              <button type="button" v-on:click="execCommand('bold')">
                <b-icon icon="type-bold" />
              </button>
            </li>
            <li class="tool">
              <button type="button" v-on:click="execCommand('italic')">
                <b-icon icon="type-italic" />
              </button>
            </li>
            <li class="tool">
              <button type="button" v-on:click="execCommand('underline')">
                <b-icon icon="type-underline" />
              </button>
            </li>
          </ul>
        </div>

        <div class="box-options">
          <ul class="tool-list">
            <li class="tool">
              <button type="button" v-on:click="execCommand('justifyLeft')">
                <i class="fas fa-align-left"></i>
              </button>
            </li>
            <li class="tool">
              <button type="button" v-on:click="execCommand('justifyCenter')">
                <i class="fas fa-align-center"></i>
              </button>
            </li>
            <li class="tool">
              <button type="button" v-on:click="execCommand('justifyRight')">
                <i class="fas fa-align-right"></i>
              </button>
            </li>
          </ul>
        </div>

        <div class="colors">
          <button
            class="colors__item colors__item--1"
            @click="execCommand('foreColor', '#091E42', 'Colors')"
          ></button>
          <button
            class="colors__item colors__item--2"
            @click="execCommand('foreColor', '#ff5630', 'Colors')"
          ></button>
          <button
            class="colors__item colors__item--3"
            @click="execCommand('foreColor', '#36b37e', 'Colors')"
          ></button>
          <button
            class="colors__item colors__item--4"
            @click="execCommand('foreColor', '#00b8d9', 'Colors')"
          ></button>
        </div>
      </div>
    </div>
    <div
      @input="(event) => updateContent(event)"
      @paste="handleCharactersLength"
      @keypress="handleCharactersLength"
      class="output"
      contenteditable="true"
      ref="infoRef"
    ></div>
    <p>{{ amountOfCharacters }} / 4000</p>

    <ul class="dropdowns-container">
      <li style="width: 30%">
        <label>Alterado:</label>
        <v-select
          v-model="alterationSelected"
          :options="createAlterationObjects()"
          label="descGrauAlteracao"
          :clearable="false"
        ></v-select>
      </li>
      <li style="width: 30%">
        <label>Repetição em:</label>
        <v-select
          v-model="repetitionSelected"
          :options="createRepetitionObjects()"
          label="description"
          :clearable="false"
        ></v-select>
      </li>
    </ul>
  </b-modal>
</template>

<script>
export default {
  name: 'InfoModal',
  props: ['exam'],
  data() {
    return {
      info: '',
      alterationSelected: {
        idGrauAlteracao: 0,
        descGrauAlteracao: 'Sem alterações'
      },
      repetitionSelected: {
        diaRepeticao: 0,
        description: 'Sem repetição'
      },
      alterationOptions: [
        'Sem alterações',
        'Alteração leve',
        'Alteração moderada',
        'Alteração severa'
      ],
      repetitionOptions: [0, 1, 30, 60, 90, 180, 360],
      amountOfCharacters: 0
    }
  },
  mounted() {
    this.$bvModal.show('add-info')
    this.recoverInfoHtml()
    this.recoverAlteration()
    this.recoverRepetition()
  },
  methods: {
    recoverAlteration() {
      if (this.exam.idGrauAlteracao) {
        this.alterationSelected = {
          idGrauAlteracao: this.exam.idGrauAlteracao,
          descGrauAlteracao: this.exam.descGrauAlteracao
        }
      }
    },
    recoverRepetition() {
      if (this.exam.diaRepeticao) {
        this.repetitionSelected = this.createRepetitionObject(
          this.exam.diaRepeticao
        )
      }
    },
    recoverInfoHtml() {
      if (this.exam.descLaudoInfo) {
        this.info = this.exam.descLaudoInfo

        this.$nextTick().then(() => {
          this.$refs.infoRef.innerHTML = this.info
        })
      }
    },
    createAlterationObject(element, index) {
      index -= 1

      if (index === -1) {
        index = null
      }

      return {
        idGrauAlteracao: index,
        descGrauAlteracao: element
      }
    },
    createAlterationObjects() {
      const alterationObjects = [...this.alterationOptions].map(
        (element, index) => {
          return this.createAlterationObject(element, index)
        }
      )

      return alterationObjects
    },
    createRepetitionObject(element) {
      const period =
        element === 0
          ? 'Sem repetição'
          : element === 1
          ? 'Imediata'
          : `${element} dias`

      return {
        diaRepeticao: element,
        description: period
      }
    },
    createRepetitionObjects() {
      const repetitionObjects = [...this.repetitionOptions].map((element) => {
        return this.createRepetitionObject(element)
      })

      return repetitionObjects
    },
    execCommand(command, value = null) {
      if (command === 'foreColor') {
        document.execCommand('styleWithCSS', false, true)
        document.execCommand(command, false, value)
        this.placeCaretAtEnd(this.$refs.complaintRef)
      } else {
        document.execCommand(command, false, value)
        this.placeCaretAtEnd(this.$refs.complaintRef)
      }
    },
    handleCharactersLength(event) {
      if (this.amountOfCharacters >= 4000) {
        event.preventDefault()
      }
    },
    updateContent(event) {
      this.amountOfCharacters = event.target.textContent.length
      if (this.amountOfCharacters >= 4000) {
        return
      }
      const info = event.target.innerHTML
      this.info = info
    },
    addInfo() {
      this.$emit('add-info', {
        info: this.info,
        idGrauAlteracao: this.alterationSelected.idGrauAlteracao,
        descGrauAlteracao: this.alterationSelected.descGrauAlteracao,
        diaRepeticao: this.repetitionSelected.diaRepeticao
      })
      this.resetModal()
    },
    resetModal() {
      this.$bvModal.hide('add-info')
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
.toolbar {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
}
.toolbar:not(:last-child) {
  margin-right: 10px;
}

.box-options {
  width: max-content;
}

.tool {
  border-right: 1px solid #ccc;
  padding-right: 4px;
  font-size: 18px;
}

.tool:not(:last-child) {
  border-right: 1px solid #ccc;
  padding-right: 4px;
}

.tool button {
  background-color: transparent;
  border: none;
}

.tool-list {
  padding: 0;
  margin: 0;
  display: flex;
}

.fontSize,
.fontFamily {
  border-radius: 50px;
  background-color: white;
  padding: 2px 5px;
}

.colors {
  &__item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 4px;
    border: none;
  }

  &__item--1 {
    background-color: #091e42;
  }
  &__item--2 {
    background-color: #ff5630;
  }
  &__item--3 {
    background-color: #36b37e;
  }
  &__item--4 {
    background-color: #00b8d9;
  }
}

.fonts {
  &__container {
    padding: 0;
    margin: 0;
    position: relative;
    width: max-content;
    border-right: 1px solid #ccc;
    padding-right: 4px;
  }

  &__text {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #000;
    p {
      margin: 0;
      margin-right: 2px;
    }
  }
}

.fonts {
  &__container {
    display: flex;
    background-color: white;
  }

  &__item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 4px;
    border: none;
  }
}

.output {
  height: 150px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 8px;
}

.active {
  background-color: #089bab !important;
  color: white;
}

.dropdowns-container {
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: space-around;
}

.dropdowns-container li label {
  padding-left: 4px;
}
</style>
