<template>
  <div class="quick-access-container">
    <ul class="quick-access-container__list">
      <li
        class="list__item"
        :key="index"
        v-for="(quickAccess, index) in quickAccesses"
      >
        <template v-if="withSubList">
          <button
            :title="quickAccess[titleProperty]"
            class="quick-access-container__button"
          >
            {{ quickAccess[titleProperty] }}
          </button>
          <ul class="sublist">
            <li
              class="sublist__item"
              @click="onQuickAccessChosen({ grau: 0, ...quickAccess })"
            >
              Leve
            </li>
            <li
              class="sublist__item"
              @click="onQuickAccessChosen({ grau: 1, ...quickAccess })"
            >
              Moderada
            </li>
            <li
              class="sublist__item"
              @click="onQuickAccessChosen({ grau: 2, ...quickAccess })"
            >
              Severa
            </li>
          </ul>
        </template>

        <button
          v-else
          :title="quickAccess[titleProperty]"
          class="quick-access-container__button"
          @click="onQuickAccessChosen(quickAccess)"
        >
          {{ quickAccess[titleProperty] }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'QuickAccess',
  props: ['quickAccesses', 'titleProperty', 'withSubList'],

  methods: {
    onQuickAccessChosen(data) {
      this.$emit('on-quick-access-chosen', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-access-container {
  margin: 4px 0;
  &__list {
    display: flex;
    padding: 0;
    margin: 0;
  }

  &__button {
    color: #000;
    background-color: transparent;
    border: none;
    height: 30px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
}

.list {
  &__item {
    position: relative;
    border: 1px solid #089bab;
    border-radius: 5px;

    margin: 0 3px;
    width: calc(100% / 5);

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover .sublist {
      display: block;
    }
  }
}

.sublist {
  position: absolute;
  display: none;
  z-index: 3;
  left: 0;
  top: 100%;
  padding: 0;
  text-align: center;
  width: 100%;
  min-width: 100px;
  color: #000;

  &__item {
    cursor: pointer;
    border-radius: 5px;
  }

  &__item:hover {
    transform: scale(1.02);
  }

  &__item:nth-child(1) {
    background-color: #abebc6;
    padding: 8px;
  }
  &__item:nth-child(2) {
    background-color: #f9e79f;
    padding: 8px;
  }
  &__item:nth-child(3) {
    background-color: #f5b7b1;
    padding: 8px;
  }
}
</style>
