<template>
  <b-modal
    id="modalFeedback"
    header-bg-variant="primary"
    title="Feedback do usuário"
    hide-footer
    size="lg"
    title-class="text-light"
    @close="close()"
    @hidden="close()"
  >
    <div class="container-feed">
      <p class="tank-you">
        Sua opinião é extremamente valiosa para nós. Estamos interessados em
        saber sobre a sua experiência com a Doctorpep.
      </p>
      <div class="box-buttons-emotis">
        <button
          :class="valueFeed === 1 ? 'btn-icon-active' : 'btn-icon'"
          @click="handleValueFeedBack(1)"
        >
          <img src="./imgs/feliz.png" alt="Icone de satisfeito" />
        </button>
        <button
          :class="valueFeed === 2 ? 'btn-icon-active' : 'btn-icon'"
          @click="handleValueFeedBack(2)"
        >
          <img src="./imgs/confuso.png" alt="icone de regular" />
        </button>
        <button
          :class="valueFeed === 3 ? 'btn-icon-active' : 'btn-icon'"
          @click="handleValueFeedBack(3)"
        >
          <img src="./imgs/bravo.png" alt="icone de insatisfeito" />
        </button>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: flex-start;
        "
      >
        <textarea
          v-model="textComent"
          :class="isValid ? 'text-feed-invalid' : 'text-feed'"
          placeholder="Envie para nos sua sugestão de melhoria!"
          name="textfeed"
          cols="10"
          rows="4"
        ></textarea>
        <span style="color: red; font-size: 0.8rem" v-if="isValid">
          Por favor dê sua opinião!</span
        >
      </div>

      <div class="box-button">
        <button class="button-feed" @click="saveFeedback()">Salvar</button>
        <b-spinner v-show="loading === true" small variant="ligth"></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted() {
    this.$bvModal.show('modalFeedback')
  },
  data() {
    return {
      isValid: false,
      loading: false,
      valueFeed: null,
      textComent: ''
    }
  },
  watch: {
    textComent(newValue) {
      if (newValue.length < 4) {
        this.isValid = true
      } else {
        this.isValid = false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  methods: {
    ...mapActions({
      setFeedbackUser: 'User/setFeedbackUser'
    }),
    async saveFeedback() {
      if (this.valueFeed === null || this.isValid === true) {
        if (this.valueFeed === null) {
          this.$toast.warning(
            'Por favor clique em um emoji para poder realizar seu feedback!!'
          )
        }
        if (this.isValid === true) {
          this.$toast.warning('Por favor deixe sua opinião!')
        }
      } else {
        let descFeed = ''
        if (this.valueFeed === 1) {
          descFeed = 'Satisfeito'
        } else if (this.valueFeed === 2) {
          descFeed = 'Regular'
        } else {
          descFeed = 'Insatisfeito'
        }
        const payload = {
          idUsuario: this.user.id,
          dtInclusao: new Date(),
          idValorSatisfacao: this.valueFeed,
          descSatisfacao: descFeed,
          descSugestao: this.textComent
        }
        this.loading = true
        const response = await this.setFeedbackUser(payload)

        if (response) {
          this.loading = false
          this.close()
          this.$toast.success(
            'Feedback envidado com sucesso. Sua opinião é essencial para nós. Obrigado por avaliar nossos serviços e compartilhar conosco sua opinão!'
          )
        } else {
          this.loading = false
          this.$toast.error(
            'Não foi possivel enviar seu feedback, tente novamente em alguns minutos!'
          )
        }
      }
    },
    handleValueFeedBack(value) {
      if (value === 3) {
        this.isValid = true
      } else {
        this.isValid = false
      }
      this.valueFeed = value
    },
    close() {
      this.$emit('closeFeed')
    }
  }
}
</script>
<style scoped>
.container-feed {
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 36px;
  padding: 20px;
}
.tank-you {
  text-align: center;
  font-weight: 600;
  font-style: oblique;
  color: #089cac;
}
.box-buttons-emotis {
  display: flex;
  gap: 10px;
  align-items: center;
}
.text-feed {
  width: 100%;
  padding: 6px 12px;
  border-radius: 10px;
}
.text-feed-invalid {
  width: 100%;
  border: solid red 1px;
  padding: 6px 12px;
  border-radius: 10px;
}
.button-feed {
  display: flex;
  gap: 6px;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #089cac;
  color: #fff;
  padding: 6px 12px;
}
.box-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-icon-active {
  background-color: transparent;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #089cac;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  padding: 10px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
</style>
