import API from '../../services/index'

export default {
  async getMedicalReportsForExport(context, object) {
    try {
      const response = await API.post(
        '/api/Prontuario/ObtemResultadoPacienteExportacao',
        {
          object
        }
      )

      return response.data.object
    } catch {
      return false
    }
  },

  async getMedicalReportsDataForExport(context, object) {
    try {
      const response = await API.post(
        '/api/Prontuario/ObtemProntuarioExportado',
        object
      )

      return response.data.object
    } catch {
      return false
    }
  },

  async getFileFromAwsAction(context, file) {
    try {
      const response = await API.get(
        `/api/ProntuarioArquivoImagem/AwsS3ObtemArquivo/?arquivoname=${file.descAwsArquivoImagem}&prontuario_id=${file.idProntuario}`,
        { responseType: 'blob' }
      )

      return response
    } catch {
      return false
    }
  }
}
