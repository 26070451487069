<template>
  <div class="info__container">
    <div class="info__main">
      <img
        @click="openUserInfoModal()"
        class="info__image"
        :src="
          patientInfo.avatar.readyState === 2
            ? patientInfo.avatar.result
            : patientInfo.idSexo === 1
            ? require('../../../assets/images/user/2.png')
            : require('../../../assets/images/user/1.jpg')
        "
        alt="User image"
      />
      <div class="name-and-age-container">
        <p class="info__name">{{ patientInfo.nome }}</p>
        <small class="info__age">{{ patientInfo.idadePaciente }} anos</small>
      </div>

      <div class="video-container" v-if="showVideoComponent">
        <video-tooltip
          :identification="videoIdentification"
          :patientEmail="patientInfo.email"
        />
      </div>
    </div>
    <div class="info__complementary">
      <!-- <p class="info__customer-since">
        Paciente Desde:
        <span>{{ formatDate(patientInfo.pacienteDesde, 'pt-BR') }}</span>
      </p> -->
      <!-- <p class="info__last-consult">
        Ultima consulta:
        <span>{{ formatDate(patientInfo.dataUltimaConsulta, 'pt-BR') }}</span>
      </p> -->
      <!-- <p class="info__total-consults">
        Total de consultas:
        <span>{{ patientInfo.quantidadeAgendamentoPaciente }}</span>
      </p> -->
      <p class="info__birthday">
        Nascimento:
        <span>{{
          patientInfo.dtNascimento
            ? formatDate(patientInfo.dtNascimento, 'pt-BR')
            : 'Não registrado'
        }}</span>
      </p>
      <p class="info__sex">
        Sexo:
        <span>{{ patientInfo.idSexo === 1 ? 'Masculino' : 'Feminino' }}</span>
      </p>
      <p class="info__civil-status">
        CPF: <span>{{ patientInfo.cpf }}</span>
      </p>
      <p class="info__religion">
        Identificação: <span>{{ patientInfo.id }}</span>
      </p>

      <p class="info__convenio">
        Convênio de consulta:
        <span>{{ patientInfo.convenioAtendimento }}</span>
      </p>

      <!-- <p class="info__mother-name">
        Nome da mãe: <span>{{ patientInfo.nomeMae }}</span>
      </p> -->

      <!-- <p class="info__observation">
        Observação:
        <span>{{ patientInfo.observacao }}</span>
      </p> -->
    </div>

    <div :class="isIOS ? 'icons-container-ios' : 'icons-container'">
      <allergies-tooltip :allergiesModule="allergiesModule" />

      <problems-tooltip :problemsModule="problemsModule" />
    </div>

    <button
      id="perception"
      type="button"
      @click="openPerceptionsModal()"
      :class="{
        info__button: true,
        'info__button--blue': !this.perceptions,
        'info__button--orange': this.perceptions
      }"
    >
      Percepções
    </button>

    <b-modal
      id="perceptions__modal"
      size="lg"
      title="Percepções"
      header-bg-variant="primary"
      header-text-variant="white"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Salvar"
      @ok="handleOkay"
      @shown="focusOnTextarea"
    >
      <clinic-report
        ref="perceptionsReport"
        :id="'perceptions__report'"
        :initial-content="this.perceptions"
        @update-content="this.updateContent"
      />
    </b-modal>
  </div>
</template>

<script>
import API from '../../../services/index'
import { parseToAscii, parseToHtml } from '../../../Utils/parsers'
import ClinicReport from '../shared/ClinicReport.vue'
import AllergiesTooltip from './tooltips/AllergiesTooltip.vue'
import ProblemsTooltip from './tooltips/ProblemsTooltip.vue'
import VideoTooltip from './tooltips/VideoToolTip'

export default {
  name: 'UserInfo',
  components: {
    ClinicReport,
    AllergiesTooltip,
    ProblemsTooltip,
    VideoTooltip
  },
  props: [
    'patientInfo',
    'idMedico',
    'allergiesModule',
    'problemsModule',
    'showVideoComponent',
    'videoIdentification'
  ],

  data() {
    return {
      perceptions: '',
      isIOS: false
    }
  },

  mounted() {
    if (this.isIOSOrMac()) {
      this.isIOS = true
    }
  },

  watch: {
    patientInfo(newValue, oldValue) {
      if (newValue.id && this.idMedico && newValue.id !== oldValue.id) {
        this.getPerceptionInformation(newValue.id, this.idMedico)
      }
    }
  },

  methods: {
    focusOnTextarea() {
      this.$nextTick(() => {
        const reportComponent = this.$refs.perceptionsReport
        if (reportComponent) {
          reportComponent.focusOnElement() // Chama o método de foco no componente ClinicReport
        }
      })
    },
    async handleOkay(bvModalEvt) {
      bvModalEvt.preventDefault()

      const description = parseToAscii(this.perceptions)

      await API.post('/api/ProntuarioPercepcoes/Salvar', {
        object: {
          idMedico: this.idMedico,
          idPaciente: this.patientInfo.id,
          descPercepcao: description,
          dtUltimaAtualizacao: new Date()
        }
      })

      this.$bvModal.hide('perceptions__modal')
    },
    isIOSOrMac() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|macintosh/i.test(userAgent)
    },
    async getPerceptionInformation(idPatient, idMedico) {
      try {
        const apiResponse = await API.get(
          `/api/ProntuarioPercepcoes/ObtemPercepcaoPorPaciente?id_paciente=${idPatient}&id_medico=${idMedico}`
        )

        if (
          apiResponse.data.object[0] &&
          apiResponse.data.object[0].descPercepcao
        ) {
          this.perceptions = parseToHtml(
            apiResponse.data.object[0].descPercepcao
          )
        }
      } catch (error) {}
    },
    formatDate(date, format) {
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString(format, { timeZone: 'UTC' })
    },
    openUserInfoModal() {
      this.$bvModal.show('user__modal')
    },

    async openPerceptionsModal() {
      this.$bvModal.show('perceptions__modal')
      this.$nextTick(() => {
        this.focusOnTextarea()
      })
    },

    /* update(data) {
      this.patientInfo = data
    }, */

    updateContent(content) {
      this.perceptions = content
    }
  }
}
</script>

<style>
#perceptions__modal h5 {
  color: #fff !important;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.info {
  &__container {
    padding: -10px;
    background-color: #ffffff;
    border-radius: 25px;
    display: grid;
    margin-top: -40px;
    grid-template-columns: 1fr 2fr 0.5fr 0.5fr;
    grid-template-rows: auto auto;
    grid-auto-flow: dense;
    font-size: 12px;
    color: black;
    width: calc(100% - 30px);

    transition: all 0.5s linear;

    border-style: solid;
    border-color: rgba($color: #089bab, $alpha: 1);
    border-width: 1px;

    div p span {
      display: block;
      color: #089bab;
      font-weight: 800;
    }

    div:not(:first-child) {
      padding-top: 20px;
    }
  }

  &__main {
    display: flex;
    padding-left: 10px;
  }

  &__complementary {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  &__sex {
    margin: 0;
  }

  &__name {
    font-weight: bold;
    margin: 0;
    color: #089bab;
    font-size: 14px;
  }

  &__age {
    font-size: 12px;
  }

  &__icon {
    justify-self: center;
    align-self: center;
  }

  &__image {
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent;
    padding: 5px;
    height: 75px;
    width: 75px;
  }

  &__button {
    padding: 8px 32px;
    border: none;
    outline: none;
    color: white;
    font-weight: bold;
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    font-size: 14px;
    border-radius: 10px;

    &--orange {
      background-color: orange;
    }

    &--blue {
      background-color: #089bab;
    }
  }
}

.name-and-age-container {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-container {
  margin-left: auto;
  margin-right: auto;
}

.perceptions-container {
  display: flex;
}

.icons-container {
  display: flex;
  position: relative;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.icons-container-ios {
  margin: 0px;
  padding: 0px;
  display: flex;
  position: relative;
  flex-direction: row;
}

#perception {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1600px) {
  .info {
    &__container {
      font-size: 11px;
    }

    &__name {
      font-size: 12px;
    }

    &__button {
      font-size: 12px;
      padding: 8px 28px;
    }

    &__button {
      font-size: 12px;
      padding: 8px 28px;
    }
  }
}

@media (max-width: 850px) {
  .info__complementary {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .info__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .info {
    &__container {
      font-size: 8px;
    }

    &__name {
      font-size: 9px;
    }

    &__button {
      font-size: 9px;
      padding: 6px 20px;
    }

    &__button {
      font-size: 9px;
      padding: 6px 20px;
    }
  }
  .icons-container {
    display: none;
  }

  .icons-container-ios {
    display: none;
  }
}

@media (max-width: 450px) {
  .info__complementary {
    display: none;
  }
  .icons-container {
    display: none;
  }
  #perception {
    display: none;
  }
  .info__main {
    width: 100%;
  }
  .name-and-age-container {
    width: 100%;
  }

  .info__age {
    width: 100%;
  }

  .info__name {
    width: 100%;
  }
  .info__container {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
</style>
