import { parseToHtml } from '../../../../Utils/parsers'
const PHYSICAL_EXAM_MODULE_ID = 6
const mutations = {
  loadPhysicalExams(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PHYSICAL_EXAM_MODULE_ID
    )

    payload.forEach((physicalExam) => {
      physicalExam.descExameFisico = parseToHtml(physicalExam.descExameFisico)
    })

    module.data = [...payload]
  },

  addPhysicalExam(state, { payload }) {
    state.localPhysicalExamsState = payload
  },

  removePhysicalExamFromState(state, { payload }) {
    state.localPhysicalExamsState = {}
  },

  editPhysicalExam(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PHYSICAL_EXAM_MODULE_ID
    )

    const physicalExamtIndex = module.data.findIndex(
      (conduct) => conduct.idProntuario === payload.idProntuario
    )

    module.data.splice(physicalExamtIndex, 1, { ...payload, modified: true })
  }
}

export default mutations
