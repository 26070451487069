<template>
  <b-modal
    id="graph-modal"
    :title="name || 'Gráfico'"
    title-class="text-light"
    header-bg-variant="primary"
    hide-footer
    size="xl"
    @close="closeGraph"
    no-close-on-backdrop
  >
    <div class="graph-container">
      <apexchart
        height="100%"
        width="100%"
        type="area"
        :series="chartSeries"
        :options="chartOptions"
      ></apexchart>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'GraphComponent',
  mounted() {
    this.$bvModal.show('graph-modal')
  },
  props: {
    name: {
      type: String
    },
    seriesData: {
      type: Array
    },
    xAxisData: {
      type: Array
    }
  },
  computed: {
    chartSeries() {
      const data = this.seriesData
      return [{ data }]
    },
    chartOptions() {
      return {
        xaxis: {
          categories: this.xAxisData
        }
      }
    }
  },
  methods: {
    closeGraph() {
      this.$emit('closeGraph')
    }
  }
}
</script>
<style scoped>
.graph-container {
  height: 50vh;
}
</style>
