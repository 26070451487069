/* eslint-disable no-unused-vars */
import API from '../../services/index'

export default {
  async addUserAction(context, payload) {
    const {
      bloqueado,
      cpf,
      emailPessoal,
      emailProfissional,
      id,
      idConselhoProfissional,
      idEstadoConselho,
      idSexo,
      identidade,
      idPerfil,
      login,
      nome,
      nomeMae,
      nomePai,
      nomeSocial,
      numeroConselho,
      senha,
      status,
      telCelular,
      telResidencial,
      usuariocbo,
      dtNascimento,
      visualizaCompartilhamento,
      prontuarioRestrito,
      responsavelTriagem,
      utilizaTriagem,
      cadastroInterno,
      alterarSenha,
      clinica_usuario: clinicaUsuario,
      localizacaoUsuario,
      receitaAutomatica,
      agendamentoRecorrente,
      feedbackAtendimento
    } = payload

    const clinicasExistentes = clinicaUsuario.map((element) => {
      return element.idClinica
    })

    const numCouncilInt = parseInt(numeroConselho)
    const authToken = `Bearer ${window.$cookies.get('token')}`
    let object
    if ([1, 4, 5].includes(idPerfil)) {
      object = {
        login,
        senha,
        nome,
        telCelular,
        emailPessoal,
        idSexo,
        dtNascimento,
        cpf,
        idConselhoProfissional,
        numeroConselho: numCouncilInt,
        idEstadoConselho,
        usuariocbo,
        bloqueado,
        emailProfissional,
        identidade,
        status,
        telResidencial,
        nomeMae,
        nomePai,
        nomeSocial,
        idPerfil,
        visualizaCompartilhamento,
        prontuarioRestrito,
        responsavelTriagem,
        utilizaTriagem,
        cadastroInterno,
        clinicasExistentes,
        alterarSenha,
        localizacaoUsuario,
        receitaAutomatica,
        agendamentoRecorrente,
        feedbackAtendimento
      }
    } else {
      object = {
        login,
        senha,
        nome,
        telCelular,
        emailPessoal,
        idSexo,
        dtNascimento,
        cpf,
        bloqueado,
        emailProfissional,
        identidade,
        status,
        telResidencial,
        nomeMae,
        nomePai,
        nomeSocial,
        idPerfil,
        responsavelTriagem,
        utilizaTriagem,
        cadastroInterno,
        clinicasExistentes,
        alterarSenha,
        localizacaoUsuario,
        receitaAutomatica,
        agendamentoRecorrente,
        feedbackAtendimento
      }
    }
    const userData = await API.post(
      '/api/Usuario/Adicionar',
      {
        object
      },
      { headers: { Authorization: authToken } }
    )

    context.commit('editUserCommit', userData)
  },

  async editUserAction(context, payload) {
    if (payload.senha === null) {
      const {
        aceitoTermo,
        bloqueado,
        cpf,
        emailPessoal,
        emailProfissional,
        id,
        idConselhoProfissional,
        idEstadoConselho,
        idSexo,
        identidade,
        idPerfil,
        login,
        nome,
        nomeMae,
        nomePai,
        nomeSocial,
        numeroConselho,
        status,
        telCelular,
        telResidencial,
        usuariocbo,
        dtNascimento,
        visualizaCompartilhamento,
        prontuarioRestrito,
        responsavelTriagem,
        utilizaTriagem,
        alterarSenha,
        localizacaoUsuario,
        receitaAutomatica,
        agendamentoRecorrente,
        feedbackAtendimento
      } = payload
      const numCouncilInt = parseInt(numeroConselho)
      const authToken = `Bearer ${window.$cookies.get('token')}`
      const userData = await API.put(
        '/api/Usuario/Editar',
        {
          object: {
            aceitoTermo,
            login,
            nome,
            telCelular,
            emailPessoal,
            idSexo,
            dtNascimento,
            cpf,
            idConselhoProfissional,
            numeroConselho: numCouncilInt,
            idEstadoConselho,
            usuariocbo,
            bloqueado,
            emailProfissional,
            identidade,
            status,
            telResidencial,
            id,
            nomeMae,
            nomePai,
            nomeSocial,
            idPerfil,
            visualizaCompartilhamento,
            prontuarioRestrito,
            responsavelTriagem,
            utilizaTriagem,
            alterarSenha,
            localizacaoUsuario,
            receitaAutomatica,
            agendamentoRecorrente,
            feedbackAtendimento
          }
        },
        { headers: { Authorization: authToken } }
      )
      context.commit('editUserCommit', userData)
    } else {
      const {
        aceitoTermo,
        bloqueado,
        cpf,
        emailPessoal,
        emailProfissional,
        id,
        idConselhoProfissional,
        idEstadoConselho,
        idSexo,
        identidade,
        idPerfil,
        login,
        nome,
        nomeMae,
        nomePai,
        nomeSocial,
        numeroConselho,
        senha,
        status,
        telCelular,
        telResidencial,
        usuariocbo,
        dtNascimento,
        visualizaCompartilhamento,
        prontuarioRestrito,
        responsavelTriagem,
        utilizaTriagem,
        alterarSenha,
        localizacaoUsuario,
        receitaAutomatica,
        agendamentoRecorrente,
        feedbackAtendimento
      } = payload
      const numCouncilInt = parseInt(numeroConselho)
      const authToken = `Bearer ${window.$cookies.get('token')}`
      const userData = await API.put(
        '/api/Usuario/Editar',
        {
          object: {
            aceitoTermo,
            login,
            senha,
            nome,
            telCelular,
            emailPessoal,
            idSexo,
            dtNascimento,
            cpf,
            idConselhoProfissional,
            numeroConselho: numCouncilInt,
            idEstadoConselho,
            usuariocbo,
            bloqueado,
            emailProfissional,
            identidade,
            status,
            telResidencial,
            id,
            nomeMae,
            nomePai,
            nomeSocial,
            idPerfil,
            visualizaCompartilhamento,
            prontuarioRestrito,
            responsavelTriagem,
            utilizaTriagem,
            alterarSenha,
            localizacaoUsuario,
            receitaAutomatica,
            agendamentoRecorrente,
            feedbackAtendimento
          }
        },
        { headers: { Authorization: authToken } }
      )
      context.commit('editUserCommit', userData)
    }
  },

  async getUserClinicsAction(context, userId) {
    const response = await API.get(
      `/api/UsuarioClinica/ObtemClinicaPorUsuario/${userId}`
    )

    return response.data.object
  },

  async getUsersByClinicAction(context, clinicId) {
    const response = await API.get(
      `/api/UsuarioClinica/ObtemUsuarioPorClinica/${clinicId}`
    )

    return response.data.object
  },

  async renewPasswordAction(context, payload) {
    try {
      await API.post(
        `/api/Usuario/RenovaSenha?id_usuario=${payload.idUsuario}&nova_senha=${payload.senha}`
      )
      this.$toast.success('Login realizado com sucesso!')
    } catch (error) {
      return this.$toast.error(error.response.data.message)
    }
  },

  async getInfosTourOfUser({ state }, payload) {
    try {
      const response = await API.get('/api/UsuarioTour/ObtemItemPorUsuario', {
        params: {
          id_usuario: payload
        }
      })
      state.isPrimaryAcess = response.data.object
      return response.data.object
    } catch (error) {
      return false
    }
  },
  async setInfosTourOfUser({ state }, payload) {
    try {
      const response = await API.put('/api/UsuarioTour/Editar', {
        appName: 'DoctorPep',
        appVersion: '1.0.0',
        object: {
          ...payload,
          id: state.isPrimaryAcess.id
        }
      })
      state.isPrimaryAcess = {
        id: state.isPrimaryAcess.id,
        ...payload
      }
    } catch (error) {
      return error
    }
  },
  async setFeedbackUser({ state }, payload) {
    try {
      await API.post('/api/SatisfacaoUsuario/Salvar', {
        appName: 'DoctorPep',
        appVersion: '1.9.10',
        object: {
          ...payload
        }
      })
      return true
    } catch (error) {
      return false
    }
  },
  async getValueService({ state }, payload) {
    try {
      const response = await API.get(
        'api/UsuarioServicoValor/ObtemItemServicoPorCodigo',
        {
          params: {
            id_usuario: payload.idUsuario,
            id_convenio: payload.idConvenio,
            codigo_servico: payload.idService
          }
        }
      )
      return response.data.object
    } catch (err) {
      return false
    }
  }
}
