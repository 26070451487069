import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import Layout1 from '../layouts/Layout1'
// import Default from '../layouts/BlankLayout'
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'
/* Dashboards View */
import Dashboard1 from '../views/Dashboards/Dashboard1.vue'
import MedicalReportPage from '../views/MedicalReport/MedicalReport.vue'
import Finance from '../views/Finance/Finance.vue'
import RelatorioFinance from '../views/Finance/RelatorioFinance.vue'

/* Authentic View */
import SignIn from '../views/AuthPages/SignIn'
import PromotionalCode from '../views/AuthPages/PromotionalCode.vue'
import SignUp from '../views/AuthPages/SignUp'
import SignUpFormPage1 from '../views/AuthPages/Forms/SignUpFormPage1'
import SignUpFormPage2 from '../views/AuthPages/Forms/SignUpFormPage2'

import LoginConfirmation from '../views/AuthPages/LoginConfirmation.vue'
import ConfirmacaoAgendamento from '../views/ConfirmacaoAgendamento'
import FeedbackPaciente from '../views/FeedbackPaciente'
import LockScreen from '../views/AuthPages/LockScreen'
import ConfirmMail from '../views/AuthPages/ConfirmMail'
/* Extra Pages */
import Telemedicine from '../views/Telemedicine'
import ErrorPage from '../views/Pages/ErrorPage'
import Calendar from '../views/Calendar/Calendar'
/* Register View */
import AddPatient from '../views/Patient/AddPatient'
import AddClinica from '../views/Clinicas/AddClinica'
import AddUser from '../views/User/AddUser'
import AddHealthInsurance from '../views/HealthInsurance/AddHealthInsurance'
import AddSchedules from '../views/Schedules/index'
import AtendimentoOnline from '@/views/AtendimentoOnline/AtendimentoOnline.vue'

/* Todo */
import Callback from '../views/AuthPages/Callback'

Vue.use(VueRouter)

const childRoutes = (prop, mode) => [
  {
    path: 'home-1',
    name: prop + '.home-1',
    meta: { dark: mode, auth: true, name: 'Home 1' },
    component: Dashboard1
  },
  {
    path: '/',
    name: prop + '.home-1',
    meta: { dark: mode, auth: true, name: 'Home 1' },
    component: Dashboard1
  }
]

const authChildRoutes = (prop, mode = false) => [
  {
    path: 'sign-in',
    name: prop + '.sign-in',
    meta: { dark: mode, auth: true },
    component: SignIn
  },
  {
    path: 'promocao',
    name: prop + '.promotion',
    meta: { dark: mode, auth: false },
    component: PromotionalCode
  },
  {
    path: 'sign-up',
    name: prop + '.sign-up',
    meta: { dark: mode, auth: true },
    component: SignUp,
    children: [
      {
        path: 'page-form1',
        name: 'page-form1',
        component: SignUpFormPage1
      },
      {
        path: 'page-form2',
        name: 'page-form2',
        component: SignUpFormPage2
      }
    ]
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    meta: { dark: mode, auth: true },
    component: () => import('../views/AuthPages/Forms/RecoverPasswordPage1.vue')
  },
  {
    path: 'RecuperarSenha',
    name: prop + '.recuperar-senha',
    meta: { dark: mode, auth: true },
    component: () => import('../views/AuthPages/Forms/RecoverPasswordPage2.vue')
  },
  {
    path: 'ConfirmacaoLogin/:id',
    name: prop + '.confirmacao-login',
    meta: { dark: mode, auth: true },
    component: LoginConfirmation
  },
  {
    path: 'lock-screen',
    name: prop + '.lock-screen',
    meta: { dark: mode, auth: true },
    component: LockScreen
  },
  {
    path: 'confirm-mail',
    name: prop + '.confirm-mail',
    meta: { dark: mode, auth: true },
    component: ConfirmMail
  }
]

const pagesChildRoutes = (prop, mode = false) => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { dark: mode, auth: true },
    component: ErrorPage
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    meta: { dark: mode, auth: true },
    component: () => import('../views/Pages/ComingSoon')
  },
  {
    path: 'agenda',
    name: prop + '.calendar',
    meta: { dark: mode, auth: true },
    component: Calendar
  },
  {
    path: 'assinaturas-pendentes',
    name: prop + '.pending-assigns',
    meta: { dark: mode, auth: true },
    component: () => import('../views/PendingAssigns')
  },
  {
    path: 'exportar-pdf',
    name: prop + '.export-pdf',
    meta: { dark: mode, auth: true },
    component: () => import('../views/ExportPdf')
  },
  {
    path: 'agenda-atendimento',
    name: prop + '.agenda-atendimento',
    meta: { dark: mode, auth: true },
    component: () =>
      import('../views/AgendaAtendimento/ScheduleAndTreatment.vue')
  }
]

const financeRoutes = (prop, mode = false) => [
  {
    path: 'controlFinance',
    name: prop + '.controlFinance',
    meta: { dark: mode, auth: true },
    component: Finance
  },
  {
    path: 'relatorioFinance',
    name: prop + '.relatorioFinance',
    meta: { auth: true },
    component: RelatorioFinance
  }
]

const medicalReportRoutes = (prop, mode = false) => [
  {
    path: '/:idPaciente/:idUsuario/:identificacao/:scheduleId',
    props: true,
    name: prop + '.prontuario',
    meta: { dark: mode, auth: true },
    component: MedicalReportPage
  }
]

const registerChildRoute = (prop, mode = false) => [
  {
    path: 'add-paciente',
    name: prop + '.addPatient',
    meta: { dark: mode, auth: true, name: 'Add Paciente' },
    component: AddPatient
  },
  {
    path: 'add-clinica',
    name: prop + '.addClinic',
    meta: { dark: mode, auth: true, name: 'Add Clinic' },
    component: AddClinica
  },
  {
    path: 'add-usuario',
    name: prop + '.addUser',
    meta: { dark: mode, auth: true, name: 'Add User' },
    component: AddUser
  },
  {
    path: 'add-convenio',
    name: prop + '.addHealthInsurance',
    meta: { dark: mode, auth: true, name: 'Add HealthInsurance' },
    component: AddHealthInsurance
  },
  {
    path: 'add-horario',
    name: prop + '.schedules',
    meta: { dark: mode, auth: true, name: 'Add Schedules' },
    component: AddSchedules
  },
  {
    path: 'add-formulario',
    name: prop + '.addForm',
    meta: { dark: mode, auth: true, name: 'Add Form' },
    component: () => import('@/views/AddForms')
  }
]

const routes = [
  {
    path: '/telemedicina/:identificacao',
    props: true,
    name: 'telemedicina',
    component: Telemedicine,
    meta: { auth: false }
  },
  {
    path: '/AgendamentoOnline/:idClinica/:idMedico',
    props: true,
    name: 'agendamento-online',
    component: AtendimentoOnline,
    meta: { auth: false }
  },
  {
    path: '/FeedbackAtendimento/:id',
    props: true,
    name: 'feedback-paciente',
    component: FeedbackPaciente,
    meta: { auth: false }
  },
  {
    path: '/ConfirmacaoAgendamento/:id',
    props: true,
    name: 'confirmacao-agendamento',
    component: ConfirmacaoAgendamento,
    meta: { auth: false }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Layout1,
    meta: { auth: true },
    children: childRoutes('dashboard')
  },
  {
    path: '/prontuario',
    name: 'prontuario',
    component: Layout1,
    meta: { auth: true },
    children: medicalReportRoutes('prontuario')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout1,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/pages',
    name: 'pages',
    component: Layout1,
    meta: { auth: true },
    children: pagesChildRoutes('pages')
  },
  {
    path: '/register',
    name: 'register',
    component: Layout1,
    meta: { auth: true },
    children: registerChildRoute('register')
  },
  {
    path: '/finance',
    name: 'finance',
    component: Layout1,
    meta: { auth: true },
    children: financeRoutes('finance')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

/* router.beforeEach((to, from, next) => {
  const isAuthenticated = window.$cookies.isKey('token')

  if (isAuthenticated) {
    to.meta.redirectHomeAuth ? next('/') : next()
  } else {
    to.meta.requiresAuth ? next('/auth/sign-in') : next()

    !to.path.includes('/auth') && !to.path.includes('/telemedicina')
      ? next('/auth/sign-in')
      : next()

    const pathsToIgnore = ['/auth', '/telemedicina']

    const verify = pathsToIgnore.some((path) => {
      if (to.path.includes(path)) {
        next()
        return true
      } else {
        return false
      }
    })

    if (!verify) {
      if (to.path !== '/') {
        Vue.$toast.info(
          'Token de acesso inválido ou expirado, faça login novamente'
        )
      }

      next('/auth/sign-in')
    }
  }
}) */

export default router
