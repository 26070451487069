var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"size":"md","id":"modal-renew","title":"Defina sua senha","title-class":"text-light","header-bg-variant":"primary","no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","cancel-title":"Cancelar","cancel-variant":"danger"},on:{"close":_vm.resetModal}},[_c('div',{staticClass:"closure"},[_c('b-col',{staticStyle:{"gap":"20px"}},[_c('div',{staticClass:"form-group2"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Senha:")]),_c('div',{staticClass:"sameLevelWithIcon"},[((_vm.passwordFieldType ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control mb-0",style:(_vm.active
                ? 'outline: none; border: 1px solid red; text-transform: none !important'
                : 'text-transform: none !important'),attrs:{"placeholder":"Digite sua senha...","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"click":function($event){return _vm.activeOrietetion()},"focus":_vm.openTooltip,"focusout":_vm.closeTooltip,"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.passwordFieldType ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control mb-0",style:(_vm.active
                ? 'outline: none; border: 1px solid red; text-transform: none !important'
                : 'text-transform: none !important'),attrs:{"placeholder":"Digite sua senha...","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"click":function($event){return _vm.activeOrietetion()},"focus":_vm.openTooltip,"focusout":_vm.closeTooltip,"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control mb-0",style:(_vm.active
                ? 'outline: none; border: 1px solid red; text-transform: none !important'
                : 'text-transform: none !important'),attrs:{"placeholder":"Digite sua senha...","type":_vm.passwordFieldType ? 'password' : 'text'},domProps:{"value":(_vm.password)},on:{"click":function($event){return _vm.activeOrietetion()},"focus":_vm.openTooltip,"focusout":_vm.closeTooltip,"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('i',{class:_vm.passwordFieldType ? 'fas fa-eye' : 'fas fa-eye-slash',on:{"click":function($event){_vm.passwordFieldType = !_vm.passwordFieldType}}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"8px"}},[(_vm.active)?_c('span',{staticStyle:{"color":"red","font-size":"0.7rem","margin-top":"2px"}},[_vm._v(" "+_vm._s(_vm.errorMensage)+" ")]):_vm._e(),(_vm.isRule)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"4px","align-items":"flex-start"}},[_c('span',{staticStyle:{"font-size":"0.7rem","margin-top":"2px"}},[_vm._v("A senha precisa conter:")]),_c('div',{staticStyle:{"display":"flex","font-size":"0.7rem","flex-direction":"column","gap":"2px","align-items":"flex-start"}},[_c('span',{style:(_vm.rule1
                      ? 'text-decoration: line-through; color: black'
                      : 'color: red')},[_vm._v("8 digitos")]),_c('span',{style:(_vm.rule2
                      ? 'text-decoration: line-through; color: black'
                      : 'color: red')},[_vm._v("1 caractere especial (como !,@,#, etc.)")]),_c('span',{style:(_vm.rule3
                      ? 'text-decoration: line-through; color: black'
                      : 'color: red')},[_vm._v("1 letra maíuscula")]),_c('span',{style:(_vm.rule4
                      ? 'text-decoration: line-through; color: black'
                      : 'color: red')},[_vm._v("1 numero")])])]):_vm._e()])])]),_c('div',{staticClass:"form-group2"},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Confirme a senha:")]),_c('div',{staticClass:"sameLevelWithIcon"},[((_vm.confirmPasswordFieldType ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control mb-0",style:(_vm.activeConfirm
                ? 'outline: none; border: 1px solid red; text-transform: none !important'
                : 'text-transform: none !important'),attrs:{"placeholder":"Confirme a senha...","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.confirmPassword)?_vm._i(_vm.confirmPassword,null)>-1:(_vm.confirmPassword)},on:{"change":function($event){var $$a=_vm.confirmPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.confirmPassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.confirmPassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.confirmPassword=$$c}}}}):((_vm.confirmPasswordFieldType ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control mb-0",style:(_vm.activeConfirm
                ? 'outline: none; border: 1px solid red; text-transform: none !important'
                : 'text-transform: none !important'),attrs:{"placeholder":"Confirme a senha...","type":"radio"},domProps:{"checked":_vm._q(_vm.confirmPassword,null)},on:{"change":function($event){_vm.confirmPassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control mb-0",style:(_vm.activeConfirm
                ? 'outline: none; border: 1px solid red; text-transform: none !important'
                : 'text-transform: none !important'),attrs:{"placeholder":"Confirme a senha...","type":_vm.confirmPasswordFieldType ? 'password' : 'text'},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}}),_c('i',{class:_vm.confirmPasswordFieldType ? 'fas fa-eye' : 'fas fa-eye-slash',on:{"click":function($event){_vm.confirmPasswordFieldType = !_vm.confirmPasswordFieldType}}}),(_vm.activeConfirm)?_c('span',{staticStyle:{"color":"red","font-size":"0.7rem","margin-top":"2px"}},[_vm._v(" "+_vm._s(_vm.errorMensageConfirm)+" ")]):_vm._e()])]),_c('div',{staticClass:"box-buttons"},[_c('button',{staticClass:"button-cancel",on:{"click":_vm.resetModal}},[_vm._v("Cancelar")]),_c('button',{class:this.password === '' || this.confirmPassword === ''
              ? 'button-renew-disabled'
              : 'button-renew',attrs:{"disabled":this.password === '' || this.confirmPassword === '' ? true : false},on:{"click":function($event){return _vm.renewPassword()}}},[_vm._v(" Salvar ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }