import API from '../../services/index'

export default {
  async saveFormCompletyAction({ state }, payload) {
    try {
      if (payload === '') {
        await API.post('/api/Formulario/Salvar', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...state.form,
            pergunta_formulario: state.pergunta_formulario
          }
        })
      } else {
        await API.post('/api/Formulario/Salvar', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...state.formEdit,
            pergunta_formulario: state.pergunta_formulario_edit
          }
        })
      }
      return true
    } catch (e) {
      return false
    }
  },

  async saveFormAction({ commit }, object) {
    try {
      if (object.id) {
        commit('setFormEdit', object)
        return object.id
      } else {
        commit('setForm', object)
        return ''
      }
    } catch (e) {
      return false
    }
  },
  async checkedIsExistCertifyAction(context, payload) {
    try {
      await API.get('/api/IntegracaoEval/ObtemItemCertificado', {
        params: {
          cpf: payload.cpf,
          type: 'qualified'
        }
      })
      return true
    } catch (error) {
      return false
    }
  },

  async saveQuestionAction({ commit }, payload) {
    try {
      const { idTipoResposta } = payload

      const { property } = getQuestionPropertyName(idTipoResposta)

      const name = `opcao_resposta_${property}`

      const object = { ...payload, [name]: payload.answers }

      delete object.answers

      if (object.idFormulario) {
        commit('setQuestionsInFormEdit', object)
      } else {
        commit('setQuestionsInForm', object)
      }
    } catch (e) {
      return false
    }
  },

  async getCompleteFormAction(context, id) {
    try {
      const response = await API.get(
        '/api/Formulario/ObtemFormularioCompleto',
        {
          params: {
            id_formulario: id
          }
        }
      )

      const fullForm = response.data.object

      const questions = fullForm.pergunta_formulario

      questions.map((element) => {
        const { property, all } = getQuestionPropertyName(
          element.idTipoResposta
        )

        const name = `opcao_resposta_${property}`

        element.answers = element[name]

        all.forEach((obj) => {
          delete element[`opcao_resposta_${obj.name}`]
        })

        return element
      })

      fullForm.pergunta_formulario = questions

      return fullForm
    } catch {
      return false
    }
  },

  async getFormAnswersAction(context, id) {
    try {
      const response = await API.get(
        `/api/ProntuarioFormularioAvaliacao/ObtemItem/${id}`
      )

      return response.data.object.listaRespostaFormularioAvaliacao
    } catch (e) {
      return false
    }
  },

  async getFormsAction(context, idClinic) {
    try {
      const response = await API.get('/api/Formulario/ObtemListaPorClinica', {
        params: {
          id_clinica: idClinic
        }
      })
      return response.data
    } catch (e) {}
  },

  async getFormsPreferencesAction(context, idClinic, idModule) {
    try {
      const apiResponse = await API.get(
        '/api/FavoritoFormularioAvaliacao/ObtemListaPorFormulario',
        {
          params: {
            id_prontuario_preferencia: idModule,
            id_clinica: idClinic
          }
        }
      )

      return apiResponse.data.object
    } catch (e) {
      return false
    }
  },

  async getQuestionsAction(context, id) {
    try {
      const response = await API.get(
        '/api/FormularioPergunta/ObtemPerguntasPorFormulario',
        { params: { id_formulario: id } }
      )

      const questions = response.data.object

      questions.map((element) => {
        const { property, all } = getQuestionPropertyName(
          element.idTipoResposta
        )

        const name = `opcao_resposta_${property}`

        element.answers = element[name]

        all.forEach((obj) => {
          delete element[`opcao_resposta_${obj.name}`]
        })

        return element
      })

      return questions
    } catch (e) {
      return false
    }
  },

  async getAnswerTypesAction() {
    try {
      const response = await API.get(
        '/api/FormularioTipoResposta/ObtemListaTipoRespostaAtivas'
      )

      return response.data.object
    } catch (e) {
      return false
    }
  },

  clearStateAction({ state }) {
    state.form = {}
    state.formEdit = {}
    state.pergunta_formulario = []
    state.pergunta_formulario_edit = []
  },

  async deleteQuestionAction(context, payload) {
    try {
      await API.delete('/api/FormularioPergunta/Remover', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          ...payload
        }
      })
    } catch (error) {
      return error
    }
  },

  async deleteResponseAction(context, payload) {
    try {
      if (payload.tipoResposta === 1) {
        await API.delete('/api/FormularioRespostaTexto/Remover', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload.choice
          }
        })
      } else if (payload.tipoResposta === 2) {
        await API.delete('/api/FormularioRespostaNumerico/Remover', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload.choice
          }
        })
      } else if (payload.tipoResposta === 3) {
        await API.delete('/api/FormularioRespostaMultiplaEscolha/Remover', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload.choice
          }
        })
      } else if (payload.tipoResposta === 4) {
        await API.delete('/api/FormularioRespostaCaixaSelecao/Remover', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload.choice
          }
        })
      } else if (payload.tipoResposta === 5) {
        await API.delete('/api/FormularioRespostaListaSuspensa/Remover', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload.choice
          }
        })
      } else {
        await API.delete('/api/FormularioRespostaDataHora/Remover', {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload.choice
          }
        })
      }
    } catch (error) {
      return error
    }
  }
}

function getQuestionPropertyName(id) {
  const propertyNames = [
    {
      name: 'texto',
      questionTypeId: 1
    },
    {
      name: 'numerico',
      questionTypeId: 2
    },
    {
      name: 'multipla_escolha',
      questionTypeId: 3
    },
    {
      name: 'caixa_selecao',
      questionTypeId: 4
    },
    {
      name: 'lista_suspensa',
      questionTypeId: 5
    },
    {
      name: 'data_hora',
      questionTypeId: 6
    }
  ]

  const isTheProperty = propertyNames.find(
    (element) => element.questionTypeId === id
  )

  return {
    property: isTheProperty.name,
    all: propertyNames
  }
}
