<template>
  <b-container
    fluid
    :style="`${expanded === 0 ? 'container-fluid' : 'height: 0;'} `"
  >
    <b-table-simple hover>
      <b-thead head-variant="primary">
        <b-tr class="table__header">
          <b-th :style="`background-color: ${headerBackground}`">Data</b-th>
          <b-th :style="`background-color: ${headerBackground}`"
            >Profissional</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`">CID</b-th>
          <b-th :style="`background-color: ${headerBackground}`"
            >Descrição</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`">Grau</b-th>
          <b-th :style="`background-color: ${headerBackground}`"
            >Observação</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`">Ativo</b-th>
          <b-th :style="`background-color: ${headerBackground}`">Ação</b-th>
        </b-tr>
      </b-thead>

      <b-tbody class="table__body">
        <b-tr
          :key="problem.id"
          v-for="problem in items"
          :title="
            problem.justificativaInativacao
              ? 'Justificativa:  ' + problem.justificativaInativacao
              : ''
          "
        >
          <b-td
            :class="[
              'table__date',
              problem.ativo === 1 ? 'line-throughed' : ''
            ]"
            >{{ formatDate(problem.dtOcorrencia, 'pt-BR') }}</b-td
          >
          <b-td :class="problem.ativo === 1 ? 'line-throughed' : ''">{{
            problem.nmProfissional
          }}</b-td>
          <b-td :class="problem.ativo === 1 ? 'line-throughed' : ''">{{
            problem.codCid
          }}</b-td>
          <b-td :class="problem.ativo === 1 ? 'line-throughed' : ''">{{
            problem.descCid
          }}</b-td>
          <b-td>
            <span :class="'table__degree ' + getBackground(problem.descGrau)">
              {{ problem.descGrau }}
            </span>
          </b-td>
          <b-td :title="problem.observacao">
            <b-form-input
              type="text"
              :value="problem.observacao ? problem.observacao.trim() : ''"
              @change="updateField(problem, 'observacao', $event)"
            />
          </b-td>
          <b-td>
            <b-form-checkbox
              disabled
              :checked="problem.ativo === 0"
            ></b-form-checkbox>
          </b-td>
          <b-td>
            <b-button
              style="width: 60%"
              @click="
                problem.ativo === 1
                  ? updateActivity(problem)
                  : problem.ativo !== 1 && problem.acao === 'Remover'
                  ? handleClick(problem)
                  : $bvModal.show(`${moduleId}_${problem.id}`)
              "
              :variant="getActionVariant(problem.acao)"
              >{{ problem.acao }}</b-button
            >
            <b-modal
              :id="`${moduleId}_${problem.id}`"
              size="lg"
              title="Justificativa"
              header-bg-variant="primary"
              title-class="text-light"
              label-for="justify-input"
              ok-title="Salvar"
            >
              <b-form-group label-for="justify-input">
                <ValidationProvider
                  name="justify"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="justify-input"
                    maxlength="150"
                    v-model="justify"
                    :class="
                      errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                    "
                  ></b-form-textarea>
                  <div class="invalid-feedback" :v-if="justifyNotValid">
                    <span
                      >A justificativa deve conter no mínimo 5 digitos!</span
                    >
                  </div>
                </ValidationProvider>
              </b-form-group>

              <p>{{ justify.length }}/150</p>
              <template #modal-footer="{ cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                  Cancelar
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="handleClick(problem)"
                >
                  Salvar
                </b-button>
              </template>
            </b-modal>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProblemsTable',
  props: ['headerBackground', 'moduleId', 'tableData', 'idMedico'],
  data() {
    return {
      justify: ''
    }
  },
  computed: {
    ...mapGetters('MedicalReports', {
      data: 'getProblemData',
      expanded: 'getIsExpanded'
    }),
    items() {
      const copy = this.tableData.filter((problem) => !problem.deleted)
      copy.sort((prevValue, currentValue) => {
        return prevValue.isNew
          ? -1
          : prevValue.ativo === currentValue.ativo
          ? 0
          : prevValue.ativo === 0
          ? -1
          : 1
      })

      return copy
    },
    justifyNotValid() {
      return this.justify.length < 5
    }
  },

  methods: {
    selectProblemToDisable(data) {
      if (this.justifyNotValid) {
        return
      }

      const object = {
        ...data,
        justificativaInativacao: this.justify,
        idUsuarioInativacao: this.idMedico,
        dtInativacao: new Date().toISOString()
      }

      this.$store.commit({
        type: 'MedicalReports/inactivateProblem',
        payload: object
      })

      this.justify = ''
      this.$bvModal.hide(`${this.moduleId}_${data.id}`)
    },
    getActionVariant(action) {
      return action !== 'Ativar' ? 'danger' : 'primary'
    },
    getBackground(degree) {
      switch (degree.toLowerCase()) {
        case 'leve': {
          return 'table__degree--light'
        }
        case 'moderada': {
          return 'table__degree--medium'
        }
        case 'severo': {
          return 'table__degree--severe'
        }
      }
    },

    formatDate(date, format) {
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString(format)
    },

    updateField(problem, field, value) {
      const payload = {
        ...problem,
        [field]: value
      }
      this.$store.commit({
        type: 'MedicalReports/updateProblemField',
        payload
      })
    },

    activateProblem(dataId) {
      this.$store.commit({
        type: 'MedicalReports/activateProblem',
        payload: { moduleId: this.moduleId, dataId }
      })
    },
    updateActivity(data) {
      if (data.ativo === 0) {
        this.$store.commit({
          type: 'MedicalReports/inactivateProblem',
          payload: data
        })
      } else {
        this.$store.commit({
          type: 'MedicalReports/activateProblem',
          payload: data
        })
      }
    },
    handleClick(data) {
      if (data.acao === 'Remover') {
        this.$store.commit({
          type: 'MedicalReports/removeProblem',
          payload: data
        })
      } else if (data.acao === 'Inativar') {
        this.selectProblemToDisable(data)
      } else if (data.acao === 'Ativar') {
        this.$store.commit({
          type: 'MedicalReports/activateProblem',
          payload: data
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0 !important;
  height: 35vh;
  background-color: white;
  border-radius: 5px;
  overflow-y: scroll !important;
}

.table {
  &__header {
    color: #fff;
    text-align: left;
    font-size: 12px;
    th {
      font-weight: 400 !important;
      background-color: #089bab;
      position: sticky;
      top: 0px;
      z-index: 1;
      max-height: 35px !important;
      height: 19px !important;
      padding: 8px !important;
      border: none !important;
      font-size: 14px;
      &:not(:first-of-type) {
        text-align: center;
      }
    }
  }

  &__body {
    tr {
      margin-top: 5px !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      td {
        max-height: 35px !important;
        height: 19px;
        padding: 8px;
        &:not(:first-of-type) {
          text-align: center;
        }
      }
    }
  }

  &__degree {
    width: 70%;
    border-radius: 8px;
    display: inline-block;
  }

  &__degree--light {
    background-color: #abebc6;
    padding: 8px;
  }
  &__degree--medium {
    background-color: #f9e79f;
    padding: 8px;
  }
  &__degree--severe {
    background-color: #f5b7b1;
    padding: 8px;
  }

  &__date {
    font-family: sans-serif;
  }
}

.line-throughed {
  text-decoration: line-through;
}

@media (min-height: 600px) {
  .container-fluid {
    height: 40vh;
  }
}

@media (min-height: 700px) {
  .container-fluid {
    height: 45vh;
  }
}

@media (min-height: 800px) {
  .container-fluid {
    height: 50vh;
  }
}

@media (min-height: 900px) {
  .container-fluid {
    height: 55vh;
  }
}
</style>
