<template>
  <div>
    <PromotionalCodeForm />
  </div>
</template>

<script>
import PromotionalCodeForm from './Forms/PromotionalCodeForm'

export default {
  name: 'PromotionalCode',
  components: { PromotionalCodeForm },
  data: () => ({}),
  methods: {}
}
</script>
