import API from '../../../../services/index'
import _ from 'lodash'
import Vue from 'vue'

const actions = {
  async saveAllVaccineProntuario({ state }) {
    state.listVaccines.forEach((vaccine) => {
      if (!vaccine.dataDose || vaccine.dataDose === '') {
        delete vaccine.dataDose
      }
    })
    if (state.listVaccines.length !== 0) {
      try {
        await API.post('/api/ProntuarioPreNatalVacina/Salvar', {
          appName: 'DoctorPep',
          appVersion: '1.0.1',
          object: {
            ...state.dataVaccines,
            listaDoses: state.listVaccines
          }
        })
        this.$toast.success('Prontuario de vacinas salvo com sucesso!')
      } catch (error) {
        this.$toast.error('Desculpe, não foi possivel salvar o Prontuario!!!')
      }
    }
  },
  // eslint-disable-next-line camelcase
  async getCoverSheetAction({ commit }, { id_paciente, id_usuario }) {
    try {
      const response = await API.get(
        `/api/PreNatalFolhaRosto/ObtemPreNatalFolhaRostoGestacaoAtualPorUsuarioPaciente`,
        {
          params: {
            id_usuario,
            id_paciente
          }
        }
      )
      const temp = {
        ...response.data.object,
        dateDum: response.data.object.dateDum.substring(0, 10)
      }
      commit('getCoverSheetMutation', temp)
    } catch (error) {
      commit('getCoverSheetMutation', {
        idGestaAtual: '',
        descGestaAtual: '',
        tipoPartoAnterior: '',
        numPartoAnterior: '',
        numAborto: '',
        dateDum: '',
        dppDum: '',
        idTipoGestacao: '',
        descTipoGestacao: '',
        idInicioTrimestre: '',
        descInicioTrimestre: '',
        idRiscoGestacao: '',
        descRiscoGestacao: '',
        nomePai: '',
        nomeBebe: '',
        pesoAnteriorGesta: '',
        alturaGestante: '',
        imcGestante: 0,
        observacaoGestacao: '',
        dtInclusao: new Date().toISOString(),
        dtFinalizacaoGestacao: '',
        idTipoDesfecho: '',
        descTipoDesfecho: '',
        idCidDesfecho: '',
        dtNascimentoBebe: '',
        idUsuarioInativacao: '',
        dtInativacao: '',
        justificativaInativacao: '',
        ativo: 0,
        idPaciente: id_paciente,
        idUsuario: id_usuario,
        listaCidIntercorrencia: []
      })
      return error
    }
  },

  async getPreNatalConsults(context, idPreNatal) {
    const response = await API.get(
      '/api/ProntuarioPreNatalConsulta/ObtemRegistroConsultaPorPreNatal',
      {
        params: {
          id_pre_natal: idPreNatal
        }
      }
    )
    return response.data.object
  },
  async getDPPAction(context, payload) {
    const response = await API.post(
      `/api/PreNatalFolhaRosto/DataProvavelParto/${payload}`
    )

    return response.data.object
  },

  // eslint-disable-next-line camelcase
  async getGestationalAge(context, { data_dum, data_ocorrencia }) {
    try {
      const response = await API.post(
        `api/ProntuarioPreNatalConsulta/IdadeGestacionalAtual/2023-05-05`,
        {},
        {
          params: {
            data_dum,
            data_ocorrencia
          }
        }
      )

      return response.data.object.igDumDias
    } catch {
      return ''
    }
  },
  async addCoverSheetToHandBook({ commit }, payload) {
    if (payload !== null) {
      try {
        const sheetAux = {
          ...payload
        }
        const response = await API.post('/api/PreNatalFolhaRosto/Salvar', {
          object: sheetAux
        })
        const temp = {
          ...response.data.object,
          dateDum: response.data.object.dateDum.substring(0, 10)
        }
        if (
          temp.dtFinalizacaoGestacao === '' ||
          temp.dtFinalizacaoGestacao === null
        ) {
          commit('getCoverSheetMutation', temp)
        } else {
          commit('getCoverSheetMutation', {
            idGestaAtual: '',
            descGestaAtual: '',
            tipoPartoAnterior: '',
            numPartoAnterior: '',
            numAborto: '',
            dateDum: '',
            dppDum: '',
            idTipoGestacao: '',
            descTipoGestacao: '',
            idInicioTrimestre: '',
            descInicioTrimestre: '',
            idRiscoGestacao: '',
            descRiscoGestacao: '',
            nomePai: '',
            nomeBebe: '',
            pesoAnteriorGesta: '',
            alturaGestante: '',
            imcGestante: 0,
            observacaoGestacao: '',
            dtInclusao: new Date().toISOString(),
            dtFinalizacaoGestacao: '',
            idTipoDesfecho: '',
            descTipoDesfecho: '',
            idCidDesfecho: '',
            dtNascimentoBebe: '',
            idUsuarioInativacao: '',
            dtInativacao: '',
            justificativaInativacao: '',
            ativo: 0,
            idPaciente: temp.idPaciente,
            idUsuario: temp.idUsuario,
            listaCidIntercorrencia: []
          })
        }
        return true
      } catch (error) {
        return false
      }
    }
  },
  async addResultsToHandBook({ commit }, { payload }) {
    try {
      await API.post('/api/ProntuarioPreNatalConsulta/Salvar', {
        object: payload
      })
    } catch (error) {}
  },

  async getExamsLaboratorysAction({ state }, payload) {
    try {
      const response = await API.get(
        `/api/ProntuarioPreNatalExame/ObtemExameProntuarioPorPreNatal`,
        {
          params: {
            id_pre_natal: payload.idPreNatal
          }
        }
      )
      const dataExam = response.data.object
      dataExam.forEach(async (element) => {
        delete element.idUsuarioInativacao
        delete element.dtInativacao
        delete element.justificativaInativacao
        if (element.listaResultado === null) {
          element.listaResultado = [
            {
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: element.codigoExamesTuss,
              sequencial: 0,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                antigoArquivo: ''
              }
            },
            {
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: element.codigoExamesTuss,
              sequencial: 1,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                antigoArquivo: ''
              }
            },
            {
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: element.codigoExamesTuss,
              sequencial: 2,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                antigoArquivo: ''
              }
            }
          ]
        } else {
          const listAux = [
            {
              id: null,
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: null,
              sequencial: 0,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                antigoArquivo: ''
              }
            },
            {
              id: null,
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: null,
              sequencial: 1,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                amtigoArquivo: ''
              }
            },
            {
              id: null,
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: null,
              sequencial: 2,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                antigoArquivo: ''
              }
            }
          ]
          element.listaResultado.forEach(async (element, index) => {
            listAux.forEach((elementAux) => {
              elementAux.codigoExamesTuss = element.codigoExamesTuss
            })
            if (element.descArquivo === '' || element.descArquivo === null) {
              listAux[element.sequencial] = {
                id: element.id,
                dataResultado: element.dataResultado,
                resultado: element.resultado,
                codigoExamesTuss: element.codigoExamesTuss,
                sequencial: element.sequencial,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null,
                  antigoArquivo: ''
                }
              }
            } else {
              const responseFile = await API.get(
                `/api/ProntuarioPreNatalExame/AwsS3ObtemArquivo?arquivoname=${element.descArquivo}&id_pre_natal_exame_resultado=${element.id}`,
                { responseType: 'blob' }
              )
              const namedFile = element.descArquivo
              const urlPdf = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = (event) => {
                  resolve(event.target.result)
                }
                reader.readAsDataURL(responseFile.data)
              })
              const namedOld = element.descArquivo
              // Atualize para usar Vue.set
              Vue.set(listAux, element.sequencial, {
                id: element.id,
                dataResultado: element.dataResultado,
                resultado: element.resultado,
                codigoExamesTuss: element.codigoExamesTuss,
                sequencial: element.sequencial,
                descArquivo: {
                  nomeArquivo: namedFile,
                  arquivo: responseFile.data,
                  urlArquivo: urlPdf,
                  antigoArquivo: namedOld
                }
              })
            }
          })
          listAux.forEach((element) => {
            if (element.id === null) {
              delete element.id
            }
          })
          element.listaResultado = listAux
          listAux.forEach((element) => {
            element = {
              id: null,
              dataResultado: '',
              resultado: '',
              codigoExamesTuss: null,
              sequencial: 0,
              descArquivo: {
                nomeArquivo: '',
                arquivo: null,
                urlArquivo: null,
                antigoArquivo: ''
              }
            }
          })
        }
      })
      // Após atualizar o estado, adicione um setTimeout
      state.dataExamsLaboratorys = dataExam
    } catch (error) {
      if (error.response.status === 404) {
        state.dataExamsLaboratorys = [
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'Grupo sanguíneo ABO e RH - pesquisa',
            codigoExamesTuss: 40403173,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40403173,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40403173,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40403173,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal:
              'Gasometria + Hb + Ht + Na +  K + Cl + Ca + glicose + lactato (quando efetuado no gasômetro) - pesquisa e/ou dosagem',
            codigoExamesTuss: 4030202,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 4030202,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 4030202,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 4030202,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'Glicose - pesquisa e/ou dosagem',
            codigoExamesTuss: 40302040,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40302040,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40302040,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40302040,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'Sífilis - VDRL',
            codigoExamesTuss: 40307760,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307760,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307760,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307760,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'HIV - antígeno P24 - pesquisa e/ou dosagem',
            codigoExamesTuss: 40307166,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307166,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307166,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307166,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal:
              'Hepatite B - HBSAG (AU, antígeno austrália) - pesquisa e/ou dosagem',
            codigoExamesTuss: 40307018,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307018,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307018,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307018,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'Toxoplasmose IgG - pesquisa e/ou dosagem',
            codigoExamesTuss: 40307824,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307824,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307824,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40307824,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'Coombs indireto',
            codigoExamesTuss: 40304884,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40304884,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40304884,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40304884,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal:
              'Teste oral de tolerância à glicose - 2 dosagens',
            codigoExamesTuss: 40302709,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40302709,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40302709,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40302709,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal: 'Cultura, urina com contagem de colônias',
            codigoExamesTuss: 40310213,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40310213,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40310213,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40310213,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          },
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            descExamePreNatal:
              'Procedimento diagnóstico em citopatologia cérvico-vaginal oncótica',
            codigoExamesTuss: 40601137,
            ativo: 0,
            listaResultado: [
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40601137,
                sequencial: 0,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40601137,
                sequencial: 1,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              },
              {
                dataResultado: '',
                resultado: '',
                codigoExamesTuss: 40601137,
                sequencial: 2,
                descArquivo: {
                  nomeArquivo: '',
                  arquivo: null,
                  urlArquivo: null
                }
              }
            ]
          }
        ]
      } else {
        return error
      }
    }
  },
  addNewExamLaboratoryAction({ state }, payload) {
    state.dataExamsLaboratorys.unshift(payload)
  },
  deleteExamLaboratoryAction({ state }, payload) {
    state.dataExamsLaboratorys.splice(payload, 1)
  },
  addFileExamLaboratorysAction({ state }, payload) {
    state.dataExamsLaboratorys[payload.index].listaResultado[
      payload.indexExamTuss
    ].descArquivo.nomeArquivo = payload.namedFile
    state.dataExamsLaboratorys[payload.index].listaResultado[
      payload.indexExamTuss
    ].descArquivo.arquivo = payload.selectedFile
    state.dataExamsLaboratorys[payload.index].listaResultado[
      payload.indexExamTuss
    ].descArquivo.urlArquivo = payload.pdfDataUrl
  },

  setResultExamLaboratoryAction({ state }, payload) {
    state.dataExamsLaboratorys[payload.index].listaResultado[
      payload.indexTuss
    ].resultado = payload.result
  },

  setDateExamLaboratoryAction({ state }, payload) {
    state.dataExamsLaboratorys[payload.index].listaResultado[
      payload.indexTuss
    ].dataResultado = payload.date
  },
  async saveAllExamLaboratorys({ state }) {
    try {
      state.dataExamsLaboratorys.forEach(async (exam, index) => {
        let listFiles = []
        if (exam.id) {
          const newListaResultado = []
          for (
            let index = exam.listaResultado.length - 1;
            index >= 0;
            index--
          ) {
            const element = exam.listaResultado[index]
            if (element.dataResultado === '' || element.resultado === '') {
              exam.listaResultado.splice(index, 1)
            } else {
              if (element.descArquivo.nomeArquivo !== '') {
                if (
                  element.descArquivo.nomeArquivo ===
                  element.descArquivo.antigoArquivo
                ) {
                  element.descArquivo = element.descArquivo.antigoArquivo
                } else {
                  listFiles.push({
                    sequencial: element.sequencial,
                    arquivo: element.descArquivo.arquivo
                  })
                  element.descArquivo = element.descArquivo.nomeArquivo
                }
              } else {
                element.descArquivo = ''
              }
              newListaResultado.push(element)
            }
          }
          exam.listaResultado = newListaResultado
        } else {
          for (
            let indexTuss = exam.listaResultado.length - 1;
            indexTuss >= 0;
            indexTuss--
          ) {
            const element = exam.listaResultado[indexTuss]

            if (element.dataResultado === '' || element.resultado === '') {
              exam.listaResultado.splice(indexTuss, 1)
            } else {
              if (element.descArquivo.nomeArquivo === '') {
                element.descArquivo = ''
              } else {
                listFiles.push({
                  sequencial: element.sequencial,
                  arquivo: element.descArquivo.arquivo
                })
                element.descArquivo = element.descArquivo.nomeArquivo
              }
            }
          }
        }
        const response = await API.post('/api/ProntuarioPreNatalExame/Salvar', {
          appName: 'DoctorPep',
          appVersion: '1.0.1',
          object: {
            ...exam
          }
        })
        const examLaboratory = response.data.object.listaResultado
        const listaDeArquivos = listFiles.slice(0).reverse()
        listFiles = []
        if (examLaboratory.descArquivo !== '') {
          examLaboratory.forEach(async (returnExam, index) => {
            const bodyFormData = new FormData()
            bodyFormData.append('file', listaDeArquivos[index].arquivo)
            await API.post(
              `/api/ProntuarioPreNatalExame/AwsS3UploadArquivo?tipoArquivo=${'pdf'}&filename=${
                returnExam.descArquivo
              }&id_pre_natal_exame_resultado=${returnExam.id}`,
              bodyFormData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${window.$cookies.get('token')}`
                }
              }
            )
          })
        }
      })
    } catch (error) {
      return error
    }
  },
  async getListVaccinesSUS({ commit }) {
    try {
      const response = await API.get('/api/VacinaSus/ObtemLista')
      if (response.data.object === null || response.data.object === '') {
        alert('Desculpe, não a vacinas!!')
      }
      return response.data.object
    } catch (error) {
      return error
    }
  },
  async getListaVaccinesPacients({ commit }, payload) {
    try {
      const response = await API.get(
        `/api/ProntuarioPreNatalVacina/ObtemVacinaProntuarioPorPreNatal`,
        {
          params: {
            id_pre_natal: payload.idPreNatal
          }
        }
      )
      const dataVaccine = response.data.object
      const listVaccine = response.data.object.listaDoses
      delete dataVaccine.listaDoses
      commit('setFormVacine', dataVaccine)
      commit('setVaccinesState', listVaccine)
    } catch (error) {
      if (error.response.status === 404) {
        const data = {
          idProntuario: payload.idProntuario,
          idPreNatal: payload.idPreNatal,
          dtInclusao: new Date(),
          dtOcorrencia: new Date(),
          calendarioAdequado: null,
          observacaoGeral: '',
          idUsuarioInativacao: 0,
          dtInativacao: '',
          justificativaInativacao: '',
          ativo: 0
        }
        const lista = [
          {
            idVacina: 1,
            descVacina: 'Antitetânica',
            numeroDoses: 0,
            indicacaoAplicada: 0,
            naoIminuzado: 0,
            imunizado: 0,
            semInformacao: 0,
            dataDose: '',
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            observacaoVacina: ''
          },
          {
            descVacina: 'Hepatite B',
            idVacina: 9,
            numeroDoses: 0,
            indicacaoAplicada: 0,
            naoIminuzado: 0,
            imunizado: 0,
            semInformacao: 0,
            dataDose: '',
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            observacaoVacina: ''
          },
          {
            descVacina: 'Influenza',
            idVacina: 44,
            numeroDoses: 0,
            indicacaoAplicada: 0,
            naoIminuzado: 0,
            imunizado: 0,
            semInformacao: 0,
            dataDose: '',
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            observacaoVacina: ''
          },
          {
            descVacina: 'Dupla Adulto',
            idVacina: 25,
            numeroDoses: 0,
            indicacaoAplicada: 0,
            naoIminuzado: 0,
            imunizado: 0,
            semInformacaormacao: 0,
            dataDose: '',
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            observacaoVacina: ''
          }
        ]
        commit('setFormVacine', data)
        commit('setVaccinesState', lista)
      } else {
        return error
      }
    }
  },

  async getListaUltrassomAction({ commit }, payload) {
    try {
      const response = await API.get(
        `/api/ProntuarioPreNatalUltrassom/ObtemUltrassomProntuarioPorPreNatal`,
        {
          params: {
            id_pre_natal: payload.idPreNatal
          }
        }
      )
      const dataUltrassom = response.data.object
      dataUltrassom.forEach(async (element, index) => {
        delete element.idUsuarioInativacao
        delete element.dtInativacao
        delete element.justificativaInativacao
        if (element.descArquivo === '' || element.descArquivo === null) {
          element.descArquivo = {
            nomeArquivo: '',
            arquivo: null,
            urlArquivo: null,
            linha: null
          }
        } else {
          const responseFile = await API.get(
            `/api/ProntuarioPreNatalUltrassom/AwsS3ObtemArquivo/?arquivoname=${element.descArquivo}&id_pre_natal_ultrassom=${element.id}`,
            { responseType: 'blob' }
          )
          const namedFile = element.descArquivo
          const line = index
          const urlPdf = await new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = (event) => {
              resolve(event.target.result)
            }
            reader.readAsDataURL(responseFile.data)
          })
          const namedOld = element.descArquivo
          element.descArquivo = {
            nomeArquivo: namedFile,
            arquivo: responseFile.data,
            urlArquivo: urlPdf,
            linha: line,
            antigoArquivo: namedOld
          }
        }
      })
      commit('setDataUltrassom', dataUltrassom)
    } catch (error) {
      if (error.response.status === 404) {
        const lista = [
          {
            idProntuario: payload.idProntuario,
            idPreNatal: payload.idPreNatal,
            dtInclusao: new Date(),
            dtOcorrencia: new Date(),
            dppUsg: '',
            peso: 0,
            placenta: '',
            liquido: '',
            transucaoNucal: '',
            vesicula: '',
            observacao: '',
            descArquivo: {
              nomeArquivo: '',
              arquivo: null,
              urlArquivo: null,
              linha: null
            },
            ativo: 0,
            igUsgQuantidadeSemana: 0,
            igUsgQuantidadeDia: 0
          }
        ]
        commit('setDataUltrassom', lista)
      } else {
        return error
      }
    }
  },

  addVaccineInList({ state, commit }, payload) {
    state.listVaccines.unshift(payload)
  },

  editVaccineInList({ state }, payload) {
    const vaccine = state.listVaccines.find(
      (vacina) => vacina.descVacina === payload.descVacina
    )
    if (vaccine) {
      // Utilizando o lodash para copiar dados do payload e trocar no vaccine
      _.merge(vaccine, payload)
    }
  },

  deleteVaccineInList({ state, commit }, payload) {
    const updatedList = state.listVaccines.filter(
      (vacina) => vacina.descVacina !== payload
    )
    commit('deleteVaccineFromLista', updatedList)
  },

  setValueInCalander({ state }, payload) {
    state.dataVaccines.calendarioAdequado = payload
  },
  setValueInObservoAll({ state }, payload) {
    state.dataVaccines.observacaoGeral = payload
  },
  addFileUtrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].descArquivo.nomeArquivo =
      payload.namedFile
    state.dataUltrassom[payload.index].descArquivo.arquivo = payload.file
    state.dataUltrassom[payload.index].descArquivo.urlArquivo = payload.urlPdf
    state.dataUltrassom[payload.index].descArquivo.linha = payload.index
  },
  addNewUltrassomAction({ state }, payload) {
    state.dataUltrassom.push({
      idProntuario: payload.idProntuario,
      idPreNatal: payload.idPreNatal,
      dtInclusao: new Date(),
      dtOcorrencia: new Date(),
      dppUsg: '',
      peso: 0,
      placenta: '',
      liquido: '',
      transucaoNucal: '',
      vesicula: '',
      observacao: '',
      descArquivo: {
        nomeArquivo: '',
        arquivo: null,
        urlArquivo: null,
        linha: null
      },
      ativo: 0,
      igUsgQuantidadeSemana: 0,
      igUsgQuantidadeDia: 0
    })
  },
  async deleteUltrassomAction({ state }, payload) {
    if (payload.id || payload.id !== undefined) {
      // Será a inativação
    } else {
      state.dataUltrassom.splice(payload.index, 1)
    }
  },
  // Inputs
  setObeserverUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].observacao = payload.observacao
  },
  setVesiculaUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].vesicula = payload.vesicula
  },
  setTransucaoNucalUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].transucaoNucal = payload.transucaoNucal
  },
  setLiquidoUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].liquido = payload.liquido
  },
  setPlacentaUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].placenta = payload.placenta
  },
  setPesoUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].peso = payload.peso
  },
  setDppUsgUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].dppUsg = payload.dppUsg
  },
  setIgDayUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].igUsgQuantidadeDia =
      payload.IGusgQuantidadeDay
  },
  setIgWeekUltrassomAction({ state }, payload) {
    state.dataUltrassom[payload.index].igUsgQuantidadeSemana =
      payload.IGusgQuantidadeWeek
  },

  async saveAllUltrassomProntuario({ state }) {
    try {
      state.dataUltrassom.forEach(async (element) => {
        if (element.id) {
          if (element.descArquivo.nomeArquivo !== '') {
            if (
              element.descArquivo.nomeArquivo ===
              element.descArquivo.antigoArquivo
            ) {
              // Não muda nada
            } else {
              const bodyFormData = new FormData()
              bodyFormData.append('file', element.descArquivo.arquivo)
              await API.post(
                `/api/ProntuarioPreNatalUltrassom/AwsS3UploadArquivo?tipoArquivo=${'pdf'}&filename=${
                  element.descArquivo.nomeArquivo
                }&id_pre_natal_ultrassom=${element.id}`,
                bodyFormData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${window.$cookies.get('token')}`
                  }
                }
              )
              const ultrassom = {
                ...element,
                descArquivo: element.descArquivo.nomeArquivo
              }
              await API.post('/api/ProntuarioPreNatalUltrassom/Salvar', {
                appName: 'DoctorPep',
                appVersion: '1.0.1',
                object: [ultrassom]
              })
            }
          } else {
            // Não faz nada
          }
        } else {
          if (element.descArquivo.nomeArquivo === '') {
            element.descArquivo = ''
            await API.post('/api/ProntuarioPreNatalUltrassom/Salvar', {
              appName: 'DoctorPep',
              appVersion: '1.0.1',
              object: [element]
            })
          } else {
            const ultrassom = {
              ...element,
              descArquivo: element.descArquivo.nomeArquivo
            }
            const response = await API.post(
              '/api/ProntuarioPreNatalUltrassom/Salvar',
              {
                appName: 'DoctorPep',
                appVersion: '1.0.1',
                object: [ultrassom]
              }
            )
            const ultrassomID = response.data.object[0].id
            const bodyFormData = new FormData()
            bodyFormData.append('file', element.descArquivo.arquivo)
            await API.post(
              `/api/ProntuarioPreNatalUltrassom/AwsS3UploadArquivo?tipoArquivo=${'pdf'}&filename=${
                element.descArquivo.nomeArquivo
              }&id_pre_natal_ultrassom=${ultrassomID}`,
              bodyFormData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${window.$cookies.get('token')}`
                }
              }
            )
          }
        }
      })
    } catch (error) {
      return error
    }
  },

  async getListaDesfechoAction({ state }) {
    try {
      const list = await API.get(`/api/PreNatalTipoDesfecho/ObtemListaAtivos`)
      const listComplety = list.data.object
      return listComplety
    } catch (error) {
      return error
    }
  }
  /* async editASOFromHandBook({ commit }, { payload }) {
    try {
      await API.put('/api/ProntuarioAso/Editar', {
        object: payload
      })
    } catch (error) {}
  },
  async removeASOFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/ProntuarioAso/Remover', {
        params: {
          id_prontuario_aso: payload
        }
      })
    } catch (error) {}
  },
  async addASOFavoriteHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some((favorite) => {
      return favorite.id === payload.id
    })

    try {
      const { location, ...newPayload } = payload

      if (alreadyOnQuickAccessOrFavorite) {
        const apiResponse = await API.put(
          '/api/FavoritoEncaminhamentoContrarreferencia/Editar',
          {
            object: newPayload
          }
        )

        commit({
          type: 'editModuleFavorite',
          payload: apiResponse.data.object
        })
      } else {
        const apiResponse = await API.post(
          '/api/FavoritoEncaminhamentoContrarreferencia/Adicionar',
          {
            object: newPayload
          }
        )

        commit({
          type: 'addToModuleFavoriteStateWithoutSelect',
          payload: apiResponse.data.object
        })
      }
    } catch (error) {}
  },
  async addASOToFavoritesHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some((favorite) => {
      return favorite.id === payload.id
    })

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        await API.put('/api/FavoritoEncaminhamentoContrarreferencia/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        const apiResponse = await API.post(
          '/api/FavoritoEncaminhamentoContrarreferencia/Adicionar',
          {
            object: requestObject
          }
        )

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async addASOToQuickAccessHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some(
      (favorite) => favorite.id === payload.id
    )

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        await API.put('/api/FavoritoEncaminhamentoContrarreferencia/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        const apiResponse = await API.post(
          '/api/FavoritoEncaminhamentoContrarreferencia/Adicionar',
          {
            object: requestObject
          }
        )
        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async removeASOFavoriteFromHandBook({ commit }, { payload }) {
    const { location, ...newPayload } = payload
    try {
      await API.delete('/api/FavoritoEncaminhamentoContrarreferencia/Remover', {
        data: {
          object: newPayload
        }
      })
      commit({ type: 'removeFromFavoriteState', payload: newPayload })
    } catch (error) {}
  } */
}

export default actions
