<template>
  <div>
    <h3 class="mb-0" style="align-text: center">{{ message }}</h3>
    <p v-if="showSubtitle">
      Vá para tela de login e utilize todas nossas funcionalidades pensadas em
      você !
    </p>
    <div class="d-inline-block w-100">
      <button
        @click="goToLogin()"
        type="submit"
        class="btn btn-primary float-right"
      >
        Acessar Login
      </button>
    </div>
  </div>
</template>
<script>
import API from '../../services/index'
import { xray } from '../../config/pluginInit'
export default {
  name: 'LoginConfirmation',
  data() {
    return {
      message: '',
      showSubtitle: false
    }
  },
  methods: {
    goToLogin() {
      this.$router.push('/auth/sign-in')
    }
  },
  mounted() {
    xray.index()
    this.message = 'Aguarde, confirmando o login...'

    const base64ID = this.$route.params.id
    const voucher = atob(base64ID)
    API.post(`/api/Usuario/ConfirmacaoLogin?voucher_confirmacao=${voucher}`)
      .then((result) => {
        this.message = 'Tudo Certo! Cadastro confirmado com sucesso'
        this.showSubtitle = true
      })
      .catch((_) => {
        this.message = 'Ocorreu um erro'
      })
  }
}
</script>
