<template>
  <b-modal
    id="add-exam-file"
    title="Adicionar arquivo"
    title-class="text-light"
    header-bg-variant="primary"
    cancel-title="Cancelar"
    cancel-variant="danger"
    ok-title="Salvar"
    no-close-on-esc
    no-close-on-backdrop
    @ok="addFile"
    @close="resetModal"
    @cancel="resetModal"
  >
    <input @change="selectFile" type="file" id="inputFile" accept=".pdf" />
    <div class="inputStyled">
      <b-form-input
        v-model="fileToAddName"
        placeholder="Selecione um arquivo"
        readonly
      />
      <b-button variant="primary" @click="triggerInputFile">Buscar</b-button>
    </div>
    <b-form-checkbox v-model="attachAllExams"
      >Aplicar resultado para exames solicitados no mesmo
      atendimento</b-form-checkbox
    >
  </b-modal>
</template>

<script>
export default {
  name: 'AddFileModal',
  data() {
    return {
      fileToAdd: '',
      fileToAddName: '',
      attachAllExams: false
    }
  },
  mounted() {
    this.$bvModal.show('add-exam-file')
  },
  methods: {
    selectFile(event) {
      this.fileToAdd = event.target.files[0]
      this.fileToAddName = this.fileToAdd.name
    },
    triggerInputFile() {
      document.getElementById('inputFile').click()
    },
    addFile(event) {
      event.preventDefault()
      if (!this.fileToAdd)
        return this.$toast.error('É necessario escolher um arquivo ou imagem!')
      if (this.fileToAdd.type !== 'application/pdf') {
        return this.$toast.error('Só é possivel salvar arquivo pdf!')
      }
      const file = {
        localFile: this.fileToAdd,
        tipoArquivoImagem: 'pdf',
        descArquivo: this.fileToAddName
      }
      this.$emit('add-file', { file, attachToAllExams: this.attachAllExams })
      this.resetModal()
    },
    resetModal() {
      this.fileToAdd = ''
      this.fileToAddName = ''
      this.$bvModal.hide('addExamFile')
      this.$emit('closeModal', 'addExamFile')
    }
  }
}
</script>

<style scoped>
#inputFile {
  display: none;
}

.lineHeight {
  line-height: 1.5 !important;
}

.inputStyled {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5px;
  margin-bottom: 20px;
}
</style>
