var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline"},[_c('ul',{staticClass:"timeline__list"},_vm._l((_vm.data),function(data){return _c('li',{key:data.id,class:_vm.withColors
          ? `timeline__item timeline__item--${data[_vm.idProperty]}`
          : 'timeline__item timeline__item--normal',attrs:{"title":data.ativo === 1
          ? 'Justificativa:  ' + data.justificativaInativacao
          : ''}},[_c('div',{staticClass:"timeline-content"},[_c('strong',{class:[
            'timeline-content__date',
            data.ativo === 1 ? 'line-throughed' : '',
            'large-fontsize'
          ],staticStyle:{"color":"#000"}},[_vm._v(_vm._s(_vm.handleFormatDate(data.dtOcorrencia, 'pt-BR').split(' ')[0])+" "+_vm._s(_vm.formatHour(data.dtOcorrencia))+" - "+_vm._s(_vm.formatName(data.nmProfissional))+" ")]),_c('p',{class:[
            'timeline-content__text',
            data.ativo === 1 ? 'line-throughed' : '',
            'medium-fontsize'
          ],staticStyle:{"color":"#000"},domProps:{"innerHTML":_vm._s(data[_vm.descProperty])}})]),_c('div',{staticClass:"button-container"},[(data.ativo !== undefined)?_c('b-button',{staticStyle:{"width":"75px","height":"35px"},attrs:{"variant":data.ativo === 0 ? 'danger' : 'primary'},on:{"click":function($event){data.ativo === 0
              ? _vm.$bvModal.show(`${_vm.descProperty}_${data.id}`)
              : _vm.selectComplaintToEnable(data)}}},[_vm._v(" "+_vm._s(data.ativo === 0 ? 'Inativar' : 'Ativar')+" ")]):_vm._e(),(data.ativo !== undefined)?_c('b-button',{staticStyle:{"width":"75px","height":"35px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.copiarText(data[_vm.descProperty])}}},[_vm._v(" Repetir ")]):_vm._e(),_c('b-modal',{attrs:{"id":`${_vm.descProperty}_${data.id}`,"size":"lg","title":"Justificativa","title-class":"text-light","header-bg-variant":"primary","header-text-variant":"text-light","label-for":"justify-input","ok-title":"Salvar"},on:{"hidden":_vm.handleModalHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ cancel }){return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.selectComplaintToDisable(data)}}},[_vm._v(" Salvar ")])]}}],null,true),model:{value:(_vm.isJustificationModalVisible),callback:function ($$v) {_vm.isJustificationModalVisible=$$v},expression:"isJustificationModalVisible"}},[_c('b-form-group',{attrs:{"label-for":"justify-input"}},[_c('ValidationProvider',{attrs:{"name":"justify","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-textarea',{class:errors.length > 0 || _vm.justifyNotValid ? ' is-invalid' : '',attrs:{"id":"justify-input","maxlength":"150"},model:{value:(_vm.justify),callback:function ($$v) {_vm.justify=$$v},expression:"justify"}}),_c('div',{staticClass:"invalid-feedback",attrs:{"v-if":_vm.justifyNotValid}},[_c('span',[_vm._v("A justificativa deve conter no mínimo 5 digitos!")])])]}}],null,true)})],1),_c('p',[_vm._v(_vm._s(_vm.justify.length)+"/150")])],1)],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }