import { render, staticRenderFns } from "./revenueGraph.vue?vue&type=template&id=54786c84&scoped=true"
import script from "./revenueGraph.vue?vue&type=script&lang=js"
export * from "./revenueGraph.vue?vue&type=script&lang=js"
import style0 from "./revenueGraph.vue?vue&type=style&index=0&id=54786c84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54786c84",
  null
  
)

export default component.exports