<template>
  <div>
    <div class="module__wrapper">
      <div class="module__options">
        <b-icon
          font-scale="2"
          class="module__options-icon"
          icon="grid"
          variant="primary"
        ></b-icon>
        <div class="module__info">
          <input
            :class="
              isEditingModuleName
                ? 'module__info-title__editing'
                : 'module__info-title'
            "
            v-model="moduleName"
            :readonly="isEditingModuleName ? false : true"
            maxlength="36"
          />
          <p class="module__info-subtitle">
            Seção {{ module.sequencia + 1 }} de {{ modulesLength }}
          </p>
        </div>
      </div>

      <b-icon
        @click="isEditingModuleName = !isEditingModuleName"
        :icon="this.isEditingModuleName ? 'check2-square' : 'pencil-square'"
        :variant="this.isEditingModuleName ? 'success' : 'primary'"
        font-scale="1.5"
        class="modal-info__edit"
      ></b-icon>

      <div class="module__icons">
        <b-icon
          @click="swapUp(module)"
          icon="triangle-fill"
          flip-v
          variant="primary"
          font-scale="1.3"
          class="mr-4 ml-1"
        ></b-icon>

        <b-icon
          icon="triangle-fill"
          variant="primary"
          font-scale="1.3"
          @click="swapDown(module)"
        ></b-icon>
      </div>

      <div
        class="modal-info__active-checkbox"
        @click.prevent="toggleExpanded(module)"
      >
        <b-form-checkbox switch :checked="module.aberto === 0" />
      </div>
      <div
        class="modal-info__open-checkbox"
        @click.prevent="toggleActive(module)"
      >
        <b-form-checkbox switch :checked="module.ativo === 0" />
      </div>

      <div class="modal-info__pallete">
        <input
          type="color"
          v-model="module.corHexadecimal"
          @change="changeModuleBackground(module, $event)"
        />
      </div>

      <div @click="toggleExpandModule()">
        <b-icon v-if="expanded" icon="chevron-up" font-scale="2"></b-icon>
        <b-icon v-else icon="chevron-down" font-scale="2"></b-icon>
      </div>
    </div>

    <template v-if="expanded">
      <div class="search">
        <p class="search__warning">
          Cadastre os seus favoritos ou itens de acesso rápido
        </p>
        <div>
          <div style="display: flex; align-items: center">
            <div class="toolbar" style="margin-right: auto">
              <div class="fonts__container">
                <button
                  class="fonts__item fonts__item--1"
                  v-on:click="execCommand('fontSize', '3', 'Fonts')"
                >
                  12
                </button>
                <button
                  class="fonts__item fonts__item--2"
                  @click="execCommand('fontSize', '4', 'Fonts')"
                >
                  14
                </button>
                <button
                  class="fonts__item fonts__item--3"
                  @click="execCommand('fontSize', '5', 'Fonts')"
                >
                  16
                </button>
                <button
                  class="fonts__item fonts__item--4"
                  @click="execCommand('fontSize', '6', 'Fonts')"
                >
                  18
                </button>
              </div>

              <div class="box-options">
                <ul class="tool-list">
                  <li class="tool">
                    <button type="button" v-on:click="execCommand('bold')">
                      <b-icon icon="type-bold" />
                    </button>
                  </li>
                  <li class="tool">
                    <button type="button" v-on:click="execCommand('italic')">
                      <b-icon icon="type-italic" />
                    </button>
                  </li>
                  <li class="tool">
                    <button type="button" v-on:click="execCommand('underline')">
                      <b-icon icon="type-underline" />
                    </button>
                  </li>
                </ul>
              </div>

              <div class="box-options">
                <ul class="tool-list">
                  <li class="tool">
                    <button
                      type="button"
                      v-on:click="execCommand('justifyLeft')"
                    >
                      <i class="fas fa-align-left"></i>
                    </button>
                  </li>
                  <li class="tool">
                    <button
                      type="button"
                      v-on:click="execCommand('justifyCenter')"
                    >
                      <i class="fas fa-align-center"></i>
                    </button>
                  </li>
                  <li class="tool">
                    <button
                      type="button"
                      v-on:click="execCommand('justifyRight')"
                    >
                      <i class="fas fa-align-right"></i>
                    </button>
                  </li>
                </ul>
              </div>

              <div>
                <div class="colors">
                  <button
                    class="colors__item colors__item--1"
                    v-on:click="execCommand('foreColor', '#091E42', 'Colors')"
                  ></button>
                  <button
                    class="colors__item colors__item--2"
                    @click="execCommand('foreColor', '#ff5630', 'Colors')"
                  ></button>
                  <button
                    class="colors__item colors__item--3"
                    @click="execCommand('foreColor', '#36b37e', 'Colors')"
                  ></button>
                  <button
                    class="colors__item colors__item--4"
                    @click="execCommand('foreColor', '#00b8d9', 'Colors')"
                  ></button>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <label
                style="font-size: 16px; margin-right: 16px"
                for="personal_and_familiar_history_title"
                >Titulo:</label
              >

              <b-form-input
                id="personal_and_familiar_history_title"
                style="text-transform: none"
                v-model="title"
                type="text"
              ></b-form-input>
            </div>
          </div>
          <div style="position: relative">
            <div
              @paste="handleCharactersLength"
              @keypress="handleCharactersLength"
              class="output"
              contenteditable="true"
              ref="trackingRef"
              @input="updateContent"
            ></div>
            <b-button
              @click="
                isEditing
                  ? addEdittedTrackingToHandBook()
                  : addFavoriteTrackingToHandBook()
              "
              variant="primary"
              style="position: absolute; bottom: 32px; right: 16px; z-index: 16"
              >{{ isEditing ? 'Salvar' : 'Adicionar' }}</b-button
            >
            <p>{{ amountOfCharacters }} / 4000;</p>
          </div>
        </div>
      </div>

      <div class="table">
        <b-container fluid>
          <b-table-simple hover>
            <b-thead head-variant="primary">
              <b-tr class="table-header">
                <b-th>Titulo</b-th>
                <b-th>Descrição</b-th>
                <b-th>Favorito</b-th>
                <b-th>Acesso Rápido</b-th>
                <b-th>Ação</b-th>
              </b-tr>
            </b-thead>

            <b-tbody class="table__body">
              <b-tr :key="favorite.id" v-for="favorite in module.favorites">
                <b-td @click="editFavorite(favorite)">{{
                  favorite.descTitulo
                }}</b-td>
                <b-td
                  @click="editFavorite(favorite)"
                  v-html="favorite.descMonitoramento"
                ></b-td>
                <b-td @click.prevent="addTrackingToFavoriteHandBook(favorite)">
                  <b-form-radio
                    v-model="favorite.location"
                    :name="`favorite-tracking-location-${favorite.id}`"
                    value="favorite"
                  ></b-form-radio>
                </b-td>
                <b-td
                  @click.prevent="addTrackingToQuickAccessHandBook(favorite)"
                >
                  <b-form-radio
                    v-model="favorite.location"
                    :name="`favorite-tracking-location-${favorite.id}`"
                    value="quickAccess"
                  ></b-form-radio>
                </b-td>
                <b-td>
                  <b-button
                    @click="removeTrackingFavoriteFromHandBook(favorite)"
                    variant="danger"
                    >Remover</b-button
                  >
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
import moduleMethods from '../../shared/modulesMethods'
export default {
  name: 'TrackingUserPreference',
  props: ['module', 'modulesLength'],
  data() {
    return {
      isEditing: false,
      edditingFavorite: {},
      isEditingModuleName: false,
      moduleName: this.module.descMeuModulo,
      content: '',
      amountOfCharacters: 0,
      title: '',
      expanded: false
    }
  },

  destroyed() {
    if (this.moduleName !== this.module.descMeuModulo) {
      this.changeModuleName(this.module, this.moduleName)
    }
  },

  methods: {
    ...moduleMethods,
    editFavorite(favorite) {
      this.title = favorite.descTitulo
      this.content = favorite.descMonitoramento
      this.$refs.trackingRef.innerHTML = favorite.descMonitoramento
      this.isEditing = true
      this.edditingFavorite = favorite
    },
    handleCharactersLength(event) {
      if (this.amountOfCharacters >= 4000) {
        event.preventDefault()
      }
    },
    execCommand(command, value = null) {
      if (command === 'foreColor') {
        document.execCommand('styleWithCSS', false, true)
        document.execCommand(command, false, value)
        this.placeCaretAtEnd(this.$refs.trackingRef)
      } else {
        document.execCommand(command, false, value)
        this.placeCaretAtEnd(this.$refs.trackingRef)
      }
    },

    removeTrackingFavoriteFromHandBook(favorite) {
      this.$store.dispatch({
        type: 'MedicalReports/removeTrackingFavoriteFromHandBook',
        payload: favorite
      })
    },

    addEdittedTrackingToHandBook() {
      const requiredFields = [
        {
          value: 'title',
          errorMessage: 'Não é possível adicionar um favorito sem titulo'
        },
        {
          value: 'content',
          errorMessage: 'Não é possível adicionar um favorito sem conteudo'
        }
      ]

      for (const requiredField of requiredFields) {
        if (this[`${requiredField.value}`] === '') {
          this.$toast.error(requiredField.errorMessage)
          return
        }
      }

      this.$store.dispatch({
        type: 'MedicalReports/addEdittedTrackingFavoriteToHandBook',
        payload: {
          descTitulo: this.title,
          descMonitoramento: this.content,
          idProntuarioPreferencia: this.module.id,
          id: this.edditingFavorite.id,
          acessoRapido: this.edditingFavorite.acessoRapido,
          favorito: this.edditingFavorite.favorito
        }
      })

      this.content = ''
      this.title = ''
      this.isEditing = false
      this.amountOfCharacters = 0
      this.$refs.trackingRef.innerHTML = ''
    },

    addFavoriteTrackingToHandBook() {
      const requiredFields = [
        {
          value: 'title',
          errorMessage: 'Não é possível adicionar um favorito sem titulo'
        },
        {
          value: 'content',
          errorMessage: 'Não é possível adicionar um favorito sem conteudo'
        }
      ]

      for (const requiredField of requiredFields) {
        if (this[`${requiredField.value}`] === '') {
          this.$toast.error(requiredField.errorMessage)
          return
        }
      }

      this.$store.dispatch({
        type: 'MedicalReports/addFavoriteTrackingToHandBook',
        payload: {
          descTitulo: this.title,
          descMonitoramento: this.content,
          idProntuarioPreferencia: this.module.id,
          favorito: -1,
          acessoRapido: -1
        }
      })

      this.content = ''
      this.title = ''
      this.amountOfCharacters = 0
      this.$refs.trackingRef.innerHTML = ''
    },

    addTrackingToQuickAccessHandBook(favorite) {
      this.$store.dispatch({
        type: 'MedicalReports/addTrackingToQuickAccessHandBook',
        payload: favorite
      })
    },

    addTrackingToFavoriteHandBook(favorite) {
      this.$store.dispatch({
        type: 'MedicalReports/addTrackingToFavoriteHandBook',
        payload: favorite
      })
    },
    updateContent(event) {
      this.amountOfCharacters = event.target.textContent.length
      if (this.amountOfCharacters >= 4000) {
        return
      }
      const content = event.target.innerHTML
      this.content = content
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalStyles.scss';
@import '../../shared/toolbarStyles.scss';
</style>
