<template>
  <div class="background">
    <div class="modal-container">
      <label class="close-button" @click="$emit('close')">
        <span>X</span>
      </label>
      <div class="logo-container">
        <img
          :src="require('../assets/images/logo.png')"
          class="logo"
          alt="logo"
        />
        <span class="logo-text">DoctorPEP</span>
      </div>
      <h3>Assinar Digitalmente</h3>
      <div class="certify">
        <div class="certify-options">
          <button
            :disabled="disabledPSC"
            @click="toogleHandle()"
            :class="disabledPSC ? 'certify-button-disabled' : 'certify-button'"
          >
            A1
          </button>
          <div class="divider"></div>
          <button
            :disabled="disabledA1"
            @click="toogleHandle()"
            :class="disabledA1 ? 'certify-button-disabled' : 'certify-button'"
          >
            PSC
          </button>
        </div>
        <div v-show="option === 'EMPETY'">
          <p>
            Você não pode assinar pois não a nenhum certificado carregado! Por
            favor carregue um certificado.
          </p>
        </div>
        <ValidationObserver
          v-show="option === 'A1'"
          ref="form"
          v-slot="{ handleSubmit }"
        >
          <form
            v-if="!certificate.thumbprint"
            class="form"
            id="form"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <ValidationProvider
              name="PIN"
              :rules="selectedAssignTypeIsPSC ? '' : 'required'"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="form.pin"
                type="password"
                placeholder="PIN"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                :disabled="selectedAssignTypeIsPSC"
              />
              <div class="invalid-feedback">
                <span>Informe um PIN</span>
              </div>
            </ValidationProvider>

            <ValidationProvider
              name="Expiration"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                v-model="form.selectedExpirationTime"
                :options="expirationObjects"
                :reduce="(expObj) => expObj.time"
                label="description"
                :clearable="false"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <div class="invalid-feedback">
                <span>Selecione um tempo de expiração</span>
              </div>
            </ValidationProvider>
            <div class="button-container">
              <b-button
                type="submit"
                style="padding: 0; border: 0"
                variant="primary"
              >
                <label class="default-button">
                  <span>Autenticar</span>
                </label>
              </b-button>
            </div>
          </form>
          <div class="tab" v-if="certificate.thumbprint">
            <div class="certificate-info-container">
              <h4>Dados certificado:</h4>
              <span class="certificate-info-text">
                {{ certificate.subject }}
              </span>
              <h4>Tipo do certificado:</h4>
              <span class="certificate-info-text">
                {{ certificate.certificateType }}
              </span>
              <h4>Válido até:</h4>
              <span class="certificate-info-text">
                {{ certificate.validTo }}
              </span>
            </div>
          </div>
        </ValidationObserver>
        <ValidationObserver v-show="option === 'PSC'" ref="form">
          <form id="form1">
            <div class="tab" v-show="certificate.thumbprint">
              <template>
                <span class="tab-item-text">Dados certificado</span>
              </template>
              <div class="certificate-info-container">
                <h4>Certificado:</h4>
                <span class="certificate-info-text">
                  {{ certificate.subject }}
                </span>
                <h4>Tipo do certificado:</h4>
                <span class="certificate-info-text">
                  {{ certificate.certificateType }}
                </span>
                <h4>Válido até:</h4>
                <span class="certificate-info-text">
                  {{ certificate.validTo }}
                </span>
              </div>
            </div>
            <div class="colunm-psc">
              <div class="psc">
                <p>Clique para autenticar o uso no sistema:</p>
                <a
                  href="https://cloud.madics.com.br/idm/auth/realms/madicscloud/protocol/openid-connect/auth?client_id=webadmin&redirect_uri=https%3A%2F%2Fcloud.madics.com.br%2Fmain&state=a3a05346-3bf4-45bd-911c-52e27b8ac178&response_mode=fragment&response_type=code%20id_token%20token&scope=openid&nonce=f959e2dd-a844-4b12-8ecc-395b5c4e17bc"
                  target="_blank"
                  >Validação</a
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DigitalAssignModal',
  props: ['userId', 'error'],
  data() {
    return {
      disabledPSC: false,
      disabledA1: false,
      option: false,
      expirationTimes: [1, 2, 4, 8, 12],
      expirationObjects: '',
      form: {
        selectedAssignType: 'A1',
        pin: '',
        selectedExpirationTime: 12
      }
    }
  },

  computed: {
    ...mapGetters({
      certificate: 'DigitalAssign/certificate',
      openedFromMedicalReports: 'DigitalAssign/openedFromMedicalReports',
      user: 'Setting/user'
    }),

    selectedAssignTypeIsPSC() {
      return this.form.selectedAssignType === 'PSC'
    }
  },

  watch: {
    selectedAssignTypeIsPSC(newValue) {
      if (newValue === true) {
        this.form.pin = null
      }
    }
  },

  methods: {
    ...mapActions({
      refreshAssignStatusAndState:
        'DigitalAssign/refreshAssignStatusAndStateAction',
      changeOpenedFromMedicalReports:
        'DigitalAssign/changeOpenedFromMedicalReportsAction'
    }),
    toogleHandle() {
      if (this.certificate.keyType === 'A1') {
        this.option = 'A1'
      }
      if (this.certificate.keyType === 'PSC') {
        this.option = 'PSC'
      }
      if (this.certificate === false) {
        this.option = 'EMPETY'
      }
    },
    setDisabled() {
      if (this.certificate.keyType === 'PSC') {
        this.disabledA1 = false
        this.disabledPSC = true
      }
      if (this.certificate.keyType === 'A1') {
        this.disabledA1 = true
        this.disabledPSC = false
      }
      if (this.certificate === false) {
        this.disabledA1 = true
        this.disabledPSC = true
      }
    },
    isTabDisabled(keyType) {
      return this.certificate.keyType === keyType
    },
    async onSubmit() {
      try {
        const object = {
          idUsuario: this.userId,
          pin: this.form.pin,
          tipoAssinatura: this.form.selectedAssignType,
          dtExpiracaoPin: this.calculateExpirationDate(),
          validadePin: 0
        }

        const result = await this.refreshAssignStatusAndState(object)
        if (this.openedFromMedicalReports) {
          this.changeOpenedFromMedicalReports(false)
        }

        if (result) {
          this.$toast.success('Assinatura atualizada com sucesso!')
        } else {
          this.$toast.error('Erro ao atualizar assinatura')
        }
      } catch {
        this.$toast.error('Erro ao atualizar assinatura')
      } finally {
        this.$emit('close')
      }
    },

    calculateExpirationDate() {
      const date = new Date()
      const timeInMinutes = this.form.selectedExpirationTime * 60
      const timeInSeconds = timeInMinutes * 60
      const timeInMilliseconds = timeInSeconds * 1000

      const expirationDateInMilliseconds = date.getTime() + timeInMilliseconds

      const expirationDate = new Date(expirationDateInMilliseconds)
        .toISOString()
        .split('.')[0]

      return expirationDate
    },

    createExpirationObjects(times) {
      const expirationObjects = [...times].map((time, index) => {
        const object = {
          id: index + 1,
          time,
          description: `Expirar login em ${time} hora(s)`
        }

        return object
      })

      this.expirationObjects = expirationObjects
    }
  },

  mounted() {
    this.setDisabled()
    this.toogleHandle()
    this.createExpirationObjects(this.expirationTimes)
  }
}
</script>
<style scoped>
::placeholder {
  color: #000 !important;
  opacity: 0.5 !important;
}

#inputFile {
  display: none;
}

.certify {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.certify-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certify-button {
  padding: 8px;
  flex: 1;
  font-size: 0.9rem;
  background-color: #089bab;
  color: white;
  border: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 3px solid #3a878f;
}

.divider {
  height: 40px;
  width: 2px;
  background-color: #eaeaea;
}

.certify-button-disabled {
  padding: 8px;
  flex: 1;
  font-size: 0.9rem;
  background-color: transparent;
  color: #089bab;
  border: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 3px solid #3a878f;
}
.tab {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 300px;
}

.tab-item-text {
  font-size: 1.2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 700;
  height: 25px;
}

.inputStyled {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5px;
  margin-bottom: 20px;
}

.certificate-info-container {
  display: flex;
  flex-direction: column;
}

.certificate-info-container h4 {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 25px;
  font-size: 1rem;
}

.certificate-info-text {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  height: 25px;
  font-size: 0.9rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.button-container {
  margin-top: auto;
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: right;
}

.default-button {
  border: 0;
  padding: 10px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.default-button span {
  font-size: 1.2rem;
  color: #fff;
}
.tooltip {
  opacity: 1 !important;
}

.colunm-psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.enabled-psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.enabled-psc a {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #089bab;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

.enabled-psc a:hover {
  color: #fff;
}

.phrase-enabled {
  display: flex;
}

.phrase-enabled span {
  position: relative;
  top: -10px;
}

#icon {
  color: #089bab;
}

.validt-psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.validt-psc button {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: rgb(52, 216, 52);
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

.background {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 500px;
  min-height: 500px;
  margin: 0px auto;
  z-index: 9999;
  padding: 20px 60px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  flex-direction: column;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.modal-container h3 {
  padding: 10px 0 5px 0;
  color: #089bab;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.logo {
  width: 50px;
  height: 50px;
}

.logo-container {
  padding: 4px;
  background-color: #089bab;
  border-radius: 4px;
}

.logo-container span {
  margin-left: 10px;
  color: #fff;
  font-size: 22px;
  vertical-align: middle;
  line-height: 100%;
  font-family: 'Poppins', sans-serif;
}

.close-button {
  margin: 0;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.close-button span {
  font-size: 22px;
  color: #1c1c1c;
}

.tab {
  height: 300px;
}

.tab-item-text {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  height: 25px;
}

.inputStyled {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5px;
  margin-bottom: 20px;
}

.psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.psc a {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: rgb(52, 216, 52);
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

.psc a:hover {
  color: #fff;
}
</style>
