<template>
  <div class="video-container">
    <div id="remoteTrack" :class="videoHorizontal ? 'horizontalRemote' : ''">
      <span
        class="message"
        v-if="activeRoom && !remoteConnected && !remoteDisconnected"
        >Aguardando Participante</span
      >

      <span
        class="message"
        v-if="activeRoom && !remoteConnected && remoteDisconnected"
        >Participante Desconectado</span
      >

      <div class="remoteIconsContainer" v-if="remoteMuted || remoteCamHided">
        <b-icon
          icon="mic-mute-fill"
          variant="danger"
          scale="1.5"
          v-if="remoteMuted"
        />

        <b-icon
          icon="camera-video-off-fill"
          variant="danger"
          scale="1.5"
          v-if="remoteCamHided"
        />
      </div>
    </div>
    <div id="localTrack" :class="videoHorizontal ? 'horizontalLocal' : ''">
      <span class="message" v-if="localConnecting">Conectando</span>
    </div>
    <div class="buttons">
      <b-button
        size="lg"
        @click="mute = !mute"
        variant="outline-dark"
        :disabled="!activeRoom"
      >
        <b-icon
          :icon="mute ? 'mic-mute-fill' : 'mic-fill'"
          :variant="mute ? 'danger' : 'white'"
          scale="1.5"
        />
      </b-button>

      <b-button
        @click="hideCamera = !hideCamera"
        variant="outline-dark"
        :disabled="!activeRoom"
      >
        <b-icon
          :icon="hideCamera ? 'camera-video-off-fill' : 'camera-video-fill'"
          :variant="hideCamera ? 'danger' : 'white'"
          scale="1.5"
        />
      </b-button>

      <!-- <b-button :disabled="!!activeRoom" variant="outline-dark">
        <b-icon icon="chat-left-dots-fill" variant="white" scale="1.5" />
      </b-button> -->

      <b-button
        :disabled="!activeRoom"
        @click="leaveRoomIfJoined"
        variant="danger"
        style="width: 30%"
      >
        <b-icon
          icon="telephone-fill"
          variant="white"
          scale="1.5"
          rotate="135"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['videoHorizontal'],
  name: 'Video',
  data() {
    return {
      participantIdentity: '',
      remoteIdentity: '',
      activeRoom: '',
      localTrack: false,
      mute: false,
      hideCamera: false,
      chatOpened: false,
      localConnecting: false,
      remoteConnected: false,
      remoteDisconnected: false,
      remoteMuted: false,
      remoteCamHided: false
    }
  },
  mounted() {
    this.createChatOptions()
  },
  watch: {
    mute(newValue) {
      if (newValue) {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable()
        })
      } else {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable()
        })
      }
    },
    hideCamera(newValue) {
      if (newValue) {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable()
        })
      } else {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable()
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      roomName: 'Video/getRoomName'
    })
  },
  methods: {
    ...mapActions({
      getToken: 'Video/getTokenAction',
      getActiveRooms: 'Video/getActiveRoomsAction',
      createNewRoom: 'Video/createNewRoomAction',
      hideVideo: 'Video/hideVideoComponentAction'
    }),
    handleConnectedLocalParticipant(participant) {
      const participantDiv = document.createElement('div')
      this.participantIdentity = participant.identity
      participantDiv.setAttribute('id', participant.identity)
      const container = document.getElementById('localTrack')
      container.appendChild(participantDiv)
      participant.tracks.forEach((trackPublication) => {
        this.handleTrackPublication(trackPublication, participant)
      })
      participant.on('trackPublished', this.handleTrackPublication)
    },

    handleConnectedRemoteParticipant(participant) {
      const participantDiv = document.createElement('div')
      this.remoteIdentity = participant.identity
      participantDiv.setAttribute('id', participant.identity)
      const container = document.getElementById('remoteTrack')
      container.appendChild(participantDiv)

      participant.tracks.forEach((trackPublication) => {
        this.handleTrackPublication(trackPublication, participant)

        this.handleTrackDisabled(trackPublication)
        this.handleTrackEnabled(trackPublication)
      })

      this.remoteConnected = true
      this.remoteDisconnected = false

      participant.on('trackPublished', this.handleTrackPublication)
    },

    handleTrackPublication(trackPublication, participant) {
      function displayTrack(track) {
        const participantDiv = document.getElementById(participant.identity)
        participantDiv.append(track.attach())
      }

      if (trackPublication.track) {
        displayTrack(trackPublication.track)
      }

      trackPublication.on('subscribed', displayTrack)
    },
    handleTrackDisabled(track) {
      track.on('trackDisabled', () => {
        if (track.kind === 'audio') {
          this.remoteMuted = true
        } else if (track.kind === 'video') {
          this.remoteCamHided = true
        }
      })
    },
    handleTrackEnabled(track) {
      track.on('trackEnabled', () => {
        if (track.kind === 'audio') {
          this.remoteMuted = false
        } else if (track.kind === 'video') {
          this.remoteCamHided = false
        }
      })
    },
    async createChatOptions() {
      const token = await this.getToken('Doctor')

      const roomName = window.atob(this.roomName)

      const connectOptions = {
        name: roomName,
        video: { framerate: 24, width: 1280 },
        audio: true
      }

      this.connectWithChatService(token, connectOptions)
    },

    async connectWithChatService(token, connectOptions) {
      this.localConnecting = true
      const Video = Twilio.Video

      const room = await Video.connect(token, connectOptions)

      this.activeRoom = room

      // render the local and remote participants' video and audio tracks

      this.handleConnectedLocalParticipant(room.localParticipant)
      room.participants.forEach(this.handleConnectedRemoteParticipant)
      room.on('participantConnected', this.handleConnectedRemoteParticipant)

      this.localConnecting = false

      room.on('participantDisconnected', () => {
        const remoteVideoElement = document.getElementById(this.remoteIdentity)
        remoteVideoElement.remove()

        this.remoteConnected = false
        this.remoteDisconnected = true
      })

      room.on('reconnecting', (error) => {
        if (error.code === 53001) {
          console.log('Reconnecting your signaling connection!', error.message)
        } else if (error.code === 53405) {
          console.log('Reconnecting your media connection!', error.message)
        }
      })

      room.on('reconnected', () => {
        console.assert.equal(room.state, 'connected')
        console.log('Reconnected your signaling and media connections!')
      })
    },

    async leaveRoomIfJoined() {
      if (this.activeRoom) {
        await this.activeRoom.disconnect()

        this.activeRoom = ''
        this.remoteConnected = false

        const localVideoElement = document.getElementById(
          this.participantIdentity
        )

        if (localVideoElement) {
          localVideoElement.remove()
        }

        if (this.remoteIdentity) {
          const remoteVideoElement = document.getElementById(
            this.remoteIdentity
          )

          if (remoteVideoElement) {
            remoteVideoElement.remove()
          }
        }
      }

      this.hideVideo()
    }
  },

  beforeDestroy() {
    this.leaveRoomIfJoined()
  }
}
</script>

<style scoped lang="scss">
.video-container {
  width: 100%;
  height: 100%;
}

#localTrack {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c3c3c;
  overflow: hidden;
  height: 45%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

#remoteTrack {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c3c3c;
  overflow: hidden;
  height: 45%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0 0;
}

.horizontalLocal {
  position: absolute !important;
  right: 0;
  bottom: 10%;
  height: 180px !important;
  width: 320px !important;
}

.horizontalRemote {
  width: 100% !important;
  height: 90% !important;
}

.buttons {
  height: 10%;
  display: flex;
  background-color: #3c3c3c;
  border-radius: 0 0 8px 8px;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 8px;
}

.message {
  font-size: 16px;
  color: white;
}

.remoteIconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 5px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50px;
}

.remoteIconsContainer svg:not(:first-child) {
  margin-left: 20px;
}
</style>

<style>
video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
}
</style>
