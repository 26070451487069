<template>
  <div>
    <modal-closure
      v-if="showClosure && data.id"
      @closeModal="closeModal()"
      :data="form"
    />
    <ValidationObserver ref="form" @submit.prevent="saveSheetFace">
      <form novalidate>
        <b-row>
          <b-col>
            <label>Gestação atual:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                :options="gestacoes"
                v-model="gestaAtual"
                value="id"
                label="description"
                :clearable="false"
                :disabled="!initialPregnancy"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              />
              <p v-if="errors.length > 0" style="color: red">
                * Por favor defina a gestação atual
              </p>
            </ValidationProvider>
          </b-col>
          <b-col>
            <label>Partos:</label>
            <b-form-input
              type="number"
              v-model="form.numPartoAnterior"
              :disabled="!initialPregnancy || form.idGestaAtual === 1"
            />
          </b-col>
          <b-col>
            <label>Tipo parto anterior:</label>
            <v-select
              :options="tipoParto"
              :clearable="false"
              v-model="form.tipoPartoAnterior"
              :disabled="!initialPregnancy || form.idGestaAtual === 1"
            />
          </b-col>
          <b-col>
            <label>Abortos:</label>
            <b-form-input
              type="number"
              v-model="form.numAborto"
              :disabled="!initialPregnancy || form.idGestaAtual === 1"
            />
          </b-col>
          <b-col>
            <label>DUM:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                type="date"
                v-model="form.dateDum"
                :disabled="!initialPregnancy"
                @input="setFormDateDump($event)"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                :max="new Date().toISOString().substring(0, 10)"
              />
            </ValidationProvider>
          </b-col>
          <b-col>
            <label>DPP:</label>
            <b-form-input
              disabled
              :value="
                form.dppDum.substring(0, 10).split('-').reverse().join('/')
              "
            />
          </b-col>
        </b-row>
        <b-row style="margin-top: 12px">
          <b-col>
            <label>Tipo de gestação:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                :options="tipoGestacao"
                v-model="typePregnant"
                label="description"
                value="id"
                :clearable="false"
                :disabled="!initialPregnancy"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              />
              <p v-if="errors.length > 0" style="color: red">
                * Por favor escolha um tipo de gestação
              </p>
            </ValidationProvider>
          </b-col>
          <b-col>
            <label>Início pré natal:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                :options="inicioPreNatal"
                v-model="preNatal"
                label="description"
                value="id"
                :clearable="false"
                :disabled="!initialPregnancy"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              />
              <p v-if="errors.length > 0" style="color: red">
                * Por favor defina o inicio
              </p>
            </ValidationProvider>
          </b-col>
          <b-col>
            <label>Risco:</label>
            <v-select
              :options="tipoRisco"
              v-model="risco"
              label="description"
              value="id"
              :clearable="false"
              :disabled="!initialPregnancy"
            />
          </b-col>
          <b-col v-if="form.descRiscoGestacao === 'Alto'">
            <b-form-group label="CID:" label-for="diagnostic">
              <b-form-input
                id="diagnostic"
                @keyup="searchOpened = true"
                v-model="filter"
                :disabled="!initialPregnancy"
              />
              <search-result
                :results="cidFilter"
                @on-result-selected="resultSelected"
                v-if="searchOpened"
              />
              <div
                @click="searchOpened = false"
                v-if="searchOpened"
                style="
                  width: 100vw;
                  height: 100vh;
                  position: fixed;
                  top: 0;
                  left: 0;
                  z-index: 12;
                "
              ></div>
              <div>
                <button
                  type="button"
                  v-for="(item, index) in form.listaCidIntercorrencia"
                  :key="item.id"
                  class="tagItem"
                  @click="form.listaCidIntercorrencia.splice(index, 1)"
                >
                  {{ item.idCid }}
                  <b-icon icon="x" />
                </button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row style="margin-top: 12px">
          <b-col>
            <label>Nome do pai:</label>
            <b-form-input
              v-model="form.nomePai"
              :disabled="!initialPregnancy"
            />
          </b-col>
          <b-col>
            <label>Nome do bebe(s):</label>
            <b-form-input
              v-model="form.nomeBebe"
              :disabled="!initialPregnancy"
            />
          </b-col>
        </b-row>
        <b-row style="margin-top: 12px">
          <b-col>
            <label>Peso anterior da gestação:</label>
            <b-form-input
              v-model="form.pesoAnteriorGesta"
              type="number"
              no-wheel
              :disabled="!initialPregnancy"
            />
          </b-col>
          <b-col>
            <label>Altura:</label>
            <b-form-input
              v-model="form.alturaGestante"
              type="number"
              no-wheel
              :disabled="!initialPregnancy"
            />
          </b-col>
          <b-col>
            <label>IMC:</label>
            <b-form-input type="number" :value="form.imcGestante" disabled />
          </b-col>
          <b-col style="display: flex; flex-direction: column">
            <label>Classificação:</label>
            <b-badge
              :variant="imcClassification.variant"
              style="
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
              "
            >
              {{ imcClassification.message }}
            </b-badge>
          </b-col>
        </b-row>

        <b-row style="margin-top: 12px">
          <b-col>
            <label>Observação:</label>
            <b-form-textarea
              rows="1"
              v-model="form.observacaoGestacao"
              :disabled="!initialPregnancy"
              style="max-height: 60px"
            />
          </b-col>
        </b-row>
        <div class="rowButton">
          <button
            type="submit"
            :class="form.id ? 'buttonSheetFaceDisabled' : 'buttonSheetFace'"
            :disabled="form.id ? true : false"
          >
            Salvar folha rosto
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import modalClosure from '../../modalClosure.vue'
import SearchResult from '../SearchResults.vue'
export default {
  name: 'CoverSheet',
  components: {
    SearchResult,
    modalClosure
  },
  props: [
    'idMedico',
    'idPaciente',
    'data',
    'initialPregnancy',
    'showClosure',
    'isInitial'
  ],
  data() {
    return {
      form: {
        idGestaAtual: '',
        descGestaAtual: '',
        tipoPartoAnterior: '',
        numPartoAnterior: '',
        numAborto: '',
        dateDum: '',
        dppDum: '',
        idTipoGestacao: '',
        descTipoGestacao: '',
        idInicioTrimestre: '',
        descInicioTrimestre: '',
        idRiscoGestacao: '',
        descRiscoGestacao: '',
        nomePai: '',
        nomeBebe: '',
        pesoAnteriorGesta: '',
        alturaGestante: '',
        imcGestante: 0,
        observacaoGestacao: '',
        dtInclusao: new Date().toISOString(),
        dtFinalizacaoGestacao: '',
        idTipoDesfecho: '',
        descTipoDesfecho: '',
        idCidDesfecho: '',
        dtNascimentoBebe: '',
        idUsuarioInativacao: '',
        dtInativacao: '',
        justificativaInativacao: '',
        ativo: 0,
        idPaciente: this.idPaciente,
        idUsuario: this.idMedico,
        listaCidIntercorrencia: []
      },
      searchOpened: false,
      filter: '',
      cids: [],
      tipoParto: ['Forceps', 'Cesária', 'Normal'],
      inicioPreNatal: [
        {
          id: 1,
          description: '1º trimestre'
        },
        {
          id: 2,
          description: '2º trimestre'
        },
        {
          id: 3,
          description: '3º trimestre'
        }
      ],
      tipoGestacao: [
        {
          id: 1,
          description: 'Simples'
        },
        {
          id: 2,
          description: 'Gemelar'
        }
      ],
      tipoRisco: [
        {
          id: 1,
          description: 'Habitual'
        },
        {
          id: 2,
          description: 'Alto'
        }
      ],
      gestacoes: [
        { id: 1, description: '1º Gestação' },
        { id: 2, description: '2º Gestação' },
        { id: 3, description: '3º Gestação' },
        { id: 4, description: '4º Gestação' },
        { id: 5, description: '5º Gestação' },
        { id: 6, description: '6º Gestação' }
      ]
    }
  },
  watch: {
    'form.alturaGestante': function (newValue) {
      if (newValue && this.form.pesoAnteriorGesta) {
        this.calculateImc(newValue, this.form.pesoAnteriorGesta)
      } else {
        this.form.imcGestante = 0
      }
    },
    'form.pesoAnteriorGesta': function (newValue) {
      if (newValue && this.form.alturaGestante) {
        this.calculateImc(this.form.alturaGestante, newValue)
      } else {
        this.form.imcGestante = 0
      }
    },
    data(newValue) {
      if (newValue.id) {
        this.form = newValue
      } else {
        this.form = {
          idGestaAtual: '',
          descGestaAtual: '',
          tipoPartoAnterior: '',
          numPartoAnterior: '',
          numAborto: '',
          dateDum: '',
          dppDum: '',
          idTipoGestacao: '',
          descTipoGestacao: '',
          idInicioTrimestre: '',
          descInicioTrimestre: '',
          idRiscoGestacao: '',
          descRiscoGestacao: '',
          nomePai: '',
          nomeBebe: '',
          pesoAnteriorGesta: '',
          alturaGestante: '',
          imcGestante: 0,
          observacaoGestacao: '',
          dtInclusao: new Date().toISOString(),
          dtFinalizacaoGestacao: '',
          idTipoDesfecho: '',
          descTipoDesfecho: '',
          idCidDesfecho: '',
          dtNascimentoBebe: '',
          idUsuarioInativacao: '',
          dtInativacao: '',
          justificativaInativacao: '',
          ativo: 0,
          idPaciente: this.idPaciente,
          idUsuario: this.idMedico,
          listaCidIntercorrencia: []
        }
      }
    }
  },
  async mounted() {
    this.cids = await this.getCids()
    if (this.data.id) {
      this.form = this.data
    } else {
      this.form = {
        idGestaAtual: '',
        descGestaAtual: '',
        tipoPartoAnterior: '',
        numPartoAnterior: '',
        numAborto: '',
        dateDum: '',
        dppDum: '',
        idTipoGestacao: '',
        descTipoGestacao: '',
        idInicioTrimestre: '',
        descInicioTrimestre: '',
        idRiscoGestacao: '',
        descRiscoGestacao: '',
        nomePai: '',
        nomeBebe: '',
        pesoAnteriorGesta: '',
        alturaGestante: '',
        imcGestante: 0,
        observacaoGestacao: '',
        dtInclusao: new Date().toISOString(),
        dtFinalizacaoGestacao: '',
        idTipoDesfecho: '',
        descTipoDesfecho: '',
        idCidDesfecho: '',
        dtNascimentoBebe: '',
        idUsuarioInativacao: '',
        dtInativacao: '',
        justificativaInativacao: '',
        ativo: 0,
        idPaciente: this.idPaciente,
        idUsuario: this.idMedico,
        listaCidIntercorrencia: []
      }
    }
  },
  methods: {
    ...mapActions({
      getDPPAction: 'MedicalReports/getDPPAction',
      getCids: 'Forwardings/getCidsAction',
      addCoverSheetToHandBook: 'MedicalReports/addCoverSheetToHandBook'
    }),
    closeModal() {
      this.$emit('closeModal')
    },
    async saveSheetFace() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        return this.$toast.error('Verifique os campos obrigatórios!')
      }
      const response = await this.addCoverSheetToHandBook(this.form)
      response
        ? this.$toast.success('Folha a rosto salva com sucesso!')
        : this.$toast.error(
            'Houve um erro ao salvar! Por favor tente novamente em alguns minutos.'
          )
    },
    calculateImc(altura, peso) {
      if (!peso && !altura) {
        return ''
      } else {
        if (altura % 1 !== 0) {
          const result = (peso / Math.pow(altura, 2)).toFixed(2)
          this.form.imcGestante = result
        } else {
          const novaAltura = altura / 100
          const result = (peso / Math.pow(novaAltura, 2)).toFixed(2)
          this.form.imcGestante = result
        }
      }
    },
    debounce(func, wait) {
      let timeout = null

      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(func, wait)
    },

    resultSelected(value) {
      this.filter = ''

      this.form.listaCidIntercorrencia.push({
        idCid: value.codCid,
        descCid: value.descCid,
        dtInclusao: new Date().toISOString()
      })

      this.searchOpened = false
    },
    async setFormDateDump(value) {
      const dpp = await this.getDPPAction(value)
      this.form.dateDum = value
      this.form.dppDum = dpp
    }
  },
  computed: {
    gestaAtual: {
      get() {
        return this.form.descGestaAtual
      },

      set(newValue) {
        this.form.descGestaAtual = newValue.description
        this.form.idGestaAtual = newValue.id
      }
    },
    preNatal: {
      get() {
        return this.form.descInicioTrimestre
      },

      set(newValue) {
        this.form.idInicioTrimestre = newValue.id
        this.form.descInicioTrimestre = newValue.description
      }
    },
    risco: {
      get() {
        return this.form.descRiscoGestacao
      },

      set(newValue) {
        this.form.idRiscoGestacao = newValue.id
        this.form.descRiscoGestacao = newValue.description
      }
    },
    typePregnant: {
      get() {
        if (this.form.idTipoGestacao === 0) {
          return ''
        } else if (this.form.idTipoGestacao === 1) {
          return 'Simples'
        } else if (this.form.idTipoGestacao === 2) {
          return 'Gemelar'
        } else {
          return this.form.idTipoGestacao
        }
      },
      set(newValue) {
        this.form.idTipoGestacao = newValue.id
        this.form.descTipoGestacao = newValue.description
      }
    },
    imcClassification() {
      const imc = this.form.imcGestante
      if (!imc) {
        return {
          message: 'Aguardando IMC...',
          variant: 'secondary'
        }
      }

      if (imc < 17) {
        return { message: 'Muito abaixo do peso', variant: 'danger' }
      }

      if (imc > 17 && imc <= 18.49) {
        return { message: 'Abaixo do peso', variant: 'warning' }
      }

      if (imc >= 18.5 && imc <= 24.99) {
        return { message: 'Peso normal', variant: 'success' }
      }

      if (imc >= 25 && imc <= 29.99) {
        return { message: 'Acima do peso', variant: 'warning' }
      }

      if (imc >= 30 && imc <= 34.99) {
        return { message: 'Obesidade I', variant: 'danger' }
      }

      if (imc > 34.99) {
        return { message: 'Obesidade II', variant: 'danger' }
      }

      return ''
    },
    cidFilter() {
      if (this.filter) {
        let count = 0
        const cidsFiltered = []
        this.cids.every((element) => {
          if (count < 20) {
            /* const desc = element.descCid.toLowerCase()
            const contain = desc.includes(this.filter.toLowerCase()) */

            const code = element.codCid.toLowerCase()
            const description = element.descCid.toLowerCase()

            const codeVerif = code.includes(this.filter.toLowerCase())

            const descriptionVerif = description.includes(
              this.filter.toLowerCase()
            )

            if (codeVerif || descriptionVerif) {
              count++
              cidsFiltered.push(element)
            }

            return true
          } else {
            return false
          }
        })

        return cidsFiltered
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>
.tagItem {
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  background-color: #089bab;
  height: 30px;
  padding: 0 10px 0 10px;
  border-radius: 3px;
  margin: 10px 0 0 0;
}

.tagItem:not(:first-child) {
  margin-left: 10px;
}

.buttonSheetFace {
  margin-top: 12px;
  border-radius: 6px;
  padding: 8px;
  background: #089bab;
  color: white;
  border: none;
}

.rowButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.buttonSheetFaceDisabled {
  margin-top: 12px;
  border-radius: 6px;
  padding: 8px;
  background: grey;
  color: white;
  border: none;
}
</style>
