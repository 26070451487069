<template>
  <div class="module">
    <div class="details">
      <div
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </div>
      <div class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactiveForwardings"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>

      <favorites-select
        :title="'Codig'"
        :favorites="componentFavorites"
        :titleProperty="'id'"
        :descriptionProperty="'descTitulo'"
        @on-favorite-chosen="forwardingSelected"
      />

      <div style="width: 25%; position: relative; margin: 0 8px">
        <div style="position: relative">
          <b-form-input
            class="details__input"
            autocomplete="off"
            v-model="filter"
            placeholder="Pesquisar"
            v-uppercase
          ></b-form-input>
          <search-result :results="searchForms" v-if="filter.length > 0" />
          <div
            @click="clearSearch"
            v-if="filter.length > 0"
            style="
              width: 100vw;
              height: 100vh;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 12;
            "
          ></div>
        </div>
      </div>

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>

    <quick-access
      v-if="componentQuickAccess.length > 0"
      :quickAccesses="componentQuickAccess"
      title-property="descTitulo"
      @on-quick-access-chosen="forwardingSelected"
    />

    <div class="content" v-if="componentInfo.aberto === 0">
      <forwarding-form
        v-if="showForm"
        :selectedForwarding="selectedForwarding"
        :idProntuario="idProntuario"
        :priorities="priorities"
        :specialties="specialties"
        :forwarding-types="forwardingTypes"
        :cids="cids"
        @closeForm="handleCloseForm"
      />
      <forwardings-list
        :header-color="componentInfo.corHexadecimal"
        :forwardings-list="forwardingsListToShow"
        :priorities="priorities"
        :specialties="specialties"
        :forwarding-types="forwardingTypes"
        :cids="cids"
        :patient="patientInfo"
      />
    </div>

    <div class="footer">
      <b-button variant="success" @click="handleOpenForm"
        >Novo Encaminhamento</b-button
      >
    </div>
  </div>
</template>

<script>
import QuickAccess from '../../shared/QuickAccess'
import FavoritesSelect from '../../shared/FavoritesSelect'
import ForwardingForm from './components/Form'
import ForwardingsList from './components/ForwardingsList'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Forwardings',
  props: [
    'componentInfo',
    'idProntuario',
    'idPaciente',
    'idMedico',
    'patientInfo'
  ],
  components: {
    QuickAccess,
    FavoritesSelect,
    ForwardingForm,
    ForwardingsList
  },
  data() {
    return {
      showInactiveForwardings: false,
      filter: '',
      selectedForwarding: '',
      specialties: [],
      priorities: [],
      forwardingTypes: [],
      cids: [],
      showForm: false
    }
  },

  async mounted() {
    const specialties = await this.getSpecialties()
    const priorities = await this.getPriorities()
    const forwardingTypes = await this.getForwardingtTypes()
    const cids = await this.getCids()

    this.specialties = specialties
    this.priorities = priorities
    this.forwardingTypes = forwardingTypes
    this.cids = cids
  },
  computed: {
    ...mapGetters({
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    componentFavorites() {
      return this.componentInfo.favorites.filter(
        (favorite) => favorite.location === 'favorite'
      )
    },
    componentQuickAccess() {
      return this.componentInfo.favorites.filter(
        (favorite) => favorite.location === 'quickAccess'
      )
    },
    forwardingsListToShow() {
      if (this.showInactiveForwardings) {
        return this.componentInfo.data
      } else {
        return this.componentInfo.data.filter(
          (forwarding) => forwarding.ativo === 0
        )
      }
    }
  },
  methods: {
    ...mapActions({
      getSpecialties: 'Forwardings/getSpecialtiesAction',
      getPriorities: 'Forwardings/getPrioritiesAction',
      getForwardingtTypes: 'Forwardings/getForwardingTypesAction',
      getCids: 'Forwardings/getCidsAction'
    }),
    handleOpenForm() {
      this.showForm = true
    },
    handleCloseForm() {
      this.showForm = false
      this.selectedForwarding = ''
    },
    forwardingSelected(forwarding) {
      this.selectedForwarding = forwarding
      this.handleOpenForm()
    },
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../shared/generalModuleStyles.scss';
.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}
.module {
  margin: 32px 0;
}

.details {
  display: flex;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
  &__input {
    &::placeholder {
      text-transform: none;
      color: #000 !important;
    }
    border-color: rgb(8, 155, 171) !important;

    background-color: white !important;
  }

  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}

.content {
  background: #fff;
}

.footer {
  padding: 8px 0 8px 8px;
}
</style>
