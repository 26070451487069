<template>
  <b-container>
    <b-modal
      id="find-services"
      ref="find-services"
      title="Pesquisar serviços"
      cancel-title="Não"
      ok-title="Sim"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      @close="closeModal"
    >
      <b-col lg="12">
        <search-service
          :idUsuarioConvenio="idUsuarioConvenio"
          @add-data="addService"
        />
        <div class="table">
          <div class="headerList">
            <span>Código</span>
            <span>Descrição</span>
            <span>Valor</span>
            <span>Inicio Vigencia</span>
            <span>Preferencial</span>
            <span>Ativo</span>
            <span>Ação</span>
          </div>

          <div class="dataList" v-for="service in services" :key="service.id">
            <span>{{ service.codigoServico }}</span>
            <span>{{ service.descServico }}</span>
            <span>
              <b-form-input
                v-currency="{
                  locale: 'pt-BR',
                  currency: null,
                  precision: 2
                }"
                v-model="service.valorServico"
            /></span>
            <b-form-input
              type="date"
              class="inicioVigencia"
              :value="formatDate(service.inicioVigencia)"
            />
            <div @click.prevent="togglePreferential(service)">
              <b-form-checkbox
                :checked="service.preferencial === 0"
              ></b-form-checkbox>
            </div>
            <div @click.prevent="toggleActive(service)">
              <b-form-checkbox :checked="service.ativo === 0"></b-form-checkbox>
            </div>
            <b-button variant="danger" @click="handleButtonClick(service)"
              >Remover</b-button
            >
          </div>
        </div>
      </b-col>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="closeModal()">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="saveAll()">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import API from '../../services/index'
import SearchService from './SearchService.vue'

export default {
  name: 'FindServicesModal',
  props: ['idUsuarioConvenio'],
  components: { SearchService },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      services: [],
      servicesToRemove: [],
      servicesExists: [],
      servicesToAdd: []
    }
  },

  watch: {
    idUsuarioConvenio(newValue) {
      if (newValue !== null) {
        this.getServicesByDoctorBoundWithHealthInsurance()
      }
    }
  },

  mounted() {
    if (this.idUsuarioConvenio !== null) {
      this.getServicesByDoctorBoundWithHealthInsurance()
    }
  },
  methods: {
    toggleActive(serviceToEdit) {
      this.services.forEach((service) => {
        if (service.codigoServico === serviceToEdit.codigoServico) {
          service.ativo = service.ativo === 0 ? 1 : 0
        }
      })
    },
    togglePreferential(serviceToEdit) {
      const serviceToEditIndex = this.services.findIndex(
        (service) => service.codigoServico === serviceToEdit.codigoServico
      )

      this.services.forEach((service, index) => {
        if (serviceToEditIndex === index) {
          service.preferencial = 0
        } else {
          service.preferencial = 1
        }
      })
    },
    async addService(serviceSelected) {
      const serviceExist = this.servicesExists.find(
        (service) => service.idServico === serviceSelected.id
      )
      const serviceIsAdded = this.services.find(
        (service) => service.idServico === serviceSelected.id
      )
      if (serviceExist && serviceIsAdded) {
        return this.$toast.error('Serviço já adicionado')
      }

      if (serviceIsAdded) {
        return this.$toast.error('Serviço já adicionado')
      }

      const serviceToAdd = {
        codigoServico: serviceSelected.codigoServico,
        descServico: serviceSelected.descServico,
        valorServico: 100,
        inicioVigencia: new Date().toISOString().split('T')[0],
        preferencial: this.services.length > 0 ? 1 : 0,
        ativo: 0,
        idUsuarioConvenio: this.idUsuarioConvenio,
        idServico: serviceSelected.id
      }

      this.services.push(serviceToAdd)
      this.servicesToAdd.push(serviceToAdd)
    },
    async getServicesByDoctorBoundWithHealthInsurance() {
      try {
        const apiResponse = await API.get(
          `/api/UsuarioServicoValor/ObtemItemServicoPorUsuarioConvenio/${this.idUsuarioConvenio}`
        )
        this.services = apiResponse.data.object
        this.services.forEach((service) => {
          service.valorServico = this.changeDecimalSeparator(
            service.valorServico,
            'comma'
          )
        })
      } catch (error) {
        this.$toast.error('Não foi encontrado serviço')
      }
      this.servicesExists = [...this.services]
    },
    changeDecimalSeparator(value, separator) {
      let valueFormated
      if (separator === 'comma') {
        valueFormated = value.toString().replace('.', ',')
      } else {
        valueFormated = value.replace(',', '.')
        parseFloat(valueFormated)
      }
      return valueFormated
    },
    handleButtonClick(serviceSelected) {
      const serviceExist = this.servicesExists.find(
        (service) => service.idServico === serviceSelected.idServico
      )

      if (serviceExist) {
        this.servicesToRemove.push(serviceSelected)
      }

      this.services = this.services.filter(
        (service) => service.idServico !== serviceSelected.idServico
      )
    },
    removeServices() {
      this.servicesToRemove.forEach(async (service) => {
        try {
          await API.delete(`/api/UsuarioServicoValor/Remover`, {
            data: { object: service }
          })
          this.$toast.success('Serviço removido com sucesso')
        } catch (error) {
          this.$toast.error('Ocorreu erro ao remover o serviço')
        }
      })
    },
    addServices() {
      this.servicesToAdd.forEach(async (service) => {
        if (service.valorServico.typeof === 'string')
          service.valorServico = this.changeDecimalSeparator(
            service.valorServico
          )
        try {
          await API.post(`/api/UsuarioServicoValor/Adicionar`, {
            object: service
          })
          this.$toast.success('Serviço adicionado com sucesso')
        } catch (error) {
          this.$toast.error('Serviço adicionado com sucesso')
        }
      })
    },
    editServices() {
      this.services.forEach(async (service) => {
        if (typeof service.valorServico === 'string')
          service.valorServico = this.changeDecimalSeparator(
            service.valorServico
          )
        try {
          await API.put('/api/UsuarioServicoValor/Editar', { object: service })
        } catch (error) {
          this.$toast.error('Ocorreu um erro')
        }
      })
    },
    formatDate(date) {
      return date.split('T')[0]
    },
    resetData() {
      this.services = []
      this.servicesToRemove = []
      this.servicesExists = []
      this.servicesToAdd = []
    },
    async saveAll() {
      if (this.servicesToRemove.length > 0) this.removeServices()
      if (this.servicesToAdd.length > 0) this.addServices()
      this.editServices()
      this.resetData()
      this.closeModal()
    },
    closeModal() {
      this.resetData()
      this.$bvModal.hide('find-services')
      this.$emit('modal-closed')
    }
  }
}
</script>

<style scoped lang="scss">
.sameLevel {
  display: flex;
  align-items: center;
}
.table {
  background: white;
  border-radius: 6px;
  height: 400px;
  overflow-y: auto;
}

.headerList {
  z-index: 1;
  position: sticky;
  height: 50px;
  top: 0;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 2fr repeat(5, 1fr);
  gap: 10px;
  border-bottom: 2px solid #c4c8d1;
  justify-items: center;
  align-items: center;
  background: #089bab;
  color: #fff;

  span:nth-child(3) {
    justify-self: flex-start;
  }

  span {
    display: block;
    font-size: 14px;
  }
}

.dataList {
  padding: 10px 0;
  z-index: 2;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr 2fr repeat(5, 1fr);
  gap: 10px;
  align-items: center;
  color: #000;
  border-bottom: 1px solid #c4c8d1;

  justify-items: center;

  transition: box-shadow 0.3s;
}
</style>
