<template>
  <div
    :class="[
      'info-container',
      infoOpened ? 'info-container-opened' : '',
      connected ? 'info-container-connected' : ''
    ]"
    @click="infoOpened = true"
    @mouseleave="infoOpened = false"
  >
    <div class="icon-video-container" v-if="!infoOpened">
      <b-icon icon="camera-video-fill" font-scale="2" variant="primary" />
      <span class="bg-danger dots" />
    </div>

    <div class="connection-info" v-if="infoOpened">
      <div v-if="!connected">
        <p>
          Nenhuma sala de vídeo aberta.
          <br />
          Para abrir uma nova sala, clique no botão abaixo
          <br />
          Um e-mail será enviado para o paciente com o acesso da sala
        </p>

        <b-button variant="primary" size="lg" @click="showVideo"
          >Iniciar chamada de vídeo</b-button
        >
      </div>

      <div v-if="connected">
        <p>
          Sala de vídeo aberta.
          <br />
          O link de acesso foi enviado para o paciente, no e-mail:
          <br />
          <strong>{{ patientEmail }}</strong>
        </p>

        <p>
          Caso o e-mail apresente algum problema, envie este link para o
          paciente:
          <br />
          <a href="">{{ defaultLink + identification }} </a>
          <br />
          <br />
          <b-button
            variant="primary"
            size="lg"
            v-clipboard:copy="defaultLink + identification"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
          >
            Copiar link
          </b-button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'VideoTooltip',
  props: ['identification', 'patientEmail'],
  data() {
    return {
      infoOpened: false,
      defaultLink: 'https://app.doctorpep.com.br/telemedicina/'
    }
  },

  computed: {
    ...mapGetters({
      connected: 'Video/isVideoComponentVisibleState'
    })
  },
  methods: {
    ...mapActions({
      showVideoAction: 'Video/showVideoComponentAction',
      hideVideo: 'Video/hideVideoComponentAction',
      setRoomName: 'Video/setRoomNameAction'
    }),
    onCopy() {
      this.$toast.info('Link de telemedicina copiado')
    },
    onCopyError() {
      this.$toast.error('Erro ao copiar link')
    },
    showVideo() {
      this.showVideoAction()
      this.setRoomName(this.identification)
    }
  },
  beforeDestroy() {
    this.hideVideo()
  }
}
</script>

<style scoped lang="scss">
.info-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  width: 60px;
  height: 30px;
  overflow: hidden;
  transition: all 0.2s ease-in-out !important;
  border-style: solid;
  border-color: rgba($color: #000000, $alpha: 0);
}

.info-container-opened {
  border-style: solid;
  border-color: rgba($color: #000000, $alpha: 0.1);
  background-color: #fff;
  height: 200px;
  width: 25vw;
}

.info-container-connected {
  height: 250px;
}

.icon-video-container {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  animation: increase-opacity 1.5s;
  transition: all 0.5s ease-in-out !important;
}

.dots {
  height: 5px;
  width: 5px;
  font-size: 0;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 5px;
  left: 30px;
  animation: shadow-pulse-dots 1s infinite;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.connection-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
  animation: increase-opacity 0.8s;
  text-align: center;
  font-size: 14px;
}

@keyframes increase-opacity {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
