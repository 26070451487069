import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return dayjs(String(value)).toString()
  }
})
