<template>
  <b-modal
    id="expensesModal"
    header-bg-variant="primary"
    title="Despesas"
    size="xl"
    title-class="text-light"
    ok-title="Salvar"
    hide-footer
    @close="close"
    no-close-on-backdrop
    no-close-on-esc
  >
    <ValidationObserver ref="form" @submit.prevent="saveExpenses()">
      <form class="box-revenue">
        <h3>Manutenção de titulos a pagar:</h3>
        <hr class="divider" />
        <div class="normal">
          <div class="content">
            <label>Clínica:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="clinic"
                :options="clinics"
                label="nmClinica"
                :clearable="false"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <div class="invalid-feedback">
                <span>Informe um pagador!</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <hr class="divider" />
        <div class="revenue-radios">
          <div class="content-radios">
            <label>Tipo (Pagador):</label>
            <div class="box-radio">
              <b-form-radio
                id="checkbox-one"
                v-model="statusOne"
                name="checkbox-one"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Clinica
              </b-form-radio>

              <b-form-radio
                id="checkbox-two"
                v-model="statusTwo"
                name="checkbox-tow"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Profissional de Saude
              </b-form-radio>
            </div>
          </div>
          <div class="content">
            <label>Pagador:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="expenses"
                :options="listExpenses"
                :label="statusTwo === 'accepted' ? 'nmUsuario' : 'nmClinica'"
                :clearable="false"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              ></v-select>
              <div
                v-if="errors.length > 0"
                style="color: rgb(234, 47, 47); font-size: 0.6rem"
              >
                <span>Informe um pagador!</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="box-normal-1">
          <div class="content">
            <label>Fornecedor:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-if="statusFornecedor === 'not_accepted'"
                v-model="fornecedor"
                :options="listFornecedor"
                label="descFornecedor"
                :clearable="false"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              ></v-select>
              <b-input
                ref="inputFornecedor"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-else
                v-model="newFornecedor"
              ></b-input>
              <div
                v-if="errors.length > 0"
                style="color: rgb(234, 47, 47); font-size: 0.6rem"
              >
                <span>Informe um pagador!</span>
              </div>
            </ValidationProvider>
          </div>
          <b-form-checkbox
            id="checkbox-Fornecedor"
            v-model="statusFornecedor"
            name="checkbox-Fornecedor"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Novo Fornecedor
          </b-form-checkbox>
          <div class="content">
            <label>Centro de custo:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-if="statusCentCust === 'not_accepted'"
                v-model="centCust"
                :options="listCentCust"
                label="descCentroCusto"
                :clearable="false"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              ></v-select>
              <b-input
                ref="centerCost"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-else
                v-model="newCentCust"
              ></b-input>
              <div
                v-if="errors.length > 0"
                style="color: rgb(234, 47, 47); font-size: 0.6rem"
              >
                <span>Informe um Centro de custo!</span>
              </div>
            </ValidationProvider>
          </div>
          <b-form-checkbox
            id="checkbox-CentCust"
            v-model="statusCentCust"
            name="checkboxCentCust"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Novo Centro de Custos
          </b-form-checkbox>
        </div>
        <hr class="divider" />
        <div class="box-normal-5">
          <b-form-checkbox
            id="checkbox-type"
            v-model="statusDespFix"
            name="checkbox-type"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Despesa Fixa
          </b-form-checkbox>
          <div v-if="statusDespFix === 'accepted'" class="content">
            <label>Qtde meses recorrência:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                type="number"
                min="0"
                max="1000000000000000000000"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-model="recorrency"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe o numero de meses!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>N do título:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                v-mask="'#######################'"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-model="nTitle"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe o numero do seu título!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Situação:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="situation"
                :options="listSituetion"
                label="descSituacao"
                :clearable="false"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <div class="invalid-feedback">
                <span>Informe a situação!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Tipo:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-if="statusType === 'not_accepted'"
                v-model="typePay"
                :options="listType"
                label="descDespesa"
                :clearable="false"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <b-input
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-else
                v-model="newType"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe um tipo de pagamento!</span>
              </div>
            </ValidationProvider>
          </div>
          <b-form-checkbox
            id="checkbox-type-pay"
            v-model="statusType"
            name="checkbox-type-pay"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Novo Tipo
          </b-form-checkbox>
        </div>
        <div class="box-normal-3">
          <div class="content">
            <label>Valor do Título:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                v-currency="{
                  locale: 'pt-BR',
                  currency: null,
                  precision: 2
                }"
                v-model="valueTitulo"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe o valor!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Juros:</label>
            <b-input
              v-currency="{
                locale: 'pt-BR',
                currency: null,
                precision: 2
              }"
              v-model="juros"
            ></b-input>
          </div>
          <div class="content">
            <label>Multa:</label>
            <b-input
              v-currency="{
                locale: 'pt-BR',
                currency: null,
                precision: 2
              }"
              v-model="multa"
            ></b-input>
          </div>
          <div class="content">
            <label>Desconto:</label>
            <b-input
              v-currency="{
                locale: 'pt-BR',
                currency: null,
                precision: 2
              }"
              v-model="discount"
            ></b-input>
          </div>
        </div>
        <div class="box-normal-3">
          <div class="content">
            <label>Data emissão:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                :style="
                  isIOS
                    ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 40px'
                    : ''
                "
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
                v-model="dateEmit"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe a data de emissão!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Data vencimento:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                :style="
                  isIOS
                    ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 40px'
                    : ''
                "
                type="date"
                :min="dateEmit"
                v-model="dateFinaly"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe a data de encerramento!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Data Pagamento:</label>
            <b-input
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 40px'
                  : ''
              "
              type="date"
              v-model="dateUltPayment"
            ></b-input>
          </div>
          <div class="content">
            <label>Data baixa:</label>
            <b-input
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 40px'
                  : ''
              "
              type="date"
              v-model="dateDown"
            ></b-input>
          </div>
        </div>
        <div class="revenue-radios">
          <div class="content">
            <label>Linha digitavel:</label>
            <b-input
              v-mask="'######.##### #####.###### #####.###### # ##############'"
              type="text"
              v-model="lineDigital"
            ></b-input>
          </div>
          <div class="content">
            <label>Complemento:</label>
            <b-input type="text" v-model="complement"></b-input>
          </div>
        </div>
        <hr class="divider" />
        <div class="box-button">
          <button @click.prevent="close()" class="b-cancel">Cancelar</button>
          <button type="submit" class="b-save">Salvar</button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'
import { VMoney } from 'v-money'
import moment from 'moment'
export default {
  props: ['user', 'admAndRecp', 'data', 'types'],
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      statusOne: 'accepted',
      statusTwo: 'not_accepted',
      statusType: 'not_accepted',
      situation: {},
      nTitle: null,
      expenses: '',
      typePay: {},
      newType: '',
      listType: [],
      listExpenses: [],
      listFornecedor: [],
      listCentCust: [],
      listSituetion: [],
      fornecedor: '',
      newFornecedor: '',
      centCust: '',
      newCentCust: '',
      statusFornecedor: 'not_accepted',
      statusDespFix: 'not_accepted',
      statusCentCust: 'not_accepted',
      valueTitulo:
        this.types !== false
          ? this.changeDecimalSeparator(this.data.valorTitulo, 'comma')
          : '',
      juros:
        this.types !== false
          ? this.changeDecimalSeparator(this.data.juros, 'comma')
          : '',
      discount:
        this.types !== false
          ? this.changeDecimalSeparator(this.data.desconto, 'comma')
          : '',
      multa:
        this.types !== false
          ? this.changeDecimalSeparator(this.data.multa, 'comma')
          : '',
      recorrency: 0,
      dateEmit: moment(new Date()).format('YYYY-MM-DD'),
      dateFinaly: '',
      dateUltPayment: '',
      dateDown: '',
      lineDigital: 0,
      complement: '',
      clinic: this.user.clinica_usuario[0],
      clinics: this.user.clinica_usuario,
      isIOS: false
    }
  },
  directives: { money: VMoney },
  watch: {
    statusFornecedor(newValue) {
      if (newValue === 'accepted') {
        this.fornecedor = ''
        this.$refs.inputFornecedor.focus()
      } else {
        this.cpfORcnpj = ''
        this.$refs.inputFornecedor.focus()
      }
    },
    statusOne(newValue) {
      if (newValue === 'accepted' || this.statusOne === 'accepted') {
        this.statusTwo = 'not_accepted'
        this.expenses = this.user.clinica_usuario[0]
        this.listExpenses = this.user.clinica_usuario
      }
    },
    statusTwo(newValue) {
      if (newValue === 'accepted' || this.statusTwo === 'accepted') {
        if (
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            1 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            4 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            5
        ) {
          this.statusOne = 'not_accepted'
          this.expenses = this.user.clinica_usuario[0]
          this.listExpenses = [this.user.clinica_usuario[0]]
        }
        if (
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            2 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            3
        ) {
          this.statusOne = 'not_accepted'
          this.expenses = this.admAndRecp[0]
          this.listExpenses = this.admAndRecp
        }
      }
    },
    situation(newValue) {
      if (newValue.id === 2) {
        this.dateUltPayment = moment(new Date()).format('YYYY-MM-DD')
        this.dateDown = moment(new Date()).format('YYYY-MM-DD')
      }
    },
    async clinic(newValue) {
      this.listCentCust = await this.getCenterCost({
        id_clinica: this.clinic.idClinica
      })
      this.listType = await this.getTypesExpenses({
        id_clinica: this.clinic.idClinica
      })
      this.typePay = this.listType[0]
      this.listFornecedor = await this.getSuppliers({
        id_clinica: this.clinic.idClinica
      })
    }
  },
  methods: {
    ...mapActions({
      getStatusFinance: 'Finance/getStatusFinance',
      getSuppliers: 'Finance/getSuppliers',
      getCenterCost: 'Finance/getCenterCost',
      getTypesExpenses: 'Finance/getTypesExpenses',
      setNewTypePay: 'Finance/setNewTypePay',
      setNewCentCost: 'Finance/setNewCentCost',
      setNewSuppliers: 'Finance/setNewSuppliers',
      setNewExpenses: 'Finance/setNewExpenses'
    }),
    changeDecimalSeparator(value, separator) {
      let valueFormated
      if (separator === 'comma') {
        valueFormated = value.toString().replace('.', ',')
      } else {
        valueFormated = value.replace(',', '.')
        valueFormated = parseFloat(valueFormated)
      }
      return valueFormated
    },
    typeRecebFunction() {
      if (this.statusOne === 'accepted') {
        return 2
      } else if (this.statusTwo === 'accepted') {
        return 1
      }
    },
    getStatusFinanceiro(valuer) {
      const list = this.listSituetion.filter((object) => object.id === valuer)
      return list[0]
    },
    getFornecedor(valuer) {
      const list = this.listFornecedor.filter((object) => object.id === valuer)
      return list[0]
    },
    getDespesaFixa(valuer) {
      if (valuer !== 0 || valuer !== undefined || valuer !== null) {
        return 'accepted'
      } else {
        return 'not_accepted'
      }
    },
    getCenter(valuer) {
      const list = this.listCentCust.filter((object) => object.id === valuer)
      return list[0]
    },
    getType(valuer) {
      const list = this.listType.filter((object) => object.id === valuer)
      return list[0]
    },
    formatData(date) {
      if (date === null || date === '' || date === undefined) {
        return ''
      } else {
        return moment(date).format('YYYY-MM-DD')
      }
    },
    setValuesInFields() {
      this.handleTypeReceb(this.data.tipoResponsavel)
      this.expenses = this.handleExpenseEdit(this.data)
      this.nTitle = this.data.numeroTitulo
      this.fornecedor = this.getFornecedor(this.data.idFornecedor)
      this.centCust = this.getCenter(this.data.idCentroCusto)
      this.situation = this.getStatusFinanceiro(this.data.idStatusFinanceiro)
      this.typePay = this.getType(this.data.idTipoDespesa)
      this.dateEmit = this.formatData(this.data.dtEmissao)
      this.dateFinaly = this.formatData(this.data.dtVencimento)
      this.dateUltPayment = this.formatData(this.data.dtUltimoPagamento)
      this.dateDown = this.formatData(this.data.dtBaixa)
      this.complement = this.data.complemento
      this.lineDigital = parseInt(this.data.linhaDigitavel)
      this.statusDespFix = this.getDespesaFixa(this.data.despesaFixa)
      this.recorrency = this.data.qtdMesesFixa
    },
    handleExpenseEdit(data) {
      if (data.tipoResponsavel === 2) {
        const object = {
          idClinica: data.idClinica,
          nmClinica: data.nmResPagador
        }
        return object
      }

      if (data.tipoResponsavel === 1) {
        const object = {
          idUsuario: data.idUsuario,
          nmUsuario: data.nmResPagador
        }
        return object
      }
    },
    handleTypeReceb(type) {
      if (type === 1) {
        this.statusOne = 'not_accepted'
        this.statusTwo = 'accepted'
      }
      if (type === 2) {
        this.statusOne = 'accepted'
        this.statusTwo = 'not_accepted'
      }
    },
    close() {
      this.$emit('close')
    },
    isIOSOrMac() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|macintosh/i.test(userAgent)
    },
    saveExpenses: async function () {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        return this.$toast.error('Verifique os campos obrigatórios!')
      } else {
        if (this.statusFornecedor === 'accepted') {
          const response = await this.setNewSuppliers({
            descFornecedor: this.newFornecedor,
            ativo: 0,
            dtInclusao: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            idClinica: this.clinic.idClinica
          })

          if (response === false) {
            return this.$toast.error('Não foi possivel salvar o Fornecedor!')
          } else {
            this.fornecedor = response
          }
        }

        if (this.statusCentCust === 'accepted') {
          const response = await this.setNewCentCost({
            idClinica: this.clinic.idClinica,
            descCentroCusto: this.newCentCust,
            ativo: 0
          })

          if (response === false) {
            return this.$toast.error(
              'Não foi possivel salvar o centro de custos!'
            )
          } else {
            this.centCust = response
          }
        }
        if (this.statusType === 'accepted') {
          const response = await this.setNewTypePay({
            idClinica: this.clinic.idClinica,
            descDespesa: this.newType
          })

          if (response === false) {
            return this.$toast.error(
              'Não foi possivel salvar o tipo de pagamento!'
            )
          } else {
            this.typePay = response
          }
        }
        if (this.types === true) {
          if (this.statusOne === 'accepted') {
            const formOne = {
              id: this.data.id,
              tipoResponsavel: this.typeRecebFunction(),
              idFornecedor: this.fornecedor.id,
              idStatusFinanceiro: this.situation.id,
              idCentroCusto: this.centCust.id,
              numeroTitulo: this.nTitle,
              valorTitulo:
                this.valueTitulo === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueTitulo, ''),
              juros:
                this.juros === ''
                  ? 0
                  : this.changeDecimalSeparator(this.juros, ''),
              multa:
                this.multa === ''
                  ? 0
                  : this.changeDecimalSeparator(this.multa, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              dtEmissao: this.dateEmit,
              dtBaixa: this.dateDown,
              dtVencimento: this.dateFinaly,
              dtUltimoPagamento: this.dateUltPayment,
              complemento: this.complement,
              linhaDigitavel: this.lineDigital,
              idClinicaResPagador: this.expenses.idClinica,
              despesaFixa: this.statusDespFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency,
              idDespesaOrigem: 0,
              idTipoDespesa: this.typePay.id,
              descDespesa: this.typePay.descDespesa,
              idClinica: this.clinic.idClinica
            }
            const response = await this.setNewExpenses(formOne)
            if (response) {
              this.$toast.success('Despesa alterada com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel alterar a Despesa!')
            }
          }
          if (this.statusTwo === 'accepted') {
            const formOne = {
              id: this.data.id,
              tipoResponsavel: this.typeRecebFunction(),
              idFornecedor: this.fornecedor.id,
              idStatusFinanceiro: this.situation.id,
              idCentroCusto: this.centCust.id,
              numeroTitulo: this.nTitle,
              valorTitulo:
                this.valueTitulo === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueTitulo, ''),
              juros:
                this.juros === ''
                  ? 0
                  : this.changeDecimalSeparator(this.juros, ''),
              multa:
                this.multa === ''
                  ? 0
                  : this.changeDecimalSeparator(this.multa, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              dtEmissao: this.dateEmit,
              dtBaixa: this.dateDown,
              dtVencimento: this.dateFinaly,
              dtUltimoPagamento: this.dateUltPayment,
              complemento: this.complement,
              linhaDigitavel: this.lineDigital,
              idUsuarioResPagador: this.expenses.idUsuario,
              despesaFixa: this.statusDespFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency,
              idDespesaOrigem: 0,
              idTipoDespesa: this.typePay.id,
              descDespesa: this.typePay.descDespesa,
              idClinica: this.clinic.idClinica
            }
            const response = await this.setNewExpenses(formOne)
            if (response) {
              this.$toast.success('Despesa alterada com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel alterar a Despesa!')
            }
          }
        } else {
          if (this.statusOne === 'accepted') {
            const formOne = {
              tipoResponsavel: this.typeRecebFunction(),
              idFornecedor: this.fornecedor.id,
              idStatusFinanceiro: this.situation.id,
              idCentroCusto: this.centCust.id,
              numeroTitulo: this.nTitle,
              valorTitulo:
                this.valueTitulo === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueTitulo, ''),
              juros:
                this.juros === ''
                  ? 0
                  : this.changeDecimalSeparator(this.juros, ''),
              multa:
                this.multa === ''
                  ? 0
                  : this.changeDecimalSeparator(this.multa, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              dtEmissao: this.dateEmit,
              dtBaixa: this.dateDown,
              dtVencimento: this.dateFinaly,
              dtUltimoPagamento: this.dateUltPayment,
              complemento: this.complement,
              linhaDigitavel: this.lineDigital,
              idClinicaResPagador: this.expenses.idClinica,
              despesaFixa: this.statusDespFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency,
              idDespesaOrigem: 0,
              idTipoDespesa: this.typePay.id,
              descDespesa: this.typePay.descDespesa,
              idClinica: this.clinic.idClinica
            }
            const response = await this.setNewExpenses(formOne)
            if (response) {
              this.$toast.success('Despesa salva com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel salvar a Despesa!')
            }
          }
          if (this.statusTwo === 'accepted') {
            const formOne = {
              tipoResponsavel: this.typeRecebFunction(),
              idFornecedor: this.fornecedor.id,
              idStatusFinanceiro: this.situation.id,
              idCentroCusto: this.centCust.id,
              numeroTitulo: this.nTitle,
              valorTitulo:
                this.valueTitulo === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueTitulo, ''),
              juros:
                this.juros === ''
                  ? 0
                  : this.changeDecimalSeparator(this.juros, ''),
              multa:
                this.multa === ''
                  ? 0
                  : this.changeDecimalSeparator(this.multa, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              dtEmissao: this.dateEmit,
              dtBaixa: this.dateDown,
              dtVencimento: this.dateFinaly,
              dtUltimoPagamento: this.dateUltPayment,
              complemento: this.complement,
              linhaDigitavel: this.lineDigital,
              idUsuarioResPagador: this.expenses.idUsuario,
              despesaFixa: this.statusDespFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency,
              idDespesaOrigem: 0,
              idTipoDespesa: this.typePay.id,
              descDespesa: this.typePay.descDespesa,
              idClinica: this.clinic.idClinica
            }
            const response = await this.setNewExpenses(formOne)
            if (response) {
              this.$toast.success('Despesa salva com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel salvar a Despesa!')
            }
          }
        }
      }
    }
  },
  async mounted() {
    this.$bvModal.show('expensesModal')
    this.listExpenses = this.user.clinica_usuario
    this.listSituetion = await this.getStatusFinance()
    this.situation = this.listSituetion[0]
    this.listCentCust = await this.getCenterCost({
      id_clinica: this.clinic.idClinica
    })
    this.listType = await this.getTypesExpenses({
      id_clinica: this.clinic.idClinica
    })
    this.typePay = this.listType[0]
    this.listFornecedor = await this.getSuppliers({
      id_clinica: this.clinic.idClinica
    })
    if (this.isIOSOrMac()) {
      this.isIOS = true
    }
    if (this.types === true) {
      this.setValuesInFields()
    }
    if (this.types === false) {
      await this.$refs.form.validate()
    }
  }
}
</script>
<style scoped>
.box-revenue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
}

.content-radios {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(253, 253, 253);
}

.revenue-radios {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.box-radio {
  display: flex;
  align-items: center;
  gap: 20px;
}
.normal {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}
.box-normal-1 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 2fr 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
}

.box-normal-2 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.box-normal-3 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  align-items: center;
  justify-content: center;
}
.box-normal-4 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 3fr 2fr 2fr;
  align-items: center;
  justify-content: center;
}

.box-normal-5 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.box-button {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.b-cancel {
  color: white;
  padding: 8px;
  border: none;
  border-radius: 10px;
  background-color: rgb(178, 48, 30);
}

.b-save {
  border: none;
  color: white;
  background-color: rgb(1, 122, 143);
  padding: 8px;
  border-radius: 10px;
}

@media (max-width: 990px) {
  .revenue-radios {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .box-normal-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .box-normal-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .box-normal-5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .normal {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .normal-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .normal-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .normal-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
</style>
