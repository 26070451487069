<template>
  <b-container>
    <b-modal
      id="agendar-paciente"
      ref="agendarPaciente"
      hide-footer
      title="Agendamento de paciente"
      header-bg-variant="primary"
      cancel-title="sair"
      ok-title="salvar"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      @close="cancelar"
    >
      <b-col lg="12">
        <b-row id="col">
          <b-col md="8" data-v-step="1c">
            <SearchPatient
              ref="searchInput"
              :doctor="doctor"
              :clinica="clinica"
              @selectedPatient="selectedPatient"
              :isNewPatient="isNewPatient"
              :isDisabled="isDataScheduleDisabled()"
            />
          </b-col>
          <b-col md="3" id="form-check">
            <b-form-group>
              <b-form-checkbox
                data-v-step="2c"
                :disabled="isDataScheduleDisabled()"
                v-model="isNewPatient"
                @change="focusInput"
                >Novo Paciente</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-icon
            @click="openTour()"
            style="cursor: pointer"
            icon="patch-question-fill"
            font-scale="1.5"
            title="Click aqui para ativar o Tour dessa pagina!"
          ></b-icon>
        </b-row>
        <b-row>
          <ValidationObserver ref="form" @submit.prevent="onSubmit">
            <form novalidate>
              <b-row>
                <b-form-group
                  class="col-md-3"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div class="photo">
                    <img
                      :src="
                        avatarUrl
                          ? avatarUrl
                          : avatar
                          ? avatar.result
                          : require('../../../assets/images/user/01.jpg')
                      "
                      :class="avatar || avatarUrl ? 'avatar-100' : 'avatar-50'"
                    />
                    <input
                      type="file"
                      ref="fileInput"
                      style="display: none"
                      v-on:change="changeImage"
                      :disabled="isDataScheduleDisabled()"
                    />
                    <b-button
                      :disabled="isDataScheduleDisabled()"
                      size="sm"
                      variant="primary"
                      @click="$refs.fileInput.click()"
                      >{{
                        this.avatar.result || avatarUrl
                          ? 'Trocar foto'
                          : 'Selecionar foto'
                      }}</b-button
                    >
                  </div>
                </b-form-group>
                <b-row class="col-md-9">
                  <b-form-group
                    class="col-md-12"
                    label="Nome:"
                    label-for="nome"
                  >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-input
                        :style="
                          isIOS
                            ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-right: 0px; height: 33px'
                            : ''
                        "
                        autocomplete="noautocomplete"
                        ref="name"
                        v-model="form.nome"
                        type="text"
                        :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                        v-uppercase
                        :class="
                          errors.length > 0 || (isNewPatient && !form.nome)
                            ? ' is-invalid'
                            : ''
                        "
                      ></b-form-input>
                      <div class="invalid-feedback">
                        <span>Informe o nome do paciente</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group
                    class="col-md-12"
                    label="Nome da Mãe:"
                    label-for="nomeMae"
                  >
                    <b-form-input
                      v-if="isIOS"
                      autocomplete="noautocomplete"
                      v-model="form.nomeMae"
                      type="text"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-uppercase
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 35px;
                      "
                    ></b-form-input>
                    <b-form-input
                      v-else
                      autocomplete="noautocomplete"
                      v-model="form.nomeMae"
                      type="text"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-uppercase
                      :style="
                        isNewPatient && !form.nomeMae
                          ? 'borderColor: #9c27b0'
                          : ''
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-row>
                <b-form-group class="col-md-4" label="CPF:" label-for="cpf">
                  <ValidationProvider
                    name="cpf"
                    rules="cpfIsValid"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-if="isIOS"
                      autocomplete="noautocomplete"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      type="text"
                      name="cpf"
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 35px;
                      "
                    ></b-form-input>
                    <b-form-input
                      v-else
                      autocomplete="noautocomplete"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      type="text"
                      name="cpf"
                      :style="
                        isNewPatient && !form.cpf ? 'borderColor: #9c27b0' : ''
                      "
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>Informe um CPF válido</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Ficha do Paciente:"
                  label-for="ficha"
                >
                  <b-form-input
                    v-if="isIOS"
                    autocomplete="noautocomplete"
                    v-model="form.fichaPaciente"
                    type="text"
                    :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                    name="ficha"
                    ref="ficha"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  ></b-form-input>
                  <b-form-input
                    v-else
                    autocomplete="noautocomplete"
                    v-model="form.fichaPaciente"
                    type="text"
                    :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                    name="ficha"
                    ref="ficha"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-4" label-for="sexo" label="Sexo:">
                  <v-select
                    v-model="form.idSexo"
                    :options="genders"
                    :reduce="(descSexo) => descSexo.id"
                    :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                    label="descSexo"
                    :style="
                      isNewPatient && !form.idSexo ? 'borderColor: #9c27b0' : ''
                    "
                    :clearable="false"
                  ></v-select>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Celular:"
                  label-for="celular"
                >
                  <ValidationProvider
                    name="celular"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-if="isIOS"
                      autocomplete="noautocomplete"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-model="form.telCelular"
                      v-mask="'(##)# #### - ####'"
                      type="text"
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 33px;
                      "
                    ></b-form-input>
                    <b-form-input
                      v-else
                      autocomplete="noautocomplete"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-model="form.telCelular"
                      v-mask="'(##)# #### - ####'"
                      type="text"
                      :class="
                        errors?.length > 0 || (isNewPatient && !form.telCelular)
                          ? ' is-invalid'
                          : ''
                      "
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>Informe o telefone do paciente</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Data de nascimento:"
                  label-for="dtNascimento"
                >
                  <ValidationProvider
                    rules="dateIsValid"
                    v-slot="{ errors }"
                    name="BirthDate"
                  >
                    <b-form-input
                      v-if="isIOS"
                      name="BirthDate"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-model="form.dtNascimento"
                      type="text"
                      :class="errors?.length > 0 ? ' is-invalid' : ''"
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 33px;
                      "
                    ></b-form-input>
                    <b-form-input
                      v-else
                      name="BirthDate"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-model="form.dtNascimento"
                      type="text"
                      :class="errors?.length > 0 ? ' is-invalid' : ''"
                      :style="
                        isNewPatient && !form.dtNascimento
                          ? 'borderColor: #9c27b0'
                          : ''
                      "
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>Informe uma data de nascimento válida</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-md-4" label="Email:" label-for="email">
                  <ValidationProvider rules="email" v-slot="{ errors }">
                    <b-form-input
                      v-if="isIOS"
                      v-model="form.email"
                      type="text"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-uppercase
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 33px;
                      "
                    ></b-form-input>
                    <b-form-input
                      v-else
                      v-model="form.email"
                      type="text"
                      :disabled="inputsIsBlocked || isDataScheduleDisabled()"
                      v-uppercase
                      :class="errors?.length > 0 ? ' is-invalid' : ''"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>Informe um email válido </span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-md-4" label="CEP:" label-for="cep">
                  <b-form-input
                    v-mask="'#####-###'"
                    v-model="form.cep"
                    type="text"
                    autocomplete="noautocomplete"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Logradouro:"
                  label-for="logradouro"
                >
                  <b-form-input
                    v-model="form.logradouro"
                    type="text"
                    v-uppercase
                    autocomplete="noautocomplete"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-2" label="Nº:" label-for="numero">
                  <b-form-input
                    v-model="form.numero"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Bairro:"
                  label-for="bairro"
                >
                  <b-form-input
                    v-model="form.bairro"
                    type="text"
                    v-uppercase
                    autocomplete="noautocomplete"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-sm-4"
                  label-for="idEstado"
                  label="Estado:"
                >
                  <v-select
                    :clearable="false"
                    v-model="form.idEstado"
                    :options="states"
                    :reduce="(descEstado) => descEstado.id"
                    label="descEstado"
                    @input="handleStateChange"
                  ></v-select>
                </b-form-group>
                <b-form-group
                  class="col-sm-4"
                  label-for="idCidade"
                  label="Cidade:"
                >
                  <select
                    v-if="citiesByState"
                    v-model="form.idCidade"
                    class="form-control"
                    @change="handleCityChange"
                  >
                    <option
                      v-for="city in citiesByState"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.descCidade }}
                    </option>
                  </select>
                  <div v-if="citiesByState && citiesByState.length === 0">
                    <p>Nenhuma cidade encontrada.</p>
                  </div>
                </b-form-group>

                <b-form-group class="col-md-12">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h6 style="color: rgb(101, 84, 192); margin: 10px">
                      **Campos importantes para identificação inequívoca do
                      paciente
                    </h6>

                    <!-- Texto clicável para alternar o accordion -->
                    <div
                      @click="toggleAccordion"
                      class="d-flex align-items-center cursor-pointer"
                    >
                      <span
                        class="mr-2 accordion-toggle-text"
                        style="color: black"
                      >
                        Informações complementares
                      </span>
                      <b-icon
                        :icon="isAccordionOpen ? 'chevron-up' : 'chevron-down'"
                        style="color: black"
                      ></b-icon>
                    </div>
                  </div>
                  <hr style="margin-top: 0" />
                </b-form-group>
                <b-collapse id="accordion-1" v-model="isAccordionOpen">
                  <b-row style="padding-left: 12px">
                    <b-col md="4">
                      <b-form-group label="CNS:" label-for="cns">
                        <ValidationProvider name="cns">
                          <b-form-input
                            v-if="isIOS"
                            autocomplete="noautocomplete"
                            v-mask="'###.####.####.####'"
                            v-model="form.cns"
                            :disabled="
                              inputsIsBlocked || isDataScheduleDisabled()
                            "
                            maxLength="18"
                            type="text"
                            name="cns"
                            ref="cns"
                            :class="form.cns && !cnsIsValid ? 'is-invalid' : ''"
                            style="
                              margin: 0px;
                              padding-left: 6px;
                              padding-bottom: 0px;
                              padding-top: 0px;
                              padding-right: 0px;
                              height: 33px;
                            "
                          ></b-form-input>
                          <b-form-input
                            v-else
                            autocomplete="noautocomplete"
                            v-mask="'###.####.####.####'"
                            v-model="form.cns"
                            :disabled="
                              inputsIsBlocked || isDataScheduleDisabled()
                            "
                            maxLength="18"
                            type="text"
                            name="cns"
                            ref="cns"
                            :class="form.cns && !cnsIsValid ? 'is-invalid' : ''"
                            :style="
                              isNewPatient && !form.cns
                                ? 'borderColor: #9c27b0'
                                : ''
                            "
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>Informe um CNS válido</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group
                        label="Nº Passaporte:"
                        label-for="passaporte"
                      >
                        <b-form-input
                          v-if="isIOS"
                          autocomplete="noautocomplete"
                          maxlength="8"
                          v-model="form.passaporte"
                          type="text"
                          :disabled="
                            inputsIsBlocked || isDataScheduleDisabled()
                          "
                          name="passaporte"
                          ref="passaporte"
                          style="
                            margin: 0px;
                            padding-left: 6px;
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-right: 0px;
                            height: 33px;
                          "
                        ></b-form-input>
                        <b-form-input
                          v-else
                          autocomplete="noautocomplete"
                          maxlength="8"
                          v-model="form.passaporte"
                          type="text"
                          :disabled="
                            inputsIsBlocked || isDataScheduleDisabled()
                          "
                          name="passaporte"
                          ref="passaporte"
                          :style="
                            isNewPatient && !form.passaporte
                              ? 'borderColor: #9c27b0'
                              : ''
                          "
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col
                      md="0"
                      style="
                        padding-top: 8px;
                        display: flex;
                        align-items: center;
                        padding-left: 24px;
                      "
                    >
                      <b-form-group
                        style="display: flex; align-items: center; padding: 0"
                      >
                        <b-form-checkbox
                          :disabled="
                            inputsIsBlocked || isDataScheduleDisabled()
                          "
                          v-model="form.indicacao"
                          value="S"
                          unchecked-value="N"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group
                        label="Contato da Indicação:"
                        label-for="indcontato"
                      >
                        <b-form-input
                          v-if="isIOS"
                          autocomplete="noautocomplete"
                          v-model="form.indicacaoContato"
                          type="text"
                          :disabled="
                            inputsIsBlocked ||
                            form.indicacao === 'N' ||
                            isDataScheduleDisabled()
                          "
                          name="indcontato"
                          ref="indcontato"
                          style="
                            margin: 0px;
                            padding-left: 6px;
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-right: 0px;
                            height: 33px;
                          "
                        ></b-form-input>
                        <b-form-input
                          v-else
                          autocomplete="noautocomplete"
                          v-model="form.indicacaoContato"
                          type="text"
                          :disabled="
                            inputsIsBlocked ||
                            form.indicacao === 'N' ||
                            isDataScheduleDisabled()
                          "
                          name="indcontato"
                          ref="indcontato"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row style="padding-left: 12px; padding-right: 12px">
                    <b-col md="6">
                      <b-form-group
                        label="Profissão(cbo):"
                        label-for="specialty"
                      >
                        <v-select
                          id="specialty"
                          :options="cbos"
                          label="descCbo"
                          :disabled="
                            inputsIsBlocked || isDataScheduleDisabled()
                          "
                          :reduce="(cbo) => cbo.id"
                          v-model="form.idCbo"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Outra Profissão:"
                        label-for="outraProfissao"
                      >
                        <b-form-input
                          v-if="isIOS"
                          v-model="form.profissaoOutros"
                          type="text"
                          v-uppercase
                          :disabled="
                            inputsIsBlocked || isDataScheduleDisabled()
                          "
                          autocomplete="noautocomplete"
                          style="
                            margin: 0px;
                            padding-left: 6px;
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-right: 0px;
                            height: 33px;
                          "
                        ></b-form-input>
                        <b-form-input
                          v-else
                          v-model="form.profissaoOutros"
                          type="text"
                          v-uppercase
                          :disabled="
                            inputsIsBlocked || isDataScheduleDisabled()
                          "
                          autocomplete="noautocomplete"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div style="padding-left: 18px; padding-right: 18px">
                    <hr />
                  </div>
                </b-collapse>

                <b-form-group class="col-md-12"> </b-form-group>

                <b-form-group
                  class="col-md-3"
                  label="Ultima consulta:"
                  label-for="ultimaConsulta"
                >
                  <b-form-input
                    v-if="isIOS"
                    tabindex="-1"
                    readonly
                    v-model="indicators.dataUltimaConsulta"
                    type="date"
                    id="lastAppointment"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  ></b-form-input>
                  <b-form-input
                    v-else
                    tabindex="-1"
                    readonly
                    v-model="indicators.dataUltimaConsulta"
                    type="date"
                    id="lastAppointment"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-3"
                  label="Dias Ultima consulta:"
                  label-for="ultimaConsulta"
                >
                  <b-form-input
                    v-if="isIOS"
                    tabindex="-1"
                    readonly
                    :value="daysSinceLastAppointment"
                    id="sinceLastAppointment"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  ></b-form-input>
                  <b-form-input
                    v-else
                    tabindex="-1"
                    readonly
                    :value="daysSinceLastAppointment"
                    id="sinceLastAppointment"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-3"
                  label="Cancelamentos:"
                  label-for="cancelamentos"
                >
                  <b-form-input
                    v-if="isIOS"
                    :title="formatedCancels"
                    tabindex="-1"
                    readonly
                    v-model="indicators.quantidadeCancelouPaciente"
                    type="text"
                    id="cancellations"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  ></b-form-input>
                  <b-form-input
                    v-else
                    :title="formatedCancels"
                    tabindex="-1"
                    readonly
                    v-model="indicators.quantidadeCancelouPaciente"
                    type="text"
                    id="cancellations"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-3"
                  label="Faltas:"
                  label-for="faltas"
                >
                  <b-form-input
                    v-if="isIOS"
                    :title="formatedFaults"
                    tabindex="-1"
                    readonly
                    v-model="indicators.quantidadeFaltaPaciente"
                    type="text"
                    id="faults"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  ></b-form-input>
                  <b-form-input
                    v-else
                    :title="formatedFaults"
                    tabindex="-1"
                    readonly
                    v-model="indicators.quantidadeFaltaPaciente"
                    type="text"
                    id="faults"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-12 mt-4 mb-2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h6 style="color: rgb(101, 84, 192); margin: 10px"></h6>

                    <!-- Texto clicável para alternar o accordion -->
                    <div
                      @click="toggleHistoryAccordion"
                      class="d-flex align-items-center cursor-pointer"
                    >
                      <span
                        class="mr-2 accordion-toggle-text"
                        style="color: black"
                      >
                        Histórico de atendimentos
                      </span>
                      <b-icon
                        :icon="
                          isHistoryAccordionOpen ? 'chevron-up' : 'chevron-down'
                        "
                        style="color: black"
                      ></b-icon>
                    </div>
                  </div>
                  <!-- <hr style="margin-top: 0" /> -->
                </b-form-group>
                <b-col>
                  <b-collapse id="accordion-1" v-model="isHistoryAccordionOpen">
                    <div class="table-container">
                      <table
                        v-if="
                          indicators.listaHistoricoPaciente &&
                          indicators.listaHistoricoPaciente.length > 0
                        "
                        class="table padded-table"
                      >
                        <thead class="table-head">
                          <tr>
                            <th>Data e Hora</th>
                            <th>Tipo de Consulta</th>
                            <th>Situação</th>
                            <th>Convênio</th>
                            <th>Valor Consulta</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              historico, index
                            ) in indicators.listaHistoricoPaciente"
                            :key="index"
                          >
                            <td>{{ historico.dtHoraAgendamento }}</td>
                            <td>{{ historico.tipoConsulta }}</td>
                            <td>{{ historico.situacao }}</td>
                            <td>{{ historico.convenio }}</td>
                            <td>{{ historico.valorConsulta }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <p v-else class="no-history-message">
                        Paciente ainda não possui histórico.
                      </p>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>
              <hr />

              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Agendamento:"
                  label-for="agendamento"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      v-if="isIOS"
                      @input="getScheduleListByDoctor()"
                      v-model="form.agendamento"
                      type="date"
                      :disabled="isDataScheduleDisabled()"
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 33px;
                      "
                    ></b-form-input>
                    <b-form-input
                      v-else
                      @input="getScheduleListByDoctor()"
                      v-model="form.agendamento"
                      type="date"
                      :disabled="isDataScheduleDisabled()"
                      :class="errors?.length > 0 ? ' is-invalid' : ''"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>Informe a data do agendamento</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-md-4" label="Hora:" label-for="hora">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      v-if="isIOS"
                      :disabled="isDataScheduleDisabled()"
                      autocomplete="off"
                      v-mask="'##:##'"
                      type="text"
                      placeholder="--:--"
                      v-model="form.hora"
                      @click="onHourInputClick"
                      style="
                        margin: 0px;
                        padding-left: 6px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        padding-right: 0px;
                        height: 33px;
                      "
                    />
                    <b-form-input
                      v-else
                      :disabled="isDataScheduleDisabled()"
                      autocomplete="off"
                      v-mask="'##:##'"
                      type="text"
                      placeholder="--:--"
                      v-model="form.hora"
                      @click="onHourInputClick"
                      :class="
                        errors?.length > 0 || !form.hora ? ' is-invalid' : ''
                      "
                    />
                    <div class="invalid-feedback">
                      <span>Informe a hora do agendamento</span>
                    </div>

                    <search-hour-results
                      :results="hourFilter"
                      @on-hour-selected="hourSelected"
                      v-if="searchHourOpened"
                    />
                  </ValidationProvider>

                  <div
                    @click="searchHourOpened = false"
                    v-if="searchHourOpened"
                    style="
                      width: 100vw;
                      height: 100vh;
                      position: fixed;
                      top: 0;
                      left: 0;
                      z-index: 12;
                    "
                  ></div>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tipo consulta:"
                  label-for="tipoConsulta"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="isDataScheduleDisabled()"
                      v-model="form.idTipoConsulta"
                      :options="scheduleType"
                      :reduce="(descTipoAgendamento) => descTipoAgendamento.id"
                      label="descTipoAgendamento"
                      :class="errors?.length > 0 ? ' is-invalid' : ''"
                      :clearable="false"
                    ></v-select>
                    <div class="invalid-feedback">
                      <span>Informe o tipo da consulta</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  class="col-md-6"
                  label-for="especialidade"
                  label="Especialidade:"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="isDataScheduleDisabled()"
                      v-model="form.idEspecialidade"
                      :options="specialtys"
                      :reduce="(descCbo) => descCbo.id"
                      label="descCbo"
                      :class="
                        errors?.length > 0 || !form.idEspecialidade
                          ? ' is-invalid '
                          : ''
                      "
                      :clearable="false"
                    ></v-select>
                    <div class="invalid-feedback">
                      <span>Informe a especialidade do médico</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  class="col-md-6"
                  label-for="attendanceType"
                  label="Tipo atendimento:"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="isDataScheduleDisabled()"
                      v-model="form.idTipoAtendimento"
                      :options="[
                        { text: 'Consulta Simples', id: 1 },
                        { text: 'Tele Consulta', id: 2 }
                      ]"
                      label="text"
                      :reduce="(item) => item.id"
                      :class="
                        errors?.length > 0 || !form.idEspecialidade
                          ? ' is-invalid '
                          : ''
                      "
                      :clearable="false"
                    ></v-select>
                    <div class="invalid-feedback">
                      <span>Informe a especialidade do médico</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  class="col-md-6"
                  label="Convenio:"
                  label-for="convenio"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="isDataScheduleDisabled()"
                      :clearable="false"
                      v-if="form.convenioPaciente"
                      v-model="form.idConvenio"
                      :options="doctorPatientHealthInsurances"
                      :reduce="(nmConvenio) => nmConvenio.idConvenio"
                      label="nmConvenio"
                      @input="handlerInputHealthInsurance()"
                      :class="
                        errors?.length > 0 || !form.idConvenio
                          ? ' is-invalid error'
                          : ''
                      "
                    ></v-select>
                    <v-select
                      :disabled="isDataScheduleDisabled()"
                      :clearable="false"
                      v-else
                      v-model="form.idConvenio"
                      :options="healthPlans"
                      :reduce="(nmConvenio) => nmConvenio.idConvenio"
                      label="nmConvenio"
                      :class="
                        errors?.length > 0 || !form.idConvenio
                          ? ' is-invalid error'
                          : ''
                      "
                      @input="getServices()"
                    ></v-select>
                    <div class="invalid-feedback">
                      <span>Informe o convênio do paciente</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Acomodação:"
                  label-for="idAcomodacaoConvenio"
                >
                  <v-select
                    :disabled="isDataScheduleDisabled()"
                    :clearable="false"
                    :item-text="'label'"
                    v-model="form.idAcomodacaoConvenio"
                    :options="acomodacaoOptions"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>

                <b-form-group
                  class="col-md-12"
                  label="Cartões:"
                  label-for="cartoes"
                >
                  <!-- <v-select
                    :clearable="false"
                    v-if="!isNewPatient && form.convenioPaciente"
                    v-model="form.pacienteConvenioCartao"
                    :options="cards"
                    :class="!form.pacienteConvenioCartao ? ' is-invalid' : ''"
                  ></v-select> -->
                  <input
                    v-if="isIOS"
                    :disabled="isDataScheduleDisabled()"
                    autocomplete="noautocomplete"
                    v-mask="cardMask"
                    v-model="form.pacienteConvenioCartao"
                    class="input-agend"
                    type="text"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  />
                  <input
                    v-else
                    :disabled="isDataScheduleDisabled()"
                    autocomplete="noautocomplete"
                    v-mask="cardMask"
                    v-model="form.pacienteConvenioCartao"
                    class="input-agend"
                    type="text"
                    :style="
                      !form.pacienteConvenioCartao
                        ? ' borderColor: #9c27b0'
                        : ''
                    "
                  />
                </b-form-group>
                <!-- Update the template -->
                <b-form-group
                  class="col-md-10"
                  label-for="consulta"
                  label="Consulta:"
                >
                  <v-select
                    :clearable="false"
                    v-model="service"
                    :options="services"
                    :reduce="(services) => services.inputLabel"
                    label="inputLabel"
                    @input="handleSelectedOption(service)"
                  >
                  </v-select>
                </b-form-group>
                <b-form-group class="col-md-2" label="Valor:" label-for="valor">
                  <b-form-input
                    v-if="isIOS"
                    autocomplete="noautocomplete"
                    v-model="service.valorServico"
                    type="number"
                    style="
                      margin: 0px;
                      padding-left: 6px;
                      padding-bottom: 0px;
                      padding-top: 0px;
                      padding-right: 0px;
                      height: 33px;
                    "
                  ></b-form-input>
                  <b-form-input
                    v-else
                    autocomplete="noautocomplete"
                    v-model="service.valorServico"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  label="Observação:"
                  label-for="observacao"
                >
                  <b-form-textarea
                    rows="2"
                    class="lineHeight"
                    maxlength="100"
                    v-model="form.observação"
                    :disabled="isDataScheduleDisabled()"
                    v-uppercase
                    placeholder="Adicionar observação sobre o paciente ou agendamento..."
                  ></b-form-textarea>
                </b-form-group>
              </b-row>

              <div class="buttons">
                <b-button
                  class="mt-2"
                  size="sm"
                  variant="danger"
                  @click="cancelar()"
                  >Cancelar</b-button
                >
                <b-button
                  data-v-step="3c"
                  @click="verifyHour"
                  :disabled="requesting"
                  type="submit"
                  class="mt-2"
                  size="sm"
                  variant="primary"
                  >Salvar</b-button
                >
              </div>
            </form>
          </ValidationObserver>
        </b-row>
      </b-col>
    </b-modal>
    <v-tour name="tourCreatePacient" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            id="step-tour3"
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template v-if="tour.currentStep === 0">
              <div id="header-step" slot="header">
                <p style="padding: 0px; margin: 0px">
                  Bem vindo ao Tour do sistema!
                </p>
              </div>
              <div id="content-step" slot="content">
                <div id="title-text">
                  Digite no mínimo 5 caracteres para buscar um paciente já
                  cadastrado. A busca pode ser feita por, nome, CPF e data de
                  nascimento.
                </div>

                <a
                  href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/8963728225691-Como-realizar-um-novo-agendamento"
                  target="_blank"
                  >Saiba mais!</a
                >
              </div>
              <div slot="actions" id="footer-step">
                <span style="color: #2b2b2b"
                  >{{ tour.currentStep + 1 }} de {{ steps?.length }}</span
                >
                <div id="butons-step">
                  <button @click="finallyTuor()" class="button-cancel">
                    Cancelar
                  </button>
                  <button @click="tour.skip" id="button-step-fin">
                    Pular Tour!
                  </button>
                  <button @click="tour.nextStep" id="button-step">
                    Próximo
                  </button>
                </div>
              </div>
            </template>
            <template v-if="tour.currentStep === 1">
              <div id="header-step" slot="header">
                <p style="padding: 0px; margin: 0px">
                  Bem vindo ao Tour do sistema!
                </p>
              </div>
              <div id="content-step" slot="content">
                <div id="title-text">
                  Marque essa opção para cadastrar um paciente novo antes de
                  realizar o agendamento. Preencha os dados obrigatórios: Nome,
                  Celular , Convenio.
                </div>

                <a
                  href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/8963728225691-Como-realizar-um-novo-agendamento"
                  target="_blank"
                  >Saiba mais!</a
                >
              </div>
              <div slot="actions" id="footer-step">
                <span style="color: #2b2b2b"
                  >{{ tour.currentStep + 1 }} de {{ steps?.length }}</span
                >
                <div id="butons-step">
                  <button @click="finallyTuor()" class="button-cancel">
                    Cancelar
                  </button>
                  <button @click="tour.skip" id="button-step-fin">
                    Pular Tour!
                  </button>
                  <button @click="tour.previousStep" id="button-step">
                    Voltar
                  </button>
                  <button @click="tour.nextStep" id="button-step">
                    Próximo
                  </button>
                </div>
              </div>
            </template>
            <template v-if="tour.currentStep === 2">
              <div id="header-step" slot="header">
                <p style="padding: 0px; margin: 0px">
                  Bem vindo ao Tour do sistema!
                </p>
              </div>
              <div id="content-step" slot="content">
                <div id="title-text">
                  Por fim, mas não menos importante, agendamos o paciente
                  clicando no salvar!
                </div>

                <a
                  href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/8963728225691-Como-realizar-um-novo-agendamento"
                  target="_blank"
                  >Saiba mais!</a
                >
              </div>
              <div slot="actions" id="footer-step">
                <span style="color: #2b2b2b"
                  >{{ tour.currentStep + 1 }} de {{ steps.length }}</span
                >
                <div id="butons-step">
                  <button @click="finallyTuor()" class="button-cancel">
                    Cancelar
                  </button>
                  <button @click="tour.skip" id="button-step-fin">
                    Pular Tour!
                  </button>
                  <button @click="tour.previousStep" id="button-step">
                    Voltar
                  </button>
                  <button @click="proxStep()" id="button-step">Próximo</button>
                </div>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

import SearchPatient from '../../../components/Calendar/SearchPatient'
import SearchHourResults from './SearchResults'
import {
  formatCurrentTime,
  formatLocalDateHour,
  validateCns
} from '../../../Utils/formatters'

import API from '../../../services/index'
export default {
  name: 'AgendarModal',
  components: { SearchPatient, SearchHourResults },
  props: [
    'schedule',
    'doctor',
    'clinica',
    'dateToView',
    'waitingItemToSchedule'
  ],
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="1c"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="2c"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="3c"]',
          params: {
            placement: 'left'
          }
        }
      ],
      isIOS: false,
      triggered: false,
      authToken: {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`
        }
      },
      requesting: false,
      isNewPatient: false,
      municipio: '',
      municipioValido: false,
      descAcomodacaoConvenio: '',
      form: {
        nome: '',
        nomeMae: '',
        cpf: '',
        cns: '',
        passaporte: '',
        dtNascimento: '',
        telCelular: '',
        email: '',
        agendamento: this.dateToView,
        idTipoConsulta: '',
        pacienteConvenioCartao: '',
        observação: '',
        idSexo: '',
        cep: '',
        logradouro: '',
        numero: '',
        bairro: '',
        idEstado: '',
        idCidade: '',
        convenioPaciente: [],
        idAcomodacaoConvenio: null,
        idConvenio: '',
        idEspecialidade: '',
        idTipoAtendimento: 1,
        descArquivoImagem: '',
        fichaPaciente: '',
        indicacao: 'N',
        indicacaoContato: '',
        hora: this.schedule.hourFormated || formatCurrentTime(),
        idCbo: '',
        descCbo: '',
        profissaoOutros: ''
      },
      acomodacaoOptions: [
        { label: 'NÃO INFORMADO', value: null },
        { label: 'ENFERMARIA', value: 1 },
        { label: 'APARTAMENTO', value: 2 }
      ],
      selectedAcomodacao: null,
      completePatientInfo: '',
      searchHourOpened: false,
      indicators: [],
      searchedPatient: false,
      isAccordionOpen: false,
      isHistoryAccordionOpen: false,
      healthPlans: [],
      scheduleType: [],
      genders: [],
      states: [],
      cities: [],
      citiesByState: [],
      cards: [],
      specialtys: [],
      freeSchedulesOptions: [],
      mainSpecialty: '',
      service: '',
      services: [],
      doctorPatientHealthInsurances: [],
      isNewSchedule: true,
      avatarUrl: '',
      avatarImage: '',
      avatar: '',
      cbos: ''
    }
  },
  async created() {
    await this.request()
    this.getSpecialtysByDoctor()
    this.getScheduleListByDoctor()
  },
  async mounted() {
    if (this.waitingItemToSchedule?.idPatient) {
      this.getPatient(this.waitingItemToSchedule.idPatient)
    } else if (this.schedule.idPaciente || this.schedule.idPaciente > 0) {
      this.isNewSchedule = false
      this.recoverSchedule()
    } else if (this.schedule.hourFormated) {
      this.form.hora = this.schedule.hourFormated
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 1000)
    }
    setTimeout(() => {
      this.$refs.searchInput.focus()
    }, 1000)
    this.$bvModal.show('agendar-paciente')
    await API.get('api/Cbo/ObtemLista', this.authToken).then(
      (res) => (this.cbos = res.data.object)
    )
    if (this.isIOSOrMac()) {
      this.isIOS = true
    }
    if (this.getIsPrimaryAcess.primeiroAcesso === 0) {
      this.$tours.tourCreatePacient.start()
    }
    this.fetchStates()
    this.fetchCities().then(() => {
      this.getCitiesByState()
    })
  },
  computed: {
    ...mapGetters({
      getIsPrimaryAcess: 'User/getIsPrimaryAcess',
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      colors: 'Setting/colorState',
      user: 'Setting/user'
    }),

    formatedCancels() {
      if (this.indicators.listaCancelamentoPaciente) {
        let datas = ''
        this.indicators.listaCancelamentoPaciente.forEach((element) => {
          datas += element + '\n'
        })

        return `Últimos cancelamentos:\n${datas}`
      }
      return ''
    },

    formatedFaults() {
      if (this.indicators.listaFaltaPaciente) {
        let datas = ''
        this.indicators.listaFaltaPaciente.forEach((element) => {
          datas += element + '\n'
        })

        return `Últimas faltas:\n${datas}`
      }
      return ''
    },

    daysSinceLastAppointment() {
      const lastAppointment = this.indicators.dataUltimaConsulta
      if (lastAppointment) {
        const lastAppointmentJSDate = new Date(lastAppointment)
        const currentJSDate = new Date()

        const difTime =
          currentJSDate.getTime() - lastAppointmentJSDate.getTime()

        return Math.floor(difTime / (1000 * 3600 * 24))
      }

      return '0'
    },

    inputsIsBlocked() {
      const block = !this.isNewPatient && !this.form.nome
      return block
    },

    hourFilter() {
      const hours = [...this.freeSchedulesOptions].filter((element) => {
        return element.includes(this.form.hora)
      })

      return hours
    },

    cardMask() {
      const healthPlan = this.healthPlans.find(
        (healthPlan) => healthPlan.idConvenio === this.form.idConvenio
      )

      if (!healthPlan) {
        return ''
      }

      const mask = healthPlan.mascaraCartao?.replaceAll(/\d/g, '#')

      return mask || ''
    },

    cnsIsValid() {
      return validateCns(this.form.cns)
    }
  },
  methods: {
    ...mapActions({
      setInfosTourOfUser: 'User/setInfosTourOfUser'
    }),
    finallyTuor() {
      const object = {
        idUsuario: this.user.id,
        dtCadastro: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        primeiroAcesso: 1,
        stepTour: 0
      }
      this.setInfosTourOfUser(object)
      this.$tours.tourFinally.stop()
      this.$tours.tourCreatePacient.stop()
      this.$tours.myTour.stop()
      this.$emit('closeModal')
    },
    proxStep() {
      this.$tours.tourFinally.start()
      this.$emit('closeModal')
    },
    openModalAndFinshTour() {
      this.showToSchedule = true
      this.$tours.myTour.stop()
    },
    handleSelectedOption(selected) {
      const serviceCompleto = this.services.find(
        (service) => service.inputLabel === selected
      )
      this.service = serviceCompleto
    },
    openTour() {
      const scriptElement = document.getElementById('step-tour3')
      if (scriptElement === null) {
        this.$tours.tourCreatePacient.start()
      } else {
        this.$tours.tourCreatePacient.stop()
      }
    },
    clearSelection() {
      this.service = ''
    },
    isIOSOrMac() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|macintosh/i.test(userAgent)
    },
    isDataScheduleDisabled() {
      if (
        this.schedule.descStatusAgenda === 'ATENDENDO' ||
        this.schedule.descStatusAgenda === 'CONTINUAR' ||
        this.schedule.descStatusAgenda === 'ATENDIDO'
      ) {
        return true
      } else {
        return false
      }
    },
    async recoverSchedule() {
      await this.getPatient(this.schedule.idPaciente)
      this.form.observação = this.schedule.observação
      this.form.idConvenio = this.schedule.idConvenio
      this.form.idTipoAtendimento = this.schedule.idTipoAtendimento
      await this.handlerPatientCard()
    },
    onHourInputClick() {
      this.form.hora = ''
      this.searchHourOpened = true
    },
    hourSelected(hour) {
      this.form.hora = hour
      this.searchHourOpened = false
    },
    onlyNumbersFilter(event) {
      const { keyCode } = event
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 8 ||
        keyCode === 9
      ) {
        return true
      } else {
        event.preventDefault()
      }
    },
    changeImage(e) {
      this.avatarImage = e.target.files[0]
      this.avatarUrl = URL.createObjectURL(this.avatarImage)
    },
    async getPatient(idPatient) {
      const apiResponse = await API.get(
        `/api/Paciente/ObtemItem/${idPatient}`,
        this.authToken
      )
      this.selectedPatient(apiResponse.data.object)
    },
    focusInput(event) {
      if (event) {
        this.resetForm()
        this.$refs.name.focus()
      }
    },
    formatDate(date, format) {
      if (date) {
        const newDateFormat = new Date(date)
        return newDateFormat.toLocaleDateString(format)
      } else {
        return ''
      }
    },
    createFileReader(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        this.avatar = reader
        return reader.result
      }
    },
    async selectedPatient(patient) {
      await this.resetForm()
      this.isNewPatient = false
      this.completePatientInfo = patient
      this.avatarUrl = ''
      this.avatarImage = ''
      if (patient.descArquivoImagem) {
        API.get(
          `api/Paciente/AwsS3ObtemArquivo?arquivoname=${patient.descArquivoImagem}&id_paciente=${patient.id}`,
          { responseType: 'blob' },
          {
            headers: {
              Authorization: `Bearer ${window.$cookies.get('token')}`
            }
          }
        ).then((res) => {
          this.createFileReader(res.data)
        })
      } else {
        this.avatar = ''
      }

      this.form = { ...this.form, ...patient }
      this.form.idAcomodacaoConvenio =
        patient.convenioPaciente[0].idAcomodacaoConvenio
      if (this.form.idEstado) {
        await this.handleStateChange(this.form.idEstado)
      }

      this.form.dtNascimento = this.formatDate(this.form.dtNascimento)
      this.searchedPatient = true
      this.getIndicators(this.form.id)
      this.handlerHealthInsurancesList()
    },
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen
    },
    toggleHistoryAccordion() {
      this.isHistoryAccordionOpen = !this.isHistoryAccordionOpen
    },
    handlerHealthInsurancesList() {
      if (this.form.convenioPaciente.length !== 0) {
        this.healthPlans.forEach((healthPlans) => {
          const healthPlanExist = this.form.convenioPaciente.find(
            (convenio) => healthPlans.idConvenio === convenio.idConvenio
          )
          if (healthPlanExist) {
            this.doctorPatientHealthInsurances.push(healthPlanExist)
          }
        })
        if (this.doctorPatientHealthInsurances.length === 1) {
          this.form.idConvenio =
            this.doctorPatientHealthInsurances[0].idConvenio
        } else if (this.doctorPatientHealthInsurances.length === 0) {
          this.doctorPatientHealthInsurances = this.healthPlans
        }
        this.handlerInputHealthInsurance()
      } else {
        this.form.convenioPaciente = []
        this.handlerInputHealthInsurance()
      }
    },
    async request() {
      await API.get(
        `/api/UsuarioConvenio/ObtemItemConvenioPorUsuario/${this.doctor}`,
        this.authToken
      ).then((res) => {
        this.healthPlans = res.data.object
        this.handlerHealthInsurancesList()
      })

      await API.get(
        '/api/TipoAgendamento/ObtemListaStatus/0',
        this.authToken
      ).then((res) => {
        this.scheduleType = res.data.object
        const scheduleType = this.scheduleType.find(
          (type) => type.descTipoAgendamento === 'CONSULTA'
        )

        this.form.idTipoConsulta = scheduleType.id
      })

      await API.get('api/Sexo/ObtemLista').then((res) => {
        this.genders = res.data.object
      })
    },
    cancelar() {
      this.$bvModal.hide('agendar-paciente')
      /* this.resetForm() */
      this.$emit('modalClosed', 'agendar-paciente')
    },
    async getIndicators(idPatient) {
      await API.get(
        `api/Indicadores/IndicadorAgendaPaciente?id_paciente=${idPatient}`
      ).then((res) => {
        const indicators = res.data.object || {}
        // Verifica se há dados de indicators

        indicators.dataUltimaConsulta = indicators.dataUltimaConsulta || []
        if (indicators.dataUltimaConsulta.length > 0) {
          indicators.dataUltimaConsulta = this.formatDate(
            indicators.dataUltimaConsulta,
            'en-CA'
          )
        }

        indicators.listaCancelamentoPaciente =
          indicators.listaCancelamentoPaciente || []
        indicators.listaCancelamentoPaciente =
          indicators.listaCancelamentoPaciente.map((element) =>
            formatLocalDateHour(element)
          )

        indicators.listaFaltaPaciente = indicators.listaFaltaPaciente || []
        indicators.listaFaltaPaciente = indicators.listaFaltaPaciente.map(
          (element) => formatLocalDateHour(element)
        )

        // Formatação de listaHistoricoPaciente
        indicators.listaHistoricoPaciente =
          indicators.listaHistoricoPaciente || []
        indicators.listaHistoricoPaciente =
          indicators.listaHistoricoPaciente.map((element) => {
            if (element.dtHoraAgendamento) {
              element.dtHoraAgendamento = formatLocalDateHour(
                element.dtHoraAgendamento
              )
            } else {
              element.dtHoraAgendamento = 'Data não informada'
            }

            if (element.valorConsulta !== null) {
              element.valorConsulta = this.formatCurrency(element.valorConsulta)
            } else {
              element.valorConsulta = 'Não informado'
            }

            if (element.convenio === null) {
              element.convenio = 'Não informado'
            }

            return element
          })

        this.indicators = indicators
      })
    },
    formatCns() {
      if (this.form.cns) {
        const newCns = this.form.cns?.replace(/[^0-9]/g, '')
        this.form.cns = newCns
      }
    },
    formatCpf() {
      if (this.form.cpf) this.form.cpf = this.form.cpf?.replace(/\D/g, '')
    },
    formatPhone() {
      const cel = this.form.telCelular
      if (cel) {
        const newPhone = cel.replace(/\D/g, '')
        this.form.telCelular = newPhone
      }
    },
    formatCep() {
      // Verifique se this.form.cep é uma string
      if (this.form.cep) {
        this.form.cep = String(this.form.cep) // Converta para string se necessário
      }
      // Remova caracteres não numéricos e formate
      this.form.cep = this.form.cep?.replace(/\D/g, '')
    },
    formatCurrency(value) {
      if (value === null || value === undefined) return 'R$ 0,00'
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)
    },
    handlerPatientCard() {
      const conveniosPaciente = this.form.convenioPaciente

      if (conveniosPaciente.length !== 0) {
        const convenio = conveniosPaciente.find(
          (convenio) => convenio.idConvenio === this.form.idConvenio
        )

        if (convenio && Array.isArray(convenio.cartaoPaciente)) {
          this.cards = convenio.cartaoPaciente.map(
            (cartao) => cartao.numeroCartao
          )

          if (this.cards.length >= 1) {
            this.form.pacienteConvenioCartao = this.cards[0]
          } else {
            this.form.pacienteConvenioCartao = ''
          }
        } else {
          this.form.pacienteConvenioCartao = ''
        }
      } else {
        this.form.pacienteConvenioCartao = ''
      }
    },
    resetForm() {
      const scheduleType = this.scheduleType.find(
        (type) => type.descTipoAgendamento === 'CONSULTA'
      )
      this.form = {
        nome: '',
        nomeMae: '',
        cpf: '',
        cns: '',
        passaporte: '',
        dtNascimento: '',
        telCelular: '',
        email: '',
        agendamento: this.dateToView,
        hora: this.schedule.hourFormated || '',
        idTipoConsulta: scheduleType ? scheduleType.id : '',
        pacienteConvenioCartao: '',
        observação: '',
        idSexo: '',
        convenioPaciente: '',
        idAcomodacaoConvenio: null,
        idConvenio: '',
        idTipoAtendimento: 1,
        fichaPaciente: '',
        indicacao: 'N',
        logradouro: '',
        bairro: '',
        indicacaoContato: '',
        idEspecialidade: this.mainSpecialty
          ? this.mainSpecialty.id
          : this.specialtys[0]
          ? this.specialtys[0].id
          : ''
      }
      this.doctorPatientHealthInsurances = []
      this.avatarUrl = ''
      this.avatar = ''
      this.completePatientInfo = ''
      this.indicators = []
    },
    async fetchStates() {
      try {
        const response = await API.get('api/Estado/ObtemLista')
        this.states = response.data.object
      } catch (error) {
        this.$toast.error('Erro ao buscar estados:', error)
      }
    },

    async fetchCities() {
      try {
        const response = await API.get('api/Cidade/ObtemLista', this.authToken)
        this.cities = response.data.object.sort()
      } catch (error) {
        this.$toast.error('Erro ao buscar cidades:', error)
      }
    },

    async getCitiesByState() {
      if (!this.form.idEstado) {
        return
      }

      try {
        const response = await API.get(
          `/api/Cidade/ObtemCidadesPorEstado/${this.form.idEstado}`
        )
        this.citiesByState = response.data.object
      } catch (error) {
        this.$toast.error('Erro ao obter cidades:', error)
      }
    },

    handleStateChange() {
      this.getCitiesByState()
    },

    handleCityChange(event) {
      this.form.idCidade = event.target.value
    },

    formatHour(hourUnformated) {
      let [hour, time] = hourUnformated.split(' ')
      if (time) {
        return time === 'AM' && hour.length <= 4
          ? (hour = `0${hour}`)
          : time === 'AM'
          ? hour
          : time === 'PM' && hour.length <= 4 && parseInt(hour[0]) + 2 < 10
          ? (hour = `1${parseInt(hour[0]) + 2}:${hour[2]}${hour[3]}`)
          : time === 'PM' && hour.length <= 4
          ? (hour = `2${parseInt(hour[0]) - 8}:${hour[2]}${hour[3]}`)
          : (hour = `2${parseInt(hour[0] + hour[1]) - 8}:${hour[3]}${hour[4]}`)
      } else {
        return hourUnformated
      }
    },
    async getServices() {
      try {
        const res = await API.get(
          `/api/UsuarioServicoValor/ObtemItemServicoAtivos/?id_usuario=${this.doctor}&id_convenio=${this.form.idConvenio}`
        )
        if (res.data.object) {
          this.services = res.data.object.map((service) => ({
            ...service,
            inputLabel: `${service.codigoServico} - ${service.descServico}`
          }))
          if (this.schedule) {
            if (this.schedule.codigoConsulta) {
              this.service = this.services.find((element) => {
                return element.codigoServico === this.schedule.codigoConsulta
              })
              this.service.valorServico = this.schedule.valorConsulta
            } else if (this.services.length === 1) {
              this.service = this.services[0]
              this.service.valorServico = this.schedule.valorConsulta
            } else {
              this.service = ''
            }
          } else {
            this.service = ''
          }
        }
      } catch (error) {
        this.services = []
      }
    },
    async handlerInputHealthInsurance() {
      await this.handlerPatientCard()
      await this.getServices()
    },
    async getScheduleListByDoctor() {
      this.freeSchedulesOptions = []
      const reqDate = this.form.agendamento || this.dateToView
      await API.get(
        `/api/Agenda/ObtemListaPorMedicoDia/?id_medico=${this.doctor}&id_clinica=${this.clinica}&data_ref=${reqDate}`,
        this.authToken
      ).then((res) => {
        const scheduleList = res.data.object
        if (scheduleList) {
          const freeSchedulesUnformated = scheduleList.filter(
            (schedule) => schedule.situacaoHorario === 'LIVRE'
          )
          freeSchedulesUnformated.forEach((freeSchedule) => {
            this.freeSchedulesOptions.push(
              this.formatHour(freeSchedule.horaAgendamento)
            )
          })

          if (this.schedule.id !== 0)
            this.freeSchedulesOptions.push(this.schedule.hourFormated)
          this.freeSchedulesOptions.sort()
        }
        this.freeSchedulesOptions = this.freeSchedulesOptions.filter(
          (freeSchedule) => freeSchedule
        )
      })
    },
    async getSpecialtysByDoctor() {
      await API.get(
        `/api/UsuarioEspecialidadeCbo/ObtemListaPorUSuario/?id_usuario=${this.doctor}`
      ).then((res) => {
        this.specialtys = res.data.object
        this.mainSpecialty = this.specialtys.find(
          (specialty) => specialty.principal === 'S'
        )
        this.form.idEspecialidade = this.mainSpecialty
          ? this.mainSpecialty.id
          : this.specialtys[0].id
      })
    },
    async addPatient() {
      const form = this.form
      const convenioPaciente = [
        {
          id: 0,
          idPaciente: 0,
          idConvenio: form.idConvenio,
          idAcomodacaoConvenio: form.idAcomodacaoConvenio,
          status: 0,
          cartaoPaciente: null
        }
      ]

      if (form.pacienteConvenioCartao) {
        convenioPaciente[0].cartaoPaciente = [
          {
            id: 0,
            idPacienteConvenio: 0,
            numeroCartao: form.pacienteConvenioCartao,
            status: 0
          }
        ]
      }
      this.formatCns()
      const newPatient = {
        dtNascimento: form.dtNascimento.split('/').reverse().join('-'),
        idClinica: this.clinica,
        idUsuario: this.doctor,
        nome: form.nome,
        nomeMae: form.nomeMae,
        cns: form.cns,
        cpf: form.cpf,
        passaporte: form.passaporte,
        idSexo: form.idSexo,
        email: form.email,
        cep: form.cep,
        logradouro: form.logradouro,
        numero: form.numero,
        bairro: form.bairro,
        idEstado: form.idEstado,
        idCidade: form.idCidade,
        telCelular: form.telCelular,
        descArquivoImagem: this.avatarImage.name,
        convenioPaciente,
        idUsuarioInclusao: this.user.id,
        fichaPaciente: form.fichaPaciente,
        indicacao: form.indicacao,
        indicacaoContato: form.indicacaoContato,
        idCbo: form.idCbo,
        descCbo: form.descCbo,
        profissaoOutros: form.profissaoOutros
      }

      API.post('api/Paciente/Adicionar', { object: newPatient }, this.authToken)
        .then((res) => {
          if (this.avatarImage) {
            const file = {
              file: this.avatarImage,
              tipoArquivoImagem:
                this.avatarImage.type === 'image/jpeg' ||
                this.avatarImage.type === 'image/png'
                  ? 'image'
                  : this.avatarImage.type === 'application/pdf'
                  ? 'pdf'
                  : 'doc',
              descAwsArquivoImagem: this.avatarImage.name
            }

            const bodyFormData = new FormData()
            bodyFormData.append('file', file.file)

            const fileType =
              file.file.type === 'image/jpeg'
                ? 'image'
                : file.file.type === 'application/pdf'
                ? 'pdf'
                : 'doc'

            const fileName = file.file.name

            API.post(
              `api/Paciente/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&id_paciente=${res.data.identificacao}`,
              bodyFormData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${window.$cookies.get('token')}`
                }
              }
            )
          }
          this.addSchedule(res.data.identificacao)
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          this.requesting = false
          this.$toast.error(err.response.data.message)
        })
    },
    async editPatient() {
      // eslint-disable-next-line no-console
      const form = this.form
      const completePatientInfo = this.completePatientInfo

      if (form.convenioPaciente) {
        form.convenioPaciente = form.convenioPaciente.map((convenio) => ({
          ...convenio,
          idAcomodacaoConvenio: this.form.idAcomodacaoConvenio
        }))
      }

      // Verifique o conteúdo de `form.convenioPaciente`
      if (
        form.convenioPaciente &&
        form.pacienteConvenioCartao !== this.cards[0]
      ) {
        const convenioIndex = form.convenioPaciente.findIndex(
          (element) => element.idConvenio === form.idConvenio
        )

        if (form.convenioPaciente[convenioIndex]?.cartaoPaciente) {
          form.convenioPaciente[convenioIndex].cartaoPaciente[0].numeroCartao =
            form.pacienteConvenioCartao
        }
      }
      this.formatCns()

      // Prepare os dados para enviar
      const editedPatient = {
        ...completePatientInfo,
        dtNascimento: form.dtNascimento.split('/').reverse().join('-'),
        idUsuario: this.doctor,
        id: form.id,
        nome: form.nome,
        nomeMae: form.nomeMae,
        convenioPaciente: form.convenioPaciente,
        cns: form.cns,
        cpf: form.cpf,
        passaporte: form.passaporte,
        idSexo: form.idSexo,
        cep: form.cep,
        logradouro: form.logradouro,
        numero: form.numero,
        bairro: form.bairro,
        idEstado: form.idEstado,
        idCidade: form.idCidade,
        email: form.email,
        telCelular: form.telCelular,
        descArquivoImagem: this.avatarImage.name
          ? this.avatarImage.name
          : form.descArquivoImagem,
        fichaPaciente: form.fichaPaciente,
        indicacao: form.indicacao,
        indicacaoContato: form.indicacaoContato,
        idCbo: form.idCbo,
        descCbo: form.descCbo,
        profissaoOutros: form.profissaoOutros
      }
      this.form.idAcomodacaoConvenio =
        editedPatient.convenioPaciente[0].idAcomodacaoConvenio

      // Verifique se há cartões existentes
      if (this.cards.length === 0) {
        await API.post(
          '/api/PacienteConvenio/Adicionar',
          {
            object: {
              id: 0,
              idPaciente: form.id,
              idConvenio: form.idConvenio,
              status: 0,
              cartaoPaciente: [
                {
                  id: 0,
                  idPacienteConvenio: 0,
                  numeroCartao: form.pacienteConvenioCartao,
                  status: 0
                }
              ]
            }
          },
          this.authToken
        )
      }

      await API.put(
        'api/Paciente/Editar',
        { object: editedPatient },
        this.authToken
      )
        .then((res) => {
          this.addSchedule(this.form.id)
          if (this.avatarImage) {
            const file = {
              file: this.avatarImage,
              tipoArquivoImagem:
                this.avatarImage.type === 'image/jpeg' ||
                this.avatarImage.type === 'image/png'
                  ? 'image'
                  : this.avatarImage.type === 'application/pdf'
                  ? 'pdf'
                  : 'doc',
              descAwsArquivoImagem: this.avatarImage.name
            }

            const bodyFormData = new FormData()
            bodyFormData.append('file', file.file)

            const fileType =
              file.file.type === 'image/jpeg'
                ? 'image'
                : file.file.type === 'application/pdf'
                ? 'pdf'
                : 'doc'

            const fileName = file.file.name

            API.post(
              `api/Paciente/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&id_paciente=${editedPatient.id}`,
              bodyFormData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${window.$cookies.get('token')}`
                }
              }
            )
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          const message = err.response.data.message
          this.requesting = false
          this.$toast.error(message || 'Não foi possivel editar o paciente')
        })
    },
    formatDataUpdate(data) {
      // Dividir a string em data e hora
      const partes = data.split(', ')
      const dataParte = partes[0]
      const horaParte = partes[1]

      // Dividir a data em dia, mês e ano
      const dataComponentes = dataParte.split('/')
      const dia = dataComponentes[0]
      const mes = dataComponentes[1]
      const ano = dataComponentes[2]

      // Dividir a hora em horas, minutos e segundos
      const horaComponentes = horaParte.split(':')
      const hora = horaComponentes[0]
      const minuto = horaComponentes[1]

      // Formatar a data no formato desejado "2023-09-13T09:45"
      const dataFormatada = `${ano}-${mes}-${dia}T${hora}:${minuto}`

      return dataFormatada
    },
    async addSchedule(idPatient) {
      const schedule = {
        id: this.schedule.id || 0,
        idStatusAgenda: this.schedule.idStatusAgenda || 0,
        idPaciente: idPatient,
        idUsuario: this.doctor,
        idClinica: this.clinica,
        dtAgendamento: `${this.form.agendamento}T${this.form.hora}`,
        idTipoAgendamento: this.form.idTipoConsulta,
        idUsuarioInclusao: this.user.id,
        observação: this.form.observação,
        pacienteNome: this.form.nome,
        idConvenio: this.form.idConvenio,
        idAcomodacaoConvenio:
          this.form.convenioPaciente[0]?.idAcomodacaoConvenio,
        pacienteConvenioCartao: this.form.pacienteConvenioCartao,
        idEspecialidade: this.form.idEspecialidade,
        codigoConsulta: this.service?.codigoServico,
        descConsulta: this.service?.descServico,
        valorConsulta: this.service?.valorServico,
        idTipoAtendimento: this.form.idTipoAtendimento,
        idUsuarioUltAtualizacao: this.user.id,
        dtUltAtualizacao: this.formatDataUpdate(new Date().toLocaleString())
      }
      if (this.isNewSchedule) {
        await API.post(
          'api/Agenda/Adicionar',
          {
            object: schedule
          },
          this.authToken
        )
          .then((res) => {
            this.$toast.success('Agendamento feito com sucesso')
            this.$bvModal.hide('agendar-paciente')
            this.resetForm()
            this.$emit('modalClosed', 'agendar-paciente')
            this.$emit('updateCalendar')
          })
          // eslint-disable-next-line handle-callback-err
          .catch((err) => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.$toast.error(err.response.data.message)
            } else if (err.message) {
              this.$toast.error(err.message)
            } else {
              this.$toast.error('Algo impossibilitou o agendamento')
            }
          })
      } else {
        await API.put(
          'api/Agenda/Editar',
          {
            object: schedule
          },
          this.authToken
        )
          .then((res) => {
            this.$toast.success('Agendamento editado com sucesso')
            this.$bvModal.hide('agendar-paciente')
            this.resetForm()
            this.$emit('modalClosed', 'agendar-paciente')
            this.$emit('updateCalendar')
          })
          // eslint-disable-next-line handle-callback-err
          .catch((err) => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.$toast.error(err.response.data.message)
            } else if (err.message) {
              this.$toast.error(err.message)
            } else {
              this.$toast.error('Algo impossibilitou o agendamento')
            }
          })
      }
    },
    verifyHour() {
      if (!this.form.hora)
        return this.$toast.error('Hora do agendamento não informada!')
    },
    onSubmit: async function () {
      try {
        this.requesting = true
        const isValid = await this.$refs.form.validate()
        if (!isValid) {
          this.requesting = false
          return this.$toast.error('Verifique os campos obrigatórios!')
        }
        this.formatPhone()
        this.formatCpf()
        this.formatCep()
        if (this.isNewPatient) {
          await this.addPatient()
        } else {
          await this.editPatient()
        }
      } catch {
        this.$toast.error('Ocorreu algum erro, tente novamente!')
        this.requesting = false
      }
    }
  }
}
</script>

<style scoped>
.lineHeight {
  line-height: 1.5 !important;
}
.table-container {
  max-height: 130px; /* Altura máxima da tabela antes de aplicar a rolagem */
  overflow-y: auto; /* Adiciona rolagem vertical quando o conteúdo exceder a altura máxima */
  border-radius: 4px;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; /* Colapso de borda para evitar espaços entre células */
}

.table-head th {
  white-space: normal; /* Permite que o texto quebre a linha */
  text-align: center; /* Centraliza o texto */
  vertical-align: middle; /* Alinha verticalmente no centro */
  background-color: #089bab;
  color: white;
  font-weight: 400;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table tbody td {
  max-height: 50px; /* Altura máxima da tabela antes de aplicar a rolagem */
  overflow-y: auto; /* Adiciona rolagem vertical quando o conteúdo exceder a altura máxima */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; /* Centraliza o texto */
  vertical-align: middle; /* Alinha verticalmente no centro */
  padding: 4px;
}
.no-history-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #888;
}

.input-agend {
  padding: 8px;
  width: 100%;
  height: 33px;
  border-radius: 10px;
  border: 1px solid grey;
}

#lastAppointment {
  background: #5dade2;
  color: #fff;
  text-align: center;
}

#sinceLastAppointment {
  background: #22b14c;
  color: #fff;
  text-align: center;
}

#cancellations {
  background: #f5b041;
  color: #fff;
  text-align: center;
}

#faults {
  background: #ec7063;
  color: #fff;
  text-align: center;
}

.sameLevel {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.v-step[data-v-54f9a632] {
  max-width: none;
}

#step-tour {
  width: 440px;
  background-color: #089bab;
  border: 1px solid #089bab;
  color: #089bab;
  padding: 0px;
}

#step-tour3 {
  width: 440px;
  background-color: #089bab;
  border: 1px solid #089bab;
  color: #089bab;
  padding: 0px;
}

#header-step {
  background-color: #f7f7f7;
  width: 700px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: #089bab 1px solid;
  padding: 6px 10px;
}

#content-step {
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 10px;
}

#content-step span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

#title-text {
  font-size: 12px;
  font-weight: normal;
  color: #2b2b2b;
  text-align: start;
  padding: 0px;
  margin: 0px;
}

#footer-step {
  background-color: #f7f7f7;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
}

#butons-step {
  display: flex;
  gap: 10px;
  align-items: center;
}

#button-step-fin {
  background-color: transparent;
  border: none;
  color: #bcb9b9;
  font-weight: bold;
}

.button-cancel {
  font-size: 0.8rem;
  padding: 6px 12px;
  color: red;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid red;
}

#button-step {
  border-radius: 10px;
  padding: 6px 10px;
  background-color: #089bab;
  color: #fff;
  font-weight: bold;
}

#step-image {
  width: 100%;
  height: 200px;
}

#col {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.headerList {
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.2fr 0.8fr 0.8fr 1fr 1.4fr 0.1fr;
  gap: 10px;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  text-align: flex-start;
  align-items: center;
  background: #089bab;
  color: #fff;
}

@media (max-width: 480px) {
  #col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  #form-check {
    margin-left: 20px;
    position: relative;
    top: 20px;
  }
}
</style>
