import ProblemsMutations from './submodules/problems/mutations'
import AllergyMutations from './submodules/allergy/mutations'
import MedicationHistoryMutations from './submodules/medicationHistory/mutations'
import ExamHistoryMutations from './submodules/examHistory/mutations'
import ComplaintsMutations from './submodules/complaints/mutations'
import PhysicalExamMutations from './submodules/physicalExam/mutations'
import ConductMutations from './submodules/conduct/mutations'
import PersonalAndFamiliarHistorMutations from './submodules/personalHistory/mutations'
import VitalSignMutations from './submodules/vitalSigns/mutations'
import FilesAndImages from './submodules/files&images/mutations'
import Forms from './submodules/forms/mutations'
import Forwardings from './submodules/forwardings/mutations'
import Prescriptions from './submodules/prescriptions/mutations'
import ASO from './submodules/aso/mutations'
import Pregnant from './submodules/pregnancy/mutations'
import Tracking from './submodules/tracking/mutations'

const mutations = {
  ...ProblemsMutations,
  ...AllergyMutations,
  ...MedicationHistoryMutations,
  ...ExamHistoryMutations,
  ...ComplaintsMutations,
  ...PhysicalExamMutations,
  ...ConductMutations,
  ...PersonalAndFamiliarHistorMutations,
  ...VitalSignMutations,
  ...FilesAndImages,
  ...Forms,
  ...Forwardings,
  ...Prescriptions,
  ...ASO,
  ...Pregnant,
  ...Tracking,

  addDataToModule(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === payload.idModulo
    )
    if (module.idModulo === 4) {
      module.content = payload
    } else {
      module.data.push(payload)
    }
  },

  addToModuleFavoriteStateWithoutSelect(state, { payload }) {
    const module = state.modules.find(
      (module) => module.id === payload.idProntuarioPreferencia
    )

    const alreadyExistsIndex = module.favorites.findIndex((favorite) => {
      const propertyToCompare = 'descTitulo'
      return favorite[propertyToCompare] === payload[propertyToCompare]
    })

    if (alreadyExistsIndex >= 0) {
      module.favorites.splice(alreadyExistsIndex, 1, payload)
    } else {
      module.favorites.push({
        ...payload,
        acessoRapido: -1,
        favorito: -1,
        location: ''
      })
    }
  },

  editModuleFavorite(state, { payload }) {
    const module = state.modules.find(
      (module) => module.id === payload.idProntuarioPreferencia
    )

    const favoriteIndex = module.favorites.findIndex(
      (favorite) => payload.id === favorite.id
    )

    if (payload.acessoRapido === 0) {
      payload.location = 'quickAccess'
    } else if (payload.favorito === 0) {
      payload.location = 'favorite'
    }

    module.favorites.splice(favoriteIndex, 1, payload)
  },

  addToModuleFavoriteState(state, { payload }) {
    const module = state.modules.find(
      (module) => module.id === payload.idProntuarioPreferencia
    )

    const propertiesToCompare = {
      1: 'idCid',
      2: 'idMedicamento',
      3: 'codigoServico',
      4: 'descTitulo',
      5: 'descTitulo',
      6: 'descTitulo',
      8: 'descTitulo',
      12: 'idFormulario',
      13: 'descTitulo',
      16: 'descTitulo'
    }

    const alreadyExistsIndex = module.favorites.findIndex((favorite) => {
      const propertyToCompare = propertiesToCompare[module.idModulo]
      return favorite[propertyToCompare] === payload[propertyToCompare]
    })
    if (alreadyExistsIndex >= 0) {
      module.favorites.splice(alreadyExistsIndex, 1, {
        ...payload,
        location: 'favorite'
      })
    } else {
      module.favorites.push({
        ...payload,
        acessoRapido: 1,
        favorito: 0,
        location: 'favorite'
      })
    }
  },

  addToModuleQuickAccessState(state, { payload }) {
    const module = state.modules.find(
      (module) => module.id === payload.idProntuarioPreferencia
    )

    const propertiesToCompare = {
      1: 'idCid',
      2: 'idMedicamento',
      3: 'codigoServico',
      4: 'descTitulo',
      5: 'descTitulo',
      6: 'descTitulo',
      8: 'descTitulo',
      12: 'idFormulario',
      13: 'descTitulo',
      16: 'descTitulo'
    }

    const alreadyExistsIndex = module.favorites.findIndex((favorite) => {
      const propertyToCompare = propertiesToCompare[module.idModulo]
      return favorite[propertyToCompare] === payload[propertyToCompare]
    })

    if (alreadyExistsIndex >= 0) {
      module.favorites.splice(alreadyExistsIndex, 1, {
        ...payload,
        location: 'quickAccess',
        acessoRapido: 0,
        favorito: 1
      })
    } else {
      module.favorites.push({
        ...payload,
        acessoRapido: 0,
        favorito: 1,
        location: 'quickAccess'
      })
    }
  },

  removeFromFavoriteState(state, { payload }) {
    const module = state.modules.find(
      (module) => module.id === payload.idProntuarioPreferencia
    )

    module.favorites = module.favorites.filter(
      (favorite) => favorite.id !== payload.id
    )
  },

  loadAllModules(state, modules) {
    modules.forEach((module) => {
      switch (module.idModulo) {
        case 9:
        case 7: {
          break
        }
        default: {
          module.favorites.forEach((favorite) => {
            if (favorite.acessoRapido === 0) {
              favorite.location = 'quickAccess'
            } else if (favorite.favorito === 0) {
              favorite.location = 'favorite'
            } else {
              favorite.location = ''
            }
          })
        }
      }

      const oldModule = state.modules.find((element) => {
        return element.idModulo === module.idModulo
      })

      if (
        oldModule &&
        oldModule.data.length !== 0 &&
        oldModule.data !== undefined
      ) {
        module.data = oldModule.data
      } else {
        module.data = []
      }
    })

    state.modules = modules
  },

  updateModules(state, { payload }) {
    state.modules = payload
  },

  toggleExpandModule(state, { payload }) {
    const moduleIndex = state.modules.findIndex(
      (module) => module.idModulo === payload.idModulo
    )

    state.modules.splice(moduleIndex, 1, payload)
  },

  toggleActiveModule(state, { payload }) {
    const moduleIndex = state.modules.findIndex(
      (module) => module.idModulo === payload.idModulo
    )

    state.modules.splice(moduleIndex, 1, payload)
  },

  changeModuleBackground(state, { payload }) {
    const moduleIndex = state.modules.findIndex(
      (module) => module.idModulo === payload.idModulo
    )

    state.modules.splice(moduleIndex, 1, payload)
  },

  changeModuleName(state, { payload }) {
    const moduleIndex = state.modules.findIndex(
      (module) => module.idModulo === payload.idModulo
    )

    state.modules.splice(moduleIndex, 1, payload)
  },
  changeModulesStateChangedToTrue(state) {
    state.modulesStateChanged = true
  },
  changeModulesStateChangedToFalse(state) {
    state.modulesStateChanged = false
  },
  changeMedicalReportType(state, payload) {
    state.medicalReportType = payload
  }
}

export default mutations
