const getters = {
  getLocalCoverSheet: (state) => {
    return state.localCoverSheet
  },
  getListExamsLaboratorys(state) {
    return state.dataExamsLaboratorys
  },
  getVaccinesPacient(state) {
    return state.listVaccines
  },
  getDataVaccine(state) {
    return state.dataVaccines
  },
  getDataUltrassom(state) {
    return state.dataUltrassom
  }
}

export default getters
