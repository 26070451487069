import { render, staticRenderFns } from "./FormsList.vue?vue&type=template&id=f65b0184&scoped=true"
import script from "./FormsList.vue?vue&type=script&lang=js"
export * from "./FormsList.vue?vue&type=script&lang=js"
import style0 from "./FormsList.vue?vue&type=style&index=0&id=f65b0184&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f65b0184",
  null
  
)

export default component.exports