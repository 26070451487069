import API from '../../services/index'

const actions = {
  async searchTasks({ state }, payload) {
    try {
      const response = await API.get(
        '/api/Tarefa/ObtemTarefasPorUsuarioResponsavel',
        {
          params: {
            id_usuario_responsavel: payload
          }
        }
      )
      state.listTask = response.data.object
    } catch (err) {
      return err
    }
  },
  async addTask({ state }, payload) {
    try {
      const response = await API.post('/api/Tarefa/Adicionar', {
        appName: 'Doctor Pep',
        appVersion: '1.9.9',
        object: {
          ...payload
        }
      })
      return response.data.object
    } catch (err) {
      return err
    }
  },
  async editTask({ state }, payload) {
    try {
      await API.put('/api/Tarefa/FinalizarTarefa', {
        appName: 'Doctor Pep',
        appVersion: '1.9.9',
        object: {
          ...payload
        }
      })
    } catch (err) {
      return err
    }
  },
  async deleteTask({ state }, payload) {
    try {
      await API.delete('/api/Tarefa/RemoverTarefa', {
        params: {
          id_tarefa: payload
        }
      })
    } catch (err) {
      return err
    }
  }
}

export default actions
