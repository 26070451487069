<template>
  <b-modal
    id="forwarding-form-modal"
    size="lg"
    title="Encaminhamento"
    title-class="text-light"
    header-bg-variant="primary"
    @close="closeForm"
    hide-footer
    no-close-on-backdrop
  >
    <div class="form-container">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form id="form" @submit.prevent="handleSubmit(saveForwarding)">
          <b-row>
            <b-col class="col-md-6">
              <b-form-group
                label="Tipo de encaminhamento:"
                label-for="forwarding-type"
              >
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="forwarding-type"
                >
                  <v-select
                    id="forwarding-type"
                    :clearable="false"
                    v-model="forwarding.idTipoEncaminhamento"
                    :options="forwardingTypes"
                    :reduce="(type) => type.id"
                    label="descTipoEncaminhamento"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />

                  <div class="invalid-feedback">
                    <span>Tipo de encaminhamento obrigatório</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col class="col-md-6">
              <b-form-group label="Especialidade:" label-for="specialty">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="specialty"
                >
                  <v-select
                    id="specialty"
                    :clearable="false"
                    v-model="forwarding.idEspecialidade"
                    :options="specialties"
                    label="descCbo"
                    :reduce="(specialty) => specialty.id"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />

                  <div class="invalid-feedback">
                    <span>Especialidade obrigatória</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-12">
              <b-form-group label="Profissional:" label-for="medic">
                <v-select
                  id="medic"
                  :clearable="false"
                  :options="medics"
                  label="nmUsuario"
                  :reduce="(medic) => medic.idUsuario"
                  v-model="forwarding.idProfissionalEncaminhado"
                >
                  <template #no-options>
                    Não há profissionais cadastrado com essa especialidade!
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-12">
              <b-form-group
                label="História clinica e questionamento:"
                label-for="clinic-history"
              >
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="clinic-history"
                >
                  <b-form-textarea
                    id="clinic-history"
                    rows="3"
                    style="line-height: 24px"
                    v-model="forwarding.descHistoricoClinico"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />

                  <div class="invalid-feedback">
                    <span>Histórico clínico obrigatório</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-6">
              <b-form-group
                label="Hipóteses diagnósticas (CID):"
                label-for="diagnostic"
              >
                <b-form-input
                  id="diagnostic"
                  @keyup="searchOpened = true"
                  v-model="filter"
                />

                <search-result
                  :results="cidFilter"
                  @on-result-selected="resultSelected"
                  v-if="searchOpened"
                />

                <div
                  @click="searchOpened = false"
                  v-if="searchOpened"
                  style="
                    width: 100vw;
                    height: 100vh;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 12;
                  "
                ></div>
              </b-form-group>
            </b-col>

            <b-col class="col-md-6">
              <b-form-group label="Prioridade:" label-for="priority">
                <v-select
                  id="priority"
                  :clearable="false"
                  v-model="forwarding.idEncaminhamentoPrioridade"
                  :options="priorities"
                  :reduce="(item) => item.id"
                  label="descPrioridadeEncaminhamento"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-6 d-flex align-items-center">
              <b-form-checkbox
                class="notify"
                :value="0"
                :unchecked-value="1"
                v-model="forwarding.notificarRecepcao"
              />
              <span>Notificar secretária para agendamento</span>
            </b-col>
            <b-col class="col-md-6 d-flex justify-content-end mt-2">
              <b-button variant="primary" type="submit">Salvar</b-button>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchResult from './SearchResults.vue'
import { formatDateHour } from '@/Utils/formatters'
export default {
  name: 'Form',
  props: [
    'selectedForwarding',
    'idProntuario',
    'specialties',
    'priorities',
    'forwardingTypes',
    'cids'
  ],
  components: {
    SearchResult
  },
  mounted() {
    this.$bvModal.show('forwarding-form-modal')

    if (this.selectedForwarding) {
      this.openWithSelectedForwarding()
    }
  },
  data() {
    return {
      medics: [],
      searchOpened: false,
      filter: '',
      forwarding: {
        idEspecialidade: '',
        idTipoEncaminhamento: '',
        idProfissionalEncaminhado: '',
        idCid: '',
        descCid: '',
        idEncaminhamentoPrioridade: '',
        descHistoricoClinico: '',
        notificarRecepcao: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),
    cidFilter() {
      if (this.filter) {
        let count = 0
        const cidsFiltered = []
        this.cids.every((element) => {
          if (count < 20) {
            const desc = element.descCid.toLowerCase()
            const contain = desc.includes(this.filter.toLowerCase())

            if (contain) {
              count++
              cidsFiltered.push(element)
            }

            return true
          } else {
            return false
          }
        })

        return cidsFiltered
      } else {
        return []
      }
    }
  },

  watch: {
    'forwarding.idEspecialidade': async function (newValue) {
      if (newValue) {
        const medics = await this.getMedicsBySpecialty({
          idCbo: newValue,
          idClinic: this.user.clinica_usuario[0].idClinica
        })

        if (medics.length === 0 || medics === false) {
          return []
        }

        this.medics = medics
      }
    }
  },

  methods: {
    ...mapActions({
      getMedicsBySpecialty: 'Forwardings/getMedicsBySpecialtyAction',
      saveForwardingAction: 'Forwardings/saveForwardingAction'
    }),
    closeForm() {
      this.$emit('closeForm')
    },
    openWithSelectedForwarding() {
      const {
        id,
        idProntuarioPreferencia,
        location,
        favorito,
        acessoRapido,
        idProfissional,
        descTitulo,
        ...rest
      } = this.selectedForwarding

      const cid = this.cids.find((element) => {
        return element.id === rest.idCid
      })

      this.filter = cid.descCid

      this.forwarding = {
        ...rest,
        idProfissionalEncaminhado: idProfissional,
        descCid: cid.descCid
      }
    },
    resultSelected(result) {
      this.forwarding.idCid = result.id
      this.forwarding.descCid = result.descCid
      this.filter = result.descCid

      this.searchOpened = false
    },
    resetForm() {
      this.forwarding = {
        idEspecialidade: '',
        idTipoEncaminhamento: '',
        idProfissionalEncaminhado: '',
        idCid: '',
        descCid: '',
        idEncaminhamentoPrioridade: '',
        descHistoricoClinico: '',
        notificarRecepcao: 0
      }

      this.$refs.form.reset()
    },
    async saveForwarding() {
      const object = {
        ...this.forwarding,
        dtInclusao: formatDateHour(),
        dtOcorrencia: formatDateHour(),
        ativo: 0,
        nmProfissionalSolicitante: this.user.nomeSocial,
        idProntuario: this.idProntuario
      }

      this.$store.commit({
        type: 'MedicalReports/addForwarding',
        payload: object
      })

      this.closeForm()
    }
  }
}
</script>

<style scoped lang="scss">
.form-container {
  padding: 8px;
}
.notify {
  vertical-align: text-bottom;
}
</style>
