<template>
  <b-container fluid>
    <b-row>
      <b-col lg="3" id="usersListContainer">
        <div class="usersList" id="usersList">
          <div class="iq-search-bar">
            <form action="#" class="searchbox">
              <input
                type="text"
                class="text search-input"
                placeholder="Busca por nome (min 5 caracteres) ou CPF completo"
                @keydown="searchPatient($event)"
              />
              <div class="search-link">
                <i v-if="!loading" class="ri-search-line"></i>
                <b-spinner
                  v-else
                  style="verticalalign: middle"
                  small
                  variant="primary"
                ></b-spinner>
              </div>
            </form>
          </div>
          <div
            class="onHover"
            @click="handlerShow(patient)"
            v-for="patient in searchedPatient"
            :key="patient.id"
          >
            <div
              :class="[
                'iq-card_scoped',
                patient.id === newPatient.id ? 'selected' : ''
              ]"
            >
              <!-- <template v-slot:headerTitle> -->
              <div class="cardContainer">
                <div class="patientInfo">
                  <p class="card-title">
                    <strong>Nome:</strong>
                    {{ patient.nomeSocial ? patient.nomeSocial : patient.nome }}
                  </p>
                  <p class="card-title">
                    <strong>Data Nasc.:</strong>
                    {{ formatDate(patient.dtNascimento, 'pt-BR') }}
                  </p>
                  <p class="card-title">
                    <strong>Celular:</strong>
                    <span>{{
                      patient.telCelular | VMask('(##)# ####-####')
                    }}</span>
                  </p>
                  <p class="card-title">
                    <strong>CPF:</strong>
                    {{ patient.cpf | VMask('###.###.###-##') }}
                  </p>
                </div>
                <div class="delete-button">
                  <b-button
                    :variant="
                      patient.id === newPatient.id
                        ? 'outline-primary'
                        : 'outline-light'
                    "
                    title="Deletar paciente"
                    @click="deletePatient(patient)"
                    :disabled="requesting"
                  >
                    <b-icon
                      :icon="
                        patient.id === newPatient.id ? 'trash-fill' : 'trash'
                      "
                      :variant="
                        patient.id === newPatient.id ? 'light' : 'primary'
                      "
                      scale="1.5"
                    />
                  </b-button>
                </div>
              </div>
              <!-- </template> -->
              <!-- <div class="delete-button">
                <b-button
                  :variant="
                    patient.id === newPatient.id
                      ? 'outline-primary'
                      : 'outline-light'
                  "
                  title="Deletar paciente"
                  @click="deletePatient(patient)"
                  :disabled="requesting"
                >
                  <b-icon
                    :icon="
                      patient.id === newPatient.id ? 'trash-fill' : 'trash'
                    "
                    :variant="
                      patient.id === newPatient.id ? 'light' : 'primary'
                    "
                    scale="1.5"
                  />
                </b-button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="buttonBottom">
          <b-button @click="handlerShow()" variant="success"
            >Adicionar</b-button
          >
        </div>
      </b-col>
      <b-col lg="9" v-show="show">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form
            class="form"
            id="patient_form"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <iq-card id="iq-card__form">
              <template v-slot:headerTitle>
                <h4 class="card-title">Cadastro de Paciente</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-3">
                      <div class="photo">
                        <img
                          :src="
                            avatarUrl
                              ? avatarUrl
                              : avatar
                              ? avatar.result
                              : newPatient.idSexo === 1
                              ? require('../../assets/images/user/2.png')
                              : require('../../assets/images/user/1.jpg')
                          "
                          class="avatar-100"
                        />
                        <input
                          type="file"
                          ref="fileInput"
                          style="display: none"
                          v-on:change="changeImage"
                        />
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="$refs.fileInput.click()"
                          >Selecionar foto</b-button
                        >
                      </div>
                    </b-form-group>
                    <b-row class="col-md-9" style="margin: 0; padding: 0">
                      <b-form-group
                        class="col-md-12"
                        label="Nome:"
                        label-for="nome"
                      >
                        <ValidationProvider
                          name="nome"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="newPatient.nome"
                            ref="nameInput"
                            name="nome"
                            type="text"
                            v-uppercase
                            autocomplete="noautocomplete"
                            :class="
                              errors.length > 0 || (isAdd && !newPatient.nome)
                                ? ' is-invalid'
                                : ''
                            "
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>Informe o nome</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group
                        class="col-md-6"
                        label="Nome social:"
                        label-for="nomeSocial"
                      >
                        <b-form-input
                          v-model="newPatient.nomeSocial"
                          name="nomeSocial"
                          type="text"
                          v-uppercase
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        class="col-md-6"
                        label="Ficha do Paciente:"
                        label-for="ficha"
                      >
                        <b-form-input
                          v-model="newPatient.fichaPaciente"
                          name="ficha"
                          type="text"
                          v-uppercase
                        ></b-form-input>
                      </b-form-group>
                    </b-row>

                    <b-form-group
                      class="col-md-3"
                      label="Data de nascimento:"
                      label-for="dtNascimento"
                    >
                      <ValidationProvider
                        rules="dateIsValid"
                        v-slot="{ errors }"
                        name="BirthDate"
                      >
                        <b-form-input
                          name="BirthDate"
                          v-mask="'##/##/####'"
                          v-model="newPatient.dtNascimento"
                          type="text"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                          :style="
                            isAdd && !newPatient.dtNascimento
                              ? 'borderColor: #9c27b0'
                              : ''
                          "
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe uma data de nascimento válida</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label-for="sexo"
                      label="Sexo:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idSexo"
                        :options="genders"
                        :reduce="(descSexo) => descSexo.id"
                        label="descSexo"
                        :style="
                          isAdd && !newPatient.idSexo
                            ? 'borderColor: #9c27b0'
                            : ''
                        "
                      ></v-select>
                    </b-form-group>
                    <b-form-group class="col-md-3" label="CPF:" label-for="cpf">
                      <ValidationProvider
                        name="cpf"
                        rules="cpfIsValid"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-mask="'###.###.###-##'"
                          v-model="newPatient.cpf"
                          name="cpf"
                          :style="
                            isAdd && !newPatient.cpf
                              ? 'borderColor: #9c27b0'
                              : ''
                          "
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um CPF válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Identidade:"
                      label-for="identidade"
                    >
                      <b-form-input
                        v-model="newPatient.identidade"
                        type="text"
                        autocomplete="noautocomplete"
                        name="identidade"
                        id="identidade"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-3" label="CNS:" label-for="cns">
                      <ValidationProvider name="cns">
                        <b-form-input
                          v-model="newPatient.cns"
                          v-mask="'###.####.####.####'"
                          maxLength="18"
                          type="text"
                          name="cns"
                          ref="cns"
                          :style="
                            isAdd && !newPatient.cns
                              ? 'borderColor: #9c27b0'
                              : ''
                          "
                          :class="[
                            newPatient.cns && !cnsIsValid ? 'is-invalid' : ''
                          ]"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um CNS válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Nº Passaporte:"
                      label-for="passaporte"
                    >
                      <b-form-input
                        maxlength="8"
                        v-model="newPatient.passaporte"
                        type="text"
                        autocomplete="noautocomplete"
                        name="passaporte"
                        ref="passaporte"
                        :style="
                          isAdd && !newPatient.passaporte
                            ? 'borderColor: #9c27b0'
                            : ''
                        "
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Celular:"
                      label-for="celular"
                    >
                      <ValidationProvider
                        name="celular"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-mask="'(##)# #### - ####'"
                          v-model="newPatient.telCelular"
                          type="text"
                          autocomplete="noautocomplete"
                          :class="
                            errors.length > 0 ||
                            (isAdd && !newPatient.telCelular)
                              ? ' is-invalid'
                              : ''
                          "
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe o telefone do paciente</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Fixo:"
                      label-for="fixo"
                    >
                      <b-form-input
                        v-mask="'(##)#### - ####'"
                        v-model="newPatient.telResidencial"
                        type="text"
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Nacionalidade:"
                      label-for="idPaisNacionalidade"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idPaisNacionalidade"
                        :options="countries"
                        :reduce="(descPais) => descPais.id"
                        label="descPais"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Naturalidade:"
                      label-for="idMunicipioNascimento"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idMunicipioNascimento"
                        :options="cities"
                        :reduce="(descCidade) => descCidade.id"
                        label="descCidade"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-4"
                      label-for="idSituacao"
                      label="Situação:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idSituacao"
                        :options="situations"
                        :reduce="(descSituacao) => descSituacao.id"
                        label="descSituacao"
                      ></v-select>
                    </b-form-group>

                    <b-form-group
                      class="col-md-6"
                      label-for="idEstadoCivil"
                      label="Estado Civil:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idEstadoCivil"
                        :options="maritalStatus"
                        :reduce="(descEstadoCivil) => descEstadoCivil.id"
                        label="descEstadoCivil"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Religião:"
                      label-for="religion"
                    >
                      <b-form-input
                        v-model="newPatient.religiao"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome da mãe:"
                      label-for="nomeMae"
                    >
                      <b-form-input
                        v-model="newPatient.nomeMae"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                        :style="
                          isAdd && !newPatient.nomeMae
                            ? 'borderColor: #9c27b0'
                            : ''
                        "
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome do Pai:"
                      label-for="nomePai"
                    >
                      <b-form-input
                        v-model="newPatient.nomePai"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Email:"
                      label-for="email"
                    >
                      <b-form-input
                        v-model="newPatient.email"
                        type="email"
                        v-uppercase
                      ></b-form-input>
                    </b-form-group>
                    <hr />
                    <b-form-group class="col-md-3" label="CEP:" label-for="cep">
                      <b-form-input
                        v-mask="'#####-###'"
                        v-model="newPatient.cep"
                        type="text"
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-3"
                      label-for="idTipoLogradouro"
                      label="Tipo endereço:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idTipoLogradouro"
                        :options="adressTypes"
                        :reduce="(descLogradouro) => descLogradouro.id"
                        label="descLogradouro"
                      ></v-select>
                    </b-form-group>

                    <b-form-group
                      class="col-md-5"
                      label="Logradouro:"
                      label-for="logradouro"
                    >
                      <b-form-input
                        v-model="newPatient.logradouro"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-2"
                      label="Nº:"
                      label-for="numero"
                    >
                      <b-form-input
                        v-model="newPatient.numero"
                        type="number"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Complemento:"
                      label-for="complementoEndereco"
                    >
                      <b-form-input
                        v-model="newPatient.complementoEndereco"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Bairro:"
                      label-for="bairro"
                    >
                      <b-form-input
                        v-model="newPatient.bairro"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-4"
                      label-for="idEstado"
                      label="Estado:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idEstado"
                        :options="states"
                        :reduce="(descEstado) => descEstado.id"
                        label="descEstado"
                        @input="getCitiesByState()"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-4"
                      label-for="idCidade"
                      label="Cidade:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idCidade"
                        :options="citiesByState"
                        :reduce="(descCidade) => descCidade.id"
                        label="descCidade"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Profissão(cbo):"
                      label-for="specialty"
                    >
                      <v-select
                        id="specialty"
                        :options="cbos"
                        label="descCbo"
                        :reduce="(cbo) => cbo.id"
                        v-model="newPatient.idCbo"
                      />
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Outra Profissão:"
                      label-for="outraProfissao"
                    >
                      <b-form-input
                        v-model="newPatient.profissaoOutros"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-6"
                      label-for="healthPlan"
                      label="Plano de Saude:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.healthPlan"
                        :options="uniqueHealthPlans"
                        label="label"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-6"
                      label-for="idAcomodacaoConvenio"
                      label="Acomodação:"
                    >
                      <v-select
                        :clearable="false"
                        v-model="newPatient.idAcomodacaoConvenio"
                        :options="acomodacaoOptions"
                        :reduce="(option) => option.value"
                      />
                    </b-form-group>
                    <b-form-group
                      class="col-sm-3"
                      label="Cartão:"
                      label-for="numberPlan"
                    >
                      <b-form-input
                        v-model="newPatient.numberPlan"
                        class="number_plan"
                        type="text"
                        autocomplete="noautocomplete"
                        maxLength="20"
                        v-mask="cardMask"
                      ></b-form-input>
                    </b-form-group>
                    <b-button
                      style="
                        align-self: center;
                        font-weight: 500;
                        margin-top: 12px;
                      "
                      size="sm"
                      variant="primary"
                      v-on:click="addConvenio()"
                      >Adicionar plano de saude</b-button
                    >
                  </b-row>
                  <div class="table">
                    <div class="headerList">
                      <span>Plano</span>
                      <span>Acomodação</span>
                      <span>Cartão</span>
                      <span>Ação</span>
                    </div>

                    <div
                      class="dataList"
                      v-for="(convenio, index) in newPatient.convenioPaciente"
                      :key="index"
                    >
                      <span>{{ convenio.nmConvenio }}</span>
                      <span>{{ convenio.descAcomodacaoConvenio }}</span>
                      <span>{{ convenio.numeroCartao }}</span>
                      <div class="d-flex">
                        <b-button
                          size="sm"
                          class="me-2"
                          variant="outline-primary"
                          v-b-modal.modal-edit-convenio
                          @click="editConvenio(convenio)"
                        >
                          Editar
                        </b-button>
                        <b-modal
                          id="modal-edit-convenio"
                          ref="modal-edit-convenio"
                          title="Editar Convênio"
                          @ok="saveConvenio"
                        >
                          <b-form-group
                            label="Plano de saúde:"
                            label-for="healthPlan"
                          >
                            <v-select
                              :clearable="false"
                              v-model="editingConvenio.healthPlan"
                              :options="uniqueHealthPlans"
                              label="label"
                            ></v-select>
                          </b-form-group>

                          <b-form-group
                            label="Acomodação:"
                            label-for="idAcomodacaoConvenio"
                          >
                            <v-select
                              :clearable="false"
                              v-model="editingConvenio.idAcomodacaoConvenio"
                              :options="acomodacaoOptions"
                              :reduce="(option) => option.value"
                            />
                          </b-form-group>

                          <b-form-group label="Cartão:" label-for="numberPlan">
                            <b-form-input
                              v-model="editingConvenio.numeroCartao"
                              class="number_plan"
                              type="text"
                              autocomplete="noautocomplete"
                              maxLength="20"
                              v-mask="cardMask"
                            ></b-form-input>
                          </b-form-group>
                        </b-modal>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="removeConvenio(convenio)"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <b-row> </b-row>
                  <div class="buttons">
                    <b-button variant="danger" v-b-modal.modal-confirm
                      >Cancelar</b-button
                    >
                    <b-modal
                      id="modal-confirm"
                      ref="modal-confirm"
                      hide-footer
                      title="Todos os dados não salvos serão perdidos"
                      cancel-title="Não"
                      ok-title="Sim"
                    >
                      <p class="my-2">Você realmente deseja cancelar?</p>
                      <div class="buttons">
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="danger"
                          @click="hideModal"
                          >Não</b-button
                        >
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="primary"
                          @click="cancelar"
                          >Sim</b-button
                        >
                      </div>
                    </b-modal>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="requesting"
                      >Salvar</b-button
                    >
                  </div>
                </div>
              </template>
            </iq-card>
          </form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import {
  formatConveniosToShow,
  formatConveniosToSubmit,
  validateCns
} from '../../Utils/formatters'

import API from '../../services/index'

import iqCard from '../../components/xray/cards/iq-card.vue'

export default {
  components: { iqCard },
  name: 'AddPatient',
  created() {
    this.requests()
  },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      requesting: false,
      debounce: null,
      searchedPatient: '',
      show: true,
      genders: [],
      countries: [],
      isAdd: true,
      states: [],
      cities: [],
      citiesByState: [],
      situations: [],
      healthPlans: [],
      userPatients: [],
      municipio: '',
      municipioValido: false,
      maritalStatus: [],
      newPatient: {
        nome: '',
        nomeSocial: '',
        dtNascimento: '',
        cpf: '',
        identidade: '',
        cns: '',
        idPaisNacionalidade: '',
        idMunicipioNascimento: '',
        passaporte: '',
        telCelular: '',
        telResidencial: '',
        status: 0,
        nomeMae: '',
        nomePai: '',
        email: '',
        idTipoLogradouro: '',
        cep: '',
        logradouro: '',
        numero: '',
        complementoEndereco: '',
        bairro: '',
        idEstado: '',
        idCidade: '',
        healthPlan: '',
        numberPlan: '',
        convenioPaciente: [],
        idSituacao: '',
        idSexo: '',
        descArquivoImagem: '',
        fichaPaciente: '',
        profissaoOutros: '',
        idCbo: '',
        descCbo: ''
      },
      editingConvenio: {},
      acomodacaoOptions: [
        { label: 'NÃO INFORMADO', value: null },
        { label: 'ENFERMARIA', value: 1 },
        { label: 'APARTAMENTO', value: 2 }
      ],
      avatar: '',
      avatarUrl: '',
      avatarImage: '',
      adressTypes: [],
      loading: false,
      patientToSave: '',
      cbos: ''
    }
  },
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      colors: 'Setting/colorState',
      user: 'Setting/user'
    }),

    cardMask() {
      if (this.newPatient.healthPlan?.mascaraCartao) {
        return this.newPatient.healthPlan.mascaraCartao.replaceAll(/\d/g, '#')
      }
      return ''
    },
    cnsIsValid() {
      return validateCns(this.newPatient.cns)
    },
    formatOptionLabel() {
      return (option) => this.formatOption(option)
    },
    uniqueHealthPlans() {
      // Crie um objeto temporário para rastrear as opções únicas
      const uniqueOptions = {}
      const uniqueHealthPlans = []
      // Percorra as opções originais e adicione um sufixo exclusivo a cada uma
      for (const option of this.healthPlans) {
        const uniqueKey = `${option.razaoSocial}-${option.nmProfissional}`
        if (!uniqueOptions[uniqueKey]) {
          uniqueOptions[uniqueKey] = true
          uniqueHealthPlans.push({
            ...option,
            id: option.id,
            label: this.formatOption(option) // Use o sufixo exclusivo como chave única
          })
        }
      }
      return uniqueHealthPlans
    }
  },

  methods: {
    ...mapActions({
      deletePatientAction: 'Patient/deletePatientAction'
    }),
    formatOption(option) {
      return `${option.razaoSocial} - ${option.nmProfissional}`
    },
    changeImage(e) {
      this.avatarImage = e.target.files[0]
      this.avatarUrl = URL.createObjectURL(this.avatarImage)
    },
    addConvenio() {
      const healthPlanId = this.newPatient.healthPlan.id
      const healthPlanName = this.newPatient.healthPlan.razaoSocial
      const numberPlan = this.newPatient.numberPlan
      const healthPlanIdUsuario = this.newPatient.healthPlan.idUsuario
      const acomodation = this.newPatient.idAcomodacaoConvenio
      const acomodacaoDesc = this.getDescriptionFromId(acomodation)

      this.newPatient.convenioPaciente.push({
        numeroCartao: numberPlan,
        nmConvenio: healthPlanName,
        idConvenio: healthPlanId,
        idUsuario: healthPlanIdUsuario,
        idPaciente: this.newPatient.id,
        idAcomodacaoConvenio: acomodation,
        descAcomodacaoConvenio: acomodacaoDesc,
        isNew: true
      })

      this.newPatient.numberPlan = ''

      document.querySelector('.number_plan').focus()
    },
    getDescriptionFromId(value) {
      const acomodacaoOptions = [
        { label: 'NÃO INFORMADO', value: null },
        { label: 'ENFERMARIA', value: 1 },
        { label: 'APARTAMENTO', value: 2 }
      ]

      if (value === null || value === undefined) {
        return 'NÃO INFORMADO'
      }

      const option = acomodacaoOptions.find((option) => option.value === value)

      return option ? option.label : 'Não Informado'
    },

    editConvenio(convenio) {
      // Desestruturar e remover o healthPlan do objeto convenio
      const { healthPlan, ...convenioSemHealthPlan } = convenio

      // Atualiza o editingConvenio sem o healthPlan
      this.editingConvenio = {
        ...convenioSemHealthPlan,
        // Atualiza healthPlan se necessário
        healthPlan:
          this.uniqueHealthPlans.find(
            (plan) => plan.id === convenio.idConvenio
          ) || null
      }

      // Exibe o modal
      this.$nextTick(() => {
        this.$bvModal.show('modal-edit-convenio')
      })
    },

    saveConvenio() {
      if (this.editingConvenio.healthPlan) {
        this.editingConvenio.idConvenio = this.editingConvenio.healthPlan.id
        this.editingConvenio.nmConvenio =
          this.editingConvenio.healthPlan.razaoSocial
      }
      this.editingConvenio.descAcomodacaoConvenio = this.getDescriptionFromId(
        this.editingConvenio.idAcomodacaoConvenio
      )

      // Cria uma cópia do objeto excluindo o campo healthPlan
      const { healthPlan, ...convenioPaciente } = this.editingConvenio

      // Busca o índice do convênio na lista de convênios do paciente
      const index = this.newPatient.convenioPaciente.findIndex(
        (c) => c.id === this.editingConvenio.id
      )
      if (index !== -1) {
        // Atualiza o convênio com os novos valores
        this.newPatient.convenioPaciente.splice(index, 1, {
          ...convenioPaciente,
          descAcomodacaoConvenio: this.getDescriptionFromId(
            this.editingConvenio.idAcomodacaoConvenio
          ),
          nmConvenio: this.editingConvenio.healthPlan.razaoSocial
        })
      }

      this.$bvModal.hide('modal-edit-convenio')
      // this.requesting = false
    },
    async removeConvenio(convenioPaciente) {
      const convenioIndex = this.newPatient.convenioPaciente.findIndex(
        (convenio) => convenioPaciente === convenio
      )

      const convenioBackup = this.newPatient.convenioPaciente[convenioIndex]
      const object = convenioBackup

      try {
        this.newPatient.convenioPaciente.splice(convenioIndex, 1)

        delete convenioBackup.isNew
        delete convenioBackup.idPacienteConvenio

        if (!this.isAdd && !convenioBackup.isNew) {
          await API.delete('/api/PacienteConvenio/Remover', {
            data: { object },
            headers: this.authToken.headers
          })
        }
      } catch (error) {
        this.newPatient.convenioPaciente.splice(
          convenioIndex,
          0,
          convenioBackup
        )
      }
    },
    formatDate(date, format) {
      if (date) {
        const newDateFormat = new Date(date)
        return newDateFormat.toLocaleDateString(format)
      } else {
        return ''
      }
    },
    handlerShow(patient) {
      this.resetForm()

      // Espera o próximo "tick" do DOM
      this.$nextTick(() => {
        if (patient) {
          this.avatarUrl = ''
          this.avatarImage = ''

          // Clona o objeto paciente para newPatient
          this.newPatient = { ...patient }

          this.avatar = patient.avatar

          // Formata os convênios e atribui a newPatient
          this.newPatient.convenioPaciente = formatConveniosToShow(
            patient.convenioPaciente
          )

          // Formata a data de nascimento
          this.newPatient.dtNascimento = this.formatDate(
            this.newPatient.dtNascimento,
            'pt-BR'
          )
          // Obtém as cidades se o idEstado estiver presente
          if (patient.idEstado) {
            this.getCitiesByState()
          }

          this.isAdd = false
        } else {
          this.isAdd = true
          this.$refs.nameInput.focus()
        }
      })
    },
    async requests() {
      await API.get('api/Sexo/ObtemLista').then((res) => {
        this.genders = res.data.object
      })

      await API.get('api/TipoLogradouro/ObtemLista', this.authToken).then(
        (res) => {
          this.adressTypes = res.data.object
        }
      )

      await API.get('api/Estado/ObtemLista').then((res) => {
        this.states = res.data.object
      })

      await API.get('api/Pais/ObtemLista', this.authToken).then((res) => {
        this.countries = res.data.object.sort(function (a, b) {
          return a.descPais - b.descPais
        })
      })

      await API.get(`api/Cidade/ObtemLista`, this.authToken).then((res) => {
        this.cities = res.data.object.sort()
      })

      await API.get(`api/SituacaoPaciente/ObtemLista`, this.authToken).then(
        (res) => {
          this.situations = res.data.object.sort()
        }
      )

      await API.get(
        `api/Convenio/ObtemListaPorUsuario/?idusuario=${this.user.id}`,
        this.authToken
      ).then((res) => {
        this.healthPlans = res.data.object.sort()
      })

      await API.get('api/EstadoCivil/ObtemLista', this.authToken).then(
        (res) => (this.maritalStatus = res.data.object)
      )

      await API.get('api/Cbo/ObtemLista', this.authToken).then(
        (res) => (this.cbos = res.data.object)
      )
    },

    formatCpf() {
      if (this.patientToSave.cpf) {
        this.patientToSave.cpf = this.patientToSave.cpf.replace(/\D/g, '')
      }
    },
    formatPhone() {
      if (this.patientToSave.telCelular) {
        const newPhone = this.patientToSave.telCelular.replace(/\D/g, '')
        this.patientToSave.telCelular = parseInt(newPhone)
      }

      if (this.patientToSave.telResidencial) {
        const newFixPhone = this.patientToSave.telResidencial.replace(/\D/g, '')
        this.patientToSave.telResidencial = parseInt(newFixPhone)
      }
    },
    formatCep() {
      if (this.patientToSave.cep) {
        this.patientToSave.cep = this.patientToSave.cep.replace(/\D/g, '')
      }
    },
    formatCns() {
      if (this.patientToSave.cns) {
        this.patientToSave.cns = this.patientToSave.cns.replace(/\D/g, '')
      }
    },
    hideModal() {
      this.$refs['modal-confirm'].hide()
    },
    cancelar() {
      this.hideModal()
    },
    resetForm() {
      this.newPatient = {
        nome: '',
        nomeSocial: '',
        dtNascimento: '',
        cpf: '',
        identidade: '',
        cns: '',
        idPaisNacionalidade: '',
        idMunicipioNascimento: '',
        passaporte: '',
        telCelular: '',
        telResidencial: '',
        status: '',
        nomeMae: '',
        nomePai: '',
        email: '',
        idTipoLogradouro: '',
        cep: '',
        logradouro: '',
        numero: '',
        complementoEndereco: '',
        convenioPaciente: [],
        bairro: '',
        idEstado: '',
        idCidade: '',
        healthPlan: '',
        numberPlan: '',
        idSituacao: '',
        idSexo: '',
        avatarUrl: '',
        avatarImage: '',
        descArquivoImagem: '',
        avatar: '',
        fichaPaciente: ''
      }
    },
    async getCitiesByState() {
      await API.get(
        `api/Cidade/ObtemCidadesPorEstado/${this.newPatient.idEstado}`
      ).then((res) => {
        this.citiesByState = res.data.object
      })
    },
    async searchPatient(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        const filter = event.target.value.toUpperCase()
        if (filter) {
          this.loading = true
          const urlToFetchData = `api/Paciente/ObtemListaPorFiltro${
            this.user.perfil_usuario[this.user.selectedProfileIndex]
              .idPerfil !== 1
              ? 'Clinica'
              : 'Medico'
          }/?${
            this.user.perfil_usuario[this.user.selectedProfileIndex]
              .idPerfil !== 1
              ? `id_clinica=${this.user.clinica_usuario[0].idClinica}`
              : `id_usuario=${this.user.id}`
          }&paciente=${filter}`
          API.get(urlToFetchData, this.authToken).then((res) => {
            if (res.data.object.length > 20) {
              this.$toast.info(
                'A pesquisa retornou muitas correspondências, por favor especifique mais os dados!'
              )
              this.loading = false
            } else {
              const temp = res.data.object
              const modifiedTemp = temp.map((patient) => {
                if (patient.descArquivoImagem) {
                  API.get(
                    `api/Paciente/AwsS3ObtemArquivo?arquivoname=${patient.descArquivoImagem}&id_paciente=${patient.id}`,
                    { responseType: 'blob' },
                    {
                      headers: {
                        Authorization: `Bearer ${window.$cookies.get('token')}`
                      }
                    }
                  ).then((res) => {
                    patient.avatar = this.createFileReader(res.data)
                  })
                }
                patient.cpf = patient.cpf?.toString()
                patient.telCelular = patient.telCelular?.toString()
                patient.telResidencial = patient.telResidencial?.toString()

                return patient
              })

              Promise.all(modifiedTemp).then(() => {
                this.searchedPatient = modifiedTemp
              })
              this.loading = false
            }
          })
        } else {
          this.searchedPatient = ''
          this.loading = false
        }
      }, 1000)
    },
    async deletePatient(patient) {
      const { avatar, ...payload } = patient

      try {
        this.requesting = true
        const response = await this.deletePatientAction(payload)

        if (response.result) {
          const index = this.searchedPatient.indexOf(patient)

          this.searchedPatient.splice(index, 1)

          this.$toast.success(response.message)
        } else {
          this.$toast.error(response.message)
        }
      } catch (e) {
        this.$toast.error(e.response.data.message)
      } finally {
        this.requesting = false
      }
    },
    municipioIsValid() {
      const city = this.cities.find(
        (city) => city.descCidade === this.municipio
      )
      if (!city) {
        return (this.municipioValido = false)
      } else {
        this.newPatient.idMunicipioNascimento = city.id
        this.municipioValido = true
      }
    },
    createFileReader(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = function (e) {
        return reader.result
      }

      return reader
    },
    onSubmit() {
      try {
        this.requesting = true
        // Define o idUsuario para o novo paciente
        this.newPatient.idUsuario = this.user.id

        // Cria uma cópia do paciente para salvar
        this.patientToSave = { ...this.newPatient }

        // Formata os campos
        this.formatCpf()

        this.formatPhone()

        this.formatCep()

        this.formatCns()

        // Formata os convênios para submissão
        const convenios = formatConveniosToSubmit(
          this.patientToSave.convenioPaciente
        )
        let file = ''
        let fileType = ''
        let fileName = ''
        let bodyFormData = ''

        // Trata o arquivo de avatar
        if (this.avatarImage) {
          file = {
            file: this.avatarImage,
            tipoArquivoImagem:
              this.avatarImage.type === 'image/jpeg' ||
              this.avatarImage.type === 'image/png'
                ? 'image'
                : this.avatarImage.type === 'application/pdf'
                ? 'pdf'
                : 'doc',
            descAwsArquivoImagem: this.avatarImage.name
          }

          bodyFormData = new FormData()
          bodyFormData.append('file', file.file)

          fileType =
            file.file.type === 'image/jpeg'
              ? 'image'
              : file.file.type === 'application/pdf'
              ? 'pdf'
              : 'doc'

          fileName = file.file.name
        }
        // Remove a propriedade isNew dos convênios
        convenios.forEach((object) => {
          delete object.isNew
        })

        // Dispatch da ação de adicionar ou editar paciente
        this.$store
          .dispatch(`Patient/${this.isAdd ? 'add' : 'edit'}PatientAction`, {
            ...this.patientToSave,
            descArquivoImagem: this.avatarImage.name
              ? this.avatarImage.name
              : this.patientToSave.descArquivoImagem,
            idClinica: this.user.clinica_usuario[0].idClinica,
            dtNascimento: this.patientToSave.dtNascimento
              .split('/')
              .reverse()
              .join('-'),
            convenioPaciente: convenios,
            fichaPaciente: this.patientToSave.fichaPaciente,
            file,
            fileType,
            fileName,
            bodyFormData
          })
          .then((response) => {
            this.$toast.success(response)
            this.patientToSave = ''
          })
          .catch((err) => {
            const message = err.response.data.message

            this.$toast.error(message || 'Ocorreu algum erro, tente novamente!')
          })
      } catch (error) {
        this.$toast.error('Ocorreu algum erro, tente novamente!')
      } finally {
        this.requesting = false
      }
    }
  }
}
</script>

<style scoped>
#usersListContainer {
  display: flex;
  flex-direction: column;
  height: 80vh;
}
.d-flex .btn {
  flex: 1 1 auto; /* Ajusta o tamanho dos botões para ocupar o mesmo espaço */
}

/* Adiciona espaçamento entre os botões */
.d-flex .me-2 {
  margin-right: 0.5rem; /* Ajuste conforme necessário */
}

#usersList {
  flex: 1;
  margin-bottom: 0;
}

.onHover:hover {
  cursor: pointer;
}

.cardSameLevel {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sameLevel {
  display: flex;
  align-items: flex-end;
}

.radioOptions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  background-color: #089bab !important;
  color: white;
  border-radius: 25px;
}

.usersList {
  overflow-x: auto;
  margin-bottom: 20px;
}

.delete-button {
  flex-direction: column;
  align-items: center; /* Alinha verticalmente o conteúdo do botão */
  justify-content: space-between; /* Alinha horizontalmente o conteúdo do botão */
}
.delete-button:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.iq-card_scoped {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px !important;
  background-color: white;
  border-radius: 25px;
}
.form {
  height: 80vh;
  overflow-x: auto;
}
.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Garante que o conteúdo e o botão sejam alinhados nas extremidades */
  padding: 10px; /* Adiciona padding ao container */
  width: 100%; /* Garante que o container ocupe toda a largura disponível */
  box-sizing: border-box; /* Inclui padding e border no cálculo da largura */
}

.patientInfo {
  flex: 1; /* Faz com que as informações do paciente ocupem o máximo de espaço disponível */
  margin-right: 20px; /* Adiciona um espaço entre as informações e o botão */
  max-width: 100%; /* Define um limite máximo para a largura das informações */
  justify-content: center;
  align-items: left;
}

.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.table {
  background: white;
  overflow-y: auto !important;
  height: 300px !important;
  border-radius: 6px !important;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #089bab;
  color: #fff;
}

.card-title {
  padding-left: 20px;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  align-items: center;
  border-bottom: 1px solid #d4dae4;
  padding: 10px;
  color: #3d5879;
}

.buttonBottom {
  margin-top: 10px;
  bottom: 0;
  right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
