<template>
  <b-modal
    id="modalCertify"
    header-bg-variant="primary"
    title="Importar Certificados"
    size="lg"
    title-class="text-light"
    ok-title="Salvar"
    hide-footer
    @close="close"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="certify">
      <div class="certify-options">
        <button
          :disabled="certificate.keyType === 'PSC' ? true : false"
          @click="toogleHandle()"
          :class="options ? 'certify-button' : 'certify-button-disabled'"
        >
          A1
        </button>
        <div class="divider"></div>
        <button
          :disabled="certificate.keyType === 'A1' ? true : false"
          @click="toogleHandle()"
          :class="
            options === false ? 'certify-button' : 'certify-button-disabled'
          "
        >
          PSC
        </button>
      </div>
      <ValidationObserver v-show="options" ref="form">
        <form id="form">
          <div class="tab" v-if="!certificate.thumbprint">
            <div class="inputStyled">
              <input
                @change="selectFile"
                type="file"
                id="inputFile"
                accept=".pfx, .p12"
              />
              <b-form-input
                v-model="fileName"
                placeholder="Selecione um arquivo"
                readonly
              />
              <b-button variant="primary" @click.prevent="triggerInputFile"
                >Buscar</b-button
              >
            </div>

            <label style="margin-top: 8px">Senha do certificado:</label>

            <b-form-input v-model="filePassword" type="password" />

            <div class="button-container" style="margin-top: 50px">
              <b-button
                variant="primary"
                @click.prevent="handleAddCertificate"
                :disabled="filePassword.length < 3"
                style="padding: 0; border: 0"
              >
                <label class="default-button">
                  <span>Enviar</span>
                </label>
              </b-button>
            </div>
          </div>

          <div class="tab" v-if="certificate.thumbprint">
            <div class="certificate-info-container">
              <h4>Dados certificado:</h4>
              <span class="certificate-info-text">
                {{ certificate.subject }}
              </span>
              <h4>Tipo do certificado:</h4>
              <span class="certificate-info-text">
                {{ certificate.certificateType }}
              </span>
              <h4>Válido até:</h4>
              <span class="certificate-info-text">
                {{ certificate.validTo }}
              </span>
            </div>

            <div class="button-container" style="margin-top: 50px">
              <b-button
                variant="danger"
                style="padding: 0; border: 0"
                @click="handleDeleteCertificate"
              >
                <label class="default-button">
                  <span>Excluir</span>
                </label>
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <ValidationObserver v-show="options === false" ref="form">
        <form id="form1">
          <div class="colunm-psc" v-if="!certificate.thumbprint">
            <div class="enabled-psc">
              <div class="phrase-enabled">
                <p>
                  Em caso de certificado em nuvem, clique no botão abaixo para
                  habilitar:
                </p>
                <span
                  id="tooltip"
                  target="icon"
                  v-b-tooltip.hover.focus.v-info
                  :title="tooltipText"
                >
                  <i class="fas fa-info-circle" id="icon"></i>
                </span>
              </div>
              <a
                href="https://cloud.madics.com.br/idm/auth/realms/madicscloud/protocol/openid-connect/auth?client_id=webadmin&redirect_uri=https%3A%2F%2Fcloud.madics.com.br%2Fmain&state=a3a05346-3bf4-45bd-911c-52e27b8ac178&response_mode=fragment&response_type=code%20id_token%20token&scope=openid&nonce=f959e2dd-a844-4b12-8ecc-395b5c4e17bc"
                target="_blank"
                >HABILITAR</a
              >
            </div>
            <div class="validt-psc">
              <p>
                Após habilitar no fluxo acima, clique para INCLUIR no sistema
                para uso:
              </p>
              <button @click.prevent="handleAddCertificatePsc()">
                INCLUSÃO
              </button>
            </div>
          </div>
          <div class="tab" v-if="certificate.thumbprint">
            <template>
              <span class="tab-item-text">Dados certificado</span>
            </template>
            <div class="certificate-info-container">
              <h4>Certificado:</h4>
              <span class="certificate-info-text">
                {{ certificate.subject }}
              </span>
              <h4>Tipo do certificado:</h4>
              <span class="certificate-info-text">
                {{ certificate.certificateType }}
              </span>
              <h4>Válido até:</h4>
              <span class="certificate-info-text">
                {{ certificate.validTo }}
              </span>
            </div>

            <div class="button-container" style="margin-top: 50px">
              <b-button
                variant="danger"
                style="padding: 0; border: 0"
                @click="handleDeleteCertificate"
              >
                <label class="default-button">
                  <span>Excluir</span>
                </label>
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},
  props: [],
  data() {
    return {
      tooltipText: 'Adefinir',
      expirationObjects: '',
      form: {
        selectedAssignType: 'A1',
        pin: '',
        selectedExpirationTime: 12
      },
      expirationTimes: [1, 2, 4, 8, 12],
      options: true,
      fileBase64: '',
      fileName: '',
      filePassword: '',
      fileType: ''
    }
  },

  computed: {
    ...mapGetters({
      user: 'Setting/user',
      certificate: 'DigitalAssign/certificate'
    })
  },
  methods: {
    ...mapActions({
      refreshAssignStatusAndState:
        'DigitalAssign/refreshAssignStatusAndStateAction',
      addCertificate: 'DigitalAssign/addCertificate',
      addCertificatePSC: 'DigitalAssign/addCertificatePSC',
      recoverCertificate: 'DigitalAssign/recoverCertificate',
      deleteCertificate: 'DigitalAssign/deleteCertificate'
    }),
    savedAcuity() {
      this.$emit('saved')
    },

    toogleHandle() {
      if (this.certificate.keyType === 'A1') {
        this.options = true
      } else if (this.certificate.keyType === 'PSC') {
        this.options = false
      } else {
        this.options = !this.options
      }
    },

    setOption() {
      this.certificate.keyType === 'PSC'
        ? (this.options = false)
        : (this.options = true)
    },

    close() {
      this.$emit('close')
    },

    selectFile(event) {
      this.fileName = event.target.files[0].name
      this.fileType = event.target.files[0].type

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])

      reader.onload = () => {
        const base64result = reader.result.substr(
          reader.result.indexOf(',') + 1
        )
        this.fileBase64 = base64result
      }
    },
    triggerInputFile() {
      document.getElementById('inputFile').click()
    },
    async handleAddCertificate() {
      const { fileBase64, filePassword, fileType } = this

      const passwordBase64 = btoa(filePassword)

      const result = await this.addCertificate({
        fileBase64,
        passwordBase64,
        fileType,
        cpf: this.user.cpf
      })

      if (result) {
        this.$toast.success('Certificado adicionado com sucesso')
        this.recoverCertificate({ cpf: this.user.cpf, type: 'qualified' })

        this.fileBase64 = ''
        this.fileName = ''
        this.filePassword = ''
        this.fileType = ''
      } else {
        this.$toast.error('Erro ao enviar o certificado')
      }
    },
    async handleAddCertificatePsc() {
      const result = await this.addCertificatePSC({
        cpf: this.user.cpf
      })

      if (result) {
        this.$toast.success('Certificado adicionado com sucesso')
        this.recoverCertificate({ cpf: this.user.cpf, type: 'qualified' })
      } else {
        this.$toast.error('Erro ao enviar o certificado')
      }
    },
    async handleDeleteCertificate() {
      try {
        await this.deleteCertificate(this.certificate.thumbprint)
      } catch (error) {
        this.$toast.success(error.response.message)
      }
    },
    calculateExpirationDate() {
      const date = new Date()
      const timeInMinutes = this.form.selectedExpirationTime * 60
      const timeInSeconds = timeInMinutes * 60
      const timeInMilliseconds = timeInSeconds * 1000

      const expirationDateInMilliseconds = date.getTime() + timeInMilliseconds

      const expirationDate = new Date(expirationDateInMilliseconds)
        .toISOString()
        .split('.')[0]

      return expirationDate
    },

    createExpirationObjects(times) {
      const expirationObjects = [...times].map((time, index) => {
        const object = {
          id: index + 1,
          time,
          description: `Expirar login em ${time} hora(s)`
        }

        return object
      })

      this.expirationObjects = expirationObjects
    }
  },
  async mounted() {
    this.$bvModal.show('modalCertify')
    this.setOption()
    this.createExpirationObjects(this.expirationTimes)
  }
}
</script>

<style scoped>
.certify {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.certify-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certify-button {
  padding: 8px;
  flex: 1;
  font-size: 0.9rem;
  background-color: #089bab;
  color: white;
  border: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 3px solid #3a878f;
}

.divider {
  height: 40px;
  width: 2px;
  background-color: #eaeaea;
}

.certify-button-disabled {
  padding: 8px;
  flex: 1;
  font-size: 0.9rem;
  background-color: transparent;
  color: #089bab;
  border: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 3px solid #3a878f;
}
.tab {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 300px;
}

.tab-item-text {
  font-size: 1.2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 700;
  height: 25px;
}

.inputStyled {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5px;
  margin-bottom: 20px;
}
#inputFile {
  display: none;
}
.certificate-info-container {
  display: flex;
  flex-direction: column;
}

.certificate-info-container h4 {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 25px;
  font-size: 1rem;
}

.certificate-info-text {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  height: 25px;
  font-size: 0.9rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.button-container {
  margin-top: auto;
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: right;
}

.default-button {
  border: 0;
  padding: 10px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.default-button span {
  font-size: 1.2rem;
  color: #fff;
}
.tooltip {
  opacity: 1 !important;
}

.colunm-psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.enabled-psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.enabled-psc a {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #089bab;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

.enabled-psc a:hover {
  color: #fff;
}

.phrase-enabled {
  display: flex;
}

.phrase-enabled span {
  position: relative;
  top: -10px;
}

#icon {
  color: #089bab;
}

.validt-psc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.validt-psc button {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: rgb(52, 216, 52);
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}
</style>
