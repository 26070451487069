<template>
  <b-modal
    size="xl"
    id="show-pdf"
    :title="pdf.nomeArquivo"
    title-class="text-light"
    header-bg-variant="primary"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @close="resetModal"
  >
    <embed
      :src="pdf.urlArquivo"
      type="application/pdf"
      width="100%"
      height="500px"
    />
  </b-modal>
</template>
<script>
export default {
  props: ['pdf'],
  mounted() {
    this.$bvModal.show('show-pdf')
  },
  methods: {
    resetModal() {
      this.$bvModal.hide('show-pdf')
      this.$emit('closeModal')
    }
  }
}
</script>
<style scoped></style>
