const CONDUCT_MODULE_ID = 8

const getters = {
  getConductByIdHandBook: (state) => (id) => {
    const module = state.modules.find(
      (module) => module.idModulo === CONDUCT_MODULE_ID
    )
    const conduct = module.data.find((conduct) => {
      return conduct.idProntuario === id
    })
    return conduct || {}
  }
}

export default getters
