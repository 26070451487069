<template>
  <div style="height: 50vh; overflow-y: auto">
    <b-table-simple hover>
      <b-thead>
        <b-tr class="table-header">
          <b-th>Dia</b-th>
          <b-th>Primeira Consulta</b-th>
          <b-th>Ultima Consulta</b-th>
          <b-th>Início Intervalo</b-th>
          <b-th>Fim Intervalo</b-th>
          <b-th>Tempo Consulta</b-th>
          <b-th>Ativo</b-th>
          <b-th>Clínica</b-th>
          <b-th />
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(schedule, index) in schedules" :key="schedule.id">
          <b-td>{{ returnDayNameById(schedule.diaSemana) }}</b-td>
          <b-td>{{ returnTimeFromDate(schedule.primeiraConsulta) }}</b-td>
          <b-td>{{ returnTimeFromDate(schedule.ultiimaConsulta) }}</b-td>
          <b-td>{{ returnTimeFromDate(schedule.inicioIntervalo) }}</b-td>
          <b-td>{{ returnTimeFromDate(schedule.fimIntervalo) }}</b-td>
          <b-td>{{ returnTimeFromInt(schedule.tempoConsulta) }}</b-td>
          <b-td
            ><b-form-checkbox
              :id="`checkbox-${schedule.id}`"
              v-model="schedule.ativo"
              :value="0"
              :unchecked-value="1"
              @change="editSchedule(schedule)"
            >
            </b-form-checkbox>
          </b-td>
          <b-td>{{ schedule.nmClinica }}</b-td>
          <b-td>
            <b-button
              variant="outline-danger"
              size="sm"
              @click="removeSchedule(schedule.id, index)"
              >Remover</b-button
            >
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayOptions from '../../Utils/dayOptions'

export default {
  name: 'SchedulesTable',
  props: {
    selectedProfessionalId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      schedules: []
    }
  },
  async mounted() {
    if (this.selectedProfessionalId) {
      this.schedules = await this.getSchedulesByUserId(
        this.selectedProfessionalId
      )
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  watch: {
    async selectedProfessionalId(newValue) {
      if (newValue) {
        this.schedules = await this.getSchedulesByUserId(newValue)
      }
    }
  },
  methods: {
    ...mapActions({
      getSchedulesByUserIdAction: 'Schedule/getScheduleByUserIdAction',
      editScheduleAction: 'Schedule/editScheduleAction',
      removeScheduleById: 'Schedule/deleteScheduleAction'
    }),
    async getSchedulesByUserId(id) {
      try {
        const schedules = await this.getSchedulesByUserIdAction(id)

        return schedules
      } catch (error) {
        const message = error.response.data.message
        this.$toast.info(message)

        return []
      }
    },
    async editSchedule(schedule) {
      try {
        await this.editScheduleAction(schedule)

        this.$toast.success('Horário editado com sucesso!')
      } catch {
        this.$toast.error('Erro ao editar horário')
      }
    },
    async removeSchedule(scheduleId, index) {
      try {
        await this.removeScheduleById(scheduleId)

        this.schedules.splice(index, 1)

        this.$toast.success('Horário removido com sucesso!')
      } catch {
        this.$toast.error('Erro ao remover horário')
      }
    },
    returnDayNameById(id) {
      const index = dayOptions.findIndex((day) => {
        return day.id === id
      })

      if (index !== -1) {
        const name = dayOptions[index].description

        return name
      }
    },
    insertZero(value) {
      const valueString = value.toString()

      if (valueString.length === 1) {
        return `0${value}`
      }

      return value
    },

    returnTimeFromInt(value) {
      const hours = Math.floor(value / 60)
      const minutes = value % 60

      const hoursFormatted = this.insertZero(hours)
      const minutesFormatted = this.insertZero(minutes)

      return `${hoursFormatted}:${minutesFormatted}`
    },
    returnTimeFromDate(date) {
      if (!date) {
        return '--:--'
      }

      const dateObject = new Date(date)

      const hours = dateObject.getHours()
      const minutes = dateObject.getMinutes()

      const hoursWithZero = this.insertZero(hours)

      const minutesWithZero = this.insertZero(minutes)

      return `${hoursWithZero}:${minutesWithZero}`
    }
  }
}
</script>

<style scoped>
th,
td {
  text-align: center !important;
}
</style>
