import axios from 'axios'

import auth from './auth'

import router from '../router/index'

const getToken = () => {
  return window.$cookies.get('token')
}

const getBearerToken = () => {
  const token = getToken()
  let bearerToken = ''

  if (token) {
    bearerToken = `Bearer ${token}`
  }

  return bearerToken
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const handleResponseSuccessInterceptor = (response) => {
  return response
}

const handleResponseErrorInterceptor = async (error) => {
  const status = error.response?.status
  switch (status) {
    case undefined: {
      auth.logout()
      router.push('/auth/sign-in')
    }
  }
  return Promise.reject(error)
}

const handleRequestSuccessInterceptor = async (config) => {
  if (!config.url.includes('ControleSessao')) {
    await auth.verifyAccess()
  }
  const token = getBearerToken()

  config.headers.Authorization = token

  return config
}

instance.interceptors.request.use(handleRequestSuccessInterceptor, {
  synchronous: true
})

instance.interceptors.response.use(
  handleResponseSuccessInterceptor,
  handleResponseErrorInterceptor
)

export default instance
