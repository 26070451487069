import { uid } from 'uid'
const PROBLEM_MODULE_ID = 1

const mutations = {
  loadAllProblems(state, { payload: { problems, handbookId } }) {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    problems.forEach((problem) => {
      problem.acao =
        problem.idProntuario === handbookId ? 'Remover' : 'Inativar'
    })
    module.data = [...problems]
  },

  updateProblemField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)
    module.data.splice(dataIndex, 1, { ...payload, modified: true })
  },

  addProblem(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    const dataId = uid()
    module.data.push({
      ...payload,
      id: dataId,
      isNew: true
    })
  },
  removeProblem(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)
    module.data.splice(dataIndex, 1, { ...payload, deleted: true })
  },

  activateProblem(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)
    const newProblem = { ...module.data[dataIndex], acao: 'Inativar' }
    newProblem.ativo = 0
    module.data.splice(dataIndex, 1, newProblem)
  },
  inactivateProblem(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)

    const newProblem = {
      ...payload,
      ativo: 1,
      acao: 'Ativar',
      modified: true
    }

    module.data.splice(dataIndex, 1, newProblem)
  }
}

export default mutations
