<template>
  <div>
    <div class="custom-row">
      <span>{{ position }}</span>
    </div>

    <div class="custom-row">
      <b-form-input
        type="date"
        v-model="item.dtOcorrencia"
        :max="new Date().toISOString().substring(0, 10)"
      />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.queixa" />
    </div>

    <div class="custom-row">
      <b-form-input :value="dumDaysFormatted" disabled />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.peso" type="number" no-wheel />
    </div>

    <div class="custom-row">
      <b-form-input :value="item.imc" disabled type="number" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.edema" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.pressaoArterial" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.alturaUterina" type="number" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.apresentacaoFetal" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.bcf" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.indicacaoToque" />
    </div>

    <div class="custom-row">
      <b-form-input v-model="item.exantema" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Column',
  props: ['item', 'position', 'altura', 'dateDum'],
  data() {
    return {
      oldItem: ''
    }
  },
  mounted() {
    this.oldItem = { ...this.item }
  },
  watch: {
    item: {
      handler(newValue) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          if (JSON.stringify(newValue) !== JSON.stringify(this.oldItem)) {
            newValue.peso = parseFloat(newValue.peso)
            newValue.alturaUterina = parseFloat(newValue.alturaUterina)
            this.commitChanges(newValue)
            this.oldItem = { ...newValue }
          }
        }, 1000)
      },
      deep: true
    },
    'item.peso': function (newValue) {
      if (newValue && this.altura) {
        this.item.imc = this.calculateImc(this.altura, parseInt(newValue))
      }
    },
    async dateDum(newValue) {
      if (newValue && this.item.igDumUsg) {
        const res = await this.getGestationalAge({
          data_dum: newValue,
          data_ocorrencia: this.item.dtOcorrencia.substring(0, 11)
        })

        this.item.igDumUsg = res
      }
    },
    'item.dtOcorrencia': async function (newValue) {
      if (newValue && this.dateDum) {
        const res = await this.getGestationalAge({
          data_dum: this.dateDum,
          data_ocorrencia: newValue.substring(0, 11)
        })

        this.item.igDumUsg = res
      }
    },
    altura() {
      if (this.altura && this.item.peso) {
        this.item.imc = this.calculateImc(this.altura, this.item.peso)
      }
    }
  },
  methods: {
    ...mapActions({
      getGestationalAge: 'MedicalReports/getGestationalAge',
      getLocalResults: 'MedicalReports/getLocalResults'
    }),
    commitChanges(val) {
      this.$store.commit({
        type: 'MedicalReports/addResults',
        payload: val
      })
    },
    calculateImc(height, weight) {
      if (weight > 1 && height > 2) {
        height = height / 100

        const imc = parseFloat(weight) / (height * height)
        return parseFloat(imc.toFixed(2))
      } else {
        return ''
      }
    }
  },
  computed: {
    dumDaysFormatted() {
      const weeks = this.item.igDumUsg / 7
      const days = this.item.igDumUsg % 7

      let string = ''

      if (weeks >= 1) {
        string += `${Math.floor(weeks)}S`
      }

      if (weeks >= 1 && days) {
        string += ' - '
      }

      if (days > 0) {
        string += `${days}D`
      }

      return string
    }
  }
}
</script>

<style scoped>
.custom-row {
  display: flex;
  height: 52px;
  width: 160px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 12px;
}
</style>
