<template>
  <b-modal
    size="md"
    id="modal-renew"
    title="Defina sua senha"
    title-class="text-light"
    header-bg-variant="primary"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @close="resetModal"
    cancel-title="Cancelar"
    cancel-variant="danger"
  >
    <div class="closure">
      <b-col style="gap: 20px">
        <div class="form-group2">
          <label for="password">Senha:</label>

          <div class="sameLevelWithIcon">
            <input
              class="form-control mb-0"
              @click="activeOrietetion()"
              @focus="openTooltip"
              @focusout="closeTooltip"
              :type="passwordFieldType ? 'password' : 'text'"
              v-model="password"
              :style="
                active
                  ? 'outline: none; border: 1px solid red; text-transform: none !important'
                  : 'text-transform: none !important'
              "
              placeholder="Digite sua senha..."
            />
            <i
              :class="passwordFieldType ? 'fas fa-eye' : 'fas fa-eye-slash'"
              @click="passwordFieldType = !passwordFieldType"
            ></i>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
              "
            >
              <span
                v-if="active"
                style="color: red; font-size: 0.7rem; margin-top: 2px"
              >
                {{ errorMensage }}
              </span>

              <div
                v-if="isRule"
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  align-items: flex-start;
                "
              >
                <span style="font-size: 0.7rem; margin-top: 2px"
                  >A senha precisa conter:</span
                >
                <div
                  style="
                    display: flex;
                    font-size: 0.7rem;
                    flex-direction: column;
                    gap: 2px;
                    align-items: flex-start;
                  "
                >
                  <span
                    :style="
                      rule1
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >8 digitos</span
                  >
                  <span
                    :style="
                      rule2
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >1 caractere especial (como !,@,#, etc.)</span
                  >
                  <span
                    :style="
                      rule3
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >1 letra maíuscula</span
                  >
                  <span
                    :style="
                      rule4
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >1 numero</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group2">
          <label for="confirmPassword">Confirme a senha:</label>
          <div class="sameLevelWithIcon">
            <input
              class="form-control mb-0"
              :type="confirmPasswordFieldType ? 'password' : 'text'"
              v-model="confirmPassword"
              :style="
                activeConfirm
                  ? 'outline: none; border: 1px solid red; text-transform: none !important'
                  : 'text-transform: none !important'
              "
              placeholder="Confirme a senha..."
            />
            <i
              :class="
                confirmPasswordFieldType ? 'fas fa-eye' : 'fas fa-eye-slash'
              "
              @click="confirmPasswordFieldType = !confirmPasswordFieldType"
            ></i>
            <span
              v-if="activeConfirm"
              style="color: red; font-size: 0.7rem; margin-top: 2px"
            >
              {{ errorMensageConfirm }}
            </span>
          </div>
        </div>

        <div class="box-buttons">
          <button class="button-cancel" @click="resetModal">Cancelar</button>
          <button
            :class="
              this.password === '' || this.confirmPassword === ''
                ? 'button-renew-disabled'
                : 'button-renew'
            "
            :disabled="
              this.password === '' || this.confirmPassword === '' ? true : false
            "
            @click="renewPassword()"
          >
            Salvar
          </button>
        </div>
      </b-col>
    </div>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: ['idUsuario'],
  data() {
    return {
      tooltipText: `Sua senha deve conter minimamente 8 caracteres alfanuméricos, letras maiúsculas e minúsculas, números e símbolos. Exemplo: AtHErI10@`,
      passwordFieldType: true,
      confirmPasswordFieldType: true,
      password: '',
      confirmPassword: '',
      active: false,
      activeConfirm: false,
      errorMensageConfirm: 'Senhas diferentes!',
      errorMensage: 'Digite uma senha!!',
      isRule: false,
      rule1: false,
      rule2: false,
      rule3: false,
      rule4: false
    }
  },
  mounted() {
    this.$bvModal.show('modal-renew')
  },
  watch: {
    confirmPassword(newValue) {
      if (newValue === this.password) {
        this.activeConfirm = false
      } else {
        this.activeConfirm = true
      }
    },
    password(value) {
      this.isRule = true
      const regex = /[^a-zA-Z0-9\s]/
      const regxUpCase = /[A-Z]/
      const regxDigito = /\d/
      if (value === '' || value === null) {
        this.active = true
        this.errorMensage = 'Digite uma senha!'
      } else {
        this.active = false
      }
      if (value.length >= 8) {
        this.rule1 = true
      } else {
        this.rule1 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regex.test(value)) {
        this.rule2 = true
        this.active = false
      } else {
        this.rule2 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regxUpCase.test(value)) {
        this.rule3 = true
      } else {
        this.rule3 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regxDigito.test(value)) {
        this.rule4 = true
      } else {
        this.rule4 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (
        regxDigito.test(value) &&
        regxUpCase.test(value) &&
        regex.test(value) &&
        value !== '' &&
        value.length >= 8
      ) {
        this.isRule = false
      }
    }
  },
  methods: {
    ...mapActions({
      renewPasswordAction: 'User/renewPasswordAction'
    }),
    activeOrietetion() {
      const regex = /[^a-zA-Z0-9\s]/
      const regxUpCase = /[A-Z]/
      const regxDigito = /\d/
      if (
        regxDigito.test(this.password) &&
        regxUpCase.test(this.password) &&
        regex.test(this.password) &&
        this.password !== '' &&
        this.password !== null &&
        this.password.length >= 8
      ) {
        this.isRule = false
      } else {
        this.isRule = true
      }
    },
    openTooltip() {
      this.$root.$emit('bv::show::tooltip', 'tooltip')
    },
    closeTooltip() {
      this.$root.$emit('bv::hide::tooltip', 'tooltip')
    },
    async renewPassword() {
      if (this.active) {
        this.active = true
        this.$toast.error('Por favor verifique o campo de senha!')
      } else {
        if (this.password === this.confirmPassword) {
          this.renewPasswordAction({
            idUsuario: this.idUsuario,
            senha: this.password
          })
          this.$router.push({ name: 'pages.calendar' })
        } else {
          this.activeConfirm = true
        }
      }
    },
    resetModal() {
      this.$bvModal.hide('modal-renew')
      this.$emit('closeModal')
    }
  }
}
</script>
<style scoped>
.box-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.button-renew {
  padding: 6px;
  background-color: #089bab;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
.button-renew-disabled {
  padding: 6px;
  background-color: rgb(191, 188, 188);
  border: none;
  border-radius: 8px;
  color: white;
}

.button-cancel {
  padding: 6px;
  background-color: rgb(224, 51, 51);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
.label-password {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tooltip {
  opacity: 1 !important;
}

.sameLevelWithIcon {
  display: grid;
  grid-template-columns: 6.5fr 0.5fr;
  gap: 2%;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
</style>
