<template>
  <b-modal
    id="add-file"
    title="Adicionar arquivo"
    title-class="text-light"
    header-bg-variant="primary"
    cancel-title="Cancelar"
    cancel-variant="danger"
    ok-title="Salvar"
    no-close-on-esc
    no-close-on-backdrop
    @ok="addFile"
    @close="resetModal"
    @cancel="resetModal"
  >
    <input
      @change="selectFile"
      type="file"
      id="inputFile"
      accept=".doc, .docx, .pdf, .jpg, .png, .jpeg"
    />
    <div class="inputStyled">
      <b-form-input
        v-model="fileToAddName"
        placeholder="Selecione um arquivo"
        readonly
      />
      <b-button variant="primary" @click="triggerInputFile">Buscar</b-button>
    </div>
    <b-form-textarea
      v-model="fileToAddDescription"
      class="lineHeight"
      placeholder="Adicione uma descrição do arquivo"
    ></b-form-textarea>
  </b-modal>
</template>

<script>
export default {
  name: 'AddFileModal',
  data() {
    return {
      fileToAdd: '',
      fileToAddName: '',
      fileToAddDescription: '',
      closeSuccess: true
    }
  },
  mounted() {
    this.$bvModal.show('add-file')
  },
  methods: {
    selectFile(event) {
      this.fileToAdd = event.target.files[0]
      this.fileToAddName = this.fileToAdd.name
    },
    triggerInputFile() {
      document.getElementById('inputFile').click()
    },
    addFile(event) {
      event.preventDefault()
      if (!this.fileToAdd)
        return this.$toast.error(
          'É necessario escolher um arquivo ou imagem!',
          { position: 'top-right' }
        )
      if (this.fileToAddDescription.length >= 5) {
        const file = {
          file: this.fileToAdd,
          tipoArquivoImagem:
            this.fileToAdd.type === 'image/jpeg' ||
            this.fileToAdd.type === 'image/png'
              ? 'image'
              : this.fileToAdd.type === 'application/pdf'
              ? 'pdf'
              : 'doc',
          descAwsArquivoImagem: this.fileToAdd.name,
          descArquivoImagem: this.fileToAddDescription
        }
        this.$emit('addFile', file)
        this.resetModal()
      } else {
        return this.$toast.error(
          'É necessario informar uma descrição de no minimo 5 caracteres!',
          { position: 'top-right' }
        )
      }
    },
    resetModal() {
      this.fileToAdd = ''
      this.fileToAddDescription = ''
      this.fileToAddName = ''
      this.$bvModal.hide('addFile')
      this.$emit('closeModal', 'add-file')
    }
  }
}
</script>

<style scoped>
#inputFile {
  display: none;
}

.lineHeight {
  line-height: 1.5 !important;
}

.inputStyled {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5px;
  margin-bottom: 20px;
}
</style>
