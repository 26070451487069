/* eslint-disable no-unused-vars */
import API from '../../services/index'

export default {
  async getPatientAction(context, idPatient) {
    try {
      const apiResponse = await API.get(
        `/api/Paciente/ObtemItem/${idPatient}`,
        this.authToken
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },
  async addPatientAction(context, payload) {
    const {
      nome,
      nomeSocial,
      dtNascimento,
      cpf,
      identidade,
      cns,
      idPaisNacionalidade,
      idMunicipioNascimento,
      passaporte,
      telCelular,
      telResidencial,
      status,
      nomeMae,
      nomePai,
      email,
      idTipoLogradouro,
      cep,
      logradouro,
      numero,
      complementoEndereco,
      bairro,
      idEstado,
      idCidade,
      healthPlan,
      numberPlan,
      idSituacao,
      idSexo,
      idUsuario,
      convenioPaciente,
      idEstadoCivil,
      religiao,
      idClinica,
      descArquivoImagem,
      file,
      fileType,
      fileName,
      bodyFormData,
      fichaPaciente,
      profissaoOutros,
      idCbo,
      descCbo
    } = payload

    const numNumberAdress = parseInt(numero)
    const authToken = `Bearer ${window.$cookies.get('token')}`

    convenioPaciente.forEach(async (convenio) => {
      if (convenio.cartaoPaciente) {
        convenio.cartaoPaciente = convenio.cartaoPaciente?.map((cartao) => {
          const { isNew, ...cartaoRequest } = cartao
          return cartaoRequest
        })
      }
    })

    const patient = await API.post(
      '/api/Paciente/Adicionar',
      {
        object: {
          nome,
          nomeSocial,
          idTipoLogradouro,
          logradouro,
          numero: numNumberAdress || '',
          complementoEndereco,
          bairro,
          idCidade,
          idEstado,
          cep,
          telCelular,
          telResidencial,
          idSexo,
          dtNascimento,
          passaporte,
          email,
          cpf,
          cns,
          identidade,
          nomeMae,
          nomePai,
          idSituacao,
          idUsuario,
          idPaisNacionalidade,
          idMunicipioNascimento,
          convenioPaciente,
          idEstadoCivil,
          religiao,
          idClinica,
          descArquivoImagem,
          fichaPaciente,
          profissaoOutros,
          idCbo,
          descCbo
        }
      },
      {
        headers: { Authorization: authToken }
      }
    )
    if (file) {
      API.post(
        `api/Paciente/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&id_paciente=${patient.data.identificacao}`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${window.$cookies.get('token')}`
          }
        }
      )
    }

    context.commit('addPatient', patient)

    return patient
  },
  async editPatientAction(context, payload) {
    const {
      id,
      nome,
      nomeSocial,
      dtNascimento,
      cpf,
      identidade,
      cns,
      idPaisNacionalidade,
      idMunicipioNascimento,
      passaporte,
      telCelular,
      telResidencial,
      status,
      nomeMae,
      nomePai,
      email,
      idTipoLogradouro,
      cep,
      logradouro,
      numero,
      complementoEndereco,
      bairro,
      idEstado,
      idCidade,
      healthPlan,
      numberPlan,
      idSituacao,
      idSexo,
      idUsuario,
      convenioPaciente,
      idEstadoCivil,
      religiao,
      descArquivoImagem,
      file,
      fileType,
      fileName,
      bodyFormData,
      fichaPaciente,
      profissaoOutros,
      idCbo,
      descCbo
    } = payload

    const numNumberAdress = parseInt(numero)
    const authToken = `Bearer ${window.$cookies.get('token')}`

    // Atualizar paciente
    const patient = await API.put(
      '/api/Paciente/Editar',
      {
        object: {
          id,
          nome,
          nomeSocial,
          idTipoLogradouro,
          logradouro,
          numero: numNumberAdress,
          complementoEndereco,
          bairro,
          idCidade,
          idEstado,
          cep,
          telCelular,
          telResidencial,
          idSexo,
          dtNascimento,
          passaporte,
          email,
          cpf,
          cns,
          identidade,
          nomeMae,
          nomePai,
          idSituacao,
          idUsuario,
          convenioPaciente,
          idMunicipioNascimento,
          idPaisNacionalidade,
          idEstadoCivil,
          religiao,
          descArquivoImagem,
          fichaPaciente,
          profissaoOutros,
          idCbo,
          descCbo
        }
      },
      {
        headers: { Authorization: authToken }
      }
    )
    const newConvenios = convenioPaciente.filter((convenio) => {
      return convenio.isNew
    })

    newConvenios.map((item) => {
      const { isNew, ...convenio } = item
      return convenio
    })

    newConvenios.forEach(async (convenio) => {
      await API.post(
        '/api/PacienteConvenio/Adicionar',
        {
          object: {
            idPaciente: id,
            ...convenio
          }
        },
        {
          headers: { Authorization: authToken }
        }
      )
    })
    // Upload de arquivo se houver
    if (file) {
      await API.post(
        `api/Paciente/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&id_paciente=${id}`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${window.$cookies.get('token')}`
          }
        }
      )
    }

    context.commit('editPatient', patient)

    return patient.data.message
  },

  async deletePatientAction(context, patient) {
    const response = await API.delete('/api/Paciente/Remover', {
      data: {
        object: patient
      }
    })

    return { message: response.data.message, result: response.data.result }
  }
}
