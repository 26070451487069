const Video = Twilio.Video

const createLocalTracks = async () => {
  const tracks = await Video.createLocalTracks({
    video: { framerate: 24, width: 1280 },
    audio: true
  })

  return tracks
}

export default createLocalTracks
