const ASO_MODULE_ID = 14

const mutations = {
  loadASOs(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ASO_MODULE_ID
    )

    module.data = payload
  },

  addASO(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ASO_MODULE_ID
    )

    module.data.push({ ...payload, isNew: true })
  },

  removeASO(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ASO_MODULE_ID
    )
    let index
    if (payload.id) {
      index = module.data.findIndex((form) => form.id === payload.id)
    } else {
      index = module.data.findIndex(
        (form) => form.dtInclusao === payload.dtInclusao
      )
    }

    module.data.splice(index, 1, { ...payload, deleted: true })
  },

  updateASOField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ASO_MODULE_ID
    )

    const forwardingIndex = module.data.findIndex(
      (forwarding) => forwarding.id === payload.id
    )

    module.data.splice(forwardingIndex, 1, { ...payload, modified: true })
  }
}

export default mutations
