const helpers = {
  placeCaretAtEnd(el) {
    el.focus()
    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange()
      range.selectNodeContents(el)
      range.collapse(false)
      const sel = window.getSelection()
      // TODO: Try to remove this line
      // sel.removeAllRanges()
      sel.addRange(range)
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange()
      textRange.moveToElementText(el)
      textRange.collapse(false)
      textRange.select()
    }
  },
  toggleExpandModule() {
    this.expanded = !this.expanded
  },

  swapUp(module) {
    this.$store.dispatch({ type: 'MedicalReports/swapUp', payload: module })
  },

  swapDown(module) {
    this.$store.dispatch({ type: 'MedicalReports/swapDown', payload: module })
  },

  toggleExpanded(module) {
    this.$store.dispatch({
      type: 'MedicalReports/toggleExpandModule',
      payload: module
    })
  },
  toggleActive(module) {
    this.$store.dispatch({
      type: 'MedicalReports/toggleActiveModule',
      payload: module
    })
  },

  changeModuleBackground(module, event) {
    const color = event.target.value

    this.$store.dispatch({
      type: 'MedicalReports/changeModuleBackground',
      payload: { ...module, corHexadecimal: color }
    })
  },

  changeModuleName(module, name) {
    this.$store.dispatch({
      type: 'MedicalReports/changeModuleName',
      payload: {
        ...module,
        descMeuModulo: name
      }
    })
  },

  removeFromAll(moduleId, id) {
    this.$store.commit({
      type: 'MedicalReports/removeFromFavorites',
      payload: {
        moduleId,
        id
      }
    })

    this.$store.commit({
      type: 'MedicalReports/removeFromQuickAccess',
      payload: {
        moduleId,
        id
      }
    })
  }
}

export default helpers
