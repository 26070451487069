import API from '../../../../services/index'
const actions = {
  async addVitalSignToHandBook({ commit }, { payload }) {
    try {
      await API.post('/api/ProntuarioSinalVital/Adicionar', { object: payload })
    } catch (error) {}
  },

  async editVitalSignPreferences({ commit }, { payload }) {
    try {
      await API.put('/api/FavoritoSinalVital/Editar', { object: payload })
    } catch (error) {}
  },

  async editVitalSignToHandBook({ commit }, { payload }) {
    try {
      await API.put('/api/ProntuarioSinalVital/Editar', { object: payload })
    } catch (error) {}
  }
}

export default actions
