<template>
  <div v-if="results.length > 0" class="search-result__container">
    <div class="boxResults">
      <div
        class="table-header"
        :style="`${
          codName
            ? 'grid-template-columns: 0.5fr 2fr 1fr 1fr;'
            : 'grid-template-columns:  2fr 1fr 1fr;'
        }`"
      >
        <p v-if="codName">{{ codName }}</p>
        <p class="table-header__description">Descrição</p>
        <p>Acesso Rápido</p>
        <p>Favorito</p>
      </div>
      <div
        class="search-result__results"
        v-for="result in this.items"
        :key="result[idProperty]"
        :style="`${
          codName
            ? 'grid-template-columns: 0.5fr 2fr 1fr 1fr;'
            : 'grid-template-columns:  2fr 1fr 1fr;'
        }`"
      >
        <p v-if="codName">{{ result[idProperty] }}</p>
        <div
          class="results__description"
          :style="`cursor: ${selectOnDescription ? 'pointer' : 'auto'}`"
          @click="() => selectOnDescription && onResultSelectedFixed(result)"
        >
          <p>
            {{ result[titleProperty] }}
          </p>
          <ul v-if="showDegreeList" class="results__degree-list">
            <li @click.stop="onResultSelectedFixed({ grau: 0, ...result })">
              Leve
            </li>
            <li @click.stop="onResultSelectedFixed({ grau: 1, ...result })">
              Moderada
            </li>
            <li @click.stop="onResultSelectedFixed({ grau: 2, ...result })">
              Severa
            </li>
          </ul>
        </div>
        <div
          class="results__checkbox"
          @click.prevent="(event) => onQuickAccessChosen(result)"
        >
          <b-form-radio
            v-model="result.location"
            :name="`favorite_location--${result[idProperty]}`"
            value="quickAccess"
          ></b-form-radio>
        </div>
        <div
          class="results__checkbox"
          @click.prevent="(event) => onFavoriteChosen(result)"
        >
          <b-form-radio
            value="favorite"
            v-model="result.location"
            :name="`favorite_location--${result[idProperty]}`"
          ></b-form-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    results: Array,
    idProperty: String,
    userId: Number,
    titleProperty: String,
    showDegreeList: {
      type: Boolean,
      default: false
    },
    codName: {
      type: String,
      required: false
    },
    selectOnDescription: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      items: []
    }
  },
  watch: {
    results(newResults) {
      this.items = newResults.map((result) => {
        result.location =
          result.acessoRapido === 0
            ? 'quickAccess'
            : result.favorito === 0
            ? 'favorite'
            : ''
        return result
      })
    }
  },
  methods: {
    onFavoriteChosen(result) {
      this.$emit('on-favorite-chosen', result)
      this.updatePreferences()
    },
    onQuickAccessChosen(result) {
      this.$emit('on-quick-access-chosen', result)
      this.updatePreferences()
    },
    onResultSelectedFixed(result) {
      this.$emit('on-result-selected', result)
      this.updatePreferences()
    },
    updatePreferences() {
      this.$store
        .dispatch({
          type: 'MedicalReports/loadAllModules',
          payload: this.userId
        })
        .then(async () => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.results {
  &__description {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    text-align: center;
  }

  &__degree-list {
    display: none;
    flex: 1;
    padding: 0;

    justify-content: space-evenly;
    align-items: center;
    margin: 0;

    li {
      cursor: pointer;
      margin: 0 4px;
      width: max-content;
      border-radius: 10px;
      text-align: center;
      padding: 8px;
      color: #000 !important;
    }

    li:hover {
      transform: scale(1.1);
    }

    li:hover {
      z-index: 10;
    }

    & > li:nth-child(1) {
      background-color: #abebc6;
    }
    & > li:nth-child(2) {
      background-color: #f9e79f;
    }
    & > li:nth-child(3) {
      background-color: #f5b7b1;
    }
  }
}

.boxResults {
  width: 100%;
  overflow-y: scroll;
  max-height: 300px;
}

.search-result {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: absolute;
    z-index: 3;
    background-color: #fff;
    right: 0;
    border: 1px solid #000;
    border-radius: 15px;
    width: 200%;
  }

  &__results {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    padding: 8px;
    &:hover .results__degree-list {
      display: flex;
    }

    p {
      margin: 0;
      color: #000;
    }
  }
}

.linkAddAllergy {
  width: 100%;
  border-top: 1px solid grey;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkAddAllergy button {
  padding: 6px 12px;
  border: none;
  background-color: transparent;
  color: green;
  cursor: pointer;
}

.table-header {
  background-color: #089bab;
  color: #fff;
  font-size: 13px;

  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr;
  align-items: center;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 3;

  p {
    justify-self: center;
  }

  p {
    margin: 0;
  }

  &__description {
    justify-self: flex-start !important;
  }
}
</style>
