<template>
  <div id="box-laboratory">
    <nav class="navbar-tests">
      <div class="navbar-test-title">Exame</div>
      <div class="box-text">
        <div class="box-text-span">
          <div class="navbar-test-span-data">Data</div>
          <div class="navbar-test-span-result">Resultado</div>
        </div>
        <div class="navbar-test-span-laudo">Laudo</div>
      </div>

      <div class="box-text">
        <div class="box-text-span">
          <div class="navbar-test-span-data">Data</div>
          <div class="navbar-test-span-result">Resultado</div>
        </div>
        <div class="navbar-test-span-laudo">Laudo</div>
      </div>

      <div class="box-text">
        <div class="box-text-span">
          <div class="navbar-test-span-data">Data</div>
          <div class="navbar-test-span-result">Resultado</div>
        </div>
        <div class="navbar-test-span-laudo">Laudo</div>
      </div>
      <div class="navbar-test-delete">Intativar</div>
    </nav>
    <div class="box-tests">
      <div
        :class="index % 2 !== 0 ? 'test-card-imp' : 'test-card'"
        v-for="(exam, index) in listExams"
        :key="index"
      >
        <div class="test-card-exam">
          <span :title="exam.descExamePreNatal">
            {{ exam.descExamePreNatal }}</span
          >
        </div>
        <div class="test-card-box">
          <div class="test-card-input">
            <input
              type="date"
              :value="formatDateForInput(exam.listaResultado[0].dataResultado)"
              @input="handleDate($event, index, 0)"
            />
            <input
              type="text"
              :value="exam.listaResultado[0].resultado"
              @input="handleResult($event, index, 0)"
            />
          </div>
          <button
            v-if="
              exam.listaResultado[0].descArquivo.nomeArquivo === '' &&
              exam.listaResultado[0].descArquivo.urlArquivo === null
            "
            class="test-card-buttons"
            @click="openModal(index, 0)"
          >
            <i class="fas fa-upload fa-2x" style="color: #089bab"></i>
          </button>
          <button
            v-else
            class="test-card-buttons"
            @click="openModalFile(exam.listaResultado[0].descArquivo)"
          >
            <img src="../laboratoryTests/assets/pdf.png" />
          </button>
        </div>
        <div class="test-card-box">
          <div class="test-card-input">
            <input
              type="date"
              :value="formatDateForInput(exam.listaResultado[1].dataResultado)"
              @input="handleDate($event, index, 1)"
            />
            <input
              type="text"
              :value="exam.listaResultado[1].resultado"
              @input="handleResult($event, index, 1)"
            />
          </div>
          <button
            v-if="
              exam.listaResultado[1].descArquivo.nomeArquivo === '' &&
              exam.listaResultado[1].descArquivo.urlArquivo === null
            "
            class="test-card-buttons"
            @click="openModal(index, 1)"
          >
            <i class="fas fa-upload fa-2x" style="color: #089bab"></i>
          </button>
          <button
            v-else
            class="test-card-buttons"
            @click="openModalFile(exam.listaResultado[1].descArquivo)"
          >
            <img src="../laboratoryTests/assets/pdf.png" />
          </button>
        </div>
        <div class="test-card-box">
          <div class="test-card-input">
            <input
              type="date"
              :value="formatDateForInput(exam.listaResultado[2].dataResultado)"
              @input="handleDate($event, index, 2)"
            />
            <input
              type="text"
              :value="exam.listaResultado[2].resultado"
              @input="handleResult($event, index, 2)"
            />
          </div>
          <button
            v-if="
              exam.listaResultado[2].descArquivo.nomeArquivo === '' &&
              exam.listaResultado[2].descArquivo.urlArquivo === null
            "
            class="test-card-buttons"
            @click="openModal(index, 2)"
          >
            <i class="fas fa-upload fa-2x" style="color: #089bab"></i>
          </button>
          <button
            v-else
            class="test-card-buttons"
            @click="openModalFile(exam.listaResultado[2].descArquivo)"
          >
            <img src="../laboratoryTests/assets/pdf.png" />
          </button>
        </div>
        <button class="test-card-delete" @click="deletedExamLaboratory(index)">
          {{ exam.id ? 'inativar' : 'remover' }}
        </button>
      </div>
    </div>
    <b-button
      style="margin-top: 20px"
      @click="OpenModalNewExam()"
      variant="primary"
      >Novo exame</b-button
    >
    <AddFileModal
      v-if="showModal"
      @add-file="addFile"
      @closeModal="closeModal()"
      :indexExamTuss="indexExamTuss"
      :indexItem="indexItem"
    />

    <showPdfVue
      v-if="showModalFile"
      @closeModalFile="closeModalFile"
      :pdf="file"
    />
    <AddNewExam
      v-if="showModalNewExam"
      @closeModal="closeModalNewExam"
      :examList="listaServices"
      :idPreNatal="idPreNatal"
      :idProntuario="idProntuario"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AddFileModal from './components/AddFileModal.vue'
import showPdfVue from './components/showPdf.vue'
import AddNewExam from './components/AddNewExam.vue'
export default {
  props: ['idPreNatal', 'idProntuario'],
  components: { AddFileModal, showPdfVue, AddNewExam },
  data() {
    return {
      showModal: false,
      showModalFile: false,
      showModalNewExam: false,
      indexExamTuss: null,
      indexItem: null,
      file: null,
      listaServices: [
        {
          name: 'Exame muito doloroso',
          codigo: 1
        },
        {
          name: 'Exame muito bom',
          codigo: 2
        },
        {
          name: 'Exame muito ruim',
          codigo: 3
        },
        {
          name: 'Teste de calvice',
          codigo: 4
        },
        {
          name: 'Ambidestro teste',
          codigo: 5
        },
        {
          name: 'Exame muito horrivel',
          codigo: 6
        },
        {
          name: 'Exame muito pessimo',
          codigo: 7
        }
      ]
    }
  },
  methods: {
    ...mapGetters({
      getListExamsLaboratorys: 'MedicalReports/getListExamsLaboratorys'
    }),
    ...mapActions({
      getExamsLaboratorysAction: 'MedicalReports/getExamsLaboratorysAction',
      addFileExamLaboratorysAction:
        'MedicalReports/addFileExamLaboratorysAction',
      setResultExamLaboratoryAction:
        'MedicalReports/setResultExamLaboratoryAction',
      setDateExamLaboratoryAction: 'MedicalReports/setDateExamLaboratoryAction',
      saveAllExamLaboratorys: 'MedicalReports/saveAllExamLaboratorys',
      getListExamsLaboratoryAction:
        'MedicalReports/getListExamsLaboratoryAction',
      deleteExamLaboratoryAction: 'MedicalReports/deleteExamLaboratoryAction'
    }),
    addFile({ pdfDataUrl, index, indexExamTuss, selectedFile, namedFile }) {
      this.addFileExamLaboratorysAction({
        pdfDataUrl: pdfDataUrl,
        index: index,
        indexExamTuss: indexExamTuss,
        selectedFile: selectedFile,
        namedFile: namedFile
      })
      this.showModal = false
    },
    deletedExamLaboratory(index) {
      this.deleteExamLaboratoryAction(index)
    },
    handleDate(event, index, indexTuss) {
      const text = event.target.value
      this.setDateExamLaboratoryAction({
        index: index,
        indexTuss: indexTuss,
        date: text
      })
    },
    handleResult(event, index, indexTuss) {
      const text = event.target.value
      this.setResultExamLaboratoryAction({
        index: index,
        indexTuss: indexTuss,
        result: text
      })
    },
    async saveExamsLaboratorys() {
      await this.saveAllExamLaboratorys()
    },
    closeModalFile() {
      this.showModalFile = false
    },
    closeModal() {
      this.showModal = false
    },
    closeModalNewExam() {
      this.showModalNewExam = false
    },
    OpenModalNewExam() {
      this.showModalNewExam = true
    },
    openModalFile(file) {
      this.file = file
      this.showModalFile = true
    },
    openModal(indexItem, indexExamTuss) {
      this.indexExamTuss = indexExamTuss
      this.indexItem = indexItem
      this.showModal = true
    },
    // Data dos inputs
    formatDateForInput(dateString) {
      if (dateString === '') {
        return ''
      } else {
        // Converte a string de data para um objeto Date
        const dateObject = new Date(dateString)
        // Formata a data no formato 'YYYY-MM-DD'
        const formattedDate = dateObject.toISOString().split('T')[0]
        return formattedDate
      }
    }
  },

  computed: {
    listExams() {
      return this.getListExamsLaboratorys()
    }
  },

  async mounted() {
    await this.getExamsLaboratorysAction({
      idPreNatal: this.idPreNatal,
      idProntuario: this.idProntuario
    })
  }
}
</script>
<style scoped>
#box-laboratory {
  display: flex;
  height: 400px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.navbar-tests {
  width: 100%;
  display: flex;
  padding: 2px 20px 2px 2px;
  align-items: center;
  justify-content: space-between;
  background-color: #089bab;
  gap: 10px;
}
.navbar-test-title {
  width: 200px;
  padding-left: 6px;
  font-weight: 600;
  color: #ffffff;
}

.box-text {
  display: flex;
  align-items: center;
  gap: 20px;
}

.box-text-span {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-test-span-laudo {
  width: 50px;
  font-weight: 600;
  color: white;
  padding: 8px;
}

.navbar-test-span-data {
  width: 120px;
  font-weight: 600;
  color: white;
  padding: 8px;
}

.navbar-test-span-result {
  width: 120px;
  font-weight: 600;
  color: white;
  padding: 8px;
}

.navbar-test-delete {
  font-weight: 600;
  color: white;
  padding: 8px;
}

.box-tests {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  height: 360px;
}

.test-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 10px;
  justify-content: space-between;
  gap: 10px;
}

.test-card-imp {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 10px;
  justify-content: space-between;
  background-color: #daf0f3;
  gap: 10px;
}

.test-card-exam {
  width: 210px;
  padding-left: 6px;
  font-weight: 600;
  color: #089bab;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.test-card-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.test-card-input {
  display: flex;
  align-items: center;
  gap: 20px;
}

.test-card-input input {
  font-size: 0.7rem;
  width: 120px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

.test-card-buttons {
  width: 50px;
  border: none;
  background: transparent;
  border-radius: 6px;
}

.test-card-delete {
  color: white;
  padding: 7px;
  border: none;
  background: rgb(209, 47, 47);
  border-radius: 6px;
}
</style>
