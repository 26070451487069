const mutations = {
  addMedicationHistory(state, { payload: { id, ...medicationHistoryData } }) {
    const module = state.modules.find((module) => module.id === id)
    const date = new Date().toLocaleString('pt-BR').split(' ')[0]
    const dataId = module.tableData.length + 1
    module.tableData.push({
      ...medicationHistoryData,
      date,
      active: true,
      action: 'Remover',
      activePrinciple: '',
      observation: '',
      posology: '',
      continuousUse: false,
      id: dataId
    })
  },

  removeMedicationHistory(state, { payload: { moduleId, dataId } }) {
    const module = state.modules.find((module) => module.id === moduleId)
    const tableDataIndex = module.tableData.findIndex(
      (data) => data.id === dataId
    )
    module.tableData.splice(tableDataIndex, 1)
  },

  activateMedicationHistory(state, { payload: { moduleId, dataId } }) {
    const module = state.modules.find((module) => module.id === moduleId)
    const tableDataIndex = module.tableData.findIndex(
      (data) => data.id === dataId
    )
    const newMedicationHistory = { ...module.tableData[tableDataIndex] }
    newMedicationHistory.active = true
    module.tableData.splice(tableDataIndex, 1, newMedicationHistory)
  },
  inactivateMedicationHistory(state, { payload: { moduleId, dataId } }) {
    const module = state.modules.find((module) => module.id === moduleId)
    const tableDataIndex = module.tableData.findIndex(
      (data) => data.id === dataId
    )
    const newMedicationHistory = { ...module.tableData[tableDataIndex] }
    newMedicationHistory.active = false
    module.tableData.splice(tableDataIndex, 1, newMedicationHistory)
  }
}

export default mutations
