const getters = {
  getPersonalAndFamiliarHistoryByIdPatient: (state) => (id) => {
    const personalAndFamiliarHistory = state.localPersonalHistoryState.find(
      (personalAndFamiliarHistory) =>
        personalAndFamiliarHistory.idPaciente === id
    )

    return personalAndFamiliarHistory
  }
}

export default getters
