import API from '../../services/index'

export default {
  async addHealthInsuranceAction(context, payload) {
    const {
      razaoSocial,
      cnpj,
      dtCadastro,
      dtFaturamento,
      mascaraCartao,
      responsavel,
      idTipoLogradouro,
      cep,
      logradouro,
      numero,
      complementoEndereco,
      bairro,
      idEstado,
      idCidade,
      telCelular,
      telComercial,
      email,
      idUsuario,
      ativo
    } = payload

    const numNumberAdress = parseInt(numero)
    const numCep = parseInt(cep)

    const healthInsurance = await API.post(
      '/api/Convenio/Adicionar',
      {
        object: {
          razaoSocial,
          cnpj,
          dtCadastro,
          dtFaturamento,
          mascaraCartao,
          responsavel,
          telCelular,
          telComercial,
          email,
          idTipoLogradouro,
          cep: numCep,
          logradouro,
          numero: numNumberAdress,
          complementoEndereco,
          bairro,
          idEstado,
          idCidade,
          linkImagem: '',
          dtInclusao: '',
          idUsuarioInclusao: idUsuario,
          idUsuario: idUsuario,
          ativo
        }
      },
      {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      }
    )
    context.commit('addHealthInsurance', healthInsurance)
  },
  async editHealthInsuranceAction(context, payload) {
    const {
      id,
      razaoSocial,
      cnpj,
      dtCadastro,
      dtFaturamento,
      mascaraCartao,
      responsavel,
      idTipoLogradouro,
      cep,
      logradouro,
      numero,
      complementoEndereco,
      bairro,
      idEstado,
      idCidade,
      telCelular,
      telComercial,
      email,
      idUsuario,
      ativo
    } = payload
    const numNumberAdress = parseInt(numero)
    const numCep = parseInt(cep)

    const healthInsurance = await API.put(
      '/api/Convenio/Editar',
      {
        object: {
          id,
          razaoSocial,
          cnpj,
          dtCadastro,
          dtFaturamento,
          mascaraCartao,
          responsavel,
          idTipoLogradouro,
          cep: numCep,
          logradouro,
          numero: numNumberAdress,
          complementoEndereco,
          bairro,
          idEstado,
          idCidade,
          telCelular,
          telComercial,
          email,
          linkImagem: '',
          dtInclusao: '',
          idUsuarioInclusao: idUsuario,
          idUsuario: idUsuario,
          ativo
        }
      },
      {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      }
    )
    context.commit('editHealthInsurance', healthInsurance)
  },

  async getHealthInsurancesByUser(context, idUser) {
    const response = await API.get(
      `/api/UsuarioConvenio/ObtemItemConvenioPorUsuario/${idUser}`
    )

    return response.data.object
  }
}
