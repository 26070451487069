export function sistolica(data) {
  const sistolica = {
    options: createDefaultOptionsValues(data.referencia, 'pressure'),
    initialValue: data.referencia.toString()
  }

  return sistolica
}

export function diastolica(data) {
  const diastolica = {
    options: createDefaultOptionsValues(data.referencia, 'pressure'),
    initialValue: data.referencia.toString()
  }

  return diastolica
}

export function peso(data) {
  const peso = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }

  return peso
}

export function altura(data) {
  const altura = {
    options: createDefaultOptionsValues(data.referencia, 'altura'),
    initialValue: data.referencia.toString()
  }

  return altura
}

export function pulso(data) {
  const pulso = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }

  return pulso
}

export function temperatura(data) {
  const temperatura = {
    options: createDefaultOptionsValues(data.referencia, 'temperatura'),
    initialValue: data.referencia.toString()
  }

  return temperatura
}

export function panturilha(data) {
  const panturrilha = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }

  return panturrilha
}

export function braco(data) {
  const braco = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }

  return braco
}

export function quadril(data) {
  const quadril = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }

  return quadril
}

export function abdominal(data) {
  const abdominal = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }

  return abdominal
}

export function saturacao(data) {
  const saturacao = {
    options: createDefaultOptionsValues(data.referencia, 'other'),
    initialValue: data.referencia.toString()
  }
  saturacao.options = saturacao.options.filter((item) => item <= 100)
  return saturacao
}

function createDefaultOptionsValues(reference, field) {
  const growValues = {
    pressure: 2,
    altura: 0.01,
    temperatura: 0.1,
    other: 1
  }

  const defaultOptions = []
  const maximum = Math.ceil(reference * 2)
  const minimum = Math.ceil(reference / 2)
  let current = minimum

  while (current < maximum) {
    const valueToAdd =
      field === 'altura'
        ? current.toFixed(2).toString(10)
        : field === 'temperatura'
        ? current.toFixed(1).toString(10)
        : current.toString(10)

    defaultOptions.push(valueToAdd)
    current += growValues[field]
  }

  const optionsAlreadyHaveReference = defaultOptions.find((element) => {
    return element === reference.toString(10)
  })

  if (!optionsAlreadyHaveReference) {
    defaultOptions.push(reference.toString(10))

    defaultOptions.sort((a, b) => {
      return a - b
    })
  }

  return defaultOptions
}
