<template>
  <div class="table-container" @click="showRemoveDropDown = false">
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th style="background-color: #089bab" class="table-header">
            Data
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Exame
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Qtd.
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Valor
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Realizado
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Resultado
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Laudo
          </b-th>
          <b-th style="background-color: #089bab" class="table-header">
            Info
          </b-th>
          <b-th style="background-color: #089bab" class="table-header"></b-th>
          <b-th style="background-color: #089bab" class="table-header"></b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr
          :class="[
            'listItem',
            exam.idGrauAlteracao !== null
              ? `degree-${exam.idGrauAlteracao}`
              : 'degree-normal',
            exam.id === selectedExamId ? 'selected' : ''
          ]"
          @click="selectedExam(exam.id, index)"
          v-for="(exam, index) in examsList"
          :key="exam.id"
          :title="popoverMessage(exam)"
        >
          <b-td :class="exam.ativo === 1 ? 'line-throughed' : ''">
            {{ handleFormatLocalDateHour(exam.dtSolicitacao) }}
          </b-td>
          <b-td
            :class="['overflowText', exam.ativo === 1 ? 'line-throughed' : '']"
            :title="exam.codigoServico + ' - ' + exam.descServico"
          >
            {{ exam.descServico }}
          </b-td>
          <b-td style="position: relative">
            <b-form-input
              class="inputSmall"
              autocomplete="off"
              maxlength="2"
              @input="updateExamField(exam, 'qtdeSolicitacao', $event)"
              @focus="scrollableListToShow = index"
              @blur="hideScrollableList"
              :value="exam.qtdeSolicitacao"
            />

            <scrollable-list
              @handle-selection="
                updateExamField(exam, index, 'qtdeSolicitacao', $event)
              "
              v-if="scrollableListToShow === index"
              :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
            />
          </b-td>
          <b-td>
            <b-form-input
              type="number"
              @input="updateExamField(exam, index, 'valorServico', $event)"
              :value="exam.valorServico !== null ? exam.valorServico : 0"
            />
          </b-td>
          <b-td>
            <b-form-input
              class="inputFull"
              type="date"
              @change="updateExamField(exam, index, 'dtRealizacao', $event)"
              :value="exam.dtRealizacao ? exam.dtRealizacao : ''"
            />
          </b-td>
          <b-td>
            <b-form-input
              type="number"
              @click="$event.stopPropagation()"
              class="inputSmall focusable-resultado"
              @input="updateExamField(exam, index, 'resultado', $event)"
              :value="exam.resultado"
              @keydown="simulateTab"
              maxlength="5"
            />
          </b-td>
          <b-td>
            <i
              v-if="exam.descArquivo.length > 0"
              class="far fa-file-pdf fa-2x"
              style="color: red"
              @click.stop="
                $emit(
                  'preview-file',
                  exam.file || {
                    descArquivo: exam.descArquivo,
                    idProntuario: exam.idProntuario
                  }
                )
              "
            ></i>
            <i
              v-else
              @click="handlerShowAddFileModal(exam)"
              class="fas fa-upload fa-2x"
              style="color: #089bab"
            ></i>
          </b-td>
          <b-td>
            <i
              class="fas fa-file-alt fa-2x"
              style="color: #089bab"
              @click="handlerShowInfoModal(exam)"
            ></i>
          </b-td>
          <b-td @click="handleEmitShowGraph">
            <b-icon icon="graph-up" variant="primary" scale="2" />
          </b-td>
          <b-td>
            <div style="position: relative">
              <b-button
                @click.stop="
                  () =>
                    (exam.file || !!exam.descArquivo) &&
                    exam.idProntuario === idProntuario
                      ? (showRemoveDropDown = index)
                      : exam.ativo === 0 && exam.acao !== 'Remover'
                      ? $bvModal.show(`examsHistory_${exam.id}`)
                      : handleRemoveOrInactivate(exam)
                "
                :variant="
                  ['Remover', 'Inativar'].includes(exam.acao)
                    ? 'danger'
                    : 'primary'
                "
                >{{ exam.acao }}</b-button
              >

              <div
                v-if="
                  showRemoveDropDown === index &&
                  (exam.file || !!exam.descArquivo)
                "
                style="
                  position: absolute;
                  top: 100%;
                  z-index: 4;
                  background-color: #fff;
                  padding: 4px;
                "
              >
                <b-button
                  :variant="
                    ['Remover', 'Inativar'].includes(exam.acao)
                      ? 'danger'
                      : 'primary'
                  "
                  class="mb-2"
                  @click.stop="
                    exam.ativo === 0
                      ? $bvModal.show(`examsHistory_${exam.id}`)
                      : handleRemoveOrInactivate(exam)
                  "
                  >Exame</b-button
                >

                <b-button
                  v-if="
                    exam.file ||
                    (exam.descArquivo && exam.idProntuario === idProntuario)
                  "
                  variant="danger"
                  @click.stop="handleRemoveFile(exam)"
                >
                  Anexo
                </b-button>
              </div>
            </div>
          </b-td>
          <b-modal
            :id="`examsHistory_${exam.id}`"
            size="lg"
            title="Justificativa"
            title-class="text-light"
            header-bg-variant="primary"
            header-text-variant="text-light"
            label-for="justify-input"
            ok-title="Salvar"
          >
            <b-form-group label-for="justify-input">
              <ValidationProvider
                name="justify"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="justify-input"
                  maxlength="150"
                  v-model="justify"
                  :class="
                    errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                  "
                ></b-form-textarea>
                <div class="invalid-feedback" :v-if="justifyNotValid">
                  <span>A justificativa deve conter no mínimo 5 digitos!</span>
                </div>
              </ValidationProvider>
            </b-form-group>

            <p>{{ justify.length }}/150</p>
            <template #modal-footer="{ cancel }">
              <b-button size="sm" variant="danger" @click="cancel()">
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="handleJustifyObject(exam)"
              >
                Salvar
              </b-button>
            </template>
          </b-modal>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <AddFileModal
      v-if="showAddFileModal"
      @add-file="addFile"
      @closeModal="closeFileModal"
    />

    <InfoModal
      v-if="showInfoModal"
      :exam="examToAddInfo"
      @add-info="addInfo"
      @closeModal="closeInfoModal"
    />
  </div>
</template>

<script>
import {
  formatDate,
  formatLocalDateHour,
  removeTagsFromString
} from '@/Utils/formatters'
import ScrollableList from '../../../shared/ScrollableList.vue'
import AddFileModal from './AddFileModal.vue'
import InfoModal from './InfoModal.vue'

export default {
  name: 'ExamsList',
  props: ['idPatient', 'idDoctor', 'examsList', 'headerColor', 'idProntuario'],
  components: { AddFileModal, ScrollableList, InfoModal },

  data() {
    return {
      selectedExamId: null,
      scrollableListToShow: null,
      showAddFileModal: false,
      examToAddFile: null,
      showInfoModal: false,
      examToAddInfo: null,
      showRemoveDropDown: null,
      justify: ''
    }
  },
  computed: {
    justifyNotValid() {
      return this.justify.length < 5
    }
  },
  methods: {
    handleEmitShowGraph() {
      this.$emit('showGraph')
    },
    selectedExam(examId, index) {
      this.selectedExamId = examId
      this.$emit('update-chart', index)
    },
    popoverMessage(exam) {
      const alteracoes = [
        'Sem alterações',
        'Alteração leve',
        'Alteração moderada',
        'Alteração severa'
      ]
      const {
        nmProfissional,
        dtRealizacao,
        descLaudoInfo,
        idGrauAlteracao,
        justificativaInativacao
      } = exam

      const alteracao =
        idGrauAlteracao === null
          ? alteracoes[0]
          : alteracoes[idGrauAlteracao + 1]

      const data = formatDate(dtRealizacao)

      return `Solicitante: ${nmProfissional}
          \nData: ${data}
          ${alteracao ? `\nAlteração: ${alteracao}` : ''}

          ${
            descLaudoInfo
              ? `\nLaudo: ${removeTagsFromString(descLaudoInfo)}`
              : ''
          }
          ${
            justificativaInativacao
              ? `\nJustificativa: ${justificativaInativacao}`
              : ''
          }
        `
    },
    simulateTab(event) {
      if (event.keyCode === 13 || event.keyCode === 9) {
        event.preventDefault()
        const focusables = document.querySelectorAll(
          `input.focusable-resultado`
        )
        const currentFocusIndex = Array.from(focusables).findIndex(
          (focusable) => {
            return focusable.id === event.target.id
          }
        )
        focusables[currentFocusIndex + 1].focus()
      }
    },
    hideScrollableList() {
      setTimeout(() => {
        this.scrollableListToShow = null
      }, 200)
    },
    handleFormatLocalDateHour(date) {
      return formatLocalDateHour(date)
    },
    addFile({ file, attachToAllExams }) {
      this.showAddFileModal = false
      this.$emit('add-file', {
        file,
        attachToAllExams,
        examToAddFile: this.examToAddFile
      })
      this.examToAddFile = null
    },
    addInfo(content) {
      this.closeInfoModal()
      this.$emit('add-info', {
        content,
        examToAddInfo: this.examToAddInfo
      })
      this.examToAddInfo = null
    },
    handlerShowAddFileModal(exam) {
      this.examToAddFile = exam
      this.showAddFileModal = true
    },
    handlerShowInfoModal(exam) {
      this.examToAddInfo = exam
      this.showInfoModal = true
    },
    convertCommaToDot(value) {
      if (value.includes(',')) {
        value = value.replaceAll(',', '.')
      }
      return value
    },
    updateExamField(exam, index, field, value) {
      this.scrollableListToShow = null
      this.$emit(
        'update-exam-field',
        exam,
        field,
        this.convertCommaToDot(value)
      )
      this.$emit('update-chart', index)
    },
    closeFileModal() {
      this.showAddFileModal = false
    },
    closeInfoModal() {
      this.showInfoModal = false
    },

    handleJustifyObject(exam) {
      if (this.justifyNotValid) {
        return
      }

      const object = {
        ...exam,
        justificativaInativacao: this.justify,
        idUsuarioInativacao: this.idDoctor,
        dtInativacao: new Date().toISOString()
      }

      this.justify = ''
      this.handleRemoveOrInactivate(object)
      this.$bvModal.hide(`examsHistory_${exam.id}`)
    },

    handleRemoveOrInactivate(exam) {
      this.showRemoveDropDown = null
      this.$emit('handle-remove-or-inactivate', exam)
    },

    handleRemoveFile(exam) {
      this.showRemoveDropDown = null

      this.$emit('handle-remove-file', exam)
    }
  }
}
</script>

<style scoped lang="scss">
.selected {
  background-color: rgb(230, 230, 230);
}

td,
th {
  padding: 0.5rem !important;
}

.form-control {
  padding: 0 !important;
}

.table-container {
  height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.table-header {
  color: #fff;
  height: 35px !important;
  vertical-align: middle !important;

  &:first-of-type {
    border-top-left-radius: 5px;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
  }
}

.table-header:not(:first-child, :nth-child(2)),
td:not(:first-child, :nth-child(2)) {
  text-align: center;
}

th {
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

th:nth-child(1) {
  width: 140px;
}

th:nth-child(2) {
  width: auto;
}

th:nth-child(3) {
  width: 3%;
}

th:nth-child(4) {
  width: 5%;
}

th:nth-child(5) {
  width: 8%;
}

th:nth-child(6) {
  width: 5%;
}

th:nth-child(7),
th:nth-child(8),
th:nth-child(9) {
  min-width: 45px;
  width: 3%;
}

th:nth-child(10) {
  width: 90px;
}

input::-webkit-calendar-picker-indicator {
  margin-inline-start: 4px;
}

.listItem {
  padding: 5px;
  color: #000;
  border-bottom: 1px solid #ccc;
}

.degree {
  &-0 {
    background-color: #abebc6;
  }

  &-1 {
    background-color: #f9e79f;
  }

  &-2 {
    background-color: #f5b7b1;
  }

  &-normal {
    background-color: #fff;
  }
}

.listItem input {
  border: 0.2px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.inputFull {
  font-size: 10px !important;
  width: 100%;
}

.inputSmall {
  width: 100%;
}

.listItem i {
  text-align: center;
}

.listItem:hover {
  cursor: pointer;
  background: #f0f0f0;
}

.overflowText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-throughed {
  text-decoration: line-through;
}

@media (min-height: 600px) {
  .table-container {
    height: 40vh;
  }
}

@media (min-height: 700px) {
  .table-container {
    height: 45vh;
  }
}

@media (min-height: 800px) {
  .table-container {
    height: 50vh;
  }

  .inputFull {
    font-size: 11px !important;
  }
}

@media (min-height: 900px) {
  .table-container {
    height: 55vh;
  }
}
</style>
