const PREGNANCY_MODULE_ID = 15

const mutations = {
  loadPregnancies(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PREGNANCY_MODULE_ID
    )

    module.data = payload
  },

  addCoverSheet(state, { payload }) {
    state.localCoverSheet = payload
  },

  insertResults(state, { payload }) {
    state.localResults = payload
  },

  addResults(state, { payload }) {
    const index = state.localResults.findIndex((element) => {
      const id = element.id || element.tempId

      return id === payload.id || id === payload.tempId
    })

    if (index !== -1) {
      state.localResults.splice(index, 1, payload)
    } else {
      state.localResults.push(payload)
    }
  },

  clearResults(state) {
    state.localResults = []
  },

  clearVaccine(state) {
    state.listVaccines = []
    state.dataVaccines = {}
    state.vaccineEdit = {}
  },

  clearUltrassom(state) {
    state.dataUltrassom = []
  },

  clearExamsLaboratorys(state) {
    state.dataExamsLaboratorys = []
  },

  removePregnancy(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PREGNANCY_MODULE_ID
    )
    let index
    if (payload.id) {
      index = module.data.findIndex((form) => form.id === payload.id)
    } else {
      index = module.data.findIndex(
        (form) => form.dtInclusao === payload.dtInclusao
      )
    }

    module.data.splice(index, 1, { ...payload, deleted: true })
  },

  updatePregnancyField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PREGNANCY_MODULE_ID
    )

    const pregnancyIndex = module.data.findIndex(
      (pregnancy) => pregnancy.id === payload.id
    )

    module.data.splice(pregnancyIndex, 1, { ...payload, modified: true })
  },
  setVaccinesState(state, vaccines) {
    state.listVaccines = vaccines
  },
  setFormVacine(state, data) {
    state.dataVaccines = data
  },
  setDataUltrassom(state, ultrassom) {
    state.dataUltrassom = ultrassom
  },
  deleteVaccineFromLista(state, data) {
    state.listVaccines = data
  },
  getCoverSheetMutation(state, data) {
    state.localCoverSheet = data
  }
}

export default mutations
