<template>
  <div>
    <section class="sign-in-page" v-if="!initialized">
      <b-container class="sign-in-page-bg mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail">
              <a class="sign-in-logo mb-5" href="#"
                ><img :src="logo" class="img-fluid" alt="logo"
              /></a>
              <div class="term-of-use">
                <div v-if="isLoading === true">
                  <div
                    v-if="sucessOrerror"
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      gap: 10px;
                    "
                  >
                    <b-icon
                      icon="check-circle-fill"
                      font-scale="16"
                      variant="success"
                    ></b-icon>
                    <p style="font-size: 1rem; font-weight: bold">
                      Operação relaizada com <strong>sucesso</strong>,
                      obrigado!!
                    </p>
                  </div>
                  <div
                    v-else
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      gap: 10px;
                    "
                  >
                    <b-icon
                      icon="x-circle-fill"
                      font-scale="16"
                      variant="danger"
                    ></b-icon>
                    <p style="font-size: 1rem; font-weight: bold">
                      Houve um problema, tente em alguns minutos novamente!!
                    </p>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                  "
                >
                  <h3 style="font-size: 1.4rem; font-weight: 600">
                    Confirmação de Agendamento
                  </h3>
                  <p style="font-size: 0.9rem; text-align: center; color: #000">
                    Você tem uma consulta agendada para o dia
                    <strong>{{ formattedDate(data.dtAgendamento) }}</strong> as
                    <strong>{{ formattedHors(data.dtAgendamento) }}</strong> com
                    o(a) Dr(a)
                    <strong>{{ data.usuarioNome }}.</strong>
                  </p>
                  <p style="font-size: 1rem; color: #000">
                    Endereço: <strong>{{ data.enderecoClinica }}</strong
                    >.
                  </p>
                  <p style="font-size: 1rem; font-style: italic; color: #000">
                    Clique abaixo na opção que deseja comunicar
                  </p>
                </div>
                <div
                  v-if="sucessOrerror === false"
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 10px;
                  "
                >
                  <button
                    @click="setConfirmation()"
                    style="
                      display: flex;
                      gap: 4px;
                      align-items: center;
                      font-size: 1rem;
                      border: none;
                      border-radius: 10px;
                      background-color: green;
                      padding: 6px 12px;
                      color: #fff;
                      cursor: pointer;
                    "
                  >
                    <span>Confirma</span>
                    <b-spinner
                      v-show="isLoadingButtonConf === true"
                      class="alignCenter"
                      small
                      variant="ligth"
                    ></b-spinner>
                  </button>
                  <button
                    @click="setCanceled()"
                    :disabled="sucessOrerror === true ? true : false"
                    style="
                      display: flex;
                      gap: 4px;
                      align-items: center;
                      font-size: 1rem;
                      border: none;
                      border-radius: 10px;
                      background-color: red;
                      padding: 6px 12px;
                      color: #fff;
                      cursor: pointer;
                    "
                  >
                    <span>Cancelar</span>
                    <b-spinner
                      v-show="isLoadingButtonCanc === true"
                      class="alignCenter"
                      small
                      variant="ligth"
                    ></b-spinner>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import logo from '../../assets/images/logo-black-white.png'
import API from '../../services/index'
import moment from 'moment'
export default {
  name: 'ConfirmacaoAgendamento',
  props: ['id'],
  data() {
    return {
      data: {
        dtAgendamento: '',
        enderecoClinica: '',
        usuarioNome: ''
      },
      isLoadingButtonConf: false,
      isLoadingButtonCanc: false,
      isLoading: false,
      sucessOrerror: false,
      idAgendamento: '',
      logo: logo
    }
  },
  methods: {
    formattedDate(date) {
      if (date !== '') {
        return moment(date).format('DD/MM/YYYY')
      }
      return ''
    },
    formattedHors(date) {
      if (date !== '') {
        return moment(date).format('HH:mm')
      }
      return ''
    },
    async setConfirmation() {
      this.isLoadingButtonConf = true
      try {
        await API.get('/api/Agenda/ConfirmacaoAgendaLink', {
          params: {
            identificacao_consulta: this.idAgendamento,
            acao_confirmacao: 1
          }
        })
        this.isLoadingButtonConf = false
        this.isLoading = true
        this.sucessOrerror = true
      } catch (err) {
        this.isLoadingButtonConf = false
        this.isLoading = true
        this.sucessOrerror = false
      }
    },
    async setCanceled() {
      this.isLoadingButtonCanc = true
      try {
        await API.get('/api/Agenda/ConfirmacaoAgendaLink', {
          params: {
            identificacao_consulta: this.idAgendamento,
            acao_confirmacao: 2
          }
        })
        this.isLoadingButtonCanc = false
        this.isLoading = true
        this.sucessOrerror = true
      } catch (err) {
        this.isLoadingButtonCanc = false
        this.isLoading = true
        this.sucessOrerror = false
      }
    },
    async getAgendamento(id) {
      try {
        const response = await API.get(
          `api/Agenda/ObtemItemPorIdentificacao?identificacao=${id}`
        )
        this.data = response.data.object
      } catch (err) {
        return err
      }
    }
  },
  mounted() {
    this.idAgendamento = this.id
    this.getAgendamento(this.idAgendamento)
  }
}
</script>
<style scoped>
.term-of-use {
  width: 158%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 32px;
}
@media (max-width: 995px) {
  .term-of-use {
    width: 140%;
  }
}

@media (max-width: 768px) {
  .term-of-use {
    width: 100%;
  }
}
</style>
