<template>
  <b-modal
    :id="`forwarding-${index}`"
    header-bg-variant="primary"
    title="Encaminhamento e contra-referência"
    size="lg"
    title-class="text-light"
    ok-title="Salvar"
    cancel-variant="danger"
    @ok="saveCounterReference"
  >
    <div class="modal-body">
      <b-row>
        <b-col class="col-md-12 title"> Dados do encaminhamento: </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-8">
          <b-form-group label="Paciente:" label-for="patient">
            <b-form-input
              id="patient"
              :value="patient.nome"
              disabled
              class="bg-disabled"
            />
          </b-form-group>
        </b-col>

        <b-col class="col-md-4">
          <b-form-group label="Nascimento:" label-for="birthday">
            <b-form-input
              id="birthday"
              :value="formatDate(patient.dtNascimento)"
              disabled
              class="bg-disabled"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-4">
          <b-form-group label="Encaminhamento:" label-for="forwarding-date">
            <b-form-input
              class="bg-disabled"
              id="forwarding-date"
              :value="formatDate(forwarding.dtInclusao)"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col class="col-md-4">
          <b-form-group label="Tipo:" label-for="forwarding-type">
            <b-form-input
              class="bg-disabled"
              id="forwarding-type"
              v-model="forwardingType"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col class="col-md-4">
          <b-form-group label="Especialidade:" label-for="specialty">
            <b-form-input
              class="bg-disabled"
              id="specialty"
              v-model="specialty"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-8">
          <b-form-group label="Referenciado:" label-for="medic">
            <b-form-input
              class="bg-disabled"
              id="medic"
              v-model="forwarding.nmProfissionalSolicitante"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col class="col-md-4">
          <b-form-group label="Prioridade:" label-for="priority">
            <b-form-input
              class="bg-disabled"
              id="priority"
              v-model="priority"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-12">
          <b-form-group label="História clínica:" label-for="history">
            <b-form-textarea
              class="bg-disabled"
              id="history"
              rows="4"
              max-rows="4"
              style="line-height: 24px"
              v-model="forwarding.descHistoricoClinico"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-7">
          <b-form-group label="Hipóteses diagnósticas" label-for="diagnostic">
            <b-form-input
              class="bg-disabled"
              id="diagnostic"
              v-model="cid"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col class="col-md-5 d-flex align-items-center">
          <b-form-checkbox
            :value="0"
            :unchecked-value="1"
            disabled
            v-model="forwarding.notificarRecepcao"
          />
          <span>Notificar secretária para agendamento</span>
        </b-col>
      </b-row>

      <b-row class="divider">
        <b-col class="col-md-12 title"> Dados de contra-referência: </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-8">
          <b-form-group
            label="Profissional responsável:"
            label-for="responsible"
          >
            <b-form-input
              id="responsible"
              v-model="counterReference.nmProfissionalContrarreferencia"
              disabled
              class="bg-disabled"
            />
          </b-form-group>
        </b-col>

        <b-col class="col-md-4">
          <b-form-group label="Data:" label-for="date">
            <b-form-input
              id="date"
              v-model="counterReference.dtContrarreferencia"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-12">
          <b-form-group
            label="Parecer médico e exames solicitados:"
            label-for="opinion"
          >
            <b-form-textarea
              id="opinion"
              rows="4"
              max-rows="4"
              style="line-height: 24px"
              v-model="counterReference.descParecerMedico"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-12">
          <b-form-group
            label="Conduta e orientações ao paciente:"
            label-for="conduct"
          >
            <b-form-textarea
              id="conduct"
              rows="4"
              max-rows="4"
              style="line-height: 24px"
              v-model="counterReference.descCondutaMedica"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-6">
          <b-form-group
            label="Período de reavaliação:"
            label-for="period"
            style="position: relative"
          >
            <v-select
              id="period"
              :options="revaluationPeriod"
              label="text"
              :clearable="false"
              :reduce="(option) => option.value"
              v-model="counterReference.periodoReavaliacao"
              appendToBody
              :calculate-position="withPopper"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-md-6 d-flex align-items-center">
          <b-form-checkbox
            class="notify"
            :value="0"
            :unchecked-value="1"
            v-model="counterReference.pacienteAlta"
          />
          <span>Paciente de alta</span>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPopper } from '@popperjs/core'
export default {
  name: 'CounterReference',
  props: [
    'forwarding',
    'index',
    'patient',
    'forwardingType',
    'specialty',
    'cid',
    'priority'
  ],
  data() {
    return {
      revaluationPeriod: [
        { text: '15 dias', value: 15 },
        { text: '30 dias', value: 30 },
        { text: '60 dias', value: 60 },
        { text: '90 dias', value: 90 },
        { text: '120 dias', value: 120 },
        { text: '150 dias', value: 150 },
        { text: '180 dias', value: 180 }
      ],
      counterReference: {
        nmProfissionalContrarreferencia: '',
        descParecerMedico: '',
        descCondutaMedica: '',
        periodoReavaliacao: 0,
        dtContrarreferencia: this.formatDate(new Date()),
        pacienteAlta: 1
      }
    }
  },
  mounted() {
    if (this.forwarding.realizouContrareferencia === 0) {
      const {
        nmProfissionalContrarreferencia,
        descParecerMedico,
        descCondutaMedica,
        periodoReavaliacao,
        dtContrarreferencia,
        pacienteAlta
      } = this.forwarding

      this.counterReference = {
        nmProfissionalContrarreferencia,
        descParecerMedico,
        descCondutaMedica,
        periodoReavaliacao,
        dtContrarreferencia: this.formatDate(dtContrarreferencia),
        pacienteAlta
      }
    } else {
      this.counterReference.nmProfissionalContrarreferencia =
        this.user.nomeSocial
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      dropdownList.style.zIndex = 9999

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      return () => popper.destroy()
    },
    formatDate(date, format) {
      if (date) {
        const newDateFormat = new Date(date)
        return newDateFormat.toLocaleDateString(format)
      } else {
        return 'Não registrado'
      }
    },

    saveCounterReference() {
      const object = {
        ...this.forwarding,
        ...this.counterReference,
        realizouContrareferencia: 0
      }

      this.$store.commit({
        type: 'MedicalReports/updateForwardingField',
        payload: object
      })
    }
  }
}
</script>

<style scoped lang="scss">
.modal-body {
  overflow-y: auto;
  max-height: 70vh;
}

.bg-disabled {
  background-color: rgb(247, 246, 246) !important;
}

.divider {
  padding-top: 10px;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.3);
  border-top-style: solid;
}

.title {
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
