<template>
  <div class="timeline">
    <ul class="timeline__list">
      <li
        v-bind:class="
          withColors
            ? `timeline__item timeline__item--${data[idProperty]}`
            : 'timeline__item timeline__item--normal'
        "
        v-for="data in data"
        :key="data.id"
        :title="
          data.ativo === 1
            ? 'Justificativa:  ' + data.justificativaInativacao
            : ''
        "
      >
        <div class="timeline-content">
          <strong
            style="color: #000"
            :class="[
              'timeline-content__date',
              data.ativo === 1 ? 'line-throughed' : '',
              'large-fontsize'
            ]"
            >{{ handleFormatDate(data.dtOcorrencia, 'pt-BR').split(' ')[0] }}
            {{ formatHour(data.dtOcorrencia) }} -
            {{ formatName(data.nmProfissional) }}
          </strong>
          <p
            style="color: #000"
            v-html="data[descProperty]"
            :class="[
              'timeline-content__text',
              data.ativo === 1 ? 'line-throughed' : '',
              'medium-fontsize'
            ]"
          ></p>
        </div>
        <div class="button-container">
          <b-button
            @click="
              data.ativo === 0
                ? $bvModal.show(`${descProperty}_${data.id}`)
                : selectComplaintToEnable(data)
            "
            :variant="data.ativo === 0 ? 'danger' : 'primary'"
            style="width: 75px; height: 35px"
            v-if="data.ativo !== undefined"
          >
            {{ data.ativo === 0 ? 'Inativar' : 'Ativar' }}
          </b-button>

          <b-button
            v-if="data.ativo !== undefined"
            @click="copiarText(data[descProperty])"
            style="width: 75px; height: 35px"
            variant="primary"
          >
            Repetir
          </b-button>

          <b-modal
            :id="`${descProperty}_${data.id}`"
            size="lg"
            title="Justificativa"
            title-class="text-light"
            header-bg-variant="primary"
            header-text-variant="text-light"
            label-for="justify-input"
            ok-title="Salvar"
            v-model="isJustificationModalVisible"
            @hidden="handleModalHidden"
          >
            <b-form-group label-for="justify-input">
              <ValidationProvider
                name="justify"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="justify-input"
                  maxlength="150"
                  v-model="justify"
                  :class="
                    errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                  "
                ></b-form-textarea>
                <div class="invalid-feedback" :v-if="justifyNotValid">
                  <span>A justificativa deve conter no mínimo 5 digitos!</span>
                </div>
              </ValidationProvider>
            </b-form-group>

            <p>{{ justify.length }}/150</p>
            <template #modal-footer="{ cancel }">
              <b-button size="sm" variant="danger" @click="cancel()">
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="selectComplaintToDisable(data)"
              >
                Salvar
              </b-button>
            </template>
          </b-modal>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { formatDate } from '../../../../Utils/formatters'
export default {
  name: 'TimeLine',
  props: {
    idUser: Number,
    disableFunction: Function,
    data: Array,
    idProperty: String,
    descProperty: String,
    withColors: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isJustificationModalVisible: false,
      justify: ''
    }
  },
  computed: {
    justifyNotValid() {
      return this.justify.length < 5
    }
  },
  methods: {
    handleFormatDate(date, format) {
      return formatDate(date, format)
    },

    formatHour(date) {
      const formattedDate = new Date(date)
      const hours = formattedDate.getHours()
      const minutes = formattedDate.getMinutes()

      const formattedHours = ('0' + hours).slice(-2)
      const formattedMinutes = ('0' + minutes).slice(-2)

      if (!isNaN(hours) && !isNaN(minutes)) {
        return `${formattedHours}:${formattedMinutes}`
      } else {
        return ''
      }
    },

    formatName(name) {
      const separateNames = name ? name.toLowerCase().split(' ') : undefined

      if (typeof separateNames === 'object') {
        const formatedNames = separateNames.map((element) => {
          if (element) {
            const striped = element[0].toUpperCase() + element.substring(1)
            return striped
          }
        })

        return formatedNames.join(' ')
      } else {
        return ''
      }
    },
    copiarText(data) {
      const copia = data
        .replace(/<\/?div>/g, '')
        .replace(/<br>/g, '')
        .replace(/&nbsp;/g, '')

      navigator.clipboard
        .writeText(copia)
        .then(() => {
          this.$toast.success('Texto copiado com sucesso!')
          this.$root.$emit('text-copied', copia)
        })
        .catch((err) => {
          this.$toast.error('Erro ao copiar o texto:', err)
          this.$toast.error('Erro ao copiar o texto!')
        })
    },
    handleModalHidden() {
      this.justify = ''
    },
    selectComplaintToDisable(data) {
      if (this.justifyNotValid) {
        return
      }
      const object = {
        ...data,
        ativo: 1,
        dtInativacao: new Date().toISOString(),
        idUsuarioInativacao: this.idUser,
        justificativaInativacao: this.justify
      }

      this.justify = ''

      this.disableFunction(object)
      this.$bvModal.hide(`${this.descProperty}_${data.id}`)
    },

    selectComplaintToEnable(data) {
      data.ativo = 0

      this.disableFunction(data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../generalModuleStyles';

.timeline {
  background-color: white;
  height: 35vh;
  overflow-y: scroll;
  padding-top: 8px;
  border-bottom-right-radius: 16px;
  display: block;
  outline: none;

  &__item {
    display: flex;
    justify-content: space-between;
    border-left: 2px solid #ccc;
    padding-left: 15px;
    padding-bottom: 30px;

    position: relative;

    &:first-of-type {
      padding-top: 0px;
    }

    &::before {
      content: '';
      left: -6px;
      top: 7px;

      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
    }

    &:first-of-type::before {
      top: 7px;
    }

    &--0::before {
      background-color: #abebc6;
    }
    &--1::before {
      background-color: #f9e79f;
    }
    &--2::before {
      background-color: #f5b7b1;
    }

    &--normal::before {
      background-color: #089bab;
    }
  }

  &-content {
    &__date {
      font-weight: bold;
      color: #000;
      font-family: sans-serif;
    }

    &__text {
      overflow-wrap: break-word;
      margin: 0;
      margin-top: 8px;
      padding: 0;
      padding-bottom: 8px;
      padding-right: 8px;
    }
  }
}
.timeline__list {
  padding-left: 10px;
  margin: 0;
}
.button-container {
  display: none;
  gap: 6px;
  margin-right: 50px;
  align-items: center;
}

.modal {
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
}

.timeline__item:hover .button-container {
  display: flex;
}

.line-throughed {
  text-decoration: line-through;
}

@media (min-height: 600px) {
  .timeline {
    height: 40vh;
  }
}

@media (min-height: 700px) {
  .timeline {
    height: 45vh;
  }
}

@media (min-height: 800px) {
  .timeline {
    height: 50vh;
  }
}

@media (min-height: 900px) {
  .timeline {
    height: 55vh;
  }
}
</style>
