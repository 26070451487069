import API from '../../services/index'

export default {
  async getClinicAction(context, id) {
    const response = await API.get(
      `/api/Clinica/ObtemItem/${id}`,
      this.authToken
    )

    return response.data.object
  },
  async addClinicAction(context, payload) {
    const {
      descClinica,
      cnes,
      cnpj,
      // eslint-disable-next-line no-unused-vars
      responsible,
      emailClinica,
      idTipoLogradouro,
      cep,
      logradouro,
      numero,
      complementoEndereco,
      bairro,
      idEstado,
      idCidade,
      telefone,
      // eslint-disable-next-line no-unused-vars
      idUsuario,
      compartilhamentoProntuario,
      file,
      fileName,
      fileType,
      bodyFormData,
      descArquivoImagem
    } = payload

    const numCnes = parseInt(cnes)
    const numNumberAdress = parseInt(numero)
    const numCep = parseInt(cep)

    const clinic = await API.post(
      '/api/Clinica/Adicionar',
      {
        object: {
          descClinica,
          cnes: numCnes,
          cnpj,
          idTipoLogradouro,
          cep: numCep,
          logradouro,
          numero: numNumberAdress,
          complementoEndereco,
          bairro,
          idEstado,
          idCidade,
          telefone,
          emailClinica,
          status: 0,
          dtCriacao: '',
          codigo: null,
          compartilhamentoProntuario,
          descArquivoImagem,
          usuclinica: [
            {
              id: 0,
              idUsuario,
              nmUsuario: 'string',
              idClinica: 0,
              nmClinica: 'string',
              responsavel: 0,
              dtCriacao: '2021-01-27T11:26:16.465Z',
              idUsuarioInclusao: 0
            }
          ]
        }
      },
      {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      }
    )

    if (file) {
      API.post(
        `api/Clinica/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&id_clinica=${clinic.data.object.id}`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${window.$cookies.get('token')}`
          }
        }
      )
    }

    context.commit('addClinic', clinic)

    return clinic.data.message
  },
  async editClinicAction(context, payload) {
    const {
      id,
      // eslint-disable-next-line no-unused-vars
      codigo,
      descClinica,
      cnes,
      cnpj,
      // eslint-disable-next-line no-unused-vars
      responsible,
      emailClinica,
      idTipoLogradouro,
      cep,
      logradouro,
      numero,
      complementoEndereco,
      bairro,
      idEstado,
      idCidade,
      telefone,
      // eslint-disable-next-line no-unused-vars
      idUsuario,
      compartilhamentoProntuario,
      file,
      fileName,
      fileType,
      bodyFormData,
      descArquivoImagem
    } = payload

    const numCnes = parseInt(cnes)
    const numNumberAdress = parseInt(numero)
    const numCep = parseInt(cep)
    const clinic = await API.put(
      '/api/Clinica/Editar',
      {
        object: {
          id,
          descClinica,
          cnes: numCnes,
          cnpj,
          idTipoLogradouro,
          cep: numCep,
          logradouro,
          numero: numNumberAdress,
          complementoEndereco,
          bairro,
          idEstado,
          idCidade,
          telefone,
          emailClinica,
          status: 0,
          dtCriacao: '',
          codigo,
          compartilhamentoProntuario,
          descArquivoImagem,
          usuclinica: [
            {
              id: 0,
              idUsuario,
              nmUsuario: 'string',
              idClinica: 0,
              nmClinica: 'string',
              responsavel: 0,
              dtCriacao: '2021-01-27T11:26:16.465Z',
              idUsuarioInclusao: 0
            }
          ]
        }
      },
      {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      }
    )
    context.commit('editClinic', clinic)

    if (file) {
      API.post(
        `api/Clinica/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&id_clinica=${id}`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${window.$cookies.get('token')}`
          }
        }
      )
    }

    return clinic.data.message
  }
}
