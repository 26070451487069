<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th
            @click="() => handleSort(header.key)"
            class="table__header"
            v-for="header in activeHeaders"
            :style="`background-color: ${headerBackground}`"
            :key="header.key"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sortedItems" :key="index">
          <td
            :style="`background-color: ${headerBackground}`"
            class="table__date"
          >
            {{ convertDate(item.dtInclusao) }}
          </td>
          <td
            v-if="headers[1].ativo"
            :style="selectedColumn === 'pressao' && selectedColumnStyle"
          >
            {{ item.sistolica }}
          </td>
          <td
            v-if="headers[2].ativo"
            :style="selectedColumn === 'pressao' && selectedColumnStyle"
          >
            {{ item.diastolica }}
          </td>
          <td
            v-if="headers[3].ativo"
            :style="selectedColumn === 'imc' && selectedColumnStyle"
          >
            {{ item.peso }}
          </td>
          <td
            v-if="headers[4].ativo"
            :style="selectedColumn === 'imc' && selectedColumnStyle"
          >
            {{ item.altura }}
          </td>
          <td
            v-if="headers[5].ativo"
            :style="selectedColumn === 'imc' && selectedColumnStyle"
          >
            {{ item.imc }}
          </td>
          <td
            v-if="headers[6].ativo"
            :style="selectedColumn === 'pulso' && selectedColumnStyle"
          >
            {{ item.pulso }}
          </td>
          <td
            v-if="headers[7].ativo"
            :style="selectedColumn === 'temperatura' && selectedColumnStyle"
          >
            {{ item.temperatura }}
          </td>
          <td
            v-if="headers[8].ativo"
            :style="selectedColumn === 'quadril' && selectedColumnStyle"
          >
            {{ item.quadril }}
          </td>
          <td
            v-if="headers[9].ativo"
            :style="selectedColumn === 'panturilha' && selectedColumnStyle"
          >
            {{ item.panturilha }}
          </td>

          <td
            v-if="headers[10].ativo"
            :style="selectedColumn === 'abdominal' && selectedColumnStyle"
          >
            {{ item.abdominal }}
          </td>
          <td
            v-if="headers[11].ativo"
            :style="selectedColumn === 'braco' && selectedColumnStyle"
          >
            {{ item.braco }}
          </td>
          <td
            v-if="headers[12].ativo"
            :style="selectedColumn === 'saturacao' && selectedColumnStyle"
          >
            {{ item.saturacao }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import sortArray from 'sort-array'

export default {
  name: 'DataTable',
  props: ['items', 'headerBackground', 'selectedColumn', 'favorites'],

  methods: {
    handleSort(key) {
      this.sort.by = key
    },

    convertDate(date) {
      return new Date(date).toLocaleDateString('pt-BR')
    }
  },

  computed: {
    activeHeaders() {
      return this.headers.filter((header) => header.ativo)
    },

    sortedItems() {
      const newItems = [...this.items]
      const sort = this.sort
      sortArray(newItems, {
        order: sort.order,
        by: sort.by
      })
      return newItems
    }
  },

  data() {
    return {
      sort: {
        by: 'dtInclusao',
        order: 'desc'
      },
      selectedColumnStyle: {
        backgroundColor: '#C5C6D0'
      },
      headers: [
        {
          key: 'dtInclusao',
          label: 'Data',
          ativo: true
        },
        {
          key: 'sistolica',
          label: 'Sistólica',
          ativo: this.favorites.dadosPressaoSistolica.ativo === 0
        },
        {
          key: 'diastolica',
          label: 'Diastólica',
          ativo: this.favorites.dadosPressaoDiastolica.ativo === 0
        },
        {
          key: 'peso',
          label: 'Peso',
          ativo: this.favorites.dadosIMCPeso.ativo === 0
        },
        {
          key: 'altura',
          label: 'Altura',
          ativo: this.favorites.dadosIMCAltura.ativo === 0
        },
        {
          key: 'imc',
          label: 'IMC',
          ativo:
            this.favorites.dadosIMCAltura.ativo === 0 &&
            this.favorites.dadosIMCPeso.ativo === 0
        },
        {
          key: 'pulso',
          label: 'Pulso',
          ativo: this.favorites.dadosMedidasPulso.ativo === 0
        },
        {
          key: 'temperatura',
          label: 'Temperatura',
          ativo: this.favorites.dadosMedidasTemperatura.ativo === 0
        },
        {
          key: 'quadril',
          label: 'Quadril',
          ativo: this.favorites.dadosMedidasQuadril.ativo === 0
        },
        {
          key: 'panturilha',
          label: 'Panturrilha',
          ativo: this.favorites.dadosMedidasPanturilha.ativo === 0
        },

        {
          key: 'abdominal',
          label: 'Abdome',
          ativo: this.favorites.dadosMedidasAbdominal.ativo === 0
        },
        {
          key: 'braco',
          label: 'Braço',
          ativo: this.favorites.dadosMedidasBraco.ativo === 0
        },
        {
          key: 'saturacao',
          label: 'Saturação',
          ativo: this.favorites.dadosMedidasSaturacao.ativo === 0
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  display: block;
  width: 100%;
  max-height: 550px;
  overflow: scroll;

  td,
  th {
    width: 12.5%;
    text-align: center;
  }

  &__header {
    color: #fff;
    font-weight: 400;

    position: sticky;
    top: 0;
  }

  &__date {
    color: #fff;
  }

  tr > td:not(:first-child) {
    background-color: white;
    border: 1px solid #ccc;
  }
}

@media (max-width: 1800px) {
  .table {
    max-height: 400px;
  }
}
</style>
