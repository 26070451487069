<template>
  <b-container fluid :style="`${expanded ? '' : 'height: 0 !important;'}`">
    <b-table-simple hover>
      <b-thead head-variant="primary">
        <b-tr class="table-header">
          <b-th
            class="table__date"
            :style="`background-color: ${headerBackground}`"
            >Data</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`"
            >Profissional</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`"
            >Tipo Alergia</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`"
            >Descrição</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`"
            >Principio Ativo</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`">Grau</b-th>
          <b-th :style="`background-color: ${headerBackground}`"
            >Observação</b-th
          >
          <b-th :style="`background-color: ${headerBackground}`">Ativo</b-th>
          <b-th :style="`background-color: ${headerBackground}`">Ação</b-th>
        </b-tr>
      </b-thead>

      <b-tbody class="table__body">
        <b-tr
          :key="data.id"
          v-for="data in items"
          :title="
            data.ativo === 1
              ? 'Justificativa:  ' + data.justificativaInativacao
              : ''
          "
        >
          <b-td
            :class="['table__date', data.ativo === 1 ? 'line-throughed' : '']"
            >{{ formatDate(data.dtOcorrencia, 'pt-BR') }}</b-td
          >
          <b-td :class="data.ativo === 1 ? 'line-throughed' : ''">{{
            data.nmProfissional
          }}</b-td>
          <b-td v-if="data.isFixed">
            <v-select
              :options="typeAllergys"
              label="descTipo"
              :value="setValueDescTypeAllergy(data)"
              :clearable="false"
              @input="updateFieldDescType(data, $event)"
              append-to-body
            ></v-select>
          </b-td>
          <b-td v-else>{{ data.descTipoAlergia }}</b-td>
          <b-td v-if="data.isFixed" :title="data.descAlergia">
            <b-form-input
              :value="data.descAlergia ? data.descAlergia.trim() : ''"
              @change="updateField(data, 'descAlergia', $event)"
              type="text"
            />
          </b-td>
          <b-td v-else :title="data.descAlergia">{{ data.descAlergia }}</b-td>
          <b-td :class="data.ativo === 1 ? 'line-throughed' : ''">{{
            data.descMedicamento
          }}</b-td>
          <b-td v-if="data.isFixed">
            <v-select
              :options="typesGraus"
              label="descGrau"
              :value="setValueDescTypeGrau(data)"
              :clearable="false"
              @input="updateFieldGrau(data, $event)"
              append-to-body
            ></v-select
          ></b-td>
          <b-td v-else>
            <span :class="'table__degree ' + getBackground(data.descGrau)">
              {{ data.descGrau }}
            </span></b-td
          >
          <b-td :title="data.observacao">
            <b-form-input
              type="text"
              :value="data.observacao ? data.observacao.trim() : ''"
              @change="updateField(data, 'observacao', $event)"
            />
          </b-td>
          <b-td>
            <b-form-checkbox
              @input="updateFieldSituacaoAlergia(data, $event)"
              :checked="data.situacaoAlergia === 0 ? false : true"
            ></b-form-checkbox>
          </b-td>
          <b-td>
            <b-button
              style="height: 100%; width: 50%; padding: 0"
              @click="
                data.ativo === 0 && data.acao !== 'Remover'
                  ? $bvModal.show(`${moduleId}_${data.id}`)
                  : updateActivity(data)
              "
              :variant="getActionVariant(data.acao)"
              >{{ data.acao }}</b-button
            >

            <b-modal
              :id="`${moduleId}_${data.id}`"
              size="lg"
              title="Justificativa"
              title-class="text-light"
              header-bg-variant="primary"
              label-for="justify-input"
              ok-title="Salvar"
            >
              <b-form-group label-for="justify-input">
                <ValidationProvider
                  name="justify"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="justify-input"
                    maxlength="150"
                    v-model="justify"
                    :class="
                      errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                    "
                  ></b-form-textarea>
                  <div class="invalid-feedback" :v-if="justifyNotValid">
                    <span
                      >A justificativa deve conter no mínimo 5 digitos!</span
                    >
                  </div>
                </ValidationProvider>
              </b-form-group>

              <p>{{ justify.length }}/150</p>
              <template #modal-footer="{ cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                  Cancelar
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="handleClick(data)"
                >
                  Salvar
                </b-button>
              </template>
            </b-modal>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-container>
</template>

<script>
import API from '../../../../../services/index'
export default {
  name: 'AllergyTable',
  props: ['tableData', 'expanded', 'moduleId', 'headerBackground', 'idMedico'],
  data() {
    return {
      justify: '',
      typeAllergys: [],
      typesGraus: [
        { id: 0, descGrau: 'LEVE' },
        { id: 1, descGrau: 'MODERADA' },
        { id: 2, descGrau: 'SEVERO' }
      ]
    }
  },

  async mounted() {
    async function getTypesAllergy() {
      try {
        const response = await API.get('api/TipoAlergia/ObtemListaStatus/0')
        return response.data.object
      } catch (err) {
        return []
      }
    }

    this.typeAllergys = await getTypesAllergy()
  },

  computed: {
    justifyNotValid() {
      return this.justify.length < 5
    },
    items() {
      const copy = this.tableData.filter((allergy) => !allergy.deleted)

      copy.sort((prevValue, currentValue) => {
        return prevValue.isNew
          ? -1
          : prevValue.ativo === currentValue.ativo
          ? 0
          : prevValue.ativo === 0
          ? -1
          : 1
      })

      return copy
    }
  },

  methods: {
    setValueDescTypeAllergy(data) {
      if (data.descTipoAlergia !== '') {
        const response = this.typeAllergys.filter(
          (item) => item.descTipo === data.descTipoAlergia
        )
        return response
      } else {
        return ''
      }
    },
    setValueDescTypeGrau(data) {
      if (data.descGrau !== '') {
        const response = this.typesGraus.filter(
          (item) => item.descGrau === data.descGrau
        )
        return response
      } else {
        return ''
      }
    },
    selectAllergyToDisable(data) {
      if (this.justifyNotValid) {
        return
      }

      const object = {
        ...data,
        justificativaInativacao: this.justify,
        idUsuarioInativacao: this.idMedico,
        dtInativacao: new Date().toISOString()
      }

      this.$store.commit({
        type: 'MedicalReports/inactivateAllergy',
        payload: object
      })

      this.justify = ''
      this.$bvModal.hide(`${this.moduleId}_${data.id}`)
    },
    getActionVariant(action) {
      return action !== 'Ativar' ? 'danger' : 'primary'
    },
    getBackground(degree) {
      switch (degree.toLowerCase()) {
        case 'leve': {
          return 'table__degree--light'
        }
        case 'moderada': {
          return 'table__degree--medium'
        }
        case 'severo': {
          return 'table__degree--severe'
        }
      }
    },
    formatDate(date, format) {
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString(format)
    },
    activateAllergy(dataId) {
      this.$store.commit({
        type: 'MedicalReports/activateAllergy',
        payload: { moduleId: this.moduleId, dataId }
      })
    },
    updateField(allergy, field, value) {
      const payload = {
        ...allergy,
        [field]: value
      }
      this.$store.commit({
        type: 'MedicalReports/updateAllergyField',
        payload
      })
    },
    updateFieldDescType(allergy, value) {
      const payload = {
        ...allergy,
        descTipoAlergia: value.descTipo,
        idTipoAlergia: value.id
      }
      this.$store.commit({
        type: 'MedicalReports/updateAllergyField',
        payload
      })
    },
    updateFieldSituacaoAlergia(allergy, value) {
      let aux = null
      if (value) {
        aux = 1
      } else {
        aux = 0
      }
      const payload = {
        ...allergy,
        situacaoAlergia: aux
      }
      this.$store.commit({
        type: 'MedicalReports/updateAllergyField',
        payload
      })
    },
    updateFieldGrau(allergy, value) {
      const payload = {
        ...allergy,
        grau: value.id,
        descGrau: value.descGrau
      }
      this.$store.commit({
        type: 'MedicalReports/updateAllergyField',
        payload
      })
    },
    updateActivity(data) {
      if (data.ativo === 0) {
        this.$store.commit({
          type: 'MedicalReports/inactivateAllergy',
          payload: data
        })
      } else {
        this.$store.commit({
          type: 'MedicalReports/activateAllergy',
          payload: data
        })
      }
    },
    handleClick(data) {
      if (data.acao === 'Remover') {
        this.$store.commit({
          type: 'MedicalReports/removeAllergy',
          payload: data
        })
      } else if (data.acao === 'Inativar') {
        this.selectAllergyToDisable(data)
      } else if (data.acao === 'Ativar') {
        this.$store.commit({
          type: 'MedicalReports/activateAllergy',
          payload: data
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0 !important;
  height: 33vh;
  background-color: white;
  border-radius: 5px;
  overflow-y: scroll !important;
}
.table {
  table-layout: fixed;
  &__body {
    tr {
      margin-top: 5px !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      td {
        max-height: 35px !important;
        height: 19px;
        padding: 8px;

        &:not(:first-of-type) {
          text-align: center;
        }
      }
    }
  }

  &__date {
    width: 8%;
    font-family: sans-serif;
  }

  &__degree {
    text-align: center;
    width: 60%;
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
  }

  &__degree--light {
    background-color: #abebc6;
  }
  &__degree--medium {
    background-color: #f9e79f;
  }
  &__degree--severe {
    background-color: #f5b7b1;
  }
}

.table-header {
  color: #fff;
  text-align: left;
  font-size: 14px;
  th {
    font-weight: 400 !important;
    background-color: #089bab;
    position: sticky;
    top: 0px;
    z-index: 1;
    max-height: 35px !important;
    height: 19px !important;
    padding: 8px !important;
    border: none !important;
  }

  th:not(:first-of-type) {
    text-align: center;
  }
}

.line-throughed {
  text-decoration: line-through;
}

@media (min-height: 600px) {
  .container-fluid {
    height: 38vh;
  }
}

@media (min-height: 700px) {
  .container-fluid {
    height: 43vh;
  }
}

@media (min-height: 800px) {
  .container-fluid {
    height: 48vh;
  }
}

@media (min-height: 900px) {
  .container-fluid {
    height: 53vh;
  }
}
</style>
