import { render, staticRenderFns } from "./asoList.vue?vue&type=template&id=027e7967&scoped=true"
import script from "./asoList.vue?vue&type=script&lang=js"
export * from "./asoList.vue?vue&type=script&lang=js"
import style0 from "./asoList.vue?vue&type=style&index=0&id=027e7967&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027e7967",
  null
  
)

export default component.exports