<template>
  <b-modal
    id="modalpdf"
    size="xl"
    header-bg-variant="primary"
    title="Exportação de PDF"
    no-close-on-backdrop
    no-close-on-esc
    @close="close"
  >
    <div id="modal-body">
      <div class="modal-body-hidder">
        <span class="hidder-warn" v-if="!pdfExported">
          O PDF está sendo gerado...</span
        >
        <span class="hidder-warn" v-if="pdfExported"
          >PDF gerado com sucesso.</span
        >
      </div>
      <div id="pdf-container" class="pdf-container">
        <div class="cabecalho">
          <img
            src="../../../assets/images/logo.png"
            alt="imagem de cabeçalho"
            height="40px"
            width="40px"
          />
          <div class="text-cabecalho">
            <span>doctorpep.com.br</span>
            <span>instagram.com/doctorpepbr</span>
          </div>
        </div>
        <div>
          <h2
            v-if="convenio !== '' && type === true"
            style="margin-bottom: 4px; color: #4ebcda; font-size: 1.4rem"
          >
            {{ `Receitas - ${title} - ${convenio}` }}
          </h2>
          <h2
            v-if="supplier !== '' && type === false"
            style="margin-bottom: 4px; color: #4ebcda; font-size: 1.4rem"
          >
            {{ `Despesas - ${title} - ${supplier}` }}
          </h2>
          <h2
            v-if="type && convenio === ''"
            style="margin-bottom: 4px; color: #4ebcda; font-size: 1.4rem"
          >
            {{ `Receitas - ${title}` }}
          </h2>
          <h2
            v-if="!type"
            style="margin-bottom: 4px; color: #4ebcda; font-size: 1.4rem"
          >
            {{ `Despesas - ${title}` }}
          </h2>
        </div>

        <span style="color: #9299a8"
          >{{ getDateFormated(date.dt_inicio) }} até
          {{ getDateFormated(date.dt_final) }}</span
        >
        <hr
          style="
            margin: 0;
            margin-bottom: 4px;
            padding: 0;
            width: 100%;
            height: 8px;
            border: none;
            background-color: #27a2c0;
          "
        />
        <h3
          style="
            text-transform: uppercase;
            margin-bottom: 10px;
            font-size: 1.2rem;
          "
        >
          {{ clinicDecison.nmClinica + '/' + this.user.nome }}
        </h3>

        <div v-if="type" class="header-table">
          <span>Recebedor</span>
          <span>Tipo</span>
          <span>Título</span>
          <span>Vencimento</span>
          <span>Recebimento</span>
          <span>Valor</span>
          <span>Saldo</span>
          <span>Desconto</span>
          <span>Recebido</span>
          <span>Tipo pagamento</span>
          <span>Situação</span>
        </div>
        <div v-else class="header-table1">
          <span>Recebedor</span>
          <span>Tipo</span>
          <span>Título</span>
          <span>Vencimento</span>
          <span>Pagamento</span>
          <span>Valor</span>
          <span>Fornecedor</span>
          <span>Centro de custo</span>
          <span>Situação</span>
        </div>

        <template v-if="type">
          <div
            class="list-table"
            v-for="(item, index) in dataPlanilha"
            :key="index"
          >
            <div :class="index % 2 !== 0 ? 'line1' : 'line2'">
              <span>{{ item.nmRecebedor }}</span>
              <span>{{ item.descTipoRecebimento }}</span>
              <span>{{ item.numeroTitulo }}</span>
              <span>{{ getDateFormated(item.dtVencimento) }}</span>
              <span>{{ getDateFormated(item.dtRecebimento) }}</span>
              <span>{{ formatCurrency(item.valor) }}</span>
              <span>{{ formatCurrency(item.saldo) }}</span>
              <span>{{ formatCurrency(item.desconto) }}</span>
              <span>{{ formatCurrency(item.valorRecebido) }}</span>
              <span>{{ item.descTipoRecebimento }}</span>
              <span>{{ item.descStatusFinanceiro }}</span>
            </div>
            <div v-if="condition"></div>
          </div>
          <div class="amount">
            <span>{{ responsePhare }}</span>
            <span>{{ formatCurrency(amount) }}</span>
          </div>
        </template>
        <template v-else>
          <div
            class="list-table1"
            v-for="(item, index) in dataPlanilha"
            :key="index"
          >
            <div :class="index % 2 !== 0 ? 'lineExpense1' : 'lineExpense2'">
              <span>{{
                item.nmResPagador === null
                  ? item.descClinica
                  : item.nmResPagador
              }}</span>
              <span>{{ item.descDespesa }}</span>
              <span>{{ item.numeroTitulo }}</span>
              <span>{{ getDateFormated(item.dtVencimento) }}</span>
              <span>{{ getDateFormated(item.dtUltimoPagamento) }}</span>
              <span>{{ formatCurrency(item.valorTitulo) }}</span>

              <span>{{ item.descFornecedor }}</span>
              <span>{{ item.descCentroCusto }}</span>
              <span>{{ item.descStatusFinanceiro }}</span>
            </div>
            <div v-if="condition"></div>
          </div>
          <div class="amount">
            <span>{{ responsePhare }}</span>
            <span>{{ formatCurrency(amount) }}</span>
          </div>
        </template>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        size="sm"
        variant="primary"
        @click="gerarPDF"
        :disabled="!pdfExported"
      >
        Gerar Novamente
      </b-button>
      <b-button size="sm" variant="danger" @click="close()"> Sair </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import html2pdf from 'js-html2pdf'
export default {
  name: 'modalpdf',
  props: [
    'data',
    'type',
    'date',
    'clinicDecison',
    'title',
    'supplier',
    'convenio'
  ],
  data() {
    return {
      condition: false,
      pdfExported: false,
      dataPlanilha: this.data
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),
    amount() {
      const soma =
        this.type === false
          ? this.dataPlanilha.reduce(
              (acumulador, valorAtual) => acumulador + valorAtual.valorTitulo,
              0
            )
          : this.dataPlanilha.reduce(
              (acumulador, valorAtual) => acumulador + valorAtual.valor,
              0
            )

      return soma.toFixed(2)
    },

    responsePhare() {
      return this.type === false ? 'Total a pagar:' : 'Total a receber:'
    }
  },
  watch: {
    data(newValue) {
      this.dataPlanilha = newValue
    }
  },
  methods: {
    getDateFormated(value) {
      if (value === '' || value === null || value === undefined) {
        return ''
      } else {
        const newadate = moment(value).format('DD/MM/YYYY')
        return newadate
      }
    },
    formatCurrency(value) {
      if (value === null || value === undefined) return 'R$0,00'
      return `R$ ${parseFloat(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`
    },
    async gerarPDF() {
      this.pdfExported = false
      const element = document.getElementById('pdf-container')
      const options = {
        margin: 8,
        filename: 'relatorio_receitas.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
      }

      // eslint-disable-next-line new-cap
      const exporter = new html2pdf(element, options)

      await exporter.getPdf(false).then(async (pdf) => {
        // Adiciona rodapé em cada página
        const addFooter = () => {
          const pageCount = pdf.internal.pages.length
          const currentDate = new Date().toLocaleDateString()

          for (let i = 1; i <= pageCount; i++) {
            pdf.setPage(i)
            pdf.setFontSize(8) // Define o tamanho da fonte como 8px
            pdf.setTextColor(128, 128, 128) // Cor cinza

            // Adiciona linha com a cor #089BAB acima do texto
            pdf.setDrawColor(8, 155, 171) // Cor da linha
            pdf.line(
              0,
              pdf.internal.pageSize.height - 8,
              pdf.internal.pageSize.width,
              pdf.internal.pageSize.height - 8
            )

            // Adiciona texto "Gerado por DoctorPEP em *data de hoje"
            pdf.text(
              `Gerado por DoctorPEP em ${currentDate}`,
              4,
              pdf.internal.pageSize.height - 4
            )

            // Adiciona paginação no formato "Página x de y"
            pdf.text(
              `Página ${i} de ${pageCount}`,
              pdf.internal.pageSize.width - 20,
              pdf.internal.pageSize.height - 4
            )
          }
        }

        addFooter()

        /// Define um nome personalizado para o arquivo
        const fileName =
          this.type === false ? 'relatorio_despesas' : 'relatorio_receitas'

        // Salva o PDF com o nome personalizado
        pdf.save(fileName + '.pdf')
        this.pdfExported = true
      })
    },
    close() {
      this.$emit('close')
    }
  },
  async mounted() {
    this.$bvModal.show('modalpdf')
    this.dataPlanilha = this.data
    setTimeout(() => {
      this.gerarPDF()
    }, 2000)
  }
}
</script>

<style scoped>
.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cabecalho {
  margin-bottom: 32px;
  padding: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  background-color: #089bab;
  align-items: center;
}

.text-cabecalho {
  display: flex;
  gap: 1px;
  flex-direction: column;
  align-items: flex-start;
}
.text-cabecalho span {
  line-height: 12px;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  color: white;
  font-weight: 500;
  font-style: normal;
}

.header-table {
  width: 100%;
  color: #9191aa;
  font-size: 0.7rem;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr 1.4fr 1.3fr 0.6fr 0.6fr 1fr 1fr 2fr 1fr;
}
.header-table1 {
  width: 100%;
  color: #9191aa;
  font-size: 0.7rem;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr 1.4fr 1.3fr 0.6fr 2fr 2fr 1fr;
}

.list-table {
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.list-table1 {
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.line1 {
  width: 100%;
  background-color: #f2f2f2;
  padding: 8px 0px;
  width: 100%;
  color: black;
  font-size: 0.5rem;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr 1.4fr 1.3fr 0.6fr 0.6fr 1fr 1fr 2fr 1fr;
}

.line2 {
  width: 100%;
  padding: 8px 0px;
  width: 100%;
  color: black;
  font-size: 0.5rem;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr 1.4fr 1.3fr 0.6fr 0.6fr 1fr 1fr 2fr 1fr;
}

.lineExpense1 {
  width: 100%;
  background-color: #f2f2f2;
  padding: 8px 0px;
  width: 100%;
  color: black;
  font-size: 0.5rem;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr 1.4fr 1.3fr 0.6fr 2fr 2fr 1fr;
}
.lineExpense2 {
  width: 100%;
  padding: 8px 0px;
  width: 100%;
  color: black;
  font-size: 0.5rem;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr 1.4fr 1.3fr 0.6fr 2fr 2fr 1fr;
}

.amount {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amount span {
  color: #089bab;
  font-size: 0.9rem;
  font-weight: bold;
}
</style>
