<template>
  <div class="painel">
    <span class="labelPainel">Receitas</span>
    <div class="revenue-reports">
      <ValidationObserver ref="form">
        <div class="card-standard revenue1">
          <div class="header-card">
            <span>Total por Clínica</span>
            <!-- <div>
              <button @click="openGenerator(1)">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
              </button>
              <button @click="gerarPlanilha(1)">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
              </button>
            </div> -->
          </div>
          <hr class="divider" />
          <v-select
            class="v-select"
            v-model="clinicRevenue1"
            :options="clinicsRevenue1"
            label="nmClinica"
            :clearable="false"
          ></v-select>
          <div class="card-data">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateInit1"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateEnd1"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
          </div>
          <hr class="divider" />
          <div class="card-radios">
            <b-form-checkbox
              id="checkbox-one"
              v-model="statusOne1"
              name="checkbox-one"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Emissão
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-two"
              v-model="statusTwo1"
              name="checkbox-tow"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Recebimento
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox-there"
              v-model="statusThere1"
              name="checkbox-there"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Pagamento
            </b-form-checkbox>
          </div>
          <div class="format-selection">
            <span class="format-text">Escolha o formato do relatório: </span>
            <div class="format-buttons">
              <button @click="openGenerator(1)" class="format-button">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
                <div
                  v-if="loading && loadingType === 'pdf'"
                  class="loading-spinner"
                ></div>
              </button>
              <button @click="gerarPlanilha(1)" class="format-button">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
                <div
                  v-if="loading && loadingType === 'xls'"
                  class="loading-spinner"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver ref="form2">
        <div class="card-standard revenue2">
          <div class="header-card">
            <span>Total por Profissional</span>
            <!-- <div>
              <button @click="openGenerator(2)">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
              </button>
              <button @click="gerarPlanilha(2)">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
              </button>
            </div> -->
          </div>
          <hr class="divider" />
          <v-select
            class="v-select"
            v-model="clinicRevenue2"
            :options="clinicsRevenue2"
            label="nmClinica"
            :clearable="false"
          ></v-select>
          <v-select
            class="v-select"
            v-model="doctor1"
            :options="doctors1"
            label="nmUsuario"
            :clearable="true"
          ></v-select>
          <div class="card-data">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateInit2"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateEnd2"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
          </div>
          <hr class="divider" />
          <div class="card-radios">
            <b-form-checkbox
              id="checkbox-one2"
              v-model="statusOne2"
              name="checkbox-one2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Emissão
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-two2"
              v-model="statusTwo2"
              name="checkbox-tow2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Recebimento
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox-there2"
              v-model="statusThere2"
              name="checkbox-there2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Pagamento
            </b-form-checkbox>
          </div>
          <div class="format-selection">
            <span class="format-text">Escolha o formato do relatório: </span>
            <div class="format-buttons">
              <button @click="openGenerator(2)" class="format-button">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
                <div
                  v-if="loading && loadingType === 'pdf'"
                  class="loading-spinner"
                ></div>
              </button>
              <button @click="gerarPlanilha(2)" class="format-button">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
                <div
                  v-if="loading && loadingType === 'xls'"
                  class="loading-spinner"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>

      <ValidationObserver ref="form3">
        <div class="card-standard revenue3">
          <div class="header-card">
            <span>Total por Situação</span>
            <!-- <div>
              <button @click="openGenerator(3)">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
              </button>
              <button @click="gerarPlanilha(3)">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
              </button>
            </div> -->
          </div>
          <hr class="divider" />
          <v-select
            class="v-select"
            v-model="clinicRevenue3"
            :options="clinicsRevenue3"
            label="nmClinica"
            :clearable="false"
          ></v-select>
          <v-select
            class="v-select"
            v-model="doctor2"
            :options="doctors2"
            label="nmUsuario"
            :clearable="true"
          ></v-select>
          <v-select
            class="v-select"
            v-model="situetion"
            :options="listSituetion"
            label="descSituacao"
            :clearable="true"
          ></v-select>
          <div class="card-data">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateInit3"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateEnd3"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="format-selection">
            <span class="format-text">Escolha o formato do relatório: </span>
            <div class="format-buttons">
              <button @click="openGenerator(3)" class="format-button">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
                <div
                  v-if="loading && loadingType === 'pdf'"
                  class="loading-spinner"
                ></div>
              </button>
              <button @click="gerarPlanilha(3)" class="format-button">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
                <div
                  v-if="loading && loadingType === 'xls'"
                  class="loading-spinner"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>

      <ValidationObserver ref="form4">
        <div class="card-standard revenue3">
          <div class="header-card">
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: flex-start;
              "
            >
              <span>Total por Convênio</span>
              <span
                style="
                  display: block;
                  font-weight: bold;
                  color: #000;
                  font-size: 0.6rem;
                  font-style: italic;
                "
                >**Apenas status de atendimento concluídos</span
              >
            </div>
            <!-- <div>
              <button @click="openGenerator(4)">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
              </button>
              <button @click="gerarPlanilha(4)">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
              </button>
            </div> -->
          </div>
          <hr class="divider" />
          <v-select
            class="v-select"
            v-model="clinicRevenue4"
            :options="clinicsRevenue4"
            label="nmClinica"
            :clearable="false"
          ></v-select>
          <v-select
            class="v-select"
            v-model="doctor3"
            :options="doctors3"
            label="nmUsuario"
            :clearable="true"
          ></v-select>
          <v-select
            class="v-select"
            v-model="convenio"
            :options="listConvenio"
            label="nmConvenio"
            :clearable="true"
          ></v-select>
          <div class="card-data">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateInit4"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateEnd4"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="format-selection">
            <span class="format-text">Escolha o formato do relatório: </span>
            <div class="format-buttons">
              <button @click="openGenerator(4)" class="format-button">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
                <div
                  v-if="loading && loadingType === 'pdf'"
                  class="loading-spinner"
                ></div>
              </button>
              <button @click="gerarPlanilha(4)" class="format-button">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="exel"
                />
                <div
                  v-if="loading && loadingType === 'xls'"
                  class="loading-spinner"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <pdfGenerator
      v-if="open"
      @close="closeGenerator"
      :data="data"
      :date="getDateForPDF()"
      :type="true"
      :supplier="''"
      :convenio="convenioName"
      :title="titlePdf"
      :clinicDecison="clinicOption"
    />
  </div>
</template>
<script>
import moment from 'moment'
import pdfGenerator from '../pdfGenerator.vue'
import { mapGetters, mapActions } from 'vuex'
import * as XLSX from 'xlsx'
export default {
  components: { pdfGenerator },
  data() {
    return {
      convenioName: '',
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      data: [],
      clinicOption: '',

      clinicRevenue1: '',
      clinicsRevenue1: [],
      clinicRevenue2: '',
      clinicsRevenue2: [],
      clinicRevenue3: '',
      clinicsRevenue3: [],
      clinicRevenue4: '',
      clinicsRevenue4: [],

      situetion: '',
      listSituetion: [],
      convenio: '',
      listConvenio: [],

      doctor1: '',
      doctor2: '',
      doctor3: '',
      doctors1: [],
      doctors2: [],
      doctors3: [],

      dateInit1: this.getInitialOfMonth(),
      dateEnd1: this.getFinalyofMonth(),
      dateInit2: this.getInitialOfMonth(),
      dateEnd2: this.getFinalyofMonth(),
      dateInit3: this.getInitialOfMonth(),
      dateEnd3: this.getFinalyofMonth(),
      dateInit4: this.getInitialOfMonth(),
      dateEnd4: this.getFinalyofMonth(),

      statusOne1: 'accepted',
      statusTwo1: 'not_accepted',
      statusThere1: 'not_accepted',

      statusOne2: 'accepted',
      statusTwo2: 'not_accepted',
      statusThere2: 'not_accepted',

      dados: {
        valor: 120,
        nome: 'José Flávio',
        idade: 23,
        cpf: '082.206.745-46'
      },
      open: false,
      whereDate: 0,
      titlePdf: '',
      loading: false,
      loadingType: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  methods: {
    ...mapActions({
      getDataForConvenioPDF: 'Finance/getDataForConvenioPDF',
      getDataConveniosOfDoctor: 'Finance/getDataConveniosOfDoctor',
      getDataForPDF: 'Finance/getDataForPDF',
      getUsersForClinics: 'Finance/getUsersForClinics',
      getStatusFinance: 'Finance/getStatusFinance'
    }),
    getFinalyofMonth() {
      const finalDoMes = new Date()
      finalDoMes.setMonth(finalDoMes.getMonth() + 1)
      finalDoMes.setDate(0)
      return moment(finalDoMes).format('YYYY-MM-DD')
    },
    getInitialOfMonth() {
      const inicioDoMes = new Date()
      inicioDoMes.setDate(1)
      return moment(inicioDoMes).format('YYYY-MM-DD')
    },
    getDateForPDF() {
      if (this.whereDate === 1) {
        return { dt_inicio: this.dateInit1, dt_final: this.dateEnd1 }
      }
      if (this.whereDate === 2) {
        return { dt_inicio: this.dateInit2, dt_final: this.dateEnd2 }
      }
      if (this.whereDate === 3) {
        return { dt_inicio: this.dateInit3, dt_final: this.dateEnd3 }
      }
      if (this.whereDate === 4) {
        return { dt_inicio: this.dateInit4, dt_final: this.dateEnd4 }
      }
    },
    getStatusCheckboxOne() {
      if (this.statusOne1 === 'accepted') {
        return 1
      }
      if (this.statusTwo1 === 'accepted') {
        return 2
      }
      if (this.statusThere1 === 'accepted') {
        return 3
      }
    },
    getStatusCheckboxTwo() {
      if (this.statusOne2 === 'accepted') {
        return 1
      }
      if (this.statusTwo2 === 'accepted') {
        return 2
      }
      if (this.statusThere2 === 'accepted') {
        return 3
      }
    },
    async openGenerator(value) {
      this.loading = true
      this.loadingType = 'pdf'
      this.convenioName = ''
      try {
        if (value === 1) {
          const isValid = await this.$refs.form.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit1).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd1).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: 0,
              id_clinica: this.clinicRevenue1.idClinica,
              id_status: 0,
              verifica_data: this.getStatusCheckboxOne()
            }
            const listTable = await this.getDataForPDF(form)
            this.data = listTable
            this.whereDate = 1
            this.clinicOption = this.clinicRevenue1
            this.titlePdf = 'Total por Clínica'
            this.open = true
          }
        }
        if (value === 2) {
          const isValid = await this.$refs.form2.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit2).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd2).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: this.doctor1.idUsuario,
              id_clinica: this.clinicRevenue2.idClinica,
              id_status: 0,
              verifica_data: this.getStatusCheckboxTwo()
            }
            const listTable = await this.getDataForPDF(form)
            this.data = listTable
            this.whereDate = 2
            this.clinicOption = this.clinicRevenue2
            this.titlePdf = 'Total por Profissional'
            this.open = true
          }
        }
        if (value === 3) {
          const isValid = await this.$refs.form3.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit3).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd3).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: this.doctor2.idUsuario,
              id_clinica: this.clinicRevenue3.idClinica,
              id_status: this.situetion.id,
              verifica_data: 0
            }
            const listTable = await this.getDataForPDF(form)
            this.data = listTable
            this.whereDate = 3
            this.clinicOption = this.clinicRevenue3
            this.titlePdf = 'Total por Situação'
            this.open = true
          }
        }
        if (value === 4) {
          const isValid = await this.$refs.form4.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit4).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd4).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: this.doctor3.idUsuario,
              id_clinica: this.clinicRevenue4.idClinica,
              id_convenio: this.convenio.id,
              verifica_data: 0
            }
            const listTable = await this.getDataForConvenioPDF(form)
            this.data = listTable
            this.whereDate = 4
            this.clinicOption = this.clinicRevenue4
            this.titlePdf = 'Total por Convênio'
            this.convenioName = this.convenio.nmConvenio
            this.open = true
          }
        }
      } catch (error) {
        this.$toast.error('Erro ao gerar dados. Tente novamente.')
      } finally {
        this.loading = false
        this.loadingType = ''
      }
    },
    closeGenerator() {
      this.open = false
    },
    async gerarPlanilha(value) {
      this.loading = true
      this.loadingType = 'xls'
      try {
        if (value === 1) {
          const isValid = await this.$refs.form.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit1).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd1).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: 0,
              id_clinica: this.clinicRevenue1.idClinica,
              id_status: 0,
              verifica_data: this.getStatusCheckboxOne()
            }
            const listTable = await this.getDataForPDF(form)
            this.data = listTable
          }
        }
        if (value === 2) {
          const isValid = await this.$refs.form2.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit2).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd2).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: this.doctor1.idUsuario,
              id_clinica: this.clinicRevenue2.idClinica,
              id_status: 0,
              verifica_data: this.getStatusCheckboxTwo()
            }
            const listTable = await this.getDataForPDF(form)
            this.data = listTable
          }
        }
        if (value === 3) {
          const isValid = await this.$refs.form3.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit3).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd3).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: this.doctor2.idUsuario,
              id_clinica: this.clinicRevenue3.idClinica,
              id_status: this.situetion.id,
              verifica_data: 0
            }
            const listTable = await this.getDataForPDF(form)
            this.data = listTable
          }
        }
        if (value === 4) {
          const isValid = await this.$refs.form4.validate()
          if (!isValid) {
            return this.$toast.error('Verifique os campos obrigatórios!')
          } else {
            const form = {
              dt_inicio: moment(this.dateInit4).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dateEnd4).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              id_usuario: this.doctor3.idUsuario,
              id_clinica: this.clinicRevenue4.idClinica,
              id_convenio: this.convenio.id,
              verifica_data: 0
            }
            const listTable = await this.getDataForConvenioPDF(form)
            this.data = listTable
          }
        }

        const planilhaData = []

        // Adicionar o título principal à planilha
        planilhaData.unshift(['Planilha de receitas'])

        // Adicionar os títulos de coluna
        planilhaData.push([
          'recebedor',
          'tipo',
          'titulo',
          'vencimento',
          'recebimento',
          'valor',
          'saldo',
          'desconto',
          'recebido',
          'tipo_pagamento',
          'situacao'
        ])

        // Iterar sobre a lista this.data e adicionar linhas à planilhaData
        for (let i = 0; i < this.data.length; i++) {
          planilhaData.push([
            this.data[i].nmRecebedor,
            this.data[i].descTipoRecebimento,
            this.data[i].numeroTitulo,
            this.getDateFormated(this.data[i].dtVencimento),
            this.getDateFormated(this.data[i].dtRecebimento),
            this.data[i].valor,
            this.data[i].saldo,
            this.data[i].desconto,
            this.data[i].valorRecebido,
            this.data[i].tipoPagamento,
            this.data[i].descStatusFinanceiro
          ])
        }

        // Criar uma nova planilha
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(planilhaData)

        // Adicionar a planilha ao livro de trabalho
        XLSX.utils.book_append_sheet(wb, ws, 'MinhaPlanilha')

        // Salvar o arquivo Excel
        XLSX.writeFile(wb, 'planilha_receitas.xlsx')
      } catch (error) {
        this.$toast.error(error)
      } finally {
        this.loading = false
        this.loadingType = ''
      }
    },
    getDateFormated(value) {
      if (value === '' || value === null || value === undefined) {
        return ''
      } else {
        const newadate = moment(value).format('DD/MM/YYYY')
        return newadate
      }
    },
    async initComponent() {
      this.clinicsRevenue1 = this.user.clinica_usuario
      this.clinicRevenue1 = this.user.clinica_usuario[0]
      this.clinicsRevenue2 = this.user.clinica_usuario
      this.clinicRevenue2 = this.user.clinica_usuario[0]
      this.clinicsRevenue3 = this.user.clinica_usuario
      this.clinicRevenue3 = this.user.clinica_usuario[0]
      this.clinicsRevenue4 = this.user.clinica_usuario
      this.clinicRevenue4 = this.user.clinica_usuario[0]
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          1 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          4 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 5
      ) {
        this.doctor1 = this.user.clinica_usuario[0]
        this.doctors1 = [this.user.clinica_usuario[0]]
        this.doctor2 = this.user.clinica_usuario[0]
        this.doctors2 = [this.user.clinica_usuario[0]]
        this.doctor3 = this.user.clinica_usuario[0]
        this.doctors3 = [this.user.clinica_usuario[0]]
        this.listConvenio = await this.getDataConveniosOfDoctor(
          this.doctor3.idUsuario
        )
        this.convenio = this.listConvenio[0]
      }
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          2 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 3
      ) {
        this.doctors1 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctors2 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctors3 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor1 = this.doctors1[0]
        this.doctor2 = this.doctors2[0]
        this.doctor3 = this.doctors3[0]
        this.listConvenio = await this.getDataConveniosOfDoctor(
          this.doctor3.idUsuario
        )
        this.convenio = this.listConvenio[0]
      }
    }
  },
  watch: {
    statusOne1(newValue) {
      if (newValue === 'accepted') {
        this.statusTwo1 = 'not_accepted'
        this.statusThere1 = 'not_accepted'
      }
    },
    statusTwo1(newValue) {
      if (newValue === 'accepted') {
        this.statusOne1 = 'not_accepted'
        this.statusThere1 = 'not_accepted'
      }
    },
    statusThere1(newValue) {
      if (newValue === 'accepted') {
        this.statusOne1 = 'not_accepted'
        this.statusTwo1 = 'not_accepted'
      }
    },
    statusOne2(newValue) {
      if (newValue === 'accepted') {
        this.statusTwo2 = 'not_accepted'
        this.statusThere2 = 'not_accepted'
      }
    },
    statusTwo2(newValue) {
      if (newValue === 'accepted') {
        this.statusOne2 = 'not_accepted'
        this.statusThere2 = 'not_accepted'
      }
    },
    statusThere2(newValue) {
      if (newValue === 'accepted') {
        this.statusOne2 = 'not_accepted'
        this.statusTwo2 = 'not_accepted'
      }
    },
    async doctor3(newValue) {
      this.listConvenio = await this.getConvenios(newValue.idUsuario)
    }
  },
  async mounted() {
    this.initComponent()
    this.listSituetion = await this.getStatusFinance()
    this.situetion = this.listSituetion[0]
  }
}
</script>
<style scoped>
.painel {
  width: 100%;
  padding: 1.2rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 70px;
}

.labelPainel {
  font-size: 1.4rem;
  font-weight: 600;
}

.revenue-reports {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
}

.card-standard {
  border-radius: 10px;
  border: 1px #b5bbbf solid;
  background-color: white;
  height: 330px;
  min-width: 400px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 4px;
}

.header-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-card span {
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  color: #b5bbbf;
}
.header-card div {
  display: flex;
  gap: 6px;
  align-items: center;
}

.header-card button {
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
}

.header-card img {
  height: 30px;
  width: 30px;
}

.divider {
  border: none;
  height: 0.5px;
  background-color: #b5bbbf;
  border-radius: 10px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.v-select {
  width: 100%;
}

.card-data {
  display: flex;
  gap: 10px;
  align-items: center;
}
.invalid-feedback {
  font-size: 0.8rem;
}

.card-data input {
  font-size: 0.8rem;
  padding: 6px 6px;
  border: 1px #b5bbbf solid;
  border-radius: 10px;
}

.card-radios {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.format-selection {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre o texto e os botões */
}

.format-text {
  color: #000;
}

.format-buttons {
  display: flex;
}

.format-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.format-button img {
  width: 34px; /* Ajuste o tamanho dos ícones conforme necessário */
  height: auto;
}
.loading-spinner {
  margin-top: 5px; /* Espaço entre o ícone e o spinner */
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
