<template>
  <div>
    <div class="toolbar" style="margin-right: auto">
      <div id="numbs" class="fonts__container">
        <button
          class="fonts__item fonts__item--1"
          @click="execCommand('fontSize', '3', 'Fonts')"
        >
          12
        </button>
        <button
          class="fonts__item fonts__item--2"
          @click="execCommand('fontSize', '4', 'Fonts')"
        >
          14
        </button>
        <button
          class="fonts__item fonts__item--3"
          @click="execCommand('fontSize', '5', 'Fonts')"
        >
          16
        </button>
        <button
          class="fonts__item fonts__item--4"
          @click="execCommand('fontSize', '6', 'Fonts')"
        >
          18
        </button>
      </div>

      <div class="box-options">
        <ul class="tool-list">
          <li class="tool">
            <button type="button" @click="execCommand('bold')">
              <b-icon icon="type-bold" />
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('italic')">
              <b-icon icon="type-italic" />
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('underline')">
              <b-icon icon="type-underline" />
            </button>
          </li>
        </ul>
      </div>

      <div class="box-options">
        <ul class="tool-list">
          <li class="tool">
            <button type="button" @click="execCommand('justifyLeft')">
              <i class="fas fa-align-left"></i>
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('justifyCenter')">
              <i class="fas fa-align-center"></i>
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('justifyRight')">
              <i class="fas fa-align-right"></i>
            </button>
          </li>
        </ul>
      </div>

      <div>
        <div class="colors">
          <button
            class="colors__item colors__item--1"
            @click="execCommand('foreColor', '#091E42')"
          ></button>
          <button
            class="colors__item colors__item--2"
            @click="execCommand('foreColor', '#ff5630')"
          ></button>
          <button
            class="colors__item colors__item--3"
            @click="execCommand('foreColor', '#36b37e')"
          ></button>
          <button
            class="colors__item colors__item--4"
            @click="execCommand('foreColor', '#00b8d9')"
          ></button>
        </div>
      </div>
    </div>
    <div
      :ref="`output--${id}`"
      @input="updateContent"
      contentEditable="true"
      :class="`output output--${id}`"
    />
  </div>
</template>

<script>
function placeCaretAtEnd(el) {
  el.focus()
  if (
    typeof window.getSelection !== 'undefined' &&
    typeof document.createRange !== 'undefined'
  ) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    // TODO: Try to remove this line
    // sel.removeAllRanges()
    sel.addRange(range)
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}
export default {
  name: 'ClinicReport',
  props: {
    id: {
      type: String,
      required: false
    },
    initialContent: {
      type: String,
      required: false
    },
    contentToAdd: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      content: '',
      editableElement: null
    }
  },
  mounted() {
    this.editableElement = this.$refs[`output--${this.id}`]
    if (this.editableElement) {
      this.editableElement.innerHTML = this.initialContent || ''
      this.focusOnElement()
    }

    this.$root.$on('text-copied', (payload) => {
      this.addTextToEditor(payload)
    })
  },
  methods: {
    addTextToEditor(copia) {
      if (this.editableElement) {
        const div = document.createElement('div')
        div.innerHTML = copia
        this.editableElement.appendChild(div)
        this.focusOnElement()
      }
    },
    execCommand(command, value = null) {
      if (command === 'foreColor') {
        document.execCommand('styleWithCSS', false, true)
        document.execCommand(command, false, value)
        placeCaretAtEnd(this.editableElement)
      } else {
        document.execCommand(command, false, value)
        placeCaretAtEnd(this.editableElement)
      }
    },
    focusOnElement() {
      if (this.editableElement) {
        const selection = window.getSelection()
        const range = document.createRange()

        // Se o conteúdo não estiver vazio
        if (this.editableElement.innerHTML.trim().length > 0) {
          // Remove qualquer linha extra existente no final
          const lastChild = this.editableElement.lastChild
          if (
            lastChild &&
            lastChild.tagName === 'DIV' &&
            lastChild.innerHTML === '<br>'
          ) {
            this.editableElement.removeChild(lastChild)
          }

          // Adiciona uma nova linha no final
          const newLine = document.createElement('div')
          newLine.innerHTML = '<br>'
          this.editableElement.appendChild(newLine)

          // Define o range para o início do novo elemento
          range.setStart(newLine, 0)
          range.collapse(true)
        } else {
          // Se o conteúdo estiver vazio, apenas coloca o cursor no início
          range.selectNodeContents(this.editableElement)
          range.collapse(true)
        }

        // Atualiza a seleção e garante que o elemento tenha o foco
        selection.removeAllRanges()
        selection.addRange(range)
        this.editableElement.focus()
      }
    },
    updateContent() {
      const content = this.editableElement.innerHTML
      this.content = content
      this.$store.commit('MedicalReports/changeModulesStateChangedToTrue')
      this.$emit('update-content', content)
    }
  },

  watch: {
    contentToAdd(newContent) {
      this.editableElement.innerHTML += newContent
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
}

.box-options {
  width: max-content;

  .tool:not(:last-child) {
    border-right: 1px solid #ccc;
    padding-right: 4px;
  }
}

.tool {
  border-right: 1px solid #ccc;
  padding-right: 4px;
  font-size: 18px;
  button {
    background-color: transparent;
    border: none;
  }
}

.tool-list {
  padding: 0;
  margin: 0;
  display: flex;
}

.fontSize,
.fontFamily {
  border-radius: 50px;
  background-color: white;
  padding: 2px 5px;
}

.output {
  display: block;

  background-color: #fff;
  color: #000;
  height: 50vh;
  overflow-y: scroll;
  border: none;
  padding: 8px;
  border-bottom-left-radius: 16px;

  outline: none;
}

.colors {
  &__item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 4px;
    border: none;
  }

  &__item--1 {
    background-color: #091e42;
  }
  &__item--2 {
    background-color: #ff5630;
  }
  &__item--3 {
    background-color: #36b37e;
  }
  &__item--4 {
    background-color: #00b8d9;
  }
}

.fonts {
  &__container {
    padding: 0;
    margin: 0;
    position: relative;
    width: max-content;
    border-right: 1px solid #ccc;
    padding-right: 4px;
  }

  &__text {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #000;
    p {
      margin: 0;
      margin-right: 2px;
    }
  }
}

.fonts {
  &__container {
    display: flex;
    background-color: white;
  }

  &__item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 4px;
    border: none;
  }
}

@media (max-width: 600px) {
  .colors {
    display: none;
  }
  #numbs {
    display: none;
  }
  .output {
    height: 14vh;
  }
}

@media (max-width: 1200px) {
  .colors {
    display: none;
  }
  .fonts__container {
    display: none;
  }
}

@media (max-width: 900px) {
  .output {
    height: 40vh;
  }
}

@media (max-width: 850px) {
  .output {
    height: 30vh;
  }
}

@media (max-width: 700px) {
  .output {
    height: 30vh;
  }
}

@media (max-width: 640px) {
  .output {
    height: 20vh;
  }
}
</style>
