<template>
  <div class="search-result__container" v-if="results.length >= 1">
    <table class="table" v-if="results.length >= 1">
      <thead class="header">
        <tr>
          <th>Código CID</th>
          <th>Descrição</th>
        </tr>
      </thead>
      <tbody class="body">
        <tr v-for="result in results" :key="result.idFormulario">
          <td @click="onResultSelected(result)" class="cell">
            {{ result.codCid }}
          </td>

          <td @click="onResultSelected(result)" class="cell">
            {{ result.descCid }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    results: Array,
    userId: Number
  },
  data() {
    return {
      items: []
    }
  },

  methods: {
    onResultSelected(result) {
      this.$emit('on-result-selected', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result {
  &__container {
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    right: -53%;
    bottom: -138%;
    border: 1px solid #000;
    border-radius: 15px;
    max-height: 300px;
    overflow-y: scroll;
    width: 150%;
  }
}

.header {
  background-color: #089bab;
  color: #fff;
  font-size: 13px;
  align-items: center;
  top: 0;
  z-index: 3;
  width: 100%;
  border-spacing: 0 50px;
}

.body {
  padding: 10px 4px;
  margin: 0;
  color: #000;
}

.checkbox {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cell {
  cursor: pointer;
}
</style>
