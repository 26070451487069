import API from '../../services/index'

export default {
  async getScheduleByUserIdAction(context, userId) {
    const response = await API.get('/api/AgendaHorario/ObtemListaPorUsuario', {
      params: { id_usuario: userId }
    })

    return response.data.object
  },
  async saveScheduleAction(context, payload) {
    const { professional } = payload

    const object = {
      idUsuario: professional.id,
      idClinica: payload.clinic.idClinica,
      primeiraConsulta: payload.firstConsult,
      ultiimaConsulta: payload.lastConsult,
      inicioIntervalo: payload.startInterval,
      fimIntervalo: payload.endInterval,
      tempoConsulta: payload.consultTime,
      selecaodiasSemanas: payload.selectedDays,
      ativo: 0,
      idUsuarioInclusao: payload.responsibleUser,
      profissional: professional.id
    }

    await API.post('/api/AgendaHorario/Adicionar', { object })
  },

  async editScheduleAction(context, payload) {
    await API.put('/api/AgendaHorario/Editar', { object: payload })
  },
  async deleteScheduleAction(context, scheduleId) {
    await API.delete('/api/AgendaHorario/Remover', {
      data: { object: { id: scheduleId } }
    })
  }
}
