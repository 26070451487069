<template>
  <div class="table-container">
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Data
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Profissional
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Especialidade
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Prioridade
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Contrarreferência
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Data
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            PDF
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Parecer
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(forwarding, index) in forwardingsOrdenedsByDate"
          class="listItem"
          :key="`${forwarding.idProntuario}-${forwarding.id}`"
        >
          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            {{ formatDate(forwarding.dtInclusao) }}
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            {{ forwarding.nmProfissionalSolicitante }}
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            {{ getSpecialtyById(forwarding.idEspecialidade) }}
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            {{ getPriorityById(forwarding.idEncaminhamentoPrioridade) }}
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            {{ forwarding.nmProfissionalContrarreferencia }}
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            {{ forwarding.dtContrarreferencia }}
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            <b-button
              variant="outline-light"
              @click.prevent="getPdf(forwarding)"
            >
              <i class="fas fa-file-pdf fa-2x" style="color: #089bab"></i>
            </b-button>
          </b-td>

          <b-td :class="forwarding.ativo === 1 ? 'line-throughed' : ''">
            <b-button
              variant="outline-light"
              @click="$bvModal.show(`forwarding-${index}`)"
            >
              <i class="fas fa-file-alt fa-2x" style="color: #089bab"></i>
            </b-button>
          </b-td>

          <b-td>
            <b-button
              :variant="forwarding.ativo === 0 ? 'danger' : 'primary'"
              @click="
                forwarding.ativo === 0
                  ? handleRemoveOrDisableForwarding(forwarding)
                  : handleActivateForwarding(forwarding)
              "
              >{{
                forwarding.isNew
                  ? 'Remover'
                  : forwarding.ativo === 0
                  ? 'Inativar'
                  : 'Ativar'
              }}</b-button
            >
          </b-td>

          <counter-reference-modal
            :forwarding="forwarding"
            :index="index"
            :specialty="getSpecialtyById(forwarding.idEspecialidade)"
            :priority="getPriorityById(forwarding.idEncaminhamentoPrioridade)"
            :forwarding-type="
              getForwardingTypeById(forwarding.idTipoEncaminhamento)
            "
            :cid="getCidById(forwarding.idCid)"
            :patient="patient"
          />

          <b-modal
            :id="`forwarding_justification_${forwarding.id}`"
            size="lg"
            title="Justificativa"
            header-bg-variant="primary"
            title-class="text-light"
            label-for="justify-input"
            ok-title="Salvar"
          >
            <b-form-group label-for="justify-input">
              <ValidationProvider
                name="justify"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="justify-input"
                  maxlength="150"
                  v-model="justify"
                  :class="
                    errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                  "
                ></b-form-textarea>
                <div class="invalid-feedback" :v-if="justifyNotValid">
                  <span>A justificativa deve conter no mínimo 5 digitos!</span>
                </div>
              </ValidationProvider>
            </b-form-group>

            <p>{{ justify.length }}/150</p>
            <template #modal-footer="">
              <b-button
                size="sm"
                variant="danger"
                @click="hideJustifyModal(forwarding.id)"
              >
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="handleDisableForwarding(forwarding)"
              >
                Salvar
              </b-button>
            </template>
          </b-modal>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <ModalGeneratePDF
      v-if="modalOpen"
      :item="item"
      @close="close()"
      :paciente="patient"
      :priorities="priorities"
      :specialties="specialties"
    />
  </div>
</template>

<script>
import CounterReferenceModal from './CounterReferenceModal'
import ModalGeneratePDF from './ModalGeneratePDF.vue'

export default {
  name: 'ForwardingsList',
  components: {
    CounterReferenceModal,
    ModalGeneratePDF
  },
  props: [
    'headerColor',
    'forwardingsList',
    'specialties',
    'priorities',
    'forwardingTypes',
    'cids',
    'patient'
  ],
  data() {
    return {
      justify: '',
      modalOpen: false,
      item: ''
    }
  },
  computed: {
    justifyNotValid() {
      return this.justify.length < 5
    },

    forwardingsOrdenedsByDate() {
      const ordened = [...this.forwardingsList].sort((a, b) => {
        return new Date(b.dtInclusao) - new Date(a.dtInclusao)
      })

      return ordened
    }
  },
  methods: {
    getPdf(data) {
      this.item = data
      this.modalOpen = true
    },
    close() {
      this.modalOpen = false
    },
    getCidById(id) {
      if (id) {
        const cid = this.cids.find((element) => {
          return element.id === id
        })

        return cid.descCid
      }
    },
    getSpecialtyById(id) {
      if (id) {
        const specialty = this.specialties.find((element) => {
          return element.id === id
        })

        return specialty.descCbo
      }
    },
    getPriorityById(id) {
      if (id) {
        const priority = this.priorities.find((element) => {
          return element.id === id
        })

        return priority.descPrioridadeEncaminhamento
      }
    },
    getForwardingTypeById(id) {
      if (id) {
        const forwardingType = this.forwardingTypes.find((element) => {
          return element.id === id
        })

        return forwardingType.descTipoEncaminhamento
      }
    },
    formatDate(date, format) {
      if (date) {
        const newDateFormat = new Date(date)
        return newDateFormat.toLocaleDateString(format)
      } else {
        return 'Não registrado'
      }
    },
    handleRemoveOrDisableForwarding(forwarding) {
      if (forwarding.isNew) {
        this.$store.commit({
          type: 'MedicalReports/removeForwarding',
          payload: forwarding
        })
      } else {
        this.showJustifyModal(forwarding.id)
      }
    },

    showJustifyModal(forwardingId) {
      this.$bvModal.show(`forwarding_justification_${forwardingId}`)
    },
    hideJustifyModal(forwardingId) {
      this.justify = ''
      this.$bvModal.hide(`forwarding_justification_${forwardingId}`)
    },
    handleDisableForwarding(forwarding) {
      this.$store.commit({
        type: 'MedicalReports/updateForwardingField',
        payload: {
          ...forwarding,
          ativo: 1,
          justificativaInativacao: this.justify
        }
      })

      this.hideJustifyModal(forwarding.id)
    },
    handleActivateForwarding(forwarding) {
      this.$store.commit({
        type: 'MedicalReports/updateForwardingField',
        payload: {
          ...forwarding,
          ativo: 0
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  height: 32vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.line-throughed {
  text-decoration: line-through;
}

th {
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

.table {
  table-layout: fixed;

  tr {
    th:not(:first-child),
    td:not(:first-child) {
      text-align: center;
    }
    th:nth-child(1) {
      width: 100px;
    }

    th:nth-child(2) {
      width: auto;
    }

    th:nth-child(3) {
      width: 200px;
    }

    th:nth-child(4) {
      width: 100px;
    }

    th:nth-child(5) {
      width: auto;
    }
    th:nth-child(6) {
      width: 100px;
    }
    th:nth-child(7) {
      width: 100px;
    }

    th:nth-child(8) {
      width: 100px;
    }
  }
}

@media (min-height: 600px) {
  .table-container {
    height: 37vh;
  }
}

@media (min-height: 700px) {
  .table-container {
    height: 42vh;
  }
}

@media (min-height: 800px) {
  .table-container {
    height: 47vh;
  }
}

@media (min-height: 900px) {
  .table-container {
    height: 52vh;
  }
}
</style>
