var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container",on:{"click":function($event){_vm.showRemoveDropDown = false}}},[_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Data ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Exame ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Qtd. ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Valor ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Realizado ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Resultado ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Laudo ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}},[_vm._v(" Info ")]),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}}),_c('b-th',{staticClass:"table-header",staticStyle:{"background-color":"#089bab"}})],1)],1),_c('b-tbody',_vm._l((_vm.examsList),function(exam,index){return _c('b-tr',{key:exam.id,class:[
          'listItem',
          exam.idGrauAlteracao !== null
            ? `degree-${exam.idGrauAlteracao}`
            : 'degree-normal',
          exam.id === _vm.selectedExamId ? 'selected' : ''
        ],attrs:{"title":_vm.popoverMessage(exam)},on:{"click":function($event){return _vm.selectedExam(exam.id, index)}}},[_c('b-td',{class:exam.ativo === 1 ? 'line-throughed' : ''},[_vm._v(" "+_vm._s(_vm.handleFormatLocalDateHour(exam.dtSolicitacao))+" ")]),_c('b-td',{class:['overflowText', exam.ativo === 1 ? 'line-throughed' : ''],attrs:{"title":exam.codigoServico + ' - ' + exam.descServico}},[_vm._v(" "+_vm._s(exam.descServico)+" ")]),_c('b-td',{staticStyle:{"position":"relative"}},[_c('b-form-input',{staticClass:"inputSmall",attrs:{"autocomplete":"off","maxlength":"2","value":exam.qtdeSolicitacao},on:{"input":function($event){return _vm.updateExamField(exam, 'qtdeSolicitacao', $event)},"focus":function($event){_vm.scrollableListToShow = index},"blur":_vm.hideScrollableList}}),(_vm.scrollableListToShow === index)?_c('scrollable-list',{attrs:{"options":['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']},on:{"handle-selection":function($event){return _vm.updateExamField(exam, index, 'qtdeSolicitacao', $event)}}}):_vm._e()],1),_c('b-td',[_c('b-form-input',{attrs:{"type":"number","value":exam.valorServico !== null ? exam.valorServico : 0},on:{"input":function($event){return _vm.updateExamField(exam, index, 'valorServico', $event)}}})],1),_c('b-td',[_c('b-form-input',{staticClass:"inputFull",attrs:{"type":"date","value":exam.dtRealizacao ? exam.dtRealizacao : ''},on:{"change":function($event){return _vm.updateExamField(exam, index, 'dtRealizacao', $event)}}})],1),_c('b-td',[_c('b-form-input',{staticClass:"inputSmall focusable-resultado",attrs:{"type":"number","value":exam.resultado,"maxlength":"5"},on:{"click":function($event){return $event.stopPropagation()},"input":function($event){return _vm.updateExamField(exam, index, 'resultado', $event)},"keydown":_vm.simulateTab}})],1),_c('b-td',[(exam.descArquivo.length > 0)?_c('i',{staticClass:"far fa-file-pdf fa-2x",staticStyle:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit(
                'preview-file',
                exam.file || {
                  descArquivo: exam.descArquivo,
                  idProntuario: exam.idProntuario
                }
              )}}}):_c('i',{staticClass:"fas fa-upload fa-2x",staticStyle:{"color":"#089bab"},on:{"click":function($event){return _vm.handlerShowAddFileModal(exam)}}})]),_c('b-td',[_c('i',{staticClass:"fas fa-file-alt fa-2x",staticStyle:{"color":"#089bab"},on:{"click":function($event){return _vm.handlerShowInfoModal(exam)}}})]),_c('b-td',{on:{"click":_vm.handleEmitShowGraph}},[_c('b-icon',{attrs:{"icon":"graph-up","variant":"primary","scale":"2"}})],1),_c('b-td',[_c('div',{staticStyle:{"position":"relative"}},[_c('b-button',{attrs:{"variant":['Remover', 'Inativar'].includes(exam.acao)
                  ? 'danger'
                  : 'primary'},on:{"click":function($event){$event.stopPropagation();return (() =>
                  (exam.file || !!exam.descArquivo) &&
                  exam.idProntuario === _vm.idProntuario
                    ? (_vm.showRemoveDropDown = index)
                    : exam.ativo === 0 && exam.acao !== 'Remover'
                    ? _vm.$bvModal.show(`examsHistory_${exam.id}`)
                    : _vm.handleRemoveOrInactivate(exam)).apply(null, arguments)}}},[_vm._v(_vm._s(exam.acao))]),(
                _vm.showRemoveDropDown === index &&
                (exam.file || !!exam.descArquivo)
              )?_c('div',{staticStyle:{"position":"absolute","top":"100%","z-index":"4","background-color":"#fff","padding":"4px"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":['Remover', 'Inativar'].includes(exam.acao)
                    ? 'danger'
                    : 'primary'},on:{"click":function($event){$event.stopPropagation();exam.ativo === 0
                    ? _vm.$bvModal.show(`examsHistory_${exam.id}`)
                    : _vm.handleRemoveOrInactivate(exam)}}},[_vm._v("Exame")]),(
                  exam.file ||
                  (exam.descArquivo && exam.idProntuario === _vm.idProntuario)
                )?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveFile(exam)}}},[_vm._v(" Anexo ")]):_vm._e()],1):_vm._e()],1)]),_c('b-modal',{attrs:{"id":`examsHistory_${exam.id}`,"size":"lg","title":"Justificativa","title-class":"text-light","header-bg-variant":"primary","header-text-variant":"text-light","label-for":"justify-input","ok-title":"Salvar"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ cancel }){return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handleJustifyObject(exam)}}},[_vm._v(" Salvar ")])]}}],null,true)},[_c('b-form-group',{attrs:{"label-for":"justify-input"}},[_c('ValidationProvider',{attrs:{"name":"justify","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-textarea',{class:errors.length > 0 || _vm.justifyNotValid ? ' is-invalid' : '',attrs:{"id":"justify-input","maxlength":"150"},model:{value:(_vm.justify),callback:function ($$v) {_vm.justify=$$v},expression:"justify"}}),_c('div',{staticClass:"invalid-feedback",attrs:{"v-if":_vm.justifyNotValid}},[_c('span',[_vm._v("A justificativa deve conter no mínimo 5 digitos!")])])]}}],null,true)})],1),_c('p',[_vm._v(_vm._s(_vm.justify.length)+"/150")])],1)],1)}),1)],1),(_vm.showAddFileModal)?_c('AddFileModal',{on:{"add-file":_vm.addFile,"closeModal":_vm.closeFileModal}}):_vm._e(),(_vm.showInfoModal)?_c('InfoModal',{attrs:{"exam":_vm.examToAddInfo},on:{"add-info":_vm.addInfo,"closeModal":_vm.closeInfoModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }