import { parseToHtml } from '../../../../Utils/parsers'
const TRACKING_MODULE_ID = 16
const mutations = {
  loadTracking(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === TRACKING_MODULE_ID
    )
    if (payload.length > 0) {
      module.data = {
        ...payload[0],
        descMonitoramento: parseToHtml(payload[0].descMonitoramento)
      }
    } else {
      module.data = {}
    }
  },
  addTracking(state, { payload }) {
    state.localTrackingState = payload
  },

  removeTrackingFromState(state, { payload }) {
    state.localTrackingState = {}
  }
}

export default mutations
