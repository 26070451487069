import API from '../../services/index'

export default {
  async createWaitingListItemAction(context, data) {
    const itemListaDiasSemana = []
    data.selectedDays.forEach((day) => {
      data.selectedPeriods.forEach((period) => {
        itemListaDiasSemana.push({
          diasSemana: day.id,
          descSemana: day.description,
          periodoDia: period.description.substring(0, 1).toUpperCase()
        })
      })
    })

    const object = {
      idPaciente: data.idPatient,
      dataInicio: `${data.initialDate}T15:00:00.000Z`,
      dataFinal: `${data.finalDate}T15:00:00.000Z`,
      status: 0,
      idUsuario: data.idUser,
      idClinica: data.idClinic,
      itemListaDiasSemana
    }

    await API.post('/api/AgendaListaEspera/Adicionar', {
      object
    })
  },

  async getAwaitingListByDoctorAndClinicAction(context, data) {
    const response = await API.get(
      '/api/AgendaListaEspera/ObtemListaPorUsuarioClinica',
      {
        params: {
          id_usuario: data.idDoctor,
          id_clinica: data.idClinic,
          status: 1
        }
      }
    )

    const items = []

    response.data.object.forEach((element) => {
      const selectedDays = []
      const selectedPeriods = []

      element.itemListaDiasSemana.forEach((item) => {
        const periodIds = {
          M: 1,
          T: 2,
          N: 3
        }

        const convertedDay = {
          description: item.descSemana,
          id: item.diasSemana
        }

        const convertedPeriod = {
          description: item.periodoDia,
          id: periodIds[item.periodoDia]
        }

        const dayIndex = selectedDays.findIndex((element) => {
          return element.id === convertedDay.id
        })

        if (dayIndex === -1) {
          selectedDays.push(convertedDay)
        }

        const periodIndex = selectedPeriods.findIndex((element) => {
          return element.id === convertedPeriod.id
        })

        if (periodIndex === -1) {
          selectedPeriods.push(convertedPeriod)
        }
      })

      function sortById() {
        return function (elem1, elem2) {
          if (elem1.id < elem2.id) {
            return -1
          } else if (elem1.id > elem2.id) {
            return 1
          } else {
            return 0
          }
        }
      }

      selectedPeriods.sort(sortById())

      const item = {
        id: element.id,
        idPatient: element.idPaciente,
        patientName: element.nmPaciente,
        idUser: element.idUsuario,
        idClinic: element.idClinica,
        initialDate: element.dataInicio,
        finalDate: element.dataInicio,
        selectedDays,
        selectedPeriods
      }

      items.push(item)
    })

    return items
  },

  async deleteWaitingListItemAction(context, Id) {
    await API.delete('/api/AgendaListaEspera/Remover', {
      data: {
        object: {
          Id
        }
      }
    })
  },
  async sendLinkconfirmation({ state }, payload) {
    try {
      await API.get(
        `/api/Agenda/ReenviaEmailLinkConfirmacao?id_agenda=${payload}`
      )
      return true
    } catch (err) {
      return false
    }
  }
}
