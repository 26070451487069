import API from '../../services/index'

export default {
  async getCompleteASOByIdAndClinic(context, { idClinic, idASO }) {
    try {
      const apiResponse = await API.get(
        '/api/ProntuarioAso/ObtemItemAsoCompleto',
        {
          params: {
            id_clinica: idClinic,
            id_prontuario_aso: idASO
          }
        }
      )
      return apiResponse.data.object
    } catch {
      return false
    }
  },
  async getASOsByPatient(context, { idPatient, idMedic, idClinic }) {
    try {
      const apiResponse = await API.get(
        '/api/ProntuarioAso/ObtemAsoPorPaciente',
        {
          params: {
            id_clinica: idClinic,
            id_paciente: idPatient,
            id_medico: idMedic
          }
        }
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },
  async getRiskFactorByClinic(context, idClinic) {
    try {
      const apiResponse = await API.get(
        '/api/MedTrabalhoFatorRisco/ObtemListaPorClinica',
        {
          params: {
            id_clinica: idClinic
          }
        }
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async getEnterpriseByClinic(context, idClinic) {
    try {
      const apiResponse = await API.get(
        '/api/MedTrabalhoEmpresa/ObtemListaPorClinica',
        {
          params: {
            id_clinica: idClinic
          }
        }
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async getOfficesByClinic(context, idClinic) {
    try {
      const apiResponse = await API.get(
        '/api/MedTrabalhoCargo/ObtemListaPorClinica',
        {
          params: {
            id_clinica: idClinic
          }
        }
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async getMedicOpinionByClinic(context, idClinic) {
    try {
      const apiResponse = await API.get(
        '/api/MedTrabalhoParecer/ObtemListaPorClinica',
        {
          params: {
            id_clinica: idClinic
          }
        }
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async getlicenceCertificateTypes(context) {
    try {
      const apiResponse = await API.get(
        '/api/MedTrabalhoTipoAtestado/ObtemListaAtivo/0'
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async getServices(context, payload) {
    try {
      const apiResponse = await API.get(
        `/api/FavoritoHistoricoExame/ObtemListaPorServico/?buscaServico=${payload}&id_prontuario_preferencia=${491}`
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async createASOEnterprise(context, payload) {
    payload.cnpj = payload.cnpj.replaceAll('.', '').replaceAll('/', '')
    payload.cep = payload.cep.replaceAll('-', '')

    try {
      const apiResponse = await API.post('/api/MedTrabalhoEmpresa/Adicionar', {
        object: payload
      })

      return apiResponse
    } catch {
      return false
    }
  },

  async createASOOffice(context, payload) {
    try {
      const apiResponse = await API.post('/api/MedTrabalhoCargo/Adicionar', {
        object: payload
      })

      return apiResponse
    } catch {
      return false
    }
  },

  async getMedicEnterpriseByMedic(context, payload) {
    try {
      const apiResponse = await API.get(
        '/api/MedicoTrabalho/ObtemListaPorUsuario',
        {
          params: {
            id_usuario: payload
          }
        }
      )

      return apiResponse.data.object
    } catch {
      return false
    }
  },

  async getMedicEnterpriseByEnterprise(context, payload) {
    try {
      const apiResponse = await API.get('/api/MedicoTrabalho/Adicionar', {
        params: {
          id_med_trabalho_empresa: payload
        }
      })

      return apiResponse
    } catch {
      return false
    }
  },
  async createMedicEnterpriseRelation(context, payload) {
    const { idMedico, idMedTrabalhoEmpresa } = payload
    try {
      const apiResponse = await API.post('/api/MedicoTrabalho/Adicionar', {
        object: {
          idMedico,
          idMedTrabalhoEmpresa
        }
      })

      return apiResponse.data.object.id
    } catch {
      return false
    }
  },

  async getClinicImage(context, payload) {
    try {
      const response = await API.get(
        `api/Clinica/AwsS3ObtemArquivo?arquivoname=${payload.descArquivoImagem}&id_clinica=${payload.id}`,
        { responseType: 'blob' }
      )

      return response.data
    } catch (e) {
      return false
    }
  },

  async createRiksFactor(context, payload) {
    try {
      const response = await API.post(`/api/MedTrabalhoFatorRisco/Adicionar`, {
        object: payload
      })

      return response.data.object
    } catch (e) {
      return false
    }
  }
}
