<template>
  <div class="module" v-if="componentInfo.ativo === 0">
    <div class="details">
      <p
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </p>
      <div class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactiveFiles"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>
      <div style="width: 25%; position: relative">
        <b-form-input
          v-model="filter"
          class="details__input"
          placeholder="Pesquisar"
          autocomplete="off"
        ></b-form-input>
      </div>
      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>
    <div class="content" v-if="componentInfo.aberto === 0">
      <div class="timeLineFiles">
        <div class="timeline">
          <div
            class="timeLineItem"
            v-for="(file, index) in filesListToShow"
            :key="file.id"
          >
            <b-form-checkbox
              :style="mobile === true ? 'display: none;' : ''"
              v-if="file.tipoArquivoImagem === 'image'"
              v-model="listFilesToCompare"
              :value="file"
              :disabled="
                listFilesToCompare.length > 2 &&
                listFilesToCompare.indexOf(file) === -1
              "
            ></b-form-checkbox>
            <div
              :style="
                file.tipoArquivoImagem !== 'image' && mobile === false
                  ? 'paddingLeft: 24px'
                  : ''
              "
              class="timeLineItemContent"
              @click="previewFiles(file)"
            >
              <i
                :style="getColor(file.tipoArquivoImagem)"
                :class="getIcon(file.tipoArquivoImagem)"
              ></i>
              <div class="timeline__item timeline__item--normal">
                <div class="timeline-content">
                  <strong
                    style="color: #000"
                    :class="[
                      'timeline-content__date',
                      'large-fontsize',
                      file.ativo === 1 ? 'line-throughed' : ''
                    ]"
                    >{{ formatDate(file.dtCadastro, 'pt-BR') }}
                    {{ formatHour(file.dtOcorrencia) }} -
                    {{ user.nome }}</strong
                  >
                  <p
                    style="color: #000"
                    :class="[
                      'timeline-content__text',
                      'medium-fontsize',
                      file.ativo === 1 ? 'line-throughed' : ''
                    ]"
                  >
                    {{ file.descArquivoImagem }}
                  </p>
                </div>
              </div>
            </div>
            <div class="buttonHover">
              <b-button
                class="button-hover"
                :variant="
                  file.ativo && file.ativo === 1 ? 'primary ' : 'danger'
                "
                @click="
                  file.ativo === 0
                    ? handlerRemoveOrInactivate(file, index)
                    : handleActivateFile(file)
                "
                >{{
                  file.ativo === 1
                    ? 'Ativar'
                    : file.ativo === 0 && !file.isNew
                    ? 'Inativar'
                    : 'Remover'
                }}</b-button
              >
            </div>

            <b-modal
              :id="`file_justification_${file.id}`"
              size="lg"
              title="Justificativa"
              header-bg-variant="primary"
              title-class="text-light"
              label-for="justify-input"
              ok-title="Salvar"
            >
              <b-form-group label-for="justify-input">
                <ValidationProvider
                  name="justify"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="justify-input"
                    maxlength="150"
                    v-model="justify"
                    :class="
                      errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                    "
                  ></b-form-textarea>
                  <div class="invalid-feedback" :v-if="justifyNotValid">
                    <span
                      >A justificativa deve conter no mínimo 5 digitos!</span
                    >
                  </div>
                </ValidationProvider>
              </b-form-group>

              <p>{{ justify.length }}/150</p>
              <template #modal-footer="">
                <b-button
                  size="sm"
                  variant="danger"
                  @click="hideJustifyModal(file.id)"
                >
                  Cancelar
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="handleDisableFile(file)"
                >
                  Salvar
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>
        <div class="moduleFooter">
          <div class="buttonsPosition">
            <b-button variant="success" @click="showAddFileModal = true"
              >Adicionar</b-button
            >
            <b-button
              :style="mobile === false ? '' : 'display: none;'"
              variant="primary"
              @click="handlerShowCompareModal"
              >Comparar imagens</b-button
            >
          </div>
        </div>
      </div>

      <div v-if="hasPreview && !loading" class="imageView">
        <div class="iconsPreview">
          <b-icon
            icon="cloud-download"
            class="module__icon iconsPreview__icon"
            font-scale="2"
            variant="light"
            style="cursor: pointer"
            @click="downloadFile"
          />
          <b-icon
            icon="arrows-move"
            class="module__icon iconsPreview__icon"
            font-scale="2"
            variant="light"
            style="cursor: pointer"
            @click="showPreviewBiggerModal = true"
          />
          <b-icon
            icon="x"
            class="module__icon iconsPreview__icon"
            font-scale="2"
            variant="light"
            style="cursor: pointer"
            @click="hasPreview = false"
          />
        </div>
        <b-img
          v-if="preview === 'image'"
          src=""
          id="previewFile"
          alt="previsualização da imagem"
        />
        <b-embed v-if="preview === 'pdf'" aspect="1by1" src="" id="previewFile">
        </b-embed>
      </div>
      <div v-else class="imageView" style="align-items: center">
        <b-spinner
          v-if="loading"
          class="alignCenter"
          big
          variant="primary"
        ></b-spinner>
      </div>
      <CompareFilesModal
        v-if="showCompareModal"
        :listFilesToCompare="listFilesToCompare"
        :componentInfo="componentInfo"
        @closeModal="closeModal"
      />
      <AddFileModal
        v-if="showAddFileModal"
        @addFile="addFile"
        @closeModal="closeModal"
      />
      <PreviewBiggerModal
        v-if="showPreviewBiggerModal"
        :file="filePreview"
        @closeModal="closeModal"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import CompareFilesModal from './components/CompareFilesModal.vue'
import AddFileModal from './components/AddFileModal.vue'
import PreviewBiggerModal from './components/PreviewBiggerModal.vue'

import API from '../../../../services/index'
import { mapGetters } from 'vuex'

export default {
  name: 'FilesAndImages',
  props: ['componentInfo', 'idProntuario', 'idPaciente', 'idMedico'],
  components: { CompareFilesModal, AddFileModal, PreviewBiggerModal },
  data() {
    return {
      justify: '',
      image: '',
      filesList: [],
      hasPreview: false,
      filter: '',
      preview: '',
      icons: {
        doc: {
          class: 'fas fa-file-word fa-2x',
          style: 'color: blue'
        },
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          {
            class: 'fas fa-file-word fa-2x',
            style: 'color: blue'
          },
        image: {
          class: 'far fa-file-image fa-2x',
          style: 'color: #bd8f68'
        },
        pdf: {
          class: 'far fa-file-pdf fa-2x',
          style: 'color: red'
        }
      },
      listFilesToCompare: [],
      showCompareModal: false,
      showAddFileModal: false,
      showPreviewBiggerModal: false,
      filePreview: '',
      loading: '',
      showInactiveFiles: false,
      mobile: false
    }
  },
  mounted() {
    if (this.isMobileDevice() === false) {
      this.mobile = false
    } else {
      this.mobile = true
    }
  },
  created() {
    this.getFilesAlreadyExists()
  },
  beforeDestroy() {
    this.filesList = []
    this.filesListToShow = []
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    justifyNotValid() {
      return this.justify.length < 5
    },
    filesListToShow() {
      return this.filesList.filter((file) => {
        if (!this.showInactiveFiles) {
          return (
            file.descArquivoImagem.includes(this.filter.toLowerCase()) &&
            file.ativo === 0
          )
        } else return file.descArquivoImagem.includes(this.filter.toLowerCase())
      })
    }
  },
  watch: {
    listFilesToCompare: function (val) {
      if (val.length === 3)
        this.$toast.info('Você selecionou o máximo de imagens para comparar!')
    }
  },
  methods: {
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    getFilesAlreadyExists() {
      API.get(
        `/api/ProntuarioArquivoImagem/ObtemDocumentoPorPaciente/?id_paciente=${this.idPaciente}&id_medico=${this.idMedico}`
      ).then((res) => {
        this.filesList = res.data.object
      })
    },
    getIcon(type) {
      return this.icons[type].class
    },
    getColor(type) {
      return this.icons[type].style
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    addFile(object) {
      const idTemp = Math.random()
      const dataId = idTemp

      const payload = {
        ...object,
        dtCadastro: this.formatDate(new Date(), 'en-CA'),
        dtOcorrencia: this.formatDateHour(),
        idProntuario: this.idProntuario,
        id: dataId,
        isNew: true
      }
      this.$store.commit({ type: 'MedicalReports/addLocalFile', payload })
      this.filesList.unshift({
        ...object,
        dtCadastro: this.formatDate(new Date(), 'en-CA'),
        dtOcorrencia: this.formatDateHour(),
        id: dataId,
        isNew: true,
        ativo: 0
      })
    },
    createFileReader(file) {
      try {
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          this.preview = 'image'
        } else if (file.type === 'application/pdf') {
          this.preview = 'pdf'
        } else {
          this.downloadFile()
          return
        }

        const reader = new FileReader()
        reader.onload = (e) => {
          const blob = new Blob([e.target.result], { type: file.type })
          const url = URL.createObjectURL(blob)
          document.getElementById('previewFile').setAttribute('src', url)
        }
        reader.onerror = (error) => {
          this.$toast.error('Erro ao carregar o arquivo')
        }
        reader.readAsArrayBuffer(file)
      } catch (error) {
        this.$toast.error('Erro ao carregar o arquivo')
      }
    },
    previewFiles(file) {
      this.loading = true
      if (file.file) {
        this.createFileReader(file.file)
        this.filePreview = file
        this.loading = false
      } else {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          API.get(
            `/api/ProntuarioArquivoImagem/AwsS3ObtemArquivo/?arquivoname=${file.descAwsArquivoImagem}&prontuario_id=${file.idProntuario}`,
            { responseType: 'blob' }
          ).then((res) => {
            this.createFileReader(res.data)
            this.filePreview = {
              file: res.data,
              descAwsArquivoImagem: file.descAwsArquivoImagem,
              descArquivoImagem: file.descArquivoImagem
            }
            this.loading = false
          })
        }, 1000)
      }
      this.hasPreview = true
    },
    addFileToCompare(file) {
      this.listFilesToCompare.push(file)
    },
    handlerShowCompareModal() {
      if (this.listFilesToCompare.length < 2)
        return this.$toast.info(
          'Selecione no mínimo duas imagens para serem comparadas'
        )
      if (this.listFilesToCompare.length > 3)
        return this.$toast.error(
          'Selecione no máximo três imagens para serem comparadas'
        )
      this.showCompareModal = true
    },
    formatHour(date) {
      const formattedDate = new Date(date)
      return moment(formattedDate).format('HH:mm')
    },
    formatDateHour(date) {
      const dateInstance = date ? new Date(date) : new Date()

      const dateToFormat = dateInstance.toLocaleDateString()
      const hour = dateInstance.toLocaleTimeString()

      const formattedDate = dateToFormat
        .replaceAll('/', '-')
        .split('-')
        .reverse()
        .join('-')

      return `${formattedDate}T${hour}`
    },
    formatName(name) {
      const separateNames = name ? name.toLowerCase().split(' ') : undefined
      if (typeof separateNames === 'object') {
        const formatedNames = separateNames.map((element) => {
          const striped = element[0].toUpperCase() + element.substring(1)
          return striped
        })

        return formatedNames.join(' ')
      } else {
        return ''
      }
    },
    closeModal(modal) {
      modal === 'add-file'
        ? (this.showAddFileModal = false)
        : modal === 'compare-images'
        ? (this.showCompareModal = false)
        : (this.showPreviewBiggerModal = false)
    },
    downloadFile() {
      const link = document.createElement('a')
      const url = window.URL.createObjectURL(this.filePreview.file)
      link.setAttribute('href', url)
      link.setAttribute('download', this.filePreview.descAwsArquivoImagem)
      document.body.appendChild(link)
      link.click()
      link.remove()
    },

    handlerListFilesToCompare() {
      if (this.listFilesToCompare.length > 3) {
        this.filesListToCompare.pop()
        this.$toast.info('Só é possivel selecionar três imagens para comparar!')
      }
    },
    handlerRemoveOrInactivate(file, index) {
      if (file.isNew) {
        this.$store.commit({
          type: 'MedicalReports/removeLocalFile',
          id: file.id
        })
        this.filesList.splice(index, 1)
      } else {
        this.showJustifyModal(file.id)
      }
    },

    handleDisableFile(file) {
      const fileIndex = this.filesList.findIndex(
        (fileOnList) => fileOnList.id === file.id
      )
      this.filesList[fileIndex].ativo = 1

      this.$store.commit({
        type: 'MedicalReports/addLocalFile',
        payload: {
          ...file,
          justificativaInativacao: this.justify,
          isEdit: true
        }
      })

      this.hideJustifyModal(file.id)
    },

    handleActivateFile(file) {
      const fileIndex = this.filesList.findIndex(
        (fileOnList) => fileOnList.id === file.id
      )
      this.filesList[fileIndex].ativo = 0

      this.$store.commit({
        type: 'MedicalReports/addLocalFile',
        payload: {
          ...file,
          justificativaInativacao: this.justify,
          isEdit: true
        }
      })

      this.hideJustifyModal(file.id)
    },

    showJustifyModal(fileId) {
      this.$bvModal.show(`file_justification_${fileId}`)
    },
    hideJustifyModal(fileId) {
      this.justify = ''
      this.$bvModal.hide(`form_justification_${fileId}`)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';
#inputFile {
  display: none;
}

.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}

.line-throughed {
  text-decoration: line-through;
}

.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 80%;
}

.lineHeight {
  line-height: 1.5 !important;
}

.module {
  margin: 32px 0;

  &__icon {
    margin-left: 3px;
    align-self: center;
  }
}
.details {
  display: flex;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__checkbox-container {
    margin-left: 8px;
    margin-right: auto;
    color: #000;

    display: inline-flex;
    align-items: center;
  }

  &__input {
    &::placeholder {
      text-transform: none;
      color: #000 !important;
    }
    border-color: rgb(8, 155, 171) !important;

    background-color: white !important;
  }
}

.content {
  margin-top: 5px;
  background: #fff;
  border-radius: 18px;

  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.timeLineFiles {
  position: relative;
}

.timeLineItem {
  min-width: 500px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.buttonHover {
  display: none;
}

.timeLineItem:hover .buttonHover {
  display: flex;
  align-items: center;
}

.timeLineItemContent {
  width: 100%;
  display: flex;
  i {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.timeLineItemContent:hover {
  cursor: pointer;
}
.timeline {
  background-color: white;
  height: 33vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 8px 0;
  border-bottom-right-radius: 16px;
  display: grid;
  grid-auto-rows: 70px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;

  &__item {
    cursor: pointer;
    border-left: 2px solid #ccc;

    padding-left: 15px;
    padding-bottom: 30px;

    position: relative;

    &:first-of-type {
      padding-top: 0px;
    }

    &::before {
      content: '';
      left: -6px;
      top: 7px;

      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
    }

    &:first-of-type::before {
      top: 7px;
    }

    &--normal::before {
      background-color: #089bab;
    }
  }

  &-content {
    &__date {
      font-weight: bold;
      font-size: 0.8rem;
      color: #000;
      font-family: sans-serif;
    }

    &__text {
      font-size: 0.8rem;
      margin: 0;
      margin-top: 10px;
      padding: 0;
      padding-bottom: 10px;
      max-width: 400px;
      word-break: break-word;
      white-space: pre-line;
      overflow-wrap: break-word;
      -ms-word-break: break-word;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }
}

.moduleFooter {
  position: absolute;
  bottom: 0;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;

  gap: 10px;
}

.buttonsPosition {
  display: flex;
  gap: 15px;
}

.imageView {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.iconsPreview {
  display: none;
}

.imageView:hover .iconsPreview {
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  top: 10px;
  right: 10px;
  &__icon {
    background: #000;
    padding: 1px;
  }
}

img {
  max-height: 100%;
  max-width: 100%;
  display: block;
}

@media (min-width: 1444px) {
  .timeline,
  .imageView {
    height: 45vh;
  }
}

@media (max-width: 1440px) {
  .timeline,
  .imageView {
    height: 45vh;
  }
}

@media (max-width: 1065px) {
  .timeline,
  .imageView {
    height: 40vh;
  }
  .timeLineItem {
    min-width: 400px;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
}

@media (max-width: 860px) {
  .timeline,
  .imageView {
    height: 30vh;
  }
  .timeLineItem {
    max-height: 80px;
    min-width: 500px;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  .content {
    margin-top: 5px;
    background: #fff;
    border-radius: 18px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .timeLineItemContent {
    width: 60%;
    display: flex;
    i {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
  .timeline {
    &-content {
      &__date {
        font-size: 0.7rem;
      }

      &__text {
        font-size: 0.6rem;
      }
    }
  }
  .fa-2x {
    font-size: 1.4em;
  }
}

@media (max-width: 500px) {
  .timeLineItem {
    max-height: 80px;
    min-width: 400px;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  .timeline,
  .imageView {
    height: 20vh;
  }
  .content {
    margin-top: 5px;
    background: #fff;
    border-radius: 18px;

    display: flex;
    flex-direction: column;
  }
  .timeLineItemContent {
    width: 50%;
    display: flex;
    i {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
</style>
