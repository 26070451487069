import API from '../../services/index'

const actions = {
  setValueInTypeGraph({ state }, payload) {
    if (payload === 'T') {
      state.isGraphORTable = false
    } else if (payload === 'G') {
      state.isGraphORTable = true
    } else {
      state.isGraphORTable = true
    }
  },
  setValueInTypeGraphExpense({ state }, payload) {
    if (payload === 'T') {
      state.isGraphORTableExpense = false
    } else if (payload === 'G') {
      state.isGraphORTableExpense = true
    } else {
      state.isGraphORTableExpense = true
    }
  },

  async setNewRevenue({ commit }, payload) {
    try {
      await API.post('/api/TituloReceber/Salvar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          ...payload
        }
      })
      return true
    } catch (err) {
      return false
    }
  },

  async setNewExpenses({ commit }, payload) {
    try {
      await API.post('/api/TituloPagar/Salvar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          ...payload
        }
      })
      return true
    } catch (err) {
      return false
    }
  },

  async setNewSuppliers({ commit }, payload) {
    try {
      const response = await API.post('api/Fornecedor/Adicionar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          idClinica: payload.idClinica,
          descFornecedor: payload.descFornecedor,
          ativo: 0,
          dtInclusao: payload.dtInclusao
        }
      })
      return response.data.object
    } catch (err) {
      return false
    }
  },

  async setNewCentCost({ commit }, payload) {
    try {
      const response = await API.post('api/CentroCusto/Adicionar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          idClinica: payload.idClinica,
          descCentroCusto: payload.descCentroCusto,
          ativo: 0
        }
      })
      return response.data.object
    } catch (err) {
      return false
    }
  },

  async setNewTypePay({ commit }, payload) {
    try {
      const response = await API.post('api/TipoDespesa/Adicionar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          descDespesa: payload.descDespesa,
          idClinica: payload.idClinica
        }
      })
      return response.data.object
    } catch (err) {
      return false
    }
  },
  async setNewPayer({ commit }, payload) {
    try {
      const response = await API.post('api/PagadorFinanceiro/Adicionar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          cpfCnpj: payload.cpfCnpj,
          nomePagadorFinanceiro: payload.nomePagadorFinanceiro,
          idClinica: payload.idClinica
        }
      })
      return response.data.object
    } catch (err) {
      return false
    }
  },

  async setNewType({ commit }, payload) {
    try {
      const response = await API.post('api/TipoRecebimento/Adicionar', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          idClinica: payload.idClinica,
          descTipoRecebimento: payload.descTipoRecebimento
        }
      })
      return response.data.object
    } catch (err) {
      return false
    }
  },

  async getSuppliers({ commit }, payload) {
    try {
      const response = await API.get('/api/Fornecedor/ObtemListaPorClinica', {
        params: {
          id_clinica: payload.id_clinica
        }
      })
      return response.data.object
    } catch (err) {
      return []
    }
  },

  async getPayers({ commit }, payload) {
    try {
      const response = await API.get(
        '/api/PagadorFinanceiro/ObtemPagadoresClinica',
        {
          params: {
            id_clinica: payload.id_clinica
          }
        }
      )
      return response.data.object
    } catch (err) {
      return []
    }
  },

  async getTypes({ commit }, payload) {
    try {
      const response = await API.get(
        'api/TipoRecebimento/ObtemTipoRecebimentoPorClinica',
        {
          params: {
            id_clinica: payload.id_clinica
          }
        }
      )
      return response.data.object
    } catch (err) {
      return []
    }
  },

  async getCenterCost({ commit }, payload) {
    try {
      const response = await API.get('api/CentroCusto/ObtemListaPorClinica', {
        params: {
          id_clinica: payload.id_clinica
        }
      })
      return response.data.object
    } catch (err) {
      return []
    }
  },

  async getTypesExpenses({ commit }, payload) {
    try {
      const response = await API.get(
        'api/TipoDespesa/ObtemTipoDespesaPorClinica',
        {
          params: {
            id_clinica: payload.id_clinica
          }
        }
      )
      return response.data.object
    } catch (err) {
      return []
    }
  },

  async getDataGraphOne({ state }, payload) {
    try {
      const response = await API.get(
        'api/Financeiro/ObtemListaValoresPorReferenciaClinica',
        {
          params: {
            dt_inicio: payload.dt_inicio,
            dt_final: payload.dt_final,
            id_clinica: payload.id_clinica
          }
        }
      )
      state.dataGraphOne = response.data.object
    } catch (err) {
      state.dataGraphOne = []
    }
  },

  async getDataGraphTwo({ state }, payload) {
    try {
      const response = await API.get(
        'api/Financeiro/ObtemListaValoresPorReferenciaTipo',
        {
          params: {
            dt_inicio: payload.dt_inicio,
            dt_final: payload.dt_final,
            id_clinica: payload.id_clinica,
            id_usuario: payload.id_usuario,
            tipo: payload.tipo
          }
        }
      )
      const data = response.data.object

      if (
        !data ||
        data.length === 0 ||
        data.every((item) => item.valor === 0)
      ) {
        this.$toast.toast(
          'Pesquisa não retornou resultado, reveja os parâmetros de busca.'
        )
        state.dataGraphTwo = []
        return false
      } else {
        state.dataGraphTwo = data
        return true
      }
    } catch (err) {
      state.dataGraphTwo = []
      return false
    }
  },

  async getDataGraphTree({ state }, payload) {
    try {
      const response = await API.get(
        'api/Financeiro/ObtemListaValoresPorReferenciaTipo',
        {
          params: {
            dt_inicio: payload.dt_inicio,
            dt_final: payload.dt_final,
            id_clinica: payload.id_clinica,
            id_usuario: payload.id_usuario,
            tipo: payload.tipo
          }
        }
      )
      const data = response.data.object
      if (
        !data ||
        data.length === 0 ||
        data.every((item) => item.valor === 0)
      ) {
        this.$toast.toast(
          'Pesquisa não retornou resultado, reveja os parâmetros de busca.'
        )
        state.dataGraphTree = []
        return false
      } else {
        state.dataGraphTree = data
        return true
      }
    } catch (err) {
      state.dataGraphTree = []
      return false
    }
  },

  async getStatusFinance() {
    try {
      const response = await API.get(
        'api/StatusFinanceiro/ObtemListaStatusFinanceiro'
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },

  async getUsersForClinics({ commit }, payload) {
    try {
      const response = await API.get(
        `api/UsuarioClinica/ObtemUsuarioPorClinica/${payload.clinic}`,
        payload.authToken
      )
      const doctors = response.data.object.filter(
        (user) =>
          user.perfil.id === 1 || user.perfil.id === 4 || user.perfil.id === 5
      )
      return doctors
    } catch (err) {
      return err
    }
  },

  setDatesGraphTwo({ state }, payload) {
    state.dateGraphTwoInit = payload.dateInit
    state.dateGraphTwoFinaly = payload.dateFinaly
  },

  setDatesGraphTree({ state }, payload) {
    state.dateGraphTreeInit = payload.dateInit
    state.dateGraphTreeFinaly = payload.dateFinaly
  },

  async getDataTablesRevenue({ commit }, payload) {
    try {
      const response = await API.post(
        '/api/TituloReceber/ObtemListaReceitaMovimentacao',
        {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload
          }
        }
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },

  async getDataTablesExpense({ commit }, payload) {
    try {
      const response = await API.post(
        '/api/TituloPagar/ObtemListaDespesaMovimentacao',
        {
          appName: 'Doctor PEP',
          appVersion: '1.0.0',
          object: {
            ...payload
          }
        }
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },

  setDoctorGraphTwo({ state }, payload) {
    state.doctorGraphTwo = payload.doctor
  },
  setDoctorGraphTree({ state }, payload) {
    state.doctorGraphTree = payload.doctor
  },

  setClinicGraphTwo({ state }, payload) {
    state.clinicGraphTwo = payload.clinic
  },
  setClinicGraphTree({ state }, payload) {
    state.clinicGraphTree = payload.clinic
  },
  async setAlterInBlock({ state }, payload) {
    const list = payload.list
    try {
      await API.post('/api/TituloReceber/AlteracaoBloco', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          idReferenciaRecebedor: payload.idReferenciaRecebedor,
          idTipoRecebedor: payload.idTipoRecebedor,
          idStatusFinanceiro: payload.idStatusFinanceiro,
          itensAlterados: [...list]
        }
      })
      return true
    } catch (err) {
      return false
    }
  },

  async setAlterInBlockExpense({ state }, payload) {
    const list = payload.list
    try {
      await API.post('/api/TituloPagar/AlteracaoBloco', {
        appName: 'Doctor PEP',
        appVersion: '1.0.0',
        object: {
          idCentroCusto: payload.IdCentroCusto,
          tipoPagador: payload.idTipoRecebedor,
          idPagador: payload.idReferenciaRecebedor,
          idStatusFinanceiro: payload.idStatusFinanceiro,
          itensAlterados: [...list]
        }
      })
      return true
    } catch (err) {
      return false
    }
  },

  async getDataForPDF({ commit }, payload) {
    try {
      const response = await API.get(
        '/api/TituloReceber/ObtemListaReceitaMovimentacao',
        {
          params: {
            id_clinica: payload.id_clinica,
            id_usuario: payload.id_usuario,
            id_status: payload.id_status,
            verifica_data: payload.verifica_data,
            dt_inicio: payload.dt_inicio,
            dt_final: payload.dt_final
          }
        }
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },

  async getDataForPDFExpenses({ commit }, payload) {
    try {
      const response = await API.get(
        '/api/TituloPagar/ObtemListaDespesaRelatorio',
        {
          params: {
            id_clinica: payload.id_clinica,
            id_usuario: payload.id_usuario,
            id_status: payload.id_status,
            verifica_data: payload.verifica_data,
            dt_inicio: payload.dt_inicio,
            id_fornecedor: payload.id_fornecedor,
            dt_final: payload.dt_final
          }
        }
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },

  async getDataForConvenioPDF({ commit }, payload) {
    try {
      const response = await API.get(
        'api/TituloReceber/ObtemListaReceitaPorConvênioRelatorio',
        {
          params: {
            id_clinica: payload.id_clinica,
            id_usuario: payload.id_usuario,
            id_convenio: payload.id_convenio,
            verifica_data: payload.verifica_data,
            dt_inicio: payload.dt_inicio,
            dt_final: payload.dt_final
          }
        }
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },
  async getDataConveniosOfDoctor({ commit }, payload) {
    try {
      const response = await API.get(
        `/api/UsuarioConvenio/ObtemItemConvenioPorUsuario/${payload}`
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },

  handleOpenModalAlterBlock({ state }, payload) {
    state.openModalAlter = payload
  },
  handleOpenModalAlterBlockE({ state }, payload) {
    state.openModalAlterE = payload
  }
}

export default actions
