<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        vid="council"
        name="Council"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group2">
          <label for="council">Conselho:</label>
          <select
            v-model="council"
            name="council"
            ref="council"
            id="council"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
          >
            <option
              v-for="council in councils"
              :key="council.id"
              v-bind:value="council.id"
            >
              {{ council.descConselho }}
            </option>
          </select>
          <div class="invalid-feedback">
            <span>Informe o conselho</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="sameLevel">
        <ValidationProvider
          vid="numCouncil"
          name="Council"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group2">
            <label for="council">Numero conselho:</label>
            <input
              type="number"
              v-model="numCouncil"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="numCouncil"
              aria-describedby="councilNumber"
            />
            <div class="invalid-feedback">
              <span>Informe um número do conselho</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          vid="councilState"
          name="CouncilState"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group2">
            <label for="councilState">Estado do Conselho:</label>
            <select
              v-model="councilState"
              name="councilState"
              id="councilState"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
            >
              <option
                v-for="state in states"
                :key="state.id"
                v-bind:value="state.id"
              >
                {{ state.descEstado }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span>Informe um estado</span>
            </div>
          </div>
        </ValidationProvider>
      </div>
      <ValidationProvider
        vid="especialidade"
        name="Especialidade"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group2">
          <label for="especialidades">Especialidade:</label>
          <v-select
            v-model="especialidade"
            :options="especialidades"
            :reduce="(descCbo) => descCbo.id"
            label="descCbo"
            :class="errors.length > 0 ? ' is-invalid' : ''"
          ></v-select>

          <div class="invalid-feedback">
            <span>Informe uma especialidade</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="login"
        name="Login"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group2">
          <label for="login">Login:</label>
          <input
            type="login"
            v-uppercase
            v-model="login"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="login"
          />
          <div class="invalid-feedback">
            <span>Informe o login</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="sameLevel">
        <div class="form-group2">
          <label for="password">Senha:</label>
          <span
            id="tooltip"
            target="icon"
            v-b-tooltip.hover.focus.v-info
            :title="tooltipText"
          >
            <i class="fas fa-info-circle" id="icon"></i>
          </span>
          <div class="sameLevelWithIcon">
            <input
              class="form-control mb-0"
              @click="activeOrietetion()"
              @focus="openTooltip"
              @focusout="closeTooltip"
              :type="passwordFieldType ? 'password' : 'text'"
              v-model="password"
              :style="
                active
                  ? 'outline: none; border: 1px solid red; text-transform: none !important'
                  : 'text-transform: none !important'
              "
              placeholder="Digite sua senha..."
            />
            <i
              :class="passwordFieldType ? 'fas fa-eye' : 'fas fa-eye-slash'"
              @click="passwordFieldType = !passwordFieldType"
            ></i>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
              "
            >
              <span
                v-if="active"
                style="color: red; font-size: 0.7rem; margin-top: 2px"
              >
                {{ errorMensage }}
              </span>

              <div
                v-if="isRule"
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  align-items: flex-start;
                "
              >
                <span style="font-size: 0.7rem; margin-top: 2px"
                  >A senha precisa conter:</span
                >
                <div
                  style="
                    display: flex;
                    font-size: 0.7rem;
                    flex-direction: column;
                    gap: 2px;
                    align-items: flex-start;
                  "
                >
                  <span
                    :style="
                      rule1
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >8 digitos</span
                  >
                  <span
                    :style="
                      rule2
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >1 caractere especial (como !,@,#, etc.)</span
                  >
                  <span
                    :style="
                      rule3
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >1 letra maíuscula</span
                  >
                  <span
                    :style="
                      rule4
                        ? 'text-decoration: line-through; color: black'
                        : 'color: red'
                    "
                    >1 numero</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group2">
          <label for="confirmPassword">Confirme a senha:</label>
          <div class="sameLevelWithIcon">
            <input
              class="form-control mb-0"
              :type="confirmPasswordFieldType ? 'password' : 'text'"
              v-model="confirmPassword"
              :style="
                activeConfirm
                  ? 'outline: none; border: 1px solid red; text-transform: none !important'
                  : 'text-transform: none !important'
              "
              placeholder="Confirme a senha..."
            />
            <i
              :class="
                confirmPasswordFieldType ? 'fas fa-eye' : 'fas fa-eye-slash'
              "
              @click="confirmPasswordFieldType = !confirmPasswordFieldType"
            ></i>
            <span
              v-if="activeConfirm"
              style="color: red; font-size: 0.7rem; margin-top: 2px"
            >
              {{ errorMensageConfirm }}
            </span>
          </div>
        </div>
      </div>
      <ValidationProvider
        vid="checkbox"
        name="Checkbox"
        rules="required:true"
        v-slot="{ errors }"
      >
        <div
          class="custom-control custom-checkbox d-inline-block mt-2 mb-2 pt-1"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            v-model="accepted"
            id="checkbox"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
          />
          <label class="custom-control-label" for="checkbox"
            >Eu aceito os <a href="#">termos e condições</a></label
          >
          <div class="invalid-feedback">
            <span>Você precisa ler e aceitar os termos de compromisso</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <button @click="voltar" class="btn btn-primary float-left">
          Voltar
        </button>
        <button
          type="submit"
          class="btn btn-primary float-right"
          :disabled="loading"
          style="width: 110px"
        >
          <b-spinner v-if="loading" small label="Spinning"></b-spinner>
          <span v-else>Criar usuário</span>
        </button>
      </div>

      <div class="sign-info2">
        <span class="dark-color d-inline-block line-height-2">
          Já tem conta?
          <router-link
            to="/dark/auth/sign-in"
            class="iq-waves-effect pr-4"
            v-if="$route.meta.dark"
          >
            Login
          </router-link>
          <router-link to="/auth/sign-in" class="iq-waves-effect pr-4" v-else>
            Login
          </router-link>
        </span>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import API from '../../../services'
import { mapGetters } from 'vuex'
export default {
  name: 'SignUpForm',
  props: ['formType'],
  computed: {
    ...mapGetters({
      userDataForm: 'Setting/userDataFormState'
    }),
    numCouncil: {
      get() {
        return this.$store.state.numCouncil
      },
      set(value) {
        this.$store.commit('setNumCouncil', value)
      }
    },
    council: {
      get() {
        return this.$store.state.council
      },
      set(value) {
        this.$store.commit('setCouncil', value)
      }
    },
    especialidade: {
      get() {
        return this.$store.state.especialidade
      },
      set(value) {
        this.$store.commit('setEspecialidade', value)
      }
    },
    councilState: {
      get() {
        return this.$store.state.councilState
      },
      set(value) {
        this.$store.commit('setCouncilState', value)
      }
    },
    password: {
      get() {
        return this.$store.state.password
      },
      set(value) {
        this.$store.commit('setPassword', value)
      }
    },
    login: {
      get() {
        return this.$store.state.login
      },
      set(value) {
        this.$store.commit('setLogin', value)
      }
    }
  },
  data: () => ({
    tooltipText: `Sua senha deve conter minimamente 8 caracteres alfanuméricos, letras maiúsculas e minúsculas, números e símbolos. Exemplo: AtHErI10@`,
    councils: [],
    states: [],
    especialidades: [],
    confirmPassword: '',
    passwordFieldType: true,
    confirmPasswordFieldType: true,
    accepted: '',
    loading: false,
    active: false,
    activeConfirm: false,
    errorMensageConfirm: 'Senhas diferentes!',
    errorMensage: 'Digite uma senha!!',
    isRule: false,
    rule1: false,
    rule2: false,
    rule3: false,
    rule4: false
  }),

  watch: {
    confirmPassword(newValue) {
      if (newValue === this.password) {
        this.activeConfirm = false
      } else {
        this.activeConfirm = true
      }
    },
    password(value) {
      this.isRule = true
      const regex = /[^a-zA-Z0-9\s]/
      const regxUpCase = /[A-Z]/
      const regxDigito = /\d/
      if (value === '' || value === null) {
        this.active = true
        this.errorMensage = 'Digite uma senha!'
      } else {
        this.active = false
      }
      if (value.length >= 8) {
        this.rule1 = true
      } else {
        this.rule1 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regex.test(value)) {
        this.rule2 = true
        this.active = false
      } else {
        this.rule2 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regxUpCase.test(value)) {
        this.rule3 = true
      } else {
        this.rule3 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regxDigito.test(value)) {
        this.rule4 = true
      } else {
        this.rule4 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (
        regxDigito.test(value) &&
        regxUpCase.test(value) &&
        regex.test(value) &&
        value !== '' &&
        value.length >= 8
      ) {
        this.isRule = false
      }
    }
  },
  created() {
    API.get('/api/Sexo/ObtemLista').then((res) => {
      this.genders = res.data.object
    })

    API.get('/api/ConselhoProfissional/ObtemLista').then((res) => {
      this.councils = res.data.object
    })

    API.get('/api/Estado/ObtemLista').then((res) => {
      this.states = res.data.object
    })

    API.get('/api/Cbo/ObtemListaSaude/0?saude=S').then((res) => {
      this.especialidades = res.data.object.sort()
    })
  },
  mounted() {
    this.$refs.council.focus()
  },
  methods: {
    activeOrietetion() {
      const regex = /[^a-zA-Z0-9\s]/
      const regxUpCase = /[A-Z]/
      const regxDigito = /\d/
      if (
        regxDigito.test(this.password) &&
        regxUpCase.test(this.password) &&
        regex.test(this.password) &&
        this.password !== '' &&
        this.password !== null &&
        this.password.length >= 8
      ) {
        this.isRule = false
      } else {
        this.isRule = true
      }
    },
    voltar() {
      this.$router.replace('/auth/sign-up/page-form1')
    },
    dataForm() {
      return {
        council: this.council,
        numCouncil: this.numCouncil,
        councilState: this.councilState,
        especialidade: this.especialidade,
        login: this.login,
        password: this.password
      }
    },
    openTooltip() {
      this.$root.$emit('bv::show::tooltip', 'tooltip')
    },
    closeTooltip() {
      this.$root.$emit('bv::hide::tooltip', 'tooltip')
    },
    onSubmit() {
      this.loading = true
      if (this.active && this.activeConfirm) {
        this.active = true
        this.activeConfirm = true
        this.$toast.error('Por favor verifique os campos!')
      } else {
        this.$store.dispatch('Setting/addUserDataFormAction', this.dataForm())
        this.jwtRegister()
      }
    },
    jwtRegister() {
      this.$store
        .dispatch('Setting/addUserAction', this.userDataForm)
        .then((userData) => {
          this.$toast.success('Cadastro realizado com sucesso!')
          this.$router.replace('/auth/confirm-mail')
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.sameLevel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5%;
}

.sameLevelWithIcon {
  display: grid;
  grid-template-columns: 6.5fr 0.5fr;
  gap: 2%;
  justify-content: center;
  align-items: center;
}

.tooltip {
  opacity: 1 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
