<template>
  <div class="module">
    <div class="details">
      <div
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </div>
      <div class="control-box">
        <div class="details__checkbox-container">
          <b-form-checkbox
            v-model="showInactivePrescriptions"
            :value="true"
            :unchecked-value="false"
          ></b-form-checkbox>
          <span>Exibir inativos</span>
        </div>
        <button
          :class="
            showMedicineUse ? 'filterMedicineUse-active' : 'filterMedicineUse'
          "
          @click="showMedicineUse = !showMedicineUse"
        >
          Medicamento Em Uso
        </button>
      </div>

      <div class="control-box">
        <b-button
          class="mr-2"
          @click="openPrescriptionScript"
          :disabled="!prescriptionCanOpen"
          variant="success"
        >
          <span v-if="prescriptionCanOpen">Nova prescrição</span>
          <b-spinner
            v-if="!prescriptionCanOpen"
            variant="light"
            small
          ></b-spinner>
        </b-button>
        <b-icon
          icon="chevron-expand"
          class="module__icon"
          font-scale="2"
          variant="primary"
          style="cursor: pointer"
          @click="toggleExpanded()"
        />

        <b-icon
          icon="three-dots-vertical"
          class="module__icon"
          font-scale="2"
          variant="primary"
          style="cursor: pointer"
        />
      </div>
    </div>

    <div id="content" class="content" v-if="componentInfo.aberto === 0">
      <prescriptions-list
        :headerColor="componentInfo.corHexadecimal"
        :showInactivePrescriptions="showInactivePrescriptions"
        :prescriptions="componentInfo.data"
        :toggleMedicineUse="showMedicineUse"
        :idProntuario="idProntuario"
        :token="handbookInfo.tokenMemed"
      />
    </div>
  </div>
</template>

<script>
import PrescriptionsList from './components/prescriptionsList'
import { mapActions, mapGetters } from 'vuex'
import { openPrescriptionService } from '../../prescriptionsService'
export default {
  name: 'MemedIntegration',
  components: {
    PrescriptionsList
  },
  props: [
    'patientInfo',
    'componentInfo',
    'handbookInfo',
    'idProntuario',
    'idPaciente',
    'idMedico'
  ],
  data() {
    return {
      showMedicineUse: false,
      showInactivePrescriptions: false
    }
  },
  computed: {
    ...mapGetters({
      medicalReportType: 'MedicalReports/getMedicalReportType',
      prescriptionIsOpened: 'MedicalReports/getPrescriptionsIsOpened',
      prescriptionCanOpen: 'MedicalReports/getPrescriptionsCanOpen'
    })
  },
  watch: {
    prescriptionIsOpened(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.scriptClosed()
      }
    }
  },
  methods: {
    ...mapActions({
      getPrescriptionHistoryAction:
        'MedicalReports/getPrescriptionHistoryAction',
      getExamsHistoryAction: 'MedicalReports/getExamsHistoryAction'
    }),
    openPrescriptionScript() {
      openPrescriptionService()
    },
    scriptClosed() {
      const payload = {
        idMedic: this.idMedico,
        idPatient: this.idPaciente,
        idClinic: this.handbookInfo.idClinica,
        handbookId: this.handbookInfo.id
      }

      this.getPrescriptionHistoryAction(payload)
      this.getExamsHistoryAction(payload)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../shared/generalModuleStyles.scss';

.control-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filterMedicineUse {
  border-radius: 10px;
  border: 1px solid #089bab;
  padding: 6px 12px;
  color: #089bab;
  background-color: #fff;
}

.filterMedicineUse-active {
  border-radius: 10px;
  border: 1px solid #089bab;
  padding: 6px 12px;
  color: #fff;
  background-color: #089bab;
}

#content {
  margin-top: 20px;
}
.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}

.content {
  background: #fff;
}
</style>
