<template>
  <div class="module">
    <div class="details">
      <div
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </div>
      <div class="details__checkbox-container">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="showInactiveASOs"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>
      <div class="box-options-aso">
        <v-select
          style="width: 160px"
          :clearable="false"
          :options="tabledOptions"
          v-model="tableOption"
        />

        <b-button class="mr-2" @click="openAsoForm" variant="success">
          <span>Novo</span>
        </b-button>
      </div>

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>

    <div class="content" v-if="componentInfo.aberto === 0">
      <aso-list
        v-show="tableOption === 'ASO'"
        :headerColor="componentInfo.corHexadecimal"
        :ASOs="componentInfo.data"
        :showInactiveASOs="showInactiveASOs"
        :componentInfo="componentInfo"
        :medicOpinions="medicOpinions"
        :licenceTypes="licenceTypes"
        :handbookId="handbookInfo.id"
        @setAsoToEdit="
          (aso) => {
            asoToEdit = aso.id
            asoFormIsOpened = true
          }
        "
        @setAsoToPrint="
          (aso) => {
            asoToPrint = aso.id
            asoPdfGeneratorIsOpened = true
          }
        "
      />
      <visualAcuityList
        v-show="tableOption === 'Acuidade Visual'"
        :headerColor="'#089bab'"
        :acuity="acuityComponent"
        :showInactive="showInactiveASOs"
        :medicOpinions="medicOpinions"
        :licenceTypes="licenceTypes"
        :handbookId="handbookInfo.id"
        @savedAcuity="handleRefreshAcuity"
        @setAcuityToEdit="
          (acuity) => {
            acuityToEdit = acuity.id
            asoFormIsOpened = true
          }
        "
        @setAcuityToPrint="
          (acuity) => {
            acuityToPrint = acuity.id
            acuityPdfGeneratorIsOpened = true
          }
        "
      />
    </div>

    <aso-form
      v-if="asoFormIsOpened"
      @saved="handleRefreshASOs"
      @close="
        () => {
          asoFormIsOpened = false
          asoToEdit = ''
          acuityToEdit = ''
        }
      "
      :idClinic="handbookInfo.idClinica"
      :idHandbook="idProntuario"
      :idPatient="idPaciente"
      :medicOpinions="medicOpinions"
      :licenceTypes="licenceTypes"
      :asoToEdit="asoToEdit"
      :acuityToEdit="acuityToEdit"
      :options="tableOption"
    />

    <aso-pdf-generator
      v-if="asoPdfGeneratorIsOpened"
      :asoId="asoToPrint"
      :idClinic="handbookInfo.idClinica"
      @close="
        () => {
          asoPdfGeneratorIsOpened = false
          asoToPrint = ''
        }
      "
    />

    <acuityPdfGenerator
      v-if="acuityPdfGeneratorIsOpened"
      :acuityID="acuityToPrint"
      :idClinic="handbookInfo.idClinica"
      @close="
        () => {
          acuityPdfGeneratorIsOpened = false
          acuityToPrint = ''
        }
      "
    />
  </div>
</template>

<script>
import asoList from './components/asoList'
import visualAcuityList from './components/visualAcuityList.vue'
import asoForm from './components/asoForm'
import asoPdfGenerator from './components/asoPdfGenerator'
import acuityPdfGenerator from './components/acuityVisualPdfGenerator'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ASO',
  components: {
    asoList,
    asoForm,
    asoPdfGenerator,
    visualAcuityList,
    acuityPdfGenerator
  },
  props: [
    'patientInfo',
    'componentInfo',
    'handbookInfo',
    'idProntuario',
    'idPaciente',
    'idMedico'
  ],
  data() {
    return {
      tabledOptions: ['ASO', 'Acuidade Visual'],
      tableOption: 'ASO',
      toogle: true,
      asoToEdit: '',
      acuityToEdit: '',
      asoToPrint: '',
      acuityToPrint: '',
      asoFormIsOpened: false,
      acuityFormIsOpened: false,
      asoPdfGeneratorIsOpened: false,
      acuityPdfGeneratorIsOpened: false,
      showInactiveASOs: false,
      medicOpinions: [],
      licenceTypes: [],
      acuityComponent: []
    }
  },
  mounted() {
    this.handleGetMedicOpinion()
    this.handleGetlicenceCertificateTypes()
    this.handleAcuity()
  },
  computed: {
    ...mapGetters({
      medicalReportType: 'MedicalReports/getMedicalReportType'
    })
  },
  methods: {
    ...mapActions({
      getAcuityByPatient: 'Acuity/getAcuityByPatient',
      getMedicOpinionByClinic: 'ASO/getMedicOpinionByClinic',
      getlicenceCertificateTypes: 'ASO/getlicenceCertificateTypes',
      getASOsByPatient: 'ASO/getASOsByPatient'
    }),
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    },
    openAsoForm() {
      this.asoFormIsOpened = true
    },
    async handleGetMedicOpinion() {
      const response = await this.getMedicOpinionByClinic(
        this.handbookInfo.idClinica
      )

      this.medicOpinions = response
    },

    async handleGetlicenceCertificateTypes() {
      const response = await this.getlicenceCertificateTypes()
      this.licenceTypes = response
    },

    async handleAcuity() {
      const acuitys = await this.getAcuityByPatient({
        idPatient: this.idPaciente,
        idMedic: this.idMedico,
        idClinic: this.handbookInfo.idClinica
      })
      this.acuityComponent = acuitys
    },

    async handleRefreshASOs() {
      const asos = await this.getASOsByPatient({
        idPatient: this.idPaciente,
        idMedic: this.idMedico,
        idClinic: this.handbookInfo.idClinica
      })

      this.$store.commit({
        type: 'MedicalReports/loadASOs',
        payload: asos
      })

      this.handleAcuity()
    },
    handleRefreshAcuity() {
      this.handleAcuity()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../shared/generalModuleStyles.scss';

.box-options-aso {
  display: flex;
  gap: 10px;
  align-items: center;
}

.aso-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aso-button {
  font-weight: 600;
  font-size: 0.9rem;
  background-color: transparent;
  color: #089bab;
  border: none;
}
.aso-button-disabled {
  font-weight: 600;
  font-size: 0.9rem;
  background-color: transparent;
  color: #b7b9b9;
  border: none;
}

.divider {
  width: 1px;
  height: 21px;
  background-color: #b7b9b9;
}

.details {
  display: flex;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}

.content {
  background: #fff;
}
</style>
