const FORWARDINGS_MODULE_ID = 13

const mutations = {
  loadForwardings(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORWARDINGS_MODULE_ID
    )

    module.data = payload
  },

  addForwarding(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORWARDINGS_MODULE_ID
    )

    module.data.push({ ...payload, isNew: true })
  },

  removeForwarding(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORWARDINGS_MODULE_ID
    )

    const formIndex = module.data.findIndex((form) => form.id === payload.id)

    module.data.splice(formIndex, 1)
  },

  updateForwardingField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORWARDINGS_MODULE_ID
    )

    const forwardingIndex = module.data.findIndex(
      (forwarding) => forwarding.id === payload.id
    )

    module.data.splice(forwardingIndex, 1, { ...payload, modified: true })
  }
}

export default mutations
