export const scheduleConfirmationMessage = (data) => {
  const { number, date, hour, doctorName, clinic, idClinic } = data
  if (idClinic === 628) {
    const message = `
      Você tem um atendimento agendado para o dia ${date} às ${hour} com o(a) Dr(a) ${doctorName}.
      %0A
      Clínica Abrace
      %0A
      Endereço: ${clinic.address.street} ${clinic.address.number} ${clinic.address.complement} ${clinic.address.neighbor}.
      ${clinic.address.city} - ${clinic.address.state}.
      %0A
      Caso não tenha disponibilidade de comparecer, favor nos avisar com 24 horas de antecedência.
    `

    const win = window.open(
      `https://wa.me/+55${number}?text=${message}`,
      '_blank'
    )
    win.focus()
  } else {
    const message = `${clinic.name}.
      Você tem uma consulta agendada para o dia ${date} As ${hour} com o(a) Dr(a) ${doctorName}.
      Endereço: ${clinic.address.street} ${clinic.address.number} ${clinic.address.complement} ${clinic.address.neighbor}.
      ${clinic.address.city} - ${clinic.address.state}.
      Caso não tenha disponibilidade de comparecer, favor nos avisar.`

    const win = window.open(
      `https://wa.me/+55${number}?text=${message}`,
      '_blank'
    )
    win.focus()
  }
}
