import API from '../../../../services/index'
import { parseToAscii, parseToHtml } from '../../../../Utils/parsers'

const actions = {
  async addTrackingToHandBook({ commit }, { payload }) {
    payload.descMonitoramento = parseToAscii(payload.descMonitoramento)

    try {
      await API.post('/api/ProntuarioMonitoramento/Salvar', {
        object: payload
      })
    } catch (error) {}
  },

  async removeTrackingFavoriteFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/FavoritoMonitoramento/Remover', {
        data: {
          object: {
            ...payload
          }
        }
      })

      commit({ type: 'removeFromFavoriteState', payload })
    } catch (error) {}
  },

  async addFavoriteTrackingToHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )
    const alreadyOnFavorite = moduleFavorites.some(
      (favorite) => favorite.descTitulo === payload.descTitulo
    )
    if (alreadyOnFavorite) {
      return
    }
    try {
      payload.descMonitoramento = parseToAscii(payload.descMonitoramento)

      const apiResponse = await API.post(
        '/api/FavoritoMonitoramento/Adicionar',
        {
          object: {
            ...payload
          }
        }
      )

      apiResponse.data.object.descMonitoramento = parseToHtml(
        apiResponse.data.object.descMonitoramento
      )

      commit({
        type: 'addToModuleFavoriteStateWithoutSelect',
        payload: apiResponse.data.object
      })
    } catch (error) {}
  },

  async addEdittedTrackingFavoriteToHandBook({ commit }, { payload }) {
    try {
      const requestObject = {
        ...payload,
        descMonitoramento: parseToAscii(payload.descMonitoramento)
      }
      await API.put('/api/FavoritoMonitoramento/Editar', {
        object: requestObject
      })

      requestObject.descMonitoramento = parseToHtml(
        requestObject.descMonitoramento
      )

      commit({ type: 'editModuleFavorite', payload: requestObject })
    } catch (error) {}
  },

  async addTrackingToFavoriteHandBook({ commit }, { payload }) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        descMonitoramento: parseToAscii(payload.descMonitoramento),
        acessoRapido: 1,
        favorito: 0
      }

      await API.put('/api/FavoritoMonitoramento/Editar', {
        object: requestObject
      })

      requestObject.descMonitoramento = parseToHtml(
        requestObject.descMonitoramento
      )

      commit({
        type: 'addToModuleFavoriteState',
        payload: requestObject
      })
    } catch (error) {}
  },

  async addTrackingToQuickAccessHandBook({ commit }, { payload }) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        descMonitoramento: parseToAscii(payload.descMonitoramento),

        acessoRapido: 0,
        favorito: 1
      }

      await API.put('/api/FavoritoMonitoramento/Editar', {
        object: requestObject
      })

      requestObject.descMonitoramento = parseToHtml(
        requestObject.descMonitoramento
      )

      commit({
        type: 'addToModuleQuickAccessState',
        payload: requestObject
      })
    } catch (error) {}
  }
}

export default actions
