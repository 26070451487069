<template>
  <ul class="table">
    <div
      v-if="data.length === 0"
      style="
        height: 400px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <span style="font-size: 1rem; font-weight: bold; color: #a09e9e"
        ><strong
          >Nenhuma receita foi encontrada, por favor revise os filtros!</strong
        ></span
      >
    </div>
    <div v-else>
      <div class="box-list-cards" v-if="isMobile()">
        <div
          title="Clique aqui para ver os detalhes e alterar!"
          v-for="(expense, i) in listExpenses"
          :key="i"
          class="list-card"
          @click="openModalInfo(expense)"
        >
          <div class="box-buttons-card">
            <button @click.stop="alterStatus(1, expense)" class="statusButton">
              <b-icon icon="unlock-fill" font-scale="1"></b-icon>
              Aberto
            </button>
            <button @click.stop="alterStatus(2, expense)" class="statusButton">
              <b-icon icon="hand-thumbs-up-fill" font-scale="1"></b-icon>
              Pago
            </button>
            <button @click.stop="alterStatus(4, expense)" class="statusButton">
              <b-icon icon="backspace-reverse-fill" font-scale="1"></b-icon>
              Cancelado
            </button>
            <button @click.stop="alterStatus(5, expense)" class="statusButton">
              <b-icon icon="calendar2-x-fill" font-scale="1"></b-icon>
              Em atraso
            </button>
          </div>
          <div class="box-info-card">
            <div class="content">
              <label>Pagador:</label>
              {{ expense.nmResPagador }}
            </div>
            <div class="content">
              <label>Status:</label>
              {{ expense.descStatusFinanceiro }}
            </div>
            <div class="content">
              <label>Valor:</label>
              {{ 'R$' + expense.valorTitulo }}
            </div>
            <div class="content">
              <label>Vencimento:</label>
              {{ getDateFormated(expense.dtVencimento) }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <li class="headerTable">
          <input
            class="checkbox-item"
            type="checkbox"
            v-model="selectAllChecked"
            @change="handleSelectAllChange"
          />
          <span class="item pagador">Recebedor</span>
          <span class="item type">Tipo</span>
          <span class="item titile">Titulo</span>
          <span class="item venc">Vencimento</span>
          <span class="item pagamento">Pagamento</span>
          <span class="item value">Valor</span>
          <span class="item fornecedor">Fornecedor</span>
          <span class="item cent">Centro de Custo</span>
          <span class="item situetion">Situação</span>
          <span class="item status">Alterar Status</span>
        </li>
        <li
          @click="openModalInfo(expense)"
          v-for="(expense, i) in listExpenses"
          :key="i"
        >
          <div>
            <input
              class="checkbox-item"
              type="checkbox"
              :checked="selectAllChecked"
              @click.stop="handleCheckboxChange($event, expense)"
            />
          </div>
          <div class="box-itens">
            <span class="item pagador">{{ expense.nmResPagador }}</span>
            <span class="item type">{{ expense.descDespesa }}</span>
            <span class="item titile">{{ expense.numeroTitulo }}</span>
            <span class="item venc">{{
              getDateFormated(expense.dtVencimento)
            }}</span>
            <span class="item pagamento">{{
              getDateFormated(expense.dtUltimoPagamento)
            }}</span>
            <span class="item value">{{ 'R$' + expense.valorTitulo }}</span>
            <span class="item fornecedor">{{ expense.descFornecedor }}</span>
            <span class="item cent">{{ expense.descCentroCusto }}</span>
            <span class="item situetion">{{
              expense.descStatusFinanceiro
            }}</span>
            <div>
              <b-dropdown
                id="dropdown-left"
                text="Alterar"
                variant="primary"
                class="md-2"
              >
                <b-dropdown-item
                  style="background-color: white"
                  class="statusButton mr-2"
                  @click.stop="alterStatus(1, expense)"
                >
                  <b-icon icon="unlock-fill" font-scale="1"></b-icon>
                  Aberto
                </b-dropdown-item>

                <b-dropdown-item
                  style="background-color: white"
                  @click.stop="alterStatus(2, expense)"
                  class="statusButton"
                >
                  <b-icon icon="hand-thumbs-up-fill" font-scale="1"></b-icon>
                  Pago
                </b-dropdown-item>
                <b-dropdown-item
                  style="background-color: white"
                  @click.stop="alterStatus(4, expense)"
                  class="statusButton"
                >
                  <b-icon icon="backspace-reverse-fill" font-scale="1"></b-icon>
                  Cancelado
                </b-dropdown-item>
                <b-dropdown-item
                  style="background-color: white"
                  @click.stop="alterStatus(5, expense)"
                  class="statusButton"
                >
                  <b-icon icon="calendar2-x-fill" font-scale="1"></b-icon>
                  Em atraso
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </li>
      </div>
    </div>
    <AlterGrroupRevenue
      v-if="getOpenModalAlterE === true"
      @close="closeAlter()"
      :data="listExpenseSelects"
      :type="type"
      :admAndRecp="admAndRecp"
    />

    <ExpensesModal
      v-if="modalInfo"
      @close="close()"
      :data="dataInfo"
      :types="true"
      :user="user"
      :admAndRecp="admAndRecp"
    />
  </ul>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AlterGrroupRevenue from '../modals/AlterGrroupRevenue.vue'
import ExpensesModal from '../modals/expensesModal.vue'
import moment from 'moment'
export default {
  props: ['admAndRecp', 'data', 'type'],
  components: { ExpensesModal, AlterGrroupRevenue },
  data() {
    const listExpenses = []

    return {
      listExpenses,
      modalInfo: false,
      dataInfo: '',
      toggle: true,
      listExpenseSelects: [],
      modalAlter: false,
      selectAllChecked: true,
      hoveredItems: Array(listExpenses.length).fill(false),
      hovered: false
    }
  },
  methods: {
    ...mapActions({
      setAlterInBlockExpense: 'Finance/setAlterInBlockExpense'
    }),
    handleSelectAllChange() {
      // Lógica para lidar com a mudança de estado do checkbox fora da lista
      const isChecked = this.selectAllChecked

      // Atualizar o estado de todos os checkboxes dentro da lista
      this.listExpenses.forEach((expense) => {
        // Faça algo com cada item, por exemplo, chame handleCheckboxChange
        this.handleCheckboxChange({ target: { checked: isChecked } }, expense)
      })
    },
    getDateFormated(value) {
      if (value === '' || value === null || value === undefined) {
        return ''
      } else {
        const newadate = moment(value).format('DD/MM/YYYY')
        return newadate
      }
    },
    formatDate(date) {
      const dateVenci = moment(date).format('DD-MM-YYYY')
      return dateVenci
    },
    handleCheckboxChange(value, expense) {
      const isChecked = value.target.checked
      if (isChecked === true) {
        if (!this.listExpenseSelects.some((item) => item.id === expense.id)) {
          this.listExpenseSelects.push(expense)
        }
      }
      if (isChecked === false) {
        this.listExpenseSelects = this.listExpenseSelects.filter(
          (item) => item.id !== expense.id
        )
      }
    },
    alterStatus(value, revenue) {
      this.setAlterInBlockExpense({
        idStatusFinanceiro: value,
        idTipoRecebedor: 0,
        IdCentroCusto: 0,
        idReferenciaRecebedor: 0,
        list: [revenue.id]
      })
    },
    closeAlter() {
      this.modalAlter = false
    },
    openModalAlter() {
      this.modalAlter = true
    },
    openModalInfo(object) {
      this.dataInfo = object
      this.modalInfo = true
    },
    close() {
      this.modalInfo = false
    },
    setHovered(index, value) {
      this.$set(this.hoveredItems, index, value)
    },
    isMobile() {
      const isMobileUserAgent =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      if (isMobileUserAgent) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    data(newValue) {
      this.listExpenses = newValue
      this.listExpenseSelects = newValue
    },
    listExpenseSelects() {
      if (this.listExpenseSelects.length < 2) {
        this.$emit('open', false)
      } else {
        this.$emit('open', true)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      getOpenModalAlterE: 'Finance/getOpenModalAlterE'
    })
  },
  mounted() {
    this.listExpenses = this.data
  }
}
</script>
<style scoped>
.table {
  overflow-y: auto;
  padding: 0;
  width: 100%;
  height: 400px;
}

.headerTable {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 10px;
  background-color: #089bab;
  color: white;
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr 1fr;
  align-items: center;
  justify-content: center;
}

li {
  display: grid;
  grid-template-columns: 0.1fr 10fr;
  gap: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgb(184, 180, 180);
  padding: 24px 10px;
  background-color: #fcfeff;
  color: #5b5c5f;
  width: 100%;
}

.box-itens {
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr 1fr;
  align-items: center;
  justify-content: center;
}

.statusButton {
  margin-bottom: 6px;
  gap: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(64, 163, 239);
  font-size: 0.7rem;
  padding: 0.2rem 0.1rem;
  border-radius: 10px;
  border: none;
}

.box-buttons-status {
  display: flex;
  gap: 10px;
  align-items: center;
}
.box-button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.att {
  gap: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(64, 163, 239);
  font-size: 0.9rem;
  padding: 0.4rem;
  border-radius: 10px;
  border: none;
}
.box-buttons-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.box-info-card {
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.box-list-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.list-card {
  cursor: pointer;
  padding: 10px;
  background-color: #fcfeff;
  border: 1px solid rgb(184, 180, 180);
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}

@media (max-width: 1110px) {
  .pagamento {
    display: none;
  }

  .venc {
    display: none;
  }

  .fornecedor {
    display: none;
  }
  .cent {
    display: none;
  }

  .headerTable {
    grid-template-columns: 0.1fr 2fr 2fr 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
  .box-itens {
    gap: 10px;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 810px) {
  .headerTable {
    font-size: 0.64rem;
  }
  .box-itens {
    font-size: 0.64rem;
  }
}

@media (max-width: 444px) {
  .box-info-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

@media (max-width: 390px) {
  .statusButton {
    font-size: 0.6rem;
  }
}
</style>
