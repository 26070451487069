<template>
  <div>
    <section class="sign-in-page" v-if="!initialized">
      <b-container class="sign-in-page-bg mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail">
              <a class="sign-in-logo mb-5" href="#"
                ><img :src="logo" class="img-fluid" alt="logo"
              /></a>
              <div class="term-of-use">
                <h5>Termos de uso</h5>
                <div class="text">
                  <br />
                  <span>
                    <strong>Declaro ter ciência que:</strong>
                  </span>

                  <span>
                    O atendimento a distância possui algumas limitações;

                    <br /><br />

                    Para o bom aproveitamento e análise do médico, devo informar
                    todos os dados possíveis, não omitindo nenhuma informação
                    acerca dos meus sinais e sintomas;

                    <br /><br />

                    A precisão das minhas informações se faz necessárias em
                    virtude da ausência de avaliação clínica pelo médico;

                    <br /><br />

                    Poderá ser necessária a complementação com exames para um
                    melhor diagnósitco e escolha da conduta terapêutica;
                  </span>
                </div>
              </div>

              <div class="guidance">
                <h3 class="title">Orientações Gerais</h3>

                <span>
                  Caso não esteja conseguindo carregar sua imagem ou audio,
                  verifique se não está sendo bloqueado pelo seu navegador.
                </span>

                <br /><br />

                <b-button
                  v-if="verified"
                  size="lg"
                  variant="outline-light"
                  @click="initialized = true"
                >
                  <span style="font-size: 20px">Iniciar Consulta</span>
                </b-button>

                <div class="loading-container" v-if="loading">
                  <p>Verificando integridade da sala, aguarde um momento.</p>
                  <br />
                  <b-overlay :show="loading" spinner-variant="light" />
                </div>

                <div class="loading-container" v-if="!loading && !verified">
                  <p>
                    Identificação de sala inválida, entre em contato com o
                    médico responsável ou com a equipe de suporte.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from preview-container">
              <h4>Prévia</h4>
              <div id="preview"></div>
            </div>
          </div>
        </div>
      </b-container>
    </section>

    <telemedicine
      v-else
      @close="initialized = false"
      :localTracks="tracks"
      :identification="identificacao"
    />
  </div>
</template>

<script>
import logo from '../../assets/images/logo-black-white.png'
import Telemedicine from './telemedicine'
import createLocalTracks from '@/components/Video/createLocalTracks'
import { mapActions } from 'vuex'
export default {
  props: ['identificacao'],
  components: {
    Telemedicine
  },
  data() {
    return {
      verified: false,
      loading: true,
      logo: logo,
      initialized: false,
      previewElement: {},
      tracks: ''
    }
  },
  async mounted() {
    await this.verifyRoomIntegrity()
    this.previewElement = document.getElementById('preview')
    this.createPreview()
  },

  beforeDestroy() {},

  methods: {
    ...mapActions({
      verifyRoomIntegrityAction: 'Video/verifyRoomIntegrityAction'
    }),
    async createPreview() {
      const tracks = await createLocalTracks()

      this.tracks = tracks

      tracks.forEach((track) => {
        this.previewElement.appendChild(track.attach())
      })
    },

    async verifyRoomIntegrity() {
      const verified = await this.verifyRoomIntegrityAction(this.identificacao)
      this.verified = verified
      this.loading = false
    }
  }
}
</script>

<style scoped>
.term-of-use {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 32px;
}

.text {
  text-align: start;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 4px 12px 12px 12px;
}

.guidance {
  margin-top: 16px;
  color: white;
}

.guidance .title {
  color: white;
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
}

.loading-container {
  font-size: 20px;
}
</style>
