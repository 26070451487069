<template>
  <div class="module">
    <div class="details">
      <div
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </div>
      <div class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactiveForms"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>

      <favorites-select
        :title="'Codig'"
        :favorites="componentFavorites"
        :titleProperty="'idFormulario'"
        :descriptionProperty="'descFormulario'"
        @on-favorite-chosen="showForm"
      />

      <div style="width: 25%; position: relative; margin: 0 8px">
        <div style="position: relative">
          <b-form-input
            class="details__input"
            autocomplete="off"
            v-model="filter"
            placeholder="Pesquisar"
            v-uppercase
          ></b-form-input>
          <search-result
            :results="searchForms"
            @on-favorite-chosen="addFormToFavorites"
            @on-quick-access-chosen="addFormToQuickAccess"
            @on-result-selected="showForm"
            v-if="filter.length > 0"
          />
          <div
            @click="clearSearch"
            v-if="filter.length > 0"
            style="
              width: 100vw;
              height: 100vh;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 12;
            "
          ></div>
        </div>
      </div>

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>

    <quick-access
      v-if="componentQuickAccess.length > 0"
      :quickAccesses="componentQuickAccess"
      title-property="descFormulario"
      @on-quick-access-chosen="showForm"
    />

    <div class="content" v-if="componentInfo.aberto === 0">
      <forms-list
        :header-color="componentInfo.corHexadecimal"
        :form-list="formsListToShow"
        @showGraph="handleShowGraph"
        @visualizeForm="showForm"
        @update-chart="getFormToMountGraph"
      />

      <graph-component
        v-if="showGraph"
        name="Pontuaçâo"
        :seriesData="this.series"
        :xAxisData="this.categories"
        @closeGraph="handleCloseGraph"
      />
    </div>

    <form-filling-modal
      :form="selectedForm"
      :id-prontuario="idProntuario"
      :medic-name="user.nome"
      :questions="selectedForm.pergunta_formulario"
      :filling="isFillingForm"
      @save="addForm"
      @edit="editForm"
      @close="hideForm"
      v-if="isFormVisible"
    />
  </div>
</template>

<script>
import QuickAccess from '../../shared/QuickAccess'
import FavoritesSelect from '../../shared/FavoritesSelect'
import FormsList from './components/FormsList'
import { mapActions, mapGetters } from 'vuex'
import { uid } from 'uid'
import FormFillingModal from '@/components/FormFillingModal'
import SearchResult from './components/SearchResult'
import GraphComponent from '../../../GraphComponent'
import API from '../../../../services'

export default {
  name: 'Forms',
  props: ['componentInfo', 'idProntuario', 'idPaciente', 'idMedico'],
  components: {
    QuickAccess,
    FavoritesSelect,
    FormsList,
    FormFillingModal,
    SearchResult,
    GraphComponent
  },
  data() {
    return {
      filter: '',
      showInactiveForms: false,
      isFormVisible: false,
      showGraph: false,
      selectedForm: '',
      formToShowGraph: '',
      isFillingForm: false,
      series: [],
      categories: [],
      allForms: Array
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    componentFavorites() {
      return this.componentInfo.favorites.filter(
        (favorite) => favorite.location === 'favorite'
      )
    },
    componentQuickAccess() {
      return this.componentInfo.favorites.filter(
        (favorite) => favorite.location === 'quickAccess'
      )
    },
    formsListToShow() {
      if (this.showInactiveForms) {
        return this.componentInfo.data
      } else {
        return this.componentInfo.data.filter((form) => form.ativo === 0)
      }
    },
    searchForms() {
      if (this.filter === '') {
        return []
      }

      const forms = this.allForms

      const filtered = forms.filter((form) => {
        return form.descFormulario.includes(this.filter)
      })

      return filtered
    }
  },
  async mounted() {
    const idClinic = this.user.clinica_usuario[0].idClinica
    const idModule = this.componentInfo.id

    const apiResponse = await API.get(
      '/api/FavoritoFormularioAvaliacao/ObtemListaPorFormulario',
      {
        params: {
          id_prontuario_preferencia: idModule,
          id_clinica: idClinic
        }
      }
    )

    this.allForms = apiResponse.data.object
  },
  methods: {
    ...mapActions({
      getForm: 'Forms/getCompleteFormAction',
      getAllForms: 'Forms/getFormsPreferencesAction',
      getFormAnswers: 'Forms/getFormAnswersAction'
    }),
    handleCloseGraph() {
      this.showGraph = false
    },
    handleShowGraph() {
      this.showGraph = true
    },
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    },
    getFormToMountGraph(forms) {
      const ordered = [...forms].sort(function (a, b) {
        return new Date(a.dtInclusao) - new Date(b.dtInclusao)
      })

      this.createChartsSeries(ordered)
      this.createChartCategories(ordered)
    },
    createChartsSeries(forms) {
      const numbers = forms.map((element) => {
        return element.pontuarioTotalFormulario
      })

      this.series = numbers
    },
    createChartCategories(forms) {
      const dates = forms.map((element) => {
        return new Date(element.dtInclusao).toLocaleDateString()
      })

      this.categories = dates
    },
    sliceChartDataSeries(series, range) {
      const formated = series.map((element) => {
        const reversed = [...element.data].reverse()

        const sliced = reversed.slice(0, range)

        const turnBackReverse = sliced.reverse()

        const object = {
          name: element.name,
          data: turnBackReverse
        }

        return object
      })

      return formated
    },
    clearSearch() {
      this.filter = ''
    },
    addForm(data) {
      const id = uid()
      data.id = id

      this.$store.commit({
        type: 'MedicalReports/addForm',
        payload: data
      })

      this.hideForm()
    },
    editForm(data) {
      this.$store.commit({
        type: 'MedicalReports/updateFormField',
        payload: data
      })

      this.hideForm()
    },
    hideForm() {
      this.isFillingForm = false
      this.isFormVisible = false
    },
    async showForm(form) {
      const selectedForm = await this.getForm(form.idFormulario)
      this.selectedForm = selectedForm

      if (form.isNew || !form.dtInclusao) {
        this.isFillingForm = true
        this.selectedForm.idList = form.id
      }

      const questions = selectedForm.pergunta_formulario.filter((element) => {
        return element.ativo === 0
      })

      questions.sort((a, b) => {
        return a.id - b.id
      })

      if (questions.length > 0) {
        questions.forEach((element) => {
          element.randomKey = uid()
          element.multipleChoice = []

          if (
            element.idTipoResposta === 3 ||
            element.idTipoResposta === 4 ||
            element.idTipoResposta === 5
          ) {
            if (element.answers) {
              element.multipleChoice = element.answers
              element.answers = []
            }
          }
        })

        const alreadyFilled = !!form.listaRespostaFormularioAvaliacao

        if (alreadyFilled) {
          this.selectedForm.answers = form.listaRespostaFormularioAvaliacao
        }

        if (
          Object.prototype.hasOwnProperty.call(
            form,
            'listaRespostaFormularioAvaliacao'
          ) &&
          !alreadyFilled
        ) {
          const fill = await this.getFormAnswers(form.id)

          this.selectedForm.listaRespostaFormularioAvaliacao = fill
        }

        this.selectedForm.pergunta_formulario = questions
      } else {
        this.selectedForm.pergunta_formulario = []
      }

      this.isFormVisible = true
    },

    addFormToFavorites(data) {
      this.$store.dispatch({
        type: 'MedicalReports/addFormToFavoritesHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })
      this.filter = ''
      this.results = []
    },

    addFormToQuickAccess(data) {
      if (this.amountOfQuickAccesses >= 5) {
        this.$toast.warning(
          'Quantidade máxima de acesso rápido já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }
      this.$store.dispatch({
        type: 'MedicalReports/addFormToQuickAccessHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })

      this.filter = ''
      this.results = []
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../shared/generalModuleStyles.scss';
.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}

.details {
  display: flex;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
  &__input {
    &::placeholder {
      text-transform: none;
      color: #000 !important;
    }
    border-color: rgb(8, 155, 171) !important;

    background-color: white !important;
  }

  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}

.content {
  background: #fff;
}
</style>
