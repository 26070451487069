/* eslint-disable prettier/prettier */
import { deletePrescriptionService } from '../../components/MedicalReport/prescriptionsService'
import auth from '../../services/auth'
import API from '../../services/index'

export default {
  horizontalMenuAction(context, payload) {
    if (context.state.horizontalMenu) {
      context.commit('horizontalMenuCommit', false)
    } else {
      context.commit('horizontalMenuCommit', true)
    }
  },
  miniSidebarAction(context, payload) {
    return new Promise((resolve, reject) => {
      if (context.state.miniSidebarMenu) {
        context.commit('miniSidebarCommit', false)
        resolve(false)
      } else {
        context.commit('miniSidebarCommit', true)
        resolve(true)
      }
    })
  },
  async loginUserAction(context, payload) {
    const { login, password } = payload

    const userData = await auth.login({ login, password })

    return userData
  },
  async confirmLoginUserAction(context, payload) {
    context.commit('loginUserCommit', {
      auth: true,
      user: payload
    })
  },
  async getTermsOfUseAction() {
    const response = await API.get(
      '/api/Usuario/AwsS3ObtemArquivoTermo',
      { responseType: 'blob' },
      {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`
        }
      }
    )

    return response.data
  },
  logoutUserAction(context, payload) {
    deletePrescriptionService()
    auth.logout()
    context.commit('loginUserCommit', {
      auth: false,
      user: {}
    })
  },
  async getIdleTimeAction() {
    try {
      const result = await API.get('api/Parametro/TimeoutAplicao')
      const time = parseInt(result.data.identificacao)

      return time
    } catch {
      return 100000
    }
  },
  async addUserAction(context, payload) {
    const {
      birthDate,
      cpf,
      email,
      gender,
      login,
      name,
      password,
      phone,
      council,
      numCouncil,
      especialidade,
      councilState
    } = payload
    const nCouncil = parseInt(numCouncil)
    const userData = await API.post('/api/Usuario/Adicionar', {
      object: {
        login,
        senha: password,
        nome: name,
        telCelular: phone,
        emailPessoal: email,
        idSexo: gender,
        dtNascimento: birthDate,
        cpf,
        idConselhoProfissional: council,
        numeroConselho: nCouncil,
        idEstadoConselho: councilState,
        usuariocbo: [
          {
            id: 0,
            idCbo: especialidade,
            idUsuario: 0,
            idUsuarioAlteracao: 0,
            dtMovimentacao: birthDate,
            principal: 'string'
          }
        ]
      }
    })
    context.commit('addUserCommit', userData)
  },
  addUserDataFormAction(context, payload) {
    context.commit('addUserDataFormCommit', payload)
  },
  async editUserAction(context, payload) {
    const {
      bloqueado,
      cpf,
      emailPessoal,
      emailProfissional,
      id,
      idConselhoProfissional,
      idEstadoConselho,
      idSexo,
      identidade,
      idPerfil,
      login,
      nome,
      nomeMae,
      nomePai,
      nomeSocial,
      numeroConselho,
      senha,
      status,
      telCelular,
      telResidencial,
      usuariocbo,
      dtNascimento
    } = payload
    const numCouncilInt = parseInt(numeroConselho)
    const authToken = `Bearer ${window.$cookies.get('token')}`
    const userData = await API.put(
      '/api/Usuario/Editar',
      {
        object: {
          login,
          senha,
          nome,
          telCelular,
          emailPessoal,
          idSexo,
          dtNascimento,
          cpf,
          idConselhoProfissional,
          numeroConselho: numCouncilInt,
          idEstadoConselho,
          usuariocbo,
          bloqueado,
          emailProfissional,
          identidade,
          status,
          telResidencial,
          id,
          nomeMae,
          nomePai,
          nomeSocial,
          idPerfil
        }
      },
      { headers: { Authorization: authToken } }
    )

    context.commit('editUserCommit', userData)
  },
  activePageAction(context, payload) {
    context.commit('activePageCommit', payload)
  },
  addBookmarkAction(context, payload) {
    context.commit('addBookmarkCommit', payload)
  },
  removeBookmarkAction(context, payload) {
    context.commit('removeBookmarkCommit', payload)
  },
  setLangAction(context, payload) {
    context.commit('setLangCommit', payload)
  },
  setRtlAction(context, payload) {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
    const body = document.getElementsByTagName('body')
    body[0].classList.add('rtl')
    body[0].classList.remove('light')
  },
  removeRtlAction(context, payload) {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
    const body = document.getElementsByTagName('body')
    body[0].classList.remove('rtl')
    body[0].classList.add('light')
  },

  async getStatesAction() {
    const response = await API.get('api/Estado/ObtemLista')

    return response.data.object
  },

  async getCitiesByStateIdAction(context, stateId) {
    const response = await API.get(
      `api/Cidade/ObtemCidadesPorEstado/${stateId}`
    )

    return response.data.object
  }
}
