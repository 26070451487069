<template>
  <transition name="slide-fade" appear>
    <div id="drawer" :class="isChat === true ? 'drawer-2' : 'drawer'">
      <div class="drawer-header">
        <article class="drawer-article">
          <h3>Central de ajuda</h3>
          <h4 @click="closeChat()">Página inicial</h4>
        </article>
        <b-icon
          id="img-drawer"
          style="cursor: pointer"
          @click="close()"
          icon="x-square"
        ></b-icon>
      </div>
      <div v-if="isChat" class="drawer-chat"></div>
      <div v-else class="drawer-body">
        <ul class="drawer-list">
          <a @click="setIsPrimaryAcess()">
            <li>
              <div class="li-content">
                <b-icon
                  id="img-drawer"
                  icon="bezier2"
                  style="color: #089cac"
                ></b-icon>
                <div>
                  <h3>Tour de primeiro acesso</h3>
                  <h4>Conheça as principais funcionalidades do sistema!</h4>
                </div>
              </div>
            </li>
          </a>
          <a href="https://doctorpephelp.zendesk.com/hc/pt-br" target="_blank">
            <li>
              <div class="li-content">
                <b-icon
                  id="img-drawer"
                  icon="file-text-fill"
                  style="color: #089cac"
                ></b-icon>
                <div>
                  <h3>Documentação</h3>
                  <h4>Acesse a documentação completa do sistema.</h4>
                </div>
              </div>
              <b-icon
                id="img-drawer"
                icon="box-arrow-up-right"
                style="color: #089cac"
              ></b-icon>
            </li>
          </a>
          <a
            href="https://doctorpephelp.zendesk.com/hc/pt-br/requests/new"
            target="_blank"
          >
            <li>
              <div class="li-content">
                <b-icon
                  id="img-drawer"
                  icon="folder2-open"
                  style="color: #089cac"
                ></b-icon>
                <div>
                  <h3>Tickets de Suporte</h3>
                  <h4>
                    Crie ou acompanhe o status de todos os seus tickets de
                    suporte.
                  </h4>
                </div>
              </div>
              <b-icon
                id="img-drawer"
                icon="box-arrow-up-right"
                style="color: #089cac"
              ></b-icon>
            </li>
          </a>
          <a @click="openChat()">
            <li style="border-bottom: 1px solid #eeeef0">
              <div class="li-content">
                <b-icon
                  id="img-drawer"
                  icon="headset"
                  style="color: #089cac"
                ></b-icon>
                <div>
                  <h3>Ajuda Online</h3>
                  <h4>
                    Fale com um de nossos especialidades disponivel no horário
                    comercial ( 07hrs até as 19hrs )
                  </h4>
                </div>
              </div>
            </li>
          </a>
          <a href="https://doctorpep.com.br/" target="_blank">
            <li style="border-bottom: 1px solid #eeeef0">
              <div class="li-content">
                <b-icon
                  id="img-drawer"
                  icon="globe2"
                  style="color: #089cac"
                ></b-icon>
                <div>
                  <h3>Nosso site</h3>
                  <h4>Acesse nosso Site!</h4>
                </div>
              </div>
              <b-icon
                id="img-drawer"
                icon="box-arrow-up-right"
                style="color: #089cac"
              ></b-icon>
            </li>
          </a>
          <a
            href="https://www.instagram.com/doctorpepbr?igsh=MXEyOGRvNGp1aWRoZw=="
            target="_blank"
          >
            <li>
              <div class="li-content">
                <b-icon
                  id="img-drawer"
                  icon="instagram"
                  style="color: #089cac"
                ></b-icon>
                <div>
                  <h3>Redes Sociais</h3>
                  <h4>Acesse nossos principais canais comerciais</h4>
                </div>
              </div>
              <b-icon
                id="img-drawer"
                icon="box-arrow-up-right"
                style="color: #089cac"
              ></b-icon>
            </li>
          </a>
        </ul>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      isPrimaryAcess: false,
      isChat: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      getIsPrimaryAcess: 'User/getIsPrimaryAcess'
    })
  },
  methods: {
    ...mapActions({
      setInfosTourOfUser: 'User/setInfosTourOfUser'
    }),
    close() {
      this.closeChat()
      this.$emit('close')
    },
    async finallyTuor() {
      this.$emit('modalClosed')
    },
    async setIsPrimaryAcess() {
      const page = document.getElementById('calenderId')
      if (this.getIsPrimaryAcess.primeiroAcesso === 1) {
        const object = {
          idUsuario: this.user.id,
          dtCadastro: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          primeiroAcesso: 0,
          stepTour: 0
        }
        await this.setInfosTourOfUser(object)
        if (page !== null) {
          this.$router.push({ name: 'register.schedules' })
        }
        this.$tours.tourH.start()
        this.$tours.myTour.start()
        this.$tours.tourCreatePacient.start()
        this.$tours.tourFinally.start()
      } else {
        const object = {
          idUsuario: this.user.id,
          dtCadastro: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          primeiroAcesso: 1,
          stepTour: 0
        }
        await this.setInfosTourOfUser(object)
        this.$tours.myTour.stop()
        this.$tours.tourH.stop()
        this.$tours.tourCreatePacient.stop()
        this.$tours.tourFinally.stop()
      }
    },
    openChat() {
      setTimeout(() => {
        this.isChat = true
        const scriptElement = document.getElementById('ze-snippet')
        if (scriptElement === null) {
          const script = document.createElement('script')
          script.src =
            'https://static.zdassets.com/ekr/snippet.js?key=6db96b2e-42c6-414f-b85f-eb3ef5673114'
          script.id = 'ze-snippet'
          document.body.appendChild(script)
        } else {
          this.showChat()
        }
      }, 100)
    },
    closeChat() {
      const valid = document.querySelector('div[style="visibility: visible;"]')
      if (valid !== null) {
        // Ocultar o iframe do chat "No content"
        const noContentFrame = document.querySelector(
          'iframe[title="No content"]'
        )
        if (noContentFrame) {
          noContentFrame.style.display = 'none'
        }

        // Ocultar o div com o estilo do chat
        const chatStyleDiv = document.querySelector(
          'div[style="visibility: visible;"]'
        )
        if (chatStyleDiv) {
          chatStyleDiv.style.display = 'none'
        }
      } else {
        // Ocultar o iframe do chat "No content"
        const noContentFrame = document.querySelector(
          'iframe[title="No content"]'
        )
        if (noContentFrame) {
          noContentFrame.style.display = 'none'
        }
        // Tornar visível o div com o estilo do chat
        const chatStyleDiv = document.querySelector(
          'div[style="visibility: visible; display: block;"]'
        )
        if (chatStyleDiv) {
          chatStyleDiv.style.display = 'none'
        }
      }

      this.isChat = false
    },
    showChat() {
      // Tornar visível o iframe do chat "No content"
      const noContentFrame = document.querySelector(
        'iframe[title="No content"]'
      )
      if (noContentFrame) {
        noContentFrame.style.display = 'block'
      }

      // Tornar visível o div com o estilo do chat
      const chatStyleDiv = document.querySelector(
        'div[style="visibility: visible; display: none;"]'
      )
      if (chatStyleDiv) {
        chatStyleDiv.style.display = 'block'
      }
    },
    closeDrawerOnClickOutside(event) {
      const drawerElement = document.getElementById('drawer')
      const chatElement = document.querySelector('.drawer-chat')

      // Verifica se o clique foi feito fora do drawer e do chat
      if (
        drawerElement &&
        !drawerElement.contains(event.target) &&
        (!chatElement || !chatElement.contains(event.target))
      ) {
        this.close()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      document.addEventListener('click', this.closeDrawerOnClickOutside)
    }, 100)
  },
  destroyed() {
    // Remove o event listener quando o componente é destruído para evitar vazamentos de memória
    document.removeEventListener('click', this.closeDrawerOnClickOutside)
  }
}
</script>
<style scoped>
.drawer {
  background-color: white;
  position: fixed;
  top: 78px;
  right: 6px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  z-index: 999999;
  box-shadow: 0px 2px 10px #dadadb;
}

.drawer-2 {
  height: 100%;
  background-color: white;
  position: fixed;
  top: 78px;
  right: 6px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  z-index: 999999;
  box-shadow: 0px 2px 10px #dadadb;
}

#img-drawer {
  font-size: 30px;
}

.drawer-header {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeef0;
}

.drawer-article {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.drawer-article h3 {
  font-weight: 600;
  color: #636160;
  font-style: normal;
}

.drawer-article h4 {
  color: #b0b0b5;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;
}

.drawer-article h4:hover {
  color: #089bab;
}

.drawer-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
}

.drawer-chat {
  position: relative;
  width: 100%;
  padding: 24px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
}

.drawer-list {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

a {
  width: 100%;
  text-decoration: none;
  color: none;
}

.li-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.drawer-list li {
  width: 100%;
  padding: 12px 24px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.drawer-list li:hover {
  background-color: rgb(239, 238, 238);
}

.drawer-list li h3 {
  color: #636160;
  font-weight: 600;
  font-style: normal;
}

.drawer-list li h4 {
  font-weight: normal;
  font-style: normal;
  color: #b0b0b5;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.9s ease; /* Adicionando a propriedade opacity à transição */
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%); /* Inicia completamente fora da tela à direita */
  transition-delay: 0.5s; /* Delay de 0s na entrada */
}

.slide-fade-leave-active {
  transition-delay: 0s; /* Delay de 5s na saída */
}

@media (max-width: 1400px) {
  .drawer {
    width: 500px;
  }
  .drawer-2 {
    width: 500px;
  }
  .drawer-header {
    padding: 20px;
  }
  .drawer-article h3 {
    font-size: 1.2rem;
  }
  .drawer-article h4 {
    font-size: 1rem;
  }
  .drawer-list li h3 {
    font-size: 1.2rem;
  }
  .drawer-list li h4 {
    font-size: 1rem;
  }
  #img-drawer {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .drawer {
    width: 500px;
  }
  .drawer-2 {
    width: 500px;
  }
  .drawer-header {
    padding: 20px;
  }
  .drawer-article h3 {
    font-size: 1.1rem;
  }
  .drawer-article h4 {
    font-size: 0.9rem;
  }
  .drawer-list li h3 {
    font-size: 1.1rem;
  }
  .drawer-list li h4 {
    font-size: 0.9rem;
  }
  #img-drawer {
    font-size: 22px;
  }
}
@media (max-width: 900px) {
  .drawer {
    width: 460px;
  }
  .drawer-header {
    padding: 18px;
  }
  .drawer-article h3 {
    font-size: 1rem;
  }
  .drawer-article h4 {
    font-size: 0.9rem;
  }
  .drawer-list li h3 {
    font-size: 1rem;
  }
  .drawer-list li h4 {
    font-size: 0.9rem;
  }
  #img-drawer {
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  .drawer {
    width: 440px;
  }
  .drawer-header {
    padding: 18px;
  }
  .drawer-article h3 {
    font-size: 1rem;
  }
  .drawer-article h4 {
    font-size: 0.9rem;
  }
  .drawer-list li h3 {
    font-size: 1rem;
  }
  .drawer-list li h4 {
    font-size: 0.9rem;
  }
  #img-drawer {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .drawer {
    width: 380px;
  }
  .drawer-header {
    padding: 16px;
  }
  .drawer-article h3 {
    font-size: 1rem;
  }
  .drawer-article h4 {
    font-size: 0.9rem;
  }
  .drawer-list li h3 {
    font-size: 1rem;
  }
  .drawer-list li h4 {
    font-size: 0.9rem;
  }
  #img-drawer {
    font-size: 16px;
  }
  .drawer-2 {
    width: 410px;
  }
}
@media (max-width: 498px) {
  .drawer {
    width: 280px;
  }
  .drawer-2 {
    width: 410px;
  }
  .drawer-header {
    padding: 10px;
  }
  .drawer-article h3 {
    font-size: 0.8rem;
  }
  .drawer-article h4 {
    font-size: 0.6rem;
  }
  .drawer-list li h3 {
    font-size: 0.8rem;
  }
  .drawer-list li h4 {
    font-size: 0.6rem;
  }
  #img-drawer {
    font-size: 16px;
  }
  .drawer-list li {
    padding: 10px 20px;
  }
  .drawer-chat {
    padding: 20px;
  }
}
</style>
