export default {
  setForm(state, formulario) {
    state.form = formulario
  },
  setFormEdit(state, formulario) {
    state.formEdit = formulario
  },
  setQuestionsInForm(state, payload) {
    state.pergunta_formulario.push(payload)
  },
  setQuestionsInFormEdit(state, payload) {
    state.pergunta_formulario_edit.push(payload)
  }
}
