var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{style:(`${_vm.expanded === 0 ? 'container-fluid' : 'height: 0;'} `),attrs:{"fluid":""}},[_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',{attrs:{"head-variant":"primary"}},[_c('b-tr',{staticClass:"table__header"},[_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Data")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Profissional")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("CID")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Descrição")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Grau")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Observação")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Ativo")]),_c('b-th',{style:(`background-color: ${_vm.headerBackground}`)},[_vm._v("Ação")])],1)],1),_c('b-tbody',{staticClass:"table__body"},_vm._l((_vm.items),function(problem){return _c('b-tr',{key:problem.id,attrs:{"title":problem.justificativaInativacao
            ? 'Justificativa:  ' + problem.justificativaInativacao
            : ''}},[_c('b-td',{class:[
            'table__date',
            problem.ativo === 1 ? 'line-throughed' : ''
          ]},[_vm._v(_vm._s(_vm.formatDate(problem.dtOcorrencia, 'pt-BR')))]),_c('b-td',{class:problem.ativo === 1 ? 'line-throughed' : ''},[_vm._v(_vm._s(problem.nmProfissional))]),_c('b-td',{class:problem.ativo === 1 ? 'line-throughed' : ''},[_vm._v(_vm._s(problem.codCid))]),_c('b-td',{class:problem.ativo === 1 ? 'line-throughed' : ''},[_vm._v(_vm._s(problem.descCid))]),_c('b-td',[_c('span',{class:'table__degree ' + _vm.getBackground(problem.descGrau)},[_vm._v(" "+_vm._s(problem.descGrau)+" ")])]),_c('b-td',{attrs:{"title":problem.observacao}},[_c('b-form-input',{attrs:{"type":"text","value":problem.observacao ? problem.observacao.trim() : ''},on:{"change":function($event){return _vm.updateField(problem, 'observacao', $event)}}})],1),_c('b-td',[_c('b-form-checkbox',{attrs:{"disabled":"","checked":problem.ativo === 0}})],1),_c('b-td',[_c('b-button',{staticStyle:{"width":"60%"},attrs:{"variant":_vm.getActionVariant(problem.acao)},on:{"click":function($event){problem.ativo === 1
                ? _vm.updateActivity(problem)
                : problem.ativo !== 1 && problem.acao === 'Remover'
                ? _vm.handleClick(problem)
                : _vm.$bvModal.show(`${_vm.moduleId}_${problem.id}`)}}},[_vm._v(_vm._s(problem.acao))]),_c('b-modal',{attrs:{"id":`${_vm.moduleId}_${problem.id}`,"size":"lg","title":"Justificativa","header-bg-variant":"primary","title-class":"text-light","label-for":"justify-input","ok-title":"Salvar"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ cancel }){return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handleClick(problem)}}},[_vm._v(" Salvar ")])]}}],null,true)},[_c('b-form-group',{attrs:{"label-for":"justify-input"}},[_c('ValidationProvider',{attrs:{"name":"justify","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-textarea',{class:errors.length > 0 || _vm.justifyNotValid ? ' is-invalid' : '',attrs:{"id":"justify-input","maxlength":"150"},model:{value:(_vm.justify),callback:function ($$v) {_vm.justify=$$v},expression:"justify"}}),_c('div',{staticClass:"invalid-feedback",attrs:{"v-if":_vm.justifyNotValid}},[_c('span',[_vm._v("A justificativa deve conter no mínimo 5 digitos!")])])]}}],null,true)})],1),_c('p',[_vm._v(_vm._s(_vm.justify.length)+"/150")])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }