<template>
  <div class="background">
    <div class="modal-container">
      <div class="header">
        <span class="title">Termos de uso</span>
        <button class="close" aria-label="Close" @click="$emit('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="body">
        <iframe class="pdf-viewer" :src="this.term" />
      </div>
      <div style="display: flex; align-items: center">
        <b-form-checkbox
          id="checkbox"
          v-model="status"
          v-if="!alreadyAccepted"
          name="checkbox"
          :value="true"
          :unchecked-value="false"
          :disabled="!isBodyScrolledToFinal"
        />
        <span v-if="!alreadyAccepted" style="font-size: 14px; color: black">
          Eu aceito os termos e condições gerais de uso da plataforma DoctorPEP
        </span>
      </div>

      <div class="buttons" v-if="!alreadyAccepted">
        <b-button class="mr-4" variant="danger" @click="$emit('close')"
          >Cancelar</b-button
        >
        <b-button
          variant="primary"
          :disabled="!status"
          @click="$emit('confirm')"
          >Continuar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'TermsOfUseModal',
  props: ['alreadyAccepted'],
  data() {
    return {
      status: false,
      isBodyScrolledToFinal: true,
      term: ''
    }
  },

  async mounted() {
    const body = this.$el.querySelector('.body')
    body.addEventListener('mouseenter', this.verifyBodyScrollHeight)
    const file = await this.getTermsOfUse()
    this.createFileReader(file)
  },

  methods: {
    ...mapActions({
      getTermsOfUse: 'Setting/getTermsOfUseAction'
    }),
    createFileReader(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        this.term = reader.result
        return reader.result
      }
    },
    verifyBodyScrollHeight(event) {
      const scrolled = event.target.offsetHeight + event.target.scrollTop
      if (scrolled === event.target.scrollHeight) {
        this.isBodyScrolledToFinal = true
      }
    }
  }
}
</script>

<style scoped>
.background {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 50%;
  height: 80%;
  z-index: 9999;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  flex-direction: column;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.header {
  border-radius: 8px 8px 0 0;
  display: flex;
  padding: 0 16px;
  width: 100%;
  background: #089bab;
  color: #fff;
  font-size: 24px;
  justify-content: space-between;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
}

.body {
  display: flex;
  width: 100%;
  flex: auto;
  overflow: visible;
  flex-direction: column;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  margin: 0;
}
</style>
