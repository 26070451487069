import API from '../../../../services/index'

const actions = {
  async addProblemsToHandBook({ commit }, { payload }) {
    try {
      await API.post('/api/ProntuarioProblema/Adicionar', {
        object: payload
      })
    } catch (error) {}
  },

  async editProblemFromHandBook({ commit }, { payload }) {
    try {
      await API.put('/api/ProntuarioProblema/Editar', {
        object: payload
      })
    } catch (error) {}
  },

  async removeProblemFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/ProntuarioProblema/Remover', {
        data: { object: payload }
      })
    } catch (error) {}
  },
  async addProblemToFavoritesHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some((favorite) => {
      return favorite.idCid === payload.idCid
    })

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        await API.put('/api/FavoritoProblema/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { ...requestObject, location: 'favorite' }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        const apiResponse = await API.post('/api/FavoritoProblema/Adicionar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async addProblemToQuickAccessHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some((favorite) => {
      return favorite.idCid === payload.idCid
    })

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        await API.put('/api/FavoritoProblema/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        const apiResponse = await API.post('/api/FavoritoProblema/Adicionar', {
          object: requestObject
        })
        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async removeProblemFavoriteFromHandBook({ commit }, { payload }) {
    const { location, ...newPayload } = payload
    try {
      await API.delete('/api/FavoritoProblema/Remover', {
        data: {
          object: newPayload
        }
      })

      commit({ type: 'removeFromFavoriteState', payload: newPayload })
    } catch (error) {}
  }
}

export default actions
