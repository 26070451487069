<template>
  <div class="container page-online">
    <div
      style="
        background-color: transparent;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 200px;
      "
      v-if="loading"
    >
      <!-- <b-spinner
        v-if="loadingMessage === false"
        class="alignCenter"
        variant="primary"
      ></b-spinner> -->
      <div class="container page-online">
        <header>
          <img
            src="../../assets/images/logo-white.png"
            alt="Logo da DoctorPep"
          />
        </header>
        <div
          style="
            display: flex;
            background-color: transparent;
            width: 100%;
            align-items: center;
            justify-content: center;
            height: 100px;
          "
          v-if="loading"
        >
          <b-spinner
            v-if="loadingMessage === false"
            class="alignCenter"
            variant="primary"
          ></b-spinner>
        </div>
        <div class="box-sections">
          <div :class="section1 ? 'section-active' : 'section-deactive'">
            <b-icon
              icon="journal-text"
              font-scale="1"
              :style="section1 ? 'color: white' : 'color:#b0b2b3'"
            ></b-icon>
            <span class="subtitles">Cadastro</span>
          </div>
          <div :class="section2 ? 'line-active' : 'line-deactive'"></div>
          <div :class="section2 ? 'section-active' : 'section-deactive'">
            <b-icon
              :style="section2 ? 'color: white' : 'color:#b0b2b3'"
              icon="clock-fill"
              font-scale="1"
            ></b-icon>
            <span class="subtitles">Horários</span>
          </div>
          <div :class="section3 ? 'line-active' : 'line-deactive'"></div>
          <div :class="section3 ? 'section-active' : 'section-deactive'">
            <b-icon
              :style="section3 ? 'color: white' : 'color:#b0b2b3'"
              icon="check-all"
              font-scale="1"
            ></b-icon>
            <span class="subtitles">Confirmação</span>
          </div>
        </div>
        <span
          style="
            border-radius: 10px;
            background-color: #fff;
            font-size: 16px;
            font-weight: 700;
            color: #117a8b;
            padding: 40px;
            align-items: center;
            justify-content: center;
          "
        >
          No momento não existe vagas disponíveis para agendamentos online nesta
          Clínica, por favor tente novamente na proxima semana!
        </span>
      </div>
    </div>
    <div v-else class="container page-online">
      <header>
        <img src="../../assets/images/logo-white.png" alt="Logo da DoctorPep" />
      </header>
      <div
        style="
          display: flex;
          background-color: transparent;
          width: 100%;
          align-items: center;
          justify-content: center;
          height: 400px;
        "
        v-if="loading"
      >
        <b-spinner class="alignCenter" variant="primary"></b-spinner>
      </div>
      <div class="box-sections">
        <div :class="section1 ? 'section-active' : 'section-deactive'">
          <b-icon
            icon="journal-text"
            font-scale="1"
            :style="section1 ? 'color: white' : 'color:#b0b2b3'"
          ></b-icon>
          <span class="subtitles">Cadastro</span>
        </div>
        <div :class="section2 ? 'line-active' : 'line-deactive'"></div>
        <div :class="section2 ? 'section-active' : 'section-deactive'">
          <b-icon
            :style="section2 ? 'color: white' : 'color:#b0b2b3'"
            icon="clock-fill"
            font-scale="1"
          ></b-icon>
          <span class="subtitles">Horários</span>
        </div>
        <div :class="section3 ? 'line-active' : 'line-deactive'"></div>
        <div :class="section3 ? 'section-active' : 'section-deactive'">
          <b-icon
            :style="section3 ? 'color: white' : 'color:#b0b2b3'"
            icon="check-all"
            font-scale="1"
          ></b-icon>
          <span class="subtitles">Confirmação</span>
        </div>
      </div>
      <div class="card">
        <div v-show="section1" class="section">
          <div class="option">
            <div class="options-patient">
              <div>
                <input
                  style="margin-right: 2px"
                  v-model="paciente"
                  type="checkbox"
                />
                <span>Já sou paciente</span>
              </div>
              <div>
                <input
                  style="margin-right: 2px"
                  v-model="primeraConsulta"
                  type="checkbox"
                />
                <span>Primeira consulta</span>
              </div>
            </div>
            <div class="colunm">
              <div class="title-search">
                <span v-if="paciente === true">
                  Digite seus dados para a busca
                </span>
                <span v-if="primeraConsulta === true"> Digite seus dados </span>
              </div>
              <div class="data-search">
                <div class="field">
                  <label>Nome completo:</label>
                  <input
                    :id="error3 ? 'input-error' : ''"
                    :disabled="disabledInputs"
                    style="width: 100%"
                    v-model="form1.nomePaciente"
                    placeholder="digite seu nome completo"
                    v-uppercase
                    type="text"
                  />
                </div>
                <div class="field">
                  <label>Número celular:</label>
                  <input
                    :id="error5 ? 'input-error' : ''"
                    :disabled="disabledInputs"
                    v-model="form1.celularPaciente"
                    placeholder="digite seu numero"
                    v-mask="'(##)#####-####'"
                    type="text"
                  />
                </div>
                <div class="field">
                  <label>Convênio:</label>
                  <select
                    :id="error6 ? 'input-error' : ''"
                    :disabled="disabledInputs"
                    class="filed-select"
                    v-model="convenio"
                    placeholder="digite seu convênio"
                    type="text"
                  >
                    <option
                      v-for="(conven, index) in convenios"
                      :key="index"
                      :value="conven"
                    >
                      <span>{{ conven.title }} </span>
                    </option>
                  </select>
                </div>

                <button
                  v-if="paciente && mostrarBotaoBuscar"
                  :disabled="!paciente"
                  style="width: 160px; margin-top: 20px"
                  v-show="this.paciente"
                  @click="buscarPaciente()"
                  class="button-search"
                >
                  Buscar meus dados
                </button>
              </div>
              <div class="row">
                <div class="field">
                  <label>CPF:</label>
                  <input
                    :disabled="
                      disabledInputs ||
                      (isPacienteIdentificado === true &&
                        !primeraConsulta &&
                        form1.cpfPaciente !== '')
                    "
                    v-model="form1.cpfPaciente"
                    placeholder="digite seu CPF"
                    v-mask="'###.###.###-##'"
                    type="text"
                  />
                </div>
                <div class="field">
                  <label>Data de nascimento:</label>
                  <input
                    :id="error2 ? 'input-error' : ''"
                    :disabled="
                      disabledInputs ||
                      (isPacienteIdentificado === true &&
                        !primeraConsulta &&
                        form1.dataNascimentoPaciente !== '')
                    "
                    v-model="form1.dataNascimentoPaciente"
                    v-mask="'##/##/####'"
                    placeholder="dd/mm/aaaa"
                    type="text"
                  />
                </div>
              </div>
              <div class="row">
                <div class="field">
                  <label>E-mail:</label>
                  <input
                    :id="error4 ? 'input-error' : ''"
                    :disabled="
                      disabledInputs ||
                      (isPacienteIdentificado === true &&
                        !primeraConsulta &&
                        form1.emailPaciente !== '')
                    "
                    v-model="form1.emailPaciente"
                    placeholder="digite seu email"
                    type="text"
                  />
                </div>
              </div>
              <div
                style="
                  display: flex;
                  width: 100%;
                  justify-content: flex-start;
                  align-items: flex-start;
                "
              >
                <button
                  v-if="primeraConsulta"
                  style="width: 170px"
                  v-show="this.primeraConsulta"
                  @click="buscarPaciente()"
                  class="button-search"
                >
                  Cadastre-se
                </button>
              </div>
            </div>
            <button
              :disabled="
                (!isPacienteIdentificado && !primeraConsulta) ||
                !isSearchButtonEnabled
              "
              @click="searchHorarios()"
              class="button-search"
            >
              Buscar horários
            </button>
          </div>
        </div>
        <div v-show="section2" class="section">
          <div class="buttons-options">
            <button
              :class="option1 ? 'button-active ' : 'button-deactive'"
              @click="handleButtonOne()"
            >
              Presencial
            </button>
            <button
              :class="option2 ? 'button-active ' : 'button-deactive'"
              @click="handleButtonTwo()"
            >
              Telemedicina
            </button>
          </div>
          <div class="box-filter">
            <div v-if="linkEmptyIdMedico" class="field">
              <label>Profissional:</label>
              <select
                class="filed-select"
                v-model="doctor"
                placeholder="escolha seu profissional"
                type="text"
              >
                <option
                  v-for="doctor in listDoctors"
                  :key="doctor.idUsuario"
                  :value="doctor"
                >
                  <span>{{ doctor.nmUsuario }} </span>
                </option>
              </select>
            </div>

            <div class="field">
              <label>Especialidade:</label>
              <select
                class="filed-select"
                v-model="especialidade"
                placeholder="escolha a especialidade"
                type="text"
              >
                <option
                  v-for="especialidade in doctor.especialidadesMedica"
                  :key="especialidade.id"
                  :value="especialidade"
                >
                  <span>{{ especialidade.descCbo }} </span>
                </option>
              </select>
            </div>
          </div>
          <div class="info-clinic">
            <div class="field">
              <label>Clinica:</label>
              <span>{{ dataList?.[0]?.descClinica }}</span>
            </div>
            <div class="field">
              <label>Rua:</label>
              <span>{{ dataList?.[0]?.logradouro }}</span>
            </div>
            <div class="field">
              <label>Numero:</label>
              <span>{{ dataList?.[0]?.numero }}</span>
            </div>
            <div class="field">
              <label>Bairro:</label>
              <span>{{ dataList?.[0]?.bairro }}</span>
            </div>
          </div>
          <div v-if="listHorarios?.length === 0" class="empty">
            <span
              >Nenhum horário disponivel nessa data, por favor tente em outra
              data!</span
            >
          </div>
          <div v-else class="table-list">
            <div
              class="item-list"
              v-for="horario in listHorarios"
              :key="horario.data"
            >
              <span>{{ horario.data }}</span>
              <div class="list-horarios">
                <button
                  v-for="item in horario.horarios"
                  :key="item.id"
                  @click="selectHorario(item, horario.horarios, horario.data)"
                  :class="
                    item.check ? 'button-horario-active' : 'button-horario'
                  "
                >
                  {{ item.horario }}
                </button>
              </div>
            </div>
          </div>
          <div class="box-buttons-2">
            <button @click="backForForm()" class="button-search1">
              Voltar
            </button>
            <button @click="confirmAgend()" class="button-search2">
              Confirmar Agendamento
            </button>
          </div>
        </div>
        <div v-show="section3" class="section">
          <div class="box-success" v-if="loadingSuccess">
            <img
              class="imgSuccess"
              src="./imgs/sucesso.png"
              alt="Imagem de confirmação de sucesso"
            />
            <span>Sua consulta foi agendada com sucesso!</span>
          </div>
          <div v-else class="view-data">
            <div v-show="dataEnd !== null" class="view-data">
              <div
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <p
                  style="
                    font-size: 1rem;
                    color: #fff;
                    background-color: #117a8b;
                    width: 100%;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 6px;
                  "
                >
                  Confirme seu agendamento
                </p>
              </div>
              <div class="field">
                <label>Profissional:</label>
                <span>{{ dataEnd.profissional }}</span>
              </div>
              <div
                style="border-bottom: #b0b2b3 0.5px solid; width: 100%"
                class="field"
              >
                <label>Especialidade:</label>
                <span>{{ dataEnd.especialidade }}</span>
              </div>
              <div class="field">
                <label>Nome do paciente:</label>
                <span>{{ dataEnd.paciente }}</span>
              </div>
              <div
                style="border-bottom: #b0b2b3 0.5px solid; width: 100%"
                class="field"
              >
                <label>Data de Nascimento:</label>
                <span>{{ dataEnd.dataNascimento }}</span>
              </div>
              <div class="field">
                <label>Data que foi realizado o agendamento:</label>
                <span>{{ dataEnd.dataAgendamento }}</span>
              </div>
              <div class="field">
                <label>Horário:</label>
                <span>{{ dataEnd.horario }}</span>
              </div>
              <div
                style="border-bottom: #b0b2b3 0.5px solid; width: 100%"
                class="field"
              >
                <label>Tipo:</label>
                <span>{{ dataEnd.tipo }}</span>
              </div>
              <div class="field">
                <label>Localização:</label>
                <span>{{ dataEnd.localizacao }}</span>
              </div>
            </div>
            <div class="box-buttons-2">
              <button @click="backForForm2()" class="button-search1">
                Voltar
              </button>
              <button @click="agendarPaciente()" class="button-search2">
                Agendar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { formatHour } from '../../Utils/formatters'
import API from '../../services/index'
export default {
  props: ['idClinica', 'idMedico'],
  async mounted() {
    this.decodeBase64(this.idClinica, this.idMedico)
    await this.initSettingsPage()
  },
  data() {
    return {
      dateToView: new Date()
        .toLocaleDateString()
        .split('/')
        .reverse()
        .join('-'),

      loading: false,
      mostrarBotaoBuscar: false,
      isLoading: false,
      buscaPacienteFeita: false,
      nomePacienteAnterior: '',
      buscaPrimeiraConsultaFeita: false,
      isSearchButtonEnabled: false,
      loadingSuccess: false,
      loadingMessage: false,

      linkEmptyIdMedico: true,

      error2: false,
      error3: true,
      error4: false,
      error5: true,
      error6: true,

      section1: true,
      section2: false,
      section3: false,
      option1: true,
      option2: false,

      dataList: null,
      listConvenios: null,
      listDoctors: [],
      listHorarios: [],
      itemSelect: null,
      selectedDate: null,
      doctor: '',
      convenios: [],
      especialidade: null,

      idClinicaDecode: null,
      idUsuarioDecode: null,

      disabledButton: false,
      disabledInputs: false,
      paciente: true,
      primeraConsulta: false,
      convenio: '',
      newPaciente: null,
      dataEnd: {
        especialidade: '',
        paciente: '',
        dataNascimento: '',
        dataAgendamento: '',
        horario: '',
        tipo: '',
        localizacao: '',
        data: null
      },

      form1: {
        celularPaciente: '',
        cpfPaciente: '',
        dataNascimentoPaciente: '',
        emailPaciente: '',
        idClinica: 0,
        idConvenioPaciente: '',
        identificaoPaciente: '',
        idusuario: '',
        jaSouPaciente: 0,
        nomePaciente: ''
      },
      isPacienteIdentificado: false,
      buscarPacienteEmAndamento: false
    }
  },

  watch: {
    option1() {
      this.definedListHrDoctor(this.dataList)
    },
    doctor() {
      this.definedListHrDoctor(this.dataList)
    },

    paciente(value) {
      if (value === true) {
        this.clearForm()
        this.disabledInputs = false
        this.primeraConsulta = false
        this.error3 = true
        this.error5 = true
        this.error6 = true
        this.error2 = false
        this.error4 = false
        this.isPacienteIdentificado = false
        this.buscaPrimeiraConsultaFeita = false
      } else {
        this.paciente = false
        this.handlesDisabledInputs()
      }
    },
    primeraConsulta(value) {
      if (value === true) {
        this.clearForm()
        this.disabledInputs = false
        this.paciente = false
        this.error3 = true
        this.error5 = true
        this.error6 = true
        this.error2 = true
        this.error4 = true
        this.isSearchButtonEnabled = false
      } else {
        this.primeraConsulta = false
        this.handlesDisabledInputs()
      }
    },
    'form1.dataNascimentoPaciente'(value) {
      if (this.primeraConsulta) {
        if (value === '') {
          this.error2 = true
        } else {
          this.error2 = false
        }
      } else {
        this.error2 = false
      }
    },
    'form1.emailPaciente'(value) {
      if (this.primeraConsulta) {
        if (value === '') {
          this.error4 = true
        } else {
          this.error4 = false
        }
      } else {
        this.error2 = false
      }
    },

    'form1.nomePaciente'(value) {
      this.error3 = ''
      if (value === '') {
        this.error3 = true
        this.mostrarBotaoBuscar = false
      } else {
        this.error3 = false

        if (this.form1.celularPaciente.length === 14 && this.convenio) {
          this.mostrarBotaoBuscar = true
        } else {
          this.mostrarBotaoBuscar = false
        }
      }
      if (value === '' && this.form1.celularPaciente === '') {
        this.isPacienteIdentificado = false
        this.form1.cpfPaciente = ''
        this.form1.emailPaciente = ''
        this.form1.dataNascimentoPaciente = ''
      }
    },
    'form1.celularPaciente'(value) {
      if (value === '') {
        this.error5 = true
      } else {
        this.error5 = false
        if (this.form1.celularPaciente.length < 14 && this.convenio) {
          this.mostrarBotaoBuscar = false
        } else {
          if (this.form1.nomePaciente && this.convenio) {
            this.mostrarBotaoBuscar = true
          } else {
            this.mostrarBotaoBuscar = false
          }
        }
      }
      if (value === '' && this.form1.nomePaciente === '') {
        this.isPacienteIdentificado = false
        this.form1.cpfPaciente = ''
        this.form1.emailPaciente = ''
        this.form1.dataNascimentoPaciente = ''
      }
    },
    convenio(value) {
      if (value === '') {
        this.error6 = true
        this.mostrarBotaoBuscar = false
      } else {
        this.error6 = false
        if (this.form1.nomePaciente && this.form1.celularPaciente.length === 14)
          this.mostrarBotaoBuscar = true
        else {
          this.mostrarBotaoBuscar = false
        }
      }
    }
  },

  computed: {},
  methods: {
    handlesDisabledInputs() {
      if (this.paciente === false && this.primeraConsulta === false) {
        this.disabledInputs = true
      }
    },
    convertToUppercase(event) {
      this.form1.nomePaciente = event.target.value.toUpperCase()
    },
    nextDate() {
      const date = new Date(this.dateToView)

      this.dateToView = new Date(date.setDate(date.getDate() + 1))
        .toISOString()
        .substring(0, 10)
    },
    forwardDate() {
      const date = new Date(this.dateToView)
      this.dateToView = new Date(date.setDate(date.getDate() - 1))
        .toISOString()
        .substring(0, 10)
    },
    handleButtonOne() {
      if (this.option1 === false) {
        this.option1 = true
        this.option2 = false
      }
    },
    handleButtonTwo() {
      if (this.option2 === false) {
        this.option2 = true
        this.option1 = false
      }
    },
    searchHorarios() {
      if (
        this.form1.nomePaciente === '' ||
        this.form1.celularPaciente === '' ||
        this.convenio === ''
      ) {
        this.error2 = false
        this.error4 = false
        this.error3 = true
        this.error5 = true
        this.error6 = true
        this.$toast.warning(
          'Por favor verifique os campos obrigatorios necessarios para próximo passo!'
        )
      } else {
        if (
          this.primeraConsulta === true &&
          (this.newPaciente === null || this.newPaciente === '')
        ) {
          this.$toast.error(
            'Não é possivel passar para proxima etapa, por favor salve seus dados!'
          )
        } else if (
          this.paciente === true &&
          this.form1.identificaoPaciente === ''
        ) {
          this.$toast.error(
            'Não é possivel passar para proxima etapa, por favor busque pelos seus dados!'
          )
        } else {
          this.section1 = false
          this.section2 = true
          this.section3 = false
        }
      }
    },
    removeSpecialCharacters(str) {
      const cleanedStr = str.replace(/[()-.]/g, '')
      return cleanedStr
    },
    backForForm() {
      this.section1 = true
      this.section2 = false
      this.section2 = false
    },
    backForForm2() {
      this.section1 = false
      this.section2 = true
      this.section3 = false
    },
    async initSettingsPage() {
      this.loading = true
      this.linkEmptyIdMedico = true
      try {
        const response = await API.get(
          '/api/AgOnline/ObtemListaPorUsuarioClinica',
          {
            params: {
              identificacao_clinica: this.idClinica,
              identificacao_usuario: this.idMedico
            }
          }
        )
        this.dataList = response.data.object
        if (this.dataList === null) {
          this.disabledButton = true
          this.loading = true
          this.loadingMessage = true
        } else {
          this.definedListConvenios(this.dataList)
          this.definedListDoctors(this.dataList)
          this.definedListHrDoctor(this.dataList)
          this.loading = false
          this.loadingMessage = false
        }
      } catch (error) {
        this.disabledButton = true
        this.loading = true
        this.loadingMessage = true
        this.$toast.error(
          'Não há horários disponíveis para link de agendamento online!'
        )
      }
    },
    definedListConvenios(list) {
      const listConvenios = []
      if (this?.idUsuarioDecode === 0) {
        list.forEach((item) => {
          if (
            item.listaDadosConvenio?.length !== 0 &&
            item.listaDadosConvenio !== null
          ) {
            item.listaDadosConvenio.forEach((item) => {
              const object = {
                title: item.razaoSocial,
                idConvenio: item.id,
                idUsuario: item.idUsuario
              }
              listConvenios.push(object)
            })
          }
        })
      } else {
        list.forEach((item) => {
          item.listaDadosMedicosOnline.forEach((item) => {
            if (item.conveniosMedicos !== null) {
              item.conveniosMedicos.forEach((item) => {
                const object = {
                  title: item.nmConvenio,
                  idConvenio: item.idConvenio,
                  idUsuario: item.idUsuario
                }
                listConvenios.push(object)
              })
            }
          })
        })
      }

      this.convenios = listConvenios
    },
    definedListDoctors(list) {
      const listDoc = []
      list.forEach((item) => {
        item.listaDadosMedicosOnline.forEach((item) => {
          if (
            item.nomeUsuario !== null &&
            item?.idUsuario !== 0 &&
            item.idUsuario !== null
          ) {
            const object = {
              especialidadesMedica: item.especialidadesMedica,
              nmUsuario: item.nomeUsuario,
              idUsuario: item.idUsuario
            }
            listDoc.push(object)
          }
        })
      })
      this.listDoctors = listDoc
      this.doctor = this?.listDoctors?.[0]
      this.especialidade = this.doctor?.especialidadesMedica?.[0]
    },
    definedListHrDoctor(list) {
      let medico = null
      list.forEach((item) => {
        item.listaDadosMedicosOnline.forEach((item) => {
          if (item.idUsuario === this.doctor.idUsuario) {
            medico = item
          }
        })
      })
      let datas = []
      const uniqueDates = {}
      medico.horariosLivres.forEach((horario) => {
        const date = this.formatData(horario.dtAgendamento)
        uniqueDates[date] = true
      })
      datas = Object.keys(uniqueDates)

      const listHrDoc = []
      datas.forEach((data) => {
        const dataObject = {
          data: data,
          horarios: []
        }
        if (this.option1 === true) {
          medico.horariosLivres.forEach((item) => {
            if (
              this.formatData(item.dtAgendamento) === data &&
              item.idStatusAgenda === 18
            ) {
              const object = {
                id: item.id,
                nomeUsuario: medico.nomeUsuario,
                idUsuario: medico.idUsuario,
                idStatusAgenda: item.idStatusAgenda,
                horario: formatHour(item.horaAgendamento),
                endereco: `${this?.dataList?.[0].logradouro} ${this?.dataList?.[0].numero} - ${this?.dataList?.[0].bairro}`,
                descClinica: this?.dataList?.[0].descClinica,
                check: false
              }
              dataObject.horarios.push(object)
            }
          })
        } else {
          medico.horariosLivres.forEach((item) => {
            if (
              this.formatData(item.dtAgendamento) === data &&
              item.idStatusAgenda === 19
            ) {
              const object = {
                id: item.id,
                nomeUsuario: medico.nomeUsuario,
                idUsuario: medico.idUsuario,
                idStatusAgenda: item.idStatusAgenda,
                horario: formatHour(item.horaAgendamento),
                endereco: `${this?.dataList?.[0].logradouro} ${this?.dataList?.[0].numero} - ${this?.dataList?.[0].bairro}`,
                descClinica: this?.dataList?.[0].descClinica,
                check: false
              }
              dataObject.horarios.push(object)
            }
          })
        }
        listHrDoc.push(dataObject)
      })
      const listFilter = listHrDoc.filter((item) => item.horarios?.length > 0)
      listFilter.forEach((item) => {
        item.horarios.sort((objetoA, objetoB) =>
          objetoA.horario.localeCompare(objetoB.horario)
        )
      })
      this.listHorarios = listFilter
    },
    decodeBase64(idClinica, idUsuario) {
      this.idClinicaDecode = Number(atob(idClinica))
      this.idUsuarioDecode = Number(atob(idUsuario))
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return ''
      const cleaned = phoneNumber.replace(/\D/g, '')
      // Aplicar máscara (exemplo: (99) 99999-9999)
      const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`
      }
      return phoneNumber
    },
    async buscarPaciente() {
      if (this.paciente === true) {
        this.buscaPacienteFeita = true
        // this.buscaPrimeiraConsultaFeita = false
        if (
          this.form1.nomePaciente !== '' &&
          this.form1.celularPaciente !== '' &&
          this.convenio !== ''
        ) {
          const formattedDate = this.form1.dataNascimentoPaciente
            ? moment(this.form1.dataNascimentoPaciente, 'DD/MM/YYYY').format(
                'YYYY-MM-DDT00:00:00'
              )
            : ''
          const object = {
            jaSouPaciente: 1,
            identificaoPaciente: 0,
            cpfPaciente: this.removeSpecialCharacters(this.form1.cpfPaciente),
            nomePaciente: this.form1.nomePaciente,
            celularPaciente: this.removeSpecialCharacters(
              this.form1.celularPaciente
            ),
            idConvenioPaciente: this.convenio.idConvenio,
            emailPaciente: this.form1.emailPaciente,
            dataNascimentoPaciente: formattedDate,
            idUsuario: this.idUsuarioDecode,
            idClinica: Number(this.idClinicaDecode)
          }

          if (object.dataNascimentoPaciente === '' || null) {
            delete object.dataNascimentoPaciente
          }
          try {
            const response = await API.post(
              '/api/AgOnline/BuscarPacienteOnline',
              {
                appName: 'DoctorPep',
                appVersion: '10.8.4',
                object: object
              }
            )
            this.form1 = {
              ...response.data.object,
              dataNascimentoPaciente: response.data.object
                .dataNascimentoPaciente
                ? moment(response.data.object.dataNascimentoPaciente).format(
                    'DD/MM/YYYY'
                  )
                : '',
              celularPaciente: this.formatPhoneNumber(
                response.data.object.celularPaciente
              )
            }
            this.isPacienteIdentificado = true
            this.isSearchButtonEnabled = true
            this.$toast.success(response.data.message)
            if (response.data.object === true) {
              this.buscaPacienteFeita = false
            }
          } catch (error) {
            this.$toast.error(
              'Dados não encontrado, revise as informações ou entre em contato com o consultório'
            )
            this.limparFormulario()
          } finally {
            this.isLoading = false
          }
        } else {
          this.error3 = true
          this.error5 = true
          this.error6 = true
          this.$toast.warning('Por favor, verifique os campos obrigatórios.')
        }
      }
      if (this.primeraConsulta === true) {
        // this.buscaPrimeiraConsultaFeita = true
        // this.buscaPacienteFeita = false
        if (
          this.form1.nomePaciente !== '' ||
          this.form1.celularPaciente !== '' ||
          this.convenio !== '' ||
          this.form1.emailPaciente !== '' ||
          this.form1.dataNascimentoPaciente !== '' ||
          this.form1.cpfPaciente !== ''
        ) {
          try {
            const object = {
              jaSouPaciente: 0,
              identificaoPaciente: 0,
              cpfPaciente: this.removeSpecialCharacters(this.form1.cpfPaciente),
              nomePaciente: this.form1.nomePaciente,
              celularPaciente: this.removeSpecialCharacters(
                this.form1.celularPaciente
              ),
              idConvenioPaciente: this.convenio.idConvenio,
              emailPaciente: this.form1.emailPaciente,
              dataNascimentoPaciente:
                this.form1.dataNascimentoPaciente === ''
                  ? ''
                  : moment(
                      this.form1.dataNascimentoPaciente,
                      'DD/MM/YYYY'
                    ).format('YYYY-MM-DDTHH:mm:ss'),
              idUsuario: this.idUsuarioDecode,
              idClinica: Number(this.idClinicaDecode)
            }

            if (object.dataNascimentoPaciente === '' || null) {
              delete object.dataNascimentoPaciente
            }
            const response = await API.post(
              '/api/AgOnline/BuscarPacienteOnline',
              {
                appName: 'DoctorPep',
                appVersion: '10.8.4',
                object: object
              }
            )
            this.newPaciente = response.data.object
            this.isSearchButtonEnabled = true
            this.$toast.success(response.data.message)
          } catch (error) {
            this.newPaciente = null
            this.$toast.error(
              'Não foi possivel salvar seu cadastro, por favor tente novamente em alguns minutos!'
            )
            this.buscaPrimeiraConsultaFeita = false
          }
        } else {
          this.error2 = true
          this.error3 = true
          this.error4 = true
          this.error5 = true
          this.error6 = true
          this.$toast.warning('Por favor verifique os campos obrigatorios')
        }
      }
    },
    limparFormulario() {
      this.form1.cpfPaciente = ''
      this.form1.dataNascimentoPaciente = ''
      this.form1.emailPaciente = ''
    },

    clearForm() {
      this.form1 = {
        celularPaciente: '',
        cpfPaciente: '',
        dataNascimentoPaciente: '',
        emailPaciente: '',
        idClinica: 0,
        idConvenioPaciente: '',
        identificaoPaciente: '',
        idusuario: '',
        jaSouPaciente: 0,
        nomePaciente: ''
      }
      this.convenio = ''
      this.newPaciente = ''
    },
    formatData(data) {
      return moment(data).format('DD/MM/YYYY')
    },
    formatHour(data) {
      return moment(data).format('HH:mm')
    },
    selectHorario(item, horarios, data) {
      if (item.check === true) {
        item.check = false
        this.itemSelect = null
        this.selectedDate = null
        this.selectedHorario = null
      } else {
        item.check = true
        this.itemSelect = item
        this.selectedDate = data
        this.selectedHorario = item.horario
        horarios.forEach((horario) => {
          if (horario.id !== item.id) {
            horario.check = false
          }
        })
      }
    },
    async agendarPaciente() {
      try {
        await API.post('api/AgOnline/Adicionar', {
          appName: 'Doctor Pep',
          appVersion: '10.8.4',
          object: this.dataEnd.data
        })
        this.loadingSuccess = true
      } catch (error) {
        this.loadingSuccess = false
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        this.$toast.error(errorMessage)
      }
    },
    confirmAgend() {
      const response = this.itemSelect
      if (response !== null && response !== undefined) {
        if (this.paciente !== false) {
          const object = {
            profissional: this.itemSelect.nomeUsuario,
            especialidade: this.especialidade.descCbo,
            paciente: this.form1.nomePaciente,
            dataNascimento: this.form1.dataNascimentoPaciente,
            dataAgendamento: this.selectedDate,
            horario: this.itemSelect.horario,
            tipo: this.option1 === true ? 'Presencial' : 'Telemedicina',
            localizacao: `${this.itemSelect.endereco} - ${this.itemSelect.descClinica}`,
            data: {
              idAgenda: this.itemSelect.id,
              idStatusAgenda: this.itemSelect.idStatusAgenda,
              idClinica: this.form1.idClinica,
              idPaciente: this.form1.identificaoPaciente,
              idConvenio: this.convenio.idConvenio,
              idUsuario: this.form1.idusuario,
              idEspeciailidadeMedica: this.especialidade.id
            }
          }
          this.dataEnd = object
          this.section1 = false
          this.section2 = false
          this.section3 = true
        }
        if (this.primeraConsulta !== false) {
          const object = {
            profissional: this.itemSelect.nomeUsuario,
            especialidade: this.especialidade.descCbo,
            paciente: this.form1.nomePaciente,
            dataNascimento: this.formatData(this.form1.dataNascimentoPaciente),
            dataAgendamento: this.selectedDate,
            horario: this.itemSelect.horario,
            tipo: this.option1 === true ? 'Presencial' : 'Telemedicina',
            localizacao: `${this.itemSelect.endereco} - ${this.itemSelect.descClinica}`,
            data: {
              idAgenda: this.itemSelect.id,
              idStatusAgenda: this.itemSelect.idStatusAgenda,
              idClinica: this.newPaciente.idClinica,
              idPaciente: this.newPaciente.identificaoPaciente,
              idConvenio: this.newPaciente.idConvenioPaciente,
              idUsuario: this.newPaciente.idusuario,
              idEspeciailidadeMedica: this.especialidade.id
            }
          }
          this.dataEnd = object
          this.section1 = false
          this.section2 = false
          this.section3 = true
        }
      } else {
        this.$toast.warning('É necessario escolher um horário para prosseguir!')
      }
    }
  }
}
</script>
<style scoped>
.col-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.colunm {
  margin-top: 20px;
  margin-bottom: 46px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.container {
  max-width: 1444px;
  margin: 0px auto;
}

.page-online {
  width: 100%;
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.page-online header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 10px;
  background-color: #117a8b;
  color: white;
}

.page-online header img {
  width: 16%;
}

.box-sections {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.section-active {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 16px;
  color: white;
  border-radius: 10px;
  background-color: #117a8b;
  border: 2.5px solid #117a8b;
}

.section-deactive {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px;
  color: #b0b2b3;
  border-radius: 10px;
  background-color: transparent;
  border: 2.5px solid #b0b2b3;
}

.line-active {
  height: 0px;
  border: 2.5px dashed #117a8b;
  width: 20%;
}

.line-deactive {
  height: 0px;
  border: 2.5px dashed #b0b2b3;
  width: 20%;
}

.card {
  width: 100%;
  background-color: rgb(243, 242, 242);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}

button {
  background-color: transparent;
  padding: 20px 12px;
}

#input-error {
  padding: 10px 12px;
  border-radius: 10px;
  border: #b0b2b3 1px solid;
  border: red 1px solid;
}

#input-error:focus {
  outline: none;
  border: red 1px solid;
}

input {
  padding: 10px 12px;
  border-radius: 10px;
  border: #b0b2b3 1px solid;
  text-transform: uppercase !important;
}

.section .buttons-options {
  width: 100%;
  display: flex;
  align-items: center;
}

.button-active {
  flex: 1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #117a8b;
  font-size: 0.8rem;
  color: #fff;
  border: none;
  border-bottom: #117a8b solid 2px;
}
.button-deactive {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex: 1;
  color: #117a8b;
  font-size: 0.8rem;
  border: none;
}
.option {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.options-patient {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-bottom: #b0b2b3 0.5px solid;
}

.data-search {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: #b0b2b3 0.5px solid;
}

.title-search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.button-search {
  width: 100%;
  padding: 12px 12px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #fff;
  background-color: #117a8b;
  border: none;
}
.button-search:disabled {
  background-color: #ccc;
  color: #666;
}
.box-filter {
  gap: 10px;
  width: 100%;
  padding: 0px 20px;
  flex-wrap: wrap;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.inputDataArrow {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.inputDataArrow li {
  cursor: pointer;
  list-style-type: none;
  font-size: 20px;
  color: #777d74;
  margin: 0 1px 0 0;
  float: left;
  padding: 0;
}

.info-clinic {
  margin: 20px;
  width: 95%;
  border-radius: 10px;
  color: #000;
  border: #117a8b 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.empty {
  height: 400px;
  width: 100%;
  font-weight: 700;
  color: #117a8b;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.error {
  color: red;
  font-style: oblique;
}

.table-list {
  overflow-x: scroll;
  padding: 0px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  display: flex;
  height: 400px;
}

.item-list {
  align-items: center;
  padding: 0px 20px;
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-horario {
  width: 100px;
  border-radius: 10px;
  border: 1px #117a8b solid;
  padding: 8px 12px;
  background-color: transparent;
  color: #117a8b;
  cursor: pointer;
  font-weight: 700;
}

.list-horarios {
  overflow-y: scroll;
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  gap: 8px;
}

.button-horario-active {
  cursor: pointer;
  border-radius: 10px;
  border: 1px #117a8b solid;
  padding: 8px 12px;
  background-color: #117a8b;
  color: #fff;
  font-weight: 700;
}

.button-check-active {
  padding: 6px 10px;
  border: 1px solid #117a8b;
  background-color: #117a8b;
  border-radius: 10px;
  color: #fff;
}

.button-check-deactive {
  padding: 6px 10px;
  border: 1px solid #b0b2b3;
  background-color: transparent;
  border-radius: 10px;
  color: #b0b2b3;
}
.box-buttons-2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  font-size: 0.8rem;
  gap: 10px;
}

.button-search1 {
  padding: 12px 12px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #fff;
  background-color: #117a8b;
  border: none;
}
.button-search2 {
  width: 30%;
  padding: 12px 12px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #fff;
  background-color: #117a8b;
  border: none;
}

.filed-select {
  padding: 10px 12px;
  border-radius: 10px;
  border: #b0b2b3 1px solid;
}

.view-data {
  font-size: 0.9rem;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.box-success {
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(2, 150, 2);
}

.box-success img {
  width: 50%;
}

@media (max-width: 720px) {
  .page-online header img {
    width: 20%;
  }
  .section-active {
    font-size: 0.8rem;
  }
  .option {
    font-size: 0.7rem;
  }

  .filed-select {
    padding: 8px 10px;
  }
  .subtitles {
    display: none;
  }

  .line-active {
    height: 0px;
    border: 1.8px dashed #117a8b;
    width: 20%;
  }

  .line-deactive {
    height: 0px;
    border: 1.8px dashed #b0b2b3;
    width: 20%;
  }

  .section-deactive {
    font-size: 0.8rem;
  }
  .button-search1 {
    width: 100%;
  }
  .button-search2 {
    width: 100%;
  }
  button {
    padding: 14px 12px;
  }

  input {
    padding: 8px 10px;
  }

  .button-active {
    flex: 1;
    font-size: 0.7rem;
  }
  .button-deactive {
    font-size: 0.7rem;
  }
}

@media (max-width: 460px) {
  .page-online header img {
    width: 26%;
  }
  .button-active {
    flex: 1;
    font-size: 0.6rem;
  }
  .table-list {
    font-size: 0.6rem;
  }
  .option {
    font-size: 0.6rem;
  }
  .button-deactive {
    font-size: 0.6rem;
  }
  .button-search {
    font-size: 0.6rem;
  }
  .button-search1 {
    font-size: 0.6rem;
    padding: 10px;
  }
  .button-search2 {
    font-size: 0.6rem;
    padding: 10px;
  }
  .section-deactive {
    padding: 10px;
    font-size: 0.7rem;
  }
  .section-active {
    padding: 10px;
    font-size: 0.7rem;
  }
}
</style>
