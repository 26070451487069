<template>
  <b-modal
    id="user-preferences__modal"
    class="justify-center"
    title="Preferências"
    title-class="text-light"
    header-bg-variant="primary"
    size="xl"
    hide-footer
  >
    <div class="medicalreport-type-container">
      <h4>Modo de exibição de prontuário</h4>

      <div class="medicalreport-type-buttons-container">
        <div
          class="change-medical-report-type-load"
          v-if="changeMedicalReportTypeLoading"
        >
          <b-spinner large variant="primary" />
        </div>
        <b-button
          @click="changeMedicalReportType(2)"
          style="width: 250px"
          :variant="`${getMedicalReportType === 2 ? 'primary' : 'light'}`"
          class="d-flex align-items-center align-items-center mr-4 pl-4 pr-4"
        >
          <div
            :class="`rounded-circle iq-card-icon ${
              getMedicalReportType === 2 ? 'bg-light' : 'bg-primary'
            } align-items-center justify-content-center d-flex mr-3`"
          >
            <i
              class="fas fa-folder mb-1 ml-1"
              :style="`color: ${
                getMedicalReportType === 2 ? '#089bab' : 'white'
              }; font-size: 35px`"
            />
          </div>
          <span style="font-weight: 600; font-size: 14px">Horizontal</span>
        </b-button>

        <b-button
          @click="changeMedicalReportType(1)"
          style="width: 250px"
          :variant="`${getMedicalReportType === 1 ? 'primary' : 'light'}`"
          class="d-flex align-items-center align-items-center pl-4 pr-4"
        >
          <div
            :class="`rounded-circle iq-card-icon ${
              getMedicalReportType === 1 ? 'bg-light' : 'bg-primary'
            } align-items-center justify-content-center d-flex mr-3`"
          >
            <i
              class="fas fa-list mb-1 ml-1"
              :style="`color: ${
                getMedicalReportType === 1 ? '#089bab' : 'white'
              }; font-size: 35px`"
            />
          </div>
          <span style="font-weight: 600; font-size: 14px">Vertical</span>
        </b-button>
      </div>
    </div>

    <div style="background-color: #fff; color: #000">
      <div class="modal-info">
        <p class="modal-info__name">Meu prontuário</p>
        <p class="modal-info__edit">Editar nome</p>
        <p class="modal-info__sequence">Sequência</p>

        <p class="modal-info__open">Aberto</p>
        <p class="modal-info__active">Ativo</p>
        <p class="modal-info__color">Cor</p>
      </div>
      <component
        v-for="module in modules"
        :modulesLength="modules.length"
        :key="module.id"
        :module="module"
        :is="module.userPreferenceComponent"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapModuleToComponent } from '../../Utils/mappers'
import { mapActions, mapGetters } from 'vuex'
import ProblemsUserPreference from './modules/Problems/index'
import AllergyUserPreference from './modules/Allergy/index'
import MedicationHistoryUserPreference from './modules/MedicationHistory/index'
import ExamsHistoryUserPreference from './modules/ExamsHistory/index'
import PersonalAndFamiliarHistoryUserPreference from './modules/PersonalAndFamiliarHistory/index'
import ComplaintsUserPreference from './modules/Complaints/index'
import PhysicalExamUserPreference from './modules/PhysicalExam/index'
import VitalSignsUserPreference from './modules/VitalSigns/index'
import ConductUserPreference from './modules/Conduct/index'
import FilesAndImagesUserPreference from './modules/FilesImages/index'
import MemedIntegrationUserPreference from './modules/MemedIntegration/index.vue'
import FormsUserPreference from './modules/Forms'
import ForwardingsUserPreference from './modules/Forwardings'
import AsoUserPreference from './modules/ASO'
import PregnantUserPreference from './modules/Pregnant'
import TrackingUserPreference from './modules/Tracking/index'

export default {
  name: 'UserPreferencesModal',
  components: {
    ProblemsUserPreference,
    AllergyUserPreference,
    MedicationHistoryUserPreference,
    ExamsHistoryUserPreference,
    PersonalAndFamiliarHistoryUserPreference,
    ComplaintsUserPreference,
    PhysicalExamUserPreference,
    VitalSignsUserPreference,
    ConductUserPreference,
    TrackingUserPreference,
    MemedIntegrationUserPreference,
    FilesAndImagesUserPreference,
    FormsUserPreference,
    ForwardingsUserPreference,
    AsoUserPreference,
    PregnantUserPreference
  },
  data() {
    return {
      changeMedicalReportTypeLoading: false
    }
  },
  computed: {
    ...mapGetters({
      allModules: 'MedicalReports/allModules',
      getMedicalReportType: 'MedicalReports/getMedicalReportType',
      user: 'Setting/user'
    }),
    modules: {
      get() {
        return mapModuleToComponent(this.allModules)
      },
      set(value) {
        this.$store.commit('MedicalReports/updateModules', value)
      }
    }
  },
  methods: {
    ...mapActions({
      getMedicalReportTypePreference:
        'MedicalReports/getMedicalReportTypePreferenceAction',
      changeMedicalReportTypeAction:
        'MedicalReports/changeMedicalReportTypePreferenceAction'
    }),
    async changeMedicalReportType(id) {
      this.changeMedicalReportTypeLoading = true
      await this.changeMedicalReportTypeAction(id)
      this.changeMedicalReportTypeLoading = false
    }
  },

  async mounted() {
    await this.getMedicalReportTypePreference({
      idUser: this.user.id
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-info {
  display: grid;
  grid-template-columns: 5fr repeat(5, 2fr) 1fr;
  text-align: center;

  padding-left: 20px;
  &__active,
  &__open {
    align-items: center;
  }
}

.medicalreport-type-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.medicalreport-type-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 8px 0 8px 0;
}

.change-medical-report-type-load {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.15);
  backdrop-filter: blur(5px);
}
</style>
