const PRESCRIPTIONS_MODULE_ID = 9

const mutations = {
  loadPrescriptions(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PRESCRIPTIONS_MODULE_ID
    )

    module.data = payload
  },

  editPrescription(state, payload) {
    const module = state.modules.find(
      (module) => module.idModulo === PRESCRIPTIONS_MODULE_ID
    )

    const index = module.data.findIndex((element) => {
      return element.id === payload.id
    })

    if (index !== -1) {
      module.data.splice(index, 1, { ...payload, modified: true })
    }
  },

  removePrescriptions(state, idPrescription) {
    const module = state.modules.find(
      (module) => module.idModulo === PRESCRIPTIONS_MODULE_ID
    )

    const filtered = module.data.filter(
      (element) => element.idPrescricaoMemed !== idPrescription
    )

    module.data = filtered
  },

  changePrescriptionsCanOpen(state, data) {
    state.prescriptionsCanOpen = data
  },
  changePrescriptionsIsOpened(state, data) {
    state.prescriptionsIsOpened = data
  }
}

export default mutations
