const ALLERGY_MODULE_ID = 2

const getters = {
  getAllergies(state) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )

    return module.data
  }
}

export default getters
