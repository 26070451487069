import API from '../../../../services/index'

const actions = {
  async getPrescriptionHistoryAction(context, payload) {
    const { idClinic, idMedic, idPatient } = payload
    try {
      const response = await API.get(
        '/api/ProntuarioMedicamento/ObtemMedicamentoPorPaciente',
        {
          params: {
            id_clinica: idClinic,
            id_medico: idMedic,
            id_paciente: idPatient
          }
        }
      )

      context.commit('loadPrescriptions', { payload: response.data.object })
    } catch {
      return false
    }
  },

  async editPrescriptionFromHandbook(context, { payload }) {
    try {
      await API.put('/api/ProntuarioMedicamento/Editar', {
        object: payload
      })
    } catch {}
  },

  async removePrescriptionFromHandbook(context, { payload }) {
    const object = {
      id_prescricao_memed: payload.idPrescription,
      id_prontuario: payload.idProntuario,
      token_medico_memed: payload.token
    }
    try {
      await API.delete('/api/ProntuarioMedicamento/Remover', {
        params: object
      })

      context.commit('removePrescriptions', payload.idPrescription)
    } catch {}
  },

  editPrescriptionAction(context, payload) {
    context.commit('editPrescription', payload)
  },

  disablePrescriptionAction(context, payload) {
    const object = {
      ...payload,
      ativo: 1
    }

    context.commit('editPrescription', object)
  },
  async enablePrescriptionAction(context, payload) {
    const object = {
      ...payload,
      ativo: 0
    }

    context.commit('editPrescription', object)
  },

  changePrescriptionsCanOpenAction(context, data) {
    context.commit('changePrescriptionsCanOpen', data)
  },
  changePrescriptionsIsOpenedAction(context, data) {
    context.commit('changePrescriptionsIsOpened', data)
  }
}

export default actions
