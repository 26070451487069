<template>
  <b-modal
    id="preview-bigger"
    header-bg-variant="primary"
    header-text-variant="white"
    hide-footer
    size="xl"
    @ok="closeModal"
    @close="closeModal"
    :title="file.descArquivoImagem"
    ok-only
    :scrollable="true"
  >
    <b-img
      class="img"
      v-if="preview === 'image'"
      src=""
      id="previewBigger"
      alt="previsualização da imagem"
    />
    <b-embed v-else-if="preview === 'pdf'" src="" id="previewBigger"> </b-embed>
  </b-modal>
</template>
<script>
export default {
  name: 'PreviewBiggerModal',
  props: ['file'],
  data() {
    return {
      preview: ''
    }
  },
  mounted() {
    this.$bvModal.show('preview-bigger')
    this.createFileReader(this.file)
  },
  methods: {
    createFileReader(file) {
      file.file.type === 'image/jpeg' || file.file.type === 'image/png'
        ? (this.preview = 'image')
        : file.file.type === 'application/pdf'
        ? (this.preview = 'pdf')
        : (this.preview = '')
      const reader = new FileReader()

      reader.onload = function (e) {
        document
          .getElementById('previewBigger')
          .setAttribute('src', e.target.result)
      }
      reader.readAsDataURL(file.file)
    },
    closeModal() {
      this.$bvModal.hide('preview-bigger')
      this.$emit('closeModal', 'preview-bigger')
    }
  }
}
</script>
<style>
#preview-bigger h5 {
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style scoped>
.img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
