<template>
  <b-modal
    id="compare-images"
    title="Comparar imagens"
    header-bg-variant="primary"
    header-text-variant="white"
    no-close-on-backdrop
    no-close-on-esc
    ok-title="Fechar"
    ok-only
    size="xl"
    @ok="resetModal"
    @close="resetModal"
  >
    <div class="gridCompare">
      <div class="item" v-for="(file, index) in listFiles" :key="file.size">
        <div
          class="itemDescription"
          :style="`background-color: ${componentInfo.corHexadecimal}`"
        >
          <span
            >{{ formatDate(file.dtCadastro, 'pt-BR') }} -
            {{ file.descArquivoImagem }}</span
          >
        </div>

        <b-img
          class="img"
          :src="previewFile(file, index)"
          :id="index"
          alt="image"
        ></b-img>
      </div>
    </div>
  </b-modal>
</template>

<script>
import API from '../../../../../services/index'

export default {
  name: 'CompareFilesModal',
  props: ['listFilesToCompare', 'componentInfo'],
  data() {
    return {
      listFiles: []
    }
  },
  created() {
    this.sortFiles()
  },
  mounted() {
    this.$bvModal.show('compare-images')
  },
  methods: {
    sortFiles() {
      this.listFiles = [...this.listFilesToCompare].sort((a, b) => {
        return new Date(a.dtCadastro) - new Date(b.dtCadastro)
      })
    },
    createFileReader(file, index) {
      file.type === 'image/jpeg' || file.type === 'image/png'
        ? (this.preview = 'image')
        : (this.preview = 'pdf')
      const reader = new FileReader()
      reader.onload = function (e) {
        document.getElementById(index).setAttribute('src', e.target.result)
      }
      reader.readAsDataURL(file)
    },
    previewFile(file, index) {
      if (file.file) {
        this.createFileReader(file.file, index)
      } else {
        API.get(
          `/api/ProntuarioArquivoImagem/AwsS3ObtemArquivo/?arquivoname=${file.descAwsArquivoImagem}&prontuario_id=${file.idProntuario}`,
          { responseType: 'blob' }
        ).then((res) => {
          this.createFileReader(res.data, index)
        })
      }
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    resetModal() {
      this.$bvModal.hide('compare-images')
      this.$emit('closeModal', 'compare-images')
    }
  }
}
</script>

<style>
#compare-images h5 {
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style scoped>
.gridCompare {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
}

.item {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #000;
}

.itemDescription {
  font-size: 13px;
  font-weight: 500;
  padding: 8px 0 10px 8px;
  display: flex;
  width: 100%;
  color: #fff;
  border-radius: 4px 4px 0 0;
}

.img {
  border-top: 1px solid #ccc;
  width: 100%;
  height: 95%;
}
</style>
