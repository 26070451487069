import { formatDate } from '../../Utils/formatters'
import API from '../../services/index'
import store from '../../store/index'

const serviceMinScript =
  'https://memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js'

export function initPrescriptionService(payload) {
  const {
    id,
    nome,
    telCelular,
    nomeMae,
    cpf,
    dtNascimento,
    logradouro,
    numero,
    descCidade
  } = payload.patient

  const requestObject = {
    nome,
    telefone: telCelular,
    nome_mae: nomeMae,
    idExterno: id,
    cpf,
    endereco: `${logradouro}, ${numero}`,
    cidade: descCidade,
    data_nascimento: dtNascimento ? formatDate(dtNascimento, 'pt-BR') : null
  }

  const clinicInfo = {
    city: payload.clinicInfo.descCidade,
    state: payload.clinicInfo.descSiglaEstado,
    cnes: payload.clinicInfo.cnes,
    local_name: payload.clinicInfo.descClinica,
    address: `${payload.clinicInfo.cep}, ${payload.clinicInfo.bairro}, ${payload.clinicInfo.logradouro}, ${payload.clinicInfo.numero}`,
    phone: payload.clinicInfo.telefone
  }

  Object.keys(requestObject).forEach((key) => {
    if (!requestObject[key]) {
      delete requestObject[key]
    }
  })

  const { cor_primaria_em_hexadecimal: _, ...features } = payload.memedParams

  let script = document.getElementById('script-memed')
  if (!script) {
    script = document.createElement('script')
    script.setAttribute('id', 'script-memed')
  }
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('data-color', payload.primaryColor)
  script.setAttribute('data-token', payload.token)
  script.src = serviceMinScript

  script.onload = async (data) => {
    window.MdSinapsePrescricao.event.add(
      'core:moduleInit',
      function moduleInitHandler(module) {
        if (module.name === 'plataforma.prescricao') {
          window.MdHub.event.add(
            'prescricaoImpressa',
            function (prescriptionData) {
              const currentHandbookId = window.currentHandbookId // Capture the current handbookId here
              const { id } = prescriptionData.prescricao
              API.post(
                `/api/PrescricaoMemed/RecuperarPrescricaoMemed?id_prescricao_memed=${id}&id_prontuario=${currentHandbookId}&token_medico_memed=${payload.token}`
              )

              API.post(
                `/api/PrescricaoMemed/RecuperarDadosPacienteMEMED?id_prescricao_memed=${id}&id_prontuario=${currentHandbookId}&token_medico_memed=${payload.token}`
              )

              API.post(
                `/api/PrescricaoMemed/RecuperarDadosAlergiaPacienteMEMED?id_prescricao_memed=${id}&id_prontuario=${currentHandbookId}&token_medico_memed=${payload.token}`
              )
            }
          )

          window.MdHub.command.send(
            'plataforma.prescricao',
            'setFeatureToggle',
            features
          )

          window.MdHub.command.send(
            'plataforma.prescricao',
            'setWorkplace',
            clinicInfo
          )

          window.MdHub.command
            .send('plataforma.prescricao', 'setPaciente', requestObject)
            .then(() => {
              updateAllergies()
              store.dispatch(
                'MedicalReports/changePrescriptionsCanOpenAction',
                true
              )
            })
        }
      }
    )

    window.MdSinapsePrescricao.event.add(
      'core:moduleHide',
      function moduloFechado(modulo) {
        if (modulo.moduleName === 'plataforma.prescricao') {
          store.dispatch(
            'MedicalReports/changePrescriptionsIsOpenedAction',
            false
          )
        }
      }
    )
  }

  const body = document.querySelector('body')
  body.appendChild(script)
}

export function updateAllergies() {
  if (window.MdHub) {
    const allergies = store.getters['MedicalReports/getAllergies']

    const medicineCodes = allergies.map((item) => {
      return item.codMedicamento
    })
    const validCodes = medicineCodes.filter((item) => !!item)
    window.MdHub.command.send('plataforma.prescricao', 'setAllergy', validCodes)
  }
}

export async function updatePatient(patient) {
  const { id, nome, telCelular, nomeMae, cpf, dtNascimento } = patient
  if (window.MdHub) {
    const requestObject = {
      nome,
      telefone: telCelular,
      nome_mae: nomeMae,
      idExterno: id,
      cpf,
      data_nascimento: dtNascimento ? formatDate(dtNascimento, 'pt-BR') : null
    }

    Object.keys(requestObject).forEach((key) => {
      if (!requestObject[key]) {
        delete requestObject[key]
      }
    })

    await window.MdHub.command.send(
      'plataforma.prescricao',
      'setPaciente',
      requestObject
    )
  }
}

export function openPrescriptionService() {
  if (window.MdHub) {
    window.MdHub.module.show('plataforma.prescricao')
    store.dispatch('MedicalReports/changePrescriptionsIsOpenedAction', true)
  }
}

export async function deletePrescriptionService() {
  if (window.MdHub) {
    const script = document.getElementById('script-memed')
    script.remove()

    await window.MdHub.command.send('plataforma.sdk', 'logout')
    await window.MdHub.server.unbindEvents()
    delete window.MdHub
  }
}
