import API from '../../../../services/index'

const actions = {
  async addAllergyToHandBook({ commit }, { payload }) {
    try {
      await API.post('/api/ProntuarioAlergia/Adicionar', {
        object: payload
      })
    } catch (error) {}
  },

  async editAllergyFromHandBook({ commit }, { payload }) {
    try {
      await API.put('/api/ProntuarioAlergia/Editar', {
        object: payload
      })
    } catch (error) {}
  },

  async removeAllergyFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/ProntuarioAlergia/Remover', {
        data: { object: payload }
      })
    } catch (error) {}
  },
  async addAllergyToFavoritesHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some(
      (favorite) => favorite.idMedicamento === payload.idMedicamento
    )

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        await API.put('/api/FavoritoAlergia/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        const apiResponse = await API.post('/api/FavoritoAlergia/Adicionar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async addAllergyToQuickAccessHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some(
      (favorite) => favorite.idMedicamento === payload.idMedicamento
    )

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        await API.put('/api/FavoritoAlergia/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...requestObject }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        const apiResponse = await API.post('/api/FavoritoAlergia/Adicionar', {
          object: requestObject
        })
        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async removeAllergyFavoriteFromHandBook({ commit }, { payload }) {
    const { location, ...newPayload } = payload
    try {
      await API.delete('/api/FavoritoAlergia/Remover', {
        data: {
          object: newPayload
        }
      })
      commit({ type: 'removeFromFavoriteState', payload: newPayload })
    } catch (error) {}
  }
}

export default actions
