import MedicalReportActions from './actions'
import MedicalReportMutations from './mutations'
import MedicalReportGetters from './getters'

import PhysicalExamState from './submodules/physicalExam/state'
import ConductState from './submodules/conduct/state'
import ComplaintsState from './submodules/complaints/state'
import PersonalHistoryState from './submodules/personalHistory/state'
import FilesAndImages from './submodules/files&images/state'
import VitalSignsState from './submodules/vitalSigns/state'
import PrescriptionsState from './submodules/prescriptions/state'
import PregnantState from './submodules/pregnancy/state'
import TrackingState from './submodules/tracking/state'

const initialState = () => ({
  modules: [],
  ...PhysicalExamState,
  ...ConductState,
  ...ComplaintsState,
  ...PersonalHistoryState,
  ...FilesAndImages,
  ...VitalSignsState,
  ...PrescriptionsState,
  ...PregnantState,
  ...TrackingState,
  modulesStateChanged: false,
  medicalReportType: {
    idModelo: 1
  },
  medicalReports: []
})

const state = initialState()

export default {
  namespaced: true,
  state,
  getters: {
    ...MedicalReportGetters
  },
  actions: {
    ...MedicalReportActions
  },
  mutations: {
    ...MedicalReportMutations,
    resetState(state) {
      state = initialState()
    }
  }
}
