<template>
  <b-container>
    <b-modal
      @shown="focusInput"
      id="find-patient"
      ref="find-patient"
      hide-footer
      title="Pesquisar paciente"
      title-class="text-light"
      header-bg-variant="primary"
      cancel-title="Não"
      ok-title="Sim"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      @close="closeModal"
    >
      <b-col md="12">
        <b-row style="margin-bottom: 20px">
          <b-col lg="4">
            <v-select
              v-if="isManager"
              v-model="selectedDoctor"
              :options="[...doctors, { nmUsuario: 'TODOS' }]"
              label="nmUsuario"
              :clearable="false"
            />

            <b-form-input v-else :value="selectedDoctor.nmUsuario" disabled />
          </b-col>
          <b-col lg="4">
            <v-select
              v-if="!isManager"
              v-model="selectedClinic"
              :options="[...clinics, { nmClinica: 'TODAS' }]"
              label="nmClinica"
              :clearable="false"
            />
            <b-form-input v-else :value="selectedClinic.nmClinica" disabled />
          </b-col>
        </b-row>

        <b-row style="margin-bottom: 20px">
          <b-col md="8">
            <b-row>
              <SearchPatient
                ref="searchPatientInput"
                :doctor="selectedDoctor.idUsuario"
                :clinica="selectedClinic"
                @selectedPatient="handlerSelectedPatient"
              />
            </b-row>
          </b-col>

          <b-col md="3">
            <b-form-checkbox
              v-model="futureScheduling"
              type="checkbox"
              value="1"
              unchecked-value="0"
              >Agendamentos futuros</b-form-checkbox
            >
          </b-col>

          <b-col md="1" style="display: flex; justify-content: flex-end">
            <b-button
              variant="primary"
              @click="getScheduleListByPatient"
              :disabled="!selectedPatient"
            >
              Pesquisar
            </b-button>
          </b-col>
        </b-row>

        <div class="table">
          <div class="headerList">
            <span></span>
            <span>Data</span>
            <span>Nome</span>
            <span>Tipo agendamento</span>
            <span>Situação</span>
            <span></span>
          </div>
          <div v-if="loading" class="d-flex justify-content-center mt-3 mb-3">
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-else class="bodyList">
            <div
              class="dataList"
              v-for="schedule in schedulesSortedByDate"
              :key="schedule.id"
            >
              <b-button
                size="sm"
                pill
                variant="primary "
                @click="selectedSchedule(schedule)"
                >{{ handleFormatHour(schedule.horaAgendamento) }}</b-button
              >
              <span>{{ formatDate(schedule.dtAgendamento, 'pt-BR') }}</span>
              <span>{{ schedule.pacienteNome }}</span>
              <span>{{ schedule.descTipoAgendamento }}</span>
              <span>{{ schedule.descStatusAgenda }}</span>
              <b-button variant="primary" @click="selectedSchedule(schedule)"
                >Ir para agenda</b-button
              >
            </div>
          </div>
        </div>
      </b-col>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import SearchPatient from '../../components/Calendar/SearchPatient'
import API from '../../services/index'
import { formatHour } from '../../Utils/formatters'

export default {
  name: 'FindPatient',
  props: ['doctor', 'clinica', 'doctors', 'clinics'],
  components: { SearchPatient },
  mounted() {
    this.$bvModal.show('find-patient')
    this.selectedDoctor = this.doctor
    this.selectedClinic = this.clinica
  },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      patientSchedules: '',
      futureScheduling: 0,
      loading: false,
      selectedDoctor: '',
      selectedClinic: '',
      selectedPatient: ''
    }
  },
  computed: {
    ...mapGetters({ user: 'Setting/user', isManager: 'Setting/isManager' }),
    schedulesSortedByDate() {
      const sortedSchedules = [...this.patientSchedules].sort((a, b) => {
        return new Date(b.dtAgendamento) - new Date(a.dtAgendamento)
      })

      return sortedSchedules
    }
  },
  methods: {
    focusInput() {
      this.$refs.searchPatientInput.focus()
    },
    getScheduleListByPatient() {
      let url = `api/Agenda/ObtemListaPorPaciente/?agendamentofuturo=${this.futureScheduling}&id_paciente=${this.selectedPatient.id}`

      if (this.selectedDoctor.idUsuario) {
        url += `&id_medico=${this.selectedDoctor.idUsuario}`
      }

      if (this.selectedClinic.idClinica) {
        url += `&id_clinica=${this.selectedClinic.idClinica}`
      }

      this.loading = true
      API.get(url)
        .then((res) => {
          this.patientSchedules = res.data.object
          this.loading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          this.$toast.error('Não foi encontrado horário para esse paciente')
          this.loading = false
        })
    },
    handlerSelectedPatient(patient) {
      this.selectedPatient = patient
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    resetForm() {
      this.patientSchedules = ''
    },
    selectedSchedule(schedule) {
      this.$bvModal.hide('find-patient')
      this.resetForm()
      this.$emit('modalClosed', 'find-patient')
      this.$emit('updateCalendar', schedule.dtAgendamento.substring(0, 10))
    },
    closeModal() {
      this.$bvModal.hide('find-patient')
      this.resetForm()
      this.$emit('modalClosed', 'find-patient')
    },
    handleFormatHour(hour) {
      return formatHour(hour)
    }
  }
}
</script>

<style scoped>
.sameLevel {
  display: flex;
  align-items: center;
}
.table {
  background: white;
  border-radius: 6px;
  overflow-y: auto;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(2, 2fr) repeat(2, 1fr);
  gap: 10px;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background: #089bab;
  color: #fff;
}

.bodyList {
  max-height: 500px;
  overflow-y: auto;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(2, 2fr) repeat(2, 1fr);
  gap: 10px;
  text-align: center;
  align-items: center;
  color: #000;
  border-bottom: 2px solid #c4c8d1;

  transition: box-shadow 0.3s;
}
</style>
