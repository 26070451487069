import API from '../../services/index'

export default {
  async showVideoComponentAction(context) {
    context.commit('showOrHideVideoComponent', true)
  },
  async hideVideoComponentAction(context) {
    context.commit('showOrHideVideoComponent', false)
  },
  async getTokenAction(context, identity) {
    try {
      const response = await API.get('/api/video/token', {
        params: {
          identificacao: identity
        }
      })

      return response.data.token
    } catch (e) {
      return false
    }
  },

  async getActiveRoomsAction() {
    try {
      const response = await API.get('/api/video/rooms')

      return response.data
    } catch (e) {
      return false
    }
  },

  async createNewRoomAction() {
    try {
      const response = await API.post('/api/video/new')

      return response.data
    } catch (e) {
      return false
    }
  },

  async setRoomNameAction(context, name) {
    context.commit('setRoomName', name)
  },

  async verifyRoomIntegrityAction(context, identification) {
    try {
      const response = await API.get('/api/Agenda/ValidaIntegridadeSala', {
        params: {
          identificacao_consulta: identification
        }
      })

      return response.data.result
    } catch (e) {
      return false
    }
  }
}
