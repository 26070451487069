<template>
  <b-col style="width: 100%">
    <div
      style="
        display: flex;
        gap: 12px;
        justify-content: space-between;
        width: 100%;
      "
    >
      <b-row style="width: 100%">
        <b-form-group class="col-md-1" label="Pesquisar:" label-for="pesquisar">
        </b-form-group>
        <b-form-group class="col-md-8">
          <div class="iq-search-bar">
            <form action="#" class="searchbox">
              <input
                autocomplete="noautocomplete"
                ref="inputSearch"
                id="inputSearch"
                type="text"
                @input="searchService($event)"
                @keydown.enter.prevent="searchService($event)"
                class="text search-input"
                placeholder="Código ou descrição do serviço"
              />
              <div class="search-link">
                <i v-if="!loading" class="ri-search-line"></i>
                <b-spinner
                  v-else
                  class="alignCenter"
                  small
                  variant="primary"
                ></b-spinner>
              </div>
            </form>
          </div>
          <iq-card>
            <div class="searchResults" v-if="showResults && results.length > 0">
              <div class="headerSearch">
                <span>Código</span>
                <span>Descrição</span>
              </div>
              <div
                class="patientItem"
                v-for="result in results"
                :key="result.id"
                @click="selectResult(result)"
              >
                <span>{{ result.codigoServico }}</span>
                <span>{{ result.descServico }}</span>
              </div>
            </div>
          </iq-card>
        </b-form-group>
      </b-row>
      <template v-if="listClinics.length !== 1">
        <v-select
          v-model="clinic"
          :options="listClinics"
          label="nmClinica"
          :clearable="false"
          style="width: 400px"
        ></v-select>
      </template>
    </div>
  </b-col>
</template>
<script>
import API from '../../services/index'
import { mapGetters } from 'vuex'
export default {
  props: ['idUsuarioConvenio'],
  name: 'SearchService',
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      listClinics: [],
      clinic: {},
      results: '',
      filter: '',
      showResults: false,
      loading: false
    }
  },
  mounted() {
    if (this.user.clinica_usuario.length === 1) {
      this.listClinics = this.user.clinica_usuario[0]
      this.clinic = this.user.clinica_usuario[0]
    } else {
      this.listClinics = this.user.clinica_usuario
      this.clinic = this.user.clinica_usuario[0]
    }
  },
  methods: {
    async searchService(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        const filter = event.target.value
        if (!filter) {
          this.showResults = false
        } else {
          this.loading = true
          API.get(
            '/api/Servico/BuscaPorServico/',
            {
              params: {
                busca_servico: filter.toUpperCase(),
                id_clinica: this.clinic.idClinica,
                tipo_servico: 'PROC'
              }
            },
            this.authToken
          )
            .then((res) => {
              if (res.data.object.length === 0) {
                this.showResults = false
                this.$toast.error('Nenhum serviço encontrado!')
              } else {
                this.results = res.data.object
                this.showResults = true
              }
              this.loading = false
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.showResults = false

              this.loading = false
            })
        }
      }, 1000)
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    selectResult(result) {
      this.showResults = false
      this.$emit('add-data', result)
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  }
}
</script>

<style scoped lang="scss">
.searchResults {
  z-index: 10;
  position: absolute;
  width: 700px;
  height: auto;
  max-height: 400px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
}

.headerSearch {
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 30px;
  gap: 5px;
  background: #089bab;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  color: #fff;
  font-weight: bold;

  span:nth-child(3) {
    justify-self: center;
    align-self: center;
  }
}

.patientItem {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  border-top: 1px solid #333;
  height: auto;
  padding: 10px;

  span:nth-child(3) {
    justify-self: center;
    align-self: center;
  }
}

.patientItem:hover {
  background: #d1edf0;
  cursor: pointer;
}
</style>
