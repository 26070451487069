import API from '../../../../services/index'
import { parseToAscii, parseToHtml } from '../../../../Utils/parsers'
const actions = {
  async addComplaintToHandBook({ commit }, { payload }) {
    payload.descQueixa = parseToAscii(payload.descQueixa)
    try {
      await API.post('/api/ProntuarioQueixa/Salvar', {
        object: {
          ...payload
        }
      })
    } catch (error) {}
  },

  async editComplaintFromHandBook({ commit }, { payload }) {
    payload.descQueixa = parseToAscii(payload.descQueixa)
    try {
      await API.post('/api/ProntuarioQueixa/Salvar', {
        object: {
          ...payload
        }
      })
    } catch (error) {}
  },

  async addComplaintFavoriteToHandBook({ getters, commit }, { payload }) {
    const complaintFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )
    const alreadyOnFavorite = complaintFavorites.some(
      (favorite) => favorite.descTitulo === payload.descTitulo
    )
    if (alreadyOnFavorite) {
      return
    }
    try {
      payload.descQueixa = parseToAscii(payload.descQueixa)
      const apiResponse = await API.post('/api/FavoritoQueixa/Adicionar', {
        object: {
          ...payload
        }
      })

      apiResponse.data.object.descQueixa = parseToHtml(
        apiResponse.data.object.descQueixa
      )

      commit({
        type: 'addToModuleFavoriteStateWithoutSelect',
        payload: apiResponse.data.object
      })
    } catch (error) {}
  },

  async addEdittedComplaintFavoriteToHandBook({ commit }, { payload }) {
    try {
      const requestObject = {
        ...payload,
        descQueixa: parseToAscii(payload.descQueixa)
      }
      await API.put('/api/FavoritoQueixa/Editar', {
        object: requestObject
      })

      requestObject.descQueixa = parseToHtml(requestObject.descQueixa)

      commit({ type: 'editModuleFavorite', payload: requestObject })
    } catch (error) {}
  },

  async removeComplaintFavoriteFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/FavoritoQueixa/Remover', {
        data: {
          object: {
            ...payload
          }
        }
      })

      commit({ type: 'removeFromFavoriteState', payload })
    } catch (error) {}
  },

  async addComplaintToFavoriteHandBook({ commit }, { payload }) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        acessoRapido: 1,
        favorito: 0
      }

      requestObject.descQueixa = parseToAscii(requestObject.descQueixa)

      await API.put('/api/FavoritoQueixa/Editar', {
        object: requestObject
      })

      requestObject.descQueixa = parseToHtml(requestObject.descQueixa)

      commit({
        type: 'addToModuleFavoriteState',
        payload: requestObject
      })
    } catch (error) {}
  },

  async addComplaintToQuickAccessHandBook({ commit }, { payload }) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        acessoRapido: 0,
        favorito: 1
      }
      requestObject.descQueixa = parseToAscii(requestObject.descQueixa)

      await API.put('/api/FavoritoQueixa/Editar', {
        object: requestObject
      })

      requestObject.descQueixa = parseToHtml(requestObject.descQueixa)

      commit({
        type: 'addToModuleQuickAccessState',
        payload: requestObject
      })
    } catch (error) {}
  }
}

export default actions
