<template>
  <div style="width: 100%; height: 30px">
    <b-form-group>
      <div class="iq-search-bar" style="padding: 0">
        <form action="#" class="searchbox">
          <input
            ref="input"
            autocomplete="noautocomplete"
            type="text"
            @input="searchRiskFactor($event)"
            @keydown.enter.prevent="searchRiskFactor($event)"
            @click="searchRiskFactor($event)"
            v-model="riskfactor"
            class="text search-input"
            placeholder="Busca pelo fator de risco"
          />

          <div class="search-link">
            <i v-if="!loading" class="ri-search-line"></i>
            <b-spinner
              v-else
              class="alignCenter"
              small
              variant="primary"
            ></b-spinner>
          </div>
        </form>
      </div>
      <iq-card>
        <div
          @click="showRiskFactors = false"
          v-if="showRiskFactors && riskfactors.length > 0"
          style="
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
          "
        ></div>
        <div
          class="searchResults"
          v-if="showRiskFactors && riskfactors.length > 0"
        >
          <div class="headerSearch">
            <span>Descrição</span>
          </div>
          <div
            class="riskfactorItem"
            v-for="riskfactor in filteredRiskFactors"
            :key="riskfactor.id"
            @click="selectRiskFactor(riskfactor)"
          >
            <span>{{ riskfactor.descFatorRisco }}</span>
          </div>
        </div>
      </iq-card>
    </b-form-group>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['idClinic'],
  name: 'SearchRiskFactor',
  data() {
    return {
      riskfactor: '',
      riskfactors: [],
      showRiskFactors: false,
      loading: false
    }
  },
  mounted() {
    this.$emit('focusInput')
    this.handleGetRiskFactors()
  },
  computed: {
    filteredRiskFactors() {
      const format = this.riskfactor
        ? this.riskfactor[0].toUpperCase() + this.riskfactor.slice(1)
        : ''

      const filter = [...this.riskfactors].filter((riskfactor) => {
        return riskfactor.descFatorRisco.includes(format)
      })

      return filter
    },
    searchHasResults() {
      return this.filteredRiskFactors.length > 0
    }
  },
  watch: {
    isNewRiskFactor(newValue) {
      if (newValue) {
        this.riskfactor = ''
      }
    }
  },
  methods: {
    ...mapActions({ getRiskFactorsByClinic: 'ASO/getRiskFactorByClinic' }),
    focus() {
      this.$refs.input.focus()
    },
    async handleGetRiskFactors() {
      const response = await this.getRiskFactorsByClinic(this.idClinic)
      this.riskfactors = response
    },
    async searchRiskFactor(event) {
      /* clearTimeout(this.debounce) */
      /* this.debounce = setTimeout(async () => { */
      this.showRiskFactors = true

      if (!this.searchHasResults) {
        this.$toast.error('Nenhum resuldado encontrado')
      }
      /*  }, 500) */
    },
    selectRiskFactor(riskfactor) {
      const object = {
        idFatorRisco: riskfactor.id,
        descFatorRisco: riskfactor.descFatorRisco
      }

      this.riskfactor = ''
      this.$emit('selectedRiskFactor', object)
      this.showRiskFactors = false
    }
  }
}
</script>

<style scoped>
.alignCenter {
  vertical-align: middle !important;
}
.searchResults {
  top: 30px;
  z-index: 10;
  position: absolute;
  width: 700px;
  height: auto;
  max-height: 400px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
}

.headerSearch {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: 30px;
  gap: 5px;
  background: #089bab;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  color: #fff;
  font-weight: bold;
}

.riskfactorItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  border-top: 1px solid #333;
  height: auto;
  padding: 10px;
}

.riskfactorItem:hover {
  background: #d1edf0;
  cursor: pointer;
}
</style>
