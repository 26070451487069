<template>
  <div>
    <img
      src="https://coderthemes.com/hyper/saas/assets/images/mail_sent.svg"
      width="80"
      alt=""
    />
    <h1 class="text">Cadastro efetuado com sucesso!</h1>
    <p style="text-align: center">
      O email de confirmação será enviado em breve para
      {{ this.$store.getters.getEmail }}. Verifique seu email e clique no link
      para confirmar seu cadastro.
    </p>
    <div class="buttons-container">
      <div class="resend-email-container">
        Não recebeu seu email de confirmação?
        <b-button @click="resendEmail" variant="primary">
          Reenviar email
        </b-button>
      </div>

      <b-button @click="goToLogin()" variant="primary">
        Acessar Login
      </b-button>
    </div>
  </div>
</template>
<script>
import API from '../../services/index'

export default {
  name: 'ConfirmMail',
  methods: {
    goToLogin() {
      this.$router.push('/auth/sign-in')
    },
    async resendEmail() {
      try {
        await API.post('api/Usuario/ReenvioConfirmacaoUsuario', null, {
          params: { email: this.$store.getters.getEmail }
        })

        this.$toast.success('Email reenviado')
      } catch (e) {
        this.$toast.error(e.response.data.message)
      }
    }
  }
}
</script>
<style scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

button {
  height: 40px;
}
.resend-email-container {
  display: flex;
  flex-direction: column;
}

.text {
  text-align: center;
  margin-bottom: 10px;
}
</style>
