var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('div',{staticClass:"details"},[(_vm.getMedicalReportType !== 2)?_c('p',{class:'details__name' +
        (_vm.componentInfo.descMeuModulo.length > 31
          ? ' font-medium'
          : ' font-large'),style:(`background-color: ${_vm.componentInfo.corHexadecimal}`)},[_vm._v(" "+_vm._s(_vm.componentInfo.descMeuModulo)+" ")]):_vm._e(),(_vm.componentInfo.showDatePicker)?_c('b-form-input',{staticClass:"details__date",staticStyle:{"background-color":"#fff"},attrs:{"type":"date"}}):_vm._e(),_c('favorites-select',{staticClass:"details__favorite",attrs:{"favorites":_vm.componentInfo.favorites.length &&
        _vm.componentInfo.favorites.filter((favorite) => favorite.favorito === 0),"with-sub-list":false,"title-property":"descTitulo","description-property":"descHistPessoalFamiliar"},on:{"on-favorite-chosen":_vm.addQuickAccessOrFavoriteToContent}}),_c('b-icon',{staticClass:"module__icon",staticStyle:{"cursor":"pointer"},attrs:{"icon":"chevron-expand","font-scale":"2","variant":"primary"},on:{"click":function($event){return _vm.toggleExpanded()}}}),_c('b-icon',{staticClass:"module__icon",staticStyle:{"cursor":"pointer"},attrs:{"icon":"three-dots-vertical","font-scale":"2","variant":"primary"}})],1),(
      _vm.componentInfo.favorites.some((favorite) => favorite.acessoRapido === 0)
    )?_c('quick-access',{attrs:{"quick-accesses":_vm.componentInfo.favorites.length &&
      _vm.componentInfo.favorites.filter(
        (favorite) => favorite.acessoRapido === 0
      ),"with-sub-list":false,"title-property":"descTitulo"},on:{"on-quick-access-chosen":_vm.addQuickAccessOrFavoriteToContent}}):_vm._e(),(_vm.componentInfo.aberto === 0)?[_c('div',{staticClass:"content"},[_c('clinic-report',{attrs:{"initial-content":_vm.content,"content-to-add":_vm.contentToAdd,"id":`${_vm.componentInfo.id}`},on:{"update-content":_vm.updateContent}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }