<template>
  <b-modal
    id="alterGroupRevenue"
    header-bg-variant="primary"
    :title="type ? 'Alteração de receitas' : 'Alteração de despesas'"
    size="md"
    title-class="text-light"
    hide-footer
    @close="close"
    no-close-on-backdrop
  >
    <div class="info">
      <div class="box-situantion">
        <b-form-checkbox
          id="checkbox-situantion"
          v-model="statusSituantion"
          name="checkbox-situantion"
          value="accepted"
          unchecked-value="not_accepted"
        >
        </b-form-checkbox>
        <div class="content">
          <label>Situação:</label>
          <v-select
            :disabled="statusSituantion === 'not_accepted'"
            v-model="situation"
            :options="listSituetion"
            label="descSituacao"
            :clearable="false"
          ></v-select>
        </div>
      </div>
      <hr class="divider" />
      <div style="width: 100%" v-if="type === false">
        <div class="box-situantion">
          <b-form-checkbox
            id="checkbox-center"
            v-model="statusCenter"
            name="checkbox-center"
            value="accepted"
            unchecked-value="not_accepted"
          >
          </b-form-checkbox>
          <div class="content">
            <label>Centro de Custo:</label>
            <v-select
              :disabled="statusCenter === 'not_accepted'"
              v-model="centerCust"
              :options="listCenter"
              label="descCentroCusto"
              :clearable="false"
            ></v-select>
          </div>
        </div>
        <hr class="divider" />
      </div>
      <div class="box-receb">
        <b-form-checkbox
          id="checkbox-receb"
          v-model="statusRecb"
          name="checkbox-receb"
          value="accepted"
          unchecked-value="not_accepted"
        >
        </b-form-checkbox>
        <div class="content-radios">
          <label>Tipo Recebedor:</label>
          <div class="box-radio">
            <b-form-radio
              id="checkbox-one"
              :disabled="statusRecb === 'not_accepted'"
              v-model="statusOne"
              name="checkbox-one"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Clinica
            </b-form-radio>

            <b-form-radio
              id="checkbox-two"
              :disabled="statusRecb === 'not_accepted'"
              v-model="statusTwo"
              name="checkbox-tow"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Profissional de Saude
            </b-form-radio>
          </div>
          <div class="content">
            <v-select
              :disabled="statusRecb === 'not_accepted'"
              v-model="receiver"
              :label="statusTwo === 'accepted' ? 'nmUsuario' : 'nmClinica'"
              :options="listReceiver"
              :clearable="false"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <hr class="divider" />
    <div class="box-button">
      <button @click.prevent="close()" class="b-cancel">Cancelar</button>
      <button type="submit" class="b-save" @click="saveAlterInBlock()">
        Salvar
      </button>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['data', 'type', 'admAndRecp'],
  data() {
    return {
      receiver: '',
      listReceiver: [],
      situation: '',
      listSituetion: [],
      listCenter: [],
      centerCust: '',
      statusCenter: 'not_accepted',
      statusSituantion: 'not_accepted',
      statusRecb: 'not_accepted',
      statusOne: 'accepted',
      statusTwo: 'not_accepted'
    }
  },
  methods: {
    ...mapActions({
      getStatusFinance: 'Finance/getStatusFinance',
      setAlterInBlock: 'Finance/setAlterInBlock',
      getCenterCost: 'Finance/getCenterCost',
      setAlterInBlockExpense: 'Finance/setAlterInBlockExpense',
      handleOpenModalAlterBlockE: 'Finance/handleOpenModalAlterBlockE',
      handleOpenModalAlterBlock: 'Finance/handleOpenModalAlterBlock'
    }),
    close() {
      this.handleOpenModalAlterBlockE(false)
      this.handleOpenModalAlterBlock(false)
      this.$emit('close')
    },
    typeRecebFunction() {
      if (this.statusOne === 'accepted') {
        return 2
      } else if (this.statusTwo === 'accepted') {
        return 1
      } else {
        return 1
      }
    },
    saveAlterInBlock() {
      if (this.type === true) {
        if (
          this.statusSituantion === 'accepted' ||
          this.statusRecb === 'accepted'
        ) {
          if (
            this.statusSituantion === 'accepted' &&
            this.statusRecb === 'accepted'
          ) {
            const response = this.setAlterInBlock({
              idStatusFinanceiro: this.situation.id,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor:
                this.statusOne === 'accepted'
                  ? this.receiver.idClinica
                  : this.receiver.idUsuario,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
          if (
            this.statusSituantion === 'not_accepted' &&
            this.statusRecb === 'accepted'
          ) {
            const response = this.setAlterInBlock({
              idStatusFinanceiro: 0,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor:
                this.statusOne === 'accepted'
                  ? this.receiver.idClinica
                  : this.receiver.idUsuario,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
          if (
            this.statusSituantion === 'accepted' &&
            this.statusRecb === 'not_accepted'
          ) {
            const response = this.setAlterInBlock({
              idStatusFinanceiro: this.situation.id,
              idTipoRecebedor: 0,
              idReferenciaRecebedor: 0,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
        } else {
          this.$toast.error(
            'Por favor, marque alguma opção para realizar a alteração!'
          )
        }
      }

      if (this.type === false) {
        if (
          this.statusSituantion === 'accepted' ||
          this.statusRecb === 'accepted' ||
          this.statusCenter === 'accepted'
        ) {
          if (
            this.statusSituantion === 'accepted' &&
            this.statusRecb === 'accepted' &&
            this.statusCenter === 'accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: this.situation.id,
              idTipoRecebedor: this.typeRecebFunction(),
              IdCentroCusto: this.centerCust.id,
              idReferenciaRecebedor:
                this.statusOne === 'accepted'
                  ? this.receiver.idClinica
                  : this.receiver.idUsuario,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
          if (
            this.statusSituantion === 'not_accepted' &&
            this.statusRecb === 'accepted' &&
            this.statusCenter === 'accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: 0,
              idTipoRecebedor: this.typeRecebFunction(),
              IdCentroCusto: this.centerCust.id,
              idReferenciaRecebedor:
                this.statusOne === 'accepted'
                  ? this.receiver.idClinica
                  : this.receiver.idUsuario,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
          if (
            this.statusSituantion === 'accepted' &&
            this.statusRecb === 'not_accepted' &&
            this.statusCenter === 'accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: this.situation.id,
              IdCentroCusto: this.centerCust.id,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor: 0,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }

          if (
            this.statusSituantion === 'not_accepted' &&
            this.statusRecb === 'not_accepted' &&
            this.statusCenter === 'accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: 0,
              IdCentroCusto: this.centerCust.id,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor: 0,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
          if (
            this.statusSituantion === 'accepted' &&
            this.statusRecb === 'accepted' &&
            this.statusCenter === 'not_accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: this.situation.id,
              IdCentroCusto: 0,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor:
                this.statusOne === 'accepted'
                  ? this.receiver.idClinica
                  : this.receiver.idUsuario,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }

          if (
            this.statusSituantion === 'accepted' &&
            this.statusRecb === 'not_accepted' &&
            this.statusCenter === 'not_accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: this.situation.id,
              IdCentroCusto: 0,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor: 0,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
          if (
            this.statusSituantion === 'not_accepted' &&
            this.statusRecb === 'accepted' &&
            this.statusCenter === 'not_accepted'
          ) {
            const response = this.setAlterInBlockExpense({
              idStatusFinanceiro: 0,
              IdCentroCusto: 0,
              idTipoRecebedor: this.typeRecebFunction(),
              idReferenciaRecebedor:
                this.statusOne === 'accepted'
                  ? this.receiver.idClinica
                  : this.receiver.idUsuario,
              list: this.data.map((object) => object.id)
            })
            if (response) {
              this.$toast.success('Alteração feita com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel realizar a alteração!')
              this.close()
            }
          }
        } else {
          this.$toast.error(
            'Por favor, marque alguma opção para realizar a alteração!'
          )
        }
      }
    }
  },
  watch: {
    statusSituantion(newValue) {
      if (newValue === 'not_accepted') {
        this.situation = ''
      }
    },
    statusRecb(newValue) {
      if (newValue === 'not_accepted') {
        this.receiver = ''
      }
    },
    statusOne(newValue) {
      if (newValue === 'accepted' || this.statusOne === 'accepted') {
        this.statusTwo = 'not_accepted'
        this.receiver = this.user.clinica_usuario[0]
        this.listReceiver = this.user.clinica_usuario
      }
    },
    statusTwo(newValue) {
      if (newValue === 'accepted' || this.statusTwo === 'accepted') {
        if (
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            1 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            4 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            5
        ) {
          this.statusOne = 'not_accepted'
          this.receiver = this.user.clinica_usuario[0]
          this.listReceiver = [this.user.clinica_usuario[0]]
        }
        if (
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            2 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            3
        ) {
          this.statusOne = 'not_accepted'
          this.receiver = this.admAndRecp[0]
          this.listReceiver = this.admAndRecp
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      getClinicGraphTwo: 'Finance/getClinicGraphTwo'
    })
  },
  async mounted() {
    this.$bvModal.show('alterGroupRevenue')
    this.listReceiver = this.user.clinica_usuario
    this.listSituetion = await this.getStatusFinance()
    this.situation = this.listSituetion[0]
    this.listCenter = await this.getCenterCost({
      id_clinica: this.getClinicGraphTwo.idClinica
    })
    this.centerCust = this.listCenter[0]
  },
  destroyed() {
    this.handleOpenModalAlterBlockE(false)
    this.handleOpenModalAlterBlock(false)
  }
}
</script>
<style scoped>
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

strong {
  color: #089bab;
}
.box-button {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(253, 253, 253);
}
.b-cancel {
  color: white;
  padding: 8px;
  border: none;
  border-radius: 10px;
  background-color: rgb(178, 48, 30);
}

.b-save {
  border: none;
  color: white;
  background-color: rgb(1, 122, 143);
  padding: 8px;
  border-radius: 10px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.box-situantion {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  width: 100%;
  align-items: center;
}

.box-receb {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  width: 100%;
  align-items: center;
}

.box-radio {
  display: flex;
  align-items: center;
  gap: 20px;
}
.content-radios {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
