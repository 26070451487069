<template>
  <b-container>
    <b-modal
      @shown="inputFocus"
      id="find-medical-report"
      ref="find-medical-report"
      hide-footer
      title="Pesquisar prontuário"
      title-class="text-light"
      header-bg-variant="primary"
      cancel-title="Não"
      ok-title="Sim"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      @close="closeModal"
    >
      <b-col lg="12">
        <b-form-group class="col-md-11">
          <div class="iq-search-bar">
            <form action="#" class="searchbox">
              <input
                autocomplete="complete"
                ref="inputSearch"
                type="text"
                @input="getMedicalReportListByPatient($event.target.value)"
                @keydown.enter.prevent="
                  getMedicalReportListByPatient($event.target.value)
                "
                class="text search-input"
                placeholder="Informe no minimo 5 caracteres"
              />
              <div class="search-link">
                <i v-if="!loadingSearch" class="ri-search-line"></i>
                <b-spinner
                  v-else
                  class="alignCenter"
                  small
                  variant="primary"
                ></b-spinner>
              </div>
            </form>
          </div>
        </b-form-group>

        <div class="table">
          <div class="headerList">
            <span>Nome</span>
            <span>Idade</span>
            <span class="mon">Nome da mãe</span>
            <span class="ultAten">Ultimo atendimento</span>
            <span>CPF</span>
            <span class="none"></span>
            <span></span>
          </div>
          <div
            v-if="loadingSearch"
            class="d-flex justify-content-center mt-3 mb-3"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div
            v-else
            class="dataList"
            v-for="(medicalReport, index) in medicalReportList"
            :key="medicalReport.id"
          >
            <span class="dataListItem">{{ medicalReport.nmPaciente }}</span>
            <span class="dataListItem">{{ medicalReport.idadepaciente }}</span>
            <span class="dataListItem mon">{{
              medicalReport.nmMaePaciente
            }}</span>
            <span class="dataListItem ultAten">{{
              formatDate(medicalReport.ultimoAtendimento, 'pt-BR')
            }}</span>
            <span class="dataListItem">{{ medicalReport.cpfPaciente }}</span>
            <button
              v-if="medicalReportBeingRequestedIndex !== index"
              type="button"
              id="dataListButton"
              @click="accessMedicalReport(medicalReport)"
            >
              Acessar prontuário
            </button>
            <div style="text-align: center" v-else>
              <b-spinner
                style="text-align: center"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
        </div>
      </b-col>
    </b-modal>
  </b-container>
</template>
<script>
import API from '../../services/index'
import { mapGetters } from 'vuex'
export default {
  name: 'FindMedicalReport',
  props: ['doctor', 'clinica'],
  mounted() {
    this.$bvModal.show('find-medical-report')
  },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      medicalReportList: [],
      loadingSearch: false,
      medicalReportBeingRequestedIndex: null
    }
  },
  methods: {
    inputFocus() {
      this.$refs.inputSearch.focus()
    },
    getMedicalReportListByPatient(patient) {
      patient = patient.toUpperCase()
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadingSearch = true
        API.get(
          `api/Agenda/ObtemListaProntuario?paciente=${patient}&id_usuario=${this.doctor.idUsuario}`
        )
          .then((res) => {
            this.medicalReportList = res.data.object
            this.loadingSearch = false
          })

          // eslint-disable-next-line handle-callback-err
          .catch((err) => {
            this.$toast.error('Não foi encontrado prontuário desse paciente')
            this.loadingSearch = false
          })
      }, 1000)
    },

    encodeParamInfo(n) {
      const encode = (n * 100) / 40
      return encode
    },

    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    resetForm() {
      this.patientSchedules = ''
    },
    async accessMedicalReport(medicalReport) {
      if (
        this.medicalReportBeingRequestedIndex !== null &&
        this.medicalReportBeingRequestedIndex >= 0
      ) {
        this.$toast.info('Prontuário já sendo requisitado')
        return
      }
      this.medicalReportBeingRequestedIndex = this.medicalReportList.findIndex(
        (report) => report.idPaciente === medicalReport.idPaciente
      )

      const date = `${new Date().toLocaleDateString('en-CA')}T${
        new Date().toTimeString().split(' ')[0]
      }Z`

      const object = {
        idStatusAgenda: 10,
        idTipoAgendamento: 4,
        dtInclusao: date,
        dtAgendamento: date,

        idPaciente: medicalReport.idPaciente,
        idUsuario: medicalReport.idUsuario,
        idUsuarioInclusao: medicalReport.idUsuario,
        pacienteNome: medicalReport.nmPaciente,
        idClinica: this.clinica.idClinica,
        origem: 'C',
        idUsuarioUltAtualizacao: this.user.id,
        dtUltAtualizacao: new Date().toISOString()
      }

      const apiResponse = await API.post('/api/Agenda/Adicionar', {
        object
      })

      const reqBody = {
        idAgenda: apiResponse.data.identificacao,
        idPaciente: medicalReport.idPaciente,
        idUsuario: medicalReport.idUsuario,
        idClinica: this.clinica.idClinica
      }

      const scheduleId = apiResponse.data.identificacao

      /* const scheduleApiResponse = await API.get(
        `/api/Agenda/ObtemItem/${scheduleId}`
      ) */

      await API.post(
        'api/Prontuario/Adicionar',
        { object: reqBody },
        this.authToken
      )
        .then((res) => {
          const { idPaciente, idUsuario, identificacao } = res.data
          this.$store
            .dispatch({
              type: 'MedicalReports/loadAllModules',
              payload: idUsuario
            })
            .then(() => {
              this.$store
                .dispatch({
                  type: 'MedicalReports/getAllModulesData',
                  payload: {
                    idProntuario: identificacao
                  }
                })
                .then(() => {
                  this.medicalReportBeingRequestedIndex = null

                  const requestData = {
                    idPaciente: window.btoa(this.encodeParamInfo(idPaciente)),
                    idUsuario: window.btoa(this.encodeParamInfo(idUsuario)),
                    identificacao: window.btoa(
                      this.encodeParamInfo(identificacao)
                    ),
                    scheduleId: window.btoa(this.encodeParamInfo(scheduleId))
                  }

                  this.$router.push({
                    name: 'prontuario.prontuario',
                    params: {
                      ...requestData
                    }
                  })
                })
                .catch((_) => {
                  this.medicalReportBeingRequestedIndex = null
                })
            })
            .catch((_) => {
              this.medicalReportBeingRequestedIndex = null
            })
        })
        .catch((_) => {
          this.medicalReportBeingRequestedIndex = null
        })
    },
    selectedSchedule(schedule) {
      this.$bvModal.hide('find-medical-report')
      this.resetForm()
      this.$emit('modalClosed', 'find-medical-report')
      this.$emit(
        'updateCalendar',
        this.formatDate(schedule.dtAgendamento, 'en-CA')
      )
    },
    closeModal() {
      this.$bvModal.hide('find-medical-report')
      this.resetForm()
      this.$emit('modalClosed', 'find-medical-report')
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  }
}
</script>

<style scoped>
.alignCenter {
  vertical-align: middle !important;
}
.sameLevel {
  display: flex;
  align-items: center;
}
.table {
  background: white;
  border-radius: 6px;
  overflow-y: auto;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 30px;
  display: grid;
  grid-template-columns: 2fr 0.5fr 2fr repeat(2, 1fr) 1.5fr;
  padding: 0 10px;
  /* gap: 10px; */
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  text-align: flex-start;
  align-items: center;
  background: #089bab;
  color: #fff;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 0.5fr 2fr repeat(2, 1fr) 1.5fr;
  padding: 0 10px;
  gap: 10px;
  align-items: center;
  color: #000;
  border-bottom: 2px solid #c4c8d1;

  transition: box-shadow 0.3s;
}

#dataListButton {
  font-size: 0.5rem;
  border: none;
  border-radius: 10px;
  background: #089bab;
  color: white;
  font-weight: 600;
  padding: 6px;
}

.headerList span:not(:first-of-type),
.dataList span:not(:first-of-type) {
  text-align: center;
}

@media (max-width: 1200px) {
  .headerList {
    font-size: 0.55rem;
  }
  .dataListItem {
    font-size: 0.55rem;
  }
  #dataListButton {
    font-size: 0.55rem;
    border: none;
    border-radius: 10px;
    background: #089bab;
    color: white;
    font-weight: 600;
    padding: 6px;
  }
}

@media (max-width: 990px) {
  .headerList {
    font-size: 0.5rem;
  }
  .dataListItem {
    font-size: 0.5rem;
  }
  #dataListButton {
    font-size: 0.5rem;
    border: none;
    border-radius: 10px;
    background: #089bab;
    color: white;
    font-weight: 600;
    padding: 6px;
  }
}

@media (max-width: 880px) {
  .headerList {
    font-size: 0.5rem;
    grid-template-columns: 1fr 0.5fr 1fr 1fr;
  }
  .ultAten {
    display: none;
  }
  .none {
    display: none;
  }
  .mon {
    display: none;
  }
  .dataList {
    grid-template-columns: 1fr 0.5fr 1fr 1fr;
  }
  .dataListItem {
    font-size: 0.5rem;
  }
  #dataListButton {
    font-size: 0.5rem;
    border: none;
    border-radius: 10px;
    background: #089bab;
    color: white;
    font-weight: 600;
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .headerList {
    font-size: 0.5rem;
  }
  .dataListItem {
    font-size: 0.5rem;
  }
  #dataListButton {
    font-size: 0.5rem;
    border: none;
    border-radius: 10px;
    background: #089bab;
    color: white;
    font-weight: 600;
    padding: 6px;
  }
}
</style>
