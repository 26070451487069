<template>
  <div
    class="link-container"
    :style="`top: ${calculatePositionY}px; left: ${calculatePositionX}px;`"
    @mouseleave="$emit('mouseleave')"
  >
    <b-button variant="primary" size="lg" :href="link" target="_blank">
      Abrir prescrição em nova guia
    </b-button>

    <b-button
      variant="primary"
      size="lg"
      v-clipboard:copy="link"
      v-clipboard:success="onCopy"
      v-clipboard:error="onCopyError"
    >
      Copiar link
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'PrescriptionLinkTooltip',
  props: ['link', 'positionX', 'positionY'],
  computed: {
    calculatePositionY() {
      const dropDown = this.positionY < 200

      if (dropDown) {
        return this.positionY - 100
      } else {
        return this.positionY - 80
      }
    },

    calculatePositionX() {
      return this.positionX - 62.5
    }
  },
  methods: {
    onCopy() {
      this.$toast.info('Link da prescrição copiado')
    },
    onCopyError() {
      this.$toast.error('Erro ao copiar link')
    }
  }
}
</script>

<style scoped lang="scss">
.link-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100px;
  position: fixed;
  z-index: 9999;
  background-color: white;
  border-radius: 8px;
  border: 2px solid rgba($color: #000000, $alpha: 0.1);
  align-items: center;
  justify-content: space-around;
}
</style>
