var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info__container"},[_c('div',{staticClass:"info__main"},[_c('img',{staticClass:"info__image",attrs:{"src":_vm.patientInfo.avatar.readyState === 2
          ? _vm.patientInfo.avatar.result
          : _vm.patientInfo.idSexo === 1
          ? require('../../../assets/images/user/2.png')
          : require('../../../assets/images/user/1.jpg'),"alt":"User image"},on:{"click":function($event){return _vm.openUserInfoModal()}}}),_c('div',{staticClass:"name-and-age-container"},[_c('p',{staticClass:"info__name"},[_vm._v(_vm._s(_vm.patientInfo.nome))]),_c('small',{staticClass:"info__age"},[_vm._v(_vm._s(_vm.patientInfo.idadePaciente)+" anos")])]),(_vm.showVideoComponent)?_c('div',{staticClass:"video-container"},[_c('video-tooltip',{attrs:{"identification":_vm.videoIdentification,"patientEmail":_vm.patientInfo.email}})],1):_vm._e()]),_c('div',{staticClass:"info__complementary"},[_c('p',{staticClass:"info__birthday"},[_vm._v(" Nascimento: "),_c('span',[_vm._v(_vm._s(_vm.patientInfo.dtNascimento ? _vm.formatDate(_vm.patientInfo.dtNascimento, 'pt-BR') : 'Não registrado'))])]),_c('p',{staticClass:"info__sex"},[_vm._v(" Sexo: "),_c('span',[_vm._v(_vm._s(_vm.patientInfo.idSexo === 1 ? 'Masculino' : 'Feminino'))])]),_c('p',{staticClass:"info__civil-status"},[_vm._v(" CPF: "),_c('span',[_vm._v(_vm._s(_vm.patientInfo.cpf))])]),_c('p',{staticClass:"info__religion"},[_vm._v(" Identificação: "),_c('span',[_vm._v(_vm._s(_vm.patientInfo.id))])]),_c('p',{staticClass:"info__convenio"},[_vm._v(" Convênio de consulta: "),_c('span',[_vm._v(_vm._s(_vm.patientInfo.convenioAtendimento))])])]),_c('div',{class:_vm.isIOS ? 'icons-container-ios' : 'icons-container'},[_c('allergies-tooltip',{attrs:{"allergiesModule":_vm.allergiesModule}}),_c('problems-tooltip',{attrs:{"problemsModule":_vm.problemsModule}})],1),_c('button',{class:{
      info__button: true,
      'info__button--blue': !this.perceptions,
      'info__button--orange': this.perceptions
    },attrs:{"id":"perception","type":"button"},on:{"click":function($event){return _vm.openPerceptionsModal()}}},[_vm._v(" Percepções ")]),_c('b-modal',{attrs:{"id":"perceptions__modal","size":"lg","title":"Percepções","header-bg-variant":"primary","header-text-variant":"white","cancel-title":"Cancelar","cancel-variant":"danger","ok-title":"Salvar"},on:{"ok":_vm.handleOkay,"shown":_vm.focusOnTextarea}},[_c('clinic-report',{ref:"perceptionsReport",attrs:{"id":'perceptions__report',"initial-content":this.perceptions},on:{"update-content":this.updateContent}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }