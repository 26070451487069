<template>
  <div style="width: 100%; height: 30px">
    <b-form-group>
      <div class="iq-search-bar" style="padding: 0">
        <form action="#" class="searchbox">
          <input
            ref="input"
            autocomplete="noautocomplete"
            type="text"
            @input="searchEnterprise($event)"
            @keydown.enter.prevent="searchEnterprise($event)"
            v-model="enterprise"
            class="text search-input"
            v-uppercase
            placeholder="Busca pelo nome da empresa"
          />

          <div class="search-link">
            <i v-if="!loading" class="ri-search-line"></i>
            <b-spinner
              v-else
              class="alignCenter"
              small
              variant="primary"
            ></b-spinner>
          </div>
        </form>
      </div>
      <iq-card>
        <div
          @click="showEnterprises = false"
          v-if="showEnterprises && enterprises.length > 0"
          style="
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
          "
        ></div>
        <div
          class="searchResults"
          v-if="showEnterprises && enterprises.length > 0"
        >
          <div class="headerSearch">
            <span>Razão Social</span>
            <span>CNPJ</span>
            <span>CEP</span>
          </div>
          <div
            class="enterpriseItem"
            v-for="enterprise in filteredEnterprises"
            :key="enterprise.id"
            @click="selectEnterprise(enterprise)"
          >
            <span>{{ enterprise.razaoSocial }}</span>
            <span>{{ enterprise.cnpj }}</span>
            <span> {{ enterprise.cep }}</span>
          </div>
        </div>
      </iq-card>
    </b-form-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['idClinic', 'isNewEnterprise'],
  name: 'SearchEnterprise',
  data() {
    return {
      enterprise: '',
      enterprises: [],
      showEnterprises: false,
      loading: false
    }
  },
  async mounted() {
    this.$emit('focusInput')
    await this.handleGetEnterprises()
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),
    filteredEnterprises() {
      const filter = [...this.enterprises].filter((enterprise) => {
        return enterprise.razaoSocial
          .toUpperCase()
          .includes(this.enterprise.toUpperCase())
      })

      return filter
    },
    searchHasResults() {
      return this.filteredEnterprises.length > 0
    }
  },
  watch: {
    isNewEnterprise(newValue) {
      if (newValue) {
        this.enterprise = ''
      }
    }
  },
  methods: {
    ...mapActions({ getEnterprisesByClinic: 'ASO/getEnterpriseByClinic' }),
    focus() {
      this.$refs.input.focus()
    },
    async handleGetEnterprises() {
      const response = await this.getEnterprisesByClinic(611)
      this.enterprises = response
    },
    async searchEnterprise(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        this.showEnterprises = true

        if (!this.searchHasResults) {
          this.$toast.error('Nenhum resuldado encontrado')
        }
      }, 1000)
    },
    selectEnterprise(enterprise) {
      this.enterprise = enterprise.razaoSocial
      const object = {
        id: enterprise.id,
        corporateName: enterprise.razaoSocial,
        cnpj: enterprise.cnpj,
        address: enterprise.logradouro,
        number: enterprise.numero,
        neighborhood: enterprise.bairro,
        city: enterprise.idCidade,
        state: enterprise.idEstado,
        cep: enterprise.cep,
        complement: enterprise.complemento
      }

      this.$emit('selectedEnterprise', object)
      this.showEnterprises = false
    }
  }
}
</script>

<style scoped>
.alignCenter {
  vertical-align: middle !important;
}
.searchResults {
  top: 30px;
  z-index: 10;
  position: absolute;
  width: 700px;
  height: auto;
  max-height: 400px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
}

.headerSearch {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: 30px;
  gap: 5px;
  background: #089bab;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  color: #fff;
  font-weight: bold;
}

.enterpriseItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  border-top: 1px solid #333;
  height: auto;
  padding: 10px;
}

.enterpriseItem:hover {
  background: #d1edf0;
  cursor: pointer;
}
</style>
