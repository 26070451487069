<template>
  <div
    id="modal-alergias"
    :class="[
      'container-tooltip',
      'left',
      allergiesModule.data.length > 0 ? 'data-filled' : '',
      hovered ? 'container-tooltip-opened' : ''
    ]"
    @click="hovered = true"
    @mouseleave="hovered = false"
  >
    <div class="icon-container" v-if="!hovered">
      <b-icon icon="exclamation-triangle" class="h5 mb-0" />
      <span>Alergias</span>
    </div>

    <b-table-simple hover v-if="hovered">
      <b-thead head-variant="primary">
        <b-tr class="table-header">
          <b-th class="table__date">Data</b-th>
          <b-th>Principio Ativo</b-th>
          <b-th>Grau</b-th>
          <b-th>Observação</b-th>
          <b-th>Ativo</b-th>
        </b-tr>
      </b-thead>

      <b-tbody class="table__body">
        <b-tr :key="data.id" v-for="data in items">
          <b-td class="table__date">{{
            formatDate(data.dtOcorrencia, 'pt-BR')
          }}</b-td>
          <b-td>{{ data.descMedicamento }}</b-td>
          <b-td>
            <span :class="'table__degree ' + getBackground(data.descGrau)">
              {{ data.descGrau }}
            </span></b-td
          >
          <b-td :title="data.observacao">
            <b-form-input
              type="text"
              :value="data.observacao"
              @change="updateField(data, 'observacao', $event)"
            />
          </b-td>
          <b-td @click.prevent="updateActivity(data)">
            <b-form-checkbox :checked="data.ativo === 0"></b-form-checkbox>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  name: 'AllergiesTooltip',
  props: ['allergiesModule'],
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    items() {
      const copy = this.allergiesModule.data.filter(
        (allergy) => !allergy.deleted
      )

      copy.sort((prevValue, currentValue) => {
        return prevValue.isNew
          ? -1
          : prevValue.ativo === currentValue.ativo
          ? 0
          : prevValue.ativo === 0
          ? -1
          : 1
      })
      return copy
    }
  },
  methods: {
    formatDate(date, format) {
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString(format)
    },
    getBackground(degree) {
      switch (degree.toLowerCase()) {
        case 'leve': {
          return 'table__degree--light'
        }
        case 'moderada': {
          return 'table__degree--medium'
        }
        case 'severo': {
          return 'table__degree--severe'
        }
      }
    },
    updateField(allergy, field, value) {
      const payload = {
        ...allergy,
        [field]: value
      }
      this.$store.commit({
        type: 'MedicalReports/updateAllergyField',
        payload
      })
    },
    updateActivity(data) {
      if (data.ativo === 0) {
        this.$store.commit({
          type: 'MedicalReports/inactivateAllergy',
          payload: data
        })
      } else {
        this.$store.commit({
          type: 'MedicalReports/activateAllergy',
          payload: data
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import './styles';
#modal-alergias {
  z-index: 9;
}
</style>
