/* eslint-disable camelcase */
import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

extend('cpfIsValid', (value) => {
  return cpf.isValid(value)
})

extend('cnpjIsValid', (value) => {
  return cnpj.isValid(value)
})

extend('dateIsValid', (value) => {
  return dayjs(value, 'DD/MM/YYYY', true).isValid()
})

extend('required_if_not', {
  ...rules.required_if,
  validate: (value, args) => {
    const target_value = args.target
    return Boolean(target_value || value)
  }
})

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
