var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('div',{staticClass:"details"},[(_vm.medicalReportType !== 2)?_c('p',{class:'details__name' +
        (_vm.componentInfo.descMeuModulo.length > 31
          ? ' font-medium'
          : ' font-large'),style:(`background-color: ${_vm.componentInfo.corHexadecimal}`)},[_vm._v(" "+_vm._s(_vm.componentInfo.descMeuModulo)+" ")]):_vm._e(),_c('div',{staticClass:"details__checkbox-container"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.showInactivesConducts),callback:function ($$v) {_vm.showInactivesConducts=$$v},expression:"showInactivesConducts"}}),_c('span',[_vm._v("Exibir inativos")])],1),_c('b-form-input',{staticClass:"details__date",attrs:{"type":"date"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-form-input',{staticClass:"details__time",attrs:{"type":"time"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('favorites-select',{attrs:{"favorites":_vm.componentInfo.favorites.length &&
        _vm.componentInfo.favorites.filter((favorite) => favorite.favorito === 0),"with-sub-list":false,"title-property":"descTitulo","description-property":"descConduta"},on:{"on-favorite-chosen":_vm.addQuickAccessOrFavoriteToContent}}),_c('b-icon',{staticClass:"module__icon",staticStyle:{"cursor":"pointer"},attrs:{"icon":"chevron-expand","font-scale":"2","variant":"primary"},on:{"click":function($event){return _vm.toggleExpanded()}}}),_c('b-icon',{staticClass:"module__icon",staticStyle:{"cursor":"pointer"},attrs:{"icon":"three-dots-vertical","font-scale":"2","variant":"primary"}})],1),(_vm.hasQuickAccesses)?_c('quick-access',{attrs:{"quick-accesses":_vm.componentInfo.favorites.length &&
      _vm.componentInfo.favorites.filter(
        (favorite) => favorite.acessoRapido === 0
      ),"with-sub-list":false,"title-property":"descTitulo"},on:{"on-quick-access-chosen":_vm.addQuickAccessOrFavoriteToContent}}):_vm._e(),(_vm.componentInfo.aberto === 0)?_c('div',{staticClass:"content"},[_c('clinic-report',{attrs:{"initial-content":_vm.content,"content-to-add":_vm.contentToAdd,"id":`${_vm.componentInfo.id}`},on:{"update-content":_vm.updateContent}}),_c('time-line',{attrs:{"data":_vm.dataToDisplay,"withColors":false,"descProperty":"descConduta","idUser":_vm.idMedico,"disableFunction":_vm.editConduct}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }