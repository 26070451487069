<template>
  <b-modal
    id="add-file-ultrassom"
    title="Adicionar arquivo"
    title-class="text-light"
    header-bg-variant="primary"
    cancel-title="Cancelar"
    cancel-variant="danger"
    ok-title="Salvar"
    no-close-on-esc
    no-close-on-backdrop
    @hide="resetModal"
    @ok="addFile"
    @close="resetModal"
    @cancel="resetModal"
  >
    <div class="box-input-file">
      <input
        class="input-none"
        type="file"
        @change="handleFileChange"
        ref="fileInput"
        accept=".pdf"
      />
      <input
        placeholder="Selecione um arquivo"
        class="input-file"
        type="text"
        :value="namedFile"
      />
      <b-button variant="primary" @click="openFilePicker">Buscar</b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ['indexItem', 'indexExamTuss'],
  data() {
    return {
      selectedFile: null,
      pdfDataUrl: null,
      namedFile: ''
    }
  },
  mounted() {
    this.$bvModal.show('add-file-ultrassom')
  },
  methods: {
    resetModal() {
      this.$bvModal.hide('add-file-ultrassom')
      this.$emit('closeModal')
    },
    addFile(event) {
      event.preventDefault()
      if (!this.selectedFile)
        return this.$toast.error('É necessario escolher um arquivo ou imagem!')
      if (this.selectedFile.type !== 'application/pdf') {
        return this.$toast.error('Só é possivel salvar arquivo pdf!')
      }
      this.$emit('add-file', {
        pdfDataUrl: this.pdfDataUrl,
        index: this.indexItem,
        indexExamTuss: this.indexExamTuss,
        selectedFile: this.selectedFile,
        namedFile: this.namedFile
      })
      this.resetModal()
    },
    openFilePicker() {
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0]
      this.namedFile = this.selectedFile.name
      this.loadPdfDataUrl()
    },
    async loadPdfDataUrl() {
      if (this.selectedFile) {
        this.pdfDataUrl = await this.readFileAsDataUrl(this.selectedFile)
      }
    },
    readFileAsDataUrl(file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          resolve(event.target.result)
        }
        reader.readAsDataURL(file)
      })
    }
  }
}
</script>
<style scoped>
.box-input-file {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.box-input-file .input-file {
  padding: 10px;
  border: none;
  font-size: 0.8rem;
  color: black;
  background-color: rgb(227, 227, 227);
  border-radius: 10px;
  flex: 1;
}
.input-none {
  display: none;
}
.box-input-file button {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  border-radius: 10px;
  font-size: 0.8rem;
  border: none;
}
</style>
