import API from '../../../../services/index'
import { parseToAscii } from '../../../../Utils/parsers'

const sendFile = ({ file, fileName, type, idHandBook }) => {
  const bodyFormData = new FormData()
  bodyFormData.append('file', file)
  API.post(
    `/api/ProntuarioHistoricoExame/AwsS3UploadArquivo?tipoArquivo=${type}&filename=${fileName}&prontuario_id=${idHandBook}`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${window.$cookies.get('token')}`
      }
    }
  )
}

// const newExam = ({ isNew, id, file, ...exam }) => {
//   API.post(`/api/ProntuarioHistoricoExame/Adicionar`, {
//     object: {
//       idProntuario: exam.idProntuario,
//       descServico: exam.descServico,
//       descArquivo: exam.descArquivo,
//       dtSolicitacao: exam.dtSolicitacao,
//       codigoServico: exam.codigoServico,
//       qtdeSolicitacao: exam.qtdeSolicitacao,
//       ativo: exam.ativo,
//       dtRealizacao: exam.dtRealizacao || '',
//       resultado: exam.resultado || ''
//     }
//   }).then((res) => {})
// }

// const editExam = ({ isNew, id, file, ...exam }) => {
//   API.put(`/api/ProntuarioHistoricoExame/Editar`, {
//     object: {
//       id: id,
//       idProntuario: exam.idProntuario,
//       descServico: exam.descServico,
//       descArquivo: exam.descArquivo,
//       dtSolicitacao: exam.dtSolicitacao,
//       codigoServico: exam.codigoServico,
//       qtdeSolicitacao: exam.qtdeSolicitacao,
//       ativo: exam.ativo,
//       dtRealizacao: exam.dtRealizacao || '',
//       resultado: exam.resultado || ''
//     }
//   }).then((res) => {})
// }

const actions = {
  // async sendExams({ commit }, { payload }) {
  //   const images = []
  //   payload.forEach((exam) => {
  //     const imageExist = images.filter(
  //       (image) =>
  //         image.descArquivo === exam.file.descArquivo &&
  //         exam.file.attachAllExams
  //     )
  //     if (imageExist.length === 0) {
  //       images.push({ file: exam.file, idProntuario: exam.idProntuario })
  //     }
  //     if (exam.isNew) {
  //       newExam(exam)
  //     } else if (exam.isEdit) {
  //       editExam(exam)
  //     }
  //   })
  //   images.forEach((image) => sendFile(image))
  // },
  async getExamsHistoryAction(context, payload) {
    const { idClinic, idMedic, idPatient, handbookId } = payload
    try {
      const response = await API.get(
        '/api/ProntuarioHistoricoExame/ObtemExamesPorPaciente',
        {
          params: {
            id_clinica: idClinic,
            id_medico: idMedic,
            id_paciente: idPatient
          }
        }
      )

      context.commit('loadExamsHistory', {
        payload: { examsHistory: response.data.object, handbookId }
      })
    } catch {
      return false
    }
  },
  async addExamHistoryToHandBook({ commit }, { payload }) {
    try {
      if (payload.file) {
        sendFile({
          file: payload.file,
          fileName: payload.descArquivo,
          type: 'pdf',
          idHandBook: payload.idProntuario
        })
      }

      if (payload.descLaudoInfo) {
        payload.descLaudoInfo = parseToAscii(payload.descLaudoInfo)
      }
      await API.post('/api/ProntuarioHistoricoExame/Adicionar', {
        object: payload
      })
    } catch (error) {}
  },

  async removeExamHistoryFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/ProntuarioHistoricoExame/Remover', {
        data: {
          object: payload
        }
      })
    } catch (error) {}
  },

  async editExamHistoryToHandBook({ commit }, { payload }) {
    try {
      if (payload.file) {
        sendFile({
          file: payload.file,
          fileName: payload.descArquivo,
          type: 'pdf',
          idHandBook: payload.idProntuario
        })
      }

      if (payload.descLaudoInfo) {
        payload.descLaudoInfo = parseToAscii(payload.descLaudoInfo)
      }

      await API.put('/api/ProntuarioHistoricoExame/Editar', {
        object: payload
      })
    } catch (error) {}
  },
  async addExamHistoryToFavoritesHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some(
      (favorite) => favorite.codigoServico === payload.codigoServico
    )

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        await API.put('/api/FavoritoHistoricoExame/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleFavoriteState',
          payload: { ...requestObject, location: 'favorite' }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 1,
          favorito: 0
        }

        const apiResponse = await API.post(
          '/api/FavoritoHistoricoExame/Adicionar',
          {
            object: requestObject
          }
        )

        commit({
          type: 'addToModuleFavoriteState',
          payload: { location: 'favorite', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async addExamHistoryToQuickAccessHandBook({ commit, getters }, { payload }) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )

    const alreadyOnQuickAccessOrFavorite = moduleFavorites.some(
      (favorite) => favorite.codigoServico === payload.codigoServico
    )

    try {
      if (alreadyOnQuickAccessOrFavorite) {
        const { location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        await API.put('/api/FavoritoHistoricoExame/Editar', {
          object: requestObject
        })

        commit({
          type: 'addToModuleQuickAccessState',
          payload: { ...requestObject, location: 'quickAccess' }
        })
      } else {
        const { id, location, ...newPayload } = payload

        const requestObject = {
          ...newPayload,
          acessoRapido: 0,
          favorito: 1
        }

        const apiResponse = await API.post(
          '/api/FavoritoHistoricoExame/Adicionar',
          {
            object: requestObject
          }
        )
        commit({
          type: 'addToModuleQuickAccessState',
          payload: { location: 'quickAccess', ...apiResponse.data.object }
        })
      }
    } catch (error) {}
  },

  async removeExamHistoryFavoriteFromHandBook({ commit }, { payload }) {
    const { location, ...newPayload } = payload
    try {
      await API.delete('/api/FavoritoHistoricoExame/Remover', {
        data: {
          object: newPayload
        }
      })
    } catch (error) {}
  }
}

export default actions
