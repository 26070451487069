<template>
  <b-modal
    id="user__modal"
    size="lg"
    header-bg-variant="primary"
    scrollable
    title="Cadastro do Paciente"
  >
    <div class="modal__content" style="padding: 16px">
      <b-row>
        <b-col style="flex: 0.47">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              style="border-radius: 50%; height: 130px; width: 130px"
              :src="
                patient.avatar.readyState === 2
                  ? patient.avatar.result
                  : patient.idSexo === 1
                  ? require('../../assets/images/user/2.png')
                  : require('../../assets/images/user/1.jpg')
              "
              alt="Foto do paciente"
            />
          </div>
        </b-col>
        <b-col>
          <b-form-group label="Nome:" label-for="name">
            <b-form-input id="name" type="text" v-model="patient.nome" />
          </b-form-group>

          <b-form-group label="Nome da mãe:" label-for="motherName">
            <b-form-input
              id="motherName"
              type="text"
              v-model="patient.nomeMae"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Sexo:" label-for="gender">
            <v-select
              v-model="patient.idSexo"
              :reduce="(gender) => gender.id"
              :options="genders"
              :clearable="false"
              label="descSexo"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Estado Civil:" label-for="civil_state">
            <v-select
              v-model="patient.idEstadoCivil"
              :clearable="false"
              :reduce="(civilState) => civilState.id"
              :options="civilStateOptions"
              label="descEstadoCivil"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Religião:" label-for="religion">
            <b-form-input
              id="religion"
              type="text"
              v-model="patient.religiao"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Data de nascimento:" label-for="birthday">
            <b-form-input
              v-model="patient.dtNascimento"
              id="birthday"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Celular:" label-for="phone">
            <b-form-input
              v-mask="'(##) #####-####'"
              id="phone"
              type="text"
              v-model="patient.telCelular"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Email" label-for="email">
            <ValidationProvider
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="patient.email"
                id="email"
                v-uppercase
                type="email"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-input>
              <div class="invalid-feedback">
                <span>Informe um email válido</span>
              </div>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- <b-col> -->
        <b-form-group label="CEP:" label-for="cep" class="col-md-4">
          <b-form-input
            v-mask="'#####-###'"
            v-model="patient.cep"
            type="text"
            autocomplete="noautocomplete"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="col-md-6"
          label="Logradouro:"
          label-for="logradouro"
        >
          <b-form-input
            v-model="patient.logradouro"
            type="text"
            v-uppercase
            autocomplete="noautocomplete"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nº:" label-for="numero" class="col-md-2">
          <b-form-input v-model="patient.numero" type="number"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-4" label="Bairro:" label-for="bairro">
          <b-form-input
            v-model="patient.bairro"
            type="text"
            v-uppercase
            autocomplete="noautocomplete"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-4" label-for="idEstado" label="Estado:">
          <v-select
            :clearable="false"
            v-model="patient.idEstado"
            :options="states"
            :reduce="(descEstado) => descEstado.id"
            label="descEstado"
            @input="handleStateChange"
          ></v-select>
        </b-form-group>

        <b-form-group class="col-sm-4" label-for="idCidade" label="Cidade:">
          <select
            v-if="citiesByState"
            v-model="patient.idCidade"
            class="form-control"
            @change="handleCityChange"
          >
            <option
              v-for="city in citiesByState"
              :key="city.id"
              :value="city.id"
            >
              {{ city.descCidade }}
            </option>
          </select>
          <div v-if="citiesByState && citiesByState.length === 0">
            <p>Nenhuma cidade encontrada.</p>
          </div>
        </b-form-group>
        <b-form-group
          class="col-md-12"
          label="Complemento:"
          label-for="complementoEndereco"
        >
          <b-form-input
            v-model="patient.complementoEndereco"
            type="text"
            v-uppercase
            autocomplete="noautocomplete"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          class="col-md-5"
          label="Ficha do Paciente:"
          label-for="ficha"
        >
          <b-form-input
            id="ficha"
            type="text"
            v-model="patient.fichaPaciente"
          />
        </b-form-group>
        <b-form-group
          style="display: flex; align-items: center"
          class="col-md-2"
        >
          <b-form-checkbox
            v-model="patient.indicacao"
            value="S"
            unchecked-value="N"
            >Indicação</b-form-checkbox
          >
        </b-form-group>
        <b-form-group
          class="col-md-5"
          label="Contato da Indicação:"
          label-for="indcontato"
        >
          <b-form-input
            autocomplete="noautocomplete"
            v-model="patient.indicacaoContato"
            type="text"
            name="indcontato"
            ref="indcontato"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="col-md-6"
          label="Profissão(cbo):"
          label-for="specialty"
        >
          <v-select
            id="specialty"
            :options="cbos"
            label="descCbo"
            :reduce="(cbo) => cbo.id"
            v-model="patient.idCbo"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="Outra Profissão:"
          label-for="outraProfissao"
        >
          <b-form-input
            v-model="patient.profissaoOutros"
            type="text"
            v-uppercase
            autocomplete="noautocomplete"
          ></b-form-input>
        </b-form-group>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Primeira consulta" label-for="Primeira consulta">
            <b-form-input
              id="readonly-input"
              v-uppercase
              readonly
              primary
              :value="formatDate(patient.pacienteDesde, 'pt-BR')"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Última consulta" label-for="Última consulta">
            <b-form-input
              id="readonly-input"
              v-uppercase
              readonly
              primary
              :value="formatDate(patient.dataUltimaConsulta, 'pt-BR')"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Total de consultas"
            label-for="Total de consultas"
          >
            <b-form-input
              id="readonly-input"
              v-uppercase
              readonly
              primary
              :value="patient.quantidadeAgendamentoPaciente"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-col>
      <div class="table-container">
        <table class="table padded-table">
          <thead class="table-head">
            <tr>
              <th class="text-center">Data e Hora</th>
              <th class="text-center">Tipo de Consulta</th>
              <th class="text-center">Situação</th>
              <th class="text-center">Convênio</th>
              <th class="text-center">Valor Consulta</th>
            </tr>
          </thead>
          <tbody>
            <!-- Itera sobre o histórico de consultas do paciente -->
            <tr
              v-for="(historico, index) in patient.listaHistoricoPaciente"
              :key="index"
            >
              <!-- Verifica se o valor de data e hora é null -->
              <td class="text-center">
                {{
                  historico.dtHoraAgendamento
                    ? formatDate(historico.dtHoraAgendamento)
                    : 'Data não informada'
                }}
              </td>

              <!-- Verifica se o valor de tipo de consulta é null -->
              <td class="text-center">
                {{ historico.tipoConsulta || 'Não informado' }}
              </td>

              <!-- Verifica se o valor de situação é null -->
              <td class="text-center">
                {{ historico.situacao || 'Não informado' }}
              </td>

              <!-- Verifica se o valor de convênio é null -->
              <td class="text-center">
                {{ historico.convenio || 'Não informado' }}
              </td>

              <!-- Verifica se o valor de valor da consulta é null -->
              <td class="text-center">
                {{ historico.valorConsulta || 'Não informado' }}
              </td>
            </tr>

            <!-- Mensagem caso não haja histórico de consultas -->
            <tr
              v-if="
                !patient.listaHistoricoPaciente ||
                patient.listaHistoricoPaciente.length === 0
              "
            >
              <td colspan="5" class="text-center no-history-message">
                Paciente ainda não possui histórico.
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Mensagem caso não haja histórico de consultas -->
        <p
          v-if="
            !patient.listaHistoricoPaciente ||
            patient.listaHistoricoPaciente.length === 0
          "
          class="no-history-message text-center"
        >
          Paciente ainda não possui histórico.
        </p>
      </div>
    </b-col>

    <b-form-group
      label="Observação :"
      label-for="observation"
      style="padding-right: 8px; padding-left: 16px; margin-top: 10px"
    >
      <b-form-textarea
        style="height: 70px"
        id="observation"
        v-model="patient.observacao"
      ></b-form-textarea>
    </b-form-group>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancelar
      </b-button>
      <b-button size="sm" variant="primary" @click="editPatient()">
        Salvar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import API from '../../services/index'
// import { formatLocalDateHour } from '../../Utils/formatters'
export default {
  name: 'UserInfoEditModal',
  props: ['patientInfo', 'updatePatientInfo'],

  mounted() {
    API.get('/api/EstadoCivil/ObtemLista').then((response) => {
      this.civilStateOptions = response.data.object
    })

    API.get('api/Sexo/ObtemLista').then((response) => {
      this.genders = response.data.object
    })

    API.get('api/Cbo/ObtemLista', this.authToken).then(
      (res) => (this.cbos = res.data.object)
    )

    this.fetchStates()
    this.fetchCities().then(() => {
      this.getCitiesByState()
    })
  },

  watch: {
    patientInfo(newValue) {
      this.patient = {
        ...newValue,
        idEstado: this.patientInfo?.idEstado || '',
        idCidade: this.patientInfo.idCidade || '',
        dtNascimento: this.patientInfo.dtNascimento
          ? this.formatBirthDate(this.patientInfo.dtNascimento)
          : ''
      }
    },
    'patient.idEstado': function (newValue) {
      if (newValue) {
        this.getCitiesByState()
      }
    },
    'patient.idCidade': function (newValue) {
      if (newValue) {
        this.getCitiesByState()
      }
    },

    'patientInfo.avatar.readyState': function () {
      this.patient.avatar = this.patientInfo.avatar
    },

    'patient.idEstadoCivil': function (newValue) {
      const civilState = this.civilStateOptions.find(
        (element) => element.id === newValue
      )

      this.patient.descEstadoCivil = civilState.descEstadoCivil
    },
    deep: true
  },

  data() {
    return {
      genders: [],
      civilStateOptions: [],
      states: [],
      cities: [],
      citiesByState: [],
      selectedHealthInsurance: '',
      patient: {
        ...this.patientInfo,
        idEstado: this.patientInfo?.idEstado || '',
        idCidade: this.patientInfo.idCidade || '',

        dtNascimento: this.patientInfo.dtNascimento
          ? this.formatBirthDate(this.patientInfo.dtNascimento)
          : '',
        listaHistoricoPaciente: this.patientInfo.listaHistoricoPaciente || []
      },
      cbos: ''
    }
  },
  methods: {
    formatBirthDate(birthDay) {
      const date = new Date(birthDay).toLocaleDateString('en-CA')
      return date
    },
    setSelectedHealthInsurance(value) {
      this.selectedHealthInsurance = value
    },

    formatDate(date, format) {
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString(format, { timeZone: 'UTC' })
    },
    async fetchStates() {
      try {
        const response = await API.get('api/Estado/ObtemLista')
        this.states = response.data.object
      } catch (error) {
        this.$toast.error('Erro ao buscar estados:', error)
      }
    },
    async fetchCities() {
      try {
        const response = await API.get('api/Cidade/ObtemLista', this.authToken)
        this.cities = response.data.object.sort()
      } catch (error) {
        this.$toast.error('Erro ao buscar cidades:', error)
      }
    },
    async getCitiesByState() {
      if (!this.patient.idEstado) {
        return
      }

      try {
        const response = await API.get(
          `/api/Cidade/ObtemCidadesPorEstado/${this.patient.idEstado}`
        )
        this.citiesByState = response.data.object
      } catch (error) {
        this.$toast.error('Erro ao obter cidades:', error)
      }
    },

    handleStateChange() {
      this.getCitiesByState()
    },
    handleCityChange(event) {
      this.patient.idCidade = event.target.value
    },
    async editPatient() {
      try {
        if (this.patient.cep) {
          this.patient.cep = this.patient.cep.replace(/\D/g, '')
        } else {
          this.$toast.warning(
            'CEP está ausente ou é inválido:',
            this.patient.cep
          )
        }

        // Envia a requisição para editar o paciente
        await API.put('/api/Paciente/Editar', {
          object: this.patient
        })

        if (this.patient.dtNascimento) {
          const currentDate = new Date()
          const newBirthDate = new Date(this.patient.dtNascimento)

          const newAgeInMilliseconds = currentDate - newBirthDate
          const newAge = Math.round(
            newAgeInMilliseconds / (1000 * 60 * 60 * 24 * 365)
          )

          this.patient.dtNascimento = newBirthDate.toISOString().slice(0, 19)
          this.patient.idadePaciente = newAge
        }

        // Atualiza as informações do paciente no sistema
        this.updatePatientInfo(this.patient)
        // Mostra uma mensagem de sucesso
        this.$toast.success('Paciente editado com sucesso')

        // Fecha o modal
        this.$bvModal.hide('user__modal')
      } catch (error) {
        // Mostra uma mensagem de erro
        this.$toast.error('Erro ao editar paciente:', error)
      }
    }
  }
}
</script>

<style>
#user__modal h5 {
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
}

#user__modal button.close {
  color: #fff !important;
  font-weight: bold;
}

#readonly-input {
  background-color: #089bab;
  color: #fff;
}
.table-container {
  overflow-y: auto; /* Adiciona rolagem vertical quando o conteúdo exceder a altura máxima */
  border-radius: 4px;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; /* Colapso de borda para evitar espaços entre células */
}

.table-head th {
  white-space: normal; /* Permite que o texto quebre a linha */
  text-align: center; /* Centraliza o texto */
  vertical-align: middle; /* Alinha verticalmente no centro */
  background-color: #089bab;
  color: white;
  font-weight: 400;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table tbody td {
  max-height: 50px; /* Altura máxima da tabela antes de aplicar a rolagem */
  overflow-y: auto; /* Adiciona rolagem vertical quando o conteúdo exceder a altura máxima */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; /* Centraliza o texto */
  vertical-align: middle; /* Alinha verticalmente no centro */
  padding: 4px;
}
.no-history-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #888;
}
</style>
