import { parseToHtml } from '../../../../Utils/parsers'
const COMPLAINT_MODULE_ID = 5

const mutations = {
  loadComplaints(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === COMPLAINT_MODULE_ID
    )

    payload.forEach((complaint) => {
      complaint.descQueixa = parseToHtml(complaint.descQueixa)
    })

    module.data = [...payload]
  },

  removeComplaintFromState(state, { payload }) {
    state.localComplaintsState = {}
  },

  addComplaint(state, { payload }) {
    state.localComplaintsState = payload
  },
  editComplaint(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === COMPLAINT_MODULE_ID
    )

    const complaintIndex = module.data.findIndex(
      (conduct) => conduct.idProntuario === payload.idProntuario
    )

    module.data.splice(complaintIndex, 1, { ...payload, modified: true })
  },

  addComplaintToFavoriteState(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === COMPLAINT_MODULE_ID
    )

    payload.descQueixa = parseToHtml(payload.descQueixa)

    module.favorites.push({
      ...payload,
      acessoRapido: -1,
      favorito: -1,
      location: ''
    })
  }
}

export default mutations
