import { uid } from 'uid'
const ALLERGY_MODULE_ID = 2
const mutations = {
  loadAllAllergies(state, { payload: { allergies, handbookId } }) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )
    allergies.forEach((allergy) => {
      allergy.acao =
        allergy.idProntuario === handbookId ? 'Remover' : 'Inativar'
    })
    module.data = [...allergies]
  },
  updateAllergyField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)
    module.data.splice(dataIndex, 1, { ...payload, modified: true })
  },

  addAllergy(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )
    const dataId = uid()
    module.data.push({
      ...payload,
      id: dataId,
      isNew: true
    })
  },

  removeAllergy(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)
    module.data.splice(dataIndex, 1, { ...payload, deleted: true })
  },

  activateAllergy(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)
    const newAllergy = { ...module.data[dataIndex], acao: 'Inativar' }
    newAllergy.ativo = 0
    module.data.splice(dataIndex, 1, newAllergy)
  },
  inactivateAllergy(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === ALLERGY_MODULE_ID
    )
    const dataIndex = module.data.findIndex((data) => data.id === payload.id)

    const newAllergy = {
      ...payload,
      ativo: 1,
      acao: 'Ativar',
      modified: true
    }

    module.data.splice(dataIndex, 1, newAllergy)
  }
}

export default mutations
