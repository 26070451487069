import { render, staticRenderFns } from "./AddHealthInsurance.vue?vue&type=template&id=6fa29994&scoped=true"
import script from "./AddHealthInsurance.vue?vue&type=script&lang=js"
export * from "./AddHealthInsurance.vue?vue&type=script&lang=js"
import style0 from "./AddHealthInsurance.vue?vue&type=style&index=0&id=6fa29994&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa29994",
  null
  
)

export default component.exports