<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <div class="title-tour">
          <h4 class="card-title">Cadastrar horários</h4>
          <b-icon
            @click="openTour()"
            style="cursor: pointer"
            icon="patch-question-fill"
            font-scale="1.5"
            title="Click aqui para ativar o Tour dessa pagina!"
          ></b-icon>
        </div>
      </template>

      <template v-slot:body>
        <schedule-form @selectedProfessionalId="changeSelectedProfissionalId" />
        <schedules-table :selectedProfessionalId="selectedProfessionalId" />
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import ScheduleForm from './ScheduleForm'
import SchedulesTable from './SchedulesTable.vue'

export default {
  name: 'Schedules',
  components: { ScheduleForm, SchedulesTable },
  data() {
    return {
      selectedProfessionalId: 0
    }
  },
  methods: {
    changeSelectedProfissionalId(id) {
      this.selectedProfessionalId = 0
      this.$nextTick(() => {
        this.selectedProfessionalId = id
      })
    },
    openTour() {
      const scriptElement = document.getElementById('step-tour2')
      if (scriptElement === null) {
        this.$tours.tourH.start()
      } else {
        this.$tours.tourH.stop()
      }
    }
  }
}
</script>
<style scoped>
.title-tour {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
