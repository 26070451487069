import API from '../../services/index'

export default {
  async getNotificationsAction(context, object) {
    const dates = notificationPeriodValues(object.period)
    const list = []
    try {
      const response1 = await API.get(
        '/api/NotificacaoTarefa/ObtemNotificacaoTarefaPorUsuario',
        {
          params: {
            id_usuario: object.idUser,
            retorno_visualizacao: object.getConcludeds,
            inicio: dates.initialDate,
            final: dates.today
          }
        }
      )

      const response2 = await API.get(
        '/api/NotificacaoTarefa/ObtemNotificacaoEncaminhamentoPorUsuario',
        {
          params: {
            id_usuario: object.idUser,
            retorno_visualizacao: object.getConcludeds,
            inicio: dates.initialDate,
            final: dates.today
          }
        }
      )
      list.push(...response2.data.object)
      list.push(...response1.data.object)

      if (object.getConcludeds === 0) {
        const listAux = list.filter(
          (item) => item.visualizacaoNotificacao === 0
        )
        context.commit('changeNotifications', listAux)
      } else {
        const listAux = list.filter(
          (item) => item.visualizacaoNotificacao === 1
        )
        context.commit('changeNotifications', listAux)
      }
    } catch (e) {
      return false
    }
  },

  async concludeNotificationAction(context, object) {
    try {
      await API.get('/api/NotificacaoTarefa/MarcarNotificacao', {
        params: {
          id_notificacao: object.id_notificacao,
          id_usuario: object.idUser
        }
      })

      context.commit('concludeNotification', object)
    } catch (e) {
      return false
    }
  }
}

function notificationPeriodValues(period) {
  let today = new Date().toLocaleDateString('en-CA')
  const todayInMilliseconds = new Date()
  const daysInMilliseconds = period * 24 * 60 * 60 * 1000

  let initialDate = new Date(
    todayInMilliseconds - daysInMilliseconds
  ).toLocaleDateString('en-CA')

  today += 'T23:59:59'
  initialDate += 'T00:00:00'

  return {
    initialDate,
    today
  }
}
