<template>
  <div class="custom-container">
    <div class="video-container">
      <div id="remoteTrack">
        <span
          class="message"
          v-if="activeRoom && !remoteConnected && !remoteDisconnected"
          >Aguardando Participante</span
        >

        <span
          class="message"
          v-if="activeRoom && !remoteConnected && remoteDisconnected"
          >Participante Desconectado</span
        >

        <div class="remoteIconsContainer" v-if="remoteMuted || remoteCamHided">
          <b-icon
            icon="mic-mute-fill"
            variant="danger"
            scale="1.5"
            v-if="remoteMuted"
          />

          <b-icon
            icon="camera-video-off-fill"
            variant="danger"
            scale="1.5"
            v-if="remoteCamHided"
          />
        </div>
      </div>
      <div id="localTrack">
        <span class="message" v-if="localConnecting">Conectando</span>
      </div>
    </div>

    <div class="buttons">
      <b-button
        size="lg"
        @click="mute = !mute"
        variant="outline-dark"
        :disabled="!activeRoom"
      >
        <b-icon
          :icon="mute ? 'mic-mute-fill' : 'mic-fill'"
          :variant="mute ? 'danger' : 'white'"
          scale="1.5"
        />
      </b-button>

      <b-button
        @click="hideCamera = !hideCamera"
        variant="outline-dark"
        :disabled="!activeRoom"
      >
        <b-icon
          :icon="hideCamera ? 'camera-video-off-fill' : 'camera-video-fill'"
          :variant="hideCamera ? 'danger' : 'white'"
          scale="1.5"
        />
      </b-button>

      <!-- <b-button :disabled="!!activeRoom" variant="outline-dark">
        <b-icon icon="chat-left-dots-fill" variant="white" scale="1.5" />
      </b-button> -->

      <b-button
        :disabled="!activeRoom"
        @click="leaveRoomIfJoined"
        variant="danger"
        class="turn-off-button"
      >
        <b-icon
          icon="telephone-fill"
          variant="white"
          scale="1.5"
          rotate="135"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapActions } from 'vuex'
export default {
  props: ['localTracks', 'identification'],
  name: 'Telemedicine',
  data() {
    return {
      participantIdentity: '',
      remoteIdentity: '',
      activeRoom: '',
      roomName: '',
      localTrack: false,
      mute: false,
      hideCamera: false,
      chatOpened: false,
      localConnecting: false,
      remoteConnected: false,
      remoteDisconnected: false,
      remoteMuted: false,
      remoteCamHided: false
    }
  },
  mounted() {
    const chatBox = document.querySelector(
      '[title="Botão para abrir a janela de mensagens"]'
    )
    if (chatBox) {
      chatBox.remove()
    }
    this.createChatOptions()
  },
  watch: {
    mute(newValue) {
      if (newValue) {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable()
        })
      } else {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable()
        })
      }
    },
    hideCamera(newValue) {
      if (newValue) {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable()
        })
      } else {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable()
        })
      }
    }
  },
  methods: {
    ...mapActions({
      getToken: 'Video/getTokenAction',
      getActiveRooms: 'Video/getActiveRoomsAction',
      createNewRoom: 'Video/createNewRoomAction'
    }),
    handleConnectedLocalParticipant(participant) {
      // create a div for this participant's tracks
      const participantDiv = document.createElement('div')
      this.participantIdentity = participant.identity
      participantDiv.setAttribute('id', participant.identity)
      const container = document.getElementById('localTrack')
      container.appendChild(participantDiv)

      // iterate through the participant's published tracks and
      // call `handleTrackPublication` on them
      participant.tracks.forEach((trackPublication) => {
        this.handleTrackPublication(trackPublication, participant)
      })

      // listen for any new track publications
      participant.on('trackPublished', this.handleTrackPublication)
    },

    handleConnectedRemoteParticipant(participant) {
      // create a div for this participant's tracks
      const participantDiv = document.createElement('div')
      this.remoteIdentity = participant.identity
      participantDiv.setAttribute('id', participant.identity)
      const container = document.getElementById('remoteTrack')
      container.appendChild(participantDiv)

      // iterate through the participant's published tracks and
      // call `handleTrackPublication` on them
      participant.tracks.forEach((trackPublication) => {
        this.handleTrackPublication(trackPublication, participant)

        this.handleTrackDisabled(trackPublication)
        this.handleTrackEnabled(trackPublication)
      })

      this.remoteConnected = true
      this.remoteDisconnected = false

      // listen for any new track publications
      participant.on('trackPublished', this.handleTrackPublication)
    },

    handleTrackPublication(trackPublication, participant) {
      function displayTrack(track) {
        // append this track to the participant's div and render it on the page
        const participantDiv = document.getElementById(participant.identity)

        // track.attach creates an HTMLVideoElement or HTMLAudioElement
        // (depending on the type of track) and adds the video or audio stream
        participantDiv.append(track.attach())
      }

      // check if the trackPublication contains a `track` attribute. If it does,
      // we are subscribed to this track. If not, we are not subscribed.

      if (trackPublication.track) {
        displayTrack(trackPublication.track)
      }

      // listen for any new subscriptions to this track publication
      trackPublication.on('subscribed', displayTrack)
    },
    handleTrackDisabled(track) {
      track.on('trackDisabled', () => {
        if (track.kind === 'audio') {
          this.remoteMuted = true
        } else if (track.kind === 'video') {
          this.remoteCamHided = true
        }
      })
    },
    handleTrackEnabled(track) {
      track.on('trackEnabled', () => {
        if (track.kind === 'audio') {
          this.remoteMuted = false
        } else if (track.kind === 'video') {
          this.remoteCamHided = false
        }
      })
    },
    async createChatOptions() {
      const token = await this.getToken('Patient')

      this.roomName = window.atob(this.identification)

      const connectOptions = {
        name: this.roomName,
        tracks: this.localTracks
      }

      this.connectWithChatService(token, connectOptions)
    },

    async connectWithChatService(token, connectOptions) {
      this.localConnecting = true
      const Video = Twilio.Video

      const room = await Video.connect(token, connectOptions)

      this.activeRoom = room
      this.roomName = room.name

      // render the local and remote participants' video and audio tracks

      this.handleConnectedLocalParticipant(room.localParticipant)
      room.participants.forEach(this.handleConnectedRemoteParticipant)
      room.on('participantConnected', this.handleConnectedRemoteParticipant)

      this.localConnecting = false

      room.on('participantDisconnected', () => {
        const remoteVideoElement = document.getElementById(this.remoteIdentity)

        if (remoteVideoElement) {
          remoteVideoElement.remove()
        }

        this.remoteConnected = false
        this.remoteDisconnected = true
      })

      room.on('reconnecting', (error) => {
        if (error.code === 53001) {
          console.log('Reconnecting your signaling connection!', error.message)
        } else if (error.code === 53405) {
          console.log('Reconnecting your media connection!', error.message)
        }
      })

      room.on('reconnected', () => {
        console.assert.equal(room.state, 'connected')
        console.log('Reconnected your signaling and media connections!')
      })
    },

    async leaveRoomIfJoined() {
      if (this.activeRoom) {
        await this.activeRoom.disconnect()

        this.activeRoom = ''
        this.remoteConnected = false

        const localVideoElement = document.getElementById(
          this.participantIdentity
        )

        if (localVideoElement) {
          localVideoElement.remove()
        }

        if (this.remoteIdentity) {
          const remoteVideoElement = document.getElementById(
            this.remoteIdentity
          )

          if (remoteVideoElement) {
            remoteVideoElement.remove()
          }
        }

        this.$emit('close')
      }
    }
  },

  beforeDestroy() {
    this.leaveRoomIfJoined()
  }
}
</script>

<style scoped>
.video-container {
  width: 100vw;
  height: 90vh;

  display: flex;
  flex-direction: row;
}

#localTrack {
  display: flex;
  background-color: #3c3c3c;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

#remoteTrack {
  position: relative;
  display: flex;
  background-color: #3c3c3c;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.buttons {
  display: flex;
  background-color: #3c3c3c;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 8px;
  height: 10vh;
  width: 100%;
}

.turn-off-button {
  width: 30%;
}

.message {
  font-size: 16px;
  color: white;
}

.remoteIconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 5px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50px;
}

.remoteIconsContainer svg:not(:first-child) {
  margin-left: 20px;
}

@media only screen and (orientation: portrait) {
  .video-container {
    flex-direction: column;
  }

  #localTrack {
    height: 50%;
    width: 100%;
  }

  #remoteTrack {
    height: 50%;
    width: 100%;
  }
}

@media (min-width: 1080px) {
  .buttons {
    padding: 8px 25%;
  }

  .turn-off-button {
    width: 25%;
  }
}
</style>

<style>
video {
  max-width: 100%;
  max-height: 100%;
}
</style>
