import API from '../../../../services/index'

const newFile = ({ isNew, id, ...file }, bodyFormData) => {
  const fileType =
    file.file.type === 'image/jpeg'
      ? 'image'
      : file.file.type === 'application/pdf'
      ? 'pdf'
      : 'doc'
  const fileName = file.file.name
  API.post(
    `/api/ProntuarioArquivoImagem/AwsS3UploadArquivo?tipoArquivo=${fileType}&filename=${fileName}&prontuario_id=${file.idProntuario}`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${window.$cookies.get('token')}`
      }
    }
  ).then(
    API.post(`/api/ProntuarioArquivoImagem/Adicionar`, {
      object: {
        idProntuario: file.idProntuario,
        dtCadastro: file.dtCadastro,
        dtOcorrencia: file.dtOcorrencia,
        tipoArquivoImagem: file.tipoArquivoImagem,
        descAwsArquivoImagem: file.descAwsArquivoImagem,
        descArquivoImagem: file.descArquivoImagem
      }
    })
  )
}

const editFile = ({ isEdit, file, ...payload }) => {
  API.put(`/api/ProntuarioArquivoImagem/Editar`, {
    object: payload
  })
}

const actions = {
  async sendFiles({ commit }, { payload }) {
    payload.forEach((file) => {
      const bodyFormData = new FormData()
      bodyFormData.append('file', file.file)
      if (file.isNew) {
        newFile(file, bodyFormData)
      } else if (file.isEdit) {
        editFile(file)
      }
    })
  }
}

export default actions
