<template>
  <div class="module">
    <div class="details">
      <p
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </p>

      <div class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactivesProblems"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>

      <favorites-select
        :favorites="
          componentInfo.favorites.filter((favorite) => favorite.favorito === 0)
        "
        title-property="descCid"
        @on-favorite-chosen="addProblem"
        :with-sub-list="true"
      />
      <div style="width: 25%; position: relative; margin: 0 8px">
        <div style="position: relative">
          <b-form-input
            class="details__input"
            autocomplete="off"
            v-model="filter"
            placeholder="Pesquisar"
          ></b-form-input>
          <span
            v-if="isLoading"
            style="
              position: absolute;
              right: 5%;
              top: 50%;
              transform: translateY(-50%);
            "
          >
            <b-spinner
              small
              key="problems-spinner"
              variant="primary"
            ></b-spinner>
          </span>
        </div>
        <search-result
          :results="results"
          :show-degree-list="true"
          cod-name="CID"
          id-property="idCid"
          title-property="descCid"
          @on-favorite-chosen="addProblemToFavorites"
          @on-quick-access-chosen="addProblemToQuickAccess"
          @on-result-selected="addProblem"
        />
        <div
          @click="hideSearchResults"
          v-if="results.length > 0"
          style="
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
          "
        ></div>
      </div>

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>
    <quick-access
      v-if="
        componentInfo.favorites.some((favorite) => favorite.acessoRapido === 0)
      "
      :quick-accesses="
        componentInfo.favorites.filter(
          (favorite) => favorite.acessoRapido === 0
        )
      "
      title-property="descCid"
      @on-quick-access-chosen="addProblem"
      :with-sub-list="true"
    />

    <div class="content">
      <problems-table
        :table-data="dataToDisplay"
        :expanded="componentInfo.aberto === 0"
        :id-medico="idMedico"
        :module-id="componentInfo.id"
        :header-background="componentInfo.corHexadecimal"
      />
    </div>
  </div>
</template>

<script>
import API from '../../../../services/index'

import SearchResult from '../../shared/SearchResult.vue'
import FavoritesSelect from '../../shared/FavoritesSelect.vue'
import QuickAccess from '../../shared/QuickAccess.vue'

import ProblemsTable from './components/ProblemsTable.vue'
import { formatDateHour } from '@/Utils/formatters'
import { mapGetters } from 'vuex'

export default {
  name: 'Problems',
  props: ['componentInfo', 'idProntuario', 'idMedico'],
  components: {
    ProblemsTable,
    QuickAccess,
    FavoritesSelect,
    SearchResult
  },
  data() {
    return {
      filter: '',
      showInactivesProblems: false,
      results: [],
      isLoading: false,
      efficientGetApiResult: this.debounce(this.getApiResults, 1000)
    }
  },

  watch: {
    filter() {
      if (this.filter === '') {
        this.results = []
      }

      this.efficientGetApiResult()
    }
  },

  computed: {
    ...mapGetters({
      user: 'Setting/user',
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    amountOfQuickAccesses() {
      return this.componentInfo.favorites.reduce((accumulator, favorite) => {
        if (favorite.acessoRapido === 0) {
          accumulator += 1
        }
        return accumulator
      }, 0)
    },

    dataToDisplay() {
      const items = this.showInactivesProblems
        ? this.componentInfo.data
        : this.filterInactives()

      return items
    }
  },

  methods: {
    filterInactives() {
      const filtered = [...this.componentInfo.data].filter((element) => {
        return element.ativo !== 1
      })

      return filtered
    },
    hideSearchResults() {
      this.filter = ''
      this.results = []
    },
    async getApiResults() {
      if (!this.filter) {
        return
      }
      try {
        this.isLoading = true
        const apiResponse = await API.get(
          '/api/FavoritoProblema/ObtemListaPorCid',
          {
            params: {
              buscaCid: this.filter,
              id_prontuario_preferencia: this.componentInfo.id
            }
          }
        )
        this.isLoading = false
        this.results = apiResponse.data.object
      } catch (error) {
        this.isLoading = false

        this.$toast.error('Nenhum resultado foi encontrado', {
          position: 'bottom-right'
        })
      }
    },

    debounce(func, wait) {
      let timeout = null

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(func, wait)
      }
    },

    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    },
    addProblem(problemData) {
      let problemDegree

      switch (problemData.grau) {
        case 0:
          problemDegree = 'Leve'
          break
        case 1:
          problemDegree = 'Moderada'
          break
        case 2:
          problemDegree = 'Severo'
          break
      }

      const requestObject = {
        idProntuario: this.idProntuario,
        dtOcorrencia: formatDateHour(),
        dtInclusao: formatDateHour(),
        nmProfissional: this.user.nome,
        codCid: problemData.idCid,
        descCid: problemData.descCid,
        grau: problemData.grau,
        descGrau: problemDegree,
        ativo: 0,
        observacao: '',
        acao: 'Remover'
      }
      this.$store.commit({
        type: 'MedicalReports/addProblem',
        payload: requestObject
      })

      this.filter = ''
    },
    removeProblemFavoriteFromHandBook(favorite) {
      this.$store.dispatch({
        type: 'MedicalReports/removeProblemFavoriteFromHandBook',
        payload: favorite
      })
    },
    addProblemToFavorites(data) {
      this.$store.dispatch({
        type: 'MedicalReports/addProblemToFavoritesHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })
      this.filter = ''
    },

    addProblemToQuickAccess(data) {
      if (this.amountOfQuickAccesses >= 5) {
        this.$toast.warning(
          'Quantidade máxima de acesso rápido já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }
      this.$store.dispatch({
        type: 'MedicalReports/addProblemToQuickAccessHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })

      this.filter = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';
.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}
.module {
  margin: 0px 0;

  &__icon {
    align-self: center;
  }
}
.details {
  display: flex;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__input {
    &::placeholder {
      text-transform: none;
      color: #000 !important;
    }
    border-color: rgb(8, 155, 171) !important;

    background-color: white !important;
  }

  &__checkbox-container {
    margin-left: 4px;
    color: #000;
    display: inline-flex;
    align-items: center;
    margin-right: auto;
  }
}

.table-header {
  background-color: #089bab;
  color: #fff;
  font-size: 13px;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  align-items: center;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cta-btn {
  color: red;
  border: 1px solid red;
  background-color: transparent;
  padding: 8px 16px;
  text-align: center;
}
</style>
