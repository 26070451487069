import { parseToHtml } from '../../../../Utils/parsers'
const PERSONAL_HISTORY_MODULE_ID = 4
const mutations = {
  loadHistory(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === PERSONAL_HISTORY_MODULE_ID
    )
    if (payload.length > 0) {
      module.data = {
        ...payload[0],
        descHistPessoalFamiliar: parseToHtml(payload[0].descHistPessoalFamiliar)
      }
    } else {
      module.data = {}
    }
  },
  addFamiliarAndPersonalHistory(state, { payload }) {
    state.localPersonalHistoryState = payload
  },

  removePersonalAndFamiliarHistoryFromState(state, { payload }) {
    state.localPersonalHistoryState = {}
  }
}

export default mutations
