export default {
  isGraphORTable: true,
  isGraphORTableExpense: true,
  dataGraphOne: [],
  dataGraphTwo: [],
  dataGraphTree: [],
  dateGraphTwoInit: '',
  dateGraphTwoFinaly: '',
  dateGraphTreeInit: '',
  dateGraphTreeFinaly: '',
  doctorGraphTwo: '',
  clinicGraphTwo: '',
  doctorGraphTree: '',
  clinicGraphTree: '',

  openModalAlter: false,
  openModalAlterE: false
}
