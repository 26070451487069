const getters = {
  getComplaintByIdHandBook: (state) => (id) => {
    const complaint = state.localComplaintsState.find(
      (complaint) => complaint.idProntuario === id
    )

    return complaint
  }
}

export default getters
