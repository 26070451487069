<template>
  <div class="module">
    <div class="details">
      <div
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </div>
      <div class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactivesExams"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>

      <favorites-select
        :title="'Codigo'"
        :favorites="componentFavorites"
        :titleProperty="'codigoServico'"
        :descriptionProperty="'descServico'"
        @on-favorite-chosen="addExamHistory"
      />

      <div style="width: 25%; position: relative; margin: 0 8px">
        <div style="position: relative">
          <b-form-input
            class="details__input"
            autocomplete="off"
            v-model="filter"
            placeholder="Pesquisar"
            @input="getSearchExamsList"
          ></b-form-input>
        </div>
        <search-result
          :results="results"
          :show-degree-list="false"
          id-property="codigoServico"
          title-property="descServico"
          @on-favorite-chosen="addExamHistoryToFavorites"
          @on-quick-access-chosen="addExamHistoryToQuickAccess"
          @on-result-selected="addExamHistory"
          :selectOnDescription="true"
        />
        <div
          @click="hideSearchResults"
          v-if="results.length > 0"
          style="
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
          "
        ></div>
      </div>

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>
    <quick-access
      v-if="componentQuickAccess.length > 0"
      :quickAccesses="componentQuickAccess"
      title-property="descServico"
      @on-quick-access-chosen="addExamHistory"
    />

    <div v-if="componentInfo.aberto === 0">
      <ExamsList
        :header-color="componentInfo.corHexadecimal"
        :exams-list="examsListToShow"
        :idPatient="idPaciente"
        :idDoctor="idMedico"
        :id-prontuario="idProntuario"
        @add-file="addFile"
        @add-info="addInfo"
        @preview-file="previewFile"
        @update-exam-field="updateExamField"
        @update-chart="updateChart"
        @handle-remove-or-inactivate="handleRemoveOrInactivate"
        @handle-remove-file="handleRemoveFile"
        @showGraph="showGraph"
      />
      <GraphComponent
        v-if="showChart"
        :name="chartData.name"
        :seriesData="chartData.seriesData"
        :xAxisData="chartData.categoriesData"
        @closeGraph="closeGraph"
      />

      <PreviewBiggerModal
        v-if="hasPreview"
        :file="fileToPreview"
        @closeFileModal="closeFileModal"
      />
    </div>
  </div>
</template>

<script>
import FavoritesSelect from '../../shared/FavoritesSelect.vue'
import QuickAccess from '../../shared/QuickAccess.vue'
import ExamsList from './components/ExamsList.vue'
import GraphComponent from '../../../GraphComponent.vue'
import PreviewBiggerModal from './components/PreviewBiggerModal.vue'
import API from '../../../../services/index'
import SearchResult from '../../shared/SearchResult.vue'
import { uid } from 'uid'
import {
  formatDate,
  formatDateHour,
  formatSystemDate
} from '../../../../Utils/formatters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ExamsHistory',
  props: [
    'componentInfo',
    'idPaciente',
    'idProntuario',
    'idMedico',
    'patientInfo'
  ],
  components: {
    QuickAccess,
    FavoritesSelect,
    ExamsList,
    SearchResult,
    GraphComponent,
    PreviewBiggerModal
  },

  data() {
    return {
      filter: '',
      showInactivesExams: false,
      fileToPreview: '',
      hasPreview: false,
      showChart: false,
      examListIndexToShowChart: null,
      results: [],
      windowHeight: window.innerHeight
    }
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })
  },

  watch: {
    filter() {
      if (this.filter === '') {
        this.results = []
      }

      this.getExamsExists()
    }
  },

  computed: {
    ...mapGetters({
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    amountOfQuickAccesses() {
      return this.componentInfo.favorites.reduce((accumulator, favorite) => {
        if (favorite.acessoRapido === 0) {
          accumulator += 1
        }
        return accumulator
      }, 0)
    },
    chartData() {
      if (this.examsListToShow[this.examListIndexToShowChart]) {
        const data = []
        const name = `${
          this.examsListToShow[this.examListIndexToShowChart].codigoServico
        } - ${this.examsListToShow[this.examListIndexToShowChart].descServico}`

        if (this.showInactivesExams) {
          this.examsListToShow.forEach((exam) => {
            if (
              exam.codigoServico ===
                this.examsListToShow[this.examListIndexToShowChart]
                  .codigoServico &&
              exam.dtRealizacao &&
              exam.resultado
            ) {
              const formattedDate = exam.dtRealizacao.includes('T')
                ? formatDate(exam.dtRealizacao, 'pt-BR')
                : exam.dtRealizacao.split('-').reverse().join('/')

              data.push({
                y: exam.resultado,
                x: formattedDate
              })
            }
          })
        } else {
          this.examsListToShow.forEach((exam) => {
            if (
              exam.codigoServico ===
                this.examsListToShow[this.examListIndexToShowChart]
                  .codigoServico &&
              exam.dtRealizacao &&
              exam.ativo === 0 &&
              exam.resultado
            ) {
              const formattedDate = exam.dtRealizacao.includes('T')
                ? formatDate(exam.dtRealizacao, 'pt-BR')
                : exam.dtRealizacao.split('-').reverse().join('/')

              data.push({
                y: exam.resultado,
                x: formattedDate
              })
            }
          })
        }

        data.sort(function (a, b) {
          const date1 = a.x.split('/')
          const date2 = b.x.split('/')
          return (
            date1[2] - date2[2] || date1[1] - date2[1] || date1[0] - date2[0]
          )
        })

        const categoriesData = [...data].map((element) => {
          return element.x
        })

        const seriesData = [...data].map((element) => {
          return element.y
        })

        return {
          name,
          categoriesData,
          seriesData
        }
      } else {
        return false
      }
    },
    componentFavorites() {
      return this.componentInfo.favorites.filter(
        (favorite) => favorite.location === 'favorite'
      )
    },
    componentQuickAccess() {
      return this.componentInfo.favorites.filter(
        (favorite) => favorite.location === 'quickAccess'
      )
    },
    examsListToShow() {
      if (this.showInactivesExams) {
        return this.componentInfo.data
      } else {
        return this.componentInfo.data.filter((exam) => exam.ativo === 0)
      }
    }
  },

  methods: {
    ...mapActions({
      getValueService: 'User/getValueService'
    }),
    closeGraph() {
      this.showChart = false
    },
    showGraph() {
      this.showChart = true
    },
    closeFileModal() {
      this.hasPreview = false
    },
    showFileModal() {
      this.hasPreview = true
    },
    updateChart(index) {
      this.examListIndexToShowChart = index
      this.fileToPreview = null
    },
    hideSearchResults() {
      this.filter = ''
      this.results = []
    },

    getSearchExamsList(filter) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        API.get(
          `/api/FavoritoHistoricoExame/ObtemListaPorServico/?buscaServico=${filter}&id_prontuario_preferencia=${this.componentInfo.id}`
        ).then((res) => (this.results = res.data.object))
      }, 1000)
    },
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    },
    addFile({ file, attachToAllExams, examToAddFile }) {
      if (attachToAllExams) {
        this.componentInfo.data.forEach((exam) => {
          if (
            exam.dtSolicitacao === examToAddFile.dtSolicitacao &&
            exam.descArquivo.length === 0
          ) {
            exam.descArquivo = file.descArquivo
            exam.dtRealizacao = new Date().toLocaleDateString('en-CA')
            exam.file = file.localFile

            this.updateExam(exam)
          }
        })
      } else {
        examToAddFile.descArquivo = file.descArquivo
        examToAddFile.dtRealizacao = new Date().toLocaleDateString('en-CA')
        examToAddFile.file = file.localFile

        this.updateExam(examToAddFile)
      }
    },
    addInfo({ content, examToAddInfo }) {
      examToAddInfo.descLaudoInfo = content.info
      examToAddInfo.descGrauAlteracao = content.descGrauAlteracao
      examToAddInfo.diaRepeticao = content.diaRepeticao
      examToAddInfo.idGrauAlteracao = content.idGrauAlteracao

      this.updateExam(examToAddInfo)
    },
    async addExamHistoryToFavorites(data) {
      this.$store.dispatch({
        type: 'MedicalReports/addExamHistoryToFavoritesHandBook',
        payload: {
          ...data,
          valorServico: 0,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })
      this.filter = ''
      this.results = []
    },

    addExamHistoryToQuickAccess(data) {
      if (this.amountOfQuickAccesses >= 5) {
        this.$toast.warning(
          'Quantidade máxima de acesso rápido já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }
      this.$store.dispatch({
        type: 'MedicalReports/addExamHistoryToQuickAccessHandBook',
        payload: {
          ...data,
          valorServico: 0,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })

      this.filter = ''
      this.results = []
    },
    async addExamHistory(data) {
      let value = await this.getValueService({
        idUsuario: this.idMedico,
        idService: data.codigoServico,
        idConvenio: this.patientInfo.convenioPaciente[0].idConvenio
      })
      if (value === false) {
        value = 0
      } else {
        if (value.length === 0) {
          value = 0
        } else {
          value = value[0]
        }
      }
      const id = uid()
      this.$store.commit({
        type: 'MedicalReports/addExamHistory',
        payload: {
          codigoServico: data.codigoServico,
          valorServico: value,
          descServico: data.descServico,
          dtSolicitacao: formatDateHour(),
          dtRealizacao: formatSystemDate(),
          qtdeSolicitacao: 1,
          descArquivo: '',
          ativo: 0,
          id,
          acao: 'Remover',
          idProntuario: this.idProntuario
        }
      })
      this.filter = ''
      this.results = []
    },
    updateExam(newExam) {
      this.$store.commit({
        type: 'MedicalReports/updateExamField',
        payload: newExam
      })
    },
    updateExamField(exam, field, value) {
      const payload = {
        ...exam,
        [field]: field === 'resultado' ? parseInt(value) || 0 : value
      }

      if (field === 'resultado' && !payload.dtRealizacao) {
        payload.dtRealizacao = formatSystemDate()
      }

      this.$store.commit({
        type: 'MedicalReports/updateExamField',
        payload
      })
    },
    previewFile(file) {
      this.fileToPreview = null
      this.hasPreview = false
      this.showChart = false

      this.$nextTick(() => {
        this.hasPreview = true
        this.fileToPreview = file
      })
    },
    handleRemoveOrInactivate(exam) {
      const shouldRemove = exam.acao === 'Remover'
      this.examListIndexToShowChart = null
      this.showChart = false
      if (shouldRemove) {
        this.$store.commit({
          type: 'MedicalReports/removeExamHistory',
          payload: exam
        })
      } else {
        this.$store.commit({
          type: 'MedicalReports/updateExamField',
          payload: {
            ...exam,
            ativo: exam.ativo === 0 ? 1 : 0,
            acao: exam.ativo === 0 ? 'Ativar' : 'Inativar'
          }
        })
      }
    },

    async handleRemoveFile(exam) {
      if (exam.file) {
        this.updateExam({ ...exam, file: null, descArquivo: '' })
      } else if (exam.descArquivo) {
        await API.delete(
          `/api/ProntuarioHistoricoExame/AwsS3RemoverArquivo?arquivoname=${exam.descArquivo}&prontuario_id=${this.idProntuario}`
        )

        this.updateExam({ ...exam, descArquivo: '' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';
[list]::-webkit-calendar-picker-indicator {
  display: none;
}

.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}

.module {
  margin: 32px 0;
}
.details {
  display: flex;
  align-items: center;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__input {
    &::placeholder {
      text-transform: none;
      color: #000 !important;
    }
    border-color: rgb(8, 155, 171) !important;

    background-color: white !important;
  }

  &__checkbox-container {
    margin-left: 4px;
    margin-right: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}
</style>
