import { render, staticRenderFns } from "./tableRevenue.vue?vue&type=template&id=6f05435a&scoped=true"
import script from "./tableRevenue.vue?vue&type=script&lang=js"
export * from "./tableRevenue.vue?vue&type=script&lang=js"
import style0 from "./tableRevenue.vue?vue&type=style&index=0&id=6f05435a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f05435a",
  null
  
)

export default component.exports