<template>
  <div style="width: 100%; height: 30px">
    <b-form-group>
      <div class="iq-search-bar" style="padding: 0">
        <form action="#" class="searchbox">
          <input
            ref="input"
            autocomplete="noautocomplete"
            type="text"
            @input="searchService($event)"
            @keydown.enter.prevent="searchService($event)"
            v-model="service"
            class="text search-input"
            placeholder="Busca pelo nome do serviço"
          />

          <div class="search-link">
            <i v-if="!loading" class="ri-search-line"></i>
            <b-spinner
              v-else
              class="alignCenter"
              small
              variant="primary"
            ></b-spinner>
          </div>
        </form>
      </div>
      <iq-card>
        <div
          @click="showServices = false"
          v-if="showServices && services.length > 0"
          style="
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
          "
        ></div>
        <div class="searchResults" v-if="showServices && services.length > 0">
          <div class="headerSearch">
            <span>Código</span>
            <span>Serviço</span>
          </div>
          <div
            class="serviceItem"
            v-for="service in services"
            :key="service.id"
            @click="selectService(service)"
          >
            <span>{{ service.codigoServico }}</span>
            <span>{{ service.descServico }}</span>
          </div>
        </div>
      </iq-card>
    </b-form-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['idClinic', 'isNewService'],
  name: 'SearchService',
  data() {
    return {
      service: '',
      services: [],
      showServices: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  watch: {
    isNewService(newValue) {
      if (newValue) {
        this.service = ''
      }
    }
  },
  methods: {
    ...mapActions({ getServices: 'ASO/getServices' }),
    focus() {
      this.$refs.input.focus()
    },
    async searchService(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        const response = await this.getServices(this.service)
        this.services = response

        this.showServices = true
        if (!response || !response[0]) {
          this.$toast.error('Nenhum resuldado encontrado')
        }
      }, 1000)
    },
    selectService(service) {
      const object = {
        idServico: service.codigoServico,
        descServico: service.descServico,
        dataResultado: ''
      }
      this.service = ''
      this.$emit('selectedService', object)
      this.showServices = false
    }
  }
}
</script>

<style scoped>
.alignCenter {
  vertical-align: middle !important;
}
.searchResults {
  top: 30px;
  z-index: 10;
  position: absolute;
  width: 1000px;
  height: auto;
  max-height: 400px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
}

.headerSearch {
  display: grid;
  grid-template-columns: 1fr 9fr;
  height: 30px;
  gap: 5px;
  background: #089bab;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  color: #fff;
  font-weight: bold;
}

.serviceItem {
  display: grid;
  grid-template-columns: 1fr 9fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  border-top: 1px solid #333;
  height: auto;
  padding: 10px;
}

.serviceItem:hover {
  background: #d1edf0;
  cursor: pointer;
}
</style>
