const getters = {
  getTrackingByIdPatient: (state) => (id) => {
    const trackingList = state.localTrackingState.find(
      (tracking) => tracking.idPaciente === id
    )

    return trackingList
  }
}

export default getters
