<template>
  <ul :class="['container', upper ? 'upper' : 'bottom']" ref="list">
    <li
      class="item"
      v-for="option in options"
      @click="handleSelection(option)"
      :ref="option === initialValue ? 'selected' : null"
      :key="option"
    >
      {{ option }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ScrollableList',
  props: {
    options: {
      type: Array,
      required: true
    },

    initialValue: {
      type: String,
      required: false
    },

    upper: {
      type: Boolean,
      required: false
    }
  },

  mounted() {
    this.$refs.list.scrollTo({
      behavior: 'smooth',
      top: this.$refs.selected[0].offsetTop
    })
  },
  methods: {
    handleSelection(option) {
      this.$emit('handle-selection', option)
    }
  }
}
</script>

<style scoped>
.container {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
  z-index: 5;
  padding: 0;
}

.upper {
  bottom: 40%;
}

.bottom {
  top: 100%;
}

.item {
  padding: 8px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
</style>
