<template>
  <b-modal
    id="modalRecorrencia"
    header-bg-variant="primary"
    title="Recorrência de Agendamento"
    hide-footer
    size="lg"
    title-class="text-light"
    @close="close()"
    @hidden="close()"
  >
    <div class="container-rec">
      <h1>Agendamento Origem:</h1>
      <div class="data-origem">
        <span>Paciente: {{ dataReconrrecia.schedule.pacienteNome }}</span>
        <span
          >Data:
          {{ formartDateInclude(dataReconrrecia.schedule.dtAgendamento) }}</span
        >
        <span>Horário: {{ dataReconrrecia.schedule.horaAgendamento }}</span>
      </div>
      <h2>Repetir no perido:</h2>
      <div class="box-period">
        <div class="box-date">
          <div class="standard">
            <label>Data inicial:</label>
            <input v-model="dataInit" type="date" />
          </div>
          <div class="standard">
            <label>Data final:</label>
            <input v-model="dataEnd" type="date" />
          </div>
        </div>
        <div class="standard">
          <label>A cada: (Em dias)</label>
          <input v-model="daysOfPeriod" type="number" />
        </div>
        <b-form-checkbox
          style="margin-top: 20px"
          id="checkbox-genaration"
          v-model="generation"
          name="checkbox-genaration"
          :value="1"
          :unchecked-value="0"
        >
          Gerar receita
        </b-form-checkbox>
        <button
          :disabled="daysOfPeriod !== null ? false : true"
          @click="getData()"
        >
          Atualizar
        </button>
      </div>
      <div v-if="isMobile()" class="box-list-cards">
        <div v-for="(item, index) in dataList" :key="index" class="list-card">
          <div v-if="dataList.length !== 0" class="box-info-card">
            <div class="content">
              <label>Semana:</label>
              {{ item.diaSemana }}
            </div>
            <div class="content">
              <label>Data:</label>
              <input
                class="input-date"
                type="date"
                @input="checkDayOfWeek($event, item)"
                v-model="item.dtAgendamento"
              />
            </div>
            <div class="content">
              <label>Horário:</label>
              {{ item.horaAgendamento }}
            </div>
            <div class="content">
              <label>Gerar Receita:</label>
              <b-form-checkbox
                id="checkbox-genaration2"
                v-model="item.geraReceita"
                name="checkbox-genaration2"
                :value="1"
                :unchecked-value="0"
              >
              </b-form-checkbox>
            </div>
            <button @click="removeItem(index)" class="button-remove">
              Remover
            </button>
          </div>
        </div>
      </div>
      <div v-else class="table-container">
        <b-table-simple hover>
          <b-thead>
            <b-tr>
              <b-th class="table-header"> Semana </b-th>
              <b-th class="table-header"> Data </b-th>
              <b-th class="table-header"> Horário </b-th>
              <b-th class="table-header"> Gerar Receita</b-th>
              <b-th class="table-header"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="dataList.length !== 0">
            <b-tr v-for="(item, index) in dataList" :key="index">
              <b-td> {{ item.diaSemana }}</b-td>

              <b-td>
                <input
                  class="input-date"
                  type="date"
                  @input="checkDayOfWeek($event, item)"
                  v-model="item.dtAgendamento"
                />
              </b-td>

              <b-td> {{ item.horaAgendamento }}</b-td>

              <b-td>
                <b-form-checkbox
                  id="checkbox-genaration1"
                  v-model="item.geraReceita"
                  name="checkbox-genaration1"
                  :value="1"
                  :unchecked-value="0"
                >
                </b-form-checkbox
              ></b-td>

              <b-td>
                <button @click="removeItem(index)" class="button-remove">
                  Remover
                </button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div style="display: flex; width: 100%; justify-content: flex-end">
        <button
          :disabled="dataList.length === 0 ? true : false"
          @click="getExecutedRecorrencia()"
          class="button-rec"
        >
          Executar alteração
          <span>
            <b-spinner
              v-show="loading === true"
              small
              variant="ligth"
            ></b-spinner>
          </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment'
import API from '../../services/index'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['dataReconrrecia'],
  data() {
    return {
      generation: 0,
      dataList: [],
      dataInit: this.firstDayOfMonth(),
      dataEnd: this.getFinalyofMonth(),
      daysOfPeriod: null,
      loading: false
    }
  },
  mounted() {
    this.$bvModal.show('modalRecorrencia')
    window.addEventListener('keydown', this.handleKeyPress)
  },
  watch: {
    dataInit(newValue) {
      this.setFinalyofMonth(newValue)
    },
    generation(newValue) {
      if (newValue === 1) {
        this.dataList.forEach((item) => {
          item.geraReceita = 1
        })
      } else {
        this.dataList.forEach((item) => {
          item.geraReceita = 0
        })
      }
    }
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({}),
    firstDayOfMonth() {
      const date = new Date()
      return moment(date).format('YYYY-MM-DD')
    },
    getFinalyofMonth() {
      return moment(this.dataInit).add(1, 'month').format('YYYY-MM-DD')
    },
    setFinalyofMonth(date) {
      this.dataEnd = moment(date).add(1, 'month').format('YYYY-MM-DD')
    },
    formartDateInclude(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    close() {
      this.$emit('closeModal')
    },
    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    isMobile() {
      const isMobileUserAgent =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      if (isMobileUserAgent) {
        return true
      } else {
        return false
      }
    },
    checkDayOfWeek(event, item) {
      const dayOfWeek = moment(event.target.value).format('dddd')
      item.diaSemana = this.translateDayOfWeek(dayOfWeek)
    },
    translateDayOfWeek(dayOfWeek) {
      switch (dayOfWeek) {
        case 'Monday':
          return 'SEGUNDA-FEIRA'
        case 'Tuesday':
          return 'TERÇA-FEIRA'
        case 'Wednesday':
          return 'QUARTA-FEIRA'
        case 'Thursday':
          return 'QUINTA-FEIRA'
        case 'Friday':
          return 'SEXTA-FEIRA'
        case 'Saturday':
          return 'SÁBADO'
        case 'Sunday':
          return 'DOMINGO'
        default:
          return ''
      }
    },
    async getData() {
      try {
        const response = await API.get(
          '/api/Agenda/ObtemVisualizacaoRecorrencia',
          {
            params: {
              id_agenda_origem: this.dataReconrrecia.schedule.id,
              dt_inicio: moment(this.dataInit).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dt_final: moment(this.dataEnd).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
              ),
              dias_recorrencia: this.daysOfPeriod
            }
          }
        )
        this.dataList = response.data.object
        this.dataList.forEach((item) => {
          item.horaAgendamento = this.formatHr(item.dtAgendamento)
        })
        this.dataList.forEach((item) => {
          item.dtAgendamento = this.formatData(item.dtAgendamento)
        })
        if (this.generation === 1) {
          this.dataList.forEach((item) => {
            item.geraReceita = 1
          })
        }
      } catch (err) {
        this.$toast.error('Desculpe, tente atualizar em alguns minutos!!')
      }
    },
    async getExecutedRecorrencia() {
      this.loading = true
      this.dataList.forEach((item) => {
        item.dtAgendamento = moment.utc(
          `${item.dtAgendamento} ${item.horaAgendamento}`,
          'YYYY-MM-DD HH:mm'
        )
      })
      try {
        await API.post('/api/Agenda/ExexuctarRecorrenciaAsync', {
          appName: 'DoctorPep',
          appVersion: '1.10.12',
          object: [...this.dataList]
        })
        this.loading = false
        this.close()
        this.$toast.success(
          'Seus agendamentos recorrêntes foram salvos com sucesso'
        )
      } catch (err) {
        this.loading = false
        this.close()
        this.$toast.error('Desculpe, tente executar em alguns minutos!!')
      }
    },
    formatData(data) {
      return moment(data).format('YYYY-MM-DD')
    },
    formatHr(data) {
      return moment(data).format('HH:mm')
    },
    removeItem(index) {
      this.dataList.splice(index, 1)
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress)
  }
}
</script>
<style scoped>
.container-rec {
  padding: 6px;
  width: 100%;
  color: #181818;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.container-rec h1 {
  font-size: 1rem;
  font-weight: 700;
  color: #9b9a9a;
}

.container-rec h2 {
  font-size: 0.9rem;
  font-weight: 700;
  color: #9b9a9a;
}

.data-origem {
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 36px;
  padding-bottom: 12px;
  border-bottom: solid 1px #9b9a9a;
}

.box-period {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.box-period button {
  margin-top: 21px;
  font-size: 0.9rem;
  padding: 6px 12px;
  color: white;
  background-color: #089bab;
  border-radius: 10px;
  border: none;
}

.box-date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.standard {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-date {
  padding: 6px 12px;
  border-radius: 10px;
  border: 1px solid #9b9a9a;
}

.standard input {
  width: 150px;
  padding: 6px 12px;
  border-radius: 10px;
  border: 1px solid #9b9a9a;
}

.table-container {
  width: 100%;
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

td {
  font-size: 0.8rem;
}

.table-header:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

th {
  width: 100% !important;
  font-weight: 400 !important;
  background-color: #089bab;
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

th:nth-child(1) {
  width: 20%;
  white-space: nowrap;
}

th:nth-child(2) {
  width: 20%;
}

th:nth-child(3) {
  width: auto;
}

th:nth-child(4) {
  width: auto;
}

th:nth-child(5) {
  justify-content: end;
  width: 15%;
}

.button-rec {
  display: flex;
  gap: 4px;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 700;
  align-items: center;
  padding: 6px 12px;
  color: white;
  background-color: rgb(5, 182, 5);
  border-radius: 10px;
  border: none;
}
.button-remove {
  font-size: 0.8rem;
  padding: 6px 12px;
  color: red;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid red;
}

.box-list-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.list-card {
  padding: 10px;
  background-color: #fcfeff;
  border: 1px solid rgb(184, 180, 180);
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}
.box-info-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
</style>
