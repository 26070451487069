<template>
  <div class="module">
    <div class="details">
      <p
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </p>
      <div class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactivesConducts"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>
      <b-form-input type="date" class="details__date" v-model="date" />

      <b-form-input type="time" class="details__time" v-model="time" />

      <favorites-select
        :favorites="
          componentInfo.favorites.length &&
          componentInfo.favorites.filter((favorite) => favorite.favorito === 0)
        "
        :with-sub-list="false"
        title-property="descTitulo"
        description-property="descConduta"
        @on-favorite-chosen="addQuickAccessOrFavoriteToContent"
      />

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>

    <quick-access
      v-if="hasQuickAccesses"
      :quick-accesses="
        componentInfo.favorites.length &&
        componentInfo.favorites.filter(
          (favorite) => favorite.acessoRapido === 0
        )
      "
      :with-sub-list="false"
      title-property="descTitulo"
      @on-quick-access-chosen="addQuickAccessOrFavoriteToContent"
    />
    <div class="content" v-if="componentInfo.aberto === 0">
      <clinic-report
        :initial-content="content"
        :content-to-add="contentToAdd"
        @update-content="updateContent"
        :id="`${componentInfo.id}`"
      />

      <time-line
        :data="dataToDisplay"
        :withColors="false"
        descProperty="descConduta"
        :idUser="idMedico"
        :disableFunction="editConduct"
      />
    </div>
  </div>
</template>

<script>
import QuickAccess from '../../shared/QuickAccess.vue'
import FavoritesSelect from '../../shared/FavoritesSelect.vue'
import TimeLine from '../../shared/TimeLine'
import ClinicReport from '../../shared/ClinicReport.vue'
import {
  formatDateHour,
  formatDate,
  formatCurrentTime
} from '@/Utils/formatters'
import { mapGetters } from 'vuex'

export default {
  name: 'Conduct',
  components: { QuickAccess, FavoritesSelect, TimeLine, ClinicReport },
  props: ['componentInfo', 'idProntuario', 'idPaciente', 'idMedico'],
  mounted() {
    const conduct = this.componentInfo.data.find(
      (conduct) => conduct.idProntuario === this.idProntuario
    )
    if (conduct) {
      this.isEdditing = true
      this.content = conduct.descConduta
      this.date = formatDateHour(conduct.dtInclusao)
      this.contentToAdd = conduct.descConduta
      this.$nextTick(() => {
        this.contentToAdd = ''
      })
    }
  },
  data() {
    return {
      showInactivesConducts: false,
      isEdditing: false,
      content: '',
      contentToAdd: '',
      efficientUpdateContentInVuex: this.debounce(
        this.updateContentInVuex,
        200
      ),

      date: formatDate('', 'en-CA'),
      time: formatCurrentTime()
    }
  },

  computed: {
    ...mapGetters({
      user: 'Setting/user',
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    hasQuickAccesses() {
      return this.componentInfo.favorites.some(
        (favorite) => favorite.acessoRapido === 0
      )
    },
    dataToDisplay() {
      const items = this.showInactivesConducts
        ? this.componentInfo.data
        : this.filterInactives()

      const data = this.isEdditing
        ? items
        : [
            {
              dtOcorrencia: this.date + 'T' + this.time,
              date: this.date,
              descConduta: this.content,
              nmProfissional: this.user.nome
            },
            ...items
          ]

      return data
    }
  },

  methods: {
    filterInactives() {
      const filtered = [...this.componentInfo.data].filter((element) => {
        return element.ativo !== 1
      })

      return filtered
    },
    editConduct(data) {
      this.$store.commit({
        type: 'MedicalReports/editConduct',
        payload: data
      })
    },
    debounce(func, wait) {
      let timeout = null

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(func, wait)
      }
    },

    updateContentInVuex() {
      if (this.isEdditing) {
        this.$store.commit({
          type: 'MedicalReports/editConduct',
          payload: {
            descConduta: this.content,
            idProntuario: this.idProntuario,
            dtInclusao: formatDateHour(),
            dtOcorrencia: this.date + 'T' + this.time
          }
        })
      } else {
        this.$store.commit({
          type: 'MedicalReports/addConduct',
          payload: {
            descConduta: this.content,
            idProntuario: this.idProntuario,
            dtInclusao: formatDateHour(),
            dtOcorrencia: this.date + 'T' + this.time
          }
        })
      }
    },

    updateContent(conductData) {
      this.content = conductData
      this.efficientUpdateContentInVuex()
    },

    addQuickAccessOrFavoriteToContent(data) {
      this.contentToAdd = `<div>${data.descConduta}</div>`
      this.content += `<div>${data.descConduta}</div>`

      this.efficientUpdateContentInVuex()

      this.$nextTick(() => {
        this.contentToAdd = ''
      })
    },

    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';
.font-large {
  font-size: 14px;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.font-medium {
  font-size: 13px;
}
.module {
  margin: 32px 0;

  &__icon {
    align-self: center;
  }
}

.custom-select {
  max-width: 25%;
}

.details {
  display: flex;

  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__date {
    background-color: #fff !important;
    display: block;
    width: 135px !important;
    margin: 0 8px;
    line-height: 1 !important;
    height: auto !important;
    font-family: sans-serif;
  }

  &__time {
    background-color: #fff !important;
    display: block;
    text-align: center;
    width: 100px !important;
    margin: 0 8px;
    line-height: 1 !important;
    height: auto !important;
    margin-right: auto;
    font-family: sans-serif;
  }

  &__checkbox-container {
    margin-left: 4px;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}
</style>
