<template>
  <div class="container-feed">
    <div class="box-feed">
      <header class="header-feed">
        <img
          class="logo"
          src="../../assets/images/logo-black-white.png"
          alt="Logo doctorPep branca"
        />
      </header>
      <div v-if="isSucess === false">
        <article v-if="data !== null" class="article-feed">
          <p>Olá {{ data === null ? '' : data.pacienteNome }}</p>
          <p>
            Recentemente você fez uma consulta com Dr(a)
            {{ data === null ? '' : data.usuarioNome }}
          </p>
          <span class="declaretion">
            Avalie nosso atendimento, sua opinião é muito importante para nós!
          </span>
        </article>
        <div v-if="data !== null" class="box-questions">
          <div
            v-for="item in data.respostas"
            :key="item.id"
            class="container-question"
          >
            <label>{{ data === null ? '' : item.descQuestao }}?</label>
            <div class="box-buttons-emotis">
              <button
                :class="item.resposta === 3 ? 'btn-icon-active' : 'btn-icon'"
                @click="handleValueFeedBack(item, 3)"
              >
                <img
                  src="../../assets/images/feliz.png"
                  alt="Icone de satisfeito"
                  class="emoti"
                />
              </button>
              <button
                :class="item.resposta === 2 ? 'btn-icon-active' : 'btn-icon'"
                @click="handleValueFeedBack(item, 2)"
              >
                <img
                  src="../../assets/images/confuso.png"
                  alt="icone de regular"
                  class="emoti"
                />
              </button>
              <button
                :class="item.resposta === 1 ? 'btn-icon-active' : 'btn-icon'"
                @click="handleValueFeedBack(item, 1)"
              >
                <img
                  src="../../assets/images/bravo.png"
                  alt="icone de insatisfeito"
                  class="emoti"
                />
              </button>
            </div>
          </div>

          <button @click="saveFeedBack()" class="button-feed">
            Confirmar
            <b-spinner
              v-show="loading === true"
              small
              variant="ligth"
            ></b-spinner>
          </button>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 30px;
          align-items: center;
          justify-content: center;
          height: 400px;
        "
        v-else
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
          "
        >
          <img
            src="../../assets/images/legal.png"
            alt="Polegar indicando positividade"
            width="200px"
          />
          <p
            style="font-size: 1rem; font-weight: 600; padding: 0px; margin: 0px"
          >
            Suas respostas foram enviadas com sucesso!
          </p>
        </div>
        <span style="font-size: 0.9rem"
          >A clinica {{ data === null ? '' : data.descClinica }} agradece sua
          participação!</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import API from '../../services/index'
export default {
  name: 'feedbackPaciente',
  props: ['id'],
  data() {
    return {
      isSucess: false,
      data: null,
      loading: false,
      valueFeed1: null,
      valueFeed2: null,
      valueFeed3: null
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleValueFeedBack(item, value) {
      item.resposta = value
    },
    getDescQUestion(value) {
      if (value === 1) {
        return 'RUIM'
      }
      if (value === 2) {
        return 'BOM'
      }
      if (value === 3) {
        return 'OTIMO'
      }
    },
    async getData() {
      try {
        const response = await API.get(
          `/api/FeedbackAtendimento/ObtemDadosFeedPorIdentificacao?identificacao=${this.id}`
        )
        const list = response.data.object
        list.feed_Questao.forEach((element) => {
          element.idFeedbackQuestao = element.id
          element.descQuestao = element.questaoFeedAtendimento
          element.resposta = null
          element.descResp = ''

          delete element.id
          delete element.questaoFeedAtendimento
          delete element.ativo
        })
        list.respostas = list.feed_Questao
        delete list.feed_Questao
        this.data = list
      } catch (err) {
        this.$toast.error(
          'Não foi possivel encontrar os dados do atendimento!!'
        )
      }
    },
    async saveFeedBack() {
      this.loading = true
      // eslint-disable-next-line prefer-const
      let isValid = true
      this.data.respostas.forEach((element) => {
        if (element.resposta === null) {
          isValid = false
        }
      })
      if (isValid === false) {
        this.loading = false
        this.$toast.error('Por favor, responda todas as perguntas!')
      } else {
        this.data.respostas.forEach((element) => {
          element.descResp = this.getDescQUestion(element.resposta)
        })
        try {
          await API.post('/api/FeedbackAtendimento/Adicionar', {
            appName: 'DoctorPep',
            appVersion: '10.3.9',
            object: {
              idAgenda: this.data.id,
              idUsuario: this.data.idUsuario,
              idPaciente: this.data.idPaciente,
              dtInclusao: new Date(),
              respostas: [...this.data.respostas]
            }
          })
          this.loading = false
          this.isSucess = true
        } catch (err) {
          this.loading = false
          this.$toast.error(
            'Não foi possivel salvar seu feedback, tente novamente em alguns minutos!!'
          )
        }
      }
    }
  }
}
</script>
<style scoped>
.container-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
.box-feed {
  padding: 10px;
  background-color: #fff;
  display: flex;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  gap: 28px;
  align-items: center;
  border: solid 1px #089dae;
}

.header-feed {
  width: 100%;
  background-color: #089dae;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.logo {
  width: 200px;
}

.article-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.article-feed p {
  text-align: center;
  margin: 0px;
  padding: 0px;
  font-size: 1rem;
  font-weight: 600;
  color: #000;
}

.declaretion {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #7e7d7d;
}

.box-questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.container-question {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

label {
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
}

.box-buttons-emotis {
  display: flex;
  gap: 10px;
  align-items: center;
}
.button-feed {
  margin-bottom: 20px;
  display: flex;
  gap: 6px;
  font-size: 1rem;
  font-weight: bolder;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #089cac;
  color: #fff;
  padding: 10px 12px;
}
.box-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-icon-active {
  background-color: transparent;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #089cac;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  padding: 10px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

@media (max-width: 876px) {
  .box-feed {
    width: 70%;
  }
  .article-feed p {
    font-size: 0.8rem;
  }

  .declaretion {
    font-size: 0.7rem;
  }

  label {
    font-size: 0.7rem;
  }
  .logo {
    width: 160px;
  }
  .emoti {
    width: 50px;
  }
}
@media (max-width: 420px) {
  .box-feed {
    width: 80%;
  }
  .article-feed p {
    font-size: 0.7rem;
  }

  .declaretion {
    font-size: 0.6rem;
  }

  label {
    font-size: 0.6rem;
  }
  .logo {
    width: 150px;
  }
  .emoti {
    width: 40px;
  }
  .button-feed {
    font-size: 0.7rem;
    padding: 6px 12px;
  }
}
</style>
