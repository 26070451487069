<template>
  <div class="module">
    <b-tabs
      card
      pills
      active-nav-item-class="bg-primary text-light"
      nav-class="align-items-center p-0"
    >
      <template #tabs-end>
        <b-button
          v-if="isInitial === false"
          style="margin-left: auto"
          @click="toogleDecision(1)"
          variant="success"
          >{{ 'Nova gestação' }}</b-button
        >
        <b-button
          v-else
          style="margin-left: auto"
          @click="toogleDecision(0)"
          variant="danger"
          >{{ 'Encerrar gestação' }}</b-button
        >
      </template>
      <b-tab>
        <template #title>
          <span>Folha rosto</span>
        </template>
        <cover-sheet
          :data="folhaRosto"
          :idPaciente="idPaciente"
          :idMedico="idMedico"
          :initialPregnancy="isInitial"
          :showClosure="showClosure"
          @closeModal="closeModal"
          :isInitial="isInitial"
        />
      </b-tab>

      <b-tab v-if="folhaRosto.id">
        <template #title>
          <span>Registro da consulta</span>
        </template>
        <results
          v-if="folhaRosto.id && results"
          :altura="folhaRosto.alturaGestante"
          :results="results"
          :idProntuario="idProntuario"
          :idPreNatal="folhaRosto.id"
          :dateDum="folhaRosto.dateDum"
        />
      </b-tab>
      <b-tab v-if="folhaRosto.id">
        <template #title>
          <span>Vacinas</span>
        </template>
        <vaccines
          v-if="folhaRosto.id"
          :idProntuario="idProntuario"
          :idPreNatal="folhaRosto.id"
        />
      </b-tab>
      <b-tab v-if="folhaRosto.id">
        <template #title>
          <span>Ultrassom</span>
        </template>
        <ultrassom
          v-if="folhaRosto.id"
          :idProntuario="idProntuario"
          :idPreNatal="folhaRosto.id"
        />
      </b-tab>
      <b-tab v-if="folhaRosto.id">
        <template #title>
          <span>Laboratoriais</span>
        </template>
        <laboratory-tests
          v-if="folhaRosto.id"
          :idProntuario="idProntuario"
          :idPreNatal="folhaRosto.id"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CoverSheet from './components/folhaARosto/coverSheet.vue'
import Results from './components/results'
import Ultrassom from './components/ultrassom/Ultrassom.vue'
import Vaccines from './components/vaccine/ComponetVaccines.vue'
import LaboratoryTests from './components/laboratoryTests/LaboratoryTests.vue'
export default {
  name: 'Pregnant',
  components: {
    CoverSheet,
    Results,
    Vaccines,
    Ultrassom,
    LaboratoryTests
  },
  props: [
    'patientInfo',
    'componentInfo',
    'handbookInfo',
    'idProntuario',
    'idPaciente',
    'idMedico'
  ],
  data() {
    return {
      results: '',
      isInitial: false,
      isAcess: true,
      showClosure: false
    }
  },
  async mounted() {
    await this.getCoverSheet({
      id_paciente: this.idPaciente,
      id_usuario: this.idMedico
    })
    this.setValueInIsInitial()
    this.handleGetPreNatalConsults()
  },
  destroyed() {
    this.clearResults()
  },
  computed: {
    ...mapGetters({
      localCoverSheet: 'MedicalReports/getLocalCoverSheet'
    }),
    folhaRosto() {
      return this.localCoverSheet
    }
  },
  watch: {
    folhaRosto() {
      this.setValueInIsInitial()
    }
  },
  methods: {
    ...mapMutations({
      clearResults: 'MedicalReports/clearResults'
    }),
    ...mapActions({
      getCoverSheet: 'MedicalReports/getCoverSheetAction',
      getPreNatalConsults: 'MedicalReports/getPreNatalConsults'
    }),
    openModal() {
      this.showClosure = true
    },
    closeModal() {
      this.showClosure = false
    },
    async handleGetPreNatalConsults() {
      try {
        const response = await this.getPreNatalConsults(this.folhaRosto.id)

        const temp = [...response].map((element) => {
          return {
            ...element,
            dtOcorrencia: element.dtOcorrencia.substring(0, 10)
          }
        })

        this.results = temp
      } catch {
        this.results = []
      }
    },
    setValueInIsInitial() {
      if (this.folhaRosto !== '' && this.folhaRosto.id) {
        this.isInitial = true
      } else if (this.folhaRosto === '' && !this.folhaRosto.id) {
        this.isInitial = false
      } else {
        this.isInitial = false
      }
    },
    toogleDecision(value) {
      if (value === 0) {
        this.openModal()
      } else {
        this.isInitial = !this.isInitial
        this.isAcess = !this.isAcess
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../shared/generalModuleStyles.scss';
</style>
