export default {
  changeNotifications(state, data) {
    state.notifications = data
  },

  concludeNotification(state, data) {
    state.notifications.map((element) => {
      if (element.idNotificacao === data.id_notificacao) {
        element.visualizacaoNotificacao === 1
          ? (element.visualizacaoNotificacao = 0)
          : (element.visualizacaoNotificacao = 1)
      }
    })
  }
}
