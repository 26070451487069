<template>
  <div class="search-result__container" v-if="results.length >= 1">
    <table class="table" v-if="results.length >= 1">
      <thead class="header">
        <tr>
          <th>Formulário</th>
          <th class="checkbox">Acesso rápido</th>
          <th class="checkbox">Favoritos</th>
        </tr>
      </thead>
      <tbody class="body">
        <tr v-for="result in results" :key="result.idFormulario">
          <td @click="onResultSelected(result)" class="cell">
            {{ result.descFormulario }}
          </td>
          <td
            class="checkbox"
            @click.prevent="(event) => onQuickAccessChosen(result)"
          >
            <b-form-radio
              v-model="result.location"
              :name="`favorite_location--${result.idFormulario}`"
              value="quickAccess"
            />
          </td>
          <td
            class="checkbox"
            @click.prevent="(event) => onFavoriteChosen(result)"
          >
            <b-form-radio
              value="favorite"
              v-model="result.location"
              :name="`favorite_location--${result.idFormulario}`"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    results: Array,
    userId: Number
  },
  data() {
    return {
      items: []
    }
  },
  watch: {
    results(newResults) {
      this.items = newResults.map((result) => {
        result.location =
          result.acessoRapido === 0
            ? 'quickAccess'
            : result.favorito === 0
            ? 'favorite'
            : ''
        return result
      })
    }
  },
  methods: {
    onFavoriteChosen(result) {
      this.$emit('on-favorite-chosen', result)
      this.updatePreferences()
    },
    onQuickAccessChosen(result) {
      this.$emit('on-quick-access-chosen', result)
      this.updatePreferences()
    },
    onResultSelected(result) {
      this.$emit('on-result-selected', result)
    },
    updatePreferences() {
      this.$store
        .dispatch({
          type: 'MedicalReports/loadAllModules',
          payload: this.userId
        })
        .then(async () => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result {
  &__container {
    position: absolute;
    z-index: 13;
    background-color: #fff;
    right: 0;
    border: 1px solid #000;
    border-radius: 15px;
    max-height: 300px;
    overflow-y: scroll;
    width: 150%;
  }
}

.header {
  background-color: #089bab;
  color: #fff;
  font-size: 13px;
  align-items: center;
  top: 0;
  z-index: 3;
  width: 100%;
  border-spacing: 0 50px;
}

.body {
  padding: 10px 4px;
  margin: 0;
  color: #000;
}

.checkbox {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cell {
  cursor: pointer;
}
</style>
