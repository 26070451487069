const getters = {
  getPhysicalExamByIdPatient: (state) => (id) => {
    const physicalExam = state.localPhysicalExamsState.find(
      (physicalExam) => physicalExam.idPatient === id
    )

    return physicalExam
  },

  getPhysicalExamByIdHandBook: (state) => (id) => {
    const physicalExam = state.localPhysicalExamsState.find(
      (physicalExam) => physicalExam.idProntuario === id
    )

    return physicalExam
  }
}

export default getters
