export const mapModuleToComponent = (modules) => {
  return modules.map((module) => {
    switch (module.idModulo) {
      case 1:
        return {
          ...module,
          component: 'problems',
          userPreferenceComponent: 'problems-user-preference'
        }

      case 2:
        return {
          ...module,
          component: 'allergy',
          userPreferenceComponent: 'allergy-user-preference'
        }

      case 3:
        return {
          ...module,
          component: 'exams-history',
          userPreferenceComponent: 'exams-history-user-preference'
        }

      case 4: {
        return {
          ...module,
          component: 'personal-and-familiar-history',
          userPreferenceComponent:
            'personal-and-familiar-history-user-preference'
        }
      }

      case 5: {
        return {
          ...module,
          component: 'complaints',
          userPreferenceComponent: 'complaints-user-preference'
        }
      }
      case 6: {
        return {
          ...module,
          component: 'physical-exam',
          userPreferenceComponent: 'physical-exam-user-preference'
        }
      }
      case 7: {
        return {
          ...module,
          component: 'vital-signs',
          userPreferenceComponent: 'vital-signs-user-preference'
        }
      }
      case 8: {
        return {
          ...module,
          component: 'conduct',
          userPreferenceComponent: 'conduct-user-preference'
        }
      }

      case 9: {
        return {
          ...module,
          component: 'prescriptions',
          userPreferenceComponent: 'memed-integration-user-preference'
        }
      }
      case 11: {
        return {
          ...module,
          component: 'files-and-images',
          userPreferenceComponent: 'files-and-images-user-preference'
        }
      }
      case 12: {
        return {
          ...module,
          component: 'forms',
          userPreferenceComponent: 'forms-user-preference'
        }
      }
      case 13: {
        return {
          ...module,
          component: 'forwardings',
          userPreferenceComponent: 'forwardings-user-preference'
        }
      }
      case 14: {
        return {
          ...module,
          component: 'ASO',
          userPreferenceComponent: 'aso-user-preference'
        }
      }
      case 15: {
        return {
          ...module,
          component: 'pregnant',
          userPreferenceComponent: 'pregnant-user-preference'
        }
      }
      case 16: {
        return {
          ...module,
          component: 'tracking',
          userPreferenceComponent: 'tracking-user-preference'
        }
      }
    }

    return module
  })
}
