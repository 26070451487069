<template>
  <div>
    <div class="module__wrapper">
      <div class="module__options">
        <b-icon
          font-scale="2"
          class="module__options-icon"
          icon="grid"
          variant="primary"
        ></b-icon>
        <div class="module__info">
          <input
            :class="
              isEditingModuleName
                ? 'module__info-title__editing'
                : 'module__info-title'
            "
            v-model="moduleName"
            :readonly="isEditingModuleName ? false : true"
            maxlength="36"
          />
          <p class="module__info-subtitle">
            Seção {{ module.sequencia + 1 }} de {{ modulesLength }}
          </p>
        </div>
      </div>

      <b-icon
        @click="isEditingModuleName = !isEditingModuleName"
        :icon="this.isEditingModuleName ? 'check2-square' : 'pencil-square'"
        :variant="this.isEditingModuleName ? 'success' : 'primary'"
        font-scale="1.5"
        class="modal-info__edit"
      ></b-icon>

      <div class="module__icons">
        <b-icon
          @click="swapUp(module)"
          icon="triangle-fill"
          flip-v
          variant="primary"
          font-scale="1.3"
          class="mr-4 ml-1"
        ></b-icon>

        <b-icon
          icon="triangle-fill"
          variant="primary"
          font-scale="1.3"
          @click="swapDown(module)"
        ></b-icon>
      </div>

      <div
        class="modal-info__active-checkbox"
        @click.prevent="toggleExpanded(module)"
      >
        <b-form-checkbox switch :checked="module.aberto === 0" />
      </div>
      <div
        class="modal-info__open-checkbox"
        @click.prevent="toggleActive(module)"
      >
        <b-form-checkbox switch :checked="module.ativo === 0" />
      </div>

      <div class="modal-info__pallete">
        <input
          type="color"
          v-model="module.corHexadecimal"
          @change="changeModuleBackground(module, $event)"
        />
      </div>

      <div @click="toggleExpandModule()">
        <b-icon v-if="expanded" icon="chevron-up" font-scale="2"></b-icon>
        <b-icon v-else icon="chevron-down" font-scale="2"></b-icon>
      </div>
    </div>

    <template v-if="expanded">
      <div class="search">
        <p class="search__warning">
          Cadastre os seus favoritos ou itens de acesso rápido
        </p>
        <div style="position: relative"></div>
      </div>
      <b-container fluid>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form id="form" @submit.prevent="handleSubmit(saveForwarding)">
            <b-row>
              <b-form-group class="col-md-3" label="Título:" label-for="title">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="title"
                >
                  <b-form-input
                    id="title"
                    v-model="forwarding.descTitulo"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />

                  <div class="invalid-feedback">
                    <span>Título obrigatório</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                class="col-md-3"
                label="Tipo de encaminhamento:"
                label-for="forwardingType"
              >
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="forwardingType"
                >
                  <v-select
                    v-model="forwarding.idTipoEncaminhamento"
                    id="forwardingType"
                    :clearable="false"
                    :options="forwardingTypes"
                    :reduce="(type) => type.id"
                    label="descTipoEncaminhamento"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />

                  <div class="invalid-feedback">
                    <span>Título obrigatório</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                class="col-md-3"
                label="Especialidade:"
                label-for="specialty"
              >
                <v-select
                  id="specialty"
                  :options="specialties"
                  :clearable="false"
                  label="descCbo"
                  :reduce="(specialty) => specialty.id"
                  v-model="forwarding.idEspecialidade"
                />
              </b-form-group>

              <b-form-group
                class="col-md-3"
                label="Profissional:"
                label-for="professional"
              >
                <v-select
                  id="professional"
                  :options="medics"
                  label="nmUsuario"
                  :reduce="(medic) => medic.idUsuario"
                  v-model="forwarding.idProfissional"
                />
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group
                class="col-md-12"
                label="História Clínica:"
                label-for="clinic-history"
              >
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="clinic-history"
                >
                  <b-form-textarea
                    id="clinic-history"
                    v-model="forwarding.descHistoricoClinico"
                    type="text"
                    maxLength="4000"
                    rows="3"
                    max-rows="6"
                    style="line-height: 24px"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />
                  <p>{{ forwarding.descHistoricoClinico.length }} / 4000</p>

                  <div class="invalid-feedback">
                    <span>História Clinica obrigatória</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group
                class="col-md-3"
                label="Hipóteses diagnósticas:"
                label-for="hypotheses"
              >
                <b-form-input
                  id="hypotheses"
                  v-model="filter"
                  @keyup="searchOpened = true"
                />

                <search-result
                  :results="cidAndDescriptionFilter"
                  @on-result-selected="resultSelected"
                  v-if="searchOpened"
                />

                <div
                  @click="searchOpened = false"
                  v-if="searchOpened"
                  style="
                    width: 100vw;
                    height: 100vh;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 12;
                  "
                ></div>
              </b-form-group>

              <b-form-group
                class="col-md-3"
                label="Prioridade:"
                label-for="priority"
              >
                <v-select
                  id="priority"
                  :clearable="false"
                  v-model="forwarding.idEncaminhamentoPrioridade"
                  :options="priorities"
                  :reduce="(item) => item.id"
                  label="descPrioridadeEncaminhamento"
                ></v-select>
              </b-form-group>

              <b-col class="d-flex align-items-center">
                <b-form-checkbox
                  name="notify"
                  :value="0"
                  :unchecked-value="1"
                  v-model="forwarding.notificarRecepcao"
                >
                </b-form-checkbox>

                <span>Notificar secretária para agendamento</span>
              </b-col>

              <div style="display: flex; align-items: center" class="col-md-2">
                <b-button variant="primary" type="submit">
                  {{ forwarding.id ? 'Salvar' : 'Adicionar' }}
                </b-button>
              </div>
            </b-row>
          </form>
        </ValidationObserver>
      </b-container>
      <div class="table">
        <b-container fluid>
          <b-table-simple hover>
            <b-thead head-variant="primary">
              <b-tr class="table-header">
                <b-th>Título</b-th>
                <b-th>Tipo de encaminhamento</b-th>
                <b-th>Favorito</b-th>
                <b-th>Acesso Rápido</b-th>
                <b-th>Ação</b-th>
              </b-tr>
            </b-thead>

            <b-tbody class="table__body">
              <b-tr
                :key="favorite.idFormulario"
                v-for="favorite in module.favorites"
                @click="selectForwarding(favorite)"
              >
                <b-td>{{ favorite.descTitulo }}</b-td>

                <b-td>{{
                  getForwardingTypeById(favorite.idTipoEncaminhamento)
                }}</b-td>

                <b-td @click.prevent="addForwardingToFavorites(favorite)">
                  <b-form-radio
                    value="favorite"
                    v-model="favorite.location"
                  ></b-form-radio>
                </b-td>

                <b-td @click.prevent="addForwardingToQuickAccess(favorite)">
                  <b-form-radio
                    value="quickAccess"
                    v-model="favorite.location"
                  ></b-form-radio>
                </b-td>

                <b-td>
                  <b-button
                    @click="removeForwardingFavoriteFromHandBook(favorite)"
                    variant="danger"
                    >Remover</b-button
                  >
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
import moduleMethods from '../../shared/modulesMethods'
import SearchResult from './SearchResults.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ForwardingsUserPreference',
  props: ['module', 'modulesLength'],
  components: {
    SearchResult
  },
  data() {
    return {
      forwardingTypes: [],
      specialties: [],
      medics: [],
      cids: [],
      filter: '',
      searchOpened: false,
      priorities: [],
      isEditingModuleName: false,
      moduleName: this.module.descMeuModulo,
      expanded: false,
      forwarding: {
        descTitulo: '',
        idProntuarioPreferencia: '',
        idEspecialidade: '',
        idTipoEncaminhamento: '',
        idProfissional: '',
        favorito: 1,
        acessoRapido: 1,
        idCid: '',
        idEncaminhamentoPrioridade: '',
        descHistoricoClinico: '',
        notificarRecepcao: 0
      }
    }
  },

  async mounted() {
    const forwardingTypes = await this.getForwardingtTypes()
    const specialties = await this.getSpecialties()
    const priorities = await this.getPriorities()
    const cids = await this.getCids()

    this.cids = cids
    this.specialties = specialties
    this.forwardingTypes = forwardingTypes
    this.priorities = priorities
  },

  destroyed() {
    if (this.moduleName !== this.module.descMeuModulo) {
      this.changeModuleName(this.module, this.moduleName)
    }
  },

  watch: {
    'forwarding.idEspecialidade': async function (newValue) {
      if (newValue !== '') {
        const medics = await this.getMedicsBySpecialty({
          idCbo: newValue,
          idClinic: this.user.clinica_usuario[0].idClinica
        })

        if (medics.length === 0) {
          this.$toast.info(
            'Não há profissionais com a especialidade selecionada'
          )

          return 0
        }

        this.medics = medics
      }
    }
  },

  computed: {
    ...mapGetters({ user: 'Setting/user' }),
    cidAndDescriptionFilter() {
      if (this.filter) {
        let cidsCount = 0
        let descriptionsCount = 0

        const cidsAndDescriptionsFiltered = []
        this.cids.every((element) => {
          if (cidsCount < 10) {
            const cod = element.codCid.toLowerCase()
            const contain = cod.includes(this.filter.toLowerCase())

            if (contain) {
              cidsCount++
              cidsAndDescriptionsFiltered.push(element)
            }

            return true
          } else {
            return false
          }
        })

        this.cids.every((element) => {
          if (descriptionsCount < 10) {
            const desc = element.descCid.toLowerCase()
            const contain = desc.includes(this.filter.toLowerCase())

            if (contain) {
              descriptionsCount++
              cidsAndDescriptionsFiltered.push(element)
            }

            return true
          } else {
            return false
          }
        })

        return cidsAndDescriptionsFiltered
      } else {
        return []
      }
    },
    cidDescription() {
      const id = this.forwarding.idCid

      const cid = this.cids.find((element) => {
        return element.id === id
      })

      return cid.descCid
    },
    amountOfFavorites() {
      return this.module.favorites.reduce((accumulator, favorite) => {
        if (favorite.favorito === 0) {
          accumulator += 1
        }
        return accumulator
      }, 0)
    },
    amountOfQuickAccesses() {
      return this.module.favorites.reduce((accumulator, favorite) => {
        if (favorite.acessoRapido === 0) {
          accumulator += 1
        }
        return accumulator
      }, 0)
    }
  },

  methods: {
    ...mapActions({
      getForwardingtTypes: 'Forwardings/getForwardingTypesAction',
      getSpecialties: 'Forwardings/getSpecialtiesAction',
      getMedicsBySpecialty: 'Forwardings/getMedicsBySpecialtyAction',
      getCids: 'Forwardings/getCidsAction',
      getPriorities: 'Forwardings/getPrioritiesAction'
    }),
    ...moduleMethods,
    hideSearchResults() {
      this.filter = ''
      this.results = []
    },

    selectForwarding(forwarding) {
      this.forwarding = { ...forwarding }
      this.filter = this.cidDescription
    },

    getForwardingTypeById(id) {
      const item = this.forwardingTypes.find((element) => element.id === id)

      return item.descTipoEncaminhamento
    },

    resultSelected(result) {
      this.forwarding.idCid = result.id
      this.filter = result.descCid

      this.searchOpened = false
    },

    saveForwarding() {
      this.$store.dispatch({
        type: 'MedicalReports/addForwardingFavoriteHandBook',
        payload: {
          ...this.forwarding,
          idProntuarioPreferencia: this.module.id
        }
      })
      this.filter = ''
      this.resetForwarding()
    },

    removeForwardingFavoriteFromHandBook(favorite) {
      this.$store.dispatch({
        type: 'MedicalReports/removeForwardingFavoriteFromHandBook',
        payload: favorite
      })
    },
    addForwardingToFavorites(data) {
      if (this.amountOfFavorites >= 5) {
        this.$toast.warning(
          'Quantidade máxima de acesso rápido já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }

      this.$store.dispatch({
        type: 'MedicalReports/addForwardingToFavoritesHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.module.id
        }
      })
      this.filter = ''
    },

    addForwardingToQuickAccess(data) {
      if (this.amountOfQuickAccesses >= 5) {
        this.$toast.warning(
          'Quantidade máxima de acesso rápido já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }
      this.$store.dispatch({
        type: 'MedicalReports/addForwardingToQuickAccessHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.module.id
        }
      })

      this.filter = ''
    },

    resetForwarding() {
      this.forwarding = {
        descTitulo: '',
        idProntuarioPreferencia: '',
        idEspecialidade: '',
        idTipoEncaminhamento: '',
        idProfissional: '',
        favorito: 1,
        acessoRapido: 1,
        idCid: '',
        idEncaminhamentoPrioridade: '',
        descHistoricoClinico: '',
        notificarRecepcao: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalStyles.scss';

.form {
  display: flex;
  width: 100%;
  height: 10px;
}
</style>
