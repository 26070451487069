<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form id="form" @submit.prevent="handleSubmit(saveAcuity)">
      <b-row class="section-title">
        <b-col md="12">
          <span>Empresa</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="10">
          <SearchEnterprise
            @selectedEnterprise="
              (value) => {
                enterprise = value
              }
            "
          />
        </b-col>

        <b-col md="2">
          <b-form-checkbox v-model="isNewEnterprise" v-if="asoCanBeEdited">
            Nova empresa
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Profissional:</label>
            <b-form-input
              :disabled="true"
              :value="user.nome"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Selecione um profissional</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Razão Social:</label>
            <b-form-input
              ref="corporateName"
              :disabled="!isNewEnterprise"
              v-model="enterprise.corporateName"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe sua empresa</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>CNPJ:</label>
            <b-form-input
              :disabled="!isNewEnterprise"
              v-model="enterprise.cnpj"
              :class="errors.length > 0 ? ' is-invalid' : ''"
              v-mask="'##.###.###/####.##'"
            />
            <div class="invalid-feedback">
              <span>Informe o CNPJ</span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Endereço:</label>
            <b-form-input
              :disabled="!isNewEnterprise"
              v-model="enterprise.address"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe um endereço</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="2">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Nº:</label>
            <b-form-input
              v-mask="'##############'"
              :disabled="!isNewEnterprise"
              v-model="enterprise.number"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe um número</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="5">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Bairro:</label>
            <b-form-input
              :disabled="!isNewEnterprise"
              v-model="enterprise.neighborhood"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe um bairro</span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <ValidationProvider rules="required">
            <label>Estado:</label>
            <v-select
              :disabled="!isNewEnterprise"
              :clearable="false"
              v-model="enterprise.state"
              :options="states"
              :reduce="(descEstado) => descEstado.id"
              label="descEstado"
            ></v-select>
            <div class="invalid-feedback">
              <span>Informe um estado</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="3">
          <ValidationProvider rules="required">
            <label>Cidade:</label>
            <v-select
              :disabled="!isNewEnterprise"
              :clearable="false"
              v-model="enterprise.city"
              :options="cities"
              :reduce="(cidade) => cidade.id"
              label="descCidade"
            ></v-select>
            <div class="invalid-feedback">
              <span>Informe um estado</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="3">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>CEP:</label>
            <b-form-input
              :disabled="!isNewEnterprise"
              v-model="enterprise.cep"
              v-mask="'#####-###'"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe um CEP</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="3">
          <label>Complemento:</label>
          <b-form-input
            v-model="enterprise.complement"
            :disabled="!isNewEnterprise"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="10">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Cargo:</label>
            <b-form-input
              ref="newOffice"
              v-if="isNewOffice"
              v-model="office"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />

            <v-select
              v-else
              :disabled="!asoCanBeEdited"
              :clearable="false"
              :options="offices"
              label="descCargo"
              :reduce="(item) => item.id"
              v-model="office"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe um cargo</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="2" style="display: flex; align-items: center">
          <b-form-checkbox v-model="isNewOffice" v-if="asoCanBeEdited">
            Novo cargo
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="section-title">
        <b-col md="12">
          <span>Dados Oculares</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Cor dos Olhos:</label>
            <b-form-input
              v-model="eyer"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Digite qual a cor do olho!</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Para longe - Acuidade visual em olho direito:</label>
            <b-form-input
              v-model="eyerRigth"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe a informação!</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label>Para longe - Acuidade visual em olho esquerdo:</label>
            <b-form-input
              v-model="eyerLeft"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            />
            <div class="invalid-feedback">
              <span>Informe a informação!</span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <ValidationProvider rules="required">
            <label>Visão Binocular:</label>
            <v-select
              :clearable="false"
              v-model="binocular"
              :options="valuesCorrect"
            ></v-select>
            <div class="invalid-feedback">
              <span>Informe um valor!</span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col md="2">
          <ValidationProvider rules="required">
            <label>Visão Cromática:</label>
            <v-select
              :clearable="false"
              v-model="cromatic"
              :options="valuesCorrect"
            ></v-select>
            <div class="invalid-feedback">
              <span>Informe um valor!</span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col md="2">
          <ValidationProvider rules="required">
            <label>Com correção visual:</label>
            <v-select
              :clearable="false"
              v-model="visual"
              :options="valuesCorrect"
            ></v-select>
            <div class="invalid-feedback">
              <span>Informe um valor!</span>
            </div>
          </ValidationProvider>
        </b-col>

        <b-col md="2">
          <ValidationProvider rules="required">
            <label>Conclusão:</label>
            <v-select
              :clearable="false"
              v-model="finaly"
              :options="finalys"
            ></v-select>
            <div class="invalid-feedback">
              <span>Informe um valor!</span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <label>Observação:</label>
          <b-form-textarea
            style="height: 60px"
            v-model="observation"
            :disabled="!asoCanBeEdited"
            rows="1"
            no-resize
          />
        </b-col>
      </b-row>
      <b-row style="margin-top: 26px">
        <b-col md="11"> </b-col>

        <b-col md="1">
          <b-button
            size="large"
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </form>
  </ValidationObserver>
</template>
<script>
import SearchEnterprise from '../../../../ASO/SearchEnterprise.vue'
import { nextTick } from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['idHandbook', 'idClinic', 'idPatient', 'acuityToEdit'],
  data() {
    return {
      loading: false,
      states: [],
      cities: [],
      offices: [],
      office: '',
      isNewEnterprise: false,
      isNewOffice: false,
      asoToEditHandbookId: '',
      enterprise: {
        id: '',
        corporateName: '',
        cnpj: '',
        address: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        complement: ''
      },
      valuesCorrect: ['SIM', 'NAO'],
      finalys: ['NORMAL', 'ALTERADO'],
      binocular: '',
      cromatic: '',
      visual: '',
      finaly: '',
      observation: '',
      eyer: '',
      eyerLeft: '',
      eyerRigth: ''
    }
  },
  components: { SearchEnterprise },
  watch: {
    acuityToEdit(newValue) {
      this.acuityToEdit = newValue
      if (newValue !== '' && newValue !== null && newValue !== undefined) {
        this.setAcuityToEdit()
      }
    },
    'enterprise.state': function () {
      this.handleGetCities()
    },
    isNewEnterprise(newValue) {
      this.enterprise = {
        id: '',
        corporateName: '',
        cnpj: '',
        address: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        complement: ''
      }

      if (newValue) {
        nextTick(() => this.$refs.corporateName.focus())
      }
    },
    isNewOffice(newValue) {
      this.office = ''
      if (newValue) {
        nextTick(() => this.$refs.newOffice.focus())
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'Setting/user' }),
    asoCanBeEdited() {
      if (this.asoToEditHandbookId) {
        return this.idHandbook === this.asoToEditHandbookId
      }
      return true
    }
  },
  methods: {
    ...mapActions({
      getStates: 'Setting/getStatesAction',
      getCitiesByStateId: 'Setting/getCitiesByStateIdAction',
      getOfficesByClinic: 'ASO/getOfficesByClinic',
      createASOEnterprise: 'ASO/createASOEnterprise',
      createASOOffice: 'ASO/createASOOffice',
      getMedicEnterpriseByMedic: 'ASO/getMedicEnterpriseByMedic',
      createMedicEnterpriseRelation: 'ASO/createMedicEnterpriseRelation',
      getAcuity: 'Acuity/getCompleteAcuityByIdAndClinic',
      addAcuityToHandBook: 'Acuity/addAcuityToHandBook',
      editAcuityFromHandBook: 'Acuity/editAcuityFromHandBook'
    }),
    async handleGetStates() {
      const states = await this.getStates()

      this.states = states
    },
    async handleGetCities() {
      const cities = await this.getCitiesByStateId(this.enterprise.state)

      this.cities = cities
    },
    async handleGetOffices() {
      const response = await this.getOfficesByClinic(this.idClinic)

      this.offices = response
    },
    async saveAcuity() {
      try {
        this.loading = true
        if (this.isNewEnterprise) {
          const id = await this.handleCreateNewEnterprise()
          this.enterprise.id = id
        } else {
          const verif = await this.verifyMedicEnterpriseRelation()

          if (!verif) {
            await this.handleCreateMedicEnterpriseRelation()
          }
        }

        if (this.isNewOffice) {
          const id = await this.handleCreateNewOffice()
          this.office = id
        }

        const object = {
          idProntuario: this.idHandbook,
          dtInclusao: new Date().toISOString(),
          dtOcorrencia: new Date().toISOString(),
          idMedEmpresaTrabalho: this.enterprise.id,
          razaoSocialEmpresa: this.enterprise.corporateName,
          idMedicoTrabalho: this.user.id,
          idCargoTrabalho: this.office,
          idPaciente: this.idPatient,
          ativo: 0,
          corOlhos: this.eyer,
          longeOlhoDireito: this.eyerRigth,
          longeOlhoEsquerdo: this.eyerLeft,
          visaoBinocular: this.binocular,
          visaoCromatica: this.cromatic,
          correcaoVisual: this.visual,
          conclusao: this.finaly,
          observacao: this.observation
        }
        if (this.acuityToEdit) {
          object.id = this.acuityToEdit
          await this.editAcuityFromHandBook({ payload: object })
        } else {
          await this.addAcuityToHandBook({ payload: object })
        }

        this.$emit('saved')
        this.$emit('close')
      } catch (e) {
        this.$toast.error(e.response.message)
      } finally {
        this.loading = false
      }
    },
    async handleCreateNewEnterprise() {
      const object = {
        razaoSocial: this.enterprise.corporateName,
        cnpj: this.enterprise.cnpj,
        logradouro: this.enterprise.address,
        numero: parseInt(this.enterprise.number),
        bairro: this.enterprise.neighborhood,
        complemento: this.enterprise.complement,
        cep: this.enterprise.cep,
        idCidade: this.enterprise.city,
        idEstado: this.enterprise.state,
        idClinica: this.idClinic,
        idUsuarioInclusao: this.user.id,
        ativo: 0,
        dtInclusao: new Date().toISOString()
      }

      const response = await this.createASOEnterprise(object)

      return response.data.object.id
    },
    async verifyMedicEnterpriseRelation() {
      const relations = await this.getMedicEnterpriseByMedic(this.user.id)

      const item = relations.find((element) => {
        return element.idMedTrabalhoEmpresa === this.enterprise.id
      })

      if (item) {
        return item.id
      } else {
        return false
      }
    },
    async handleCreateMedicEnterpriseRelation() {
      const id = await this.createMedicEnterpriseRelation({
        idMedico: this.user.id,
        idMedTrabalhoEmpresa: this.enterprise.id
      })

      return id
    },
    async setAcuityToEdit() {
      const acuity = await this.getAcuity({
        idClinic: this.idClinic,
        idAcuity: this.acuityToEdit
      })

      const { dadosEmpresa } = acuity

      this.asoToEditHandbookId = acuity.idProntuario
      this.office = acuity.idCargoTrabalho
      this.observation = acuity.observacao
      this.visual = acuity.correcaoVisual
      this.eyer = acuity.corOlhos
      this.eyerLeft = acuity.longeOlhoEsquerdo
      this.eyerRigth = acuity.longeOlhoDireito
      this.binocular = acuity.visaoBinocular
      this.cromatic = acuity.visaoCromatica
      this.finaly = acuity.conclusao

      this.enterprise = {
        id: dadosEmpresa.id,
        corporateName: dadosEmpresa.razaoSocial,
        cnpj: dadosEmpresa.cnpj,
        address: dadosEmpresa.logradouro,
        number: dadosEmpresa.numero,
        neighborhood: dadosEmpresa.bairro,
        city: dadosEmpresa.idCidade,
        state: dadosEmpresa.idEstado,
        cep: dadosEmpresa.cep,
        complement: dadosEmpresa.complemento
      }
    }
  },
  async mounted() {
    this.handleGetStates()
    await this.handleGetOffices()
    if (
      this.acuityToEdit !== '' &&
      this.acuityToEdit !== null &&
      this.acuityToEdit !== undefined
    ) {
      this.setAcuityToEdit()
    }
  }
}
</script>
<style>
.section-title {
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  margin-bottom: 10px;
}

.section-title:not(:first-child) {
  margin-top: 20px;
}
</style>
