const FORMS_MODULE_ID = 12

const mutations = {
  loadForms(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORMS_MODULE_ID
    )

    module.data = payload
  },

  addForm(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORMS_MODULE_ID
    )

    module.data.push({ ...payload, isNew: true })

    module.data.sort((a, b) => {
      return new Date(b.dtInclusao) - new Date(a.dtInclusao)
    })
  },

  removeForm(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORMS_MODULE_ID
    )

    const formIndex = module.data.findIndex((form) => form.id === payload.id)

    module.data.splice(formIndex, 1)
  },

  updateFormField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === FORMS_MODULE_ID
    )

    const formIndex = module.data.findIndex((form) => form.id === payload.id)

    module.data.splice(formIndex, 1, { ...payload, modified: true })
  }
}

export default mutations
