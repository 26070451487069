<template>
  <div key="vitalSigns">
    <div class="module__wrapper">
      <div class="module__options">
        <b-icon
          font-scale="2"
          class="module__options-icon"
          icon="grid"
          variant="primary"
        ></b-icon>
        <div class="module__info">
          <input
            :class="
              isEditingModuleName
                ? 'module__info-title__editing'
                : 'module__info-title'
            "
            v-model="moduleName"
            :readonly="isEditingModuleName ? false : true"
            maxlength="37"
          />
          <p class="module__info-subtitle">
            Seção {{ module.sequencia + 1 }} de {{ modulesLength }}
          </p>
        </div>
      </div>

      <b-icon
        @click="isEditingModuleName = !isEditingModuleName"
        :icon="this.isEditingModuleName ? 'check2-square' : 'pencil-square'"
        :variant="this.isEditingModuleName ? 'success' : 'primary'"
        font-scale="1.5"
        class="modal-info__edit"
      ></b-icon>

      <div class="module__icons">
        <b-icon
          @click="swapUp(module)"
          icon="triangle-fill"
          flip-v
          variant="primary"
          font-scale="1.3"
          class="mr-4 ml-1"
        ></b-icon>

        <b-icon
          icon="triangle-fill"
          variant="primary"
          font-scale="1.3"
          @click="swapDown(module)"
        ></b-icon>
      </div>

      <div
        class="modal-info__active-checkbox"
        @click.prevent="toggleExpanded(module)"
      >
        <b-form-checkbox switch :checked="module.aberto === 0" />
      </div>
      <div
        class="modal-info__open-checkbox"
        @click.prevent="toggleActive(module)"
      >
        <b-form-checkbox switch :checked="module.ativo === 0" />
      </div>

      <div class="modal-info__pallete">
        <input
          type="color"
          v-model="module.corHexadecimal"
          @change="changeModuleBackground(module, $event)"
        />
      </div>

      <div @click="toggleExpandModule()">
        <b-icon v-if="expanded" icon="chevron-up" font-scale="2"></b-icon>
        <b-icon v-else icon="chevron-down" font-scale="2"></b-icon>
      </div>
    </div>

    <template v-if="expanded">
      <div class="search">
        <p class="search__warning">
          Habilite os campos e as unidades de medidas que deseja em seu
          prontuário
        </p>
      </div>

      <div class="optionsContainer">
        <div class="first-row">
          <div class="option">
            <div class="sistolica">
              <b-form-group label="Sistólica:">
                <v-select
                  :options="
                    possibleUnits.dadosPressaoSistolica.unidadePossiveis
                  "
                  @input="
                    (value) => setSelected(value, 'dadosPressaoSistolica')
                  "
                  :value="possibleUnits.dadosPressaoSistolica.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosPressaoSistolica', $event)"
                  :value="possibleUnits.dadosPressaoSistolica.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="diastolica">
              <b-form-group label="Diastólica:">
                <v-select
                  :options="
                    possibleUnits.dadosPressaoDiastolica.unidadePossiveis
                  "
                  @input="
                    (value) => setSelected(value, 'dadosPressaoDiastolica')
                  "
                  :value="possibleUnits.dadosPressaoDiastolica.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>
              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosPressaoDiastolica', $event)"
                  :value="possibleUnits.dadosPressaoDiastolica.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                @change="changeActive('dadosPressao')"
                :checked="
                  possibleUnits.dadosPressaoDiastolica.ativo === 0 &&
                  possibleUnits.dadosPressaoSistolica.ativo === 0
                "
                switch
              ></b-form-checkbox>
            </div>
          </div>
          <div class="option">
            <div class="weight">
              <b-form-group label="Peso:">
                <v-select
                  :options="possibleUnits.dadosIMCPeso.unidadePossiveis"
                  @input="(value) => setSelected(value, 'dadosIMCPeso')"
                  :value="possibleUnits.dadosIMCPeso.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosIMCPeso', $event)"
                  :value="possibleUnits.dadosIMCPeso.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="height">
              <b-form-group label="Altura:">
                <v-select
                  :options="possibleUnits.dadosIMCAltura.unidadePossiveis"
                  @input="(value) => setSelected(value, 'dadosIMCAltura')"
                  :value="possibleUnits.dadosIMCAltura.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosIMCAltura', $event)"
                  :value="possibleUnits.dadosIMCAltura.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="checkbox">
              <b-form-checkbox
                switch
                :checked="
                  possibleUnits.dadosIMCAltura.ativo === 0 &&
                  possibleUnits.dadosIMCPeso.ativo === 0
                "
                @change="changeActive('dadosIMC')"
              ></b-form-checkbox>
            </div>
          </div>
          <div class="option">
            <div class="arm">
              <b-form-group label="Braço:">
                <v-select
                  :options="possibleUnits.dadosMedidasBraco.unidadePossiveis"
                  @input="(value) => setSelected(value, 'dadosMedidasBraco')"
                  :value="possibleUnits.dadosMedidasBraco.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasBraco', $event)"
                  :value="possibleUnits.dadosMedidasBraco.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="checkbox">
              <b-form-checkbox
                :checked="possibleUnits.dadosMedidasBraco.ativo === 0"
                @change="changeActive('dadosMedidasBraco')"
                switch
              ></b-form-checkbox>
            </div>
          </div>
          <div class="option">
            <div class="hip">
              <b-form-group label="Quadril:">
                <v-select
                  :options="possibleUnits.dadosMedidasQuadril.unidadePossiveis"
                  @input="(value) => setSelected(value, 'dadosMedidasQuadril')"
                  :value="possibleUnits.dadosMedidasQuadril.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasQuadril', $event)"
                  :value="possibleUnits.dadosMedidasQuadril.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                @change="changeActive('dadosMedidasQuadril')"
                :checked="possibleUnits.dadosMedidasQuadril.ativo === 0"
                switch
              ></b-form-checkbox>
            </div>
          </div>
          <div class="option">
            <div class="temperature">
              <b-form-group label="Temperatura:">
                <v-select
                  :options="
                    possibleUnits.dadosMedidasTemperatura.unidadePossiveis
                  "
                  @input="
                    (value) => setSelected(value, 'dadosMedidasTemperatura')
                  "
                  :value="possibleUnits.dadosMedidasTemperatura.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasTemperatura', $event)"
                  :value="possibleUnits.dadosMedidasTemperatura.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                :checked="possibleUnits.dadosMedidasTemperatura.ativo === 0"
                @change="changeActive('dadosMedidasTemperatura')"
                switch
              ></b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="second-row">
          <div class="option">
            <div class="calf">
              <b-form-group label="Panturrilha:">
                <v-select
                  :options="
                    possibleUnits.dadosMedidasPanturilha.unidadePossiveis
                  "
                  @input="
                    (value) => setSelected(value, 'dadosMedidasPanturilha')
                  "
                  :value="possibleUnits.dadosMedidasPanturilha.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasPanturilha', $event)"
                  :value="possibleUnits.dadosMedidasPanturilha.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                :checked="possibleUnits.dadosMedidasPanturilha.ativo === 0"
                @change="changeActive('dadosMedidasPanturilha')"
                switch
              ></b-form-checkbox>
            </div>
          </div>
          <div class="option">
            <div class="abdominal">
              <b-form-group label="Abdome:">
                <v-select
                  :options="
                    possibleUnits.dadosMedidasAbdominal.unidadePossiveis
                  "
                  @input="
                    (value) => setSelected(value, 'dadosMedidasAbdominal')
                  "
                  :value="possibleUnits.dadosMedidasAbdominal.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasAbdominal', $event)"
                  :value="possibleUnits.dadosMedidasAbdominal.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                :checked="possibleUnits.dadosMedidasAbdominal.ativo === 0"
                @change="changeActive('dadosMedidasAbdominal')"
                switch
              ></b-form-checkbox>
            </div>
          </div>

          <div class="option">
            <div class="pulse">
              <b-form-group label="Pulso:">
                <v-select
                  :options="possibleUnits.dadosMedidasPulso.unidadePossiveis"
                  @input="(value) => setSelected(value, 'dadosMedidasPulso')"
                  :value="possibleUnits.dadosMedidasPulso.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasPulso', $event)"
                  :value="possibleUnits.dadosMedidasPulso.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                :checked="possibleUnits.dadosMedidasPulso.ativo === 0"
                @change="changeActive('dadosMedidasPulso')"
                switch
              ></b-form-checkbox>
            </div>
          </div>

          <div class="option">
            <div class="saturacao">
              <b-form-group label="Saturação:">
                <v-select
                  :options="
                    possibleUnits.dadosMedidasSaturacao.unidadePossiveis
                  "
                  @input="
                    (value) => setSelected(value, 'dadosMedidasSaturacao')
                  "
                  :value="possibleUnits.dadosMedidasSaturacao.descUnidade"
                  label="descUnidade"
                  :clearable="false"
                ></v-select>
              </b-form-group>

              <b-form-group label="Referência">
                <b-form-input
                  @change="updateField('dadosMedidasSaturacao', $event)"
                  :value="possibleUnits.dadosMedidasSaturacao.referencia"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="checkbox">
              <b-form-checkbox
                :checked="possibleUnits.dadosMedidasSaturacao.ativo === 0"
                @change="changeActive('dadosMedidasSaturacao')"
                switch
              ></b-form-checkbox>
            </div>
          </div>
          <div class="cta-button">
            <b-button @click="submitChanges" variant="primary"
              >Salvar alterações</b-button
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '../../../../services/index'
import moduleMethods from '../../shared/modulesMethods'

export default {
  name: 'VitalSignsUserPreference',
  props: ['module', 'modulesLength'],
  mounted() {
    this.possibleUnits = this.vitalSignsFavorites
  },
  data() {
    return {
      possibleUnits: null,
      expanded: false,
      isEditingModuleName: false,
      moduleName: this.module.descMeuModulo
    }
  },

  destroyed() {
    if (this.moduleName !== this.module.descMeuModulo) {
      this.changeModuleName(this.module, this.moduleName)
    }
  },

  computed: {
    ...mapGetters('MedicalReports', ['vitalSignsFavorites'])
  },
  methods: {
    setSelected(value, key) {
      this.possibleUnits[key].idUnidade = value.id
      this.possibleUnits[key].descUnidade = value.descUnidade
    },
    changeActive(unit) {
      switch (unit) {
        case 'dadosPressao': {
          this.possibleUnits.dadosPressaoDiastolica.ativo =
            this.possibleUnits.dadosPressaoDiastolica.ativo === 0 ? 1 : 0
          this.possibleUnits.dadosPressaoSistolica.ativo =
            this.possibleUnits.dadosPressaoSistolica.ativo === 0 ? 1 : 0
          break
        }
        case 'dadosIMC': {
          this.possibleUnits.dadosIMCAltura.ativo =
            this.possibleUnits.dadosIMCAltura.ativo === 0 ? 1 : 0

          this.possibleUnits.dadosIMCPeso.ativo =
            this.possibleUnits.dadosIMCPeso.ativo === 0 ? 1 : 0
          break
        }
        default: {
          this.possibleUnits[unit].ativo =
            this.possibleUnits[unit].ativo === 0 ? 1 : 0
        }
      }
    },
    convertCommaToDot(value) {
      if (value.includes(',')) {
        value = value.replaceAll(',', '.')
      }
      return value
    },
    updateField(field, value) {
      this.possibleUnits[field].referencia =
        parseFloat(this.convertCommaToDot(value)) || null
    },
    async submitChanges() {
      try {
        await API.put('/api/FavoritoSinalVital/Editar', {
          object: this.possibleUnits
        })

        this.$toast.success('Preferências salvas!')
      } catch (error) {
        this.$toast.error('Ocorreu um erro!')
      }
    },
    ...moduleMethods
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalStyles.scss';

.optionsContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
  padding: 0 20px;

  .cta-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 16px;
  }

  .option {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    align-items: center;

    > div {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 8px;
    }

    .sistolica,
    .weight {
      grid-column: 1 / 3;
    }

    .diastolica,
    .height {
      grid-column: 3 / 5;
    }

    .arm,
    .hip,
    .temperature,
    .calf,
    .abdominal,
    .pulse,
    .saturacao {
      grid-column: 1 / 5;
    }

    .checkbox {
      grid-column: -1 / -2;
    }
  }

  .first-row {
    grid-column: 1 / 2;
  }

  .second-row {
    position: relative;
    grid-column: 2 / 3;
  }
}
</style>
