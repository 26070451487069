<template>
  <b-row id="totalizers">
    <b-col md="2" lg="3" class="size">
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class="iq-bg-primary rounded"
      >
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <div class="rounded-circle iq-card-icon bg-primary">
              <i class="ri-group-fill"></i>
            </div>
            <div class="text-right">
              <h3 class="mb-0">
                <span class="counter">{{
                  indicators.quantidadeDePacienteMeuDia || 0
                }}</span>
              </h3>
              <h5 class="totalizerText">Paciente(s)</h5>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col md="2" lg="3" class="size">
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class="iq-bg-warning rounded"
      >
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <div class="rounded-circle iq-card-icon bg-warning">
              <i class="ri-calendar-fill"></i>
            </div>
            <div class="text-right">
              <h3 class="mb-0">
                <span class="counter"
                  >{{ indicators.ocupaçãoAgendaMeuDia || 0 }}%</span
                >
              </h3>
              <h5 class="totalizerText">Ocupação</h5>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col md="2" lg="3" class="size">
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class="iq-bg-danger rounded"
      >
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <div class="rounded-circle iq-card-icon bg-danger">
              <i class="far fa-calendar-times"></i>
            </div>
            <div class="text-right">
              <h3 class="mb-0">
                <span class="counter">{{
                  indicators.quantidadeFaltaMeuDia || 0
                }}</span>
              </h3>
              <h5 class="totalizerText">Faltas</h5>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col md="2" lg="3" class="size">
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class="iq-bg-info rounded"
      >
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <div class="rounded-circle iq-card-icon bg-info">
              <i class="far fa-clock"></i>
            </div>
            <div class="text-right">
              <h3 class="mb-0">
                <span class="counter">{{
                  indicators.previsaoSaidaMeuDia || ''
                }}</span>
              </h3>

              <h6 class="totalizerText">Previsão de saída</h6>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col md="2" lg="3" class="size">
      <iq-card
        id="color"
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class=" rounded"
      >
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <div class="rounded-circle iq-card-icon bg-info" id="bg-info">
              <i class="fas fa-user-clock"></i>
            </div>
            <div class="text-right">
              <h3 class="mb-0">
                <span class="counter">{{
                  indicators.quantidadeEsperaMeuDia || 0
                }}</span>
              </h3>

              <h5 class="totalizerText">Sala de espera</h5>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import API from '../../services/index'

export default {
  props: ['doctor', 'clinica', 'dateToView'],
  mounted() {
    this.getIndicators(this.doctor.idUsuario, this.clinica.idClinica)
  },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      indicators: ''
    }
  },
  methods: {
    getIndicators(doctor, clinic) {
      if (doctor && clinic) {
        const queryDoctor = `id_usuario=${doctor}`
        const queryClinica = `id_clinica=${clinic}`
        const queryDay = `data_ref=${this.dateToView}`

        API.get(
          `/api/Indicadores/IndicadorMeuDia/?${queryDoctor}&${queryClinica}&${queryDay}`,
          this.authToken
        ).then((res) => {
          this.indicators = res.data.object
          if (this.indicators.previsaoSaidaMeuDia) {
            this.indicators.previsaoSaidaMeuDia = this.formatHour(
              this.indicators.previsaoSaidaMeuDia
            )
          }
        })
      }
    },
    formatHour(hourUnformated) {
      if (hourUnformated != null) {
        let [hour, time] = hourUnformated.split(' ')
        if (time) {
          return time === 'AM' && hour.length <= 4
            ? (hour = `0${hour}`)
            : time === 'AM'
            ? hour
            : time === 'PM' && hour.length <= 4 && parseInt(hour[0]) + 2 < 10
            ? (hour = `1${parseInt(hour[0]) + 2}:${hour[2]}${hour[3]}`)
            : time === 'PM' && hour.length <= 4
            ? (hour = `2${parseInt(hour[0]) - 8}:${hour[2]}${hour[3]}`)
            : (hour = `2${parseInt(hour[0] + hour[1]) - 8}:${hour[3]}${
                hour[4]
              }`)
        } else {
          return hourUnformated
        }
      }
    }
  }
}
</script>

<style scoped>
.size {
  max-width: 20% !important;
}
.totalizersText > h5 {
  font-weight: 700 !important;
}

#color {
  background: #a2d9ce !important;
}

#bg-info {
  background: linear-gradient(to right, #678a83 0%, #88b3aa 100%);
}

@media (max-width: 855px) {
  #totalizers {
    display: none;
  }
}
</style>
