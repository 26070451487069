<template>
  <div class="table-container">
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Data
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Profissional
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Empresa
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Cargo
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Imprimir
          </b-th>

          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Editar
          </b-th>

          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="acuity.length !== 0">
        <b-tr v-for="acuity in AcuityListToShow" :key="acuity.dtInclusao">
          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            {{ handleFormatDate(acuity.dtInclusao) }}
          </b-td>

          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            {{ acuity.nmUsuarioMedicoTrabalho || user.nome }}
          </b-td>

          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            {{ acuity.razaoSocialEmpresa }}
          </b-td>

          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            {{ acuity.descCargoTrabalho }}
          </b-td>

          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            <b-button variant="outline-light" @click="setAcuityToPrint(acuity)">
              <i class="fas fa-print fa-2x" style="color: #089bab"></i>
            </b-button>
          </b-td>

          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            <b-button variant="outline-light" @click="setAcuityToEdit(acuity)">
              <i class="fas fa-file-alt fa-2x" style="color: #089bab"></i>
            </b-button>
          </b-td>

          <b-td :class="acuity.ativo === 1 ? 'line-throughed' : ''">
            <b-button
              :variant="acuity.ativo === 0 ? 'danger' : 'primary'"
              @click="handleRemoveAcuity(acuity)"
            >
              {{
                acuity.idProntuario === handbookId
                  ? 'Remover'
                  : acuity.ativo === 0
                  ? 'Inativar'
                  : 'Ativar'
              }}
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '../../../../../Utils/formatters'
export default {
  name: 'VisualAcuityList',
  props: [
    'headerColor',
    'acuity',
    'showInactive',
    'medicOpinions',
    'licenceTypes',
    'handbookId'
  ],
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      removeAcuityFromHandBook: 'Acuity/removeAcuityFromHandBook',
      updateAcuityAtivo: 'Acuity/updateAcuityAtivo'
    }),
    handleRemoveAcuity(acuity) {
      if (acuity.idProntuario === this.handbookId) {
        this.removeAcuityFromHandBook(acuity.id)
        this.$emit('savedAcuity')
      } else {
        this.updateAcuityAtivo(acuity)
        this.$emit('savedAcuity')
      }
    },
    filterMedicOpinionDescriptionById(id) {
      const medicOpinion = this.medicOpinions.find((opinion) => {
        return opinion.id === id
      })

      return medicOpinion.descParecer
    },

    filterLicenceDescriptionById(id) {
      const licenceType = this.licenceTypes.find((licence) => {
        return licence.id === id
      })

      return licenceType.descTipoAtestado
    },
    setAcuityToEdit(acuity) {
      this.$emit('setAcuityToEdit', acuity)
    },
    setAcuityToPrint(acuity) {
      this.$emit('setAcuityToPrint', acuity)
    },
    handleFormatDate(date) {
      return formatDate(date)
    }
  },
  computed: {
    ...mapGetters({ user: 'Setting/user' }),
    AcuityListToShow() {
      if (this.showInactive) {
        return this.acuity.filter((acuity) => !acuity.deleted)
      } else {
        return this.acuity.filter(
          (acuity) => acuity.ativo === 0 && !acuity.deleted
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  height: 35vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

td {
  font-size: 11px;
}

.table-header:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

.line-throughed {
  text-decoration: line-through;
}

th {
  font-weight: 400 !important;
  background-color: #089bab;
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

th:nth-child(1) {
  width: 100px;
  white-space: nowrap;
}

th:nth-child(2) {
  width: auto;
}

th:nth-child(3) {
  width: auto;
}

th:nth-child(4) {
  width: auto;
}

th:nth-child(5) {
  width: 100px;
}

th:nth-child(6) {
  width: 150px;
}

th:nth-child(7) {
  width: 100px;
}

@media (min-height: 600px) {
  .table-container {
    height: 40vh;
  }
}

@media (min-height: 700px) {
  .table-container {
    height: 45vh;
  }
}

@media (min-height: 800px) {
  .table-container {
    height: 50vh;
  }
}

@media (min-height: 900px) {
  .table-container {
    height: 53vh;
  }
}
</style>
