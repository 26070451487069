import { parseToHtml } from '../../../../Utils/parsers'

const EXAMS_HISTORY_ID_MODULE = 3

const mutations = {
  loadExamsHistory(state, { payload: { examsHistory, handbookId } }) {
    const module = state.modules.find(
      (module) => module.idModulo === EXAMS_HISTORY_ID_MODULE
    )

    const newExamsOnActualHandbook = module.data.filter((exam) => {
      return exam.isNew
    })

    examsHistory.forEach((exam) => {
      exam.acao =
        exam.idProntuario === handbookId
          ? 'Remover'
          : exam.ativo === 0
          ? 'Inativar'
          : 'Ativar'

      if (exam.dtRealizacao) {
        exam.dtRealizacao = new Date(exam.dtRealizacao).toLocaleDateString(
          'en-CA'
        )
      }

      if (exam.descLaudoInfo) {
        exam.descLaudoInfo = parseToHtml(exam.descLaudoInfo)
      }
    })

    module.data = [...newExamsOnActualHandbook, ...examsHistory]
  },

  addExamHistory(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === EXAMS_HISTORY_ID_MODULE
    )

    module.data.unshift({ ...payload, isNew: true })
  },

  updateExamField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === EXAMS_HISTORY_ID_MODULE
    )

    const examIndex = module.data.findIndex((exam) => exam.id === payload.id)

    module.data.splice(examIndex, 1, { ...payload, modified: true })
  },

  removeExamHistory(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === EXAMS_HISTORY_ID_MODULE
    )

    const examIndex = module.data.findIndex((exam) => exam.id === payload.id)

    module.data.splice(examIndex, 1)
  }
}

export default mutations
