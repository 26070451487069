<template>
  <b-modal
    id="modalTasks"
    header-bg-variant="primary"
    title="Lista de Tarefas"
    hide-footer
    size="lg"
    title-class="text-light"
    @close="close()"
    @hidden="close()"
  >
    <div class="container-task">
      <ValidationObserver ref="form">
        <div class="add-task">
          <h1>Criar Tarefa</h1>
          <div class="add-task-fields">
            <div class="content-task">
              <label>Responsável:</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  class="select"
                  :options="responsibles"
                  v-model="responsible"
                  label="nmUsuario"
                  :clearable="false"
                  :style="
                    errors.length > 0
                      ? 'border: 1px solid #e56672; border-radius: 10px;'
                      : ''
                  "
                />
                <div
                  v-if="errors.length > 0"
                  style="color: #e56672; font-size: 0.6rem; margin-top: 4px"
                >
                  <p>* Por favor escolha um responsável!</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="content-task">
              <label>Prioridade:</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  class="select"
                  :options="prioritys"
                  v-model="priority"
                  label="descPriority"
                  :clearable="false"
                  :style="
                    errors.length > 0
                      ? 'border: 1px solid #e56672; border-radius: 10px;'
                      : ''
                  "
                />
                <div
                  v-if="errors.length > 0"
                  style="color: #e56672; font-size: 0.6rem; margin-top: 4px"
                >
                  <p>* Por favor escolha uma prioridade!</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="content-task">
              <label>Descreva a tarefa:</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div style="display: flex; align-items: flex-end; gap: 6px">
                  <textarea
                    rows="3"
                    maxlength="4000"
                    v-model="task"
                    :style="
                      errors.length > 0
                        ? 'border: 1px solid #e56672; border-radius: 10px;'
                        : ''
                    "
                  />
                  <button @click="createTask()">Salvar</button>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 91%;
                  "
                >
                  <div
                    v-if="errors.length > 0"
                    style="color: #e56672; font-size: 0.6rem; margin-top: 4px"
                  >
                    <p>* Campo descrição é obrigatório!</p>
                  </div>
                  <div v-else></div>
                  <span>{{ task.length + '/4000' }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="list-task">
        <div class="content-task">
          <label>Pendentes:</label>
          <div class="list">
            <div class="header-list">
              <span>Data inclusão</span>
              <span>Incluido por</span>
              <span>Tarefa</span>
              <span>Prioridade</span>
              <span>Concluir</span>
              <span></span>
            </div>
            <div class="box-list">
              <li class="list-item" v-for="item in listPend" :key="item.id">
                <span>{{ formartDateInclude(item.dtAbertura) }}</span>
                <span style="max-width: 135px; text-overflow: ellipsis">{{
                  item.nmUsuarioSolicitante
                }}</span>
                <span>{{ item.descTarefa }}</span>
                <span>{{ getPrioridade(item.prioridade) }}</span>
                <input
                  style="width: 100%"
                  @change="checkboxClickedConcluid(item.id)"
                  type="checkbox"
                  :checked="item.finalizado === 1 ? true : false"
                />
                <div class="box-button-item">
                  <button
                    @click="duplicatedTask(item)"
                    class="button-list-item"
                  >
                    <i class="ri-file-copy-line" style="color: #089cac"></i>
                  </button>
                  <button
                    @click="removeItemByIdPend(item.id)"
                    class="button-list-item-delete"
                  >
                    <i class="ri-delete-bin-fill" style="color: aliceblue"></i>
                  </button>
                </div>
              </li>
            </div>
          </div>
        </div>
        <div class="content-task">
          <label>Concluidas:</label>
          <div class="list">
            <div class="header-list-concluid">
              <span>Data inclusão</span>
              <span>Incluido por</span>
              <span>Tarefa</span>
              <span>Prioridade</span>
              <span>Concluir</span>
              <span>Data conclusão</span>
              <span></span>
            </div>
            <div class="box-list">
              <li
                class="list-item-concluid"
                v-for="item in listEnd"
                :key="item.id"
              >
                <span style="margin-left: 4px">{{
                  formartDateInclude(item.dtAbertura)
                }}</span>
                <span style="margin-left: 4px">{{
                  item.nmUsuarioSolicitante
                }}</span>
                <span style="margin-left: 4px">{{ item.descTarefa }}</span>
                <span style="margin-left: 4px">{{
                  getPrioridade(item.prioridade)
                }}</span>
                <input
                  @change="checkboxClickedPendent(item.id)"
                  type="checkbox"
                  :checked="item.finalizado === 1 ? true : false"
                />
                <span style="margin-left: 4px">{{
                  formartDateFinally(item.dtFinalizado)
                }}</span>
                <div class="box-button-item">
                  <button
                    @click="duplicatedTask(item)"
                    class="button-list-item"
                  >
                    <i class="ri-file-copy-line" style="color: #089cac"></i>
                  </button>
                  <button
                    @click="removeItemByIdEnd(item.id)"
                    class="button-list-item-delete"
                  >
                    <i class="ri-delete-bin-fill" style="color: aliceblue"></i>
                  </button>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
  mounted() {
    this.$bvModal.show('modalTasks')
    this.initSelects()
    this.initAndAttLists()
    window.addEventListener('keydown', this.handleKeyPress)
  },
  data() {
    return {
      listPend: [],
      listEnd: [],
      responsibles: [],
      responsible: {},
      prioritys: [],
      priority: {},
      task: ''
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      getTasks: 'Tasks/getTasks',
      user: 'Setting/user'
    })
  },
  methods: {
    ...mapActions({
      deleteTask: 'Tasks/deleteTask',
      addTask: 'Tasks/addTask',
      editTask: 'Tasks/editTask',
      searchTasks: 'Tasks/searchTasks',
      getUsersForClinics: 'Finance/getUsersForClinics'
    }),
    getPrioridade(id) {
      if (id === 1) {
        return 'BAIXA'
      } else if (id === 2) {
        return 'MEDIA'
      } else if (id === 3) {
        return 'ALTA'
      }
    },
    async initAndAttLists() {
      await this.searchTasks(this.user.clinica_usuario[0].idUsuario)
      const listAux = this.getTasks
      if (listAux.length === 0) {
        this.listPend = []
        this.listEnd = []
      } else {
        this.listPend = listAux.filter((item) => item.finalizado === 0)
        this.listEnd = listAux.filter((item) => item.finalizado === 1)
      }
    },
    async initSelects() {
      this.responsibles = await this.getUsersForClinics({
        clinic: this.user.clinica_usuario[0].idClinica,
        authToken: this.authToken
      })
      this.responsible = this.responsibles[0]

      const listPriority = [
        {
          descPriority: 'BAIXA',
          id: 1
        },
        {
          descPriority: 'MEDIA',
          id: 2
        },
        {
          descPriority: 'ALTA',
          id: 3
        }
      ]
      this.prioritys = listPriority
      this.priority = this.prioritys[0]
    },
    duplicatedTask(task) {
      this.responsible = this.responsibles.find(
        (item) => item.idUsuario === task.idUsuarioResponsavel
      )
      this.priority = this.prioritys.find((item) => item.id === task.prioridade)
      this.task = task.descTarefa
    },
    async createTask() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        return this.$toast.error('Verifique os campos obrigatórios!')
      } else {
        if (
          this.responsible.idUsuario === this.user.clinica_usuario[0].idUsuario
        ) {
          const object = {
            idUsuarioSolicitante: this.user.clinica_usuario[0].idUsuario,
            idUsuarioResponsavel: this.responsible.idUsuario,
            dtAbertura: new Date(),
            prioridade: this.priority.id,
            descTarefa: this.task
          }
          const response = await this.addTask(object)
          this.listPend.unshift(response)
          this.$toast.success('Tarefa cadastrada com sucesso!')
          this.task = ''
        } else {
          const object = {
            idUsuarioSolicitante: this.user.clinica_usuario[0].idUsuario,
            idUsuarioResponsavel: this.responsible.idUsuario,
            dtAbertura: new Date(),
            prioridade: this.priority.id,
            descTarefa: this.task
          }
          await this.addTask(object)
          this.$toast.success('Tarefa cadastrada com sucesso!')
          this.task = ''
        }
      }
    },
    async checkboxClickedConcluid(id) {
      const itemToMove = this.listPend.find((item) => item.id === id)
      this.listPend = this.listPend.filter((item) => item.id !== id)
      itemToMove.finalizado = 1
      itemToMove.dtFinalizado = new Date()
      this.listEnd.push(itemToMove)
      await this.editTask(itemToMove)
    },

    async checkboxClickedPendent(id) {
      const itemToMove = this.listEnd.find((item) => item.id === id)
      this.listEnd = this.listEnd.filter((item) => item.id !== id)
      itemToMove.finalizado = 0
      this.listPend.push(itemToMove)
      await this.editTask(id)
    },
    async removeItemByIdEnd(id) {
      const index = this.listEnd.findIndex((item) => item.id === id)
      this.listEnd.splice(index, 1)
      await this.deleteTask(id)
    },
    async removeItemByIdPend(id) {
      const index = this.listPend.findIndex((item) => item.id === id)
      this.listPend.splice(index, 1)
      await this.deleteTask(id)
    },
    formartDateInclude(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formartDateFinally(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    close() {
      this.$emit('closeModal')
    },
    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress)
  }
}
</script>
<style scoped>
.container-task {
  padding: 6px;
  width: 100%;
  color: #181818;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.add-task {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-task h1 {
  font-weight: bold;
  font-size: 1.3rem;
  color: #979797;
}
.add-task-fields {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.add-task-fields button {
  cursor: pointer;
  border-radius: 10px;
  border: none;
  background-color: #089cac;
  padding: 10px;
  color: #fff;
}
.content-task {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.content-task label {
  width: 100%;
  font-size: 0.8rem;
  color: #7e7e7e;
}
.content-task textarea {
  border: 1px solid #d6d4d4;
  width: 660px;
  padding: 8px;
  border-radius: 10px;
}
.content-task textarea:focus {
  outline: none;
  border: 1px solid #089cac;
}
.select {
  width: 350px;
}

.list-task {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.list-task h1 {
  font-weight: bold;
  font-size: 1.3rem;
  color: #979797;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-list {
  gap: 6px;
  font-size: 0.6rem;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px;
  background-color: #089cac;
  color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 1fr 1.5fr 0.7fr 0.3fr 0.8fr;
  width: 100%;
  align-items: center;
}
.header-list-concluid {
  gap: 6px;
  font-size: 0.6rem;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px;
  background-color: #089cac;
  color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 0.5fr 0.4fr 0.8fr 0.3fr;
  width: 100%;
  align-items: center;
}

.box-list {
  overflow-y: scroll;
  height: 150px;
}

.list-item {
  gap: 6px;
  font-size: 0.6rem;
  width: 100%;
  border-bottom: 1px solid #d6d4d4;
  padding: 10px 8px;
  background-color: #fff;
  color: #181818;
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 1fr 1.5fr 0.7fr 0.3fr 0.8fr;
  width: 100%;
  align-items: center;
}
.list-item-concluid {
  gap: 6px;
  font-size: 0.6rem;
  width: 100%;
  border-bottom: 1px solid #d6d4d4;
  padding: 10px 8px;
  background-color: #fff;
  color: #181818;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 0.5fr 0.4fr 0.8fr 0.3fr;
  width: 100%;
  align-items: center;
}
.box-button-item {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.button-list-item-delete {
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: red;
}

.button-list-item {
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d6d4d4;
  background: transparent;
}

@media (max-width: 990px) {
  .content-task textarea {
    width: 400px;
  }
}

@media (max-width: 500px) {
  .content-task textarea {
    width: 330px;
  }
}

@media (max-width: 470px) {
  .select {
    width: 300px;
  }
  .content-task textarea {
    border: 1px solid #d6d4d4;
    width: 300px;
    padding: 8px;
    border-radius: 10px;
  }
}
</style>
