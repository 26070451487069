import sortArray from 'sort-array'

const VITAL_SIGN_MODULE_ID = 7

const mutations = {
  loadVitalSigns(state, { payload: { vitalSigns, handbookId } }) {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGN_MODULE_ID
    )

    sortArray(vitalSigns, {
      by: 'dtInclusao',
      order: 'asc'
    })

    vitalSigns.forEach((vitalSign) => {
      vitalSign.action = handbookId === vitalSign.idProntuario ? 'Inativar' : ''
    })

    module.data = [...vitalSigns]
  },

  sortVitalSigns(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGN_MODULE_ID
    )

    sortArray(module.data, {
      order: payload.order,
      by: payload.by
    })
  },

  createLocalVitalSign(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGN_MODULE_ID
    )
    const alreadyExists = module.data.findIndex(
      (vitalSign) => vitalSign.idProntuario === payload.idProntuario
    )

    if (alreadyExists < 0) {
      module.data.push({
        idProntuario: payload.idProntuario,
        sistolica: null,
        diastolica: null,
        peso: null,
        altura: null,
        pulso: null,
        temperatura: null,
        saturacao: null,
        panturilha: null,
        braco: null,
        quadril: null,
        abdominal: null,
        imc: null,
        ativoAbdominal: 'S',
        ativoBraco: 'S',
        ativoImc: 'S',
        ativoPanturilha: 'S',
        ativoPressao: 'S',
        ativoPulso: 'S',
        ativoQuadril: 'S',
        ativoTemperatura: 'S',
        ativoSaturacao: 'S',
        dtInclusao: new Date().toLocaleDateString('en-EN')
      })
    }
  },

  updateVitalSignField(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGN_MODULE_ID
    )
    const vitalSignIndex = module.data.findIndex(
      (vitalSign) => vitalSign.idProntuario === payload.idProntuario
    )

    if (vitalSignIndex >= 0) {
      module.data.splice(vitalSignIndex, 1, payload)
    }
  },

  editVitalSign(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGN_MODULE_ID
    )

    const dataIndex = module.data.findIndex(
      (vitalSign) => vitalSign.id === payload.id
    )

    module.data.splice(dataIndex, 1, payload)
  },

  removeVitalSignFromState(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGN_MODULE_ID
    )
    const vitalSignIndex = module.data.findIndex(
      (vitalSign) => vitalSign.idProntuario === payload.idProntuario
    )

    state.localVitalSignsState.splice(vitalSignIndex, 1)
  }
}

export default mutations
