<template>
  <b-container fluid style="margin-top: -20px">
    <PainelExpAndReve />
    <PainelRevenue />
    <PainelExpenses />
  </b-container>
</template>
<script>
import PainelRevenue from '../../components/Finance/relatorioFinance/paineis/PainelRevenue.vue'
import PainelExpenses from '../../components/Finance/relatorioFinance/paineis/PainelExpenses.vue'
import PainelExpAndReve from '../../components/Finance/relatorioFinance/paineis/PainelExpAndReve.vue'
export default {
  components: { PainelRevenue, PainelExpenses, PainelExpAndReve }
}
</script>
<style scoped></style>
