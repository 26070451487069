<template>
  <div class="module">
    <div class="details">
      <p
        v-if="getMedicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </p>

      <div class="details__checkbox-container">
        <b-form-checkbox v-model="showInactiveVitalSigns"></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>
    <div
      v-if="componentInfo.aberto === 0"
      :class="['content', getMedicalReportType === 2 ? 'content-tab' : '']"
      @click="() => (this.focusedInput = null)"
    >
      <div class="dados-form">
        <b-row
          align-v="center"
          v-if="
            componentInfo.favorites.dadosPressaoSistolica.ativo === 0 &&
            componentInfo.favorites.dadosPressaoDiastolica.ativo === 0
          "
        >
          <b-col cols="3" @click="(event) => event.stopPropagation()">
            <b-form-group
              style="position: relative"
              label="Sistólica:"
              label-for="sistolica"
            >
              <b-form-input
                autocomplete="off"
                type="text"
                class="focusable"
                @keydown="simulateTab($event)"
                :disabled="isEdditing"
                name="sistolica"
                :value="
                  isEdditing
                    ? this.vitalSignBeingEddited.sistolica
                    : currentVitalSign(this.idProntuario).sistolica
                "
                @input="
                  (value) => !isEdditing && updateField('sistolica', value)
                "
                @focus="setFocused('sistolica', 'pressao', $event)"
              >
              </b-form-input>

              <scrollable-list
                v-if="focusedInput === 'sistolica'"
                v-bind="defaultOptions.sistolica"
                @handle-selection="(value) => updateField('sistolica', value)"
              />
            </b-form-group>
            <span class="unit__description">{{
              componentInfo.favorites.dadosPressaoSistolica.descUnidade
            }}</span>
          </b-col>
          <b-col cols="3" @click="(event) => event.stopPropagation()">
            <b-form-group
              label="Diastólica:"
              label-for="diastelica"
              style="position: relative"
            >
              <b-form-input
                autocomplete="off"
                :disabled="isEdditing"
                type="text"
                class="focusable"
                @keydown="simulateTab($event)"
                name="diastelica"
                @input="
                  (value) => !isEdditing && updateField('diastolica', value)
                "
                :value="
                  isEdditing
                    ? this.vitalSignBeingEddited.diastolica
                    : currentVitalSign(this.idProntuario).diastolica
                "
                @focus="
                  setFocused('diastolica', 'pressao', 'dadosPressaoDiastolica')
                "
              ></b-form-input>

              <scrollable-list
                v-if="focusedInput === 'diastolica'"
                v-bind="defaultOptions.diastolica"
                @handle-selection="(value) => updateField('diastolica', value)"
              />
            </b-form-group>
            <span class="unit__description">{{
              componentInfo.favorites.dadosPressaoDiastolica.descUnidade
            }}</span>
          </b-col>

          <b-col cols="3">
            <b-row align-h="center">
              <b-button
                style="width: 80%"
                variant="success"
                :class="`blood-pressure blood-pressure--${bloodPressureInfo.classification}`"
                tabindex="-1"
                >{{ bloodPressureInfo.category }}</b-button
              >
            </b-row>
          </b-col>

          <b-col cols="3">
            <b-row align-v="center" align-h="center" style="margin-left: 15px">
              <template v-if="!isEdditing">
                <b-icon-grid3x3
                  class="mr-2"
                  font-scale="2"
                  variant="primary"
                  @click="selectChart('table', 'pressao')"
                ></b-icon-grid3x3>
                <b-icon-graph-up
                  font-scale="2"
                  variant="primary"
                  @click="selectChart('area', 'pressao')"
                ></b-icon-graph-up>
              </template>
              <b-button
                v-if="isEdditing"
                class="ml-4 cta-button"
                :variant="getButtonVariant(vitalSignBeingEddited.ativoPressao)"
                @click="toggleField('pressao')"
                >{{
                  getButtonText(vitalSignBeingEddited.ativoPressao)
                }}</b-button
              >
            </b-row>
          </b-col>
        </b-row>
        <hr class="line" />
        <b-row
          v-if="
            componentInfo.favorites.dadosIMCPeso.ativo === 0 &&
            componentInfo.favorites.dadosIMCAltura.ativo === 0
          "
          align-v="center"
        >
          <b-col cols="2" @click="(event) => event.stopPropagation()">
            <b-form-group
              style="position: relative"
              label="Peso:"
              label-for="peso"
            >
              <b-form-input
                autocomplete="off"
                :disabled="isEdditing"
                type="text"
                class="focusable"
                @keydown="simulateTab($event)"
                name="peso"
                @input="(value) => !isEdditing && updateField('peso', value)"
                :value="
                  isEdditing
                    ? this.vitalSignBeingEddited.peso
                    : currentVitalSign(this.idProntuario).peso
                "
                @focus="setFocused('peso', 'imc', 'dadosIMCPeso')"
              ></b-form-input>

              <scrollable-list
                v-if="focusedInput === 'peso'"
                v-bind="defaultOptions.peso"
                @handle-selection="(value) => updateField('peso', value)"
              />
            </b-form-group>
            <span class="unit__description">{{
              componentInfo.favorites.dadosIMCPeso.descUnidade
            }}</span>
          </b-col>
          <b-col cols="2" @click="(event) => event.stopPropagation()">
            <b-form-group
              label="Altura:"
              label-for="altura"
              style="position: relative"
            >
              <b-form-input
                autocomplete="off"
                :disabled="isEdditing"
                type="text"
                class="focusable"
                @keydown="simulateTab($event)"
                :value="
                  isEdditing
                    ? this.vitalSignBeingEddited.altura
                    : currentVitalSign(this.idProntuario).altura
                "
                @input="(value) => !isEdditing && updateField('altura', value)"
                name="altura"
                @focus="setFocused('altura', 'imc', 'dadosIMCAltura')"
              ></b-form-input>

              <scrollable-list
                v-if="focusedInput === 'altura'"
                v-bind="defaultOptions.altura"
                @handle-selection="(value) => updateField('altura', value)"
              />
            </b-form-group>
            <span class="unit__description">{{
              componentInfo.favorites.dadosIMCAltura.descUnidade
            }}</span>
          </b-col>
          <b-col cols="2" @click="(event) => event.stopPropagation()">
            <b-form-group label="Imc:" label-for="imc">
              <b-form-input
                autocomplete="off"
                type="text"
                disabled
                name="imc"
                :value="
                  isEdditing ? this.vitalSignBeingEddited.imc : imcInfo.result
                "
              ></b-form-input>
            </b-form-group>
            <span class="unit__description">Kg/m2</span>
          </b-col>
          <b-col cols="3">
            <b-row align-h="center">
              <b-button
                style="width: 80%"
                variant="success"
                :class="`imc imc--${imcInfo.category}`"
                tabindex="-1"
                >{{ imcInfo.classification }}</b-button
              >
            </b-row>
          </b-col>

          <b-col align-self="center">
            <b-row align-v="center" align-h="center" style="margin-left: 0px">
              <template v-if="!isEdditing">
                <b-icon-grid3x3
                  class="mr-2"
                  font-scale="2"
                  variant="primary"
                  @click="selectChart('table', 'imc')"
                ></b-icon-grid3x3>
                <b-icon-graph-up
                  font-scale="2"
                  variant="primary"
                  @click="selectChart('area', 'imc')"
                ></b-icon-graph-up>
              </template>

              <b-button
                v-if="isEdditing"
                class="ml-4 cta-button"
                :variant="getButtonVariant(vitalSignBeingEddited.ativoImc)"
                @click="toggleField('imc')"
                >{{ getButtonText(vitalSignBeingEddited.ativoImc) }}</b-button
              >
            </b-row>
          </b-col>
        </b-row>
        <hr class="line" />
        <div class="dadosAntropometricos">
          <b-row v-if="componentInfo.favorites.dadosMedidasPulso.ativo === 0">
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Pulso:"
                label-for="pulso"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  name="pulso"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.pulso
                      : currentVitalSign(this.idProntuario).pulso
                  "
                  @input="(value) => !isEdditing && updateField('pulso', value)"
                  @focus="setFocused('pulso', 'pulso', 'dadosMedidasPulso')"
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'pulso'"
                  v-bind="defaultOptions.pulso"
                  @handle-selection="(value) => updateField('pulso', value)"
                />
              </b-form-group>
              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasPulso.descUnidade
              }}</span>
            </b-col>

            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'pulso')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'pulso')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  :variant="getButtonVariant(vitalSignBeingEddited.ativoPulso)"
                  @click="toggleField('pulso')"
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoPulso)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-if="componentInfo.favorites.dadosMedidasTemperatura.ativo === 0"
          >
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Temperatura:"
                label-for="temperatura"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.temperatura
                      : currentVitalSign(this.idProntuario).temperatura
                  "
                  @input="
                    (value) => !isEdditing && updateField('temperatura', value)
                  "
                  name="temperatura"
                  @focus="
                    setFocused(
                      'temperatura',
                      'temperatura',
                      'dadosMedidasTemperatura'
                    )
                  "
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'temperatura'"
                  v-bind="defaultOptions.temperatura"
                  @handle-selection="
                    (value) => updateField('temperatura', value)
                  "
                />
              </b-form-group>

              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasTemperatura.descUnidade
              }}</span>
            </b-col>

            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'temperatura')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'temperatura')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  :variant="
                    getButtonVariant(vitalSignBeingEddited.ativoTemperatura)
                  "
                  @click="toggleField('temperatura')"
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoTemperatura)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="componentInfo.favorites.dadosMedidasQuadril.ativo === 0">
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Quadril:"
                label-for="Quadril"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.quadril
                      : currentVitalSign(this.idProntuario).quadril
                  "
                  @input="
                    (value) => !isEdditing && updateField('quadril', value)
                  "
                  name="quadril"
                  @focus="
                    setFocused('quadril', 'quadril', 'dadosMedidasQuadril')
                  "
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'quadril'"
                  v-bind="defaultOptions.quadril"
                  @handle-selection="(value) => updateField('quadril', value)"
                  upper
                />
              </b-form-group>

              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasQuadril.descUnidade
              }}</span>
            </b-col>

            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'quadril')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'quadril')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  :variant="
                    getButtonVariant(vitalSignBeingEddited.ativoQuadril)
                  "
                  @click="toggleField('quadril')"
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoQuadril)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-if="componentInfo.favorites.dadosMedidasPanturilha.ativo === 0"
          >
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Panturrilha:"
                label-for="panturrilha"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.panturilha
                      : currentVitalSign(this.idProntuario).panturilha
                  "
                  @input="
                    (value) => !isEdditing && updateField('panturilha', value)
                  "
                  name="panturilha"
                  @focus="
                    setFocused(
                      'panturilha',
                      'panturilha',
                      'dadosMedidasPanturilha'
                    )
                  "
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'panturilha'"
                  v-bind="defaultOptions.panturilha"
                  @handle-selection="
                    (value) => updateField('panturilha', value)
                  "
                  upper
                />
              </b-form-group>

              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasPanturilha.descUnidade
              }}</span>
            </b-col>

            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'panturilha')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'panturilha')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  @click="toggleField('panturilha')"
                  :variant="
                    getButtonVariant(vitalSignBeingEddited.ativoPanturilha)
                  "
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoPanturilha)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-if="componentInfo.favorites.dadosMedidasAbdominal.ativo === 0"
          >
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Abdome:"
                label-for="abdominal"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.abdominal
                      : currentVitalSign(this.idProntuario).abdominal
                  "
                  @input="
                    (value) => !isEdditing && updateField('abdominal', value)
                  "
                  name="abdominal"
                  @focus="
                    setFocused(
                      'abdominal',
                      'abdominal',
                      'dadosMedidasAbdominal'
                    )
                  "
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'abdominal'"
                  v-bind="defaultOptions.abdominal"
                  @handle-selection="(value) => updateField('abdominal', value)"
                  upper
                />
              </b-form-group>

              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasAbdominal.descUnidade
              }}</span>
            </b-col>
            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'abdominal')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'abdominal')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  :variant="
                    getButtonVariant(vitalSignBeingEddited.ativoAbdominal)
                  "
                  @click="toggleField('abdominal')"
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoAbdominal)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="componentInfo.favorites.dadosMedidasBraco.ativo === 0">
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Braço:"
                label-for="Braço"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.braco
                      : currentVitalSign(this.idProntuario).braco
                  "
                  @input="(value) => !isEdditing && updateField('braco', value)"
                  name="braco"
                  @focus="setFocused('braco', 'braco', 'dadosMedidasBraco')"
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'braco'"
                  v-bind="defaultOptions.braco"
                  @handle-selection="(value) => updateField('braco', value)"
                  upper
                />
              </b-form-group>

              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasBraco.descUnidade
              }}</span>
            </b-col>

            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'braco')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'braco')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  :variant="getButtonVariant(vitalSignBeingEddited.ativoBraco)"
                  @click="toggleField('braco')"
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoBraco)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-if="componentInfo.favorites.dadosMedidasSaturacao.ativo === 0"
          >
            <b-col cols="6" @click="(event) => event.stopPropagation()">
              <b-form-group
                label="Saturação:"
                label-for="Saturação"
                style="position: relative"
              >
                <b-form-input
                  autocomplete="off"
                  :disabled="isEdditing"
                  type="text"
                  class="focusable"
                  @keydown="simulateTab($event)"
                  :value="
                    isEdditing
                      ? this.vitalSignBeingEddited.saturacao
                      : currentVitalSign(this.idProntuario).saturacao
                  "
                  @input="
                    (value) => !isEdditing && updateField('saturacao', value)
                  "
                  name="saturacao"
                  @focus="
                    setFocused(
                      'saturacao',
                      'saturacao',
                      'dadosMedidasSaturacao'
                    )
                  "
                ></b-form-input>

                <scrollable-list
                  v-if="focusedInput === 'saturacao'"
                  v-bind="defaultOptions.saturacao"
                  @handle-selection="(value) => updateField('saturacao', value)"
                  upper
                />
              </b-form-group>

              <span class="unit__description">{{
                componentInfo.favorites.dadosMedidasSaturacao.descUnidade
              }}</span>
            </b-col>

            <b-col style="align-self: center">
              <b-row align-v="center" align-h="center">
                <template v-if="!isEdditing">
                  <b-icon-grid3x3
                    class="mr-2"
                    font-scale="2"
                    variant="primary"
                    @click="selectChart('table', 'saturacao')"
                  ></b-icon-grid3x3>
                  <b-icon-graph-up
                    @click="selectChart('area', 'saturacao')"
                    font-scale="2"
                    variant="primary"
                  ></b-icon-graph-up>
                </template>
                <b-button
                  v-if="isEdditing"
                  class="ml-4 cta-button"
                  :variant="
                    getButtonVariant(vitalSignBeingEddited.ativoSaturacao)
                  "
                  @click="toggleField('saturacao')"
                  >{{
                    getButtonText(vitalSignBeingEddited.ativoSaturacao)
                  }}</b-button
                >
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div style="right: 2vw; bottom: 0vh; position: absolute">
          <template v-if="isEdditing">
            <b-button
              class="mr-3"
              variant="danger"
              @click="cancelEdditing()"
              type="button"
              >Cancelar</b-button
            >

            <b-button @click="editVitalSign()" variant="primary" type="button"
              >Salvar</b-button
            >
          </template>
        </div>
      </div>
      <div class="apexchart-container">
        <apexchart
          v-if="chartType === 'area'"
          :type="this.chartType"
          :series="
            this.chartData.series[0].data.length > 10
              ? sliceChartDataSeries(this.chartData.series, 10)
              : this.chartData.series
          "
          :options="this.chartData.options"
          ref="chart"
        />
        <data-table
          v-else
          :favorites="componentInfo.favorites"
          :header-background="this.componentInfo.corHexadecimal"
          :selected-column="this.chartLabel"
          :items="componentInfo.data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '../../DataTable.vue'
import ScrollableList from '../../shared/ScrollableList.vue'
import * as defOptions from './defaultOptions'

export default {
  name: 'VitalSigns',
  props: ['componentInfo', 'idProntuario'],
  components: {
    DataTable,
    ScrollableList
  },

  mounted() {
    this.$store.commit({
      type: 'MedicalReports/createLocalVitalSign',
      payload: { idProntuario: this.idProntuario }
    })
  },

  data() {
    return {
      defaultOptions: {},

      focusedInput: null,

      isEdditing: false,
      showInactiveVitalSigns: false,
      vitalSignBeingEddited: null,
      unttouchedVitalSignBeingEddited: null,
      vitalSignsBeingShown: [],
      chartType: 'area',
      chartLabel: 'imc'
    }
  },

  methods: {
    getDefaultOptions(unit) {
      const datas = {
        sistolica: 'dadosPressaoSistolica',
        diastolica: 'dadosPressaoDiastolica',
        peso: 'dadosIMCPeso',
        altura: 'dadosIMCAltura',
        pulso: 'dadosMedidasPulso',
        temperatura: 'dadosMedidasTemperatura',
        panturilha: 'dadosMedidasPanturilha',
        braco: 'dadosMedidasBraco',
        quadril: 'dadosMedidasQuadril',
        abdominal: 'dadosMedidasAbdominal',
        saturacao: 'dadosMedidasSaturacao'
      }

      const focusedInputData = this.componentInfo.favorites[datas[unit]]

      this.defaultOptions[unit] = defOptions[unit](focusedInputData)
    },
    sliceChartDataSeries(series, range) {
      const formated = series.map((element) => {
        const reversed = [...element.data].reverse()

        const sliced = reversed.slice(0, range)

        const turnBackReverse = sliced.reverse()

        const object = {
          name: element.name,
          data: turnBackReverse
        }

        return object
      })

      return formated
    },
    sliceChartLabels(dates, range) {
      const reversed = [...dates].reverse()

      const sliced = reversed.slice(0, range)

      const turnBackReverse = sliced.reverse()

      return turnBackReverse
    },
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    },
    createChartSeries() {
      this.vitalSignsBeingShown = []

      if (this.chartLabel === 'pressao') {
        const series = [
          {
            name: 'SISTOLICA',
            data: this.componentInfo.data.reduce((accumulator, vitalSign) => {
              if (this.showInactiveVitalSigns && !!vitalSign.sistolica) {
                accumulator.push(vitalSign.sistolica)
              } else {
                if (vitalSign.ativoPressao === 'S' && !!vitalSign.sistolica) {
                  accumulator.push(vitalSign.sistolica)
                }
              }
              return accumulator
            }, [])
          },
          {
            name: 'DIASTÓLICA',
            data: this.componentInfo.data.reduce((accumulator, vitalSign) => {
              if (this.showInactiveVitalSigns && !!vitalSign.diastolica) {
                accumulator.push(vitalSign.diastolica)
                if (
                  this.vitalSignsBeingShown.findIndex(
                    (vitalSignToCompare) =>
                      vitalSignToCompare.id === vitalSign.id
                  ) < 0
                ) {
                  this.vitalSignsBeingShown.push(vitalSign)
                }
              } else {
                if (vitalSign.ativoPressao === 'S' && !!vitalSign.diastolica) {
                  accumulator.push(vitalSign.diastolica)
                  if (
                    this.vitalSignsBeingShown.findIndex(
                      (vitalSignToCompare) =>
                        vitalSignToCompare.id === vitalSign.id
                    ) < 0
                  ) {
                    this.vitalSignsBeingShown.push(vitalSign)
                  }
                }
              }
              return accumulator
            }, [])
          }
        ]
        return series
      }

      let vitalSignToShow = this.chartLabel.split('')
      vitalSignToShow[0] = vitalSignToShow[0].toUpperCase()
      vitalSignToShow = vitalSignToShow.join('')
      const series = [
        {
          name: this.chartLabel.toUpperCase(),
          data: this.componentInfo.data.reduce((accumulator, vitalSign) => {
            if (this.showInactiveVitalSigns && !!vitalSign[this.chartLabel]) {
              accumulator.push(vitalSign[this.chartLabel])
              if (
                this.vitalSignsBeingShown.findIndex(
                  (vitalSignToCompare) => vitalSignToCompare.id === vitalSign.id
                ) < 0
              ) {
                this.vitalSignsBeingShown.push(vitalSign)
              }
            } else {
              if (
                vitalSign[`ativo${vitalSignToShow}`] === 'S' &&
                !!vitalSign[this.chartLabel]
              ) {
                accumulator.push(vitalSign[this.chartLabel])
                if (
                  this.vitalSignsBeingShown.findIndex(
                    (vitalSignToCompare) =>
                      vitalSignToCompare.id === vitalSign.id
                  ) < 0
                ) {
                  this.vitalSignsBeingShown.push(vitalSign)
                }
              }
            }
            return accumulator
          }, [])
        }
      ]
      return series
    },

    createChartLabels() {
      const labels = []
      if (this.chartLabel === 'pressao') {
        this.componentInfo.data.forEach((vitalSign) => {
          const vitalSignsToshow = ['sistolica', 'diastolica']
          if (
            this.showInactiveVitalSigns &&
            !!vitalSign[vitalSignsToshow[0]] &&
            !!vitalSign[vitalSignsToshow[1]]
          ) {
            labels.push(
              `${new Date(vitalSign.dtInclusao).toLocaleDateString('pt-BR')} ${
                vitalSign.nmProfissional ? ` - ${vitalSign.nmProfissional}` : ''
              }`
            )
          } else if (
            vitalSign.ativoPressao === 'S' &&
            !!vitalSign[vitalSignsToshow[0]] &&
            !!vitalSign[vitalSignsToshow[1]]
          ) {
            labels.push(
              `${new Date(vitalSign.dtInclusao).toLocaleDateString('pt-BR')} ${
                vitalSign.nmProfissional ? ` - ${vitalSign.nmProfissional}` : ''
              }`
            )
          }
        })
      } else {
        this.componentInfo.data.forEach((vitalSign) => {
          let vitalSignToShow = this.chartLabel.split('')
          vitalSignToShow[0] = vitalSignToShow[0].toUpperCase()
          vitalSignToShow = vitalSignToShow.join('')

          if (this.showInactiveVitalSigns && !!vitalSign[this.chartLabel]) {
            labels.push(
              new Date(vitalSign.dtInclusao).toLocaleDateString('pt-BR')
            )
          } else if (
            vitalSign[`ativo${vitalSignToShow}`] === 'S' &&
            !!vitalSign[this.chartLabel]
          ) {
            labels.push(
              new Date(vitalSign.dtInclusao).toLocaleDateString('pt-BR')
            )
          }
        })
      }

      return labels.length > 10 ? this.sliceChartLabels(labels, 10) : labels
    },
    toggleField(field) {
      const translations = {
        peso: 'Peso',
        altura: 'Altura',
        pulso: 'Pulso',
        temperatura: 'Temperatura',
        panturilha: 'Panturilha',
        braco: 'Braco',
        quadril: 'Quadril',
        abdominal: 'Abdominal',
        pressao: 'Pressao',
        imc: 'Imc',
        saturacao: 'Saturacao'
      }

      const fieldToEdit = translations[field]

      this.vitalSignBeingEddited[`ativo${fieldToEdit}`] =
        this.vitalSignBeingEddited[`ativo${fieldToEdit}`] === 'S' ? 'N' : 'S'
    },

    getButtonVariant(isActive) {
      return isActive === 'S' ? 'danger' : 'primary'
    },

    getButtonText(isActive) {
      return isActive === 'S' ? 'Inativar' : 'Ativar'
    },
    setFocused(unit, chartSerie) {
      this.selectChart('area', chartSerie)
      this.focusedInput = unit
      this.getDefaultOptions(unit)
    },
    cancelEdditing() {
      this.isEdditing = false
      this.$store.commit({
        type: 'MedicalReports/editVitalSign',
        payload: { ...this.unttouchedVitalSignBeingEddited }
      })
    },
    selectChart(type, series) {
      this.chartType = type
      this.chartLabel = series
    },

    updateField(field, value) {
      this.focusedInput = ''
      if (
        field === 'diastolica' &&
        value > this.currentVitalSign(this.idProntuario).sistolica
      ) {
        this.$toast.warning('Diastólica não pode ter valor maior que sistólica')
        return
      }

      const newVitalSign = {
        ...this.currentVitalSign(this.idProntuario),
        isNew: true,
        [field]: parseFloat(this.convertCommaToDot(value)) || null
      }

      this.$store.commit({
        type: 'MedicalReports/updateVitalSignField',
        payload: newVitalSign
      })
    },

    handleEdditing(selectedData) {
      this.isEdditing = true

      this.vitalSignBeingEddited = { ...selectedData }
      this.unttouchedVitalSignBeingEddited = { ...selectedData }
    },

    simulateTab(event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        const focusables = document.querySelectorAll('input.focusable')

        const currentFocusIndex = Array.from(focusables).findIndex(
          (focusable) => {
            return focusable.id === event.target.id
          }
        )
        focusables[currentFocusIndex + 1].focus()
      }
    },

    editVitalSign() {
      const requestObject = {
        ...this.vitalSignBeingEddited,
        modified: true
      }

      this.isEdditing = false

      this.$store.commit({
        type: 'MedicalReports/editVitalSign',
        payload: requestObject
      })
    },

    convertCommaToDot(value) {
      if (value.includes(',')) {
        value = value.replaceAll(',', '.')
      }
      return value
    }
  },

  watch: {
    'imcInfo.result': {
      handler: function (newValue, oldValue) {
        this.updateField('imc', newValue)
      }
    }
  },
  computed: {
    ...mapGetters('MedicalReports', [
      'getModuleByIdModule',
      'currentVitalSign',
      'getMedicalReportType'
    ]),
    chartData() {
      return {
        options: {
          dataLabels: {
            style: {
              fontSize: '10px'
            }
          },
          legend: {
            showForSingleSeries: true,
            show: true,
            position: 'top',
            fontSize: '14px',
            fontWeight: 'bold'
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: this.createChartLabels(),
            tickPlacement: 'on',
            labels: {
              show: false
            }
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '10px'
              }
            }
          },
          chart: {
            width: '100%',
            height: '100%',
            zoom: {
              enabled: true,
              type: 'x',
              autoScaleYaxis: true
            },
            events: {
              markerClick: (
                event,
                chartContext,
                { seriesIndex, dataPointIndex, config }
              ) => {
                const selectedData = this.vitalSignsBeingShown[dataPointIndex]

                this.handleEdditing(selectedData)
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        series: this.createChartSeries()
      }
    },
    imcInfo() {
      const peso = this.currentVitalSign(this.idProntuario).peso
      const altura = this.currentVitalSign(this.idProntuario).altura
      if (!peso || !altura) {
        return { result: 0, classification: 'Classificação' }
      }

      const result = (peso / Math.pow(altura, 2)).toFixed(2)
      let classification
      let category

      if (result <= 18.5) {
        classification = 'Magreza'
        category = 'thinness'
      } else if (result > 18.5 && result <= 24.9) {
        classification = 'Normal'
        category = 'normal'
      } else if (result > 24.9 && result <= 29.9) {
        classification = 'Sobrepeso'
        category = 'overpeso'
      } else if (result > 29.9 && result <= 34.9) {
        classification = 'Obesidade I - Leve'
        category = 'obesity_one'
      } else if (result > 34.9 && result <= 39.9) {
        classification = 'Obesidade II - Severa'
        category = 'obesity_two'
      } else {
        classification = 'Obesidade Mórbida'
        category = 'morbid_obesity'
      }

      return { result, classification, category }
    },

    bloodPressureInfo() {
      const { sistolica, diastolica } = this.currentVitalSign(this.idProntuario)
      let category, classification

      if (!sistolica || !diastolica) {
        return { category: 'Classificação' }
      }

      if (sistolica <= 120 && diastolica <= 80) {
        category = 'Normal'
        classification = 'normal'
      } else if (
        (sistolica > 120 && sistolica <= 139) ||
        (diastolica > 80 && diastolica <= 89)
      ) {
        category = 'Pré-Hipertensão'
        classification = 'pre_hypertension'
      } else if (
        (sistolica > 139 && sistolica <= 159) ||
        (diastolica > 89 && diastolica <= 99)
      ) {
        category = 'Hipertensão Estágio 1'
        classification = 'high_stage_one'
      } else if (
        (sistolica > 159 && sistolica <= 180) ||
        (diastolica > 99 && diastolica <= 109)
      ) {
        category = 'Hipertensão Estágio 2'
        classification = 'high_stage_two'
      } else if (sistolica > 180 || diastolica > 109) {
        category = 'Crise Hipertensiva'
        classification = 'emergency'
      }
      return { category, classification }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';
.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content-tab {
  height: 40vh;
}

.dados-form {
  .form-group {
    margin-bottom: 4px !important;
  }

  .col-3,
  .col-2 {
    padding-left: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.line {
  margin: 16px 0;
}
.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}
.details {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    height: 30px;
    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__checkbox-container {
    margin-left: 8px;
    color: #000;
    display: inline-flex;
    align-items: center;
    margin-right: auto;
  }
}
.dados-form {
  background-color: #fff;
  position: relative;
  padding: 0 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 525px;

  > div {
    margin: 8px 0;
    &:nth-child(1),
    &:nth-child(3) {
      &:hover {
        .cta-button {
          display: inline-block !important;
        }
      }
    }
  }
}

.apexchart-container {
  max-height: 525px;
}

.dadosAntropometricos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  > div {
    margin-bottom: 8px;

    &:hover {
      .cta-button {
        display: inline-block !important;
      }
    }
  }
}

.unit {
  &__description {
    color: blue;
  }
}

.cta-button {
  display: none !important;
}

.save__button {
  position: sticky;
  top: 0;
  right: 0;
  margin: 0;
}

.blood-pressure {
  border: none !important;
  color: #000 !important;
  &--normal {
    background: #abebc6 !important;
  }

  &--pre_hypertension {
    background: #fdebd0 !important;
  }

  &--high_stage_one {
    background: #edbb99 !important;
  }

  &--high_stage_two {
    background: #d2b4de !important;
  }

  &--emergency {
    background: #f5b7b1 !important;
  }
}

.imc {
  border: none !important;

  color: #000 !important;
  &--thinness {
    background: #edbb99 !important;
  }
  &--normal {
    background: #abebc6 !important;
  }
  &--overpeso {
    background: #a2d9ce !important;
  }
  &--obesity_one {
    background: #edbb9935 !important;
  }
  &--obesity_two {
    background: #d2b4de !important;
  }
  &--morbid_obesity {
    background: #f5b7b1 !important;
  }
}

@media (min-height: 600px) {
  .content-tab {
    height: 45vh;
  }
}

@media (min-height: 700px) {
  .content-tab {
    height: 50vh;
  }
}

@media (min-height: 800px) {
  .content-tab {
    height: 55vh;
  }
}

@media (min-height: 900px) {
  .content-tab {
    height: 60vh;
  }
}

@media (max-width: 1800px) {
  .line {
    margin: 4px 0;
  }
  .dados-form {
    font-size: 10px;
    max-height: 400px;
    > div {
      margin: 2px 0;
    }
  }

  .apexchart-container {
    max-height: 400px;
  }

  .dadosAntropometricos {
    > div {
      margin-bottom: 2px;
    }
  }
}
</style>
