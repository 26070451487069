<template>
  <div key="medicationHistory">
    <div class="module__wrapper">
      <div class="module__options">
        <b-icon
          font-scale="2"
          class="module__options-icon"
          icon="grid"
          variant="primary"
        ></b-icon>
        <div class="module__info">
          <input
            :class="
              isEditingModuleName
                ? 'module__info-title__editing'
                : 'module__info-title'
            "
            v-model="moduleName"
            :readonly="isEditingModuleName ? false : true"
            maxlength="36"
          />
          <p class="module__info-subtitle">
            Seção {{ module.sequencia + 1 }} de {{ modulesLength }}
          </p>
        </div>
      </div>

      <b-icon
        @click="isEditingModuleName = !isEditingModuleName"
        :icon="this.isEditingModuleName ? 'check2-square' : 'pencil-square'"
        :variant="this.isEditingModuleName ? 'success' : 'primary'"
        font-scale="1.5"
        class="modal-info__edit"
      ></b-icon>

      <div>
        <b-icon
          icon="arrow-down"
          variant="primary"
          font-scale="2"
          class="mr-3"
          @click="swapUp(module)"
        ></b-icon>
        <b-icon
          @click="swapUp(module)"
          icon="arrow-up"
          variant="primary"
          font-scale="2"
        ></b-icon>
      </div>

      <div
        class="modal-info__active-checkbox"
        @click.prevent="toggleExpanded(module.id)"
      >
        <b-form-checkbox switch :checked="module.expanded" />
      </div>
      <div
        class="modal-info__open-checkbox"
        @click.prevent="toggleActive(module.id)"
      >
        <b-form-checkbox switch :checked="module.active" />
      </div>

      <div @click="toggleExpandModule()">
        <b-icon v-if="expanded" icon="chevron-up" font-scale="2"></b-icon>
        <b-icon v-else icon="chevron-down" font-scale="2"></b-icon>
      </div>
    </div>
    <template v-if="expanded">
      <div class="search">
        <p class="search__warning">
          Cadastre os seus favoritos ou itens de acesso rápido
        </p>
        <div style="position: relative">
          <div class="search__group">
            <b-form-input
              type="text"
              class="search__input"
              placeholder="Informe no minimo 5 caracteres"
            />
            <label class="search__label" for="">Pesquisa em CID 10 *</label>
          </div>

          <search-result :results="[]" />
        </div>
      </div>
      <div class="table">
        <b-container fluid>
          <b-table-simple hover>
            <b-thead head-variant="primary">
              <b-tr class="table-header">
                <b-th>Código</b-th>
                <b-th>Descrição</b-th>
                <b-th>Favorito</b-th>
                <b-th>Acesso Rápido</b-th>
                <b-th>Ação</b-th>
              </b-tr>
            </b-thead>

            <b-tbody class="table__body">
              <b-tr :key="favorite.cid" v-for="favorite in module.favorites">
                <b-td>{{ favorite.code }}</b-td>
                <b-td>{{ favorite.name }}</b-td>
                <b-td>
                  <b-form-radio></b-form-radio>
                </b-td>
                <b-td>
                  <b-form-radio></b-form-radio>
                </b-td>
                <b-td>
                  <b-button
                    @click="removeFromAll(module.id, favorite.code)"
                    variant="danger"
                    >Remover</b-button
                  >
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
import moduleMethods from '../../shared/modulesMethods'
import SearchResult from '../../../MedicalReport/shared/SearchResult.vue'

export default {
  name: 'MedicationHistoryUserPreference',
  components: {
    SearchResult
  },
  props: ['module', 'modulesLength'],
  data() {
    return {
      expanded: false,
      isEditingModuleName: false,
      moduleName: this.module.descMeuModulo
    }
  },

  destroyed() {
    if (this.moduleName !== this.module.descMeuModulo) {
      this.changeModuleName(this.module, this.moduleName)
    }
  },
  methods: {
    ...moduleMethods
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalStyles.scss';
@import '../../shared/toolbarStyles.scss';
</style>
