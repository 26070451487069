<template>
  <b-modal
    id="awaiting-list-modal"
    title="Lista de espera"
    header-bg-variant="primary"
    title-class="text-light"
    hide-footer
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    @close="close"
  >
    <b-tabs
      style="
        width: 100%;
        height: max-content;
        background-color: white;
        border-radius: 16px;
        margin-top: 4px;
      "
      active-nav-item-class="bg-primary text-light"
      nav-class="align-items-center p-0"
      align="left"
      justified
    >
      <b-tab>
        <template #title>
          <span class="tab-item-text">Formulário de espera</span>
        </template>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row style="margin-bottom: 10px">
              <b-col md="9">
                <SearchPatient
                  ref="searchInput"
                  :doctor="selectedDoctor"
                  :clinica="selectedClinic.idClinica"
                  @selectedPatient="onSelectPatient"
                  :isNewPatient="false"
                />
              </b-col>

              <b-col md="3">
                <b-form-checkbox v-model="isNewPatient"
                  >Novo Paciente</b-form-checkbox
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label>Nome:</label>
                  <b-form-input
                    ref="patientName"
                    :readonly="!isNewPatient"
                    v-model="schedule.patientName"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />
                  <div class="invalid-feedback">
                    <span>Selecione um paciente</span>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col md="3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label>Convênio:</label>
                  <v-select
                    :options="filteredHealthInsurances"
                    label="nmConvenio"
                    v-model="selectedHealthInsurance"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                  />
                  <div class="invalid-feedback">
                    <span>Selecione um convênio</span>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col md="3">
                <b-form-group label="Celular:" label-for="celular">
                  <ValidationProvider
                    name="celular"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      :readonly="!isNewPatient"
                      autocomplete="noautocomplete"
                      v-model="schedule.celPhone"
                      v-mask="'(##)# #### - ####'"
                      type="text"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>Informe o telefone do paciente</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col md="6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label>Médico:</label>
                  <v-select
                    v-if="isManager"
                    :options="doctors"
                    label="nmUsuario"
                    v-model="selectedDoctor"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                  />

                  <b-form-input
                    v-else
                    readonly
                    v-model="selectedDoctor.nmUsuario"
                  />
                  <div class="invalid-feedback">
                    <span>Selecione um paciente</span>
                  </div>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <label>Clinica:</label>
                <v-select
                  v-if="!isManager"
                  :options="clinics"
                  label="nmClinica"
                  :clearable="false"
                  v-model="selectedClinic"
                />
                <b-form-input
                  v-else
                  readonly
                  :value="user.clinica_usuario[0].nmClinica"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                style="
                  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
                  margin: 20px 0 20px 0;
                "
              >
                <span>Período de preferência</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-row style="display: flex; align-items: center">
                  <b-form-group
                    class="col-md-2 d-flex flex-column align-items-center text-center text-nowrap"
                    label="Selecionar dias úteis"
                  >
                    <b-form-checkbox
                      switch
                      v-model="selectedBusinessDays"
                      @change="toggleBusinessDays($event)"
                    >
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group class="col-md-10">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-checkbox
                        inline
                        v-for="day in dayOptions"
                        :key="day.id"
                        v-model="schedule.selectedDays"
                        :value="day"
                        :class="
                          errors.length > 0 || schedule.selectedDays.length < 1
                            ? ' is-invalid'
                            : ''
                        "
                        >{{ day.description }}
                      </b-form-checkbox>
                      <div class="invalid-feedback">
                        <span>Selecione pelo menos um dia da semana</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col md="3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label>Data inicial:</label>
                  <b-form-input
                    type="date"
                    v-model="schedule.initialDate"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />
                </ValidationProvider>
              </b-col>

              <b-col md="3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label>Data final:</label>
                  <b-form-input
                    type="date"
                    v-model="schedule.finalDate"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  />
                </ValidationProvider>
              </b-col>

              <b-col md="6" style="display: flex; justify-content: center">
                <b-form-group label="Períodos:">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-checkbox
                      inline
                      v-for="period in periodOptions"
                      :key="period.id"
                      v-model="schedule.selectedPeriods"
                      :value="period"
                      :class="
                        errors.length > 0 || schedule.selectedPeriods.length < 1
                          ? ' is-invalid'
                          : ''
                      "
                      >{{ period.description }}
                    </b-form-checkbox>
                    <div class="invalid-feedback">
                      <span>Selecione pelo menos um dia da semana</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" style="display: flex; justify-content: end">
                <b-button variant="primary" type="submit"> Salvar </b-button>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="tab-item-text">Lista de espera</span>
        </template>

        <b-row style="overflow-x: hidden">
          <b-col md="12">
            <WaitingList
              :doctors="doctors"
              :clinics="clinics"
              @close="close()"
              @update="updateCalender()"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayOptions from '../../../Utils/dayOptions'
import SearchPatient from '../../../components/Calendar/SearchPatient.vue'
import WaitingList from './WaitingList.vue'

export default {
  components: {
    SearchPatient,
    WaitingList
  },
  async mounted() {
    this.$bvModal.show('awaiting-list-modal')
    this.schedule.selectedDays = [...this.businessDays]
    this.schedule.selectedPeriods = [...this.periodOptions]
    this.setDefaultDates()
    this.clinics = this.user.clinica_usuario
    this.selectedClinic = this.user.clinica_usuario[0]
    if (this.isManager) {
      await this.getDoctorsByClinic()

      this.schedule.idClinic = this.selectedClinic.idClinica
    } else {
      this.selectedDoctor = {
        nmUsuario: this.user.nome,
        idUsuario: this.user.id,
        ...this.user
      }
      this.doctors.push(this.selectedDoctor)
      this.schedule.idUser = this.user.id
    }
  },
  name: 'WaitingListModal',
  data() {
    return {
      dateToView: new Date().toLocaleDateString('en-CA'),
      dayOptions,
      doctors: [],
      clinics: [],
      healthInsurances: [],
      selectedDoctor: '',
      selectedClinic: '',
      selectedPatient: {
        nome: '',
        id: null
      },
      selectedHealthInsurance: '',
      isNewPatient: false,
      schedule: {
        idPatient: '',
        patientName: '',
        idUser: '',
        idClinic: '',
        initialDate: '',
        finalDate: '',
        selectedDays: [],
        selectedPeriods: [],
        celPhone: ''
      },
      periodOptions: [
        {
          description: 'Manhã',
          id: 1
        },
        {
          description: 'Tarde',
          id: 2
        },
        {
          description: 'Noite',
          id: 3
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getUsersByClinic: 'User/getUsersByClinicAction',
      createWaitingListItem: 'WaitingList/createWaitingListItemAction',
      addPatient: 'Patient/addPatientAction',
      getHealthInsurancesByUser: 'HealthInsurance/getHealthInsurancesByUser'
    }),
    updateCalender() {
      this.$emit('updateCalender')
    },
    async handleGetHealthInsurancesByUser() {
      const response = await this.getHealthInsurancesByUser(
        this.selectedDoctor.id
      )

      this.healthInsurances = response
    },
    async addNewPatient() {
      const response = await this.addPatient({
        nome: this.schedule.patientName,
        telCelular: this.schedule.celPhone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', ''),
        convenioPaciente: [
          {
            id: 0,
            idPaciente: 0,
            idConvenio: this.selectedHealthInsurance.idConvenio,
            status: 0,
            cartaoPaciente: null
          }
        ],
        idClinica: this.schedule.idClinic,
        idUsuario: this.schedule.idUser,
        idUsuarioInclusao: this.user.id
      })

      this.schedule.idPatient = response.data.identificacao
    },
    async onSubmit() {
      if (!this.schedule.idPatient) {
        await this.addNewPatient()
      }
      await this.createWaitingListItem(this.schedule)
      this.$toast.success('Agendamento em espera adicionado com sucesso')
      this.close()
    },
    onSelectPatient(patient) {
      this.selectedPatient = patient
      this.schedule.idPatient = patient.id
      this.schedule.patientName = patient.nome
      this.schedule.celPhone = patient.telCelular
    },
    async getDoctorsByClinic() {
      const users = await this.getUsersByClinic(
        this.user.clinica_usuario[0].idClinica
      )

      const doctors = [...users].filter((element) => {
        return element.perfil.id === 1
      })

      this.doctors = doctors
    },
    close() {
      this.$emit('close')
    },
    toggleBusinessDays(event) {
      if (event) {
        this.addBusinessDaysOnSelectedDays()
      } else {
        this.removeBusinessDaysFromSelectedDays()
      }
    },
    addBusinessDaysOnSelectedDays() {
      this.businessDays.forEach((day) => {
        const index = this.schedule.selectedDays.findIndex((element) => {
          return element.id === day.id
        })

        if (index === -1) {
          this.schedule.selectedDays.push(day)
        }
      })
    },
    removeBusinessDaysFromSelectedDays() {
      this.businessDays.forEach((day) => {
        const index = this.schedule.selectedDays.findIndex(
          (element) => element.id === day.id
        )

        if (index !== -1) {
          this.schedule.selectedDays.splice(index, 1)
        }
      })
    },
    setDefaultDates() {
      const today = new Date()
      const priorDate = new Date(new Date().setDate(today.getDate() + 30))

      this.schedule.initialDate = today.toISOString().substring(0, 10)
      this.schedule.finalDate = priorDate.toISOString().substring(0, 10)
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),
    isManager() {
      return [2, 3].includes(
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil
      )
    },
    filteredHealthInsurances() {
      if (this.selectedPatient?.convenioPaciente) {
        const commonHealthInsurancesBetweenDoctorAndPatient = []

        this.healthInsurances.forEach((doctorHI) => {
          const index = this.selectedPatient.convenioPaciente.findIndex(
            (patientHI) => {
              return patientHI.idConvenio === doctorHI.idConvenio
            }
          )

          if (index !== -1) {
            commonHealthInsurancesBetweenDoctorAndPatient.push(doctorHI)
          }
        })
        return commonHealthInsurancesBetweenDoctorAndPatient
      } else {
        return this.healthInsurances
      }
    },
    businessDays() {
      const businessDays = [...this.dayOptions].filter((day) => {
        return day.description.includes('feira')
      })

      return businessDays
    },
    selectedBusinessDays: {
      get() {
        const selectedBusinessDays = this.businessDays.every((element) => {
          return this.schedule.selectedDays.includes(element)
        })
        return selectedBusinessDays
      },

      set() {}
    }
  },
  watch: {
    filteredHealthInsurances(newValue) {
      if (newValue.length === 1) {
        this.selectedHealthInsurance = newValue[0]
      }
    },
    selectedClinic(newValue) {
      this.schedule.idClinic = newValue.idClinica
    },
    selectedDoctor(newValue) {
      this.schedule.idUser = newValue.idUsuario
      this.handleGetHealthInsurancesByUser(newValue.idUsuario)
    },
    isNewPatient(newValue) {
      this.selectedPatient = {
        nome: '',
        id: null
      }
      this.schedule.idPatient = ''
      this.schedule.patientName = ''
      this.schedule.celPhone = ''

      this.$refs.patientName.focus()
    }
  },
  beforeDestroy() {
    this.$bvModal.hide('awaiting-list-modal')
  }
}
</script>

<style scoped>
.tab-item-text {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  height: 25px;
}
</style>
