<template>
  <div class="table-container">
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Data
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Profissional
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Formulário
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Score
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Visualizar
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          ></b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="form in formList"
          :class="[selectedId === form.id ? 'active' : '']"
          @click="handleFormsToMountGraph(form)"
          :key="form.id"
        >
          <b-td :class="form.ativo === 1 ? 'line-throughed' : ''">
            {{ formatDate(form.dtInclusao) }}
          </b-td>

          <b-td :class="form.ativo === 1 ? 'line-throughed' : ''">
            {{ form.nmProfissional }}
          </b-td>

          <b-td :class="form.ativo === 1 ? 'line-throughed' : ''">
            {{ form.descFormulario }}
          </b-td>

          <b-td :class="form.ativo === 1 ? 'line-throughed' : ''">
            {{ form.pontuarioTotalFormulario }}
          </b-td>

          <b-td>
            <b-button
              @click="$emit('visualizeForm', form)"
              variant="outline-light"
            >
              <i class="fas fa-file-alt fa-2x" style="color: #089bab"></i>
            </b-button>
          </b-td>

          <b-td>
            <b-button @click="$emit('showGraph')" variant="outline-light">
              <b-icon icon="graph-up" variant="primary" scale="1.5" />
            </b-button>
          </b-td>

          <b-td>
            <b-button
              @click="
                form.ativo === 0
                  ? handleRemoveOrDisableForm(form)
                  : handleActivateForm(form)
              "
              :variant="form.ativo === 0 ? 'danger' : 'primary'"
              >{{
                form.isNew
                  ? 'Remover'
                  : form.ativo === 0
                  ? 'Inativar'
                  : 'Ativar'
              }}</b-button
            >
          </b-td>

          <b-modal
            :id="`form_justification_${form.id}`"
            size="lg"
            title="Justificativa"
            header-bg-variant="primary"
            title-class="text-light"
            label-for="justify-input"
            ok-title="Salvar"
          >
            <b-form-group label-for="justify-input">
              <ValidationProvider
                name="justify"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="justify-input"
                  maxlength="150"
                  v-model="justify"
                  :class="
                    errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                  "
                ></b-form-textarea>
                <div class="invalid-feedback" :v-if="justifyNotValid">
                  <span>A justificativa deve conter no mínimo 5 digitos!</span>
                </div>
              </ValidationProvider>
            </b-form-group>

            <p>{{ justify.length }}/150</p>
            <template #modal-footer>
              <b-button
                size="sm"
                variant="danger"
                @click="hideJustifyModal(form.id)"
              >
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="handleDisableForm(form)"
              >
                Salvar
              </b-button>
            </template>
          </b-modal>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  name: 'FormsList',
  props: ['headerColor', 'formList'],
  data() {
    return {
      selectedId: '',
      justify: ''
    }
  },
  computed: {
    justifyNotValid() {
      return this.justify.length < 5
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('pt-BR')
    },
    handleFormsToMountGraph(form) {
      this.selectedId = form.id
      const forms = this.formList.filter((element) => {
        return element.idFormulario === form.idFormulario
      })

      this.$emit('update-chart', forms)
    },
    handleRemoveOrDisableForm(form) {
      if (form.isNew) {
        this.$store.commit({
          type: 'MedicalReports/removeForm',
          payload: form
        })
      } else {
        this.showJustifyModal(form.id)
      }
    },
    handleDisableForm(form) {
      this.$store.commit({
        type: 'MedicalReports/updateFormField',
        payload: {
          ...form,
          ativo: 1,
          justificativaInativacao: this.justify
        }
      })

      this.hideJustifyModal(form.id)
    },
    handleActivateForm(form) {
      this.$store.commit({
        type: 'MedicalReports/updateFormField',
        payload: {
          ...form,
          ativo: 0
        }
      })
    },
    showJustifyModal(formId) {
      this.$bvModal.show(`form_justification_${formId}`)
    },
    hideJustifyModal(formId) {
      this.justify = ''
      this.$bvModal.hide(`form_justification_${formId}`)
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.line-throughed {
  text-decoration: line-through;
}

th {
  font-weight: 400 !important;
  background-color: #089bab;
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

th:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

th:nth-child(1) {
  width: 100px;
}

th:nth-child(2) {
  width: auto;
}

th:nth-child(3) {
  width: auto;
}

th:nth-child(4) {
  width: 100px;
}

th:nth-child(5) {
  width: 100px;
}

th:nth-child(6) {
  width: 70px;
}

th:nth-child(7) {
  width: 100px;
}

.active {
  background-color: rgb(230, 230, 230);
}

@media (min-height: 600px) {
  .table-container {
    height: 40vh;
  }
}

@media (min-height: 700px) {
  .table-container {
    height: 45vh;
  }
}

@media (min-height: 800px) {
  .table-container {
    height: 50vh;
  }
}

@media (min-height: 900px) {
  .table-container {
    height: 55vh;
  }
}
</style>
