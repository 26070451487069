var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"justify-center",attrs:{"id":"user-preferences__modal","title":"Preferências","title-class":"text-light","header-bg-variant":"primary","size":"xl","hide-footer":""}},[_c('div',{staticClass:"medicalreport-type-container"},[_c('h4',[_vm._v("Modo de exibição de prontuário")]),_c('div',{staticClass:"medicalreport-type-buttons-container"},[(_vm.changeMedicalReportTypeLoading)?_c('div',{staticClass:"change-medical-report-type-load"},[_c('b-spinner',{attrs:{"large":"","variant":"primary"}})],1):_vm._e(),_c('b-button',{staticClass:"d-flex align-items-center align-items-center mr-4 pl-4 pr-4",staticStyle:{"width":"250px"},attrs:{"variant":`${_vm.getMedicalReportType === 2 ? 'primary' : 'light'}`},on:{"click":function($event){return _vm.changeMedicalReportType(2)}}},[_c('div',{class:`rounded-circle iq-card-icon ${
            _vm.getMedicalReportType === 2 ? 'bg-light' : 'bg-primary'
          } align-items-center justify-content-center d-flex mr-3`},[_c('i',{staticClass:"fas fa-folder mb-1 ml-1",style:(`color: ${
              _vm.getMedicalReportType === 2 ? '#089bab' : 'white'
            }; font-size: 35px`)})]),_c('span',{staticStyle:{"font-weight":"600","font-size":"14px"}},[_vm._v("Horizontal")])]),_c('b-button',{staticClass:"d-flex align-items-center align-items-center pl-4 pr-4",staticStyle:{"width":"250px"},attrs:{"variant":`${_vm.getMedicalReportType === 1 ? 'primary' : 'light'}`},on:{"click":function($event){return _vm.changeMedicalReportType(1)}}},[_c('div',{class:`rounded-circle iq-card-icon ${
            _vm.getMedicalReportType === 1 ? 'bg-light' : 'bg-primary'
          } align-items-center justify-content-center d-flex mr-3`},[_c('i',{staticClass:"fas fa-list mb-1 ml-1",style:(`color: ${
              _vm.getMedicalReportType === 1 ? '#089bab' : 'white'
            }; font-size: 35px`)})]),_c('span',{staticStyle:{"font-weight":"600","font-size":"14px"}},[_vm._v("Vertical")])])],1)]),_c('div',{staticStyle:{"background-color":"#fff","color":"#000"}},[_c('div',{staticClass:"modal-info"},[_c('p',{staticClass:"modal-info__name"},[_vm._v("Meu prontuário")]),_c('p',{staticClass:"modal-info__edit"},[_vm._v("Editar nome")]),_c('p',{staticClass:"modal-info__sequence"},[_vm._v("Sequência")]),_c('p',{staticClass:"modal-info__open"},[_vm._v("Aberto")]),_c('p',{staticClass:"modal-info__active"},[_vm._v("Ativo")]),_c('p',{staticClass:"modal-info__color"},[_vm._v("Cor")])]),_vm._l((_vm.modules),function(module){return _c(module.userPreferenceComponent,{key:module.id,tag:"component",attrs:{"modulesLength":_vm.modules.length,"module":module}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }