<template>
  <b-modal
    id="modalForwarding"
    size="xl"
    header-bg-variant="primary"
    title="Exportação de PDF"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    @close="close()"
    @hidden="close()"
  >
    <div class="genaretePdf">
      <span>Verifique a baixo os dados do seu emcaminhamento:</span>
      <button @click="generatePDF">
        <b-spinner
          v-if="loading"
          class="alignCenter"
          small
          variant="ligth"
        ></b-spinner>
        <span v-else>Baixar PDF</span>
      </button>
    </div>
    <div style="" id="pdf" class="pdf-container">
      <div class="cabecalho">
        <img
          src="../../../../../assets/images/logo.png"
          alt="iamgem de cabeçalho"
          height="40px"
          width="40px"
        />
        <div class="text-cabecalho">
          <span>doctorpep.com.br</span>
          <span>instagram.com/doctorpepbr</span>
        </div>
      </div>
      <span style="color: #089bab; font-size: 1rem; font-weight: bold"
        >Encaminhamento:</span
      >
      <div class="divider"></div>
      <div>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            font-size: 0.8rem;
            margin-top: 10px;
          "
        >
          <span><strong>Paciente:</strong> {{ paciente.nome }}</span>
          <span
            ><strong>Data de nascimento:</strong>
            {{ formatData(paciente.dtNascimento) }}</span
          >
          <span
            ><strong>Prioridade:</strong>
            {{ getPriorityById(item.idEncaminhamentoPrioridade) }}</span
          >
          <span
            ><strong>Encaminhado em:</strong>
            {{ formatData(item.dtInclusao) }}</span
          >
          <span
            ><strong>Tipo de encaminhamento:</strong>
            {{ getTypeEcam(item.idTipoEncaminhamento) }}</span
          >
          <span
            ><strong>Especialidade:</strong>
            {{ getSpecialtyById(item.idEspecialidade) }}</span
          >
          <span
            ><strong>Medico Solicitante:</strong>
            {{ item.nmProfissionalSolicitante }}</span
          >
          <div
            style="width: 100%; display: flex; flex-direction: column; gap: 8px"
          >
            <span><strong>Histórico da clinica:</strong></span>
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                border-radius: 8px;
                width: 100%;
                padding: 2px 2px;
                height: 150px;
                border: #494949 1px solid;
              "
            >
              <span style="max-width: 1090px; word-wrap: break-word">{{
                item.descHistoricoClinico
              }}</span>
            </div>
          </div>
          <span
            ><strong>Hipótises diagnósticas:</strong> {{ item.descCid }}</span
          >
        </div>
      </div>
      <span
        style="
          color: #089bab;
          font-size: 1rem;
          font-weight: bold;
          margin-top: 10px;
        "
        >Contra-referência:</span
      >
      <div class="divider"></div>
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          font-size: 0.8rem;
          margin-top: 10px;
          width: 100%;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          "
        >
          <span><strong>Profissional:</strong> </span>
          <span style="width: 300px"><strong>Data de avaliação:</strong> </span>
        </div>
        <div
          style="width: 100%; display: flex; flex-direction: column; gap: 8px"
        >
          <span><strong>Parecer médico e exames solicitados:</strong></span>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              border-radius: 8px;
              width: 100%;
              padding: 2px 2px;
              height: 150px;
              border: #494949 1px solid;
            "
          >
            <span style="max-width: 1090px; word-wrap: break-word">{{
              ''
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import html2pdf from 'html2pdf.js'
import moment from 'moment'
export default {
  props: ['item', 'paciente', 'priorities', 'specialties'],
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$bvModal.show('modalForwarding')
  },
  methods: {
    formatData(data) {
      return moment(data).format('DD/MM/YYYY')
    },
    getTypeEcam(data) {
      if (data === 1) {
        return 'Primeira Avaliação'
      } else if (data === 2) {
        return 'Encaminhamento a Pedido'
      } else {
        return 'Referência'
      }
    },
    getSpecialtyById(id) {
      if (id) {
        const specialty = this.specialties.find((element) => {
          return element.id === id
        })

        return specialty.descCbo
      }
    },
    getPriorityById(id) {
      if (id) {
        const priority = this.priorities.find((element) => {
          return element.id === id
        })

        return priority.descPrioridadeEncaminhamento
      }
    },
    async generatePDF() {
      this.loading = true
      const element = document.getElementById('pdf')
      const options = {
        margin: 4,
        filename: 'documento.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      await html2pdf().from(element).set(options).save()
      this.loading = false
      this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.genaretePdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.genaretePdf span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #494949;
}

.genaretePdf button {
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  background-color: #089bab;
  cursor: pointer;
}
.genaretePdf button span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
}
.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cabecalho {
  margin-bottom: 16px;
  padding: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  background-color: #089bab;
  align-items: center;
}

.text-cabecalho {
  display: flex;
  gap: 1px;
  flex-direction: column;
  align-items: flex-start;
}
.text-cabecalho span {
  line-height: 12px;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  color: white;
  font-weight: 500;
  font-style: normal;
}
.divider {
  display: block;
  height: 6px;
  width: 100%;
  background-color: #089bab;
}
</style>
