<template>
  <div>
    <h2 class="mb-0">Bem vindo ao DoctorPEP</h2>
    <div v-if="!sended">
      <p>Informe os dados de contato.</p>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider name="Login" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="codeInput">Código Promocional:</label>
              <input
                ref="login"
                :class="
                  'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
                "
                id="codeInput"
                aria-describedby="codeHelp"
                v-model="codigoPromocionalContato"
                v-uppercase
                required
              />
              <div class="invalid-feedback">
                <span>Informe o Código Promocional</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            vid="name"
            name="name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label for="nameInput">Nome:</label>

              <input
                :class="
                  'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
                "
                id="passwordInput"
                v-model="nomeContato"
                required
              />
              <div class="invalid-feedback">
                <span>Informe o nome</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            vid="email"
            name="email"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label for="emailInput">E-mail:</label>

              <input
                type="email"
                :class="
                  'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
                "
                id="emailInput"
                v-model="emailContato"
                required
              />
              <div class="invalid-feedback">
                <span>Informe o e-mail</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            vid="phone"
            name="phone"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label for="phoneInput">Celular:</label>

              <input
                type="phone"
                :class="
                  'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
                "
                id="phoneInput"
                v-model="celularContato"
                v-mask="'(##) #####-####'"
                required
              />
              <div class="invalid-feedback">
                <span>Informe o e-mail</span>
              </div>
            </div>
          </ValidationProvider>
          <div class="d-inline-block w-100">
            <button
              type="submit"
              :disabled="!canPressButton || loading"
              class="btn btn-primary float-right"
              style="width: 80px"
            >
              <b-spinner v-if="loading" small label="Spinning"></b-spinner>
              <span v-else>Enviar</span>
            </button>
          </div>
          <div class="sign-info">
            <span class="dark-color d-inline-block line-height-2">
              Já tem conta?
              <router-link
                to="/dark/auth/sign-in"
                class="iq-waves-effect pr-4"
                v-if="$route.meta.dark"
              >
                Login
              </router-link>
              <router-link
                to="/auth/sign-in"
                class="iq-waves-effect pr-4"
                v-else
              >
                Login
              </router-link>
            </span>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else style="text-align: center">
      <h4>
        Dados enviados com sucesso!
        <b-icon icon="check" scale="2.0" variant="primary"></b-icon>
      </h4>

      <p style="margin-top: 20px; font-size: 20px; text-align: center">
        Agradecemos o seu contato, nossa equipe dará início ao seu atendimento
        em breve.
      </p>

      <div class="d-inline-block w-100">
        <button
          @click="sended = false"
          class="btn btn-primary float-right"
          style="width: 80px"
        >
          <span>Voltar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import API from '../../../services/index'
export default {
  name: 'PromotionalCodeForm',
  mounted() {
    xray.index()
  },
  data() {
    return {
      nomeContato: '',
      emailContato: '',
      celularContato: '',
      codigoPromocionalContato: '',
      dataContato: new Date().toISOString(),
      loading: false,
      sended: false
    }
  },
  methods: {
    formatPhone(phone) {
      const newPhone = phone.replace(/[^0-9]/g, '')
      return parseInt(newPhone)
    },
    async onSubmit() {
      try {
        this.loading = true
        const {
          nomeContato,
          emailContato,
          celularContato,
          codigoPromocionalContato,
          dataContato
        } = this

        await API.post('/api/ComercialContato/Adicionar', {
          object: {
            nomeContato,
            emailContato,
            celularContato: this.formatPhone(celularContato),
            codigoPromocionalContato,
            dataContato
          }
        })
        this.sended = true
      } catch (e) {
        this.$toast.error(e.response.data.message)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    canPressButton() {
      if (
        this.nomeContato &&
        this.emailContato &&
        this.celularContato &&
        this.codigoPromocionalContato
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
