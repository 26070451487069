<template>
  <b-modal
    id="preview-big"
    header-bg-variant="primary"
    header-text-variant="white"
    hide-footer
    size="xl"
    @close="closeModal"
    :title="descFile"
    :scrollable="true"
    no-close-on-backdrop
  >
    <b-embed src="" id="previewBig"> </b-embed>
  </b-modal>
</template>
<script>
import API from '../../../../../services/index'
export default {
  name: 'PreviewBiggerModal',
  props: ['file'],
  data() {
    return {
      preview: '',
      loading: false,
      descFile: ''
    }
  },
  mounted() {
    this.$bvModal.show('preview-big')
    this.getFileImage(this.file)
  },
  methods: {
    async getFileImage(file) {
      this.loading = true

      if (file.descArquivo && file.idProntuario) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          API.get(
            `/api/ProntuarioHistoricoExame/AwsS3ObtemArquivo/?arquivoname=${file.descArquivo}&prontuario_id=${file.idProntuario}`,
            { responseType: 'blob' }
          )
            .then((res) => {
              this.createFileReader(res.data)
              this.preview = res.data
              this.descFile = file.descArquivo

              this.loading = false
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              this.loading = false

              return this.$toast.error('Falha ao carregar documento')
            })
        }, 1000)
      } else if (file) {
        this.createFileReader(file)
        this.filePreview = file
        this.descFile = file.name
        this.loading = false
      }
    },
    createFileReader(file) {
      const reader = new FileReader()

      reader.onload = function (e) {
        document
          .getElementById('previewBig')
          .setAttribute('src', e.target.result)
      }
      reader.readAsDataURL(file)
    },
    closeModal() {
      this.$emit('closeFileModal')
    }
  }
}
</script>
<style>
#preview-bigger h5 {
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style scoped>
.img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
