<template>
  <div>
    <div class="module__wrapper">
      <div class="module__options">
        <b-icon
          font-scale="2"
          class="module__options-icon"
          icon="grid"
          variant="primary"
        ></b-icon>
        <div class="module__info">
          <input
            :class="
              isEditingModuleName
                ? 'module__info-title__editing'
                : 'module__info-title'
            "
            v-model="moduleName"
            :readonly="isEditingModuleName ? false : true"
            maxlength="36"
          />
          <p class="module__info-subtitle">
            Seção {{ module.sequencia + 1 }} de {{ modulesLength }}
          </p>
        </div>
      </div>

      <b-icon
        @click="isEditingModuleName = !isEditingModuleName"
        :icon="this.isEditingModuleName ? 'check2-square' : 'pencil-square'"
        :variant="this.isEditingModuleName ? 'success' : 'primary'"
        font-scale="1.5"
        class="modal-info__edit"
      ></b-icon>

      <div class="module__icons">
        <b-icon
          @click="swapUp(module)"
          icon="triangle-fill"
          flip-v
          variant="primary"
          font-scale="1.3"
          class="mr-4 ml-1"
        ></b-icon>

        <b-icon
          icon="triangle-fill"
          variant="primary"
          font-scale="1.3"
          @click="swapDown(module)"
        ></b-icon>
      </div>

      <div
        class="modal-info__active-checkbox"
        @click.prevent="toggleExpanded(module)"
      >
        <b-form-checkbox switch :checked="module.aberto === 0" />
      </div>
      <div
        class="modal-info__open-checkbox"
        @click.prevent="toggleActive(module)"
      >
        <b-form-checkbox switch :checked="module.ativo === 0" />
      </div>

      <div class="modal-info__pallete">
        <input
          type="color"
          v-model="module.corHexadecimal"
          @change="changeModuleBackground(module, $event)"
        />
      </div>

      <div @click="toggleExpandModule()">
        <b-icon v-if="expanded" icon="chevron-up" font-scale="2"></b-icon>
        <b-icon v-else icon="chevron-down" font-scale="2"></b-icon>
      </div>
    </div>

    <template v-if="expanded">
      <div class="search">
        <p class="search__warning">
          Cadastre os seus favoritos ou itens de acesso rápido
        </p>
        <div style="position: relative">
          <div class="search__group">
            <div style="position: relative; width: 30%">
              <b-form-input
                type="text"
                class="details__input"
                v-model="filter"
                placeholder="Pesquisar"
              ></b-form-input>
              <span
                v-if="isLoading"
                style="
                  position: absolute;
                  right: 5%;
                  top: 50%;
                  transform: translateY(-50%);
                "
              >
                <b-spinner
                  small
                  key="problems-spinner"
                  variant="primary"
                ></b-spinner>
              </span>
            </div>
          </div>
          <search-result
            style="width: 100%"
            :results="results"
            id-property="idFormulario"
            title-property="descFormulario"
            @on-favorite-chosen="addFormToFavorites"
            @on-quick-access-chosen="addFormToQuickAccess"
          />

          <div
            @click="hideSearchResults"
            v-if="results.length > 0"
            style="
              width: 100vw;
              height: 100vh;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 1;
            "
          ></div>
        </div>
      </div>
      <div class="table">
        <b-container fluid>
          <b-table-simple hover>
            <b-thead head-variant="primary">
              <b-tr class="table-header">
                <b-th>Descrição</b-th>
                <b-th>Favorito</b-th>
                <b-th>Acesso Rápido</b-th>
                <b-th>Ação</b-th>
              </b-tr>
            </b-thead>

            <b-tbody class="table__body">
              <b-tr
                :key="favorite.idFormulario"
                v-for="favorite in module.favorites"
              >
                <b-td>{{ favorite.descFormulario }}</b-td>
                <b-td @click.prevent="addFormToFavorites(favorite)">
                  <b-form-radio
                    value="favorite"
                    v-model="favorite.location"
                  ></b-form-radio>
                </b-td>
                <b-td @click.prevent="addFormToQuickAccess(favorite)">
                  <b-form-radio
                    value="quickAccess"
                    v-model="favorite.location"
                  ></b-form-radio>
                </b-td>
                <b-td>
                  <b-button
                    @click="removeFormFavoriteFromHandBook(favorite)"
                    variant="danger"
                    >Remover</b-button
                  >
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
import moduleMethods from '../../shared/modulesMethods'
import SearchResult from '../../../MedicalReport/shared/SearchResult.vue'
import API from '../../../../services/index'
import { mapGetters } from 'vuex'

export default {
  name: 'FormsUserPreference',
  components: {
    SearchResult
  },
  props: ['module', 'modulesLength'],
  data() {
    return {
      filter: '',
      results: [],
      isEditingModuleName: false,
      moduleName: this.module.descMeuModulo,
      isLoading: false,
      efficientGetApiResult: this.debounce(this.getApiResults, 1000),
      expanded: false
    }
  },

  destroyed() {
    if (this.moduleName !== this.module.descMeuModulo) {
      this.changeModuleName(this.module, this.moduleName)
    }
  },

  computed: {
    ...mapGetters({ user: 'Setting/user' }),
    amoutOfFavorites() {
      return this.module.favorites.reduce((accumulator, favorite) => {
        if (favorite.favorito === 0) {
          accumulator += 1
        }
        return accumulator
      }, 0)
    },
    amountOfQuickAccesses() {
      return this.module.favorites.reduce((accumulator, favorite) => {
        if (favorite.acessoRapido === 0) {
          accumulator += 1
        }
        return accumulator
      }, 0)
    }
  },
  watch: {
    filter() {
      if (this.filter === '') {
        this.results = []
      }

      this.efficientGetApiResult()
    }
  },
  methods: {
    ...moduleMethods,
    hideSearchResults() {
      this.filter = ''
      this.results = []
    },
    async getApiResults() {
      if (this.filter === '') {
        this.isLoading = false
        return 0
      }
      try {
        const idClinic = this.user.clinica_usuario[0].idClinica

        this.isLoading = true
        const apiResponse = await API.get(
          '/api/FavoritoFormularioAvaliacao/ObtemListaPorFormulario',
          {
            params: {
              id_prontuario_preferencia: this.module.id,
              id_clinica: idClinic
            }
          }
        )

        this.isLoading = false
        this.results = apiResponse.data.object
      } catch (error) {
        this.isLoading = false
        this.$toast.error('Nenhum resultado encontrado', {
          position: 'bottom-right'
        })
      }
    },

    debounce(func, wait) {
      let timeout = null

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(func, wait)
      }
    },
    removeFormFavoriteFromHandBook(favorite) {
      this.$store.dispatch({
        type: 'MedicalReports/removeFormFavoriteFromHandBook',
        payload: favorite
      })
    },
    addFormToFavorites(data) {
      if (this.amountOfFavorites >= 5) {
        this.$toast.warning(
          'Quantidade máxima de favoritos já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }

      this.$store.dispatch({
        type: 'MedicalReports/addFormToFavoritesHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.module.id
        }
      })
      this.filter = ''
    },

    addFormToQuickAccess(data) {
      if (this.amountOfQuickAccesses >= 5) {
        this.$toast.warning(
          'Quantidade máxima de acesso rápido já atingida, caso queira adicionar, é necessário remover um'
        )
        return
      }
      this.$store.dispatch({
        type: 'MedicalReports/addFormToQuickAccessHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.module.id
        }
      })

      this.filter = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalStyles.scss';
</style>
