import axios from './index'
import store from '../store/index'

const cookieTime = 60 * 60 * 10

export default {
  login: async ({ login, password }) => {
    try {
      const req = axios.post('api/Usuario/EfetuaLogin', {
        object: { login, senha: password }
      })
      const res = await req

      // object = data of user
      const { object, token, chave_Acesso: accessKey } = res.data

      window.$cookies.set('token', token, cookieTime)
      window.$cookies.set('accessKey', accessKey, cookieTime)
      store.commit('setToken', token)
      store.commit('setAccessKey', accessKey)

      return object
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },
  logout: async () => {
    window.$cookies.remove('token')
    store.commit('setToken', '')
    store.commit('setAccessKey', '')
  },

  verifyAccess: async () => {
    const token = store.getters.getToken
    const accessKey = store.getters.getAccessKey

    const tokenCookie = window.$cookies.get('token')
    const accessKeyCookie = window.$cookies.get('accessKey')

    if (token && accessKey && !tokenCookie && !accessKeyCookie) {
      await refreshAccess()
    }
  },

  recovery({ email }) {}
}

const refreshAccess = async () => {
  const token = store.getters.getToken
  const accessKey = store.getters.getAccessKey

  const req = axios.get('api/ControleSessao/ObtemSessaoAtiva', {
    params: {
      token_atual: token,
      chave_sessao: accessKey
    }
  })

  const res = await req

  const { token: newToken, chave_Acesso: newAccessKey } = res.data

  window.$cookies.set('token', newToken, cookieTime)
  window.$cookies.set('accessKey', newAccessKey, cookieTime)

  store.commit('setToken', newToken)
  store.commit('setAccessKey', newAccessKey)
}
