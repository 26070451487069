<template>
  <div style="overflow: scroll; height: 500px; width: 100%; display: flex">
    <div style="padding-bottom: 10px">
      <div class="custom-row" v-for="field in fields" :key="field">
        <span>{{ field }}</span>
      </div>
    </div>

    <div v-for="(item, index) in items" :key="item.id">
      <column
        :item="item"
        :position="index + 1"
        :altura="altura"
        :dateDum="dateDum"
      />
    </div>

    <div>
      <b-button
        variant="primary"
        @click="addNewColumn"
        style="width: 160px; margin: 12px"
        >Adicionar coluna</b-button
      >
    </div>
  </div>
</template>

<script>
import { uid } from 'uid'
import Column from './Column'
import { mapActions } from 'vuex'
export default {
  name: 'Results',
  props: ['altura', 'results', 'idProntuario', 'idPreNatal', 'dateDum'],
  components: { Column },
  data() {
    return {
      fields: [
        'Consulta',
        'Data',
        'Queixa',
        'IG - DUM/USG',
        'Peso(kg)',
        'IMC',
        'Edema',
        'Pressão arterial(mmHG)',
        'Altura uterina(cm)',
        'Apresentação fetal',
        'BCF/Mov.fetal',
        'Toque, se indicado',
        'Exantema(presença ou relato)'
      ],
      items: []
    }
  },
  mounted() {
    this.items = [...this.results]

    this.addNewColumn()
  },
  methods: {
    ...mapActions({
      getGestationalAge: 'MedicalReports/getGestationalAge'
    }),
    async addNewColumn() {
      const object = {
        tempId: uid(),
        idProntuario: this.idProntuario,
        idPreNatal: this.idPreNatal,
        dtInclusão: new Date().toISOString(),
        dtOcorrencia: new Date().toISOString().substring(0, 10),
        queixa: '',
        igDumUsg: '',
        descIGAtual: 'teste',
        peso: '',
        imc: '',
        edema: '',
        alturaUterina: '',
        apresentacaoFetal: '',
        pressaoArterial: '',
        indicacaoToque: '',
        exantema: '',
        bcf: '',
        idUsuarioInativacao: 0,
        dtInativacao: '',
        justificativaInativacao: '',
        ativo: 0
      }

      object.igDumUsg = await this.getGestationalAge({
        data_dum: this.dateDum,
        data_ocorrencia: object.dtOcorrencia
      })

      this.items.push(object)
    }
  }
}
</script>

<style scoped>
.custom-row {
  display: flex;
  height: 52px;
  width: 160px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 12px;
}
</style>
