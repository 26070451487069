<template>
  <div id="module-vacine">
    <div class="box-vaccine">
      <!-- Area de cards-->
      <div class="vaccines">
        <!-- Area de cards-->
        <div
          class="card-vaccines"
          v-for="(vaccine, index) in getList"
          :key="index"
        >
          <!--Cards-->
          <div class="card-bar">
            <h1>{{ vaccine.descVacina }}</h1>
            <div class="card-bar-buttons">
              <b-button
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                class="button-card"
                variant="outline-light"
                @click="openModal(true, vaccine)"
              >
                <i
                  class="fas fa-pen fa-2x"
                  style="color: #089bab; padding-left: 4px"
                ></i>
              </b-button>
              <b-button
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                class="button-card"
                variant="outline-light"
                @click="deleteVaccine(vaccine.descVacina)"
              >
                <i
                  class="fas fa-trash fa-2x"
                  style="color: #089bab; padding-left: 4px"
                ></i>
              </b-button>
            </div>
          </div>
          <hr />
          <article class="options-vaccines">
            <div class="options-vaccines-info">
              <input
                type="checkbox"
                :checked="vaccine.imunizado === 1 ? true : false"
                disabled
              />
              <span>Imune</span>
            </div>
            <div class="options-vaccines-info">
              <input
                type="checkbox"
                :checked="vaccine.naoIminuzado === 1 ? true : false"
                disabled
              />
              <span>Não Imunizada</span>
            </div>
            <div class="options-vaccines-info">
              <input
                type="checkbox"
                :checked="vaccine.semInformacao === 1 ? true : false"
                disabled
              />
              <span>Sem informação</span>
            </div>
            <div class="options-vaccines-info">
              <span>Data da dose:</span>
              <span>{{ formatData(vaccine.dataDose) }}</span>
            </div>
            <div class="options-vaccines-info">
              <span>Data de Inclusão:</span>
              <span>{{ formatData(vaccine.dtInclusao) }}</span>
            </div>
            <hr />
            <div class="options-vaccines-info">
              <span>OBS:</span>
              <span class="observation-vaccine">{{
                vaccine.observacaoVacina
              }}</span>
            </div>
          </article>
        </div>
      </div>
      <div style="width: 100%">
        <div class="box-config">
          <div class="options-vaccines-info">
            <span>Calendario:</span>
            <div class="options-vaccines-info">
              <input
                type="checkbox"
                :checked="vaccineData.calendarioAdequado === 1 ? true : false"
                @click="handleIsValidCalendario()"
                @change="handleCheckboxChange"
              />
              <span>Adequado</span>
              <input
                type="checkbox"
                :checked="vaccineData.calendarioAdequado === 0 ? true : false"
                @click="handleIsNotValidCalendario()"
                @change="handleCheckboxChange"
              />
              <span>Não adequado</span>
            </div>
          </div>
          <b-button
            variant="primary"
            @click="openModal(false, listVaccinesSUS)"
            style="width: 160px"
          >
            Adicionar vacina
          </b-button>
        </div>
      </div>
      <!-- Caixa de texto de observação geral -->
      <div class="observation">
        <span>Observação geral:</span>
        <textarea
          rows="2"
          v-model="observacaoGeral"
          @input="handleObsevorAllChange"
          :placeholder="vaccineData.observacaoGeral"
        ></textarea>
      </div>
      <ModalVaccine
        v-if="showVaccineModal"
        @closeModal="closeModal"
        :whichModal="whichModal"
        :contentModal="contentModal"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ModalVaccine from './VaccineModal.vue'
import moment from 'moment'
export default {
  name: 'ComponentVaccines',
  components: { ModalVaccine },
  props: ['idProntuario', 'idPreNatal'],
  data() {
    return {
      listVaccinesSUS: [],
      // Itens para o modal
      showVaccineModal: false,
      contentModal: null,
      whichModal: false,
      // Lista de vacinas do paciente e seus dados
      vaccineData: {},
      observacaoGeral: '',
      listaDoses: []
    }
  },
  methods: {
    ...mapGetters({
      getVaccinesPacient: 'MedicalReports/getVaccinesPacient',
      getDataVaccine: 'MedicalReports/getDataVaccine'
    }),
    ...mapActions({
      setValueInObservoAll: 'MedicalReports/setValueInObservoAll',
      setValueInCalander: 'MedicalReports/setValueInCalander',
      deleteVaccineInList: 'MedicalReports/deleteVaccineInList',
      getListVaccinesSUS: 'MedicalReports/getListVaccinesSUS',
      getListaVaccinesPacients: 'MedicalReports/getListaVaccinesPacients'
    }),
    formatData(data) {
      if (data === '' || !data) {
        return 'Sem informação.'
      } else {
        const dataFormatada = moment(data).format('DD/MM/YYYY HH:mm')
        return dataFormatada
      }
    },
    closeModal() {
      this.showVaccineModal = false
    },
    openModal(value, payload) {
      this.contentModal = payload
      this.whichModal = value
      this.showVaccineModal = true
    },
    handleIsValidCalendario() {
      this.vaccineData.calendarioAdequado = 1
    },
    handleIsNotValidCalendario() {
      this.vaccineData.calendarioAdequado = 0
    },
    deleteVaccine(descVacina) {
      this.deleteVaccineInList(descVacina)
    },
    handleCheckboxChange() {
      setTimeout(() => {
        if (this.vaccineData.calendarioAdequado === 1) {
          this.setValueInCalander(1)
        } else if (this.vaccineData.calendarioAdequado === 0) {
          this.setValueInCalander(0)
        }
      }, 1500)
    },
    handleObsevorAllChange() {
      setTimeout(() => {
        this.setValueInObservoAll(this.observacaoGeral)
      }, 1500)
    }
  },
  computed: {
    getVacinas() {
      return this.$store.state.vaccines
    },
    getList() {
      return this.getVaccinesPacient()
    }
  },

  async mounted() {
    // Pega lista de vacinas do api, adciona atributo em todas e passa para a variavel.
    const list = await this.getListVaccinesSUS()
    this.listVaccinesSUS = list

    // Busca as vacinas do paciente e seta no estado global
    await this.getListaVaccinesPacients({
      idProntuario: this.idProntuario,
      idPreNatal: this.idPreNatal
    })

    // Pegando dados do formulario de vacina
    this.vaccineData = this.getDataVaccine()

    // Seta valores nos buttons radios do calendario de vacina
    if (this.vaccineData.calendarioAdequado === null) {
      this.calendarioIsNotValid = false
      this.calendarioIsValid = false
    }
    if (this.vaccineData.calendarioAdequado === 1) {
      this.calendarioIsValid = true
      this.calendarioIsNotValid = false
    } else {
      this.calendarioIsNotValid = true
      this.calendarioIsValid = false
    }
  }
}
</script>
<style scoped>
.box-vaccine {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 10px;
  justify-content: space-between;
}
.vaccines {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  padding-bottom: 10px;
  gap: 32px;
}

.card-vaccines {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #edf6f7;
  border-radius: 10px;
}

.card-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-bar-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.options-vaccines-info {
  display: flex;
  gap: 6px;
  align-items: center;
}
.card-vaccines h1 {
  width: 340px;
  max-width: 340px;
  font-size: 1.1rem;
}

hr {
  border: 1px solid #b5bbbf;
  width: 100%;
}

.options-vaccines {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.options-vaccines input {
  height: 18px;
  width: 18px;
}

.options-vaccines span {
  font-size: 0.9rem;
}

.observation-vaccine {
  display: inline-block;
  width: 330px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.box-config {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-config input {
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.box-config span {
  font-size: 0.9rem;
}

.observation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.observation span {
  font-size: 0.9rem;
}

.observation textarea {
  width: 100%;
  border: 1px solid #b5bbbf;
  padding: 10px;
  border-radius: 10px;
}
</style>
