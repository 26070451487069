import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueApexCharts from 'vue-apexcharts'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMask from 'v-mask'
import vSelect from 'vue-select'
import VueCurrencyInput from 'vue-currency-input'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@babel/polyfill'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueTour from 'vue-tour'

import './Utils/filters'
import './plugins'
import './registerServiceWorker'
require('vue-tour/dist/vue-tour.css')

Sentry.init({
  Vue: Vue,
  dsn: 'https://5669e7039bb64c32820d1bc3667ba6d4@o810485.ingest.sentry.io/5834288',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies)
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.use(VueCurrencyInput)
Vue.use(VueTour)

Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect)
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.directive('uppercase', {
  update(el) {
    const sourceValue = el.value
    const newValue = sourceValue.toUpperCase()

    if (sourceValue !== newValue) {
      el.value = newValue
      el.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

Vue.config.productionTip = false
