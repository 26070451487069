<template>
  <b-container fluid style="margin-top: -40px">
    <b-row>
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        style="margin-bottom: 10px"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title">Filtros</h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="4">
              <v-select
                v-if="isSecretary"
                :clearable="false"
                :options="doctors"
                label="nmUsuario"
                v-model="selectedDoctor"
              />

              <b-form-input
                v-else
                readonly
                type="text"
                :value="selectedDoctor.nmUsuario"
              />
            </b-col>

            <b-col md="4">
              <b-form-input
                v-if="isSecretary"
                readonly
                type="text"
                :value="selectedClinic.nmClinica"
              />

              <v-select
                v-else
                :clearable="false"
                :options="clinics"
                label="nmClinica"
                v-model="selectedClinic"
              />
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-row>

    <b-row style="height: 70vh; overflow-y: auto">
      <iq-card class-name="iq-card-block iq-card-stretch">
        <template v-slot:headerTitle>
          <h4 class="card-title">Meu consultório</h4>
        </template>

        <template v-slot:body>
          <b-row>
            <b-col md="3" lg="3">
              <iq-card>
                <template v-slot:body>
                  <div class="iq-info-box d-flex align-items-center p-3">
                    <div class="info-image mr-3">
                      <img
                        src="../../assets/images/page-img/31.png"
                        class="img-fluid"
                        alt="image-box"
                      />
                    </div>

                    <div class="info-text">
                      <h3>{{ myClinicIndicators.carteiraPaciente }}</h3>

                      <span>Carteira do Paciente</span>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-col>

            <b-col md="6" lg="3">
              <iq-card
                class-name="iq-card-block iq-card-stretch iq-card-height"
              >
                <template v-slot:body>
                  <div class="iq-info-box d-flex align-items-center p-3">
                    <div class="info-image mr-3">
                      <img
                        src="../../assets/images/page-img/33.png"
                        class="img-fluid"
                        alt="image-box"
                      />
                    </div>

                    <div class="info-text">
                      <h3>{{ myClinicIndicators.atendimentoRealizados }}</h3>

                      <span>Atendimentos realizados</span>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-col>

            <b-col md="6" lg="3">
              <iq-card
                class-name="iq-card-block iq-card-stretch iq-card-height"
              >
                <template v-slot:body>
                  <div class="iq-info-box d-flex align-items-center p-3">
                    <div class="info-image mr-3">
                      <img
                        src="../../assets/images/page-img/30.png"
                        class="img-fluid"
                        alt="image-box"
                      />
                    </div>

                    <div class="info-text">
                      <h3>{{ myClinicIndicators.atendimentosPendentes }}</h3>

                      <span>Atendimentos Pendentes</span>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-col>

            <b-col md="6" lg="3">
              <iq-card
                class-name="iq-card-block iq-card-stretch iq-card-height"
              >
                <template v-slot:body>
                  <div class="iq-info-box d-flex align-items-center p-3">
                    <div class="info-image mr-3">
                      <img
                        src="../../assets/images/page-img/32.png"
                        class="img-fluid"
                        alt="image-box"
                      />
                    </div>

                    <div class="info-text">
                      <h3>{{ myClinicIndicators.agendamentosFuturos }}</h3>

                      <span>Atendimentos Futuros</span>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </template>
      </iq-card>

      <b-col sm="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <div
              class="d-flex mt-4 mb-4"
              style="justify-content: space-between; align-items: center"
            >
              <h4 class="card-title mr-3">Meta de Atendimento Diário</h4>
              <b-form-input
                style="text-align: center; width: 80px"
                class="date-input"
                type="text"
                v-mask="'##/####'"
                v-model="selectedDate"
              ></b-form-input>
            </div>
          </template>

          <template v-slot:body>
            <div>
              <div class="chart-container">
                <bar-chart
                  v-if="diaryAttendanceChartOptions"
                  :data="diaryAttendanceChartData"
                  :options="diaryAttendanceChartOptions"
                  ref="chart"
                />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col sm="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <div class="d-flex mt-4 mb-4" style="align-items: center">
              <h4 class="card-title mr-3">
                Total de Atendimentos Por Convênio
              </h4>
              <v-select
                style="width: 200px"
                v-model="convenio2"
                :options="listConvenio2"
                :clearable="true"
              ></v-select>
            </div>
          </template>

          <template v-slot:body>
            <bar-chart
              v-if="totalAttendanceChartOptions"
              :data="totalAttendanceChartOptions.data"
              :options="totalAttendanceChartOptions.options"
              height="350"
              ref="chart"
            />
          </template>
        </iq-card>
      </b-col>

      <b-col sm="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <div class="d-flex mt-4 mb-4" style="align-items: center">
              <h4 class="card-title mr-3">Faturamento por periodo</h4>
              <v-select
                style="width: 200px"
                v-model="convenio"
                :options="listConvenio"
                :clearable="true"
              ></v-select>
            </div>
          </template>

          <template v-slot:body>
            <bar-chart
              v-if="revenuesByPeriodChartOptions"
              :data="revenuesByPeriodChartOptions.data"
              :options="revenuesByPeriodChartOptions.options"
              height="350"
              ref="chart"
            />
          </template>
        </iq-card>
      </b-col>

      <b-col sm="6">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <div class="d-flex mt-4 mb-4" style="align-items: center">
              <h4 class="card-title mr-3">Agenda Por Periodo</h4>
              <b-form-input
                style="text-align: center; width: 80px"
                class="date-input"
                type="text"
                v-mask="'##/####'"
                v-model="selectedDate2"
              ></b-form-input>
            </div>
          </template>

          <template v-slot:body>
            <div>
              <div class="chart-container">
                <pie-chart
                  v-if="scheduleByPeriodChartOptions"
                  :data="scheduleByPeriodChartData"
                  :options="scheduleByPeriodChartOptions"
                  height="350"
                  ref="chart"
                />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col sm="6">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">Ocupação Por Periodo</h4>
          </template>

          <template v-slot:body>
            <div>
              <div class="chart-container">
                <horizontal-bar-chart
                  v-if="occupationByPeriodChartData"
                  :data="occupationByPeriodChartData"
                  :options="occupationByPeriodChartOptions"
                  height="350"
                  ref="chart"
                />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <div style="display: flex; flex-direction: column; width: 100%">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="card-feedback">
              <h4>Avaliação do Paciente</h4>
              <div class="box-filter-cardd-feedback">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    align-items: flex-start;
                  "
                >
                  <label>Profissional:</label>
                  <v-select
                    style="width: 400px"
                    v-model="doctor"
                    label="nmUsuario"
                    :options="doctors2"
                    :clearable="true"
                  ></v-select>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    align-items: flex-start;
                  "
                >
                  <label>Período:</label>
                  <v-select
                    style="width: 200px"
                    :options="selectOptions"
                    v-model="timeFilter"
                    @input="handleFilterFeedBack"
                    :clearable="true"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    align-items: flex-start;
                  "
                >
                  <div style="display: flex; align-items: center; gap: 8px">
                    <b-icon-grid3x3
                      style="cursor: pointer; margin-top: 20px"
                      class="mr-2"
                      font-scale="2"
                      variant="primary"
                      @click="optionsGraphOrTable(false)"
                    ></b-icon-grid3x3>
                    <b-icon-graph-up
                      style="cursor: pointer; margin-top: 20px"
                      font-scale="2"
                      variant="primary"
                      @click="optionsGraphOrTable(true)"
                    ></b-icon-graph-up>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:body>
            <div
              style="
                height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                color: #000;
              "
              v-if="dataList.length === 0"
            >
              <span>Nenhuma avaliação encontrada!</span>
            </div>

            <div v-else>
              <div v-if="showGraph">
                <div class="box-graphs">
                  <iq-card v-if="dataGraph1ChartData && dataGraph1ChartOptions">
                    <template v-slot:headerTitle>
                      <h4>Avaliação Consultório:</h4>
                    </template>
                    <template v-slot:body>
                      <feedback-chart
                        :data="dataGraph1ChartData"
                        :options="dataGraph1ChartOptions"
                        class="responsive-chart"
                        ref="chart"
                      />
                    </template>
                  </iq-card>
                  <iq-card v-if="dataGraph2ChartData && dataGraph2ChartOptions">
                    <template v-slot:headerTitle>
                      <h4>Avaliação Recepção::</h4>
                    </template>
                    <template v-slot:body>
                      <feedback-chart
                        :data="dataGraph2ChartData"
                        :options="dataGraph2ChartOptions"
                        class="responsive-chart"
                        ref="chart"
                      />
                    </template>
                  </iq-card>
                  <iq-card v-if="dataGraph3ChartData && dataGraph3ChartOptions">
                    <template v-slot:headerTitle>
                      <h4>Avaliação Atendimento:</h4>
                    </template>
                    <template v-slot:body>
                      <feedback-chart
                        :data="dataGraph3ChartData"
                        :options="dataGraph3ChartOptions"
                        class="responsive-chart"
                        ref="chart"
                      />
                    </template>
                  </iq-card>
                </div>
              </div>

              <div v-else class="table-container">
                <b-table-simple hover>
                  <b-thead class="thead-fixed">
                    <b-tr>
                      <b-th class="table-header">Data Atendimento</b-th>
                      <b-th class="table-header">Data Avaliação</b-th>
                      <b-th class="table-header">Paciente</b-th>
                      <b-th class="table-header">Consultório</b-th>
                      <b-th class="table-header">Instalações</b-th>
                      <b-th class="table-header">Atendimento recepção</b-th>
                      <b-th class="table-header">Atendimento profissional</b-th>
                    </b-tr>
                  </b-thead>

                  <b-tbody class="table__body">
                    <b-tr v-for="item in dataList" :key="item.id">
                      <b-td>{{ formatData(item.dtInclusao) }}</b-td>
                      <b-td>{{ formatData(item.dtAtendimento) }}</b-td>
                      <b-td>{{ item.nmPaciente }}</b-td>
                      <b-td>{{ item.descClinica }}</b-td>
                      <b-td>
                        <img
                          v-if="item.respostas[0]?.resposta === 3"
                          :src="require('../../assets/images/feliz.png')"
                          alt="Icone de satisfeito"
                          class="emoti"
                        />
                        <img
                          v-if="item.respostas[0]?.resposta === 2"
                          :src="require('../../assets/images/confuso.png')"
                          alt="icone de regular"
                          class="emoti"
                        />
                        <img
                          v-if="item.respostas[0]?.resposta === 1"
                          :src="require('../../assets/images/bravo.png')"
                          alt="icone de insatisfeito"
                          class="emoti"
                        />
                      </b-td>
                      <b-td>
                        <img
                          v-if="item.respostas[1]?.resposta === 3"
                          src="../../assets/images/feliz.png"
                          alt="Icone de satisfeito"
                          class="emoti"
                        />
                        <img
                          v-if="item.respostas[1]?.resposta === 2"
                          src="../../assets/images/confuso.png"
                          alt="icone de regular"
                          class="emoti"
                        />
                        <img
                          v-if="item.respostas[1]?.resposta === 1"
                          src="../../assets/images/bravo.png"
                          alt="icone de insatisfeito"
                          class="emoti"
                        />
                      </b-td>
                      <b-td>
                        <img
                          v-if="item.respostas[2]?.resposta === 3"
                          src="../../assets/images/feliz.png"
                          alt="Icone de satisfeito"
                          class="emoti"
                        />
                        <img
                          v-if="item.respostas[2]?.resposta === 2"
                          src="../../assets/images/confuso.png"
                          alt="icone de regular"
                          class="emoti"
                        />
                        <img
                          v-if="item.respostas[2]?.resposta === 1"
                          src="../../assets/images/bravo.png"
                          alt="icone de insatisfeito"
                          class="emoti"
                        />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </div>
          </template>
        </iq-card>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import API from '../../services/index'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { Bar, Doughnut, Pie } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
} from 'chart.js'
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  ChartDataLabels
)

export default {
  name: 'Dashboard1',
  components: {
    IqCard,
    BarChart: {
      extends: Bar,
      props: ['data', 'options']
      // mounted() {
      //   this.renderChart(this.data, this.options)
      // }
    },
    HorizontalBarChart: {
      extends: Bar,
      props: ['data', 'options']
    },
    PieChart: {
      extends: Pie,
      props: ['data', 'options']
    },
    FeedbackChart: {
      extends: Doughnut,
      props: ['data', 'options']
    }
  },
  async mounted() {
    xray.index()
    this.initComponent()
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const monthFormatted = month < 10 ? '0' + month : '' + month
    this.selectedDate = `${monthFormatted}/${year}`
    this.selectedDate2 = `${monthFormatted}/${year}`

    this.clinics = [
      ...this.user.clinica_usuario,
      { nmClinica: 'TODAS', idClinica: 0 }
    ]
    this.selectedClinic = this.user.clinica_usuario[0]

    if (this.isSecretary) {
      const clinicUsers = await this.getUsersByClinic(
        this.selectedClinic.idClinica
      )
      this.doctors = [
        ...clinicUsers.filter((user) => user.perfil.id === 1),
        { nmUsuario: 'TODOS', idUsuario: 0 }
      ]
      this.selectedDoctor = this.doctors[0]
    } else {
      this.selectedDoctor = {
        idUsuario: this.user.id,
        nmUsuario: this.user.nome
      }
    }
    this.getAllIndicators()
  },

  methods: {
    ...mapActions({
      getUsersByClinic: 'User/getUsersByClinicAction',
      getUsersForClinics: 'Finance/getUsersForClinics'
    }),
    async getAllIndicators() {
      if (this.haveAllDataToGetIndicators) {
        try {
          // Executa as requisições em paralelo
          await Promise.all([
            this.getRevenuesByPeriodAndTotalAttendanceIndicators(),
            this.getOccupationByPeriodIndicators(),
            this.getDiaryAttendanceIndicators(),
            this.getScheduleByPeriodIndicators(),
            this.getMyClinicIndicators()
          ])
        } catch (error) {}
      }
    },
    formatData(data) {
      return moment(data).format('DD/MM/YYYY HH:mm')
    },
    async initComponent() {
      try {
        const response = await API.get(
          'api/FeedbackQuestoes/ObtemListaQuestoesAtiva'
        )
        this.questions = response.data.object.reverse()
      } catch (err) {
        this.$toast.error('Não foi possivel buscar as questões!')
      }
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          1 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          4 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 5
      ) {
        this.doctor = this.user.clinica_usuario[0]
        this.doctors2 = [this.user.clinica_usuario[0]]
      }
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          2 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 3
      ) {
        this.doctors2 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor = this.doctors[0]
      }
      this.updateFeedbackData()
    },

    async handleFilterFeedBack(value) {
      this.isLoading = true
      this.showGraph = this.graphOrTable
      await this.updateFeedbackData()
      this.isLoading = false
    },
    async updateFeedbackData() {
      this.dataGraph1 = null
      this.dataGraph2 = null
      this.dataGraph3 = null

      try {
        const response = await API.get(
          'api/FeedbackAtendimento/ObtemDadosFeedRelatorio',
          {
            params: {
              id_usuario: this.doctor.idUsuario,
              id_questoes:
                this.question === null || this.question === ''
                  ? 0
                  : this.question.id,
              referencia_data: this.timeFilter.value // Apenas o valor do select
            }
          }
        )

        this.dataList = response.data.object || []

        if (this.dataList.length !== 0 && this.dataList !== null) {
          const questions1 = []
          const questions2 = []
          const questions3 = []

          this.dataList.forEach((item) => {
            item.respostas.forEach((resp) => {
              if (resp.idFeedbackQuestao === 1) {
                questions1.push(resp)
              } else if (resp.idFeedbackQuestao === 2) {
                questions2.push(resp)
              } else if (resp.idFeedbackQuestao === 3) {
                questions3.push(resp)
              }
            })
          })

          if (this.question === null || this.question === '') {
            this.setDataGraph1(questions1)
            this.setDataGraph2(questions2)
            this.setDataGraph3(questions3)
          } else if (this.question.id === 1) {
            this.setDataGraph1(questions1)
          } else if (this.question.id === 2) {
            this.setDataGraph2(questions2)
          } else if (this.question.id === 3) {
            this.setDataGraph3(questions3)
          }
        }
      } catch (err) {
        this.dataList = []
        this.$toast.error(err)
      }
    },

    setDataGraph1(list) {
      const otimas = list.reduce(
        (contador, objeto) => (objeto.resposta === 3 ? contador + 1 : contador),
        0
      )
      const boas = list.reduce(
        (contador, objeto) => (objeto.resposta === 2 ? contador + 1 : contador),
        0
      )
      const ruins = list.reduce(
        (contador, objeto) => (objeto.resposta === 1 ? contador + 1 : contador),
        0
      )

      const total = otimas + boas + ruins

      // Calcular porcentagens apenas se houver dados
      const percentOtimo = total > 0 ? ((otimas / total) * 100).toFixed(2) : 0
      const percentRegular = total > 0 ? ((boas / total) * 100).toFixed(2) : 0
      const percentRuim = total > 0 ? ((ruins / total) * 100).toFixed(2) : 0

      // Definir os dados do gráfico
      this.dataGraph1ChartData = {
        labels: ['Ótimo', 'Regular', 'Ruim'],
        datasets: [
          {
            data: [percentOtimo, percentRegular, percentRuim],
            backgroundColor: ['#1ABC9C', '#F1C40F', '#C0392B']
          }
        ]
      }

      // Definir opções do gráfico
      this.dataGraph1ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top'
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.label + ': ' + tooltipItem.raw + '%'
              }
            }
          },
          datalabels: {
            color: '#fff', // Cor dos números (branco)
            formatter: function (value, context) {
              // Verificar se o valor é maior que 0 para exibir apenas valores válidos
              if (parseFloat(value) > 0) {
                return `${value}%`
              }
              return '' // Retorna vazio para valores menores ou iguais a 0
            },
            font: {
              weight: 'bold'
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        aspectRatio: 2,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          }
        }
      }
    },
    setDataGraph2(list) {
      const otimas = list.reduce(
        (contador, objeto) => (objeto.resposta === 3 ? contador + 1 : contador),
        0
      )
      const boas = list.reduce(
        (contador, objeto) => (objeto.resposta === 2 ? contador + 1 : contador),
        0
      )
      const ruins = list.reduce(
        (contador, objeto) => (objeto.resposta === 1 ? contador + 1 : contador),
        0
      )

      const total = otimas + boas + ruins

      // Calcular porcentagens apenas se houver dados
      const percentOtimo = total > 0 ? ((otimas / total) * 100).toFixed(2) : 0
      const percentRegular = total > 0 ? ((boas / total) * 100).toFixed(2) : 0
      const percentRuim = total > 0 ? ((ruins / total) * 100).toFixed(2) : 0

      // Definir os dados do gráfico
      this.dataGraph2ChartData = {
        labels: ['Ótimo', 'Regular', 'Ruim'],
        datasets: [
          {
            data: [percentOtimo, percentRegular, percentRuim],
            backgroundColor: ['#1ABC9C', '#F1C40F', '#C0392B']
          }
        ]
      }

      // Definir opções do gráfico
      this.dataGraph2ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top'
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.label + ': ' + tooltipItem.raw + '%'
              }
            }
          },
          datalabels: {
            color: '#fff', // Cor dos números (branco)
            formatter: function (value, context) {
              // Verificar se o valor é maior que 0 para exibir apenas valores válidos
              if (parseFloat(value) > 0) {
                return `${value}%`
              }
              return '' // Retorna vazio para valores menores ou iguais a 0
            },
            font: {
              weight: 'bold'
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        aspectRatio: 2,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          }
        }
      }
    },
    setDataGraph3(list) {
      const otimas = list.reduce(
        (contador, objeto) => (objeto.resposta === 3 ? contador + 1 : contador),
        0
      )
      const boas = list.reduce(
        (contador, objeto) => (objeto.resposta === 2 ? contador + 1 : contador),
        0
      )
      const ruins = list.reduce(
        (contador, objeto) => (objeto.resposta === 1 ? contador + 1 : contador),
        0
      )

      const total = otimas + boas + ruins

      // Calcular porcentagens apenas se houver dados
      const percentOtimo = total > 0 ? ((otimas / total) * 100).toFixed(2) : 0
      const percentRegular = total > 0 ? ((boas / total) * 100).toFixed(2) : 0
      const percentRuim = total > 0 ? ((ruins / total) * 100).toFixed(2) : 0

      // Definir os dados do gráfico
      this.dataGraph3ChartData = {
        labels: ['Ótimo', 'Regular', 'Ruim'],
        datasets: [
          {
            data: [percentOtimo, percentRegular, percentRuim],
            backgroundColor: ['#1ABC9C', '#F1C40F', '#C0392B']
          }
        ]
      }

      // Definir opções do gráfico
      this.dataGraph3ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top'
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.label + ': ' + tooltipItem.raw + '%'
              }
            }
          },
          datalabels: {
            color: '#fff', // Cor dos números (branco)
            formatter: function (value, context) {
              // Verificar se o valor é maior que 0 para exibir apenas valores válidos
              if (parseFloat(value) > 0) {
                return `${value}%`
              }
              return '' // Retorna vazio para valores menores ou iguais a 0
            },
            font: {
              weight: 'bold'
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        aspectRatio: 2,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          }
        }
      }
    },
    optionsGraphOrTable(value) {
      this.graphOrTable = value
      this.showGraph = value
    },

    async getIndicatorsWithDate() {
      if (this.haveAllDataToGetIndicators) {
        await this.getDiaryAttendanceIndicators()
      }
    },
    getIndicatorsWithDate2() {
      if (this.haveAllDataToGetIndicators) {
        this.getScheduleByPeriodIndicators()
      }
    },
    async getOccupationByPeriodIndicators() {
      this.isLoading = true
      try {
        const occupationByPeriodApiResponse = await API.get(
          '/api/Indicadores/PainelOcupacaoPeriodo',
          {
            params: {
              id_usuario: this.selectedDoctor.idUsuario,
              id_clinica: this.selectedClinic.idClinica
            }
          }
        )
        this.occupationByPeriodIndicators =
          occupationByPeriodApiResponse.data.object
        this.updateOccupationByPeriodChartOptions()
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },
    updateOccupationByPeriodChartOptions() {
      const lastSixMonthsIndicators =
        this.occupationByPeriodIndicators.slice(-6)

      const labels = lastSixMonthsIndicators.map(
        (indicator) => indicator.mesRef
      )
      const dataOccupied = lastSixMonthsIndicators.map((indicator) => {
        const percentualOcupado = parseFloat(indicator.percentualOcupado)
        return isNaN(percentualOcupado) ? 0 : percentualOcupado.toFixed(2)
      })
      const dataEmpty = lastSixMonthsIndicators.map((indicator) => {
        const percentualOcupado = parseFloat(indicator.percentualOcupado)
        return isNaN(percentualOcupado)
          ? 100
          : (100 - percentualOcupado).toFixed(2)
      })

      this.occupationByPeriodChartData = {
        labels,
        datasets: [
          {
            label: 'Porcentagem de Ocupação',
            backgroundColor: '#1ABC9C',
            data: dataOccupied
          },
          {
            label: 'Porcentagem de Vazio',
            backgroundColor: '#2980B9',
            data: dataEmpty
          }
        ]
      }

      this.occupationByPeriodChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            max: 100
          },
          y: {
            stacked: true,
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'top',
            labels: {
              usePointStyle: true
            }
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.dataset.label + ': ' + tooltipItem.raw + '%'
              }
            }
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            formatter: (value) => `${value}%`,
            color: '#fff',
            font: {
              weight: 'bold'
            }
          }
        }
      }
    },
    async getRevenuesByPeriodAndTotalAttendanceIndicators() {
      const response = await API.get(
        '/api/Indicadores/PainelTotalAtendimento',
        {
          params: {
            id_usuario: this.selectedDoctor.idUsuario,
            id_clinica: this.selectedClinic.idClinica
          }
        }
      )

      const data = response.data.object
      this.revenuesByPeriodIndicators = data
      this.totalAttendanceIndicators = data

      // Criando lista de convênios
      this.listConvenio = [
        ...new Set(data.map((element) => element.dadosConvenio[0].descConvenio))
      ]
      this.listConvenio2 = [...this.listConvenio]

      this.updateRevenuesByPeriodChartOptions()
      this.updateTotalAttendanceChartOptions()
    },

    async getDiaryAttendanceIndicators() {
      try {
        const formattedDate = `${this.selectedDate
          .split('/')
          .reverse()
          .join('-')}-01`

        const diaryAttendanceApiResponse = await API.get(
          '/api/Indicadores/PainelMetaAtendimento',
          {
            params: {
              id_usuario: this.selectedDoctor.idUsuario,
              data_ref: formattedDate,
              id_clinica: this.selectedClinic.idClinica
            }
          }
        )

        this.diaryAttendanceIndicators =
          diaryAttendanceApiResponse.data.object[0]

        this.updateDiaryAttendanceChartData()
      } catch (error) {}
    },

    async getScheduleByPeriodIndicators() {
      const formattedDate = `${this.selectedDate2
        .split('/')
        .reverse()
        .join('-')}-01`

      try {
        const scheduleByPeriodApiResponse = await API.get(
          '/api/Indicadores/PainelAgendaPeriodo',
          {
            params: {
              id_usuario: this.selectedDoctor.idUsuario,
              data_ref: formattedDate,
              id_clinica: this.selectedClinic.idClinica
            }
          }
        )

        this.scheduleByPeriodIndicators =
          scheduleByPeriodApiResponse.data.object[0]

        this.updateScheduleByPeriodChartData()
      } catch (error) {}
    },
    async getMyClinicIndicators() {
      try {
        const response = await API.get('api/Indicadores/PainelMeuConsultorio', {
          params: {
            id_usuario: this.selectedDoctor.idUsuario,
            id_clinica: this.selectedClinic.idClinica
          }
        })
        this.myClinicIndicators = response.data.object
      } catch (error) {}
    },

    updateTotalAttendanceChartOptions() {
      const healthInsurances = {}
      const categories = []
      let lastMonths = []
      let datasets = []

      this.totalAttendanceIndicators.sort((a, b) => {
        const [aMonth, aYear] = a.mesRef.split('/')
        const [bMonth, bYear] = b.mesRef.split('/')
        return new Date(aYear, aMonth, 1) - new Date(bYear, bMonth, 1)
      })

      if (this.convenio2) {
        this.totalAttendanceIndicators.forEach(({ dadosConvenio, mesRef }) => {
          const convenioAtual = dadosConvenio[0].descConvenio

          if (convenioAtual === this.convenio2) {
            if (!categories.includes(mesRef)) categories.push(mesRef)

            if (healthInsurances[this.convenio2]) {
              healthInsurances[this.convenio2].push(
                dadosConvenio[0].totalAtendimento
              )
            } else {
              healthInsurances[this.convenio2] = [
                dadosConvenio[0].totalAtendimento
              ]
            }
          }
        })
        lastMonths = categories.slice(-6)
        datasets = [
          {
            label: this.convenio2,
            backgroundColor: this.chartColors[0], // Aqui você pode ajustar o índice conforme necessário
            data: healthInsurances[this.convenio2].slice(-6)
          }
        ]
      } else {
        this.totalAttendanceIndicators.forEach(({ dadosConvenio, mesRef }) => {
          const convenioAtual = dadosConvenio[0].descConvenio
          if (!categories.includes(mesRef)) categories.push(mesRef)

          if (healthInsurances[convenioAtual]) {
            healthInsurances[convenioAtual].push(
              dadosConvenio[0].totalAtendimento
            )
          } else {
            healthInsurances[convenioAtual] = [
              dadosConvenio[0].totalAtendimento
            ]
          }
        })
        lastMonths = categories.slice(-3)
        datasets = Object.keys(healthInsurances).map((convenio, index) => ({
          label: convenio,
          backgroundColor: this.chartColors[index % this.chartColors.length],
          data: healthInsurances[convenio].slice(-3)
        }))
      }

      this.totalAttendanceChartOptions = {
        data: {
          labels: lastMonths,
          datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: false
            },
            y: {
              stacked: false
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true
              }
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.dataset.label + ': ' + tooltipItem.raw
                }
              }
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              formatter: (value) => `${value}`,
              color: '#fff', // Cor dos números (branco)
              font: {
                weight: 'bold'
              }
            }
          }
        }
      }
    },

    updateRevenuesByPeriodChartOptions() {
      const healthInsurances = {}
      const categories = []
      let lastMonths = []
      let datasets = []

      // Mapear convênios para cores
      const convenioColors = {}
      let colorIndex = 0

      this.revenuesByPeriodIndicators.sort((a, b) => {
        const [aMonth, aYear] = a.mesRef.split('/')
        const [bMonth, bYear] = b.mesRef.split('/')
        return new Date(aYear, aMonth, 1) - new Date(bYear, bMonth, 1)
      })

      if (this.convenio) {
        this.revenuesByPeriodIndicators.forEach(({ dadosConvenio, mesRef }) => {
          const convenioAtual = dadosConvenio[0].descConvenio

          if (convenioAtual === this.convenio) {
            if (!categories.includes(mesRef)) categories.push(mesRef)

            if (healthInsurances[this.convenio]) {
              healthInsurances[this.convenio].push(
                dadosConvenio[0].valorTotalConsulta
              )
            } else {
              healthInsurances[this.convenio] = [
                dadosConvenio[0].valorTotalConsulta
              ]
            }
          }
        })
        lastMonths = categories.slice(-6)
        datasets = [
          {
            label: this.convenio,
            backgroundColor: convenioColors[this.convenio] || '#1ABC9C',
            data: healthInsurances[this.convenio].slice(-6)
          }
        ]
      } else {
        this.revenuesByPeriodIndicators.forEach(({ dadosConvenio, mesRef }) => {
          const convenioAtual = dadosConvenio[0].descConvenio
          if (!categories.includes(mesRef)) categories.push(mesRef)

          if (!convenioColors[convenioAtual]) {
            convenioColors[convenioAtual] =
              this.chartColors[colorIndex % this.chartColors.length]
            colorIndex++
          }

          if (healthInsurances[convenioAtual]) {
            healthInsurances[convenioAtual].push(
              dadosConvenio[0].valorTotalConsulta
            )
          } else {
            healthInsurances[convenioAtual] = [
              dadosConvenio[0].valorTotalConsulta
            ]
          }
        })
        lastMonths = categories.slice(-3)
        datasets = Object.keys(healthInsurances).map((convenio) => ({
          label: convenio,
          backgroundColor: convenioColors[convenio],
          data: healthInsurances[convenio].slice(-3)
        }))
      }

      this.revenuesByPeriodChartOptions = {
        data: {
          labels: lastMonths,
          datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: false
            },
            y: {
              stacked: false
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true
              }
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.dataset.label + ': ' + tooltipItem.raw
                }
              }
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              formatter: (value) => `${value}`,
              color: '#fff',
              font: {
                weight: 'bold',
                size: 12
              }
            },
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  yMin: this.diaryAttendanceIndicators.metaRef,
                  yMax: this.diaryAttendanceIndicators.metaRef,
                  borderColor: 'rgb(75, 192, 192)',
                  borderWidth: 2,
                  label: {
                    enabled: true,
                    content: 'Meta de atendimento'
                  }
                }
              }
            }
          },
          // Ajuste de largura e espaçamento das barras
          indexAxis: 'x', // Caso esteja usando barras horizontais
          barPercentage: 1, // Ajuste a largura das barras
          categoryPercentage: 0.8, // Ajuste o espaçamento entre as barras
          barThickness: 'flex' // Define a espessura das barras como flexível
        }
      }
    },
    updateScheduleByPeriodChartData() {
      this.scheduleByPeriodChartData = {
        labels: this.scheduleByPeriodIndicators.dadosAgendaPorTipo.map(
          (schedule) => schedule.tipoAgendamento
        ),
        datasets: [
          {
            label: 'Quantidade de Agendamentos',
            backgroundColor: this.chartColors,
            data: this.scheduleByPeriodIndicators.dadosAgendaPorTipo.map(
              (schedule) => parseFloat(schedule.quantidadeAgendamento)
            )
          }
        ]
      }

      this.updateScheduleByPeriodChartOptions()
    },
    updateScheduleByPeriodChartOptions() {
      this.scheduleByPeriodChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true
            }
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.label}: ${tooltipItem.raw}`
              }
            }
          },
          datalabels: {
            formatter: (value) => `${value}`,
            color: '#fff',
            font: {
              weight: 'bold'
            },
            display: 'auto', // Exibe automaticamente apenas os valores numéricos dentro das fatias
            padding: 20
          }
        }
      }
    },
    updateDiaryAttendanceChartData() {
      const indicators = this.diaryAttendanceIndicators.dadosMetaVm

      this.diaryAttendanceChartData = {
        labels: indicators.map((indicator) => indicator.diaRef),
        datasets: [
          {
            label: 'Meta Atendimento',
            backgroundColor: '#3498DB',
            data: indicators.map((indicator) => indicator.totalAtendimento)
          }
        ]
      }

      this.updateDiaryAttendanceChartOptions()
    },
    updateDiaryAttendanceChartOptions() {
      this.diaryAttendanceChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false,
            beginAtZero: true,
            barThickness: 50, // Define a espessura mínima das barras
            maxBarThickness: 100 // Define a espessura máxima das barras
          },
          y: {
            stacked: false,
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'top',
            labels: {
              usePointStyle: true
            }
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`
              }
            }
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            formatter: (value) => `${value}`,
            color: '#fff',
            padding: {
              top: 8,
              bottom: 4,
              left: 4,
              right: 4
            },
            font: {
              weight: 'bold'
            }
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: this.diaryAttendanceIndicators.metaRef,
                yMax: this.diaryAttendanceIndicators.metaRef,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 2,
                label: {
                  enabled: true,
                  content: 'Meta de atendimento'
                }
              }
            }
          }
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 20,
            bottom: 10
          }
        }
      }
    }
  },
  computed: {
    haveAllDataToGetIndicators() {
      const verif =
        this.selectedDoctor?.idUsuario !== null &&
        this.selectedClinic?.idClinica !== null &&
        this.selectedDate.length === 7 &&
        this.selectedDate2.length === 7

      return verif
    },
    isSecretary() {
      return [2, 3].includes(
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil
      )
    },
    ...mapGetters({
      user: 'Setting/user'
    }),

    activeDiaryAttendance() {
      return Object.keys(this.diaryAttendanceIndicators).length > 0
    },
    chartData() {
      if (!this.diaryAttendanceIndicators.dadosMetaVm) return {}

      return {
        labels: this.diaryAttendanceIndicators.dadosMetaVm.map(
          (indicator) => indicator.diaRef
        ),
        datasets: [
          {
            label: 'Meta de Atendimento',
            data: this.diaryAttendanceIndicators.dadosMetaVm.map(
              (indicator) => indicator.totalAtendimento
            ),
            backgroundColor: '#36a2eb',
            borderColor: '#000'
          }
        ]
      }
    },

    activeScheduleByPeriod() {
      return Object.keys(this.scheduleByPeriodIndicators).length > 0
    },
    activeOccupationByPeriod() {
      return Object.keys(this.occupationByPeriodChartData).labels.length > 0
    }
  },

  watch: {
    selectedDoctor() {
      this.getAllIndicators()
    },
    selectedClinic() {
      this.getAllIndicators()
    },
    selectedDate() {
      this.getIndicatorsWithDate()
    },
    selectedDate2() {
      this.getIndicatorsWithDate2()
    },
    convenio() {
      this.updateRevenuesByPeriodChartOptions()
    },
    convenio2() {
      this.updateTotalAttendanceChartOptions()
    }
  },

  data() {
    return {
      isLoading: false,
      dataList: [],
      graphOrTable: true, // Mostrar gráfico por padrão
      showGraph: true, // Exibir gráfico por padrão
      timeFilter: { label: 'Últimos 30 dias', value: 1 },
      selectOptions: [
        { label: 'Últimos 30 dias', value: 1 },
        { label: 'Últimos 60 dias', value: 2 },
        { label: 'Últimos 90 dias', value: 3 },
        { label: 'Geral', value: 0 }
      ],
      question: '',
      questions: [],
      dataGraph1ChartData: null,
      dataGraph1ChartOptions: null,
      dataGraph2ChartData: null,
      dataGraph2ChartOptions: null,
      dataGraph3ChartData: null,
      dataGraph3ChartOptions: null,
      doctor: '',
      doctors2: [],
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      listConvenio: [],
      listConvenio2: [],
      convenio: '',
      convenio2: '',
      selectedDate: '',
      selectedDate2: '',
      doctors: [],
      selectedDoctor: {
        idUsuario: '',
        nmUsuario: ''
      },
      clinics: [],
      selectedClinic: '',
      chartColors: [
        '#2980B9',
        '#1ABC9C',
        '#C0392B',
        '#27AE60',
        '#9B59B6',
        '#F1C40F',
        '#E67E22',
        '#95A5A6',
        '#34495E',
        '#212F3D'
      ],
      myClinicIndicators: {},
      diaryAttendanceIndicators: null,
      diaryAttendanceChartData: null,
      diaryAttendanceChartOptions: null,
      scheduleByPeriodIndicators: [],
      scheduleByPeriodDate: '',
      scheduleByPeriodChartOptions: '',
      occupationByPeriodIndicators: [],
      occupationByPeriodChartData: null,
      occupationByPeriodChartOptions: null,
      revenuesByPeriodIndicators: [],
      revenuesByPeriodChartOptions: '',
      totalAttendanceIndicators: [],
      totalAttendanceChartOptions: ''
    }
  }
}
</script>

<style scoped>
.date-input {
  width: 80px;
}
.iq-card-body {
  flex: unset;
}
.info-text {
  font-size: 16px;
}
.small-padding {
  padding: -20px, 0, -20px, 0;
}
.table-header {
  background-color: #089bab;
}
.emoti {
  width: 40px;
}
.table-container {
  width: 100%;
  max-height: 400px;
  height: 45vh;
  overflow-x: scroll;
  overflow-y: scroll;
}
th:not(:first-child),
td:not(:first-child) {
  text-align: center;
}
.thead-fixed {
  position: sticky;
  top: 0;
  z-index: 1;
}
.card-feedback {
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.box-filter-cardd-feedback {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 10px;
}
.box-graphs {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.iq-card {
  width: 100%;
}

.responsive-chart {
  width: 100% !important;
  height: auto !important;
}

.button-filter {
  cursor: pointer;
  border-radius: 10px;
  padding: 6px 12px;
  border: #089bab 1px solid;
  background-color: #fff;
  color: #089bab;
}
.button-filter-active {
  border-radius: 10px;
  cursor: pointer;
  padding: 6px 12px;
  border: #089bab 1px solid;
  background-color: #089bab;
  color: #fff;
}
.chart-container {
  position: relative;
  width: 100%;
  height: 400px; /* Defina a altura desejada para o gráfico */
}
@media (max-width: 1024px) {
  .box-graphs {
    flex-direction: column;
  }

  .responsive-chart {
    width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 768px) {
  .responsive-chart {
    width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 480px) {
  .responsive-chart {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
