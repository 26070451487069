<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        vid="name"
        name="Name"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="name">Nome Completo:</label>
          <input
            type="text"
            name="name"
            ref="name"
            v-uppercase
            v-model="name"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="name"
            aria-describedby="nameText"
            placeholder="Digite seu nome completo..."
          />
          <div class="invalid-feedback">
            <span>Informe um nome válido</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="email"
        name="Email"
        rules="required|email"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="email">Email:</label>
          <input
            type="email"
            name="email"
            v-uppercase
            v-model="email"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="email"
            aria-describedby="emailHelp"
            placeholder="Digite seu email..."
          />
          <div class="invalid-feedback">
            <span>Informe um email válido</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="sameLevel">
        <ValidationProvider
          vid="cpf"
          name="CPF"
          rules="required|cpfIsValid"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="cpf">CPF:</label>
            <input
              type="text"
              v-mask="'###.###.###-##'"
              v-model="cpf"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="cpf"
              aria-describedby="cpfNumber"
              placeholder="Digite seu cpf..."
            />
            <div class="invalid-feedback">
              <span>Informe um CPF válido</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          vid="gender"
          name="Gender"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="gender">Sexo:</label>
            <select
              v-model="gender"
              name="gender"
              id="gender"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
            >
              <option
                v-for="gender in genders"
                :key="gender.id"
                v-bind:value="gender.id"
              >
                {{ gender.descSexo }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span>Informe o sexo</span>
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="sameLevel">
        <ValidationProvider
          vid="bithDate"
          name="BirthDate"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="birthDate">Data de Nascimento:</label>
            <input
              type="date"
              v-model="birthDate"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="birthDate"
              aria-describedby="birthDate"
            />
            <div class="invalid-feedback">
              <span>Informe a data de nascimento</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          vid="phone"
          name="Phone"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="phone">Celular:</label>
            <input
              type="text"
              v-mask="'(##)# #### - ####'"
              v-model="phone"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="phone"
              aria-describedby="phoneNumber"
              placeholder="(DD)9 9191 - 9191"
            />
            <div class="invalid-feedback">
              <span>Informe um celular válido</span>
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right buttonFocus">
          Próximo
        </button>
      </div>
      <div class="sign-info2">
        <span class="dark-color d-inline-block line-height-2">
          Já tem conta?
          <router-link
            to="/dark/auth/sign-in"
            class="iq-waves-effect pr-4"
            v-if="$route.meta.dark"
          >
            Login
          </router-link>
          <router-link to="/auth/sign-in" class="iq-waves-effect pr-4" v-else>
            Login
          </router-link>
        </span>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import API from '../../../services'
import { cpf } from 'cpf-cnpj-validator'

export default {
  name: 'SignUpForm',
  props: ['formType'],
  computed: {
    name: {
      get() {
        return this.$store.state.name
      },
      set(value) {
        this.$store.commit('setName', value)
      }
    },
    email: {
      get() {
        return this.$store.state.email
      },
      set(value) {
        this.$store.commit('setEmail', value)
      }
    },
    cpf: {
      get() {
        return this.$store.state.cpf
      },
      set(value) {
        this.$store.commit('setCpf', value)
      }
    },
    gender: {
      get() {
        return this.$store.state.gender
      },
      set(value) {
        this.$store.commit('setGender', value)
      }
    },
    birthDate: {
      get() {
        return this.$store.state.birthDate
      },
      set(value) {
        this.$store.commit('setBirthDate', value)
      }
    },
    phone: {
      get() {
        return this.$store.state.phone
      },
      set(value) {
        this.$store.commit('setPhone', value)
      }
    },
    login: {
      get() {
        return this.$store.state.login
      },
      set(value) {
        this.$store.commit('setLogin', value)
      }
    }
  },
  data: () => ({
    genders: ''
  }),
  mounted() {
    this.$refs.name.focus()
    API.get('/api/Sexo/ObtemLista').then((res) => {
      this.genders = res.data.object
    })
  },
  methods: {
    formatCpf() {
      const newCpf = cpf.strip(this.cpf)
      this.cpf = newCpf
    },
    formatPhone() {
      const newPhone = this.phone?.replace(/[^0-9]/g, '')
      this.phone = parseInt(newPhone)
    },
    dataForm() {
      return {
        name: this.name,
        email: this.email,
        cpf: this.cpf,
        gender: this.gender,
        birthDate: this.birthDate,
        phone: this.phone
      }
    },
    onSubmit() {
      this.formatPhone()
      this.formatCpf()
      if (!this.login) {
        this.login = this.email
      }

      this.$store.dispatch('Setting/addUserDataFormAction', this.dataForm())
      this.$router.replace('/auth/sign-up/page-form2')
    }
  }
}
</script>

<style scoped>
.sameLevel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5%;
}

.sameLevelWithIcon {
  display: grid;
  grid-template-columns: 6.5fr 0.5fr;
  gap: 2%;
  justify-content: center;
  align-items: center;
}

.tooltip {
  margin-left: 10px;
}

.buttonFocus:focus {
  background: #ccc !important;
  color: #089bab !important;
  border: 1px solid #089bab !important;
}
</style>
