<template>
  <div class="module">
    <div class="details">
      <p
        v-if="getMedicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </p>

      <b-form-input
        style="background-color: #fff"
        type="date"
        class="details__date"
        v-if="componentInfo.showDatePicker"
      />

      <favorites-select
        class="details__favorite"
        :favorites="
          componentInfo.favorites.length &&
          componentInfo.favorites.filter((favorite) => favorite.favorito === 0)
        "
        :with-sub-list="false"
        title-property="descTitulo"
        description-property="descHistPessoalFamiliar"
        @on-favorite-chosen="addQuickAccessOrFavoriteToContent"
      />

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>

    <quick-access
      v-if="
        componentInfo.favorites.some((favorite) => favorite.acessoRapido === 0)
      "
      :quick-accesses="
        componentInfo.favorites.length &&
        componentInfo.favorites.filter(
          (favorite) => favorite.acessoRapido === 0
        )
      "
      :with-sub-list="false"
      title-property="descTitulo"
      @on-quick-access-chosen="addQuickAccessOrFavoriteToContent"
    />
    <template v-if="componentInfo.aberto === 0">
      <div class="content">
        <clinic-report
          :initial-content="content"
          :content-to-add="contentToAdd"
          @update-content="updateContent"
          :id="`${componentInfo.id}`"
        />
      </div>
    </template>
  </div>
</template>

<script>
import QuickAccess from '../../shared/QuickAccess.vue'
import FavoritesSelect from '../../shared/FavoritesSelect.vue'

import ClinicReport from '../../shared/ClinicReport.vue'

import API from '../../../../services/index'
import { mapGetters } from 'vuex'
import { formatDateHour } from '@/Utils/formatters'

export default {
  name: 'PersonalAndFamiliarHistory',
  components: { QuickAccess, FavoritesSelect, ClinicReport },
  props: ['componentInfo', 'idProntuario', 'idPaciente', 'idMedico'],
  mounted() {
    const personalAndFamiliarHistory = this.componentInfo.data
    const dataExists = Object.keys(personalAndFamiliarHistory).length > 0
    if (dataExists) {
      this.content = personalAndFamiliarHistory.descHistPessoalFamiliar
      this.date = formatDateHour(personalAndFamiliarHistory.dtInclusao)
      this.contentToAdd = personalAndFamiliarHistory.descHistPessoalFamiliar
    }
  },
  data() {
    return {
      content: '',
      contentToAdd: '',

      date: formatDateHour(),
      filter: '',
      results: [],
      efficientGetApiResult: this.debounce(this.getApiResults, 1000)
    }
  },
  computed: {
    ...mapGetters('MedicalReports', [
      'getPersonalAndFamiliarHistoryByIdPatient',
      'getMedicalReportType'
    ])
  },
  watch: {
    filter() {
      if (this.filter === '') {
        this.results = []
      }

      this.efficientGetApiResult()
    }
  },

  methods: {
    hideSearchResults() {
      this.filter = ''
      this.results = []
    },
    async getApiResults() {
      try {
        const apiResponse = await API.get(
          '/api/FavoritoHistPessoalFamiliar/ObtemListaPorTitulo',
          {
            params: {
              buscaTitulo: this.filter,
              id_prontuario_preferencia: this.componentInfo.id
            }
          }
        )
        this.results = apiResponse.data.object
      } catch (error) {}
    },

    debounce(func, wait) {
      let timeout = null

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(func, wait)
      }
    },
    updateContent(personalHistoryData) {
      this.content = personalHistoryData
      this.$store.commit({
        type: 'MedicalReports/addFamiliarAndPersonalHistory',
        payload: {
          idMedico: this.idMedico,
          idPaciente: this.idPaciente,
          descHistPessoalFamiliar: this.content,
          dtInclusao: formatDateHour(),
          idProntuario: this.idProntuario,
          dtOcorrencia: this.date
        }
      })
    },
    addQuickAccessOrFavoriteToContent(data) {
      this.contentToAdd = `<div>${data.descHistPessoalFamiliar}</div>`
      this.content += `<div>${data.descHistPessoalFamiliar}</div>`

      this.$store.commit({
        type: 'MedicalReports/addFamiliarAndPersonalHistory',
        payload: {
          idMedico: this.idMedico,
          idPaciente: this.idPaciente,
          descHistPessoalFamiliar: this.content,
          dtInclusao: formatDateHour(),
          idProntuario: this.idProntuario,
          dtOcorrencia: this.date
        }
      })

      this.$nextTick(() => {
        this.contentToAdd = ''
      })
      this.filter = ''
    },

    addPersonalAndFamiliarHistoryToFavorites(data) {
      this.$store.dispatch({
        type: 'MedicalReports/addPersonalAndFamiliarHistoryToFavoritesHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })

      this.filter = ''
    },

    addPersonalAndFamiliarHistoryToQuickAccess(data) {
      this.$store.dispatch({
        type: 'MedicalReports/addPersonalAndFamiliarHistoryToQuickAccessHandBook',
        payload: {
          ...data,
          idProntuarioPreferencia: this.componentInfo.id
        }
      })

      this.filter = ''
    },
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';
.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 12px;
}
.module {
  margin: 32px 0;

  &__icon {
    align-self: center;
  }
}

.custom-select {
  max-width: 25%;
}

.details {
  display: flex;

  &__name {
    font-size: 14px;

    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    margin-right: auto;

    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__date {
    max-width: 15%;
    display: block;
    line-height: 1 !important;
    height: auto !important;
  }

  &__favorite {
    margin-left: auto;
  }

  &__input {
    &::placeholder {
      color: #000 !important;
      text-transform: none;
    }
    border-color: rgb(8, 155, 171) !important;

    background-color: white !important;
  }
}

.degree-list {
  display: flex;
  padding: 0;
  margin: 0;
  color: skyblue;
  font-weight: bold;

  &__item {
    cursor: pointer;
  }

  &__item:nth-child(1) {
    background-color: #eafaf1;
    padding: 8px;
  }
  &__item:nth-child(2) {
    background-color: #fef5e7;
    padding: 8px;
  }
  &__item:nth-child(3) {
    background-color: #fdedec;
    padding: 8px;
  }
}
</style>
