import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Chat from './Chat/index'
import Clinic from './Clinic/index'
import HealthInsurance from './HealthInsurance/index'
import Patient from './Patient/index'
import User from './User/index'
import Schedule from './Schedule/index'
import MedicalReports from './MedicalReports/index'
import Finance from './Finance/index'
import DigitalAssign from './DigitalAssign'
import Forms from './Forms'
import Forwardings from './Forwardings'
import Notifications from './Notifications'
import Video from './Video'
import ExportPdf from './ExportPdf'
import WaitingList from './WaitingList'
import ASO from './ASO'
import createPersistedState from 'vuex-persistedstate'
import Acuity from './Acuity'
import ReportSchedule from './ReportSchedule'
import Tasks from './Tasks'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Chat,
    Clinic,
    HealthInsurance,
    Patient,
    User,
    Schedule,
    DigitalAssign,
    MedicalReports,
    Forms,
    Forwardings,
    Notifications,
    Video,
    ExportPdf,
    WaitingList,
    ASO,
    Finance,
    ReportSchedule,
    Acuity,
    Tasks
  },
  state: {
    isPrimaryAcess: false,
    name: '',
    email: '',
    cpf: '',
    gender: '',
    birthDate: '',
    phone: '',
    login: '',
    council: '',
    numCouncil: '',
    councilState: '',
    especialidade: '',
    password: '',
    token: '',
    accessKey: ''
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],

  mutations: {
    setName(state, data) {
      state.name = data
    },
    setEmail(state, data) {
      state.email = data
    },
    setCpf(state, data) {
      state.cpf = data
    },
    setGender(state, data) {
      state.gender = data
    },
    setBirthDate(state, data) {
      state.birthDate = data
    },
    setPhone(state, data) {
      state.phone = data
    },
    setLogin(state, data) {
      state.login = data
    },
    setCouncil(state, data) {
      state.council = data
    },
    setNumCouncil(state, data) {
      state.numCouncil = data
    },
    setEspecialidade(state, data) {
      state.especialidade = data
    },
    setCouncilState(state, data) {
      state.councilState = data
    },
    setPassword(state, data) {
      state.password = data
    },
    resetState(state, data) {
      state.name = data
      state.email = data
      state.cpf = data
      state.gender = data
      state.birthDate = data
      state.phone = data
      state.login = data
      state.council = data
      state.numCouncil = data
      state.councilState = data
      state.especialidade = data
      state.password = data
    },
    setToken(state, data) {
      state.token = data
    },
    setAccessKey(state, data) {
      state.accessKey = data
    }
  },
  actions: {
    getIsPrimaryAcess({ state }, payload) {
      state.isPrimaryAcess = payload
    }
  },
  getters: {
    getValueIsPrimaryAcess(state) {
      return state.isPrimaryAcess
    },
    getToken(state) {
      return state.token
    },
    getAccessKey(state) {
      return state.accessKey
    },
    getEmail(state) {
      return state.email
    }
  },
  strict: debug
})
