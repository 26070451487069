<template>
  <div style="width: 100%; height: 30px">
    <b-form-group>
      <div class="iq-search-bar">
        <form action="#" class="searchbox">
          <input
            :disabled="isDisabled"
            ref="input"
            autocomplete="noautocomplete"
            type="text"
            @input="searchPatient($event)"
            @keydown.enter.prevent="searchPatient($event)"
            v-model="patient"
            class="text search-input"
            placeholder="Busca por nome, mínimo de 5 caracteres, ou CPF completo."
          />

          <div class="search-link">
            <i v-if="!loading" class="ri-search-line"></i>
            <b-spinner
              v-else
              class="alignCenter"
              small
              variant="primary"
            ></b-spinner>
          </div>
        </form>
      </div>
      <iq-card>
        <div
          @click="showPatients = false"
          v-if="showPatients && searchedPatient.length > 0"
          style="
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
          "
        ></div>
        <div
          class="searchResults"
          v-if="showPatients && searchedPatient.length > 0"
        >
          <div class="headerSearch">
            <span>Nome</span>
            <span>CPF/PASSAPORTE/CNS</span>
            <span>Data de Nascimento</span>
          </div>
          <div
            class="patientItem"
            v-for="patient in searchedPatient"
            :key="patient.id"
            @click="selectPatient(patient)"
          >
            <span>{{ patient.nome }}</span>
            <span>{{ patient.cpf || patient.passaporte || patient.cns }}</span>
            <span> {{ formatDate(patient.dtNascimento, 'pt-BR') }}</span>
          </div>
        </div>
      </iq-card>
    </b-form-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import API from '../../services/index'

export default {
  props: ['doctor', 'clinica', 'isNewPatient', 'isDisabled'],
  name: 'SearchPatient',
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      patient: '',
      searchedPatient: '',
      showPatients: false,
      loading: false
    }
  },
  mounted() {
    this.$emit('focusInput')
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  watch: {
    isNewPatient(newValue) {
      if (newValue) {
        this.patient = ''
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    async searchPatient(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        const filter = event.target.value.toUpperCase()

        if (!filter) {
          this.showPatients = false
        } else {
          if (filter.length < 5) {
            this.showPatients = false
          } else {
            this.loading = true
            const urlToFetchData = `api/Paciente/ObtemListaPorFiltro${
              this.user.perfil_usuario[this.user.selectedProfileIndex]
                .idPerfil !== 1
                ? 'Clinica'
                : 'Medico'
            }/?${
              this.user.perfil_usuario[this.user.selectedProfileIndex]
                .idPerfil !== 1
                ? `id_clinica=${this.user.clinica_usuario[0].idClinica}`
                : `id_usuario=${this.user.id}`
            }&paciente=${filter}`
            API.get(urlToFetchData, this.authToken)
              .then((res) => {
                if (res.data.object.length === 0) {
                  this.showPatients = false
                  this.$toast.error('Paciente não encontrado!')
                  this.loading = false
                } else if (res.data.object.length > 10) {
                  this.showPatients = false
                  this.$toast.info(
                    'A pesquisa retornou muitas correspondências, por favor especifique mais os dados!'
                  )
                  this.loading = false
                } else {
                  this.searchedPatient = res.data.object
                  this.searchedPatient.map((patient) => {
                    patient.cpf = patient.cpf?.toString()
                    patient.telCelular = patient.telCelular?.toString()
                    patient.telResidencial = patient.telResidencial?.toString()

                    return patient
                  })
                  this.showPatients = true
                  this.loading = false
                }
              })
              // eslint-disable-next-line handle-callback-err
              .catch(() => {
                this.showPatients = false
                this.loading = false
              })
          }
        }
      }, 1000)
    },
    formatDate(date, format) {
      if (date) {
        const newDateFormat = new Date(date)
        return newDateFormat.toLocaleDateString(format)
      } else {
        return 'Não registrado'
      }
    },
    selectPatient(patient) {
      this.patient = patient.nome
      this.$emit('selectedPatient', patient)
      this.showPatients = false
    }
  }
}
</script>

<style scoped>
.alignCenter {
  vertical-align: middle !important;
}
.searchResults {
  top: 30px;
  z-index: 10;
  position: absolute;
  width: 700px;
  height: auto;
  max-height: 400px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
}

.headerSearch {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: 30px;
  gap: 5px;
  background: #089bab;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  color: #fff;
  font-weight: bold;
}

.patientItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  border-top: 1px solid #333;
  height: auto;
  padding: 10px;
}

.patientItem:hover {
  background: #d1edf0;
  cursor: pointer;
}

@media (max-width: 998px) {
  .searchResults {
    top: 30px;
    z-index: 10;
    position: absolute;
    width: 420px;
    height: auto;
    max-height: 400px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    overflow-y: auto;
  }

  .patientItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 0.6rem;
    border-top: 1px solid #333;
    height: auto;
    padding: 10px;
  }

  .headerSearch {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 30px;
    gap: 10px;
    background: #089bab;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    font-size: 0.6rem;

    color: #fff;
    font-weight: bold;
  }
}

@media (max-width: 490px) {
  .searchResults {
    width: 360px;
  }
}

@media (max-width: 427px) {
  .searchResults {
    width: 300px;
  }
}
</style>
