<template>
  <div class="background">
    <b-modal id="modal-1" title="BootstrapVue">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
    <div class="modal-container">
      <div class="header">
        <span class="title">Formulário</span>
        <button class="close" aria-label="Close" @click="$emit('close')">
          <span aria-hidden="true" style="color: white; margin-right: 8px"
            >&times;</span
          >
        </button>
      </div>
      <div class="body">
        <div class="title-container">
          <span class="title">{{ form.titulo }}</span>
        </div>

        <div
          class="question-container"
          v-for="(question, index) in questions"
          :key="index"
        >
          <div style="display: flex; align-items: center">
            <span class="question">
              {{ index + 1 }} - {{ question.descPergunta }}</span
            >
            <div
              class="guidance-container"
              :title="question.orientacaoPergunta"
            >
              <b-icon
                :id="`popover-${question.randomkey}`"
                class="h5 mb-1 ml-3"
                style="cursor: pointer"
                icon="question-circle-fill"
                variant="primary"
                v-if="question.orientacaoPergunta"
              />
            </div>
          </div>

          <b-form-group class="mt-2" v-if="question.idTipoResposta === 1">
            <b-form-textarea
              type="text"
              rows="3"
              max-rows="6"
              style="line-height: 24px"
              v-model="question.answer"
              @keyup="inputTimeout($event, question)"
              :disabled="disabled"
            />
          </b-form-group>

          <b-form-group
            class="col-md-2"
            v-else-if="question.idTipoResposta === 2"
            :no-wheel="true"
          >
            <b-form-input
              v-uppercase
              type="number"
              :no-wheel="true"
              v-model="question.answer"
              :disabled="disabled"
            />
          </b-form-group>

          <b-form-radio-group
            v-else-if="question.idTipoResposta === 3"
            stacked
            :disabled="disabled"
            v-model="question.answer"
            @change="includeMultipleChoiceAnswer($event, question)"
            :options="question.multipleChoice"
            text-field="descOpcaoResposta"
            value-field="id"
            class="multiple-choice"
          />

          <b-form-checkbox-group
            v-else-if="question.idTipoResposta === 4"
            stacked
            :disabled="disabled"
            v-model="question.answer"
            @change="includeMultipleChoiceAnswer($event, question)"
            :options="question.multipleChoice"
            text-field="descOpcaoResposta"
            value-field="id"
            class="multiple-choice"
          />

          <b-form-select
            class="col-md-6 mb-2 multiple-choice"
            v-else-if="question.idTipoResposta === 5"
            :disabled="disabled"
            v-model="question.answer"
            @change="includeMultipleChoiceAnswer($event, question)"
            :options="question.multipleChoice"
            text-field="descOpcaoResposta"
            value-field="id"
          />

          <b-form-row
            class="col-md-12"
            v-else-if="question.idTipoResposta === 6"
          >
            <b-form-input
              class="col-md-2 ml-1"
              type="date"
              :disabled="disabled"
            />
            <b-form-input
              class="col-md-2 ml-2"
              type="time"
              :disabled="disabled"
            />
          </b-form-row>
        </div>
      </div>
      <div class="footer">
        <b-progress :max="maxScore" height="2rem">
          <b-progress-bar :value="score">
            <span
              >Pontuação: <strong>{{ score }} / {{ maxScore }}</strong>
            </span>
          </b-progress-bar>
        </b-progress>
        <div class="buttons-container" v-if="filling">
          <b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
          <b-button variant="primary" @click="saveAnswers">Salvar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateHour } from '@/Utils/formatters'

export default {
  name: 'FormFillingModal',
  props: ['form', 'questions', 'idProntuario', 'medicName', 'filling'],

  data() {
    return {
      alreadyFilled: false,
      disabled: false,
      answers: [],
      score: 0,
      maxScore: this.form.scoreTotalPossivel,
      timer: ''
    }
  },

  mounted() {
    if (this.form.answers) {
      this.alreadyFilled = true
      this.answers = this.form.answers
      this.includeAnswerOnQuestions()
    }
    if (!this.filling) {
      this.disabled = true
    }
  },

  watch: {
    answers() {
      let sum = 0
      this.answers.forEach((element) => {
        sum += element.pontuacaoResposta
      })

      this.score = sum
    }
  },

  methods: {
    includeMultipleChoiceAnswer(evt, question) {
      if (Array.isArray(evt)) {
        this.removeAnswersByIdQuestion(question.id)
        this.$nextTick(() => {
          this.includeArrayMultipleChoiceAnswer(evt, question)
        })
      } else {
        this.includeUniqueMultipleChoiceAnswer(evt, question)
      }
    },
    includeUniqueMultipleChoiceAnswer(evt, question) {
      const selectedAnswer = question.multipleChoice.find((element) => {
        return element.id === evt
      })

      const object = {
        idPerguntaFormulario: selectedAnswer.idPergunta,
        idRespostaFormulario: selectedAnswer.id,
        descRespostaFormulario: selectedAnswer.descOpcaoResposta,
        pontuacaoResposta: selectedAnswer.pesoResposta
      }

      const index = this.answers.findIndex((element) => {
        return element.idPerguntaFormulario === question.id
      })

      index !== -1 ? this.editAnswer(object, index) : this.addAnswer(object)
    },

    includeArrayMultipleChoiceAnswer(evt, question) {
      const selectedAnswers = [...evt].map((id) => {
        const selectedAnswer = question.multipleChoice.find((element) => {
          return element.id === id
        })

        return selectedAnswer
      })

      selectedAnswers.forEach((selectedAnswer) => {
        const object = {
          idPerguntaFormulario: selectedAnswer.idPergunta,
          idRespostaFormulario: selectedAnswer.id,
          descRespostaFormulario: selectedAnswer.descOpcaoResposta,
          pontuacaoResposta: selectedAnswer.pesoResposta
        }

        this.$nextTick(() => {
          this.addAnswer(object)
        })
      })
    },

    inputTimeout(evt, question) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.includeCommonAnswer(evt, question)
      }, 1000)
    },

    includeCommonAnswer(evt, question) {
      const answer = question.answers[0]

      const object = {
        idPerguntaFormulario: answer.idPergunta,
        descRespostaFormulario: evt.target.value,
        pontuacaoResposta: answer.pesoResposta
      }

      const index = this.answers.findIndex((element) => {
        return element.idPerguntaFormulario === question.id
      })

      index !== -1 ? this.editAnswer(object, index) : this.addAnswer(object)
    },

    addAnswer(answer) {
      this.answers.push(answer)
    },

    editAnswer(answer, index) {
      this.answers.splice(index, 1, answer)
    },

    removeAnswersByIdQuestion(idQuestion) {
      const removed = this.answers.filter((element) => {
        return element.idPerguntaFormulario !== idQuestion
      })

      this.answers = removed
    },

    includeAnswerOnQuestions() {
      this.questions.map((element) => {
        element.answer = []

        return element
      })

      this.answers.forEach((answer) => {
        const index = this.questions.findIndex((question) => {
          return answer.idPerguntaFormulario === question.id
        })

        if (answer.idRespostaFormulario) {
          if (this.questions[index].idTipoResposta === 4) {
            this.questions[index].answer.push(answer.idRespostaFormulario)
          } else {
            this.questions[index].answer = answer.idRespostaFormulario
          }
        } else {
          this.questions[index].answer = answer.descRespostaFormulario
        }
      })
    },

    saveAnswers() {
      const fillForm = {
        idProntuario: this.idProntuario,
        dtInclusao: formatDateHour(),
        idFormulario: this.form.id,
        descFormulario: this.form.titulo,
        pontuarioTotalFormulario: this.score,
        ativo: 0,
        idUsuarioInativacao: null,
        dtInativacao: null,
        justificativaInativacao: null,
        nmProfissional: this.medicName,
        listaRespostaFormularioAvaliacao: this.answers
      }

      if (this.alreadyFilled) {
        fillForm.id = this.form.idList
        this.$emit('edit', fillForm)
      } else {
        this.$emit('save', fillForm)
      }
    }
  }
}
</script>

<style scoped>
.background {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 50%;
  height: 80%;
  z-index: 9999;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  flex-direction: column;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.header {
  border-radius: 8px 8px 0 0;
  display: flex;
  padding: 0 16px;
  width: 100%;
  background: #089bab;
  color: #fff;
  font-size: 24px;
  justify-content: space-between;
  padding: 12px;
}

.body {
  display: flex;
  width: 100%;
  padding: 0 3% 0 3%;
  flex: auto;
  overflow: auto;
  flex-direction: column;
}

.body .title-container {
  width: 100%;
  display: flex;
  padding: 5% 0;
  color: #089bab;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}

.question-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question {
  color: rgb(41, 41, 41);
  font-weight: bold;
  margin: 16px 0;
  font-size: 14px;
}

.multiple-choice {
  font-size: 14px;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3% 5%;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  align-items: center;
  margin-top: 5%;
  justify-content: flex-end;
}

.buttons-container *:not(:last-child) {
  margin-right: 20px;
}

.guidance-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
