const mutations = {
  addLocalFile(state, { payload }) {
    const existPayload = state.localFilesState.findIndex(
      (file) => file.id === payload.id
    )
    if (existPayload < 0) state.localFilesState.push(payload)
    else state.localFilesState[existPayload] = payload
  },
  removeLocalFile(state, { id }) {
    state.localFilesState = state.localFilesState.filter(
      (localFile) => localFile.id !== id
    )
  }
}

export default mutations
