import API from '../../services/index'

export default {
  async saveForwardingAction(context, object) {
    try {
      const response = await API.post(
        '/api/ProntuarioEncaminhamentoContrarreferencia/Adicionar',
        { object }
      )

      return response
    } catch (e) {
      return false
    }
  },

  async getForwardingTypesAction() {
    try {
      const response = await API.get('/api/TipoEncaminhamento/ObtemListaAtivos')

      return response.data.object
    } catch (e) {
      return false
    }
  },

  async getSpecialtiesAction() {
    try {
      const response = await API.get('/api/Cbo/ObtemListaSaude/0', {
        params: {
          saude: 'S'
        }
      })

      return response.data.object
    } catch (e) {
      return false
    }
  },

  async getMedicsBySpecialtyAction(context, { idCbo, idClinic }) {
    try {
      const response = await API.get(
        '/api/FavoritoEncaminhamentoContrarreferencia/ObtemMedicoPorCboClinica',
        {
          params: {
            id_cbo: idCbo,
            id_clinica: idClinic
          }
        }
      )

      return response.data.object
    } catch (e) {
      return false
    }
  },

  async getCidsAction() {
    try {
      const response = await API.get('/api/Cid/ObtemLista')

      return response.data.object
    } catch (e) {
      return false
    }
  },

  async getPrioritiesAction() {
    try {
      const response = await API.get('/api/EncaminhamentoPrioridade/ObtemLista')

      return response.data.object
    } catch (e) {
      return false
    }
  }
}
