var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.results.length > 0)?_c('div',{staticClass:"search-result__container"},[_c('div',{staticClass:"boxResults"},[_c('div',{staticClass:"table-header",style:(`${
        _vm.codName
          ? 'grid-template-columns: 0.5fr 2fr 1fr 1fr;'
          : 'grid-template-columns:  2fr 1fr 1fr;'
      }`)},[(_vm.codName)?_c('p',[_vm._v(_vm._s(_vm.codName))]):_vm._e(),_c('p',{staticClass:"table-header__description"},[_vm._v("Descrição")]),_c('p',[_vm._v("Acesso Rápido")]),_c('p',[_vm._v("Favorito")])]),_vm._l((this.items),function(result){return _c('div',{key:result[_vm.idProperty],staticClass:"search-result__results",style:(`${
        _vm.codName
          ? 'grid-template-columns: 0.5fr 2fr 1fr 1fr;'
          : 'grid-template-columns:  2fr 1fr 1fr;'
      }`)},[(_vm.codName)?_c('p',[_vm._v(_vm._s(result[_vm.idProperty]))]):_vm._e(),_c('div',{staticClass:"results__description",style:(`cursor: ${_vm.selectOnDescription ? 'pointer' : 'auto'}`),on:{"click":() => _vm.selectOnDescription && _vm.onResultSelectedFixed(result)}},[_c('p',[_vm._v(" "+_vm._s(result[_vm.titleProperty])+" ")]),(_vm.showDegreeList)?_c('ul',{staticClass:"results__degree-list"},[_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.onResultSelectedFixed({ grau: 0, ...result })}}},[_vm._v(" Leve ")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.onResultSelectedFixed({ grau: 1, ...result })}}},[_vm._v(" Moderada ")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.onResultSelectedFixed({ grau: 2, ...result })}}},[_vm._v(" Severa ")])]):_vm._e()]),_c('div',{staticClass:"results__checkbox",on:{"click":function($event){$event.preventDefault();return ((event) => _vm.onQuickAccessChosen(result)).apply(null, arguments)}}},[_c('b-form-radio',{attrs:{"name":`favorite_location--${result[_vm.idProperty]}`,"value":"quickAccess"},model:{value:(result.location),callback:function ($$v) {_vm.$set(result, "location", $$v)},expression:"result.location"}})],1),_c('div',{staticClass:"results__checkbox",on:{"click":function($event){$event.preventDefault();return ((event) => _vm.onFavoriteChosen(result)).apply(null, arguments)}}},[_c('b-form-radio',{attrs:{"value":"favorite","name":`favorite_location--${result[_vm.idProperty]}`},model:{value:(result.location),callback:function ($$v) {_vm.$set(result, "location", $$v)},expression:"result.location"}})],1)])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }