import { parseToHtml } from '../../../../Utils/parsers'

const CONDUCT_MODULE_ID = 8

const mutations = {
  loadConducts(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === CONDUCT_MODULE_ID
    )

    payload.forEach((conduct) => {
      conduct.descConduta = parseToHtml(conduct.descConduta)
    })

    module.data = payload
  },
  addConduct(state, { payload }) {
    state.localConductsState = payload
  },

  editConduct(state, { payload }) {
    const module = state.modules.find(
      (module) => module.idModulo === CONDUCT_MODULE_ID
    )

    const conductIndex = module.data.findIndex(
      (conduct) => conduct.idProntuario === payload.idProntuario
    )

    module.data.splice(conductIndex, 1, { ...payload, modified: true })
  },

  removeConductFromState(state) {
    state.localConductsState = {}
  }
}

export default mutations
