var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"box-ultrassom"}},[_vm._m(0),_c('div',{staticClass:"box-ultra"},_vm._l((_vm.getListUtrassom),function(ultrassom,index){return _c('div',{key:index,class:index % 2 !== 0 ? 'ultra-card-imp' : 'ultra-card'},[_c('input',{attrs:{"disabled":"true","id":"ultra-input-data","type":"text"},domProps:{"value":_vm.formatData(ultrassom.dtInclusao)}}),_c('div',{staticClass:"box-input"},[_c('div',{staticClass:"ultra-box-ig"},[_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{staticClass:"ultra-input-ig-week",style:(errors[0] || ultrassom.igUsgQuantidadeSemana === 0
                      ? 'border: 1px solid red'
                      : 'ultra-input-ig-week'),attrs:{"placeholder":ultrassom.igUsgQuantidadeSemana + ' semanas',"type":"number","disabled":ultrassom.id ? true : false},on:{"input":function($event){return _vm.handleWeek($event, index)}}})]}}],null,true)})],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{staticClass:"ultra-input-ig-day",style:(errors[0] || ultrassom.igUsgQuantidadeDia === 0
                      ? 'border: 1px solid red'
                      : 'ultra-input-ig-day'),attrs:{"placeholder":ultrassom.igUsgQuantidadeDia + ' dias',"type":"number","disabled":ultrassom.id ? true : false},on:{"input":function($event){return _vm.handleDay($event, index)}}})]}}],null,true)})],1)],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{style:(errors[0] || ultrassom.dppUsg === ''
                    ? 'border: 1px solid red'
                    : 'ultra-input-dpp'),attrs:{"id":"ultra-input-dpp","type":"date","disabled":ultrassom.id ? true : false},domProps:{"value":_vm.formatDateForInput(ultrassom.dppUsg)},on:{"input":function($event){return _vm.handleDppUsg($event, index)}}})]}}],null,true)})],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{staticClass:"ultra-input-normal",style:(errors[0] || ultrassom.peso === 0
                    ? 'border: 1px solid red'
                    : 'ultra-input-normal'),attrs:{"type":"number","disabled":ultrassom.id ? true : false,"min":"0"},domProps:{"value":ultrassom.peso},on:{"input":function($event){return _vm.handlePeso($event, index)}}})]}}],null,true)})],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{staticClass:"ultra-input-pacient",style:(errors[0] || ultrassom.placenta === ''
                    ? 'border: 1px solid red'
                    : 'ultra-input-pacient'),attrs:{"disabled":ultrassom.id ? true : false,"type":"text"},domProps:{"value":ultrassom.placenta},on:{"input":function($event){return _vm.handlePlacenta($event, index)}}})]}}],null,true)})],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{staticClass:"ultra-input-liguid",style:(errors[0] || ultrassom.liquido === ''
                    ? 'border: 1px solid red'
                    : 'ultra-input-liguid'),attrs:{"disabled":ultrassom.id ? true : false,"type":"text"},domProps:{"value":ultrassom.liquido},on:{"input":function($event){return _vm.handleLiquido($event, index)}}})]}}],null,true)})],1),_c('input',{staticClass:"ultra-input-nucal",attrs:{"type":"text","disabled":ultrassom.id ? true : false},domProps:{"value":ultrassom.transucaoNucal},on:{"input":function($event){return _vm.handleTransucaoNucal($event, index)}}}),_c('input',{staticClass:"ultra-input-veci",attrs:{"type":"text","disabled":ultrassom.id ? true : false},domProps:{"value":ultrassom.vesicula},on:{"input":function($event){return _vm.handleVesicula($event, index)}}}),_c('input',{staticClass:"ultra-input-obs",attrs:{"type":"text","disabled":ultrassom.id ? true : false},domProps:{"value":ultrassom.observacao},on:{"input":function($event){return _vm.handleObsevor($event, index)}}}),_c('div',{staticClass:"ultra-box-buttons"},[(
                ultrassom.descArquivo !== '' &&
                ultrassom.descArquivo.urlArquivo !== null
              )?_c('button',{staticClass:"ultra-button",on:{"click":function($event){return _vm.openPDF(ultrassom.descArquivo)}}},[_c('img',{staticClass:"img-pdf",attrs:{"src":require("./assets/pdf.png"),"alt":"Icone de arquivo PDF"}})]):_c('button',{staticClass:"ultra-button",on:{"click":function($event){return _vm.openModal(index)}}},[_c('i',{staticClass:"fas fa-upload fa-2x",staticStyle:{"color":"#089bab"}})]),_c('button',{staticClass:"ultra-button-delete",on:{"click":function($event){return _vm.deleteUltrassom(index, ultrassom.id)}}},[_vm._v(" "+_vm._s(ultrassom.id ? 'inativar' : 'remover')+" ")])])],1)])}),0)]),_c('b-button',{staticStyle:{"margin-top":"10px","width":"100%","display":"flex","align-items":"center","justify-content":"center"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addNewUltrassom()}}},[_vm._v("Novo ultrassom")]),(_vm.showModal)?_c('pdf',{attrs:{"valueIndex":_vm.index},on:{"closeModal":function($event){return _vm.closeModal()},"add-file":_vm.setPDF}}):_vm._e(),(_vm.showPDF)?_c('showFromPdf',{attrs:{"pdf":_vm.dataPdf},on:{"closeModal":_vm.closeShowPDF}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar-tests"},[_c('div',{staticClass:"navbar-ultrassom-title"},[_vm._v("Data")]),_c('div',{staticClass:"navbar-ultrassom-box"},[_c('div',{staticClass:"navbar-ultrassom-span-ig"},[_vm._v("IG USG")]),_c('div',{staticClass:"navbar-ultrassom-span-dpp"},[_vm._v("DPP")]),_c('div',{staticClass:"navbar-ultrassom-span-peso"},[_vm._v("Peso")]),_c('div',{staticClass:"navbar-ultrassom-span"},[_vm._v("Placenta")]),_c('div',{staticClass:"navbar-ultrassom-span"},[_vm._v("Liquido")]),_c('div',{staticClass:"navbar-ultrassom-span"},[_vm._v("Trans.Nucal")]),_c('div',{staticClass:"navbar-ultrassom-span"},[_vm._v("Vesícula")]),_c('div',{staticClass:"navbar-ultrassom-obs"},[_vm._v("Observação")]),_c('div',{staticClass:"navbar-ultrassom-laudo"},[_vm._v("Laudo")])])])
}]

export { render, staticRenderFns }