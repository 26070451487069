import API from '../../../../services/index'
import { parseToAscii, parseToHtml } from '../../../../Utils/parsers'

const actions = {
  async addPhysicalExamToHandBook({ commit }, { payload }) {
    payload.descExameFisico = parseToAscii(payload.descExameFisico)

    try {
      await API.post('/api/ProntuarioExameFisico/Salvar', {
        object: payload
      })
    } catch (error) {}
  },

  async editPhysicalExamFromHandBook({ commit }, { payload }) {
    payload.descExameFisico = parseToAscii(payload.descExameFisico)

    try {
      await API.post('/api/ProntuarioExameFisico/Salvar', {
        object: payload
      })
    } catch (error) {}
  },

  async addPhysicalExamFavoriteToHandBook({ commit, getters }, { payload }) {
    const complaintFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )
    const alreadyOnFavorite = complaintFavorites.some(
      (favorite) => favorite.descTitulo === payload.descTitulo
    )
    if (alreadyOnFavorite) {
      return
    }
    try {
      payload.descExameFisico = parseToAscii(payload.descExameFisico)
      const apiResponse = await API.post('/api/FavoritoExameFisico/Adicionar', {
        object: {
          ...payload
        }
      })

      apiResponse.data.object.descExameFisico = parseToHtml(
        apiResponse.data.object.descExameFisico
      )
      commit({
        type: 'addToModuleFavoriteStateWithoutSelect',
        payload: apiResponse.data.object
      })
    } catch (error) {}
  },

  async addEdittedPhysicalExamFavoriteToHandBook({ commit }, { payload }) {
    try {
      const requestObject = {
        ...payload,
        descExameFisico: parseToAscii(payload.descExameFisico)
      }
      await API.put('/api/FavoritoExameFisico/Editar', {
        object: requestObject
      })

      requestObject.descExameFisico = parseToHtml(requestObject.descExameFisico)

      commit({ type: 'editModuleFavorite', payload: requestObject })
    } catch (error) {}
  },

  async removePhysicalExamFavoriteFromHandBook({ commit }, { payload }) {
    try {
      await API.delete('/api/FavoritoExameFisico/Remover', {
        data: {
          object: {
            ...payload
          }
        }
      })
      commit({ type: 'removeFromFavoriteState', payload })
    } catch (error) {}
  },

  async addPhysicalExamToFavoriteHandBook({ commit }, { payload }) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        descExameFisico: parseToAscii(payload.descExameFisico),
        acessoRapido: 1,
        favorito: 0
      }

      await API.put('/api/FavoritoExameFisico/Editar', {
        object: requestObject
      })

      requestObject.descExameFisico = parseToHtml(requestObject.descExameFisico)

      commit({
        type: 'addToModuleFavoriteState',
        payload: requestObject
      })
    } catch (error) {}
  },

  async addPhysicalExamToQuickAccessHandBook({ commit }, { payload }) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        descExameFisico: parseToAscii(payload.descExameFisico),
        acessoRapido: 0,
        favorito: 1
      }

      await API.put('/api/FavoritoExameFisico/Editar', {
        object: requestObject
      })

      requestObject.descExameFisico = parseToHtml(requestObject.descExameFisico)

      commit({
        type: 'addToModuleQuickAccessState',
        payload: requestObject
      })
    } catch (error) {}
  }
}

export default actions
