export default {
  getTypeGraph(state) {
    return state.isGraphORTable
  },
  getTypeGraphExpense(state) {
    return state.isGraphORTableExpense
  },
  getGraphOne(state) {
    return state.dataGraphOne
  },
  getGraphTwo(state) {
    return state.dataGraphTwo
  },
  getGraphTree(state) {
    return state.dataGraphTree
  },
  getDateInitGraphTwo(state) {
    return state.dateGraphTwoInit
  },
  getDateEndGraphTwo(state) {
    return state.dateGraphTwoFinaly
  },
  getDateInitGraphTree(state) {
    return state.dateGraphTreeInit
  },
  getDateEndGraphTree(state) {
    return state.dateGraphTreeFinaly
  },
  getClinicGraphTwo(state) {
    return state.clinicGraphTwo
  },
  getClinicGraphTree(state) {
    return state.clinicGraphTree
  },
  getDoctorGraphTwo(state) {
    return state.doctorGraphTwo
  },
  getDoctorGraphTree(state) {
    return state.doctorGraphTree
  },
  getOpenModalAlter(state) {
    return state.openModalAlter
  },
  getOpenModalAlterE(state) {
    return state.openModalAlterE
  }
}
