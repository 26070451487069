export default {
  changeDigitalAssign(state, data) {
    state.object = data
  },

  changeAssignModalVisibility(state, data) {
    state.isVisible = data
  },

  changeCertificate(state, data) {
    state.certificate = data
  },

  changeOpenedFromMedicalReports(state, data) {
    state.openedFromMedicalReports = data
  },

  changeSwichState(state) {
    state.isActive = !state.isActive
  }
}
