const PROBLEM_MODULE_ID = 1

const getters = {
  getProblemData: (state) => {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    return module.data
  },

  getIsExpanded: (state) => {
    const module = state.modules.find(
      (module) => module.idModulo === PROBLEM_MODULE_ID
    )
    return module.aberto
  },

  getProblemsByPatientId: (state) => (id) => {
    const problems = state.localProblems.find(
      (problem) => problem.idPatient === id
    )

    return problems.problems
  },
  getProblemsByIdHandBook: (state) => (id) => {
    const problems = state.localProblems.find(
      (problem) => problem.idProntuario === id
    )

    if (problems) {
      return problems.problems
    }

    return []
  }
}

export default getters
