import API from '../../../../services/index'
import { parseToAscii, parseToHtml } from '../../../../Utils/parsers'

const actions = {
  async addPersonalAndFamiliarHistoryToHandBook({ commit }, { payload }) {
    payload.descHistPessoalFamiliar = parseToAscii(
      payload.descHistPessoalFamiliar
    )

    try {
      await API.post('/api/ProntuarioHistPessoalFamiliar/Salvar', {
        object: payload
      })
    } catch (error) {}
  },

  async removePersonalAndFamiliarHistoryFavoriteFromHandBook(
    { commit },
    { payload }
  ) {
    try {
      await API.delete('/api/FavoritoHistPessoalFamiliar/Remover', {
        data: {
          object: {
            ...payload
          }
        }
      })

      commit({ type: 'removeFromFavoriteState', payload })
    } catch (error) {}
  },

  async addPersonalAndFamiliarHistoryFavoriteToHandBook(
    { commit, getters },
    { payload }
  ) {
    const moduleFavorites = getters.getModuleFavoritesById(
      payload.idProntuarioPreferencia
    )
    const alreadyOnFavorite = moduleFavorites.some(
      (favorite) => favorite.descTitulo === payload.descTitulo
    )
    if (alreadyOnFavorite) {
      return
    }
    try {
      payload.descHistPessoalFamiliar = parseToAscii(
        payload.descHistPessoalFamiliar
      )

      const apiResponse = await API.post(
        '/api/FavoritoHistPessoalFamiliar/Adicionar',
        {
          object: {
            ...payload
          }
        }
      )

      apiResponse.data.object.descHistPessoalFamiliar = parseToHtml(
        apiResponse.data.object.descHistPessoalFamiliar
      )

      commit({
        type: 'addToModuleFavoriteStateWithoutSelect',
        payload: apiResponse.data.object
      })
    } catch (error) {}
  },

  async addEdittedPersonalAndFamiliarHistoryFavoriteToHandBook(
    { commit },
    { payload }
  ) {
    try {
      const requestObject = {
        ...payload,
        descHistPessoalFamiliar: parseToAscii(payload.descHistPessoalFamiliar)
      }
      await API.put('/api/FavoritoHistPessoalFamiliar/Editar', {
        object: requestObject
      })

      requestObject.descHistPessoalFamiliar = parseToHtml(
        requestObject.descHistPessoalFamiliar
      )

      commit({ type: 'editModuleFavorite', payload: requestObject })
    } catch (error) {}
  },

  async addPersonalAndFamiliarHistoryToFavoriteHandBook(
    { commit },
    { payload }
  ) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        descHistPessoalFamiliar: parseToAscii(payload.descHistPessoalFamiliar),
        acessoRapido: 1,
        favorito: 0
      }

      await API.put('/api/FavoritoHistPessoalFamiliar/Editar', {
        object: requestObject
      })
      requestObject.descHistPessoalFamiliar = parseToHtml(
        requestObject.descHistPessoalFamiliar
      )

      commit({
        type: 'addToModuleFavoriteState',
        payload: requestObject
      })
    } catch (error) {}
  },

  async addPersonalAndFamiliarHistoryToQuickAccessHandBook(
    { commit },
    { payload }
  ) {
    try {
      const { location, ...newPayload } = payload

      const requestObject = {
        ...newPayload,
        descHistPessoalFamiliar: parseToAscii(payload.descHistPessoalFamiliar),

        acessoRapido: 0,
        favorito: 1
      }

      await API.put('/api/FavoritoHistPessoalFamiliar/Editar', {
        object: requestObject
      })

      requestObject.descHistPessoalFamiliar = parseToHtml(
        requestObject.descHistPessoalFamiliar
      )

      commit({
        type: 'addToModuleQuickAccessState',
        payload: requestObject
      })
    } catch (error) {}
  }
}

export default actions
