import API from '../../services/index'

export default {
  async addCertificate(context, payload) {
    try {
      /* const type = payload.fileType.split('/')[1] */

      await API.post('/api/IntegracaoEval/AdicionarCertificado', {
        object: {
          base64Pxf: payload.fileBase64,
          base64Pin: payload.passwordBase64,
          tipoCertificado: 'AI',
          cpf_responsavel: payload.cpf
        }
      })

      return true
    } catch (e) {
      return false
    }
  },

  async addCertificatePSC(context, payload) {
    try {
      await API.post('/api/IntegracaoEval/AdicionarCertificado', {
        object: {
          base64Pxf: '',
          base64Pin: '',
          tipoCertificado: 'PSC',
          cpf_responsavel: payload.cpf
        }
      })

      return true
    } catch (e) {
      return false
    }
  },

  async deleteCertificate(context, thumbprint) {
    await API.delete('/api/IntegracaoEval/DeletarCertificado', {
      params: {
        thumbprint
      }
    })
    context.commit('changeCertificate', false)
  },

  async recoverCertificate(context, { cpf, type }) {
    try {
      const response = await API.get(
        '/api/IntegracaoEval/ObtemItemCertificado',
        {
          params: {
            cpf,
            type
          }
        }
      )
      context.commit('changeCertificate', response.data.object)
    } catch (e) {
      context.commit('changeCertificate', false)
      return e
    }
  },

  async getAssignStatusAction(context, id) {
    try {
      const response = await API.get('/api/UsuarioDadosAssinatura/ObtemItem', {
        params: {
          id_usuario: id
        }
      })

      context.commit('changeDigitalAssign', response.data.object)
    } catch (err) {
      context.commit('changeDigitalAssign', false)
    }
  },

  async getPendingAssignsAction(context, id) {
    const response = await API.get(
      '/api/Prontuario/ObtemProntuarioSemAssinar',
      {
        params: {
          id_usuario: id
        }
      }
    )

    return response.data
  },

  async assignAllPendingsAction(context, array) {
    try {
      const response = await API.post(
        '/api/IntegracaoEval/AdicionarAssinaturaLote',
        {
          array
        }
      )

      return response.data
    } catch {
      return false
    }
  },

  async refreshAssignStatusAndStateAction(context, object) {
    try {
      const result = await context.dispatch('createAssignStatusAction', object)

      if (result) {
        context.commit('changeDigitalAssign', object)
      }

      return result
    } catch {
      return false
    }
  },

  async assignMedicalReportAction(context, idProntuario) {
    // comunicação apenas com o back
    try {
      const response = await API.post(
        `/api/IntegracaoEval/AdicionarAssinatura?id_prontuario=${idProntuario}`
      )

      return response.status
    } catch (e) {
      return false
    }

    // comunicação direto com a eval
    // const info = await getAssignEvalInfo(idProntuario)
    // const response = await sendInfoToEvalApi(info)
    // return response.status
  },

  async createAssignStatusAction(context, object) {
    const response = await API.post('/api/UsuarioDadosAssinatura/Salvar', {
      object
    })

    return response.data.result
  },

  changeAssignModalVisibilityAction(context, data) {
    context.commit('changeAssignModalVisibility', data)
  },

  changeSwitchStateAction(context) {
    context.commit('changeSwichState')
  },

  changeOpenedFromMedicalReportsAction(context, data) {
    context.commit('changeOpenedFromMedicalReports', data)
  },

  verifyAssignExpired({ state }) {
    const date = new Date()
    const expiration = new Date(state.object.dtExpiracaoPin)

    const diff = expiration.getTime() - date.getTime()

    let expired

    diff <= 0 ? (expired = true) : (expired = false)

    return expired
  }
}
