<template>
  <b-modal
    id="revenueModal"
    header-bg-variant="primary"
    title="Receita"
    size="xl"
    title-class="text-light"
    ok-title="Salvar"
    hide-footer
    @close="close"
    no-close-on-backdrop
    no-close-on-esc
  >
    <ValidationObserver ref="form" @submit.prevent="saveRevenue()">
      <form class="box-revenue">
        <h3>Manutenção de titulos a receber</h3>
        <hr class="divider" />
        <div class="normal">
          <div class="content">
            <label>Clínica:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="clinic"
                :options="clinics"
                label="nmClinica"
                :clearable="false"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <div class="invalid-feedback">
                <span>Informe um pagador!</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <hr class="divider" />
        <div class="revenue-radios">
          <div class="content-radios">
            <label>Tipo recebedor:</label>
            <div class="box-radio">
              <b-form-radio
                id="checkbox-one"
                v-model="statusOne"
                name="checkbox-one"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Clinica
              </b-form-radio>

              <b-form-radio
                id="checkbox-two"
                v-model="statusTwo"
                name="checkbox-tow"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Profissional de Saude
              </b-form-radio>
            </div>
          </div>
          <div class="content">
            <label>Recebedor:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-if="statusText === false"
                v-model="receiver"
                :options="listReceiver"
                :label="statusTwo === 'accepted' ? 'nmUsuario' : 'nmClinica'"
                :clearable="false"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              ></v-select>
              <b-input
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-else
                v-model="newReceb"
              ></b-input>
              <div
                v-if="errors.length > 0"
                style="color: rgb(234, 47, 47); font-size: 0.6rem"
              >
                <span>Informe um recebedor!</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="box-normal-1">
          <div class="content">
            <label>CPF ou CNPJ Pagador:</label>
            <div style="display: flex; align-items: center; gap: 8px">
              <b-input
                :style="
                  isIOS
                    ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 40px'
                    : ''
                "
                v-if="statusCpfOrCnpj === 'not_accepted'"
                v-mask="'###.###.###-##'"
                type="text"
                ref="inputCpfCnpjPay"
                v-model="cpfORcnpj"
              ></b-input>
              <b-input
                :style="
                  isIOS
                    ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 40px'
                    : ''
                "
                v-else
                v-mask="'##.###.###/####-##'"
                type="text"
                ref="inputCpfCnpjPay"
                v-model="cpfORcnpj"
              ></b-input>
            </div>
          </div>
          <div class="content">
            <label>Pagador:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-if="statusPagador === 'not_accepted'"
                v-model="pagador"
                :options="listPagador"
                label="nomePagadorFinanceiro"
                :clearable="false"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              ></v-select>
              <b-input
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-else
                v-model="newPagador"
              ></b-input>
              <div
                v-if="errors.length > 0"
                style="color: rgb(234, 47, 47); font-size: 0.6rem"
              >
                <span>Informe um pagador!</span>
              </div>
            </ValidationProvider>
          </div>
          <b-form-checkbox
            id="checkbox-pagador"
            v-model="statusPagador"
            name="checkbox-pagador"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Novo Pagador
          </b-form-checkbox>
        </div>
        <hr class="divider" />
        <div class="box-normal-5">
          <b-form-checkbox
            id="checkbox-typeFix"
            v-model="statusRevenueFix"
            name="checkbox-typeFix"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Receita Fixa
          </b-form-checkbox>
          <div v-if="statusRevenueFix === 'accepted'" class="content">
            <label>Qtde meses recorrência:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                type="number"
                min="0"
                max="1000000000000000000000"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-model="recorrency"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe o numero de meses!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>N do título:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                v-mask="'#######################'"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-model="nTitle"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe o numero do seu título!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Situação:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="situation"
                :options="listSituetion"
                label="descSituacao"
                :clearable="false"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <div class="invalid-feedback">
                <span>Informe a situação!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Tipo:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-if="statusType === 'not_accepted'"
                v-model="type"
                :options="listType"
                label="descTipoRecebimento"
                :clearable="false"
                :style="
                  errors.length > 0
                    ? 'border: 1px solid red; border-radius: 11px'
                    : ''
                "
              ></v-select>
              <b-input
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-else
                v-model="newtype"
              ></b-input>
              <div
                v-if="errors.length > 0"
                style="color: rgb(234, 47, 47); font-size: 0.6rem"
              >
                <span>Informe o tipo!</span>
              </div>
            </ValidationProvider>
          </div>
          <b-form-checkbox
            id="checkbox-type"
            v-model="statusType"
            name="checkbox-type"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Novo Tipo
          </b-form-checkbox>
        </div>
        <div class="box-normal-2">
          <div class="content">
            <label>Valor:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                v-currency="{
                  locale: 'pt-BR',
                  currency: null,
                  precision: 2
                }"
                v-model="valuer"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe o valor!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Valor Recebido:</label>
            <b-input
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 28px'
                  : ''
              "
              v-currency="{
                locale: 'pt-BR',
                currency: null,
                precision: 2
              }"
              v-model="valueRecb"
            ></b-input>
          </div>
          <div class="content">
            <label>Desconto:</label>
            <b-input
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 28px'
                  : ''
              "
              v-currency="{
                locale: 'pt-BR',
                currency: null,
                precision: 2
              }"
              v-model="discount"
            ></b-input>
          </div>
          <div class="content">
            <label>Saldo:</label>
            <b-input
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 28px'
                  : ''
              "
              v-currency="{
                locale: 'pt-BR',
                currency: null,
                precision: 2
              }"
              v-model="balance"
            ></b-input>
          </div>
        </div>
        <div class="box-normal-3">
          <div class="content">
            <label>Data emissão:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                :style="
                  isIOS
                    ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 34px'
                    : ''
                "
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
                v-model="dateEmit"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe a data de emissão!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Data vencimento:</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                :style="
                  isIOS
                    ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 34px'
                    : ''
                "
                type="date"
                :min="dateEmit"
                v-model="dateFinaly"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-input>
              <div class="invalid-feedback">
                <span>Informe a data de encerramento!</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="content">
            <label>Data recebimento:</label>
            <b-input
              type="date"
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 34px'
                  : ''
              "
              v-model="dateRecb"
            ></b-input>
          </div>
          <div class="content">
            <label>Data baixa:</label>
            <b-input
              :style="
                isIOS
                  ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 34px'
                  : ''
              "
              type="date"
              v-model="dateDown"
            ></b-input>
          </div>
        </div>
        <div class="box-normal-4">
          <div class="content">
            <label>Complemento:</label>
            <b-input type="text" v-model="complement"></b-input>
          </div>
          <div class="content">
            <label>Identificação de pagamento:</label>
            <b-input type="text" v-model="IDPayment"></b-input>
          </div>
          <div class="content">
            <label>Tipo de pagamento:</label>
            <v-select
              v-model="typePayment"
              :options="listPayment"
              label="name"
              :clearable="false"
            ></v-select>
          </div>
        </div>
        <div v-if="types === true && data?.dadosAtendimento">
          <hr class="divider" />
          <button
            @click.prevent="isToogleDetail = !isToogleDetail"
            class="button-detail"
          >
            <span>Detalhes do atendimento</span>
            <b-icon icon="arrow-down" font-scale="1"></b-icon>
          </button>
        </div>
        <div class="box-deatil" v-if="isToogleDetail">
          <div class="normal-1">
            <div class="content">
              <label>Id Agenda:</label>
              <b-input type="text" disabled="true" v-model="idAgenda"></b-input>
            </div>
            <div class="content">
              <label>Data do Atendimento:</label>
              <b-input
                disabled="true"
                type="text"
                v-model="dateAtendent"
              ></b-input>
            </div>
            <div class="content">
              <label>Status:</label>
              <b-input
                disabled="true"
                type="text"
                v-model="statusAtendement"
              ></b-input>
            </div>
          </div>
          <div class="normal-2">
            <div class="content">
              <label>Nome do paciente:</label>
              <b-input
                type="text"
                disabled="true"
                v-model="namePacient"
              ></b-input>
            </div>
            <div class="content">
              <label>Convênio:</label>
              <b-input type="text" disabled="true" v-model="conven"></b-input>
            </div>
          </div>
          <div class="normal-3">
            <div class="content">
              <label>Codigo:</label>
              <b-input
                type="text"
                disabled="true"
                v-model="codeAtendement"
              ></b-input>
            </div>
            <div class="content">
              <label>Serviço TUSS:</label>
              <b-input
                type="text"
                disabled="true"
                v-model="serviceTUSS"
              ></b-input>
            </div>
            <div class="content">
              <label>Valor da Consulta:</label>
              <b-input
                disabled="true"
                type="text"
                step="any"
                v-model="valeConsult"
              ></b-input>
            </div>
          </div>
          <span style="font-size: 1rem; font-weight: 600; color: black"
            >Lista de Procedimentos:</span
          >
          <div
            v-if="listaProced.length === 0 || listaProced === null"
            style="
              height: 200px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <span style="font-size: 1rem; font-weight: 600">
              Nenhum procedimento na lista!
            </span>
          </div>
          <div v-else class="box-scroll-list-proced">
            <li class="li-list-proced" v-for="item in listaProced" :key="item">
              <div class="content">
                <label>Prontuário:</label>
                <span>{{ item.idProntuario }}</span>
              </div>
              <div class="content">
                <label>Data de solicitação:</label>
                <span>{{ item.dtSolicitacao }}</span>
              </div>
              <div class="content">
                <label>Codigo Procedimento:</label>
                <span>{{ item.codProcedimento }}</span>
              </div>
              <div class="content">
                <label>Procedimento:</label>
                <span>{{ item.descProcedimento }}</span>
              </div>
              <div class="content">
                <label>Valor:</label>
                <span>{{ item.valorProcedimento }}</span>
              </div>
            </li>
          </div>
        </div>
        <hr class="divider" />
        <div class="box-button">
          <button @click.prevent="close()" class="b-cancel">Cancelar</button>
          <button type="submit" class="b-save">Salvar</button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
  props: ['user', 'admAndRecp', 'types', 'data'],
  data() {
    return {
      isToogleDetail: false,
      dateAtendent: '',
      idAgenda: 0,
      statusAtendement: '',
      namePacient: '',
      conven: '',
      codeAtendement: 0,
      serviceTUSS: '',
      valeConsult: 0,
      statusReceb: true,
      statusText: false,
      isIOS: false,
      nTitle: null,
      situation: {},
      type: '',
      statusCpfOrCnpj: 'not_accepted',
      valuer: '',
      valueRecb: '',
      discount: '',
      balance: '',
      dateEmit: '',
      dateFinaly: '',
      dateRecb: '',
      dateDown: '',
      complement: '',
      IDPayment: '',
      typePayment: '',

      cpfORcnpj: '',
      pagador: '',
      statusPagador: 'not_accepted',
      statusType: 'not_accepted',
      newtype: '',
      newPagador: '',
      newReceb: '',

      listSituetion: [],
      listType: [],
      listPagador: [],
      listaProced: [],

      listPayment: [
        { name: 'Cartão de Credito', id: 1 },
        { name: 'Dinheiro', id: 2 },
        { name: 'Pix', id: 3 },
        { name: 'Cartão de Debito', id: 4 }
      ],

      statusOne: 'accepted',
      statusTwo: 'not_accepted',

      receiver: '',
      listReceiver: [],

      clinic: '',
      clinics: '',

      statusRevenueFix: 'not_accepted',
      recorrency: 0
    }
  },
  async mounted() {
    this.$bvModal.show('revenueModal')
    this.clinic = this.user.clinica_usuario[0]
    this.clinics = this.user.clinica_usuario
    this.listReceiver = this.user.clinica_usuario
    this.listSituetion = await this.getStatusFinance()
    this.listPagador = await this.getPayers({
      id_clinica: this.clinic.idClinica
    })
    this.listType = await this.getTypes({
      id_clinica: this.clinic.idClinica
    })
    this.situation = this.listSituetion[0]
    if (this.isIOSOrMac()) {
      this.isIOS = true
    }
    if (this.types === true) {
      this.setValuesInFields()
    } else {
      await this.$refs.form.validate()
    }
  },
  watch: {
    cpfORcnpj(newValue) {
      const formattedValue = newValue.replace(/[.\-/]/g, '')

      // Verifica se o valor inicial é um CPF ou CNPJ e aplica a máscara correspondente
      if (formattedValue.length > 11) {
        this.statusCpfOrCnpj = 'accepted'
      } else {
        this.statusCpfOrCnpj = 'not_accepted'
      }
    },
    valuer(newValue) {
      if (this.situation.id === 2) {
        this.valueRecb = newValue
      }
    },
    statusPagador(newValue) {
      if (newValue === 'accepted') {
        this.cpfORcnpj = ''
        this.pagador = ''
        this.$refs.inputCpfCnpjPay.focus()
      } else {
        this.cpfORcnpj = ''
        this.$refs.inputCpfCnpjPay.focus()
      }
    },
    statusOne(newValue) {
      if (newValue === 'accepted' || this.statusOne === 'accepted') {
        this.statusTwo = 'not_accepted'
        this.receiver = this.user.clinica_usuario[0]
        this.listReceiver = this.user.clinica_usuario
        this.statusReceb = true
        this.statusText = false
      }
    },
    statusTwo(newValue) {
      if (newValue === 'accepted' || this.statusTwo === 'accepted') {
        if (
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            1 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            4 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            5
        ) {
          this.statusOne = 'not_accepted'
          this.receiver = this.user.clinica_usuario[0]
          this.listReceiver = [this.user.clinica_usuario[0]]
          this.statusReceb = false
          this.statusText = false
        }
        if (
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            2 ||
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
            3
        ) {
          this.statusOne = 'not_accepted'
          this.receiver = this.admAndRecp[0]
          this.listReceiver = this.admAndRecp
          this.statusReceb = false
          this.statusText = false
        }
      }
    },
    async clinic() {
      this.listPagador = await this.getPayers({
        id_clinica: this.clinic.idClinica
      })
      this.pagador = this.listPagador[0]
      this.listType = await this.getTypes({
        id_clinica: this.clinic.idClinica
      })
      this.type = this.listType[0]
    },
    pagador(newValue) {
      if (this.types !== true) {
        this.cpfORcnpj = newValue.cpfCnpj
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      setNewPayer: 'Finance/setNewPayer',
      setNewType: 'Finance/setNewType',
      setNewRevenue: 'Finance/setNewRevenue',
      getTypes: 'Finance/getTypes',
      getPayers: 'Finance/getPayers',
      getStatusFinance: 'Finance/getStatusFinance'
    }),
    changeDecimalSeparator(value, separator) {
      if (typeof value === 'string') {
        // Remover pontos e vírgulas
        const formattedValue = value.replace(/[.,]/g, '')

        return parseFloat(formattedValue)
      } else {
        return value
      }
    },
    isIOSOrMac() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|macintosh/i.test(userAgent)
    },
    close() {
      this.$emit('close')
    },
    adicionarZeros(numero) {
      // Converte o número para string para facilitar a manipulação
      numero = String(numero)

      // Verifica se o número possui um ponto decimal
      if (numero.indexOf('.') !== -1) {
        // Obtém a parte decimal do número
        const parteDecimal = numero.split('.')[1]

        // Se a parte decimal for diferente de "00", adiciona um zero no final
        if (parteDecimal !== '00') {
          numero += '0'
        }
      } else {
        // Se não tiver ponto, adiciona dois zeros no final
        numero += '00'
      }

      return numero
    },
    formatData(date) {
      if (date === null || date === '' || date === undefined) {
        return ''
      } else {
        return moment(date).format('YYYY-MM-DD')
      }
    },
    getPayment(valuer) {
      if (valuer === '' || valuer === null || valuer === undefined) {
        return ''
      } else {
        const list = this.listPayment.filter((object) => object.name === valuer)
        return list[0]
      }
    },
    getPagador(valuer) {
      if (valuer === '' || valuer === null || valuer === undefined) {
        return ''
      } else {
        const list = this.listPagador.filter((object) => object.id === valuer)
        return list[0]
      }
    },
    getStatusFinanceiro(valuer) {
      if (valuer === '' || valuer === null || valuer === undefined) {
        return ''
      } else {
        const list = this.listSituetion.filter((object) => object.id === valuer)
        return list[0]
      }
    },
    getType(valuer) {
      if (valuer === '' || valuer === null || valuer === undefined) {
        return ''
      } else {
        const list = this.listType.filter((object) => object.id === valuer)
        return list[0]
      }
    },
    handleTypeReceb(type) {
      if (type === 1) {
        this.statusOne = 'not_accepted'
        this.statusTwo = 'accepted'
      }
      if (type === 2) {
        this.statusOne = 'accepted'
        this.statusTwo = 'not_accepted'
      }
    },
    setValuesInFields() {
      this.dateEmit = this.formatData(this.data.dtEmissao)
      this.dateFinaly = this.formatData(this.data.dtVencimento)
      this.handleTypeReceb(this.data.tipoRecebedor)
      this.receiver = this.handleRevenueEdit(this.data)
      this.nTitle = this.data.numeroTitulo
      this.type = this.getType(this.data.idTipoRecebimento)
      this.situation = this.getStatusFinanceiro(this.data.idStatusFinanceiro)
      this.dateRecb = this.formatData(this.data.dtRecebimento)
      this.dateDown = this.formatData(this.data.dtBaixa)
      this.valuer = this.data.valor
      this.valueRecb = this.data.valorRecebido
      this.discount = this.data.desconto
      this.balance = this.data.saldo
      if (this.data?.dadosAtendimento) {
        // Atribui os valores dos dados de atendimento aos campos correspondentes
        this.idAgenda = this.data?.dadosAtendimento.idAgenda || 0
        this.dateAtendent =
          moment(this.data?.dadosAtendimento.dtAtendimento).format(
            'DD/MM/YYYY HH:mm'
          ) || ''
        this.statusAtendement = this.data?.dadosAtendimento.statusAgenda || ''
        this.namePacient = this.data?.dadosAtendimento.nmPaciente || ''
        this.conven = this.data?.dadosAtendimento.descConvenio || ''
        this.codeAtendement = this.data?.dadosAtendimento.codProcedimento || 0
        this.serviceTUSS = this.data?.dadosAtendimento.descProcedimento || ''
        this.valeConsult = this.data?.dadosAtendimento.valorConsulta || 0
        this.listaProced = this.data?.dadosAtendimento.listaProcedimentos || []
      } else {
        // Se não houver dados de atendimento, define os campos como vazios ou valores padrão
        this.idAgenda = 0
        this.dateAtendent = ''
        this.statusAtendement = ''
        this.namePacient = ''
        this.conven = ''
        this.codeAtendement = 0
        this.serviceTUSS = ''
        this.valeConsult = 0
        this.listaProced = []
      }
      this.applyCpfCnpjMask()
      this.complement = this.data.complemento
      this.IDPayment = this.data.identificacaoPagamento
      this.typePayment = this.getPayment(this.data.tipoPagamento)
      this.pagador = this.getPagador(this.data.idPagador)
      this.idAgenda = this.data?.dadosAtendimento.idAgenda
      this.statusRevenueFix = this.getRevenueFixa(this.data.receitaFixa)
      this.recorrency = this.data.qtdMesesFixa
    },
    applyCpfCnpjMask() {
      // Aplica a máscara de CPF ou CNPJ ao campo cpfORcnpj
      if (this.statusCpfOrCnpj === 'accepted') {
        // Aplica a máscara de CNPJ
        this.cpfORcnpj = this.data.cpfCnpjPagador?.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5'
        )
      } else {
        // Aplica a máscara de CPF
        this.cpfORcnpj = this.data.cpfCnpjPagador?.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          '$1.$2.$3-$4'
        )
      }
    },
    getRevenueFixa(valuer) {
      if (valuer === 0 || valuer === undefined || valuer) {
        return 'not_accepted'
      } else {
        return 'accepted'
      }
    },
    handleRevenueEdit(data) {
      if (data.tipoRecebedor === 2) {
        const object = {
          idClinica: data.idClinica,
          nmClinica: data.nmRecebedor
        }
        return object
      }

      if (data.tipoRecebedor === 1) {
        const object = {
          idUsuario: data.idUsuarioRecebedor,
          nmUsuario: data.nmRecebedor
        }
        return object
      }
    },
    typeRecebFunction() {
      if (this.statusOne === 'accepted') {
        return 2
      } else if (this.statusTwo === 'accepted') {
        return 1
      }
    },
    saveRevenue: async function () {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        return this.$toast.error('Verifique os campos obrigatórios!')
      } else {
        let newPayment = {}

        if (this.statusPagador === 'accepted') {
          const response = await this.setNewPayer({
            cpfCnpj: this.cpfORcnpj?.replace(/[.\-/]/g, ''),
            nomePagadorFinanceiro: this.newPagador,
            idClinica: this.clinic.idClinica
          })

          if (response === false) {
            return this.$toast.error('Não foi possivel salvar o pagador!')
          } else {
            newPayment = response
          }
        }

        let newtypeSave = {}

        if (this.statusType === 'accepted') {
          const response = await this.setNewType({
            idClinica: this.clinic.idClinica,
            descTipoRecebimento: this.newtype
          })

          if (response === false) {
            return this.$toast.error('Não foi possivel salvar o tipo!')
          } else {
            newtypeSave = response
          }
        }

        if (this.types === true) {
          if (this.statusOne === 'accepted') {
            const form = {
              id: this.data.id,
              tipoRecebedor: this.typeRecebFunction(),
              idTipoRecebimento:
                this.statusType === 'accepted'
                  ? newtypeSave.id
                  : this.typePayment.id,
              idStatusFinanceiro: this.situation.id,
              numeroTitulo: parseInt(this.nTitle),
              valor:
                this.valuer === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valuer, ''),
              valorRecebido:
                this.valueRecb === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueRecb, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              saldo:
                this.balance === ''
                  ? 0
                  : this.changeDecimalSeparator(this.balance, ''),
              dtEmissao: this.dateEmit,
              dtVencimento: this.dateFinaly,
              dtRecebimento: this.dateRecb,
              dtBaixa: this.dateDown,
              complemento: this.complement,
              idClinicaRecebedor: this.receiver.idClinica,
              cpfCnpjPagador: this.cpfORcnpj?.replace(/[.\-/]/g, ''),
              idPagador:
                this.statusPagador === 'accepted'
                  ? newPayment.id
                  : this.pagador.id,
              pagador:
                this.statusPagador === 'accepted'
                  ? newPayment.nomePagadorFinanceiro
                  : this.pagador.nomePagadorFinanceiro,
              identificacaoPagamento: this.IDPayment,
              tipoPagamento: this.typePayment.name,
              idAgendaOrigem: 0,
              idClinica: this.clinic.idClinica,
              receitaFixa: this.statusRevenueFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency
            }

            if (this.data?.dadosAtendimento) {
              const data = {
                idAgenda: parseInt(this.data.dadosAtendimento.idAgenda),
                dtAtendimento: this.data.dadosAtendimento.dtAtendimento,
                nmPaciente: this.data.dadosAtendimento.nmPaciente,
                descConvenio: this.data.dadosAtendimento.descConvenio,
                codProcedimento: parseInt(
                  this.data.dadosAtendimento.codProcedimento
                ),
                descProcedimento: this.data.dadosAtendimento.descProcedimento,
                valorConsulta: this.data.dadosAtendimento.valorConsulta,
                statusAgenda: this.data.dadosAtendimento.statusAgenda,
                listaProcedimentos:
                  this.data.dadosAtendimento.listaProcedimentos
              }

              form.dadosAtendimento = data
            }

            const response = await this.setNewRevenue(form)

            if (response) {
              this.$toast.success('Receita editada com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel editar a receita!')
            }
          }
          if (this.statusTwo === 'accepted') {
            const form = {
              id: this.data.id,
              tipoRecebedor: this.typeRecebFunction(),
              idTipoRecebimento:
                this.statusType === 'accepted'
                  ? newtypeSave.id
                  : this.typePayment.id,
              idStatusFinanceiro: this.situation.id,
              numeroTitulo: parseInt(this.nTitle),
              valor:
                this.valuer === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valuer, ''),
              valorRecebido:
                this.valueRecb === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueRecb, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              saldo:
                this.balance === ''
                  ? 0
                  : this.changeDecimalSeparator(this.balance, ''),
              dtEmissao: this.dateEmit,
              dtVencimento: this.dateFinaly,
              dtRecebimento: this.dateRecb,
              dtBaixa: this.dateDown,
              complemento: this.complement,
              idUsuarioRecebedor: this.receiver.idUsuario,
              cpfCnpjPagador: this.cpfORcnpj?.replace(/[.\-/]/g, ''),
              idPagador:
                this.statusPagador === 'accepted'
                  ? newPayment.id
                  : this.pagador.id,
              pagador:
                this.statusPagador === 'accepted'
                  ? newPayment.nomePagadorFinanceiro
                  : this.pagador.nomePagadorFinanceiro,
              identificacaoPagamento: this.IDPayment,
              tipoPagamento: this.typePayment.name,
              idAgendaOrigem: 0,
              idClinica: this.clinic.idClinica,
              receitaFixa: this.statusRevenueFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency
            }
            if (this.data?.dadosAtendimento) {
              const data = {
                idAgenda: parseInt(this.data.dadosAtendimento.idAgenda),
                dtAtendimento: this.data.dadosAtendimento.dtAtendimento,
                nmPaciente: this.data.dadosAtendimento.nmPaciente,
                descConvenio: this.data.dadosAtendimento.descConvenio,
                codProcedimento: parseInt(
                  this.data.dadosAtendimento.codProcedimento
                ),
                descProcedimento: this.data.dadosAtendimento.descProcedimento,
                valorConsulta: this.data.dadosAtendimento.valorConsulta,
                statusAgenda: this.data.dadosAtendimento.statusAgenda,
                listaProcedimentos:
                  this.data.dadosAtendimento.listaProcedimentos
              }

              form.dadosAtendimento = data
            }
            const response = await this.setNewRevenue(form)
            if (response) {
              this.$toast.success('Receita editada com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel editar a receita!')
            }
          }
        } else {
          if (this.statusOne === 'accepted') {
            const form = {
              tipoRecebedor: this.typeRecebFunction(),
              idTipoRecebimento:
                this.statusType === 'accepted'
                  ? newtypeSave.id
                  : this.typePayment.id,
              idStatusFinanceiro: this.situation.id,
              numeroTitulo: parseInt(this.nTitle),
              valor:
                this.valuer === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valuer, ''),
              valorRecebido:
                this.valueRecb === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueRecb, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              saldo:
                this.balance === ''
                  ? 0
                  : this.changeDecimalSeparator(this.balance, ''),
              dtEmissao: this.dateEmit,
              dtVencimento: this.dateFinaly,
              dtRecebimento: this.dateRecb,
              dtBaixa: this.dateDown,
              complemento: this.complement,
              idClinicaRecebedor: this.receiver.idClinica,
              cpfCnpjPagador: this.cpfORcnpj?.replace(/[.\-/]/g, ''),
              idPagador:
                this.statusPagador === 'accepted'
                  ? newPayment.id
                  : this.pagador.id,
              pagador:
                this.statusPagador === 'accepted'
                  ? newPayment.nomePagadorFinanceiro
                  : this.pagador.nomePagadorFinanceiro,
              identificacaoPagamento: this.IDPayment,
              tipoPagamento: this.typePayment.name,
              idAgendaOrigem: 0,
              idClinica: this.clinic.idClinica,
              receitaFixa: this.statusRevenueFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency
            }

            if (this.data?.dadosAtendimento) {
              const data = {
                idAgenda: parseInt(this.data.dadosAtendimento.idAgenda),
                dtAtendimento: this.data.dadosAtendimento.dtAtendimento,
                nmPaciente: this.data.dadosAtendimento.nmPaciente,
                descConvenio: this.data.dadosAtendimento.descConvenio,
                codProcedimento: parseInt(
                  this.data.dadosAtendimento.codProcedimento
                ),
                descProcedimento: this.data.dadosAtendimento.descProcedimento,
                valorConsulta: this.data.dadosAtendimento.valorConsulta,
                statusAgenda: this.data.dadosAtendimento.statusAgenda,
                listaProcedimentos:
                  this.data.dadosAtendimento.listaProcedimentos
              }

              form.dadosAtendimento = data
            }

            const response = await this.setNewRevenue(form)

            if (response) {
              this.$toast.success('Receita salva com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel salvar a receita!')
            }
          }
          if (this.statusTwo === 'accepted') {
            const form = {
              tipoRecebedor: this.typeRecebFunction(),
              idTipoRecebimento:
                this.statusType === 'accepted'
                  ? newtypeSave.id
                  : this.typePayment.id,
              idStatusFinanceiro: this.situation.id,
              numeroTitulo: parseInt(this.nTitle),
              valor:
                this.valuer === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valuer, ''),
              valorRecebido:
                this.valueRecb === ''
                  ? 0
                  : this.changeDecimalSeparator(this.valueRecb, ''),
              desconto:
                this.discount === ''
                  ? 0
                  : this.changeDecimalSeparator(this.discount, ''),
              saldo:
                this.balance === ''
                  ? 0
                  : this.changeDecimalSeparator(this.balance, ''),
              dtEmissao: this.dateEmit,
              dtVencimento: this.dateFinaly,
              dtRecebimento: this.dateRecb,
              dtBaixa: this.dateDown,
              complemento: this.complement,
              idUsuarioRecebedor: this.receiver.idUsuario,
              cpfCnpjPagador: this.cpfORcnpj?.replace(/[.\-/]/g, ''),
              idPagador:
                this.statusPagador === 'accepted'
                  ? newPayment.id
                  : this.pagador.id,
              pagador:
                this.statusPagador === 'accepted'
                  ? newPayment.nomePagadorFinanceiro
                  : this.pagador.nomePagadorFinanceiro,
              identificacaoPagamento: this.IDPayment,
              tipoPagamento: this.typePayment.name,
              idAgendaOrigem: 0,
              idClinica: this.clinic.idClinica,
              receitaFixa: this.statusRevenueFix === 'accepted' ? 1 : 0,
              qtdMesesFixa: this.recorrency
            }

            if (this.data?.dadosAtendimento) {
              const data = {
                idAgenda: parseInt(this.data.dadosAtendimento.idAgenda),
                dtAtendimento: this.data.dadosAtendimento.dtAtendimento,
                nmPaciente: this.data.dadosAtendimento.nmPaciente,
                descConvenio: this.data.dadosAtendimento.descConvenio,
                codProcedimento: parseInt(
                  this.data.dadosAtendimento.codProcedimento
                ),
                descProcedimento: this.data.dadosAtendimento.descProcedimento,
                valorConsulta: this.data.dadosAtendimento.valorConsulta,
                statusAgenda: this.data.dadosAtendimento.statusAgenda,
                listaProcedimentos:
                  this.data.dadosAtendimento.listaProcedimentos
              }

              form.dadosAtendimento = data
            }

            const response = await this.setNewRevenue(form)

            if (response) {
              this.$toast.success('Receita salva com sucesso!')
              this.close()
            } else {
              this.$toast.error('Não foi possivel salvar a receita!')
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.box-revenue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
}

.content-radios {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(253, 253, 253);
}

.revenue-radios {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
}

.box-radio {
  display: flex;
  align-items: center;
  gap: 20px;
}
.box-normal-1 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 2fr 3fr 1fr;
  align-items: center;
  justify-content: center;
}

.box-normal-2 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  align-items: center;
  justify-content: center;
}
.box-normal-5 {
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1.5fr 2fr 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
}
.box-normal-3 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  align-items: center;
  justify-content: center;
}
.box-normal-4 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 3fr 2fr 2fr;
  align-items: center;
  justify-content: center;
}

.box-button {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-detail {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}

.box-deatil {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.b-cancel {
  color: white;
  padding: 8px;
  border: none;
  border-radius: 10px;
  background-color: rgb(178, 48, 30);
}
.normal {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}
.normal-1 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.normal-2 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  justify-content: center;
}

.normal-3 {
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
}

.b-save {
  border: none;
  color: white;
  background-color: rgb(1, 122, 143);
  padding: 8px;
  border-radius: 10px;
}

.box-scroll-list-proced {
  height: 200px;
  padding: 0px;
  padding-top: 6px;
  padding-right: 12px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow-y: scroll;
}

.li-list-proced {
  padding: 6px 12px;
  border-radius: 10px;
  border: none;
  background-color: rgb(151, 219, 231);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

@media (max-width: 990px) {
  .revenue-radios {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .box-normal-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .box-normal-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .box-normal-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .box-normal-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .normal {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .normal-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .normal-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .normal-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
</style>
