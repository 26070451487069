<template>
  <router-view />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { xray } from './config/pluginInit'

export default {
  name: 'App',
  timer: '',
  time: '',
  components: {},
  created() {
    if (!this.user.id) {
      if (
        this.$router.currentRoute.path !== '/auth/sign-in' &&
        !this.$router.currentRoute.path.includes('AgendamentoOnline') &&
        !this.$router.currentRoute.path.includes('FeedbackAtendimento') &&
        !this.$router.currentRoute.path.includes('ConfirmacaoLogin') &&
        !this.$router.currentRoute.path.includes('confirm-mail') &&
        !this.$router.currentRoute.path.includes('telemedicina') &&
        !this.$router.currentRoute.path.includes('promocao') &&
        !this.$router.currentRoute.path.includes('ConfirmacaoAgendamento')
      ) {
        this.$router.push('/auth/sign-in')
      }
    }
    xray.mainIndex()

    /* this.getIdleTimeAction().then((result) => {
      this.time = result
      this.inactivityTime()--
    }) */
  },

  beforeDestroy() {
    this.logout()
  },

  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },

  methods: {
    ...mapActions({
      logout: 'Setting/logoutUserAction',
      getIdleTimeAction: 'Setting/getIdleTimeAction'
    }),

    inactivityTime() {
      window.onload = this.resetTimer
      // DOM Events
      document.onmousemove = this.resetTimer
      document.onkeydown = this.resetTimer
    },
    timeExpired() {
      this.logout()
      this.$router.push('/auth/sign-in')
    },

    resetTimer() {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.timeExpired, this.time)
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/style.scss';

/* input {
  text-transform: uppercase;
} */

.v-select .vs__dropdown-toggle {
  height: 35px;
  border-radius: 10px;
}

.error .vs__dropdown-toggle {
  border-color: #dc3545;
}
</style>
