<template>
  <b-container fluid style="margin-top: -20px">
    <div class="painel">
      <span>Filtros</span>
      <div class="painel-config">
        <div class="content">
          <label>Clínica:</label>
          <v-select
            class="v-select"
            v-model="clinic"
            :options="clinics"
            label="nmClinica"
            :clearable="false"
          ></v-select>
        </div>
        <div class="box-date">
          <div class="content">
            <label> Vencimento inicial:</label>
            <input type="date" v-model="graphOneDateInit" />
          </div>
          <div class="content">
            <label>Vencimento final:</label>
            <input type="date" :min="limitOneData" v-model="graphOneDateEnd" />
          </div>
        </div>
        <div style="display: flex; align-items: center; gap: 10px">
          <button
            @click="getDataGraphOneAndAtt()"
            class="att"
            style="margin-top: 24px"
          >
            Atualizar
            <span v-if="!isLoading"></span>
            <b-spinner v-else small></b-spinner>
          </button>
          <span
            style="margin-top: 24px"
            id="tooltip"
            target="icon"
            v-b-tooltip.hover.focus.v-info
            :title="tooltipText"
          >
            <i class="fas fa-info-circle" id="icon"></i>
          </span>
        </div>
      </div>
      <div class="notData" v-if="getGraphOne.length === 0">
        <span
          ><strong
            >Não há informações para o período selecionado. Atualize os dados
            dos filtros.</strong
          ></span
        >
      </div>
      <PrimaryGraph v-else></PrimaryGraph>
    </div>
    <div class="painel">
      <span>Receitas</span>
      <div class="row-select-date">
        <div class="painel-config">
          <div class="box-select">
            <div class="box-date">
              <div class="content">
                <label>Vencimento inicial:</label>
                <input type="date" v-model="graphTwoDateInit" />
              </div>
              <div class="content">
                <label>Vencimento final:</label>
                <input
                  type="date"
                  :min="limitTwoData"
                  v-model="graphTwoDateEnd"
                />
              </div>
            </div>
            <div class="content">
              <label>Clínica:</label>
              <v-select
                class="v-select"
                v-model="clinic2"
                :options="clinics2"
                label="nmClinica"
                :clearable="false"
              ></v-select>
            </div>
            <div class="content">
              <label>Profissional:</label>
              <v-select
                class="v-select"
                v-model="doctor"
                :options="doctors"
                label="nmUsuario"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type === false" class="content">
              <label>Tipo:</label>
              <v-select
                v-model="consult"
                :options="listConsult"
                label="descTipoRecebimento"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type === false" class="content">
              <label>Pagador:</label>
              <v-select
                v-model="payment"
                label="nomePagadorFinanceiro"
                :options="listPayment"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type === false" class="content">
              <label>Situação:</label>
              <v-select
                v-model="situetion"
                :options="listSituetion"
                label="descSituacao"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type === false" class="box-options">
              <b-form-checkbox
                v-model="statusAll"
                title="Seleciona todos as opções"
                switch
              ></b-form-checkbox>
              <b-form-radio
                id="checkbox-vencNow"
                v-model="statusVencNow"
                name="checkbox-vencNow"
                :disabled="statusAll === false"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Vencendo hoje
              </b-form-radio>
              <b-form-radio
                :disabled="statusAll === false"
                id="checkbox-vencWeek"
                v-model="statusVencWeek"
                name="checkbox-vencWeek"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Vencendo está semana
              </b-form-radio>
              <b-form-radio
                :disabled="statusAll === false"
                id="checkbox-vencids"
                v-model="statusVencids"
                name="checkbox-vencids"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Vencidos
              </b-form-radio>
            </div>

            <div class="box-buttons">
              <div
                v-if="isVisibleAlterButton && type === false"
                class="box-button"
              >
                <button @click="handleOpenModalAlterBlock(true)" class="att">
                  <b-icon icon="card-checklist" font-scale="1"></b-icon>
                  Alterar em bloco
                </button>
              </div>
              <button @click="attRevenue()" class="att">
                Atualizar
                <span v-if="!isLoading"></span>
                <b-spinner v-else small></b-spinner>
              </button>
              <div class="box-buttons-bnt">
                <b-icon-grid3x3
                  style="cursor: pointer"
                  class="mr-2"
                  font-scale="2"
                  variant="primary"
                  @click="() => setValueTypeGraph('T')"
                ></b-icon-grid3x3>
                <b-icon-graph-up
                  style="cursor: pointer"
                  font-scale="2"
                  variant="primary"
                  @click="() => setValueTypeGraph('G')"
                ></b-icon-graph-up>
              </div>
              <span
                id="tooltip"
                target="icon"
                v-b-tooltip.hover.focus.v-info
                :title="tooltipText"
              >
                <i class="fas fa-info-circle" id="icon"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="notData" v-if="getGraphTwo.length === 0">
        <span
          ><strong
            >Não há informações para o período selecionado. Atualize os dados
            dos filtros.</strong
          ></span
        >
      </div>
      <div style="width: 100%" v-else>
        <revenueGraph v-if="type === true"></revenueGraph>
        <tableRevenue
          v-else
          :type="true"
          :admAndRecp="doctors"
          :data="dataRevenue"
          @openAlter="setIsVisibled"
        />
      </div>
    </div>
    <div class="painel">
      <span>Despesas</span>
      <div class="row-select-date">
        <div class="painel-config">
          <div class="box-select">
            <div class="box-date">
              <div class="content">
                <label>Vencimento inicial:</label>
                <input type="date" v-model="graphTreeDateInit" />
              </div>
              <div class="content">
                <label>Vencimento final:</label>
                <input
                  type="date"
                  :min="limitTwoData"
                  v-model="graphTreeDateEnd"
                />
              </div>
            </div>
            <div class="content">
              <label>Clínica:</label>
              <v-select
                class="v-select"
                v-model="clinic3"
                :options="clinics3"
                label="nmClinica"
                :clearable="false"
              ></v-select>
            </div>
            <div class="content">
              <label>Profissional:</label>
              <v-select
                class="v-select"
                v-model="doctor2"
                :options="doctors2"
                label="nmUsuario"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type2 === false" class="content">
              <label>Tipo:</label>
              <v-select
                v-model="typeExpense"
                :options="listTypeExpense"
                label="descDespesa"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type2 === false" class="content">
              <label>Fornecedor:</label>
              <v-select
                v-model="fornecedor"
                :options="listFornecedor"
                label="descFornecedor"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type2 === false" class="content">
              <label>Centro de Custos:</label>
              <v-select
                v-model="centro"
                :options="listCentro"
                label="descCentroCusto"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type2 === false" class="content">
              <label>Situação:</label>
              <v-select
                v-model="situetion2"
                :options="listSituetion2"
                label="descSituacao"
                :clearable="true"
              ></v-select>
            </div>
            <div v-if="type2 === false" class="box-options">
              <b-form-checkbox
                v-model="statusAll2"
                title="Seleciona todos as opções"
                switch
              ></b-form-checkbox>
              <b-form-checkbox
                id="checkbox-vencNow2"
                v-model="statusVencNow2"
                name="checkbox-vencNo2"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Vencendo hoje
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-vencWeek2"
                v-model="statusVencWeek2"
                name="checkbox-vencWeek2"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Vencendo está semana
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-vencids2"
                v-model="statusVencids2"
                name="checkbox-vencid2"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Vencidos
              </b-form-checkbox>
            </div>
            <div class="box-buttons">
              <div
                v-if="isVisibleAlterButton2 && type2 === false"
                class="box-button"
              >
                <button @click="handleOpenModalAlterBlockE(true)" class="att">
                  <b-icon icon="card-checklist" font-scale="1"></b-icon>
                  Alterar em bloco
                </button>
              </div>
              <button @click="attExpense()" class="att">
                Atualizar
                <span v-if="!isLoading"></span>
                <b-spinner v-else small></b-spinner>
              </button>
              <div class="box-buttons-bnt">
                <b-icon-grid3x3
                  style="cursor: pointer"
                  class="mr-2"
                  font-scale="2"
                  variant="primary"
                  @click="() => setValueInTypeGraphExpense('T')"
                ></b-icon-grid3x3>
                <b-icon-graph-up
                  style="cursor: pointer"
                  font-scale="2"
                  variant="primary"
                  @click="() => setValueInTypeGraphExpense('G')"
                ></b-icon-graph-up>
              </div>
              <span
                id="tooltip"
                target="icon"
                v-b-tooltip.hover.focus.v-info
                :title="tooltipText"
              >
                <i class="fas fa-info-circle" id="icon"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="notData" v-if="getGraphTree.length === 0">
        <span
          ><strong
            >Não há informações para o período selecionado. Atualize os dados
            dos filtros.</strong
          ></span
        >
      </div>
      <div style="width: 100%" v-else>
        <expenseGraph v-if="type2 === true"></expenseGraph>
        <tableExpenses
          v-else
          :type="false"
          :admAndRecp="doctors2"
          :data="dataExpenses"
          @open="setIsVisibled2"
        />
      </div>
    </div>
    <Revenue
      v-if="modalRevenue"
      :types="false"
      :admAndRecp="doctors"
      :user="user"
      @close="revenueClose()"
    ></Revenue>
    <Expenses
      v-if="modalExpenses"
      :types="false"
      :admAndRecp="doctors"
      :user="user"
      @close="expensesClose()"
    ></Expenses>
    <div class="round-button" @click="toggleDropdown">
      <span class="icon">+</span>
      <div v-if="showDropdown" class="dropdown">
        <div class="item-drop" @click.stop="handleModalRevenue()">Receita</div>
        <div class="item-drop" @click.stop="handleModalExpenses()">Despesa</div>
      </div>
    </div>
  </b-container>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Revenue from '../../components/Finance/modals/revenueModal.vue'
import Expenses from '../../components/Finance/modals/expensesModal.vue'
import revenueGraph from '../../components/Finance/graphs/revenueGraph.vue'
import expenseGraph from '../../components/Finance/graphs/expenseGraph.vue'
import PrimaryGraph from '../../components/Finance/graphs/primaryGraph.vue'
import tableRevenue from '../../components/Finance/tables/tableRevenue.vue'
import tableExpenses from '../../components/Finance/tables/tableExpenses.vue'
export default {
  components: {
    PrimaryGraph,
    revenueGraph,
    expenseGraph,
    tableRevenue,
    tableExpenses,
    Revenue,
    Expenses
  },
  data() {
    return {
      isLoading: false,
      tooltipText:
        'A referência do período utilizado é a data de vencimento dos títulos!',
      showDropdown: false,
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      modalRevenue: false,
      modalExpenses: false,
      clinic: '',
      clinics: [],
      clinic2: '',
      clinics2: [],
      clinic3: '',
      clinics3: [],
      doctor: '',
      doctors: [],
      doctor2: '',
      doctors2: [],
      typeGraph: 'Receitas',
      typesGraphs: ['Receitas', 'Despesas'],
      graphOneDateInit: this.firstDayOfMonth(),
      graphOneDateEnd: this.dateAddPlusFive(this.graphOneDateInit),
      graphTwoDateInit: this.firstDayOfMonth(),
      graphTwoDateEnd: this.dateAddPlusFive(this.graphTwoDateInit),
      graphTreeDateInit: this.firstDayOfMonth(),
      graphTreeDateEnd: this.dateAddPlusFive(this.graphTreeDateInit),

      // filtros fixos revenue
      dataRevenue: [],
      listPayment: [],
      payment: '',
      listConsult: [],
      consult: '',
      listSituetion: [],
      situetion: '',
      statusVencids: 'not_accepted',
      statusVencWeek: 'not_accepted',
      statusVencNow: 'not_accepted',
      statusAll: false,

      // filtros fixos expense
      statusVencids2: 'not_accepted',
      statusVencWeek2: 'not_accepted',
      statusVencNow2: 'not_accepted',
      statusAll2: false,
      listTypeExpense: [],
      typeExpense: '',
      fornecedor: '',
      centro: '',
      dataExpenses: [],
      listFornecedor: [],
      listCentro: [],
      listSituetion2: [],
      situetion2: null,
      isVisibleAlterButton: false,
      isVisibleAlterButton2: false
    }
  },
  watch: {
    graphOneDateInit(newValue) {
      this.graphOneDateEnd = this.dateAddPlusFive(newValue)
      this.limitOneData = newValue
    },
    async graphTwoDateInit(newValue) {
      this.graphTwoDateEnd = this.dateAddPlusFive(newValue)
      this.limitTwoData = newValue
      await this.setDatesGraphTwo({
        dateInit: newValue,
        dateFinaly: this.graphTwoDateEnd
      })
    },
    async graphTreeDateInit(newValue) {
      this.graphTreeDateEnd = this.dateAddPlusFive(newValue)
      this.limitTwoData = newValue
      await this.setDatesGraphTree({
        dateInit: newValue,
        dateFinaly: this.graphTreeDateEnd
      })
    },
    async graphTwoDateEnd(newValue) {
      await this.setDatesGraphTwo({
        dateInit: this.graphTwoDateInit,
        dateFinaly: this.dateAddPlusFive(newValue)
      })
    },
    async graphTreeDateEnd(newValue) {
      await this.setDatesGraphTree({
        dateInit: this.graphTreeDateInit,
        dateFinaly: this.dateAddPlusFive(newValue)
      })
    },
    typeGraph(newValue) {
      this.setValueRevenueOrExpenses(newValue)
    },
    clinic2(newValue) {
      this.setClinicGraphTwo({ clinic: newValue })
    },
    clinic3(newValue) {
      this.setClinicGraphTree({ clinic: newValue })
    },
    doctor(newValue) {
      this.setDoctorGraphTwo({ doctor: newValue })
    },
    doctor2(newValue) {
      this.setDoctorGraphTree({ doctor: newValue })
    },
    statusAll(newValue) {
      if (newValue === false) {
        this.statusVencids = 'not_accepted'
        this.statusVencWeek = 'not_accepted'
        this.statusVencNow = 'not_accepted'
      } else {
        this.statusVencids = 'not_accepted'
        this.statusVencWeek = 'not_accepted'
        this.statusVencNow = 'not_accepted'
      }
    },
    statusVencids(newValue) {
      if (newValue === 'accepted') {
        this.statusVencWeek = 'not_accepted'
        this.statusVencNow = 'not_accepted'
      }
    },
    statusVencWeek(newValue) {
      if (newValue === 'accepted') {
        this.statusVencids = 'not_accepted'
        this.statusVencNow = 'not_accepted'
      }
    },
    statusVencNow(newValue) {
      if (newValue === 'accepted') {
        this.statusVencWeek = 'not_accepted'
        this.statusVencids = 'not_accepted'
      }
    },
    statusAll2(newValue) {
      if (newValue === false) {
        this.statusVencids2 = 'not_accepted'
        this.statusVencWeek2 = 'not_accepted'
        this.statusVencNow2 = 'not_accepted'
      } else {
        this.statusVencids2 = 'not_accepted'
        this.statusVencWeek2 = 'not_accepted'
        this.statusVencNow2 = 'not_accepted'
      }
    },
    statusVencids2(newValue) {
      if (newValue === 'accepted') {
        this.statusVencWeek2 = 'not_accepted'
        this.statusVencNow2 = 'not_accepted'
      }
    },
    statusVencWeek2(newValue) {
      if (newValue === 'accepted') {
        this.statusVencids2 = 'not_accepted'
        this.statusVencNow2 = 'not_accepted'
      }
    },
    statusVencNow2(newValue) {
      if (newValue === 'accepted') {
        this.statusVencWeek2 = 'not_accepted'
        this.statusVencids2 = 'not_accepted'
      }
    }
  },
  methods: {
    ...mapActions({
      setValueRevenueOrExpenses: 'Finance/setValueRevenueOrExpenses',
      setValueTypeGraph: 'Finance/setValueInTypeGraph',
      setValueInTypeGraphExpense: 'Finance/setValueInTypeGraphExpense',
      getDataGraphOne: 'Finance/getDataGraphOne',
      getUsersForClinics: 'Finance/getUsersForClinics',
      getDataGraphTwo: 'Finance/getDataGraphTwo',
      getDataGraphTree: 'Finance/getDataGraphTree',
      setDatesGraphTwo: 'Finance/setDatesGraphTwo',
      setDatesGraphTree: 'Finance/setDatesGraphTree',
      setClinicGraphTwo: 'Finance/setClinicGraphTwo',
      setDoctorGraphTwo: 'Finance/setDoctorGraphTwo',
      setClinicGraphTree: 'Finance/setClinicGraphTree',
      setDoctorGraphTree: 'Finance/setDoctorGraphTree',
      getDataTablesRevenue: 'Finance/getDataTablesRevenue',
      getDataTablesExpense: 'Finance/getDataTablesExpense',
      getSuppliers: 'Finance/getSuppliers',
      getCenterCost: 'Finance/getCenterCost',
      getTypes: 'Finance/getTypes',
      getPayers: 'Finance/getPayers',
      getTypesExpenses: 'Finance/getTypesExpenses',
      getStatusFinance: 'Finance/getStatusFinance',
      handleOpenModalAlterBlock: 'Finance/handleOpenModalAlterBlock',
      handleOpenModalAlterBlockE: 'Finance/handleOpenModalAlterBlockE'
    }),
    firstDayOfMonth() {
      const primeiroDiaDoMes = moment().startOf('month').format('YYYY-MM-DD')
      return primeiroDiaDoMes
    },
    setIsVisibled(value) {
      this.isVisibleAlterButton = value
    },
    toogleModalAlter() {
      this.setOpenModalAlterBlock(true)
    },
    dateAddPlusFive(date) {
      const dataMoment = moment(date)
      const dataComMais5Meses = dataMoment.add(5, 'months')
      return dataComMais5Meses.format('YYYY-MM-DD')
    },
    setIsVisibled2(value) {
      this.isVisibleAlterButton2 = value
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    handleModalRevenue() {
      this.showDropdown = false
      this.modalRevenue = true
    },
    handleModalExpenses() {
      this.showDropdown = false
      this.modalExpenses = true
    },
    revenueClose() {
      this.modalRevenue = false
    },
    expensesClose() {
      this.modalExpenses = false
    },
    attRevenue() {
      if (this.type) {
        this.attGraphRevenue()
      } else {
        this.attTableRevenue()
      }
    },
    attExpense() {
      if (this.type2) {
        this.attGraphExpense()
      } else {
        this.attTableExpense()
      }
    },
    async attGraphRevenue() {
      this.isLoading = true
      try {
        let response
        if (this.doctor === null) {
          response = await this.getDataGraphTwo({
            dt_inicio: this.graphTwoDateInit,
            dt_final: this.graphTwoDateEnd,
            id_clinica: this.clinic2.idClinica,
            id_usuario: 0,
            tipo: 'Receitas'
          })
        } else {
          response = await this.getDataGraphTwo({
            dt_inicio: this.graphTwoDateInit,
            dt_final: this.graphTwoDateEnd,
            id_clinica: this.clinic2.idClinica,
            id_usuario: this.doctor.idUsuario,
            tipo: 'Receitas'
          })
        }
        if (response === false) {
          this.$toast.warning(
            'Pesquisa não retornou resultado, reveja os parâmetros de busca.'
          )
        }
      } catch (error) {
        this.$toast.error('Erro ao buscar os dados:', error)
      } finally {
        this.isLoading = false
      }
    },
    async attGraphExpense() {
      this.isLoading = true
      try {
        let response
        if (this.doctor2 === null) {
          response = await this.getDataGraphTree({
            dt_inicio: this.graphTreeDateInit,
            dt_final: this.graphTreeDateEnd,
            id_clinica: this.clinic3.idClinica,
            id_usuario: 0,
            tipo: 'Despesas'
          })
        } else {
          response = await this.getDataGraphTree({
            dt_inicio: this.graphTreeDateInit,
            dt_final: this.graphTreeDateEnd,
            id_clinica: this.clinic3.idClinica,
            id_usuario: this.doctor2.idUsuario,
            tipo: 'Despesas'
          })
        }
        if (response === false) {
          this.$toast.warning(
            'Pesquisa não retornou resultado, reveja os parâmetros de busca.'
          )
        }
      } catch (error) {
        this.$toast.error('Erro ao buscar os dados:', error)
      } finally {
        this.isLoading = false
      }
    },
    async getDataGraphOneAndAtt() {
      this.isLoading = true
      try {
        await this.getDataGraphOne({
          dt_inicio: this.graphOneDateInit,
          dt_final: this.graphOneDateEnd,
          id_clinica: this.clinic.idClinica
        })
      } catch (error) {
        this.$toast.error('Erro ao buscar os dados:', error)
      } finally {
        this.isLoading = false
      }
    },
    async initComponent() {
      this.clinics = this.user.clinica_usuario
      this.clinic = this.user.clinica_usuario[0]
      this.clinics2 = this.user.clinica_usuario
      this.clinic2 = this.user.clinica_usuario[0]
      this.clinics3 = this.user.clinica_usuario
      this.clinic3 = this.user.clinica_usuario[0]
      this.setClinicGraphTwo({ clinic: this.clinic2 })
      this.setClinicGraphTree({ clinic: this.clinic3 })
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          1 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          4 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 5
      ) {
        this.doctor = this.user.clinica_usuario[0]
        this.setDoctorGraphTwo({ doctor: this.doctor })
        this.doctors = [this.user.clinica_usuario[0]]
        this.doctor2 = this.user.clinica_usuario[0]
        this.setDoctorGraphTree({ doctor: this.doctor2 })
        this.doctors2 = [this.user.clinica_usuario[0]]
      }
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          2 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 3
      ) {
        this.doctors = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor = this.doctors[0]
        this.setDoctorGraphTwo({ doctor: this.doctor })
        this.doctors2 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor2 = this.doctors2[0]
        this.setDoctorGraphTree({ doctor: this.doctor2 })
      }
      this.getDataGraphOneAndAtt()
      this.attGraphRevenue()
      this.attGraphExpense()
    },
    async initTables() {
      this.listSituetion = await this.getStatusFinance()
      this.situetion = ''
      this.listSituetion2 = await this.getStatusFinance()
      this.situetion2 = ''
      this.listPayment = await this.getPayers({
        id_clinica: this.doctor.idClinica
      })
      this.payment = ''
      this.listConsult = await this.getTypes({
        id_clinica: this.doctor.idClinica
      })
      this.consult = ''
      this.listTypeExpense = await this.getTypesExpenses({
        id_clinica: this.clinic3.idClinica
      })
      this.listFornecedor = await this.getSuppliers({
        id_clinica: this.doctor2.idClinica
      })
      this.listCentro = await this.getCenterCost({
        id_clinica: this.doctor2.idClinica
      })
      await this.getInitTables()
    },
    async getInitTables() {
      const form = {
        dt_inicio: moment(this.graphTwoDateInit).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        dt_final: moment(this.graphTwoDateEnd).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        idMedicoRecebedor: this.getDoctorGraphTwo.idUsuario,
        idClinicaRecebedor: this.getDoctorGraphTwo.idClinica,
        idClinica: this.getClinicGraphTwo.idClinica,
        idTipoReceita: 0,
        idPagador: 0,
        idStatusFinanceiro: 0,
        buscaVencimento: 0
      }
      const listTable = await this.getDataTablesRevenue(form)
      this.dataRevenue = listTable.reverse()

      const form2 = {
        dt_inicio: moment(this.graphTreeDateInit).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        dt_final: moment(this.graphTreeDateEnd).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        idMedicoPagador: this.getDoctorGraphTree.idUsuario,
        idClinicaRecebedor: this.getDoctorGraphTree.idClinica,
        idClinica: this.getClinicGraphTree.idClinica,
        idTipoDespesa: 0,
        idPagador: 0,
        idCentroCusto: 0,
        idFornecedor: 0,
        idStatusFinanceiro: 0,
        buscaVencimento: 0
      }
      const listTable2 = await this.getDataTablesExpense(form2)
      this.dataExpenses = listTable2.reverse()
    },

    getFilterRadio(value) {
      if (value === 'R') {
        if (this.statusVencids === 'accepted') {
          return 3
        } else if (this.statusVencWeek === 'accepted') {
          return 2
        } else if (this.statusVencNow === 'accepted') {
          return 1
        }
      }
      if (value === 'D') {
        if (this.statusVencids2 === 'accepted') {
          return 3
        } else if (this.statusVencWeek2 === 'accepted') {
          return 2
        } else if (this.statusVencNow2 === 'accepted') {
          return 1
        }
      }
    },
    getValueZeroS(value) {
      if (value === 'R') {
        if (
          this.situetion === '' ||
          this.situetion === undefined ||
          this.situetion === null
        ) {
          return 0
        } else {
          return this.situetion.id
        }
      }
      if (value === 'D') {
        if (
          this.situetion2 === '' ||
          this.situetion2 === undefined ||
          this.situetion2 === null
        ) {
          return 0
        } else {
          return this.situetion2.id
        }
      }
    },
    getValueZeroCenter() {
      if (
        this.centro === '' ||
        this.centro === undefined ||
        this.centro === null
      ) {
        return 0
      } else {
        return this.centro.id
      }
    },
    getValueZeroForncedor() {
      if (
        this.fornecedor === '' ||
        this.fornecedor === undefined ||
        this.fornecedor === null
      ) {
        return 0
      } else {
        return this.fornecedor.id
      }
    },
    getValueZeroC() {
      if (
        this.consult === '' ||
        this.consult === undefined ||
        this.consult === null
      ) {
        return 0
      } else {
        return this.consult.id
      }
    },
    getValueZeroType(value) {
      if (
        this.typeExpense === '' ||
        this.typeExpense === undefined ||
        this.typeExpense === null
      ) {
        return 0
      } else {
        return this.typeExpense.id
      }
    },
    getValueZeroP() {
      if (
        this.payment === '' ||
        this.payment === undefined ||
        this.payment === null
      ) {
        return 0
      } else {
        return this.payment.id
      }
    },
    async attTableRevenue() {
      if (this.doctor === null) {
        const form = {
          dt_inicio: moment(this.graphTwoDateInit).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          dt_final: moment(this.graphTwoDateEnd).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          idMedicoRecebedor: 0,
          idClinicaRecebedor: 0,
          idClinica: this.getClinicGraphTwo.idClinica,
          idTipoReceita: this.getValueZeroC(),
          idPagador: this.getValueZeroP(),
          idStatusFinanceiro: this.getValueZeroS('R'),
          buscaVencimento: this.getFilterRadio('R')
        }
        const listTable = await this.getDataTablesRevenue(form)
        this.dataRevenue = listTable.reverse()
      }
      const form = {
        dt_inicio: moment(this.graphTwoDateInit).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        dt_final: moment(this.graphTwoDateEnd).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        idMedicoRecebedor: this.getDoctorGraphTwo.idUsuario,
        idClinicaRecebedor: this.getDoctorGraphTwo.idClinica,
        idClinica: this.getClinicGraphTwo.idClinica,
        idTipoReceita: this.getValueZeroC(),
        idPagador: this.getValueZeroP(),
        idStatusFinanceiro: this.getValueZeroS('R'),
        buscaVencimento: this.getFilterRadio('R')
      }
      const listTable = await this.getDataTablesRevenue(form)
      this.dataRevenue = listTable.reverse()
    },
    async attTableExpense() {
      if (this.doctor2 === null) {
        const form = {
          dt_inicio: moment(this.graphTreeDateInit).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          dt_final: moment(this.graphTreeDateEnd).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          idMedicoRecebedor: 0,
          idClinicaRecebedor: 0,
          idClinica: this.getClinicGraphTree.idClinica,
          idTipoDespesa: this.getValueZeroType(),
          idPagador: 0,
          idCentroCusto: this.getValueZeroCenter(),
          idFornecedor: this.getValueZeroForncedor(),
          idStatusFinanceiro: this.getValueZeroS('D'),
          buscaVencimento: this.getFilterRadio('D')
        }
        const listTable = await this.getDataTablesExpense(form)
        this.dataExpenses = listTable.reverse()
      }
      const form = {
        dt_inicio: moment(this.graphTreeDateInit).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        dt_final: moment(this.graphTreeDateEnd).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        idMedicoRecebedor: this.getDoctorGraphTree.idUsuario,
        idClinicaRecebedor: this.getDoctorGraphTree.idClinica,
        idClinica: this.getClinicGraphTree.idClinica,
        idTipoDespesa: this.getValueZeroType(),
        idPagador: 0,
        idCentroCusto: this.getValueZeroCenter(),
        idFornecedor: this.getValueZeroForncedor(),
        idStatusFinanceiro: this.getValueZeroS('D'),
        buscaVencimento: this.getFilterRadio('D')
      }
      const listTable = await this.getDataTablesExpense(form)
      this.dataExpenses = listTable.reverse()
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      type: 'Finance/getTypeGraph',
      type2: 'Finance/getTypeGraphExpense',
      getGraphOne: 'Finance/getGraphOne',
      getGraphTwo: 'Finance/getGraphTwo',
      getGraphTree: 'Finance/getGraphTree',
      getDoctorGraphTwo: 'Finance/getDoctorGraphTwo',
      getClinicGraphTwo: 'Finance/getClinicGraphTwo',
      getDoctorGraphTree: 'Finance/getDoctorGraphTree',
      getClinicGraphTree: 'Finance/getClinicGraphTree'
    }),
    limitOneData() {
      return moment(this.graphOneDateInit).format('YYYY-MM-DD')
    },
    limitTwoData() {
      return moment(this.graphTwoDateInit).format('YYYY-MM-DD')
    }
  },
  async mounted() {
    await this.initComponent()
    await this.setDatesGraphTwo({
      dateInit: this.firstDayOfMonth(),
      dateFinaly: this.dateAddPlusFive()
    })
    await this.setDatesGraphTree({
      dateInit: this.firstDayOfMonth(),
      dateFinaly: this.dateAddPlusFive()
    })
    await this.initTables()
  }
}
</script>

<style scoped>
.tooltip {
  opacity: 1 !important;
}
.painel {
  width: 100%;
  padding: 1.2rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 70px;
}

.row-select-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
}

.v-select {
  width: 240px;
}

.box-select {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.painel span {
  font-size: 1.4rem;
  font-weight: 600;
}

.painel-config {
  width: 100%;
  gap: 2rem;
  display: flex;
  align-items: center;
}

.box-date {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.box-date input {
  padding: 0.44rem;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
}

.box-buttons {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.box-buttons-bnt {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.att {
  gap: 0.2rem;
  display: flex;
  align-items: center;
  color: white;
  background-color: rgb(64, 163, 239);
  font-size: 0.9rem;
  padding: 0.4rem;
  border-radius: 10px;
  border: none;
}

.notData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
}

.notData span {
  font-size: 1rem;
}

.round-button {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  bottom: 20px;
  right: 40px;
  border-radius: 50%;
  background-color: #089bab;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
  position: absolute;
  font-size: 20px;
  bottom: 20px;
  right: 29px;
}

.dropdown {
  position: absolute;
  bottom: 40px;
  right: 60px;
  color: black;
  background-color: #ffffff;
  border: 1px solid #089bab;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.item-drop {
  padding: 10px;
  font-weight: bold;
  transition: 0.5s;
}

.item-drop:hover {
  background-color: #089bab;
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box-options {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .box-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
  }
  .box-date {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  .box-options {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
  }
}

@media (max-width: 820px) {
  .row-select-date {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .painel-config {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}
</style>
