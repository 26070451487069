<template>
  <div id="memed-container" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  deletePrescriptionService,
  initPrescriptionService,
  openPrescriptionService,
  updateAllergies,
  updatePatient
} from '../../../prescriptionsService'

export default {
  name: 'MemedIntegration',
  props: {
    token: {
      type: String,
      required: true
    },
    memedParams: {
      type: Object,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    primaryColor: {
      type: String,
      required: true
    },
    handbookId: {
      type: Number,
      required: true
    },
    idClinic: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      allergies: 'MedicalReports/getAllergies'
    })
  },

  watch: {
    patient() {
      this.getAllergies()
    },
    allergies() {
      updateAllergies()
    }
  },
  async mounted() {
    const clinic = await this.getClinic(this.idClinic)

    const payload = {
      token: this.token,
      memedParams: this.memedParams,
      patient: this.patient,
      primaryColor: this.primaryColor,
      handbookId: this.handbookId,
      clinicInfo: clinic
    }
    initPrescriptionService(payload)
    this.initMemed()
  },
  methods: {
    ...mapActions({
      getClinic: 'Clinic/getClinicAction'
    }),
    showMemed() {
      openPrescriptionService()
    },
    async initMemed() {
      window.currentHandbookId = this.handbookId
      if (window.MdHub) {
        updatePatient(this.patient)
        updateAllergies()
      }
    },

    async detroyMemed() {
      deletePrescriptionService()
    }
  }

  /* destroyed() {
    this.detroyMemed()
  } */
}
</script>

<style scoped>
#memed-container {
  margin: 0 auto;
  position: relative;
  background-color: red;
  /* Largura mínima 820px */
  /* width: 100%; */
  width: 0;
  /* min-width: 820px; */
  /* height: 710px; */
  height: 0;
}
</style>
