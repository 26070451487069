<template>
  <b-modal
    id="add-vaccine"
    size="lg"
    :title="whichModal === true ? 'Editar Vacina' : 'Adicionar nova vacina'"
    header-bg-variant="primary"
    title-class="text-light"
    cancel-title="Cancelar"
    cancel-variant="danger"
    ok-title="Salvar"
    @hide="resetModal"
    @ok="whichModal === true ? editVaccine() : addVaccine()"
    @close="resetModal"
    @cancel="resetModal"
  >
    <!-- Modal de editar -->
    <div class="edit-vaccine" v-if="whichModal">
      <div class="detail-vaccine">
        <h1>
          {{ vaccineDetail.descVacina }}
        </h1>
        <div class="detail-config">
          <label>Situação da vacina:</label>
          <div class="detail-vaccine-info">
            <input
              type="checkbox"
              :checked="vaccineDetail.imunizado === 1 ? true : false"
              @click="setImuneDetail()"
            />
            <span>Imune</span>
          </div>
          <div class="detail-vaccine-info">
            <input
              type="checkbox"
              :checked="vaccineDetail.naoIminuzado === 1 ? true : false"
              @click="setNotImuneDetail()"
            />
            <span>Não Imunizada</span>
          </div>
          <div class="detail-vaccine-info">
            <input
              type="checkbox"
              :checked="vaccineDetail.semInformacao === 1 ? true : false"
              @click="setEmptyInfoDetail()"
            />
            <span>Sem informação</span>
          </div>
        </div>
        <div class="detail-config">
          <div class="detail-vaccine-data">
            <label>Data da dose:</label>
            <input
              type="text"
              v-mask="['##/##/####', '##/##/##']"
              placeholder="DD/MM/AAAA"
              v-model="vaccineDetail.dataDose"
            />
          </div>
          <div class="detail-vaccine-data">
            <label>Data de Inclusão:</label>
            <span>{{ formatData(vaccineDetail.dtInclusao) }}</span>
          </div>
        </div>
        <div class="observation">
          <label>Observação geral:</label>
          <textarea
            rows="4"
            placeholder="Digite aqui sua observação sobre a vacina"
            v-model="vaccineDetail.observacaoVacina"
          ></textarea>
        </div>
      </div>
    </div>

    <!-- Modal de adicionar -->
    <div class="add-vaccine" v-else>
      <div class="search-vaccines">
        <input
          class="input-vaccine"
          type="text"
          placeholder="Busque aqui por uma vacina"
          v-model="nameFilter"
          @keyup.enter="filterListVaccinesNamed(nameFilter)"
        />
        <button @click="filterListVaccinesNamed(nameFilter)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="sus-vaccines" v-if="vaccineSelected === null">
        <li
          @click="selectVaccine(vaccine)"
          class="vaccine-option"
          v-for="(vaccine, index) in listVaccines"
          :key="index"
        >
          <h3>{{ vaccine.descVacina }} - {{ vaccine.siglaVacina }}</h3>
        </li>
      </div>
      <div class="detail-vaccine" v-else>
        <h1>
          {{ vaccineSelected.descVacina }} - {{ vaccineSelected.siglaVacina }}
        </h1>
        <div class="detail-config">
          <label>Situação da vacina:</label>
          <div class="detail-vaccine-info">
            <input
              type="checkbox"
              :checked="vacine.imunizado === 1 ? true : false"
              @click="setImune()"
            />
            <span>Imune</span>
          </div>
          <div class="detail-vaccine-info">
            <input
              type="checkbox"
              :checked="vacine.naoIminuzado === 1 ? true : false"
              @click="setNotImune()"
            />
            <span>Não Imunizada</span>
          </div>
          <div class="detail-vaccine-info">
            <input
              type="checkbox"
              :checked="vacine.semInformacao === 1 ? true : false"
              @click="setEmptyInfo()"
            />
            <span>Sem informação</span>
          </div>
        </div>
        <div class="detail-config">
          <div class="detail-vaccine-data">
            <label>Data da dose:</label>
            <input
              type="text"
              v-mask="['##/##/####', '##/##/##']"
              placeholder="DD/MM/AAAA"
              v-model="vacine.dataDose"
            />
          </div>
          <div class="detail-vaccine-data">
            <label>Data de Inclusão:</label>
            <span>{{ formatData(vacine.dtInclusao) }}</span>
          </div>
        </div>
        <div class="observation">
          <label>Observação geral:</label>
          <textarea
            rows="4"
            placeholder="Digite aqui sua observação sobre a vacina"
            v-model="vacine.observacaoVacina"
          ></textarea>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import moment from 'moment'
export default {
  directives: { mask },
  name: 'vaccine-modal',
  props: ['whichModal', 'contentModal'],
  data() {
    return {
      nameFilter: '',
      listVaccines: [],
      vaccineDetail: {},
      vaccineSelected: null,

      // Nova vacina pra adicionar no stado global
      vacine: {
        descVacina: '',
        idVacina: 0,
        numeroDoses: 0,
        indicacaoAplicada: 0,
        naoIminuzado: 0,
        imunizado: 0,
        semInformacao: 0,
        dataDose: '',
        dtInclusao: new Date(),
        dtOcorrencia: new Date(),
        observacaoVacina: ''
      }
    }
  },
  methods: {
    ...mapActions({
      addVaccineInList: 'MedicalReports/addVaccineInList',
      editVaccineInList: 'MedicalReports/editVaccineInList'
    }),
    formatData(data) {
      if (data === '') {
        return 'Sem informação.'
      } else {
        const dataFormatada = moment(data).format('DD/MM/YYYY HH:mm')
        return dataFormatada
      }
    },
    // Filtro da lista de vacinas
    filterListVaccinesNamed(value) {
      this.vaccineSelected = null
      this.listVaccines = this.contentModal
      const listVaccinesFilter = this.listVaccines.filter((vacina) =>
        vacina.descVacina.toLowerCase().includes(value.toLowerCase())
      )
      this.listVaccines = listVaccinesFilter
    },
    // Resata o modal
    resetModal() {
      this.$bvModal.hide('add-vaccine')
      this.$emit('closeModal')
    },

    // Add vacina na lsita global
    addVaccine() {
      this.vacine.dataDose = moment(this.vacine.dataDose, 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      )
      this.vacine.dtInclusao = moment(
        this.vacine.dtInclusao,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      this.vacine.dtOcorrencia = moment(
        this.vacine.dtOcorrencia,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      this.addVaccineInList(this.vacine)
    },

    // Editar Vacina na lista global
    editVaccine() {
      if (
        this.vaccineDetail.dataDose === '' ||
        this.vaccineDetail.dataDose === null
      ) {
        this.vaccineDetail.dataDose = ''
      } else {
        this.vaccineDetail.dataDose = moment(
          this.vaccineDetail.dataDose,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      }
      this.editVaccineInList(this.vaccineDetail)
    },

    // Captura os dados da vacina especifica para mostra nos detalhes
    selectVaccine(vaccine) {
      this.vaccineSelected = {
        ...vaccine
      }
      this.vacine.descVacina = this.vaccineSelected.descVacina
      this.vacine.idVacina = this.vaccineSelected.codigo
    },
    // mudança de estado para radius buttons adicionar
    setImune() {
      this.vacine.naoIminuzado = 0
      this.vacine.semInformacao = 0
      this.vacine.imunizado = 1
    },
    setNotImune() {
      this.vacine.semInformacao = 0
      this.vacine.imunizado = 0
      this.vacine.naoIminuzado = 1
    },
    setEmptyInfo() {
      this.vacine.imunizado = 0
      this.vacine.naoIminuzado = 0
      this.vacine.semInformacao = 1
    },

    // mudança de estado para radius buttons editar
    setImuneDetail() {
      this.vaccineDetail.naoIminuzado = 0
      this.vaccineDetail.semInformacao = 0
      this.vaccineDetail.imunizado = 1
    },
    setNotImuneDetail() {
      this.vaccineDetail.semInformacao = 0
      this.vaccineDetail.imunizado = 0
      this.vaccineDetail.naoIminuzado = 1
    },
    setEmptyInfoDetail() {
      this.vaccineDetail.imunizado = 0
      this.vaccineDetail.naoIminuzado = 0
      this.vaccineDetail.semInformacao = 1
    }
  },
  mounted() {
    this.$bvModal.show('add-vaccine')
    if (this.whichModal) {
      this.vaccineDetail = {
        ...this.contentModal,
        dataDose: moment(this.contentModal.dataDose).format('DD/MM/YYYY HH:mm')
      }
    } else {
      this.listVaccines = this.contentModal
    }
  }
}
</script>
<style scoped>
.sus-vaccines {
  padding: 10px;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  overflow-y: scroll;
  gap: 10px;
}

.search-vaccines {
  position: relative;
}

.input-vaccine {
  margin-bottom: 20px;
  padding: 12px;
  padding-right: 40px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: #c3c4c7 solid 1px;
}

.input-vaccine:focus {
  outline: none;
  border: #0cb1c4 solid 1px;
}

.search-vaccines button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 37%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.search-vaccines i {
  padding: 2px;
  color: #0cb1c4;
}

.sus-vaccines li {
  list-style: none;
}

.vaccine-option {
  padding: 10px;
  border-radius: 8px;
  background-color: #edf6f7;
  color: #c3c4c7;
  cursor: pointer;
  transition: all 400ms ease;
}

.vaccine-option:hover {
  background-color: #0cb1c4;
}

.vaccine-option:active {
  background-color: #022d32;
}

.detail-vaccine {
  border: solid 0.5px #c3c4c7;
  background-color: #edf6f7;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.detail-vaccine h1 {
  font-size: 1.6rem;
}

.detail-config {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detail-vaccine-info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.detail-vaccine-info input {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.detail-vaccine-data {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.detail-vaccine-data input {
  padding: 8px 12px;
  padding-right: 40px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: #c3c4c7 solid 1px;
}

.detail-vaccine-data input:focus {
  outline: none;
  border: #0cb1c4 solid 1px;
}

.observation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.observation textarea {
  width: 100%;
  border: 1px solid #b5bbbf;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  border: #c3c4c7 solid 1px;
}

.observation textarea:focus {
  outline: none;
  border: #0cb1c4 solid 1px;
}
</style>
