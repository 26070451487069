<template>
  <div>
    <div class="module__wrapper">
      <div class="module__options">
        <b-icon
          font-scale="2"
          class="module__options-icon"
          icon="grid"
          variant="primary"
        ></b-icon>
        <div class="module__info">
          <input
            :class="
              isEditingModuleName
                ? 'module__info-title__editing'
                : 'module__info-title'
            "
            v-model="moduleName"
            :readonly="isEditingModuleName ? false : true"
            maxlength="36"
          />
          <p class="module__info-subtitle">
            Seção {{ module.sequencia + 1 }} de {{ modulesLength }}
          </p>
        </div>
      </div>

      <b-icon
        @click="isEditingModuleName = !isEditingModuleName"
        :icon="this.isEditingModuleName ? 'check2-square' : 'pencil-square'"
        :variant="this.isEditingModuleName ? 'success' : 'primary'"
        font-scale="1.5"
        class="modal-info__edit"
      ></b-icon>

      <div class="module__icons">
        <b-icon
          @click="swapUp(module)"
          icon="triangle-fill"
          flip-v
          variant="primary"
          font-scale="1.3"
          class="mr-4 ml-1"
        ></b-icon>

        <b-icon
          icon="triangle-fill"
          variant="primary"
          font-scale="1.3"
          @click="swapDown(module)"
        ></b-icon>
      </div>

      <div
        class="modal-info__active-checkbox"
        @click.prevent="toggleExpanded(module)"
      >
        <b-form-checkbox switch :checked="module.aberto === 0" />
      </div>
      <div
        class="modal-info__open-checkbox"
        @click.prevent="toggleActive(module)"
      >
        <b-form-checkbox switch :checked="module.ativo === 0" />
      </div>

      <div class="modal-info__pallete">
        <input
          type="color"
          v-model="module.corHexadecimal"
          @change="changeModuleBackground(module, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moduleMethods from '../../shared/modulesMethods'
export default {
  name: 'AsoUserPreference',
  props: ['module', 'modulesLength'],
  data() {
    return {
      isEditingModuleName: false,
      moduleName: this.module.descMeuModulo
    }
  },

  destroyed() {
    if (this.moduleName !== this.module.descMeuModulo) {
      this.changeModuleName(this.module, this.moduleName)
    }
  },

  methods: {
    ...moduleMethods
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalStyles.scss';
</style>
