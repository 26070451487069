<template>
  <b-modal
    id="modalCanceled"
    header-bg-variant="primary"
    title="Cancelamento de Recorrência"
    hide-footer
    size="md"
    title-class="text-light"
    @close="close()"
    @hidden="close()"
  >
    <div class="container-canceled">
      <div class="box-radio">
        <div class="standard"></div>
        <b-form-radio
          id="checkbox-one"
          v-model="statusOne"
          name="checkbox-one"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Cancelar todos os agendamentos recorrêntes futuros
        </b-form-radio>

        <div class="standard"></div>
        <b-form-radio
          id="checkbox-two"
          v-model="statusTwo"
          name="checkbox-tow"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Remover todos os agendamentos futuro
        </b-form-radio>
        <b-form-radio
          id="checkbox-tree"
          v-model="statusTree"
          name="checkbox-tree"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Cancelar
        </b-form-radio>
      </div>
      <button class="button-canceled" @click="() => {}">
        Cancelar Recorrência
      </button>
    </div>
  </b-modal>
</template>
<script>
import API from '../../services/index'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['idAgenndamento'],
  data() {
    return {
      dataList: [],
      statusOne: 'not_accepted',
      statusTwo: 'not_accepted',
      statusTree: 'not_accepted'
    }
  },
  mounted() {
    this.$bvModal.show('modalCanceled')
    window.addEventListener('keydown', this.handleKeyPress)
  },
  watch: {
    statusOne(newValue) {
      if (newValue === 'accepted' || this.statusOne === 'accepted') {
        this.statusTwo = 'not_accepted'
        this.statusTree = 'not_accepted'
      }
    },
    statusTwo(newValue) {
      if (newValue === 'accepted' || this.statusTwo === 'accepted') {
        this.statusTree = 'not_accepted'
        this.statusOne = 'not_accepted'
      }
    },
    statusTree(newValue) {
      if (newValue === 'accepted' || this.statusTree === 'accepted') {
        this.statusTwo = 'not_accepted'
        this.statusOne = 'not_accepted'
      }
    }
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({}),
    async canceled() {
      if (this.statusTree === 'accepted') {
        this.$emit('cancelModal')
      } else {
        if (this.statusOne === 'accepted') {
          try {
            await API.delete('/api/Agenda/CancelamentoRecorrenciaAsync', {
              params: {
                id_agenda_origem: this.idAgenndamento,
                acao_cancelamento: 'cancelar'
              }
            })
            this.$toast.success('Cancelamento executado com sucesso!')
          } catch (err) {
            this.$toast.error(
              'Desculpe não foi possivel cancelar os agendamentos!'
            )
          }
        }
        if (this.statusTwo === 'accepted') {
          try {
            await API.delete('/api/Agenda/CancelamentoRecorrenciaAsync', {
              params: {
                id_agenda_origem: this.idAgenndamento,
                acao_cancelamento: 'remover'
              }
            })
            this.$toast.success('Todos os itens foram removidos com sucesso!')
          } catch (err) {
            this.$toast.error(
              'Desculpe não foi possivel remover os agendamentos!'
            )
          }
        }
      }
    },
    close() {
      this.$emit('closeModal')
    },
    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress)
  }
}
</script>
<style scoped>
.container-canceled {
  padding: 6px;
  width: 100%;
  color: #181818;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.box-radio {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.button-canceled {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 6px 12px;
  color: white;
  background-color: red;
  border-radius: 10px;
  border: none;
}
</style>
