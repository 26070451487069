var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('div',{staticClass:"details"},[(_vm.medicalReportType !== 2)?_c('div',{class:'details__name' +
        (_vm.componentInfo.descMeuModulo.length > 31
          ? ' font-medium'
          : ' font-large'),style:(`background-color: ${_vm.componentInfo.corHexadecimal}`)},[_vm._v(" "+_vm._s(_vm.componentInfo.descMeuModulo)+" ")]):_vm._e(),_c('div',{staticClass:"details__checkbox-container"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.showInactiveASOs),callback:function ($$v) {_vm.showInactiveASOs=$$v},expression:"showInactiveASOs"}}),_c('span',[_vm._v("Exibir inativos")])],1),_c('div',{staticClass:"box-options-aso"},[_c('v-select',{staticStyle:{"width":"160px"},attrs:{"clearable":false,"options":_vm.tabledOptions},model:{value:(_vm.tableOption),callback:function ($$v) {_vm.tableOption=$$v},expression:"tableOption"}}),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success"},on:{"click":_vm.openAsoForm}},[_c('span',[_vm._v("Novo")])])],1),_c('b-icon',{staticClass:"module__icon",staticStyle:{"cursor":"pointer"},attrs:{"icon":"chevron-expand","font-scale":"2","variant":"primary"},on:{"click":function($event){return _vm.toggleExpanded()}}}),_c('b-icon',{staticClass:"module__icon",staticStyle:{"cursor":"pointer"},attrs:{"icon":"three-dots-vertical","font-scale":"2","variant":"primary"}})],1),(_vm.componentInfo.aberto === 0)?_c('div',{staticClass:"content"},[_c('aso-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableOption === 'ASO'),expression:"tableOption === 'ASO'"}],attrs:{"headerColor":_vm.componentInfo.corHexadecimal,"ASOs":_vm.componentInfo.data,"showInactiveASOs":_vm.showInactiveASOs,"componentInfo":_vm.componentInfo,"medicOpinions":_vm.medicOpinions,"licenceTypes":_vm.licenceTypes,"handbookId":_vm.handbookInfo.id},on:{"setAsoToEdit":(aso) => {
          _vm.asoToEdit = aso.id
          _vm.asoFormIsOpened = true
        },"setAsoToPrint":(aso) => {
          _vm.asoToPrint = aso.id
          _vm.asoPdfGeneratorIsOpened = true
        }}}),_c('visualAcuityList',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableOption === 'Acuidade Visual'),expression:"tableOption === 'Acuidade Visual'"}],attrs:{"headerColor":'#089bab',"acuity":_vm.acuityComponent,"showInactive":_vm.showInactiveASOs,"medicOpinions":_vm.medicOpinions,"licenceTypes":_vm.licenceTypes,"handbookId":_vm.handbookInfo.id},on:{"savedAcuity":_vm.handleRefreshAcuity,"setAcuityToEdit":(acuity) => {
          _vm.acuityToEdit = acuity.id
          _vm.asoFormIsOpened = true
        },"setAcuityToPrint":(acuity) => {
          _vm.acuityToPrint = acuity.id
          _vm.acuityPdfGeneratorIsOpened = true
        }}})],1):_vm._e(),(_vm.asoFormIsOpened)?_c('aso-form',{attrs:{"idClinic":_vm.handbookInfo.idClinica,"idHandbook":_vm.idProntuario,"idPatient":_vm.idPaciente,"medicOpinions":_vm.medicOpinions,"licenceTypes":_vm.licenceTypes,"asoToEdit":_vm.asoToEdit,"acuityToEdit":_vm.acuityToEdit,"options":_vm.tableOption},on:{"saved":_vm.handleRefreshASOs,"close":() => {
        _vm.asoFormIsOpened = false
        _vm.asoToEdit = ''
        _vm.acuityToEdit = ''
      }}}):_vm._e(),(_vm.asoPdfGeneratorIsOpened)?_c('aso-pdf-generator',{attrs:{"asoId":_vm.asoToPrint,"idClinic":_vm.handbookInfo.idClinica},on:{"close":() => {
        _vm.asoPdfGeneratorIsOpened = false
        _vm.asoToPrint = ''
      }}}):_vm._e(),(_vm.acuityPdfGeneratorIsOpened)?_c('acuityPdfGenerator',{attrs:{"acuityID":_vm.acuityToPrint,"idClinic":_vm.handbookInfo.idClinica},on:{"close":() => {
        _vm.acuityPdfGeneratorIsOpened = false
        _vm.acuityToPrint = ''
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }