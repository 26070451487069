export default {
  digitalAssignState(state) {
    return state.object
  },

  digitalAssignModalIsVisible(state) {
    return state.isVisible
  },

  openedFromMedicalReports(state) {
    return state.openedFromMedicalReports
  },

  switchState(state) {
    return state.isActive
  },
  certificate(state) {
    return state.certificate
  }
}
