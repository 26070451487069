<template>
  <div>
    <Loader />
    <section class="sign-in-page">
      <b-container class="sign-in-page-bg mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <a class="sign-in-logo mb-5" href="#"
                ><img :src="logo" class="img-fluid" alt="logo"
              /></a>
              <Slick :option="slickOptions">
                <div class="item">
                  <img
                    :src="
                      require('../../assets/images/login/telemedicina_site.png')
                    "
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <!-- <h4 class="mb-1 text-white">Agilize o seu atendimento</h4>
                  <p>
                    Personalize seu prontuário, configure seus acessos rápidos e
                    favoritos e tenha ainda mais tempo para o seu paciente.
                  </p> -->
                </div>
                <div class="item">
                  <img
                    :src="
                      require('../../assets/images/login/integrado_site.png')
                    "
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <!-- <h4 class="mb-1 text-white">Prescrição Digital</h4>
                  <p>
                    Conte com uma base atualizada de medicamentos, alerta de
                    alergias e interações medicamentosa.
                  </p> -->
                </div>
                <div class="item">
                  <img
                    :src="
                      require('../../assets/images/login/completo_site.png')
                    "
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <!-- <h4 class="mb-1 text-white">Indicadores</h4>
                  <p>
                    Defina suas metas e tenha uma visão diária do seus
                    rendimentos por convenio e acompanhe o crescimento de seu
                    consultório.
                  </p> -->
                </div>
              </Slick>

              <br />
              <br />
              <span>Versão {{ appVersion }}</span>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div
              :class="
                this.$router.currentRoute.path === '/auth/sign-up/page-form2'
                  ? 'sign-up-from'
                  : 'sign-in-from'
              "
            >
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
import logo from '../../assets/images/logo-black-white.png'
import bgImage from '../../assets/images/login/2.png'
export default {
  name: 'AuthLayout1',
  components: {
    Loader
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION
    }
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2500
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>

<style scoped>
img {
  border-radius: 15px;
}
</style>
