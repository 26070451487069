import API from '../../services/index'
const actions = {
  async getAcuityByPatient(context, payload) {
    try {
      const apiResponse = await API.get(
        '/api/ProntuarioAsoAcuidadeVisual/ObtemAsoPorPaciente',
        {
          params: {
            id_clinica: payload.idClinic,
            id_paciente: payload.idPatient,
            id_medico: payload.idMedic
          }
        }
      )

      return apiResponse.data.object
    } catch (error) {
      if (error.response.status === 404) {
        return []
      } else {
        return error
      }
    }
  },
  async getCompleteAcuityByIdAndClinic(context, payload) {
    try {
      const apiResponse = await API.get(
        '/api/ProntuarioAsoAcuidadeVisual/ObtemItemAcuidadeVisualCompleto',
        {
          params: {
            id_clinica: payload.idClinic,
            id_prontuario_aso: payload.idAcuity
          }
        }
      )
      return apiResponse.data.object
    } catch {
      return false
    }
  },
  async addAcuityToHandBook({ commit }, { payload }) {
    try {
      await API.post('/api/ProntuarioAsoAcuidadeVisual/Adicionar', {
        object: payload
      })
    } catch (error) {}
  },
  async editAcuityFromHandBook({ commit }, { payload }) {
    try {
      await API.put('/api/ProntuarioAsoAcuidadeVisual/Editar', {
        object: payload
      })
    } catch (error) {}
  },
  async removeAcuityFromHandBook({ commit }, payload) {
    try {
      await API.delete('/api/ProntuarioAsoAcuidadeVisual/Remover', {
        params: {
          id_prontuario_aso: payload
        }
      })
    } catch (error) {}
  },
  async updateAcuityAtivo({ commit }, payload) {
    try {
      payload.ativo = 1
      await API.put('/api/ProntuarioAsoAcuidadeVisual/Editar', {
        object: payload
      })
    } catch (error) {}
  }
}

export default actions
