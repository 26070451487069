<template>
  <div>
    <h2 class="mb-0">Bem vindo ao DoctorPEP</h2>
    <p>Digite seu endereço de usuário e senha.</p>
    <SignInForm></SignInForm>
  </div>
</template>

<script>
import { xray } from '../../config/pluginInit'
import SignInForm from './Forms/SignInForm'

export default {
  name: 'SignIn',
  components: { SignInForm },
  data: () => ({}),
  methods: {},
  mounted() {
    xray.index()
  }
}
</script>
