<template>
  <div class="box-graph">
    <chart :options="chartOptions" :data="chartDataOne" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
)

export default {
  name: 'LineChart',
  components: { chart: Line },
  data() {
    return {
      chartOptions: { responsive: true, maintainAspectRatio: false }
    }
  },
  computed: {
    ...mapGetters({
      getGraphTwo: 'Finance/getGraphTwo',
      dateInitAndFinGraphTwo: 'Finance/dateInitAndFinGraphTwo'
    }),
    chartDataOne() {
      return {
        labels: this.getDateRec(),
        datasets: [
          {
            label: 'RECEITAS',
            data: this.getValuesRec(),
            backgroundColor: '#1abc9c',
            borderColor: '#1abc9c',
            pointStyle: 'circle',
            pointRadius: 8,
            pointHoverRadius: 10
          }
        ]
      }
    }
  },
  methods: {
    getValuesRec() {
      if (this.getGraphTwo.length === 0) {
        return [0]
      } else {
        const listAux = []
        const list = this.getGraphTwo
        list.forEach((element) => {
          if (element.tipo === 'Receitas') {
            listAux.push(element)
          }
        })
        const listOrder = this.orderForDataRevenue(listAux)
        const listValues = []
        listOrder.forEach((element) => {
          listValues.push(element.valor)
        })
        return listValues
      }
    },
    getDateRec() {
      const listAux = []
      this.getGraphTwo.forEach((element) => {
        if (element.tipo === 'Receitas') {
          listAux.unshift(element.mesReferencia)
        }
      })
      const listOrder = listAux.sort((a, b) => {
        const [monthA, yearA] = a.split('/').map(Number)
        const [monthB, yearB] = b.split('/').map(Number)

        if (yearA !== yearB) {
          return yearA - yearB // Ordena por ano
        }

        return monthA - monthB // Ordena por mês se os anos forem iguais
      })
      return listOrder
    },
    orderForDataRevenue(listaDeObjetos) {
      function converterParaData(mesReferenciaString) {
        const [mes, ano] = mesReferenciaString.split('/')
        return new Date(ano, mes - 1)
      }

      function compararPorMesReferencia(a, b) {
        const mesReferenciaA = converterParaData(a.mesReferencia)
        const mesReferenciaB = converterParaData(b.mesReferencia)

        if (mesReferenciaA < mesReferenciaB) {
          return -1
        }
        if (mesReferenciaA > mesReferenciaB) {
          return 1
        }
        return 0
      }

      listaDeObjetos.sort(compararPorMesReferencia)

      return listaDeObjetos
    }
  }
}
</script>

<style scoped>
.box-graph {
  width: 100%;
  height: 400px;
}
</style>
