import API from '../../services/index'

const actions = {
  async getReportOfTreatmentAndSchedule({ state }, payload) {
    try {
      const response = await API.get(
        '/api/Agenda/ObtemRelatorioAtendimentoAgenda',
        {
          params: {
            data_inicio: payload.data_inicio,
            data_final: payload.data_final,
            id_usuario: payload.id_usuario,
            id_clinica: payload.id_clinica,
            id_status: payload.id_status,
            adiciona_horarios_livres: payload.hrsLivres,
            id_convenio: payload.idConvenio
          }
        }
      )
      return response.data.object
    } catch (err) {
      return err
    }
  },
  async getAllStatusSchedule({ state }, payload) {
    try {
      const response = await API.get('/api/StatusAgenda/ObtemListaCompleta')
      return response.data.object
    } catch (err) {
      return err
    }
  }
}

export default actions
