const VITAL_SIGNS_MODULE_ID = 7

const getters = {
  vitalSignsFavorites: (state) => {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGNS_MODULE_ID
    )
    return module.favorites
  },

  currentVitalSign: (state) => (id) => {
    const module = state.modules.find(
      (module) => module.idModulo === VITAL_SIGNS_MODULE_ID
    )

    const current = module.data.find(
      (vitalSign) => vitalSign.idProntuario === id
    )

    if (current) {
      return current
    }

    return {}
  }
}

export default getters
