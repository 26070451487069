import { render, staticRenderFns } from "./pdfGenerator.vue?vue&type=template&id=bb61c166&scoped=true"
import script from "./pdfGenerator.vue?vue&type=script&lang=js"
export * from "./pdfGenerator.vue?vue&type=script&lang=js"
import style0 from "./pdfGenerator.vue?vue&type=style&index=0&id=bb61c166&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb61c166",
  null
  
)

export default component.exports