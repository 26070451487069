var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"mt-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"Login","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"box"},[_c('label',{attrs:{"for":"emailInput"}},[_vm._v("Login:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth.login),expression:"auth.login"},{name:"uppercase",rawName:"v-uppercase"}],ref:"login",class:'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : ''),style:(_vm.isIOS
              ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 35px'
              : ''),attrs:{"aria-describedby":"emailHelp","placeholder":"Digite seu login...","required":""},domProps:{"value":(_vm.auth.login)},on:{"click":_vm.selecionarTexto,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.auth, "login", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v("Login inválido")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"box"},[_c('label',{attrs:{"for":"passwordInput"}},[_vm._v("Senha:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth.password),expression:"auth.password"}],ref:"passoword",class:'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : ''),style:(_vm.isIOS
              ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 35px'
              : ''),attrs:{"type":"password","id":"passwordInput","placeholder":"Digite sua senha...","required":""},domProps:{"value":(_vm.auth.password)},on:{"click":_vm.selecionarPassord,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.auth, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v("Senha inválida")])]),(_vm.capsLock)?_c('div',{staticStyle:{"color":"#e56773","margin":"0px","font-size":"0.6rem"}},[_c('span',[_vm._v("Capslock ativado")])]):_vm._e()]),_c('router-link',{staticClass:"float-left",attrs:{"to":"/auth/password-reset"}},[_vm._v(" Esqueceu a senha? ")])]}}],null,true)}),_c('div',{staticClass:"d-inline-block w-100"},[_c('button',{staticClass:"btn btn-primary float-right",staticStyle:{"width":"80px"},attrs:{"type":"submit","disabled":_vm.logging}},[(_vm.logging)?_c('b-spinner',{attrs:{"small":"","label":"Spinning"}}):_c('span',[_vm._v("Acessar")])],1)]),_c('div',{staticClass:"sign-info"},[_c('span',{staticClass:"dark-color d-inline-block line-height-2"},[_vm._v(" Primeiro acesso? "),_c('router-link',{staticClass:"iq-waves-effect pr-4",attrs:{"to":"/auth/sign-up/page-form1"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Cadastre-se")])],1)],1)])],1),_c('b-modal',{staticClass:"custom-large-modal",attrs:{"title":"Selecione o perfil","title-class":"text-light","header-bg-variant":"primary","no-close-on-esc":"","no-close-on-backdrop":"","cancel-title":"Cancelar","cancel-variant":"danger"},on:{"hide":_vm.resetProfileSelection},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-select',{attrs:{"options":_vm.availableProfiles.map((perfil, index) => ({
            index,
            label: perfil?.descPerfil
          })),"placeholder":"Selecione um perfil...","size":"lg"},model:{value:(_vm.selectedProfile),callback:function ($$v) {_vm.selectedProfile=$$v},expression:"selectedProfile"}})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.resetProfileSelection}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.selectProfile}},[_vm._v(" Salvar ")])]},proxy:true}],null,true),model:{value:(_vm.showProfileSelectionModal),callback:function ($$v) {_vm.showProfileSelectionModal=$$v},expression:"showProfileSelectionModal"}}),(_vm.termsOfUseIsVisible)?_c('terms-of-use-modal',{attrs:{"alreadyAccepted":false},on:{"close":function($event){_vm.termsOfUseIsVisible = false},"confirm":_vm.continueLogin}}):_vm._e(),(_vm.showRenewPassword)?_c('RenewPassword',{attrs:{"idUsuario":_vm.idUser},on:{"closeModal":_vm.closeShowRenewPassword}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }