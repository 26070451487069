import ProblemsGetters from './submodules/problems/getters'
import PersonalAndFamiliarHistoryGetters from './submodules/personalHistory/getters'
import PhysicalExamGetters from './submodules/physicalExam/getters'
import ConductGetters from './submodules/conduct/getters'
import ComplaintGetters from './submodules/complaints/getters'
import VitalSignsGetters from './submodules/vitalSigns/getters'
import AllergyGetters from './submodules/allergy/getters'
import PrescriptionsGetters from './submodules/prescriptions/getters'
import Pregnant from './submodules/pregnancy/getters'
import Tracking from './submodules/tracking/getters'

const getters = {
  ...ProblemsGetters,
  ...PersonalAndFamiliarHistoryGetters,
  ...ConductGetters,
  ...PhysicalExamGetters,
  ...ComplaintGetters,
  ...VitalSignsGetters,
  ...AllergyGetters,
  ...PrescriptionsGetters,
  ...Pregnant,
  ...Tracking,

  allModules(state) {
    return state.modules
  },
  activeModules(state) {
    return state.modules.filter((module) => module.ativo === 0)
  },

  getModuleById: (state) => (id) => {
    const module = state.modules.find((module) => module.id === id)

    return module
  },

  getModuleByIdModule: (state) => (id) => {
    const module = state.modules.find((module) => module.idModulo === id)

    return module
  },

  getModuleFavoritesById: (state, getters) => (id) => {
    const module = getters.getModuleById(id)

    if (module) {
      return module.favorites
    } else {
      return null
    }
  },

  getFavorites(state) {
    return function (moduleName) {
      return state.modules.find((module) => module.name === moduleName)
        .favorites
    }
  },
  getModulesStateChanged(state) {
    return state.modulesStateChanged
  },
  getMedicalReportType(state) {
    return state.medicalReportType.idModelo
  }
}

export default getters
