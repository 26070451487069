<template>
  <div>
    <h2 class="mb-0">Bem vindo ao DoctorPEP</h2>
    <p>Informe os dados básicos de acesso.</p>
    <router-view></router-view>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import auth0 from 'auth0-js'
import constant from '@/config/constant'

export default {
  name: 'SignUp',
  data: () => ({
    step: 1
  }),
  methods: {
    loginOAuth0() {
      new auth0.WebAuth(constant.auth0Config).authorize()
    }
  },
  mounted() {
    xray.index()
  }
}
</script>
