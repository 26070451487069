<template>
  <b-modal
    id="pdf-modal"
    size="sm"
    header-bg-variant="primary"
    title="Exportação de PDF"
    no-close-on-backdrop
    no-close-on-esc
    @close="close"
  >
    <div id="modal-body">
      <div class="modal-body-hidder">
        <span class="hidder-warn" v-if="!pdfExported">
          O PDF está sendo gerado...</span
        >
        <span class="hidder-warn" v-if="pdfExported"
          >PDF gerado com sucesso.</span
        >
      </div>
      <div id="pdf-container" v-if="acuity">
        <section id="clinic-section">
          <div class="clinic-logo-container">
            <img class="logo" v-if="image" :src="image" alt="" />
          </div>
          <div class="clinic-info-container">
            <span class="clinic-title"> Acuidade Visual </span>
            <span>
              {{ formatPhone(acuity.dadosClinica?.telefone) || '**' }}
            </span>
            <span>
              {{ acuity.dadosClinica?.logradouro || '**' }} Nº
              {{ acuity.dadosClinica?.numero || '**' }} - CEP:
              {{ formatCEP(acuity.dadosClinica.cep?.toString()) || '**' }}
            </span>
            <span class="clinic-name"
              >{{ acuity.dadosClinica?.descClinica || '**' }}
            </span>
          </div>
          <div class="date-container">{{ handleFormatDate() }}</div>
        </section>

        <section id="generic-section">
          <div class="subtitle">Empresa</div>

          <div class="grid">
            <div class="col">
              <span>Razão Social: </span>
              <span>CNPJ: </span>
              <span>Endereço: </span>
              <span>Cidade/UF: </span>
            </div>
            <div class="col">
              <span>{{ acuity.dadosEmpresa.razaoSocial || '**' }} </span>
              <span>{{ formatCNPJ(acuity.dadosEmpresa?.cnpj) || '**' }} </span>
              <span>
                {{ acuity.dadosEmpresa?.logradouro || '**' }} Nº
                {{ acuity.dadosEmpresa?.numero || '**' }}
              </span>
              <span
                >{{ acuity.dadosEmpresa?.descCidade || '**' }} /
                {{ acuity.dadosEmpresa?.descSiglaEstado || '**' }}</span
              >
            </div>
            <div class="col">
              <span>Bairro: </span>
              <span>CEP: </span>
            </div>
            <div class="col">
              <span>{{ acuity.dadosEmpresa.bairro || '**' }} </span>
              <span>{{ formatCEP(acuity.dadosEmpresa.cep) || '**' }} </span>
            </div>
          </div>
        </section>

        <section id="generic-section">
          <div class="subtitle">Funcionário</div>

          <div class="grid">
            <div class="col">
              <span>Nome: </span>
              <span>CPF: </span>
              <span>Nascimento: </span>
              <span>Cargo: </span>
            </div>
            <div class="col">
              <span style="font-weight: 700"
                >{{ acuity.nmPaciente || '**' }}
              </span>
              <span>{{ formatCPF(acuity.cpfPaciente) || '**' }} </span>
              <span
                >{{ handleFormatDate(acuity.nascimentoPaciente) || '**' }}
              </span>
              <span>{{ acuity.descCargoTrabalho || '**' }} </span>
            </div>
            <div class="col">
              <span>RG: </span>
              <span>Sexo: </span>
            </div>
            <div class="col">
              <span>{{ acuity.rgPaciente || '**' }} </span>
              <span>{{ acuity.sexoPaciente?.descSexo || '**' }} </span>
            </div>
          </div>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">Médico responsável:</span>

          <span style="margin-right: 15px">{{
            acuity.nmUsuarioMedicoTrabalho || '**'
          }}</span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">Cor dos olhos:</span>

          <span style="margin-right: 15px">{{ acuity.corOlhos || '**' }}</span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">
            Para Longe - Acuidade Visual em olho direito:
            <strong class="strong-person">{{ acuity.longeOlhoDireito }}</strong>
          </span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">
            Para Longe - Acuidade Visual em olho esquerdo:
            <strong class="strong-person">{{
              acuity.longeOlhoEsquerdo
            }}</strong>
          </span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">
            Visão Binocular:
            <strong class="strong-person">{{ acuity.visaoBinocular }}</strong>
          </span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">
            Visão Cromática:
            <strong class="strong-person">{{ acuity.visaoCromatica }}</strong>
          </span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">Correção Visual:</span>

          <span style="margin-right: 15px">{{
            `(${acuity.correcaoVisual === 'SIM' ? 'X' : '  '}) SIM  (${
              acuity.correcaoVisual === 'NÃO' ? 'X' : '  '
            }) NÃO`
          }}</span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">Conclusão:</span>

          <span style="margin-right: 15px">{{
            `(${acuity.conclusao === 'NORMAL' ? 'X' : '  '}) NORMAL  (${
              acuity.conclusao === 'ALTERADO' ? 'X' : '  '
            }) ALTERADO`
          }}</span>
        </section>

        <section id="generic-section-2">
          <span class="subtitle-2">Observação:</span>

          <span style="margin-right: 15px">{{ acuity.observacao }}</span>
        </section>

        <div id="footer">
          <div
            style="
              border-top: 1px solid black;
              width: 150px;
              text-align: center;
            "
          >
            <span>Carimbo e Assinatura</span>
          </div>

          <div
            style="
              border-top: 1px solid black;
              width: 150px;
              text-align: center;
            "
          >
            {{ acuity.nmUsuarioMedicoTrabalho }}
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        size="sm"
        variant="primary"
        @click="generatePDF"
        :disabled="!pdfExported"
      >
        Gerar Novamente
      </b-button>
      <b-button size="sm" variant="danger" @click="close"> Sair </b-button>
    </template>
  </b-modal>
</template>

<script>
import jsPDF from 'jspdf'
import { mapActions } from 'vuex'
import { formatDate } from '@/Utils/formatters'
export default {
  name: 'ExportPDF',
  props: ['acuityID', 'idClinic'],
  data() {
    return {
      acuity: '',
      pdfExported: false,
      image: ''
    }
  },
  computed: {},
  watch: {
    acuity(newValue) {
      if (newValue.dadosClinica.descArquivoImagem) {
        this.handleGetClinicImage()
      } else {
        setTimeout(() => {
          this.generatePDF()
        }, 1000)
      }
    },
    image() {
      setTimeout(() => {
        this.generatePDF()
      }, 1000)
    }
  },
  methods: {
    ...mapActions({
      getAcuity: 'Acuity/getCompleteAcuityByIdAndClinic',
      getClinicImage: 'ASO/getClinicImage'
    }),
    async handleGetAcuity() {
      const acuity = await this.getAcuity({
        idAcuity: this.acuityID,
        idClinic: this.idClinic
      })

      this.acuity = acuity
    },
    async handleGetClinicImage() {
      const imageBlob = await this.getClinicImage({
        id: this.acuity.dadosClinica.id,
        descArquivoImagem: this.acuity.dadosClinica.descArquivoImagem
      })

      this.createFileReader(imageBlob)
    },
    createFileReader(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        this.image = reader.result
      }

      return reader
    },
    async generatePDF() {
      this.pdfExported = false
      const source = window.document.getElementById('pdf-container')
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: 'a4'
      })

      doc.html(source, {
        callback: (doc) => {
          doc.save(`${this.acuity.nmPaciente} - Acuidade Visual`)
          this.pdfExported = true
          this.close()
        }
      })
    },
    formatCEP(cep) {
      if (cep) {
        return `${cep.substring(0, 5)}-${cep.substring(5)}`
      } else {
        return ''
      }
    },
    formatDate(dateString) {
      if (dateString === '' || dateString === null) {
        return ''
      } else {
        // Converte a string de data para um objeto Date
        const dateObject = new Date(dateString)

        // Obtém o dia, mês e ano da data
        const day = String(dateObject.getDate()).padStart(2, '0')
        const month = String(dateObject.getMonth() + 1).padStart(2, '0') // Lembre-se de que os meses são baseados em zero, então somamos 1.
        const year = dateObject.getFullYear()

        // Formata a data no estilo 'dd-mm-yyyy'
        const formattedDate = `${day}/${month}/${year}`
        return formattedDate
      }
    },
    formatCNPJ(cnpj) {
      if (cnpj) {
        let format = ''
        format += `${cnpj.substring(0, 2)}.`
        format += `${cnpj.substring(2, 5)}.`
        format += `${cnpj.substring(5, 8)}/`
        format += `${cnpj.substring(8, 12)}-`
        format += `${cnpj.substring(12, 14)}`

        return format
      } else {
        return ''
      }
    },
    formatCPF(cpf) {
      if (cpf) {
        let format = ''
        format += `${cpf.substring(0, 3)}.`
        format += `${cpf.substring(3, 6)}.`
        format += `${cpf.substring(6, 9)}-`
        format += `${cpf.substring(9, 11)}`

        return format
      } else {
        return ''
      }
    },
    formatPhone(phone) {
      if (phone) {
        let format = '('
        format += `${phone.substring(0, 2)})`
        format += ` ${phone.substring(2, 7)}-`
        format += `${phone.substring(7, 11)}`

        return format
      } else {
        return ''
      }
    },
    handleFormatDate(date) {
      return formatDate(date)
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {
    this.$bvModal.show('pdf-modal')
    this.$nextTick(async () => {
      await this.handleGetAcuity()
    })
  }
}
</script>

<style scoped>
#pdf-modal {
  position: relative;
}
#modal-body {
  height: 100px !important;
  overflow: hidden;
}

#pdf-container {
  width: 590px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 9px;
  color: black;
}
section {
  border: 0.5px solid rgb(118, 118, 118);
  margin: 10px 30px 0px 30px;
}

#clinic-section {
  border: none !important;
  display: flex;
  padding: 10px 0 0 0;
}

#generic-section {
  display: flex;
  flex-direction: column;
}
#generic-section-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

#footer {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: initial;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 20% auto 20% auto;
}

.col {
  display: flex;
  flex-direction: column;
}

.modal-body-hidder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}

.clinic-title {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  color: black;
  border-bottom: 1px solid black;
}

.clinic-name {
  margin: 2px 0;
  font-size: 13px;
  font-weight: 700;
  color: black;
}

.clinic-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20%;
}

.clinic-info-container {
  padding: 25px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 60%;
}

.date-container {
  font-size: 10px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 20%;
}

.logo {
  width: 100px;
  height: 100px;
}

.subtitle {
  width: 100%;
  font-size: 10px;
  font-weight: 700;
  border-bottom: 1px solid rgb(118, 118, 118);
  padding-left: 15px;
  padding-bottom: 2px;
}

.subtitle-2 {
  font-size: 10px;
  font-weight: 700;
  padding-left: 15px;
}

.strong-person {
  font-size: 10px;
  font-weight: 500;
}

span {
  margin: 1px 0 1px 0;
}

.exams-data {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
