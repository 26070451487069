<template>
  <b-container>
    <b-modal
      id="find-schedule"
      ref="find-schedule"
      hide-footer
      title="Busca avançada de horários"
      title-class="text-light"
      header-bg-variant="primary"
      cancel-title="Não"
      ok-title="Sim"
      @close="closeModal"
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
    >
      <b-col lg="12">
        <b-row>
          <b-form-group
            class="col-md-2 d-flex flex-column align-items-center text-center text-nowrap"
            label="Selecionar dias úteis"
            label-for="select-business-days"
          >
            <b-form-checkbox
              id="select-business-days"
              v-model="selectedBusinessDays"
              switch
              @change="switchBusinessDays($event)"
            >
            </b-form-checkbox
          ></b-form-group>

          <b-form-group class="col-md-10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-checkbox-group
                v-model="selectedDays"
                :options="dayOptions"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-checkbox-group>
              <div class="invalid-feedback">
                <span>Selecione pelo menos um dia da semana</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-row style="width: 100%">
            <div class="sameLevel">
              <b-form-group
                class="col-md-3"
                label="Clínica:"
                label-for="clinica"
              >
                <v-select
                  v-model="form.clinica"
                  :options="clinicas"
                  :reduce="(clinica) => clinica"
                  label="nmClinica"
                  :clearable="false"
                ></v-select>
              </b-form-group>
              <b-form-group
                class="col-md-2"
                label-for="dataInicial"
                label="Data Inicial:"
              >
                <b-form-input
                  type="date"
                  v-model="form.dataInicial"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-md-2"
                label-for="dataFinal"
                label="Data Final:"
              >
                <b-form-input
                  type="date"
                  v-model="form.dataFinal"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col-md-1 checkBoxDivSize">
                <b-form-checkbox
                  v-model="selectAllShifts"
                  @change="toggleAllShifts()"
                  switch
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group class="col-md-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-checkbox-group
                    v-model="selectedShift"
                    :options="shiftOptions"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-checkbox-group>
                  <div class="invalid-feedback">
                    <span>Selecione pelo menos um turno da semana</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                label-for="agendarButton"
                label=" "
                class="col-md-1"
              >
                <b-button @click="findSchedule()" variant="primary"
                  >Pesquisar</b-button
                >
              </b-form-group>
            </div>
          </b-row>
          <div class="table">
            <div class="headerList">
              <span>Dia semana</span>
              <span>Data</span>
              <span>Horário</span>
              <span></span>
            </div>
            <div v-if="loading" class="d-flex justify-content-center mt-3 mb-3">
              <b-spinner variant="primary"></b-spinner>
            </div>
            <div
              v-else
              class="dataList"
              v-for="schedule in schedulesList"
              :key="schedule.id"
            >
              <span>{{ schedule.diaSemana }}</span>
              <span>{{ formatDate(schedule.dt_Agendamento, 'pt-BR') }}</span>
              <span>{{ formatHour(schedule.hora_agendamento) }}</span>
              <b-button variant="primary" @click="toSchedule(schedule)"
                >Agendar</b-button
              >
            </div>
          </div>
        </b-row>
      </b-col>
    </b-modal>
  </b-container>
</template>
<script>
import API from '../../services/index'

export default {
  name: 'FindScheduleModal',
  props: ['doctor', 'clinica', 'clinicas'],
  mounted() {
    this.nextMonth()
    this.$bvModal.show('find-schedule')
  },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      selectedBusinessDays: true,
      businessDays: [
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira'
      ],
      selectedDays: [
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira'
      ],
      dayOptions: [
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
        'Domingo'
      ],
      shiftOptions: ['Manhã', 'Tarde', 'Noite'],
      selectedShift: ['Manhã', 'Tarde', 'Noite'],
      selectAllShifts: true,
      form: {
        dataInicial: new Date().toLocaleDateString('en-CA'),
        dataFinal: '',
        clinica: this.clinica
      },
      schedulesList: [],
      loading: false
    }
  },
  watch: {
    selectedDays(newValue) {
      // Handle changes in individual checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.selectedBusinessDays = false

        return null
      }

      const selectedDaysContainsBusinessDays = this.businessDays.every(
        (element) => {
          return newValue.includes(element)
        }
      )

      if (selectedDaysContainsBusinessDays) {
        this.indeterminate = false
        this.selectedBusinessDays = true
      } else {
        this.indeterminate = true
        this.selectedBusinessDays = false
      }
    },
    selectedShift(newValue, oldValue) {
      // Handle changes in individual checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.selectAllShifts = false
      } else if (newValue.length === this.shiftOptions.length) {
        this.indeterminate = false
        this.selectAllShifts = true
      } else {
        this.indeterminate = true
        this.selectAllShifts = false
      }
    }
  },
  methods: {
    switchBusinessDays(value) {
      if (value) {
        this.selectBusinessDays()
      } else {
        this.unselectAllDays()
      }
    },
    nextMonth(initialDate) {
      let date
      initialDate ? (date = new Date(initialDate)) : (date = new Date())
      this.form.dataFinal = new Date(
        date.setMonth(date.getMonth() + 1)
      ).toLocaleDateString('en-CA')
    },
    formatHour(hourUnformated) {
      if (!hourUnformated) return ''
      let [hour, time] = hourUnformated.split(' ')

      if (time) {
        return time === 'AM' && hour[0] === '1' && hour[1] === '2'
          ? `00:${hour[3]}${hour[4]}`
          : time === 'PM' && hour[0] === '1' && hour[1] === '2'
          ? hour
          : time === 'AM' && hour.length <= 4
          ? (hour = `0${hour}`)
          : time === 'AM'
          ? hour
          : time === 'PM' && hour.length <= 4 && parseInt(hour[0]) + 2 < 10
          ? (hour = `1${parseInt(hour[0]) + 2}:${hour[2]}${hour[3]}`)
          : time === 'PM' && hour.length <= 4
          ? (hour = `2${parseInt(hour[0]) - 8}:${hour[2]}${hour[3]}`)
          : (hour = `2${parseInt(hour[0] + hour[1]) - 8}:${hour[3]}${hour[4]}`)
      } else {
        return hourUnformated
      }
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    getDayCode(days) {
      return days.map((day) => {
        switch (day) {
          case 'Domingo':
            return 0
          case 'Segunda-feira':
            return 1
          case 'Terça-feira':
            return 2
          case 'Quarta-feira':
            return 3
          case 'Quinta-feira':
            return 4
          case 'Sexta-feira':
            return 5
          case 'Sábado':
            return 6
        }
      })
    },
    getShiftCode(shifts) {
      return shifts.map((shift) => {
        switch (shift) {
          case 'Manhã':
            return 'M'
          case 'Tarde':
            return 'T'
          case 'Noite':
            return 'N'
        }
      })
    },
    async findSchedule() {
      const reqBody = {
        id_usuario: this.doctor.idUsuario,
        id_clinica: this.form.clinica.idClinica,
        dt_inicio: this.form.dataInicial,
        dt_fim: this.form.dataFinal,
        listaDiaSemana: this.getDayCode(this.selectedDays),
        listaPeriodoDia: this.getShiftCode(this.selectedShift)
      }
      this.loading = true
      await API.post(
        `/api/Agenda/LocalizaHorarioAgenda/`,
        { object: reqBody },
        this.authToken
      )
        .then((res) => {
          this.schedulesList = res.data.object
          this.loading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          this.$toast.error('Nenhum horário livre encontrado!')
          this.loading = false
        })
    },
    selectBusinessDays() {
      this.selectedDays = this.businessDays
    },
    unselectAllDays() {
      this.selectedDays = []
    },
    toggleAllShifts() {
      !this.selectAllShifts
        ? (this.selectedShift = [])
        : (this.selectedShift = ['Manhã', 'Tarde', 'Noite'])
    },
    closeModal() {
      this.$bvModal.hide('find-schedule')
      this.$emit('modalClosed', 'find-schedule')
    },
    toSchedule(schedule) {
      this.$bvModal.hide('find-schedule')
      this.$emit('modalClosed', 'find-schedule')
      this.$emit('handlerShowToScheduleFromSearch', schedule)
      // this.closeModal()
    }
  }
}
</script>

<style scoped>
.checkBoxDivSize {
  padding-right: 0 !important;
  padding-left: 0 !important;
  max-width: 50px !important;
  margin: 0;
}

.select {
  max-width: 80% !important;
}

.sameLevel {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.table {
  margin-top: 20px;
  height: 65vh;
  background: white;
  border-radius: 6px;
  overflow-y: auto;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background: #089bab;
  color: #fff;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-content: space-between;

  text-align: center;
  align-items: center;
  color: #000;
  border-bottom: 2px solid #c4c8d1;

  transition: box-shadow 0.3s;
}
</style>
