export default {
  // Result
  localCoverSheet: null,
  localResults: [],
  // Vacinas
  listVaccines: [],
  dataVaccines: {},
  vaccineEdit: {},
  // Ultrassom
  dataUltrassom: [],
  // Estado dos exames laboratoriais
  dataExamsLaboratorys: [],
  getListExamsLaboratory: []
}
