import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  object: false,
  isVisible: false,
  openedFromMedicalReports: false,
  isActive: false,
  certificate: ''
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
