<template>
  <div></div>
</template>
<script>
import auth0 from 'auth0-js'
import constant from '@/config/constant'

export default {
  name: 'Callback',
  mounted() {
    const autho = new auth0.WebAuth(constant.auth0Config)
    const token = this.$route.hash

    autho.parseHash({ hash: token }, function (err, authResult) {
      autho.client.userInfo(authResult.accessToken, (user) => {
        this.$router.push({ name: 'mini.dashboard.home-1' })
      })
    })
    this.$router.push('/')
  }
}
</script>
